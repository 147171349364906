//Customizable Area Start
import React from "react";
import {
    Box,
} from "@material-ui/core";

import './global.css';
import ComingSoonController,{Props} from "./ComingSoonController.web";
import MainScreen from "./MainScreen.web";

export default class Comingsoon extends ComingSoonController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
             return (
            <MainScreen>
                 <Box style={{textAlign:"center",alignItems:"center",padding:"30px", width:"100%"}}>
                <h4>Coming soon...</h4>
              </Box>
            </MainScreen>
             
            )

    }
}

const webStyle = {
};
//Customizable Area End


