import React from "react"
// Customizable Area Start
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
    withStyles,
    Divider,
    DialogTitle, DialogActions, Dialog
} from "@material-ui/core";
import CEPollSurveyController, { Props } from './CEPollSurveyController.web';
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Cardcalendar,
    CheckMark,
    allUsers,
    awated,
    building,
    pollcreate,
    surveycreate,
    xmark,
    pollandsurvey
} from "../assets";
import {withRouter} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
// @ts-ignore
import DOMPurify from 'dompurify'
// Customizable Area End

class CEPollSurvey extends CEPollSurveyController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  iconComponent = (props: any) => (
    <ExpandMoreIcon style={webStyle.expandIcon} />
  );

  renderSelectMenuWrap = (placeholder: string, name: string, menuProps: any, selectOptions: any, value: string, onHandleChange: any) => (
    <div className="formGroup">
    <FormControl variant="outlined" style={{ paddingTop: 0, paddingBottom: 0 }} size="small" fullWidth>
        <Select
            displayEmpty
            className="select-input cePollSurveySelect"
            value={value}
            style={{...webStyle.selectInputWrappers, fontWeight: "normal"}}
            id="PollSearch"
            MenuProps={menuProps}
            name={name}
            labelId="demo-simple-select-standard-label"
            onChange={onHandleChange}
            IconComponent={this.iconComponent}
            data-test-id={name}
        >
        <MenuItem disabled style={{ height: '36px' }} value=''>
            {placeholder}
        </MenuItem>
        <MenuItem value='all' style={{ height: '36px' }}>All</MenuItem>
        {selectOptions.length > 0 && selectOptions.map((option: string) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
        </Select>
    </FormControl>
    </div>

)

  renderSearchButtonWrap = () => (
    <div style={{ margin: "none" }}>
      <Button
        data-test-id="apply_search_filter"
        className="Myteam"
        color="primary"
        variant="contained"
        style={{
          borderRadius: "10px",
          backgroundColor: "#2B6FED",
          color: "#ffffff",
          fontSize: "16px",
          margin: "0px",
          height:"46px",
        }}
        type="submit"
      >
        <SearchIcon />
        <Typography
          className="login-h1 bold-text"
          style={{ fontWeight: "bolder", color: "#ffffff" }}
        >
          Search
        </Typography>
      </Button>
    </div>
)

getCreatedCard = (type: string, data: any) => (
    <Box className="dashboard_card" style={webStyle.cardWrapper} p={3}>
        <img style={{ width: "fit-content", padding: "16px", border: "1px solid #8dabe338", borderRadius: "50%" }} src={type === "Polls" ? pollcreate : surveycreate} alt="" />
        <Typography className="bold-text" style={{ fontSize: "18px"}}>{type} created</Typography>
        <Typography className="bold-text" style={{ color: "rgb(252, 132, 52)", fontWeight: 700, fontSize: "18px"}}>{data.total}</Typography>
        <Typography style={{ fontSize: "16px", color: "#000" }}>Last {type === "Polls" ? 'poll' : 'survey'} created on  {data.lastCreated}</Typography>
    </Box>
)

CreateCard = (type: string, data: any) => (
    <Box className="dashboard_card" style={webStyle.cardWrapper} p={3}>
        <Box className="CreatePSCE" onClick={() => {  this.setState({ showDialog: true})}} style={{paddingTop:"2rem",paddingBottom:"4rem"}}>
            <Box sx={{ml:1, mb:2}} >
                <img src={pollandsurvey} alt="pollandsurvey" />
            </Box>
            <Typography  className="CreatePSHeading bold-text" style={{fontSize:"16px"}}>{"Create a New Polls/Survey"}</Typography>
        </Box>
    </Box>
)

getIconData = (icon: string, data: number) => (
    <Box style={webStyle.rowContent}>
        <img src={icon} alt={icon} />
        <Typography className="bold-text" style={{ fontSize: "16px" }}>{data}</Typography>
    </Box>
)

    getRecentSection = (type: string, data: any) => (
        <Box>
            <Box style={webStyle.cardSectionTop}>
                <Typography className="bold-text" style={{ fontSize: "22px"}}>Recent {type}</Typography>
                <Typography className="bold-text" data-test-id="view-all-button" onClick={() => this.handleViewAll(type)} style={{ color: "rgb(252, 132, 52)", cursor: "pointer",fontWeight: 700, fontSize: "18px"}}>View All</Typography>
            </Box>
            <Grid container spacing={2}>
                {data.length > 0 ? data.map((cardData: any) => (
                    <Grid item xs={4} md={4}>
                        <Box data-test-id="card" style={{ cursor: "pointer"}} key={cardData.id} onClick={() => this.navigateToPollSurvey(cardData.id, type)} className="dashboard_card" p={2}>
                          <Box display="flex" flexDirection="column" paddingBottom="15px" style={{ gap: "8px"}}>
                            <Box className="bold-text" style={webStyle.statusContainer} sx={{ bgcolor: this.getBackground(cardData.status)+"20", color: this.getBackground(cardData.status)}} >{cardData.status}</Box>
                            <Typography className="bold-text textwrap" style={{ fontSize: "18px" }} >{cardData.title}</Typography>
                            <Typography style={{ fontSize: "18px"}} className="textwrap" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cardData.description) }}/>
                            <Box style={webStyle.rowContent}>
                                <img src={Cardcalendar} alt="card-calendar" />
                                <Typography style={{ fontSize: "14px" }}>{cardData.start_date} - {cardData.end_date}</Typography>
                            </Box>
                            <Box style={webStyle.rowContent}>
                                <img src={building} alt="building" />
                                <Typography style={{ fontSize: "14px" }}>{cardData.complex_name}</Typography>
                            </Box>
                          </Box>
                            <Divider />
                            <Box display="flex" style={{ gap: "25px"}} marginTop="15px">
                                {this.getIconData(allUsers, cardData.completed_answers+cardData.awaited)}
                                {cardData.status !== "upcoming" && this.getIconData(CheckMark, cardData.completed_answers)}
                                {cardData.status !== "upcoming" && this.getIconData(cardData.status === "ongoing" ? awated : xmark, cardData.awaited)}
                            </Box>
                        </Box>
                    </Grid>
                ))
                :
                    <Typography style={{ fontSize: "18px", fontWeight: 700,marginLeft:"10px",minHeight:"150px"}} color="textSecondary">No {type} found</Typography>
                }
            </Grid>
        </Box>
    )

    getRecentSectionSurvey = (type: string, data: any) => (
        <Box>
            <Box style={webStyle.cardSectionTop} className="survey">
                <Typography className="bold-text survey" style={{ fontSize: "22px"}}>Recent {type}</Typography>
                <Typography className="bold-text survey" data-test-id="view-all-button" onClick={() => this.handleViewAll(type)} style={{ color: "rgb(252, 132, 52)", cursor: "pointer",fontWeight: 700, fontSize: "18px"}}>View All</Typography>
            </Box>
            <Grid container spacing={2} className={"survey"}>
                {data.length > 0 ? data.map((cardData: any) => (
                    <Grid item xs={4} md={4} className="survey">
                        <Box data-test-id="card" style={{ cursor: "pointer"}} key={cardData.id}  onClick={() => this.navigateToPollSurvey(cardData.id, type)} className="dashboard_card survey" p={2}>
                            <Box display="flex" flexDirection="column" paddingBottom="15px" style={{ gap: "8px"}} className="survey">
                                <Box className="bold-text survey" style={webStyle.statusContainer} sx={{ bgcolor: this.getBackground(cardData.attributes.status)+"20", color: this.getBackground(cardData.attributes.status)}} >{cardData.attributes.status}</Box>
                                <Typography className="bold-text survey textwrap" style={{ fontSize: "18px" }} >{cardData.attributes?.title}</Typography>
                                <Typography style={{ fontSize: "18px"}} className="textwrap survey" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cardData.attributes?.description) }}/>
                                <Box style={webStyle.rowContent} className="survey">
                                    <img src={Cardcalendar} alt="card-calendar survey" />
                                    <Typography style={{ fontSize: "14px" }} className="survey">{cardData.attributes?.start_date} - {cardData.attributes?.end_date}</Typography>
                                </Box>
                                <Box style={webStyle.rowContent} className="survey">
                                    <img src={building} alt="building" className="survey" />
                                    <Typography style={{ fontSize: "14px" }} className="survey">{cardData.attributes?.building_name}</Typography>
                                </Box>
                            </Box>
                            <Divider className="survey" />
                            <Box display="flex" className="survey" style={{ gap: "25px"}} marginTop="15px">
                                {this.getIconData(allUsers, cardData.attributes?.total_audience)}
                                {cardData.status !== "upcoming" && this.getIconData(CheckMark, cardData.attributes.total_response)}
                                {cardData.status !== "upcoming" && this.getIconData(cardData.attributes.status === "ongoing" ? awated : xmark, (cardData.attributes.total_audience - cardData.attributes.total_response))}
                            </Box>
                        </Box>
                    </Grid>
                ))
                    :
                    <Typography style={{ fontSize: "18px", fontWeight: 700,marginLeft:"10px",minHeight:"150px"}} color="textSecondary" className="survey">No {type} found</Typography>
                }
            </Grid>
        </Box>
    )
  // Customizable Area End
  render() {
    // Customizable Area Start
      const { classes } = this.props;
      const menuProps = {
      classes: {
          paper: classes.menuPaper,
          list: classes.menuList,
      },
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={{ padding: '40px', paddingRight: '40px' }}>
                <Typography style={{ fontSize: '16px' }}> Activities / <Typography component="span" style={{ color: "rgb(43, 111, 237)"}}>Poll and surveys</Typography></Typography>
                <br />
                <Box className="Box12" style={webStyle.pageAction}>
                    <Box>
                        <Typography
                            className="login-h1 bold-text"
                            style={{ fontSize: "30px" }}
                        >
                            Poll / Survey
                        </Typography>
                    </Box>
                </Box>
                <br />
                <Box style={webStyle.cardSection}>
                    {this.CreateCard('Polls', this.state.pollsCreated)}
                    {this.getCreatedCard('Polls', this.state.pollsCreated)}
                    {this.getCreatedCard('Surveys', this.state.surveysCreated)}
                </Box>
                {this.getRecentSection("Polls", this.state.recentPolls)}
                {this.getRecentSectionSurvey("Surveys", this.state.recentSurveys)}
                  <Dialog
                      open={this.state.showDialog} onClose={() => this.setState({ showDialog: false })}
                      scroll="paper"
                      className="DialogMainBox"
                  >
                      <DialogTitle id="alert-dialog-title CEOptions" style={{textAlign:"center"}}>{"Choose Options"}</DialogTitle>
                      <Box className="CEOptions" style={{ display: "flex", marginLeft: 50, marginRight: 50}}>
                          <DialogActions className={"CEOptions"} disableSpacing style={{flexDirection:"row",marginTop:'0px'}}>
                              <div
                                  onClick={() => {
                                      this.setState({ showDialog: false})
                                      this.props.history.push("/CompanyEmployee/CreatePoll")
                                  }} className="dialogOption CEOptionsPoll"
                              >
                                  <img src={pollcreate} alt="pollcreate" className="DialogIcons CEOptions" width="50px"/>
                                  <p className="CEOptions" >{"Create Poll"}</p>
                              </div>
                              <div
                                  onClick={() => {
                                      this.setState({ showDialog: false})
                                      this.props.history.push("/CompanyEmployee/CreateSurvey")
                                  }} className="dialogOption CEOptionsSurvey"
                              >
                                  <img src={surveycreate} alt="surveycreate" className="DialogIcons CEOptions" width="50px"/>
                                  <p className="CEOptions">{"Create Survey"}</p>
                              </div>
                          </DialogActions>
                      </Box>
                  </Dialog>
            </Box>
      // Customizable Area End
    )
  }

}
// Customizable Area Start

const webStyle: any = {
    selectSections: {
        flexWrap: 'wrap',
        marginTop: '15px',
        display: 'flex',
        gap: '15px',
    },
    rowContent: {
      display: 'flex',
      alignItems: "center",
      gap: "8px"
    },
    selectInputWrappers: {
        minWidth: "150px",
        padding: "7px 7px 7px 12px",
        height: '48px',
        border: "1px solid #F0F0F0",
        background: "#FFFFFF",
        fontWeight: "bold",
        color: "#212121",
        borderRadius: "8px"
    },
    cardSection: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "20px"
    },
    cardWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "5px"
    },
    cardSectionTop: {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 0 10px",
    },
    statusContainer: {
      borderRadius: "18px",
      fontSize: "12px",
      padding: "4px 10px",
      width: "fit-content",
      textTransform: "Capitalize"
    },
    expandIcon: {
      color: 'rgb(177, 177, 177)'
  },
};

const styles: any = {
  menuTeamPaper: {
    maxHeight: '300px'
  },
  menuTeamList: {
    paddingRight: '0px !important',
  }
}

export default withTranslation()(withStyles(styles)(withRouter(CEPollSurvey)))
//Add a style or MUI customized style
// Customizable Area End