// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";


export const configJSON = require("../../dashboard/src/config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  invoicesList:any;
  filterBuilding:any;
  filterFloor:any;
  filterUnit:any;
  filterStatus:any;
  filterType:any;
  searchKey:any;
  buildingList:any;
  unitList:any;
  floorList:any;
  page:any
  count:any;
  pagination:any;
  downloadId:any;
  showError:boolean;
  error:any;
  showSuccess:boolean;
  successMessage:any;
}

interface SS {
  id: any;
}

export default class CharmainInvoicesController extends CommonApiCallForBlockComponent<Props,S,SS> {
  getOverDueInvoiceListId:any
  getOverDueBuildingListId:any
  getOverDueUnitListId:any;
  getOverDueFloorListId:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    this.state = {
      invoicesList:[],
      filterBuilding:"",
      filterFloor:"",
      filterStatus:"",
      filterType:"",
      filterUnit:"",
      searchKey:"",
      buildingList:[],
      floorList:[],
      unitList:[],
      page:1,
      count:10,
      pagination:{
        current_page:1,
        total_count:0,
        total_pages:1,
      },
      downloadId:"",
      showError:false,
      error:"",
      showSuccess:false,
      successMessage:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getOverDueBuildingList()
    this.overDuePayment({
      buildingId:this.state.filterBuilding,
      floorNo:this.state.filterFloor,
      unitId:this.state.filterUnit,
      paymentType:this.state.filterType,
      status:this.state.filterStatus,
      searchKey:this.state.searchKey,
      page:this.state.page,
    })
  }

  handleInvoicesPagination = (e:any,value:any) => {
    this.overDuePayment({
      buildingId:this.state.filterBuilding,
      floorNo:this.state.filterFloor,
      unitId:this.state.filterUnit,
      paymentType:this.state.filterType,
      status:this.state.filterStatus,
      searchKey:this.state.searchKey,
      page:value,
    })
    this.setState({
      page:value
    })
  }

  handleFilterBy = () => {
    this.overDuePayment({
      buildingId:this.state.filterBuilding,
      floorNo:this.state.filterFloor,
      unitId:this.state.filterUnit,
      paymentType:this.state.filterType,
      status:this.state.filterStatus,
      searchKey:this.state.searchKey,
      page:this.state.page,
    })
  }

  getOverDueInvoicesListResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("invoices")){
      this.setState({
        invoicesList:responseJson.invoices.data,
        pagination:responseJson?.meta?.pagination,
      })
    }else{
        this.setState({
            invoicesList:[],
            pagination:{
            current_page:1,
            total_count:0,
            total_pages:1,
            },
        })
    }
  }

  getOverDueBuildingListResponse = (responseJson:any) => {
    if(responseJson?.hasOwnProperty("buildings")){
      this.setState({
        buildingList:responseJson?.buildings
      })
    }
  }

  getOverDueUnitListResponse = (responseJson:any) => {
    if(responseJson?.hasOwnProperty("units")){
      this.setState({
        unitList:responseJson.units
      })
    }else{
      this.setState({
        unitList:[]
      })
    }
  }

  getOverDueFloorListResponse = (responseJson:any) => {
    if(responseJson?.hasOwnProperty("floors")){
      this.setState({
        floorList:responseJson.floors
      })
    }else{
      this.setState({
        floorList:[]
      })
    }
  }

  manageDownload = async (id:any) => {
    await this.downloadPdf(`/bx_block_fees_payment/invoices/${id}/download_invoice`,`Invoice-${id}.pdf`)
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getOverDueInvoiceListId){
        this.getOverDueInvoicesListResponse(responseJson)
      }
      if(apiRequestCallId === this.getOverDueBuildingListId){
        this.getOverDueBuildingListResponse(responseJson)
      }
      if(apiRequestCallId === this.getOverDueUnitListId){
        this.getOverDueUnitListResponse(responseJson)
      }
      if(apiRequestCallId === this.getOverDueFloorListId){
        this.getOverDueFloorListResponse(responseJson)
      }
    }
  }

  selectBuilding = (e:any) => {
    this.setState({
      filterBuilding:e.target.value,
    })
    this.getOverDueUnitList(e.target.value,"")
    this.getOverDueFloorList(e.target.value)
  }

  selectFloor = (e:any) => {
    this.setState({
      filterFloor:e.target.value,
    })
    this.getOverDueUnitList(this.state.filterBuilding,e.target.value)
  }

  overDuePayment = async (data:any) => {
    const {buildingId,floorNo,unitId,searchKey,page} = data
    const societyID = localStorage.getItem("society_id")
    this.getOverDueInvoiceListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/overdue_payments?society_management_id=${societyID}&search=${searchKey|| ""}&unit_id=${unitId|| ""}&building_management_id=${buildingId|| ""}&floor_number=${floorNo|| ""}&page=${page || 1}`,
    });
    return true
  };

  getOverDueBuildingList = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getOverDueBuildingListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/buiding_list?society_management_id=${societyID}`,
    });
    return true
  };

  getOverDueUnitList = async (buildingId:any,floorId:any) => {
    this.getOverDueUnitListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/unit_list?building_management_id=${buildingId}&floor_number=${floorId}`,
    });
    return true
  };

  getOverDueFloorList = async (buildingId:any) => {
    this.getOverDueFloorListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/floor_number?building_id=${buildingId}`,
    });
    return true
  };

  handleSearch = (e:any) => {
    this.setState({
      searchKey:e.target.value
    })
    this.overDuePayment({
      buildingId:this.state.filterBuilding,
      floorNo:this.state.filterFloor,
      unitId:this.state.filterUnit,
      paymentType:this.state.filterType,
      status:this.state.filterStatus,
      searchKey:e.target.value,
      page:this.state.page,
    })
  }

}
// Customizable Area End