import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  location:any;
  match: any;
  i18n: any;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showError:boolean;
  userRole:any;
  loading:boolean;
  error:any;
  details:any;
  handleOpen:boolean;
  updateVal:any;
  newValue:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientTicketsDetailsController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDetailsCallId:any;
  getUpdateList:any;
  getUpdateCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showError:false,
      userRole:"",
      loading:false,
      error:null,
      details:[],
      handleOpen:false,
      updateVal:"",
      newValue:null,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        
        switch (apiRequestCallId) {
        
          case this.getDetailsCallId:
            return this.handledetailListSucc(responseJson);
        
          case this.getUpdateCallId:
            return this.handleUpdateSucc(responseJson)
          default:
            break

         
        }
        
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getDetails()
  }
  handleUpdate=async()=>{
    this.setState({loading:true})
    const match: any = this.props.match;
    const id = match?.params.id;
    const formData = new FormData();
    formData.append("response",  this.state.updateVal);
   
    this.getUpdateCallId = await apiCall({
     
      method: "PUT",
      endPoint: `bx_block_contact_us/client_tickets/coo_response_ticket?ticket_id=${id}`,
      body:formData
    });
  }
  handleUpdateSucc=(responseJson:any)=>{
    this.setState({newValue:responseJson.data,loading:false,handleOpen:false},()=>this.getDetails())
  }
  handleResponse=()=>{
   
    this.setState({handleOpen:true})
  }
  getDetails = async () => {
    
    this.setState({loading:true})
    const match: any = this.props.match;
    const id = match?.params.id;
    this.getDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contact_us/client_tickets/${id}`,
    });
  };
  handledetailListSucc=(responseJson:any)=>{ this.setState({details:responseJson.data.attributes,loading:false})}
 
  // Customizable Area End
}
