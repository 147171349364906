import React from "react";

// Customizable Area Start
import { Box, Button, Typography, IconButton, Dialog, DialogActions, Grid, DialogContent } from "@material-ui/core";
import { Building1, NoChat, Search } from "./assets";
import { withRouter } from "react-router";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Loader from "../../../components/src/Loader.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InboxController, { Props } from "./inboxController.web";
import moment from "moment";
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { ROLE } from "../../../framework/src/Enum";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

class OwnerChatInbox extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProfile();
    this.getInbox();
  }

  displaytime(obj: any) {
    let messageTime: any = obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    if (messageTime) {
      return this.dateToFromNowDaily(messageTime[messageTime.length - 1].message.created_at);
    } else {
      return "";
    }
  }

  dateToFromNowDaily(myDate: any) {
    return moment(myDate).calendar(null, {
      lastWeek: "[Last] dddd",
      sameElse: "DD/MM/YYYY",
      nextWeek: "dddd",
      sameDay: "HH:mm A",
      lastDay: "[Yesterday]",
      nextDay: "[Tomorrow]",
    });
  }

  getLastMessage = (obj: any) => {
    let value = obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    if (value) {
      return value[value.length - 1].message.message;
    } else {
      return "";
    }
  };

  ChatBox = (item: any) => {
    return (
      <>
        <Box
          className="owner-chat-box"
          key={item}
          display="flex"
          style={{
            gap: "1rem",
            maxHeight: "5rem",
            cursor: "pointer",
            borderBottom: "2px solid #F8F8F8",
            padding: "10px 20px",
          }}
          onClick={() => this.openChat(item)}
        >
          <img
            src={
              item?.attributes?.chat_with_account?.id != localStorage.getItem("userId") ? item?.attributes?.chat_with_account?.attributes?.profile_pic?.url || NoProfile_Img : item?.attributes?.chatable?.attributes?.profile_pic?.url || NoProfile_Img
            }
            width="50"
            height="50"
            style={{ borderRadius: 25 }}
          />
          <Box padding="0.25rem" width="100%">
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <h5>
                {item?.attributes?.chat_with_account?.id != localStorage.getItem("userId") ? item?.attributes?.chat_with_account?.attributes?.full_name || "N/A" : item?.attributes?.chatable?.attributes?.full_name || "N/A"}
              </h5>
              <p style={{ color: "#9c9c9c" }}>{this.displaytime(item.attributes.messages)}</p>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <p>
                {Object.keys(item.attributes.messages).length != 0 && this.getLastMessage(item.attributes.messages)}
              </p>
              {item.attributes.is_mark_unread !== 0 && (
                <p
                  style={{
                    background: "#FC8434",
                    color: "white",
                    borderRadius: "50%",
                    width: "12px",
                    height: "12px",
                    fontSize: "12px",
                    padding: "4px 6px 8px 6px",
                    textAlign: "center",
                  }}
                >
                  {item.attributes.is_mark_unread}
                </p>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    let profileData = this.state.profileData;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box
          dir={languageCondition(language, "rtl", "ltr")}
          className="login-wrapper reg-wrapper"
          style={{ margin: 0, background: "white", marginTop: 5 }}
        >
          <Grid container style={{ padding: "6px 1rem", borderBottom: "2px solid #f2f2f2" }}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                display="flex"
                alignItems="center"
                width={this.state.isSearch ? "7%" : "100%"}
                className="owner-chat-back-box"
                onClick={() => {
                  if (ROLE.OWNER === localStorage.getItem("userType")) {
                    this.props.navigation.navigate("OwnerDashboard");
                  } else {
                    this.props.navigation.navigate("ResidentDashboard");
                  }
                }}
              >
                <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                <span className="bold-text" style={{ marginLeft: "0.5rem" }}>
                  {!this.state.isSearch && `${t("My Chat")}`}
                </span>
              </Box>
              <Box display="flex" alignItems="center" width="100%">
                <Box width="100%" display="flex" style={{ gap: "0.5rem" }} justifyContent="end" alignItems="center">
                  {this.state.isSearch ? (
                    <>
                      {" "}
                      <input
                        placeholder={t("Search")}
                        autoFocus
                        className="inputbox"
                        style={{ outline: "none", borderRadius: "8px", padding: "0" }}
                        onChange={(e) => this.getInboxBySearch(e.target.value)}
                      />{" "}
                      <span onClick={this.handlesearchIcon} style={{ fontWeight: "bold", cursor: "pointer" }}>
                        X
                      </span>
                    </>
                  ) : (
                    <img src={Search} style={{ float: "right", cursor: "pointer" }} onClick={this.handlesearchIcon} />
                  )}
                </Box>
                <div className={languageCondition(language, "", "right-icon chat-menu")}>
                  <Menu
                    menuButton={
                      <IconButton>
                        <MoreVertIcon />
                      </IconButton>
                    }
                  >
                    <MenuItem className="owner-chat-toggle-menu" onClick={() => this.setState({ showSuccessModal: true, showDialog: false })}>
                      {profileData?.attributes?.disable_chat ? `${t("Enable Chat")}` : `${t("Disable Chat")}`}
                    </MenuItem>
                  </Menu>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols" style={{ justifyContent: "normal" }}>
              {this.state.allInbox.length != 0 ? (
                this.state.allInbox.map((item) => (
                  <>
                    {this.ChatBox(item)}
                  </>
                ))
              ) : (
                <div> {t("No chat")} </div>
              )}
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols owner-chat-grid">
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="owner-chat-image" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={this.state.showSuccessModal}
          className="delete-document personal owner-chat-modal"
          fullWidth
          onClose={() => this.setState({ showSuccessModal: false })}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={NoChat} />
              <Typography variant="h6" className="bold-text">
                {profileData?.attributes?.disable_chat ? `${t("Enable Chat")}` : `${t("Disable Chat")}`}{" "}
                {t("Functionality?")}
              </Typography>
              <Typography variant="body1">
                {t("Are you sure want to")}{" "}
                {profileData?.attributes?.disable_chat ? `${t("Enable Chat")}` : `${t("Disable Chat")}`}{" "}
                {t("functionality? No one will be able to send you any messages while it is disabled.")}
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button className="owner-success-modal-btn" onClick={() => this.disablechat()}>
                  {t("Yes")} {profileData?.attributes?.disable_chat ? `${t("Enable")}` : `${t("Disable")}`}
                </Button>
                <Button className="owner-cancel-modal-btn" onClick={() => this.setState({ showSuccessModal: false })}>
                  {t("No, don’t")} {profileData?.attributes?.disable_chat ? `${t("Enable")}` : `${t("Disable")}`}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Loader loading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignore
//@ts-nocheck
export default withTranslation()(withRouter(OwnerChatInbox));

// Customizable Area Start
// Customizable Area End
