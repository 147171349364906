// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography, MenuItem, Select, Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import BuildingBudgetController, { Props } from "./BuildingBudgetController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class BuildingBudget extends BuildingBudgetController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols buildingBudget">
          <Grid container className="fee-payment-top-bar buildingBudget-navigation">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} onClick={() => this.props.history.push("/BuildingBudget/ComplexList")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {t("View Building Budget")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list buildingBudget-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant={"body1"} className="bold-text">
                    {sessionStorage.getItem("complexName")}
                  </Typography>
                  <Select
                    onChange={this.handleChange}
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    disableUnderline
                    className="selectFYBox buildingBudget-select-box"
                    value={this.state.budgetYear}
                  >
                    <MenuItem value={new Date().getFullYear() - 2} className="budget-menu-prev">
                      {t("FY")} {new Date().getFullYear() - 2}-{(new Date().getFullYear() - 1).toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear() - 1} className="budget-menu-prev">
                      {t("FY")} {new Date().getFullYear() - 1}-{new Date().getFullYear().toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear()} className="budget-menu-today">
                      {t("FY")} {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(-2)}
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} className="buildingBudget-content">
                <Box style={{ backgroundColor: "#2b6fed", borderRadius: "8px 8px 0px 0px" }}>
                  <Box style={{ padding: "12px 15px", display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="subtitle2" className="bold-text" style={{ color: "white" }}>
                      {t("Facility")}
                    </Typography>
                    <Typography variant="subtitle2" className="bold-text" style={{ color: "white" }}>
                      {t("Allocated Budget")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  bgcolor="white"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9", borderRadius: "0 0 8px 8px" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    {this.state.buildingBudgetList.map((item: any, key: any) => {
                      return (
                        <Box
                          key={key}
                          style={{
                            padding: "8px 15px 0",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography variant="subtitle2" color="textSecondary">
                            {t(item.budget_category)}
                          </Typography>
                          <Typography variant="subtitle2">
                            {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)} {Number(item.allocate_budget).toLocaleString()}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Divider />
                    <Box style={{ padding: "15px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Final Budget")}</Typography>
                      <Typography variant="body2" className="bold-text" style={{ fontSize: "16px" }}>
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)} {Number(this.state.finalBudgetList).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  marginTop=".5rem"
                  padding="1rem"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Final Budget")}</Typography>
                      <Typography variant="body2" className="bold-text" style={{ fontSize: "16px" }}>
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)} {Number(this.state.finalBudgetList || 0).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Budget per sq. ft.")}</Typography>
                      <Typography variant="body2" className="bold-text" style={{ fontSize: "16px" }}>
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)} {Number(this.state.perBudgetList || 0).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} className="only-download-button">
                <CloseButton className="download-budget" style={{ width: "98%" }} onClick={this.buildingBudgetDownload}>
                  {t("Download budget document")}
                </CloseButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(BuildingBudget));
// Customizable Area End
