import React from "react";
// Customizable Area Start
import {
    Typography,
    IconButton,
    Select,
    MenuItem,
    Divider,
    Grid,
    TextField,
    InputAdornment, DialogContent, Box, DialogActions, Button, Dialog
} from "@material-ui/core";
import CECreateNominationController, { Props } from "./CECreateNominationController";
import "@szhsin/react-menu/dist/core.css";
import { withTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { dashBoardActions, PublishButton, CloseButton } from "../../../InvoiceBilling/src/chairmanUIStyles";
import { withRouter } from "react-router-dom";
import {languageCondition} from "../../../../components/src/ConditionManager.web";
import moment from "moment/moment";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import {Form, Formik} from "formik";
import {warningIcon} from "../assets";
// Customizable Area End
class CECreateNomination extends CECreateNominationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const errorHandlerFormik = (errors: any, touched: any) => {
      if (errors && touched) {
          return <small className="error">{errors}</small>
      }
    }
    const {language} = i18n
    return (
        <div style={dashBoardActions.expensePaper} dir={languageCondition(language,"rtl","ltr")} className={languageCondition(language, "arabic-grid", "CE-Nomination")}>
          <div style={dashBoardActions.expenseModalHeader} className="CE-Nomination">
            <Typography variant="h6" className="bold-text CE-Nomination" style={dashBoardActions.subHeadingFont}>
              {t("Chairman and Vice Chairman Nomination")}
            </Typography>
            <IconButton className="createNominationCloseButton CE-Nomination" onClick={() => this.props.handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <Formik initialValues={this.state.isEdit ? this.initialValuesNominationEdit() : this.initialValuesNomination()}
                  validationSchema={this.nominationSchema()}
                  validateOnMount={true}
                  enableReinitialize
                  onSubmit={(values) => { this.createNomination(values) }}
          >
            {({   values,
                errors,
                handleChange,setFieldValue,touched}) => (
                <Form className="commonForm" translate="yes" style={{width:"100%",marginTop:"10px"}} >
                  <Grid container spacing={2}>
                    {   !this.props.isEdit &&
                        <>
                        <Grid item xs={6}>
                          <Select
                              variant="outlined"
                              displayEmpty
                              fullWidth
                              className="select-input selectCountryCENomniation" name="country"
                              onChange={(e)=> this.manageChangeCountry(e,setFieldValue)}
                              value={values.country}
                              style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select Country")}
                            </MenuItem>
                            {this.state.countryList.map((item: any, key: any) => {
                              return (
                                  <MenuItem key={key} value={item} className={languageCondition(language, "select-arabic-menu", "")}>
                                    {item}
                                  </MenuItem>
                              );
                            })}
                          </Select>
                          {errorHandlerFormik(errors.country, touched.country)}
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                              variant="outlined"
                              displayEmpty
                              fullWidth
                              className="select-input selectCityCENomniation"
                              name="city"
                              value={values.city}
                              onChange={(e)=> this.handleChangeCity(e,setFieldValue,values.country)}
                              style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select City")}
                            </MenuItem>
                            {this.state.cityList.map((item: any, key: any) => {
                              return (
                                  <MenuItem key={key} value={item.city} className={languageCondition(language, "select-arabic-menu", "")}>
                                    {item.city}
                                  </MenuItem>
                              );
                            })}
                          </Select>
                            {errorHandlerFormik(errors.city, touched.city)}
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                              variant="outlined"
                              displayEmpty
                              fullWidth
                              className="select-input selectBuildingCENomniation"
                              value={values.complex}
                              name="complex"
                              onChange={handleChange}
                              style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select Complex")}
                            </MenuItem>
                            {this.state.buildingList.map((item: any, key: any) => {
                              return (
                                  <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                    {item.attributes?.name}
                                  </MenuItem>
                              );
                            })}
                          </Select>
                            {errorHandlerFormik(errors.complex, touched.complex)}
                        </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                      <TextField
                          label={t("Title")}
                          className={languageCondition(language,"placeHolderAlignmentSpace","titleCENomination")}
                          variant="outlined"
                          id="nominationDate"
                          name="title"
                          onChange={handleChange}
                          value={values.title}
                          fullWidth inputProps={{ maxLength: 50 }}
                          style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      />
                        {errorHandlerFormik(errors.title, touched.title)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                          label={t("Start Date")} variant="outlined"
                          className="startDateCENomination"
                          placeholder={t("Start Date")}
                          style={{borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                          type={this.state.startDateType} fullWidth
                          onFocus={()=> this.setState({startDateType:"date"})}
                          id="nominationDate"
                          format='DD/MM/YYYY'
                          value={values.startDate}
                          name="startDate"
                          onChange={handleChange}
                          inputProps={{
                            min: moment().format("YYYY-MM-DD"),
                            max: "5000-05-31",
                          }}
                          InputProps={{
                            //@ts-ignore
                            max: "5000-05-31",
                            startAdornment: (
                                <InputAdornment position="start">
                                  <DateRangeOutlinedIcon  style={{color:"7a7878"}}/>
                                </InputAdornment>
                            ),
                          } }
                      />
                        {errorHandlerFormik(errors.startDate, touched.startDate)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField label={t("End Date")} variant="outlined"
                                 type={this.state.endDateType} fullWidth
                                 className="endDateCENomination"
                                 placeholder={t("End Date")}
                                 style={{borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                 onFocus={()=> this.setState({endDateType:"date"})}
                                 value={values.endDate}
                                 name="endDate"
                                 onChange={handleChange}
                                 inputProps={{
                                   min: values.startDate,
                                   max: "5000-05-31",
                                 }}
                                 id="nominationDate"
                                 InputProps={{
                                   //@ts-ignore
                                   max: "5000-05-31",
                                   startAdornment: (
                                       <InputAdornment position="start">
                                         <DateRangeOutlinedIcon style={{color:"7a7878"}} />
                                       </InputAdornment>
                                   )
                                 }}
                      />
                        {errorHandlerFormik(errors.endDate, touched.endDate)}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          label={t("Description")}
                          className={languageCondition(language,"placeHolderAlignmentSpace","descriptionCENomination")}
                          variant="outlined"
                          fullWidth
                          multiline
                          value={values.description}
                          name="description"
                          onChange={handleChange}
                          rows={4}
                          inputProps={{ maxLength: 50 }}
                          style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      />
                        {errorHandlerFormik(errors.description, touched.description)}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px", marginTop: "5px" }}
                    >
                      <PublishButton
                          className="cancelNominationCreate"
                          style={{ marginRight: "10px", height: "40px" }}
                          onClick={() => this.props.handleClose()}>
                        {t("Cancel")}
                      </PublishButton>
                      <CloseButton type="submit" style={{ height: "40px",marginRight: "10px", }} className="nominationCreate">
                        {t("Add")}
                      </CloseButton>
                    </Grid>
                  </Grid>
                </Form>
            )}
          </Formik>
            <Dialog
                fullWidth
                onClose={() => this.setState({confirmModal:false})}
                open={this.state.confirmModal}
                className="cancel-meeting-dialog confirmNominationDailog"
            >
                <DialogContent style={{margin: "15px 0"}}>
                    <Box textAlign="center" style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <img className="comment-image" src={warningIcon} alt="check"/>
                        <Typography variant="h6" className="bold-text">{t("Warning")}</Typography>
                        <Typography variant="body1" style={{marginBottom: "15px",width:"80%"}}>
                            {t("Selecting confirm will send a massage to all building/complex owners asking them to nominate themselves for the new election either as Chairman or a Vice Chairman. Are you sure you want to continue ?")}?
                        </Typography>
                        <DialogActions className="dialog-button-group">
                            <Button className="cancel-button" style={{width: "200px", height: "55px"}} onClick={()=> this.setState({confirmModal:false})}>
                                {t("Cancel")}
                            </Button>
                            <Button style={{width: "200px", height: "55px"}} className="add-button" onClick={()=> this.createNominationAPICall(this.state.nominationDetails)}>
                                {t("Confirm")}
                            </Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(withRouter(CECreateNomination));

// Customizable Area End