import React from "react";
//components
import {Box, Button, Card, CardActions, CardContent, Dialog, DialogTitle, Grid, Typography,} from "@material-ui/core";
//resources
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedController, {Props} from "./ClassifiedController.web";
//Customizable Area Start
//resorces
import {Building1, Close_Icon, Tenant_Logo, User_Icon} from "../src/assets";
import {CalendarIcon, callIcon, emailIcon} from "../../invitefriends/src/assets";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class ClassifiedDetails extends ClassifiedController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    //@ts-ignore
    this.getClassifiedDetailsById(this.props.history.location.id);
  }

  render() {
    //@ts-ignore
    const { t,i18n} = this.props;
    const language = i18n.language;
    const attributes = this.state.getClassifiedDetails?.attributes;
    const classified_type = this.state.getClassifiedDetails?.attributes.classified_type
    const checkClassifiedType = () => {
        if (classified_type === "buyer") {
            return "Buyers Details"
        } else if (classified_type === "seller") {
            return "Sellers Details"
        } else {
            return "Generic Details"
        }
    }
    return (
      <>
        <Box className="login-wrapper incident-wrapper" dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box>
                <Box className="content-header">
                  <Box className="left-block blocks">
                    <ArrowBackIcon data-test-id="arrowBack" onClick={() => window.history.back()} style={{fontSize:"20px"}} />
                    <h4 style={{display: "flex",fontSize:"18px"}} className="bold-text">{t("Classified")}</h4>
                  </Box>
                </Box>
                <Box className="common-incident-block" style={{padding:"0px 15px"}}>
                  <Box className="incident-content-wrapper">
                    <Box className="incident-rows mt-15">
                      <h4 style={{fontSize:"18px"}} className="bold-text">{t("Classified Details")}</h4>
                    </Box>
                    <Card className="incident-card card">
                      <CardContent>
                        <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                          {t("Title")}:
                        </Typography>
                        <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                          {attributes?.title}
                        </Typography>
                        <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                          {t("Description:")}
                        </Typography>
                        <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                          {attributes?.description}
                        </Typography>
                        <DescriptionManage attributes={attributes} t={t} language={language}/>
                        <Typography component="span" style={{fontSize:"14px"}}>
                          {t("Duration")}:
                        </Typography>
                        <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}} >
                          {t("From")} {attributes?.duration_from} {t("to")} {attributes?.duration_to}
                        </Typography>
                        {
                          attributes?.attachments.length !== 0 &&
                            <>
                              <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                                {t("Photos")}
                              </Typography>
                              <CardActions className="card-img-row">
                                {
                                  attributes?.attachments?.map((val: any, index: any) => (
                                    val.content_type === "video/mp4" || val.content_type === "video/x-m4v" ?
                                      <Box id="custom-dialog" className="video-img TestVideoCheck1" data-test-id="showdata" key={index} onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val.content_type, name: val.file_name } }) }}>
                                        <Box className="img-layer"></Box>
                                        <video className="incident-dialog-video"  >
                                          <source src={val.url} type={val.file.type} />
                                        </video>
                                        <PlayCircleOutlineIcon className="play-icon" />
                                      </Box>
                                      :
                                      <Box data-test-id="showDialog" className="video-img TestVideoCheck" key={index} onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val.content_type, name: val.file_name } }) }}>
                                        <Box className="img-layer"></Box>
                                        <img src={val.url} className="card-img" alt="card-img" />
                                        <FullscreenIcon className="play-icon" />
                                      </Box>
                                  ))
                                }
                              </CardActions>
                              <hr />
                            </>
                        }
                      </CardContent>
                    </Card>
                    <Box className="incident-rows mt-20">
                      {checkClassifiedType()}
                    </Box>
                    <Card className="incident-card reporting-card card">
                      <CardContent>
                        <Box className="reporting-row">
                          <img src={User_Icon.default} className="icons" alt="" style={{height:"25px"}} />
                          <Box className="reporting-right-block">
                            <h5>{t("Published by:")}</h5>
                            <h4 className="title classifiedDetailFullName bold-text" style={{padding:"0px",fontSize:"16px"}}>{attributes?.published_by?.full_name}</h4>
                          </Box>
                        </Box>
                        <Box className="reporting-row">
                          <img src={callIcon.default} className="icons" alt="" style={{height:"25px"}} />
                          <Box className="reporting-right-block">
                            <h5>{t("Phone Number:")}</h5>
                            <h4 className="title classifiedDetailPhoneNo bold-text" style={{padding:"0px"}}>{attributes?.published_by?.full_phone_number}</h4>
                          </Box>
                        </Box>
                        <Box className="reporting-row">
                          <img src={CalendarIcon.default} className="icons" alt="" style={{height:"25px"}} />
                          <Box className="reporting-right-block">
                            <h5>{t("Published On:")}</h5>
                            <h4 className="title  classifiedDetailPublishedOn bold-text" style={{padding:"0px"}}>{moment(attributes?.published_on).format("DD MMM, YYYY")}</h4>
                          </Box>
                        </Box>
                        <Box className="reporting-row">
                          <img src={emailIcon.default} className="icons" alt="" style={{height:"25px"}} />
                          <Box className="reporting-right-block">
                            <h5>{t("Email Address:")}</h5>
                            <h4 className="title classifiedDetailsPublishedBy bold-text" style={{padding:"0px"}}>{attributes?.published_by?.email || "NA"}</h4>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
                <Box className="bottomBlock common-bottom-padding" display={{ xs: 'none', md: 'flex' }}>
                  <h6 className="bottom-text">{t("POWERED BY")}</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo" alt="" />
                </Box>
              </Box>
            </Grid>
            {/* desktop footer block */}
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
          <Dialog
            open={this.state.showDialog}
            data-test-id="showDialog"
            onClose={() => this.setState({ showDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={{
              style: {
                borderRadius: '15px',
              },
            }}
          >
            <Box className="diloag-body">
              <Box className="diloag-header">
                <DialogTitle className="alert-dialog-title" id="alert-dialog-title" style={{fontSize:"16px"}}>
                  {this.state.file.name}
                </DialogTitle>
                <Button className="closeIcon" onClick={() => { this.setState({ showDialog: false }) }}>
                  <img src={Close_Icon} className="close-icon closeIconbtn" onClick={() => { this.setState({ showDialog: false }) }} style={{width:"20px",height:"20px"}} />
                </Button>
              </Box>
              <Box className="diloag-content-body">
                {
                  this.state.file.type === "video/mp4" || this.state.file.type === "video/x-m4v" ?
                    <video className="incident-dialog-video" controls >
                      <source src={this.state?.file?.url} type={this.state?.file?.type} />
                    </video>
                    :
                    <Box>
                      <img src={this.state.file.url} className="incident-dialog-photo" alt="card-img" />
                    </Box>
                }
              </Box>
            </Box>
          </Dialog>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

export default withTranslation()(withRouter(ClassifiedDetails));

const DescriptionManage = (props:any) => {
  const {attributes,t,language} = props
  return(
      <>
        {
          attributes?.price ?
              <>
                <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                  {t("Price")}:
                </Typography>
                <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                  {attributes?.price} {languageCondition(language,attributes?.currency?.currency_ar,attributes?.currency?.currency)}
                </Typography>
              </>
              : null
        }
        {
          attributes?.payment_detail ?
              <>
                <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                  {t("Payment Detail")}:
                </Typography>
                <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                  {attributes?.payment_detail} {languageCondition(language,attributes?.currency?.currency_ar,attributes?.currency?.currency)}
                </Typography>
              </>
              : null
        }

        {
          attributes?.price_from && attributes?.price_to ?
              <>
                <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                  {t("Price")}:
                </Typography>
                <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                  ${attributes?.price_from} {languageCondition(language,attributes?.currency?.currency_ar,attributes?.currency?.currency)} - {attributes?.price_to} {languageCondition(language,attributes?.currency?.currency_ar,attributes?.currency?.currency)}
                </Typography>
              </>
              : null
        }

        {
          attributes?.time_from && attributes?.time_to ?
              <Box className="card-rows">
                <Typography className="title-span" component="span" style={{fontSize:"14px"}}>
                  {t("Time")}:
                </Typography>
                <Typography className="sub-title" style={{fontSize:"17px",marginBottom:"5px"}}>
                  {t("From")} {attributes?.time_from} {t("to")} {attributes?.time_to}
                </Typography>
              </Box>
              :
              null
        }
      </>
  )
}

//Customizable Area End