import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Container,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Divider,
  Link,
} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import Pagination from "@material-ui/lab/Pagination";
import { withRouter } from "react-router-dom";
import SentInvitationController, { Props } from "./SentInvitationController";
import { withTranslation } from "react-i18next";
import { CommunityStyle } from "./CommunityStyle.web";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class AdminSentInvitation extends SentInvitationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getInvitation();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.dataSearch !== this.state.dataSearch) {
      await this.getInvitation();
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <GeneralHeaderComponentWeb>
          <Container className={classes.communityDashboard}>
            <Box className="navigation" style={{ flexDirection: "column", gap: "10px", alignItems: "flex-start" }}>
              <Box className="admin-sent-heading-box">
                <Typography variant="body1">
                  {t("Community Management")} /{" "}
                  <Link href="/CommunityRequestManagement">{t("Request Management")}</Link> /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Total Sent Invitations")}
                  </Box>
                </Typography>
              </Box>
              <Box className="sub-heading-box admin-sent-heading">
                <Typography variant="h5" className="bold-text">
                  {t("Total Sent Invitations")}
                </Typography>
              </Box>
            </Box>

            <Box className="suggestion-table invite-user-table">
              <Box className="top-content admin-sent-table-header">
                <Box className="heading">
                  <h2 className="bold-text">{t("Invitations")}</h2>
                </Box>
                <Box className="right-content admin-sent-search-box">
                  <TextField
                    onChange={(e) => this.setState({ dataSearch: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={this.state.dataSearch}
                    placeholder={t("Search")}
                    className="search-unit"
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer>
                <Table className="unit-table admin-sent-invitation-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell
                        align={languageCondition(language, "right", "left")}
                        className="bold-text admin-sent-table-column"
                      >
                        {t("Name")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Unit Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Invitation sent on")}
                      </TableCell>
                      <TableCell
                        align={languageCondition(language, "right", "left")}
                        className="bold-text admin-sent-table-column"
                      >
                        {t("Phone Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Email Address")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.allInvitation.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8}>{t("No invitation found")}</TableCell>
                      </TableRow>
                    )}
                    {this.state.allInvitation.map((invitation: any, idx: number) => {
                      return (
                        <TableRow key={invitation.id}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {invitation.attributes.full_name}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {invitation.attributes?.apartment_management?.apartment_name || "-"}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {moment(invitation.attributes.created_at).format("MMMM DD, YYYY")}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {invitation.attributes.phone_number}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {invitation.attributes.email_address}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={languageCondition(language, "arabic-sent-menu", "delete-request-menu")}
                          >
                            <Menu menuButton={<MoreVertIcon />}>
                              <MenuItem
                                className="admin-sent-delete-menu"
                                onClick={() => {
                                  this.setState({ loading: false }, () => {
                                    this.deleteUserRequest(invitation.id);
                                  });
                                }}
                              >
                                {t("Delete Request")}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className="unit-pagination admin-sent-pagination">
                <p className="admin-sent-pagination-text">
                  {t("Showing")} <span>{this.state.allInvitation.length}</span> {t("of")}{" "}
                  <span>{this.state.pagination ? this.state.pagination.total_count : 0}</span> {t("results")}
                </p>
                {this.state.pagination && (
                  <Pagination
                    variant="outlined"
                    onChange={(event: any, value: any) => {
                      const pageValue = Number(value);
                      if (pageValue !== this.state.page) {
                        this.setState({ page: pageValue }, () => {
                          this.getInvitation();
                        });
                      }
                    }}
                    siblingCount={2}
                    count={this.state.pagination.total_pages}
                    shape="rounded"
                    page={this.state.pagination.current_page}
                  />
                )}
              </Box>
            </Box>
          </Container>
        </GeneralHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withTranslation()(withStyles(CommunityStyle)(withRouter(AdminSentInvitation)));
