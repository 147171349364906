//Customizable area start
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import React from "react";
export const image_realEstateCompany = require("../../assets/pollsurvey.png");
import { buildings, calanderIcon, userIcon } from "../assets";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";
import moment from "moment";

export interface Props {
  navigation?: any;
  id?: string;
  dataFromComponent1?: any;
  userId?: any;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

export interface PendingTaskData {
  id: string;
  title: string;
  description: string;
  company_name: string;
  complex: string;
  building: string;
  assigned_by: string;
  assigned_on: string;
  due_on: string;
}

interface UserData {
  name: string;
  complexBuildingName: string;
}

interface S {
  userId: string | number;
  employeeId: any;

  data: any;
  number: number;
  userData: UserData;
  dataId: string | number;
  mapPoints: any;
  mapPointClick: number;
  value: any;
  empData: any;
  menuopen: any;
  menufield: any;
  menufield2: any;
  key: any;
  rowsPerPage: any;
  page: any;
  menuopen2: any;
  dialogopen: any;
  ChangeRPopen: any;
  Deleteopen: any;
  Revokeopen: any;
  NotDelete: any;
  assigneeOpen: boolean;
  editTeamMemberOpen: boolean;
  mapSwitch: boolean;
  searchText: string;
  pagination: {
    current_page: number;
    total_count: number;
    total_pages: number;
  };
  pendingTaskData: PendingTaskData[];
  assignedCompanies: string[];
  role: string;
  openCompanyPopUp: boolean;
  companyPopup: any;
}

interface SS {
  id: any;
}

export default class CETeamMemberTableController extends CommonApiCallForBlockComponent<Props, S, SS> {
  LoadDataCallID: any;
  PostCallId: any;
  ComplexCallId: any;
  IndividualBuildingCallId: any;
  PendingTasksCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      userId: "",
      employeeId: "",

      data: {},
      searchText: "",
      userData: {
        name: "",
        complexBuildingName: "",
      },
      number: 1,
      value: 0,
      dataId: "",
      rowsPerPage: 12,
      mapPoints: [],
      mapPointClick: 0,
      page: 0,
      menuopen: false,
      menuopen2: false,
      dialogopen: false,
      menufield: "",
      menufield2: "",
      key: 0,
      ChangeRPopen: false,
      Deleteopen: false,
      Revokeopen: false,
      NotDelete: false,
      empData: [],
      assigneeOpen: false,
      editTeamMemberOpen: false,
      mapSwitch: false,
      pagination: {
        current_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      assignedCompanies: [],
      pendingTaskData: [],
      role: "",
      openCompanyPopUp: false,
      companyPopup: "",
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    const user_id = this.props.navigation.getParam("id");
    const userRole = localStorage.getItem("role") ?? "";
    this.setState({ userId: user_id, role: userRole }, () => {
      this.getUserData(user_id);
    });
  }

  ref: any;

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      (prevState.data !== this.state.data ||
        prevState.searchText !== this.state.searchText ||
        prevState.value !== this.state.value ||
        prevState.page !== this.state.page) &&
      this.state.data.attributes
    ) {
      if (this.state.value === 0) {
        await this.getComplexData("");
      } else if (this.state.value === 1) {
        await this.getIndividualBuildingData();
      }
      this.getPendingTasksData();
    }

    if (prevState.mapSwitch !== this.state.mapSwitch || prevState.value !== this.state.value) {
      this.updateMapData();
    }
  }

  showingRoleList: any = {
    account_manager: "Account Manager",
    sales_manager: "Sales Manager",
    coo: "Coo",
  };

  getNameFieldLabel = () => (this.state.value === 0 ? "Complex Id" : "Building Id");

  getNameLabel = (complex: any) => (this.state.value === 0 ? complex.complex_name : complex.building_name);

  handleOpen = (e: any, index: any, name?: string) => {
    if (e !== null && name) {
      this.setState({ userData: { ...this.state.userData, complexBuildingName: name } });
    }
    this.setState({
      menuopen: !this.state.menuopen,
      dataId: index ? index : "",
    });
    this.setState({
      menufield: e !== null ? e.currentTarget : e,
      key: index,
    });
  };

  updateMapData = () => {
    if (this.state.mapSwitch) {
      const locationObj: any[] = [];
      this.state.empData.forEach((complex: any) => {
        if (complex.lat && complex.long) {
          locationObj.push({
            lat: complex.lat,
            lng: complex.long,
            id: complex.id,
            name: this.getNameLabel(complex),
            data: [
              {
                label: this.getNameFieldLabel(),
                value: complex.id,
              },
              {
                label: "Is Manager",
                value: complex.is_manager,
              },
              {
                label: "Company Name",
                value: complex.company_name,
              },
              {
                label: "Chairman",
                value: complex.chairman,
              },
              {
                label: "Total Unit",
                value: complex.total_units,
              },
            ],
          });
        }
      });
      this.setState({ mapPoints: locationObj });
    }
  };

  handleOpen2 = (e: any) => {
    this.setState({
      menuopen2: !this.state.menuopen2,
    });
    this.setState({
      menufield2: e.currentTarget ? e.currentTarget : e,
    });
  };
  handleChangePage = (e: any, newPage: any) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  handleView = (mapSwitch: boolean) => this.setState({ mapSwitch });

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.LoadDataCallID) {
          if (!responseJson.errors) {
            this.handleUserData(responseJson);
          } else if (
            responseJson.errors[0].failed_login === "Account Not Found or Not Activated" ||
            responseJson.errors[0].failed_login === "Invalid Password or email"
          ) {
            this.errorModal(responseJson.errors[0].failed_login);
          }
        }

        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.ComplexCallId) {
      this.handleComplexData(responseJson);
    }

    if (apiRequestCallId === this.IndividualBuildingCallId) {
      this.handleIndividualBuildingData(responseJson);
    }

    if (apiRequestCallId === this.PendingTasksCallId) {
      this.handlePendingTasksData(responseJson);
    }
  };

  handleModalClose = () => {
    this.setState({ dialogopen: false });
  };
  handleModalChangeRP = () => {
    this.setState({ ChangeRPopen: false });
  };
  handleDelete = () => {
    this.setState({ Deleteopen: false });
  };
  handleNotDelete = () => {
    this.setState({ NotDelete: false });
  };
  handleRevoke = () => {
    this.setState({ Revokeopen: false });
  };

  openCompPopup = (target: any) => {
    this.setState({ openCompanyPopUp: !this.state.openCompanyPopUp, companyPopup: target.currentTarget });
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, isAssignedCompanies?: boolean) => {
    const { value } = event.target;
    if (isAssignedCompanies) {
      if (this.state.data.attributes) {
        const filteredData =
          this.state.data.attributes.assign_companies.length > 0
            ? this.state.data.attributes.assign_companies.filter((item: any) =>
                item[1].toLowerCase().includes(value.toLowerCase())
              )
            : [];
        this.setState({ assignedCompanies: filteredData });
      }
    } else {
      this.setState({ searchText: value });
    }
  };

  handleAssignModal = () => {
    this.setState({ assigneeOpen: !this.state.assigneeOpen, menuopen2: false });
    if (this.state.data && this.state.data.id) {
      this.getUserData(this.state.data.id);
    }
  };

  handleEditTeamdetialModal = () => {
    this.setState({ editTeamMemberOpen: !this.state.editTeamMemberOpen });
    if (this.state.data && this.state.data.id) {
      this.getUserData(this.state.data.id);
    }
  };

  paginationLayout = ({ from, to, count }: { from: number; to: number; count: number }) => {
    return `${from}-${to} of ${count}`;
  };

  pendingTaskContent = (taskDetail: PendingTaskData) => {
    return [
      { name: "Real Estate Company", value: taskDetail.company_name, img: image_realEstateCompany },
      { name: "Complex", value: taskDetail.complex, img: buildings },
      { name: "Building", value: taskDetail.building, img: buildings },
      { name: "Assigned by", value: taskDetail.assigned_by, img: userIcon },
      { name: "Assigned_on", value: taskDetail.assigned_on, img: calanderIcon },
      { name: "Due_on", value: taskDetail.due_on, img: calanderIcon },
    ];
  };

  getUserData = async (id: string) => {
    this.LoadDataCallID = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/${id}`,
    });
  };

  getQueryForIndividual = (isIndividual: boolean) => {
    const text = this.state.searchText.trim();
    if (text === "") {
      return "";
    } else if (Number.isNaN(Number(text))) {
      return `&name=${text}`;
    } else {
      return `&${isIndividual ? "building_id" : "complex_id"}=${text}`;
    }
  };

  getPageQuery = () => (this.state.page ? `&page=${this.state.page}&per_page=10` : "");

  getIndividualBuildingData = async () => {
    const { employeeId, userId } = this.state;
    this.IndividualBuildingCallId = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/employee_individual_building?employee_team_member_id=${userId}&employee_account_id=${employeeId}`,
    });
  };

  getComplexData = async (complexName: any) => {
    const { employeeId, userId } = this.state;
    this.ComplexCallId = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/employee_complex?employee_team_member_id=${userId}&employee_account_id=${employeeId}&complex_name=${complexName}`,
    });
  };

  getPendingTasksData = async () => {
    const { employeeId, userId } = this.state;
    this.PendingTasksCallId = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/employee_pending_task?employee_team_member_id=${userId}&employee_account_id=${employeeId}`,
    });
  };

  generateProfileData = (name: string) => {
    if (this.state.data && this.state.data.attributes && this.state.data.attributes[name]) {
      return this.state.data.attributes[name];
    } else {
      return "NA";
    }
  };

  checkOjbesIsAssigned = () => {
    if (this.state.data && this.state.data.attributes) {
      if (
        this.state.data.attributes.real_estate_company > 0 ||
        this.state.data.attributes.society_management > 0 ||
        this.state.data.attributes.building_management > 0
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  handleUserData = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState(
        {
          data: responseJson.data,
          userData: { ...this.state.userData, name: responseJson.data?.attributes?.employee_name },
          assignedCompanies: responseJson.data.attributes ? responseJson.data.attributes.assign_companies : [],
          employeeId: responseJson.data.attributes.employee_account_id,
        },
        () => {
          this.getPendingTasksData();
        }
      );
    }
  };

  handleComplexData = (responseJson: any) => {
    if (!Array.isArray(responseJson.complex) && responseJson.complex.data.length > 0) {
      const data = responseJson.complex.data;
      const meta = responseJson.meta.pagination;
      const complexData = data.map((item: any) => {
        const { attributes } = item;
        return {
          id: item.id,
          complex_name: attributes.name,
          is_manager: attributes.is_manager,
          company_name: attributes.company_name,
          chairman: attributes.chairman,
          total_units: attributes.totle_unit,
          lat: attributes.latitute,
          long: attributes.longitute,
          subscription_end_in: attributes.subscirtion_end_in.subscription_end_date,
        };
      });
      this.setState({
        empData: [...complexData],
        pagination: {
          current_page: meta.current_page,
          total_count: meta.total_count,
          total_pages: meta.total_pages,
        },
      });
    } else {
      this.setState({
        empData: [],
        pagination: {
          current_page: 1,
          total_count: 1,
          total_pages: 1,
        },
      });
    }
  };

  handleIndividualBuildingData = (responseJson: any) => {
    if (!Array.isArray(responseJson.complex) && responseJson.complex.data.length > 0) {
      const data = responseJson.complex.data;
      const meta = responseJson.meta.pagination;
      const buildingData = data.map((item: any) => {
        const { attributes } = item;
        return {
          id: item.id,
          building_name: attributes.name,
          is_manager: attributes.is_manager,
          company_name: attributes.company_name,
          chairman: attributes.chairman,
          total_units: attributes.totle_unit,
          lat: attributes.latitute,
          long: attributes.longitute,
          subscription_end_in: attributes.subscirtion_end_in.subscription_end_date,
        };
      });
      this.setState({
        empData: [...buildingData],
        pagination: {
          current_page: meta.current_page,
          total_count: meta.total_count,
          total_pages: meta.total_pages,
        },
      });
    } else {
      this.setState({
        empData: [],
        pagination: {
          current_page: 1,
          total_count: 1,
          total_pages: 1,
        },
      });
    }
  };

  formattedDate = (date: string) => moment(date, "DD-MM-YYYY").format("MMMM DD, YYYY");

  handleData = (data: string) => (data && data !== null ? data : "");

  handlePendingTasksData = (responseJson: any) => {
    if (responseJson.data.length > 0) {
      const pendingTaskData = responseJson.data.map((data: any) => {
        const attributes = data.attributes;
        const assignOnDate = this.formattedDate(attributes.assign_on);
        const dueDate = this.formattedDate(attributes.due_till);
        return {
          id: this.handleData(data.id),
          title: this.handleData(attributes.title),
          description: this.handleData(attributes.description),
          company_name: this.handleData(attributes.real_estate_company?.company_name),
          complex: this.handleData(attributes?.society_management?.name),
          building: this.handleData(attributes?.building_management?.name),
          assigned_by: this.handleData(attributes.assign_by),
          assigned_on: this.handleData(assignOnDate),
          due_on: this.handleData(dueDate),
        };
      });
      this.setState({ pendingTaskData: pendingTaskData });
    } else {
      this.setState({ pendingTaskData: [] });
    }
  };

  handleMapPointClick = (key: any) => {
    this.setState({ mapPointClick: key });
  };

  toggleMenu() {
    this.setState((prevState: any) => ({
      menuopen2: !prevState.menuopen2,
    }));
  }

  handleProfileName = (value: any) => {
    if (value !== "NA") {
      return (
        this.generateProfileData("reporting_person").first_name +
        " " +
        this.generateProfileData("reporting_person").last_name
      );
    } else {
      return "NA";
    }
  };

  handleTaskValue = (item: any) => {
    if (item.value) {
      return item.value;
    } else {
      return "";
    }
  };

  handleComplexSearch = (e: any) => {
    this.getComplexData(e.target.value);
  };
}
// Customizable Area End
