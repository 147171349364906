// Customizable Area Start
import React from "react";
import { Button, Container, Link, withStyles, Box, Grid, IconButton } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeSharpIcon from "@material-ui/icons/NavigateBeforeSharp";
import FaqOwnerController, { Props } from "./FaqOwnerController.web";
import { FaqChairmanStyleWeb } from "./FaqChairmanStyle.web";
import { withTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import Lightbox from "react-image-lightbox";
// @ts-ignore
import Slider from "react-slick";
// @ts-ignore
import { baseURL } from "../../../framework/src/config";

export const settings = {
  swipeToSlide: true,
  slidesToShow: 3,
  infinite: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
};

class FaqOwner extends FaqOwnerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n?.language;

    return (
      <>
        <Box
          style={{ background: "#F7F9FE", height: "100vh" }}
          className={classes.faqOwner}
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <Grid container className="faq-owner-container">
            <Grid item xs={12} md={7}>
              <Box className="faq-step faq-owner-content-box">
                {this.state.faqStep === 1 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon faq-owner-head-box">
                      <Link href="/FaqComplexList">
                        <IconButton>
                          <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                        </IconButton>
                      </Link>
                      <span className="bold-text heading">{t("FAQs")}</span>
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        {this.state.catagoriesList.map((category: any) => {
                          return (
                            <div
                              key={category.id}
                              className="faq-item owner-faq-box"
                              onClick={() => {
                                this.setState(
                                  {
                                    ...this.state,
                                    faq: category.attributes.name,
                                    faqList: category.attributes.FAQ.data,
                                  },
                                  () => {
                                    this.changeFaqState(2);
                                  }
                                );
                              }}
                            >
                              <p className="bold-text">{category.attributes.name}</p>
                              {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                            </div>
                          );
                        })}
                      </Box>
                    </Container>
                  </>
                )}
                {this.state.faqStep === 2 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon faq-owner-step-head">
                      <IconButton onClick={() => this.changeFaqState(1)}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      <span className="bold-text heading">
                        {this.state.faq}
                        {t("'s FAQs")}
                      </span>
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        {this.state.faqList.map((faq: any) => {
                          return (
                            <div
                              key={faq.id}
                              className="faq-item"
                              onClick={() => {
                                this.setState(
                                  {
                                    ...this.state,
                                    question: faq.attributes.title,
                                    answer: faq.attributes.content,
                                    images: faq.attributes.images,
                                  },
                                  () => {
                                    this.changeFaqState(3);
                                  }
                                );
                              }}
                            >
                              <p className="bold-text">{faq.attributes.title}</p>
                              {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                            </div>
                          );
                        })}
                      </Box>
                    </Container>
                  </>
                )}
                {this.state.faqStep === 3 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon faq-owner-content-head">
                      <IconButton onClick={() => this.changeFaqState(2)}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      <span className="bold-text heading">{this.state.faq}'s FAQs</span>
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        <div className="faq-ans">
                          <span className="bold-text">{this.state.question}</span>
                          <p className="faq-owner-ans-box">{this.state.answer}</p>
                          <Box className="slicker-images-box">
                            <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                              {this.state.images.map((image: any, index: number) => {
                                return (
                                  <div className="testId-image" onClick={() => this.setState({ imageBox: true, photoIndex: index })}>
                                    <img src={`${baseURL}${image.url}`} alt="" />
                                  </div>
                                );
                              })}
                            </Slider>
                          </Box>
                        </div>

                        {this.state.imageBox && this.state.images.length > 0 && (
                          <Lightbox
                            imagePadding={120}
                            mainSrc={`${baseURL}${this.state.images[this.state.photoIndex].url}`}
                            nextSrc={`${baseURL}${
                              this.state.images[(this.state.photoIndex + 1) % this.state.images.length].url
                            }`}
                            prevSrc={`${baseURL}${
                              this.state.images[
                                (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length
                              ].url
                            }`}
                            onCloseRequest={() => this.setState({ imageBox: false })}
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex:
                                  (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                              })
                            }
                          />
                        )}

                        <Button
                          className="faq-owner-close-btn"
                          fullWidth
                          variant="contained"
                          disableElevation
                          onClick={() => this.changeFaqState(2)}
                        >
                          {t("Close")}
                        </Button>
                      </Box>
                    </Container>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(FaqChairmanStyleWeb)(FaqOwner));
// Customizable Area End
