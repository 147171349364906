export const countryListMock ={
    "data": {
        "countries": [
            "India",
            "india",
            "Distinctio Est aut ",
            "United Arab Emirate",
            "Saudia Arabia",
            "United Arab Emirates",
            "UAE"
        ]
    }
}

export const cityListMock ={
    "data": {
        "cities": [
            "Indore",
            "bhopal",
            "Surat",
            "Chattisgarh",
            "Babara",
            "Hyderabad",
            "Visakhapatnam",
            "Ratlam",
            "Ahmedabad"
        ]
    }
}

export const complexListMock ={
    "data": [
        {
            "id": "28",
            "type": "society_management",
            "attributes": {
                "name": "The Urban Haven",
                "description": "",
                "complex_area": "70000",
                "measurement_unit": "Sqft",
                "maintenance_per_square_feet": 5000,
                "is_building": true,
                "logo": null,
                "photos": [],
                "is_building_list": [
                    {
                        "id": 39,
                        "name": "The Urban Haven",
                        "society_management_id": 28,
                        "description": null,
                        "created_at": "2023-08-08T04:08:51.655Z",
                        "updated_at": "2023-08-08T04:08:51.655Z",
                        "per_floor_unit": 2,
                        "generation_methods": "A-101, A-102, A-103",
                        "number_of_floor": 2,
                        "building_area": "70000",
                        "account_id": null,
                        "lat": null,
                        "long": null,
                        "city": null
                    }
                ],
                "building_list": [
                    {
                        "building_management_id": 39,
                        "building_name": "The Urban Haven"
                    }
                ],
                "shared_area": [],
                "documents": [],
                "total_buildings": 1,
                "total_units": [
                    4
                ],
                "country": "India",
                "city": "Ahmedabad",
                "lat": 17.4524903,
                "long": 78.3654961,
                "complex_lat": null,
                "complex_long": null
            }
        }
    ]
}

export const buildingListMock = {
    data:{
        "buildings": [{
            "id": 39,
            "name": "The Urban Haven",
        }]
    }
}

export const unitListMock = {
    "data": {
        "unit_apartments": [
            {
                "id": 6226,
                "society_management_id": 28,
                "building_management_id": 39,
                "created_at": "2023-08-08T06:36:35.287Z",
                "updated_at": "2023-08-08T06:36:35.287Z",
                "apartment_name": "109",
                "floor_number": null,
                "size": null,
                "purchase_price": null,
                "configuration": null,
                "purchase_date": null,
                "unit_type": null,
                "status": "No-Own",
                "current_valuation": null,
                "monthly_renting_income": null,
                "account_id": null
            }
        ]
    }
}

export const dummyVal1 = {
    "value": "test",
    "label": "test",
    "lat": 17,
    "long": 78,
    "is_building": true,
    "is_building_list": [
        {
            "id": "test",
            "name": "",
            "society_management_id": 28,
            "description": null,
            "created_at": "2023-08-08T04:08:51.655Z",
            "updated_at": "2023-08-08T04:08:51.655Z",
            "per_floor_unit": 2,
            "generation_methods": "A-101, A-102, A-103",
            "number_of_floor": 2,
            "building_area": "70000",
            "account_id": null,
            "lat": null,
            "long": null,
            "city": null
        }
    ]
}

export const dummyVal2 = {
    "value": "test",
    "label": "test",
    "lat": 17.4524903,
    "long": 78.3654961,
    "is_building": false,
    "is_building_list": [
        {
            "id": "test",
            "name": "The Urban Haven",
            "society_management_id": 28,
            "description": null,
            "created_at": "2023-08-08T04:08:51.655Z",
            "updated_at": "2023-08-08T04:08:51.655Z",
            "per_floor_unit": 2,
            "generation_methods": "A-101, A-102, A-103",
            "number_of_floor": 2,
            "building_area": "70000",
            "account_id": null,
            "lat": null,
            "long": null,
            "city": null
        }
    ]
}