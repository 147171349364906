// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  withStyles,
  Link,
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import Box from '@material-ui/core/Box';
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityManagerContorller, { Props } from "./FacilityManagerContorller.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import { withTranslation } from 'react-i18next';
import { FacilityLocation, FacilityCalender, FacilityUser, FacilityBuilding } from "./assets";
import { SearchIconImage } from "../../StoreCredits/src/assets";
import moment from "moment";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";


class ManagerFacilityReservation extends FacilityManagerContorller {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    this.getFacilityReservationListing("", "", "");
    this.getBuildingName();
    this.getUnit()
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const language = i18n.language
    const statusArray = [t("Pending"), t("Upcoming"), t("Completed"), t("Cancelled"), t("Rejected")];

    return (
      <>
        <GeneralHeader>
              <Container className="incident-Listing-wrapper desktop-ui manager-facility-content-box fac-manager">
                <Box className={classes.navigation}>
                  <Box className="manager-facility-heading-title fac-manager">
                    <Typography variant="body1">
                      {t("My Dashboard")} / <Link href="/DashboardGeneral">{t("General Dashboard")}</Link> /<Box component="span" style={{ color: "blue" }}> {t("Facility Reservation")}</Box>
                    </Typography>
                    <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>{t("Facility Reservation")}</Typography>
                  </Box>
                </Box>

                <Box className="sorting-header ">
                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="buildingName"
                        displayEmpty
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        style={{width:"170px"}}
                        onChange={(e) => { this.onChange(e); }}
                        value={this.state.buildingName}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled >
                          {t("Select Building")}
                        </MenuItem>
                        {
                          this.state.buildingNameData.length >= 1 && <MenuItem value=" ">
                            {t("All")}
                          </MenuItem>
                        }
                        {
                          this.state.buildingNameData.map((val: any, index: any) => (
                            <MenuItem
                              key={index}
                              value={val?.id}
                            >
                              {val?.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="status"
                        displayEmpty
                        style={{width:"170px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => { this.onChange(e); }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={this.state.status}
                      >
                        <MenuItem disabled value="">
                          {t("Select Status")}
                        </MenuItem>
                        {
                            statusArray.length > 1 && <MenuItem value=" ">
                                {t("All")}
                            </MenuItem>
                        }
                        {
                          statusArray?.map((val, index) => (
                            <MenuItem
                              key={index}
                              value={val}
                            >
                              {val}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="areaReserve"
                        displayEmpty
                        style={{width:"170px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => { this.onChange(e); }}
                        value={this.state.areaReserve}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem disabled value="">
                          {t("Select Facility")}
                        </MenuItem>
                        {
                            this.state.unitNameData.length > 1 && <MenuItem value=" ">
                              {t("All")}
                            </MenuItem>
                        }
                        {
                          this.state.unitNameData.map((val: any, index: any) => (
                            <MenuItem
                              key={index}
                              value={val?.id}
                            >
                              {val?.attributes?.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box >
                    <Button variant="contained" color="primary" style={{ backgroundColor: "#2b6fed", borderRadius: "8px" }} startIcon={<img src={SearchIconImage} />} onClick={() => this.serachHandle()}>
                      {t("Search")}
                    </Button>
                  </Box>
                </Box>

                <Grid container spacing={2} style={{ marginTop: 15, marginBottom: 15 }}>
                  {
                    this.state?.facilityListing.length === 0 && (
                      <Box style={{ marginLeft: "12px", "width": "100%", background: "white", padding: "10px 15px" }}>
                        {t("No Data Found")}
                      </Box>
                    )
                  }
                  {
                    this.state.facilityListing.map((val: any, index: any) => (
                      <Grid item xs={12} sm={6} lg={6} xl={4} key={index} id="card1" onClick={() => this.getFacilityDetails(val.id)}>
                        <Card className="management-card card" key={index}>
                          <CardContent className="costom-card-content">
                            <Box className="customButton" style={{ marginBottom: "15px" }}>
                              <Button variant="contained" className={val?.attributes?.status === 'Pending' ? "contain warning" : val?.attributes?.status === 'Upcoming' ? 'contain success' : val?.attributes?.status === 'Completed' ? 'contain blue' : "contain danger"} type="submit">
                                {t(val?.attributes?.status)}
                              </Button>
                            </Box>
                            <h4 className="bold-text" style={{ fontSize: "18px", lineHeight: "22px" }}>{t("Facility Reservation")}</h4>
                            <Box className="card-rows" style={{ margin: "15px 0" }}>
                              <img src={FacilityLocation} alt="Bank Icon" style={languageCondition(language,{marginLeft:"10px"},{marginLeft:"0px"})} />
                              <h5 style={{ margin: 0 }}> {val?.attributes?.common_area?.name}</h5>
                            </Box>
                            <Box className="card-rows" style={{ margin: "15px 0" }}>
                              <img src={FacilityCalender} alt="Bank Icon" style={languageCondition(language,{marginLeft:"10px"},{marginLeft:"0px"})}/>
                              <h5 style={{ margin: 0 }}>{moment(val?.attributes?.date, "DD-MMM-YYYY").format("MMM DD, YYYY")} {val?.attributes?.start_time} to {val?.attributes?.end_time}</h5>
                            </Box>
                            <Box className="card-rows" style={{ margin: "15px 0" }}>
                              <img src={FacilityUser} alt="Bank Icon" style={languageCondition(language,{marginLeft:"10px"},{marginLeft:"0px"})}/>
                              <h5 style={{ margin: 0 }}>{val?.attributes?.Owner_name}</h5>
                            </Box>
                            <Box className="card-rows" style={{ margin: "15px 0" }}>
                              <img src={FacilityBuilding} alt="Bank Icon" style={languageCondition(language,{marginLeft:"10px"},{marginLeft:"0px"})} />
                              <h5 style={{ margin: 0 }}>{val?.attributes?.building?.name}</h5>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  }
                </Grid>
              </Container>
        </GeneralHeader>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export const dashBoard: any = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  reservationTitle: {
    fontWeight: "500",
    color: "red",
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};
export default withTranslation()(withStyles(dashBoard)(withRouter(ManagerFacilityReservation)));
// Customizable Area End
