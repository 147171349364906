// Customizable Area Start
import React from 'react';
//components
import {
  Box,
  Card,
  Grid,
  Typography,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import SuggestionController, { Props } from './SuggestionController.web';
import { Building1 } from '../../ContentManagement/src/assets';
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {withTranslation} from "react-i18next";
class ResponseSuggestion extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const data: any = JSON.parse(localStorage.getItem('selectSuggestion')!);
    // @ts-ignore
    const {t} = this.props;
    // @ts-ignore
    const language = this.props.i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper responseSuggestion" style={{ background: "#F8F9FE", overflowY: "hidden" }}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block responseSuggestion" style={{ height: '100%', display: 'block' }} dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header responseSuggestion">
                  <Box className="left-block blocks responseSuggestion" style={{gap:"1rem"}}>
                    <ArrowBackIcon onClick={() => window.history.back()} />
                    <h4 className="bold-text responseSuggestion" style={{ fontSize: "18px" }}>{t("Response")}</h4>
                  </Box>
                  <Box className="incident-right-block blocks">
                  </Box>
                </Box>
                <Box className="content-block-wrapper common-incident-block" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '95%', background: '#F8F9FE' }}>
                  <Box style={{ paddingTop: '12px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography className="sub-title h5-title bold-text" component="h5" style={{ fontWeight: 600, fontSize: "16px" }}>
                        {data?.attributes?.title}
                      </Typography>
                    </Box>
                    <Box style={{ marginTop: "8px" }}>
                      <Typography className="sub-title h5-title" component="h5">
                        {data?.attributes?.description}
                      </Typography>
                    </Box>
                    <Box style={{marginBottom:"50px"}}>
                      {
                        data?.attributes?.response != null &&
                        data?.attributes?.response.data.map((item: any) => <>
                          <Card style={{ marginTop: '1rem', boxShadow: "4px 0px 14px #e9e9e9", borderRadius: "8px" }}>
                            <Box className="response" style={{ padding: "8px 12px" }}>
                              <pre style={{ fontSize: "15px", whiteSpace: 'break-spaces' }}>{item?.attributes?.description}</pre>
                            </Box>
                          </Card>
                        </>)
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(ResponseSuggestion));
// Customizable Area End