import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { ROLE } from "../../../framework/src/Enum";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isScheduledMeetingOpen: boolean;
  isAttendMeetingModalOpen: boolean;

  scheduleMeetingId: string;

  meeting: any;

  response: string;

  scheduleMeetingList: any[];
  minuteMeetingList: any[];

  filter: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyMeetingsController extends BlockComponent<Props, S, SS> {
  GetAllScheduledMeetingsCallId: any;
  GetAllMinuteMeetingsCallId: any;
  CreateMeetingResponseCallId: any;
  UpdateMeetingResponseCallId: any;
  GetMeetingByIdCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      isScheduledMeetingOpen: true,
      isAttendMeetingModalOpen: false,

      scheduleMeetingId: "",

      meeting: null,

      response: "true",

      scheduleMeetingList: [],
      minuteMeetingList: [],

      filter: "scheduled",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetAllScheduledMeetingsCallId:
          this.handleGetScheduleMeetingsResponse(responseJson);
          break;
        case this.GetAllMinuteMeetingsCallId:
          this.handleGetMinuteMeetingResponse(responseJson);
          break;
        case this.GetMeetingByIdCallId:
          this.handleGetMeetingResponse(responseJson);
          break;
        case this.CreateMeetingResponseCallId:
          this.handleCreateMeetingResponse(responseJson);
          break;
        case this.UpdateMeetingResponseCallId:
          this.handleUpdateMeetingResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetScheduleMeetingsResponse = (responseJson: any) => {
    if (responseJson && responseJson.meeting) {
      this.setState({
        ...this.state,
        scheduleMeetingList: responseJson.meeting.data,
      });
    }
  }
  
  handleGetMinuteMeetingResponse = (responseJson: any) => {
    if (responseJson && responseJson.meeting) {
      this.setState({
        ...this.state,
        minuteMeetingList: responseJson.meeting.data,
      });
    }
  }

  handleGetMeetingResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.setState({
        ...this.state,
        meeting: responseJson.meeting.data,
      });
    }
  }

  handleCreateMeetingResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.handleAttendMeetingModal();
      this.getMeetingById();
    }
  }

  handleUpdateMeetingResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.handleAttendMeetingModal();
      this.getMeetingById();
    }
  }

  // Get All Scheduled Meeting API
  getScheduledMeetingList = () => {
    const { filter } = this.state;

    const society_id = localStorage.getItem("society_id");
    let APIEndPoint = `society_managements/${society_id}/bx_block_meeting/meetings/shaduled_meeting`;

    if (filter === "asc" || filter === "desc") {
      APIEndPoint = APIEndPoint + "?sort=" + filter;
    } else if (filter === "accepted" || filter === "rejected") {
      APIEndPoint = APIEndPoint + "?response=" + filter;
    } else if (filter === "scheduled" || filter === "cancelled") {
      APIEndPoint = APIEndPoint + "?status=" + filter;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetAllScheduledMeetingsCallId = apiRequest.messageId;

    // const society_id = localStorage.getItem("society_id");
    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), APIEndPoint);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get All Minute Meeting API
  getMinuteMeetingList = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetAllMinuteMeetingsCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meeting_mins/approved_meeting_mins`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Meeting API
  getMeetingById = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetMeetingByIdCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meetings/${this.state.scheduleMeetingId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Meeting Response API
  createMeetingResponse = () => {
    const body = {
      meeting_response: {
        meeting_id: this.state.scheduleMeetingId,
        status: this.state.response === "true",
      },
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateMeetingResponseCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meeting_responses`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Update Meeting Response API
  updateMeetingResponse = () => {
    const body = {
      meeting_response: {
        meeting_id: this.state.scheduleMeetingId,
        status: this.state.response === "true",
      },
    };

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.UpdateMeetingResponseCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meeting_responses/${this.state.scheduleMeetingId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handel State
  handleScheduledMeetingModal = () => {
    this.setState({
      isScheduledMeetingOpen: !this.state.isScheduledMeetingOpen,
    });
  };

  handleAttendMeetingModal = () => {
    this.setState({
      isAttendMeetingModalOpen: !this.state.isAttendMeetingModalOpen,
    });
  };

  redirectToDashboard = () => {
    const userType = localStorage.getItem("userType");
    if (userType === ROLE.OWNER || userType === ROLE.PROPERTY_MANAGER) {
      this.props.navigation.navigate("OwnerDashboard");
    } else {
      this.props.navigation.navigate("ResidentDashboard");
    }
  };
  // Customizable Area End
}
