// Customizable Area Start
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from 'react-router-dom';
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
export interface Props extends RouteComponentProps {
    navigation: any;
    id: string;
    t: (val: string) => string
    classes?: any;
    i18n?: any;
}

interface S {
    withdrawAnnouncement: boolean;
    details: boolean;
    id: number;
    dropDownData: any[];
    loading: boolean
    announcements: any;
    countryList: any;
    cityList: any;
    complexList: any;
    buildingList: any;
    categoryList: any;
    yearList: any;
    countryFilter: any;
    cityFilter: any;
    complexFilter: any;
    buildingFilter: any;
    categoryFilter: any;
    yearFilter: any;
    searchQuery: string,
    pagination: any,
    page: number,
    announcementDetail: any,
    error: any,
    showError: boolean,
    sortBy: string,
    accountType: string,
}

interface SS {
    id: any;
}

export default class CompEmpAnnouncementController extends CommonApiCallForBlockComponent<
    Props,
    S,
    SS
> {
    // exampleAPICallId: string = "";
    // Customizable Area Start
    GetAnnouncementsList: any;
    GetAnnouncementsSortBy: any;
    GetCountriesFilterList: any;
    GetCityFilterList: any;
    GetComplexFilterList: any;
    GetCategoryFilterList: any;
    GetBuildingFilterList: any;
    GetYearFilterList: any;
    GetFilterApi: any;
    GetSearchApi: any;
    GetAnnouncementDetail: any;
    PostWithdrawAnnouncement: any;
    timeout: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this)
        // this.getBuildingFilterList = this.getBuildingFilterList.bind(this)
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage)
        ];
        this.state = {
            loading: false,
            withdrawAnnouncement: false,
            details: false,
            id: 0,
            searchQuery: '',
            showError: false,
            error: '',
            pagination: {
                current_page: 1,
                total_count: 1,
                total_pages: 1,
            },
            sortBy: '',
            announcementDetail: {},
            page: 1,
            countryList: [],
            cityList: [],
            complexList: [],
            buildingList: [],
            categoryList: [],
            yearList: [],
            announcements: [],
            countryFilter: '',
            cityFilter: '',
            complexFilter: '',
            buildingFilter: '',
            categoryFilter: '',
            yearFilter: '',
            accountType: '',
            dropDownData: [
                {
                    label: 'Title',
                    value: 'title'
                },
                {
                    label: 'Announcement By',
                    value: 'announcement_by'
                },
                {
                    label: 'Announcement On',
                    value: 'announcement_on'
                },
                {
                    label: 'Building',
                    value: 'building_name'
                },
            ],
        };
        this.timeout = null;
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getAnnouncementList()
        const filterCallTable = [
            {
                name: 'GetCountriesFilterList',
                endPoint: 'bx_block_custom_form/incident_managements/country_list',
            },
            {
                name: 'GetCityFilterList',
                endPoint: '/bx_block_custom_form/incident_managements/city_list?country=all',
            },
            {
                name: 'GetCategoryFilterList',
                endPoint: 'bx_block_announcement/announcement_categories',
            },
            {
                name: 'GetYearFilterList',
                endPoint: 'bx_block_expense_report/expense_reports/year_list'
            },
            {
                name: 'GetComplexFilterList',
                endPoint: `/bx_block_interactive_faqs/complex_list?country=${this.state.countryFilter}&city=${this.state.cityFilter}`
            }
        ]
        const accountRole = localStorage.getItem("role")

        if (accountRole) {
            this.setState({ accountType: accountRole?.replace(/"/g, '') })
        }

        filterCallTable.forEach((filter) => {
            this.getFilterListTable(filter.name, filter.endPoint)
        })
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            switch (apiRequestCallId) {
                case this.GetAnnouncementsList:
                    this.handleAnnouncementsList(responseJson);
                    break;
                case this.GetCountriesFilterList:
                    this.handleFiltersList(responseJson, 'countryList', 'country_list')
                    break;
                case this.GetCityFilterList:
                    this.handleFiltersList(responseJson, 'cityList', 'city_list')
                    break;
                case this.GetComplexFilterList:
                    this.handleFiltersList(responseJson, 'complexList', 'complexes')
                    break;
                case this.GetCategoryFilterList:
                    this.handleFiltersList(responseJson, 'categoryList', 'announcement_categories')
                    break;
                case this.GetBuildingFilterList:
                    this.handleFiltersList(responseJson, 'buildingList', 'buildings')
                    break;
                case this.GetYearFilterList:
                    this.handleFiltersList(responseJson, 'yearList', 'year')
                    break;
                case this.GetFilterApi:
                    this.handleAnnouncementsFilter(responseJson)
                    break;
                case this.GetAnnouncementsSortBy:
                    this.handleAnnouncementsFilter(responseJson)
                    break;
                case this.GetSearchApi:
                    this.handleAnnouncementsSearch(responseJson)
                    break;
                case this.GetAnnouncementDetail:
                    this.handleAnnouncementDetail(responseJson)
                    break;
                case this.PostWithdrawAnnouncement:
                    this.handleAnnouncementConfirm(responseJson)
                    break;
                default:
                    break;
            }
            this.setState({ loading: false })
        }
    }
    // Customizable Area End

    // Customizable Area Start

    backToAnnouncements = () => {
        this.setState({
            details: false,
        });
    };

    announcementOpen = () => {
        this.setState({
            withdrawAnnouncement: true,
        });
    };

    announcementClose = () => {
        this.setState({
            withdrawAnnouncement: false,
        });
    };

    showError = () => {
        if (this.state.error) {
            this.setState({
                showError: true,
                loading: false
            })
        }
    }
    getSearchResults = async () => {
        this.setState({ loading: true })
        this.GetSearchApi = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_announcement/announcements/announcement_list_with_filter?search=${this.state.searchQuery}&page=${this.state.page}&per_page=10`,
        });
    }

    getAnnouncementsSearchFilter = async () => {
        this.setState({ loading: true, searchQuery: "" })
        this.GetFilterApi = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_announcement/announcements/announcement_list_with_filter?country=${this.state.countryFilter.trim()}&city=${this.state.cityFilter.trim()}&complex=${this.state.complexFilter}&category_id=${this.state.categoryFilter.trim()}&building=${this.state.buildingFilter}&year=${this.state.yearFilter}&sort_by=${this.state.sortBy}&page=${this.state.page}&per_page=10`,
        });
    };

    getAnnouncementsSortBy = async (sortBy: string) => {
        this.setState({ loading: true, searchQuery: ""  })
        this.GetAnnouncementsSortBy = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_announcement/announcements/announcement_list_with_filter?country=${this.state.countryFilter.trim()}&city=${this.state.cityFilter.trim()}&complex=${this.state.complexFilter.trim()}&category_id=${this.state.categoryFilter.trim()}&building=${this.state.buildingFilter}&sort_by=${sortBy}&page=${this.state.page}&per_page=10`,
        });
    };

    getFilterListTable = async (method: string, endPoint: string) => {
        this.setState({ loading: true })
        //@ts-ignore
        this[method] = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint,
        });
    }

    getAnnouncementList = async () => {
        this.setState({ loading: true })
        this.GetAnnouncementsList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_announcement/announcements/announcement_list_with_filter?page=${this.state.page}&per_page=10`,
        });
    }

    handleAnnouncementsList = (responseJson: any) => {
        if (responseJson) {
            this.setState({
                announcements: responseJson.announcement.data,
                pagination: {
                    current_page: responseJson.meta.pagination.current_page,
                    total_count: responseJson.meta.pagination.total_count,
                    total_pages: responseJson.meta.pagination.total_pages,
                },
            })
        }
    }

    handleAnnouncementsFilter = (responseJson: any) => {
        if (responseJson.announcement) {
            this.setState({
                announcements: responseJson.announcement.data,
                pagination: {
                    current_page: responseJson.meta.pagination.current_page,
                    total_count: responseJson.meta.pagination.total_count,
                    total_pages: responseJson.meta.pagination.total_pages,
                },
            })
        } else {
            this.setState({ error: Object.values(responseJson.errors) || "Something went wrong!", loading: false,announcements:[]});
            this.showError()
        }
    }

    handleAnnouncementsSearch = (responseJson: any) => {
        if (responseJson.announcement) {
            this.setState({
                announcements: responseJson.announcement.data,
                pagination: {
                    current_page: responseJson.meta.pagination.current_page,
                    total_count: responseJson.meta.pagination.total_count,
                    total_pages: responseJson.meta.pagination.total_pages,
                },
            })
        } else {
            this.setState({ error: Object.values(responseJson.errors) || "Something went wrong!" });
            this.showError()
        }
    }

    handleAnnouncementDetail = (responseJson: any) => {
        if (responseJson) {
            this.setState({
                announcementDetail: responseJson.announcement.data
            })
        } else {
            this.setState({ error: Object.values(responseJson.errors) || "Something went wrong!" });
            this.showError()
        }
    }

    handleFilterChange = (event: any, stateParam: string) => {
        if (stateParam === "countryFilter") {
            this.getCityFilterList(event.target.value)
            this.setState({ [stateParam]: event.target.value, cityFilter: '', complexFilter: '', buildingFilter: '',categoryFilter:'',yearFilter:''})
            this.getComplexFilterList(event.target.value, this.state.cityFilter)
        }
        if (stateParam === "cityFilter") {
            this.setState({ [stateParam]: event.target.value , complexFilter: '', buildingFilter: '',categoryFilter:'',yearFilter:''})
            this.getComplexFilterList(this.state.countryFilter, event.target.value)
        }
        if (stateParam === "complexFilter") {
            this.getBuildingFilterList(event.target.value)
            this.setState({ [stateParam]: event.target.value, buildingFilter: '',categoryFilter:'',yearFilter:'' })
        }
        if (stateParam === 'sortBy') {
            this.setState({ [stateParam]: event.target.value,categoryFilter:'',yearFilter:'' })
            this.getAnnouncementsSortBy(event.target.value)
        }
        if(stateParam === "buildingFilter"){
            this.setState({ [stateParam]: event.target.value,categoryFilter:'',yearFilter:'' })
        }
        if (stateParam === "categoryFilter") {
            this.setState({[stateParam]: event.target.value,yearFilter:''})
        }
        if (stateParam === "yearFilter") {
            this.setState({[stateParam]: event.target.value})
        }
    }

    handleFiltersList = (responseJson: any, stateParam: string, resParam: string) => {
        if (responseJson[resParam]) {
            this.setState({ ...this.state, [stateParam]: stateParam === 'categoryList' || stateParam === 'complexList' ? responseJson[resParam].data : responseJson[resParam] })
        }
    }
    handleSubmit = (event: any) => {
        this.setState({page: 1})
        event.preventDefault()
        this.getAnnouncementsSearchFilter()
    }

    handleSearch = () => {
        this.getSearchResults();
    };

    convertTime = (inputDate: string) => {
        console.log("ttt", inputDate);
        
        const parts = inputDate.split('-');
        const year = parseInt(parts[2]) + 2000;
        const month = parseInt(parts[1]) - 1;
        const day = parseInt(parts[0]);
        const date = new Date(year, month, day);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-GB', options);
        return formattedDate;
    }
    handlePagination = (_e: any, value: number) => {
        this.setState({
            page: value
        }, () => {
            if (this.state.searchQuery) {
                this.getSearchResults()
            } else {
                this.getAnnouncementsSearchFilter()
            }
        })
    }

    onSelectRow = async (event: any) => {
        this.setState({ loading: true, details: true })
        this.GetAnnouncementDetail = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_announcement/announcements/${event.id}`,
        });
    }

    getBuildingFilterList = async (society_management_id: any) => {
        this.GetBuildingFilterList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${society_management_id}`
        })
    }
    getCityFilterList = async (country: any) => {
        this.GetCityFilterList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`
        })
    }
    getComplexFilterList = async (country?: string, city?: string) => {
        this.GetComplexFilterList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_interactive_faqs/complex_list?country=${country? country: this.state.countryFilter}&city=${city? city: this.state.cityFilter}`
        })
    }

    announcementConfirm = async () => {
        const data = {
            society_management_id: this.state.announcementDetail.attributes.society_management.id,
            announcement: {
                ids: [Number(this.state.announcementDetail.id)]
            }
        }
        this.PostWithdrawAnnouncement = await apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `bx_block_announcement/announcements/withdraw_announcement`,
            body: JSON.stringify(data)
        })
    }

    handleAnnouncementConfirm = (responseJson: any) => {
        if (responseJson) {
            this.announcementClose()
            this.backToAnnouncements()
            this.getAnnouncementList()
        }
    }
    onChange = (e: any) => {
        this.setState({ searchQuery: e.target.value },()=> this.getSearchResults());
    }
    // Customizable Area End
}
// Customizable Area End