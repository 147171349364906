// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";

interface RecentData {
  description: string;
  startDate: string;
  status: string;
  title: string;
  participants: number;
  checked?: number;
  endDate: string;
  address: string;
  process?: number;
  unchecked?: number;
  id: string;
}

export interface Props {
  navigation?: any;
  id?: string;
  classes?: any;
  history: any;
  location: any;
  match: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  surveyPollList: RecentData[];

  type: string;
  complexId: string | number;

  year: string;
  sortBy: string;
  yearList: string[];
}

interface SS {}

export default class CEPollSurveyController extends BlockComponent<Props, S, SS> {
  GetPollSurveyListCallId: string = "";
  GetPollYearListCallId: string = "";
  classes: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      surveyPollList: [],

      complexId: "",
      type: "Polls",

      year: "",
      sortBy: "",
      yearList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        this.checkAPICallResponse(apiRequestCallId, responseJson);
      }
    }
  }

  checkAPICallResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.GetPollSurveyListCallId) {
      this.handlePollSurveyListResponse(responseJson);
    } else if (apiRequestCallId === this.GetPollYearListCallId) {
      this.handlePollYearListResponse(responseJson);
    }
  }

  async componentDidMount() {
    const society_id = localStorage.getItem("society_id") || "";
    this.setState({ complexId: society_id }, () => {
      this.getCardType();
    });
  }

  getCardType = () => {
    let { location } = this.props.navigation.history;
    if (location.state && location.state.type) {
      this.setState({ type: location.state.type }, () => {
        this.getPollSurveyList();
        this.getPollYearList();
      });
    }
  };

  getPollSurveyList = async () => {
    let endpoint = "";
    const { type, complexId, sortBy, year } = this.state;

    if (type === "Polls") {
      endpoint = `society_managements/${complexId}/bx_block_polling/polls?sort_by=${sortBy}&year=${year === "all" ? "" : year}`;
    } else {
      endpoint = `society_managements/${complexId}/bx_block_survey/surveys?sort_by=${sortBy}&year=${year === "all" ? "" : year}`;
    }

    this.GetPollSurveyListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${endpoint}`,
    });
  };

  handlePollSurveyListResponse = (responseJson: any) => {
    const { type } = this.state;
    if (type === "Polls") {
      if (responseJson && responseJson.length > 0) {
        this.setState({ surveyPollList: responseJson });
      } else {
        this.setState({ surveyPollList: [] });
      }
    } else {
      if (responseJson && responseJson.surveys) {
        this.setState({ surveyPollList: responseJson.surveys.data });
      } else {
        this.setState({ surveyPollList: [] });
      }
    }
  };

  getPollYearList = async () => {
    this.GetPollYearListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_expense_report/expense_reports/year_list`,
    });
  };

  handlePollYearListResponse = (responseJson: any) => {
    if (responseJson && responseJson.year) {
      this.setState({ yearList: responseJson.year.map((item: any) => ({ label: item, value: item })) });
    }
  };

  getBackground = (value: string) => {
    if (value === "ongoing") {
      return "#FC8434";
    } else if (value === "upcoming") {
      return "#2B6FED";
    } else {
      return "#1EC65B";
    }
  };

  navigateToPollSurvey = () => this.props.history.push("/CompanyEmployee/CEPoll");

  navigateToPollSurveyReport = (id: string) => this.props.history.push("/CompanyEmployee/CEPollSurveyDetails", { type: this.state.type, id });
}
// Customizable Area End
