// Customizable Area Start
import React from "react";
interface MyComponentProps {
  opendashboard: any;
}
export const DashboardImageComponent: React.FC<MyComponentProps>  = ({ opendashboard }) => {
  const strokeColor = opendashboard 
  return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="dashboard-apps" transform="translate(1 1)">
        <path
          d="M18 1.5C18 0.67158 18.6716 0 19.5 0L22.5 0C23.3284 0 24 0.67158 24 1.5L24 7.5C24 8.32842 23.3284 9 22.5 9L19.5 9C18.6716 9 18 8.32842 18 7.5L18 1.5ZM0 16.5C0 15.6715 0.67158 15 1.5 15L4.5 15C5.32842 15 6 15.6715 6 16.5L6 22.5C6 23.3284 5.32842 24 4.5 24L1.5 24C0.67158 24 0 23.3284 0 22.5L0 16.5ZM0 1.5C0 0.67158 0.67158 0 1.5 0L10.5 0C11.3285 0 12 0.67158 12 1.5L12 7.5C12 8.32842 11.3285 9 10.5 9L1.5 9C0.67158 9 0 8.32842 0 7.5L0 1.5ZM12 16.5C12 15.6715 12.6715 15 13.5 15L22.5 15C23.3284 15 24 15.6715 24 16.5L24 22.5C24 23.3284 23.3284 24 22.5 24L13.5 24C12.6715 24 12 23.3284 12 22.5L12 16.5Z"
          id="Shape"
          fill="none"
          stroke={strokeColor}
          fill-rule="evenodd"
          stroke-width="2.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
