// Customizable Area Start
import * as React from "react";
import { Box, Button, Dialog, Divider, Grid } from "@material-ui/core";
import { Building1, info, NoVehicles } from "./assets";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import VeichleListController from "./VeichleListController.web";
import "../assets/css/style.scss";
import { Back_btn } from "../../email-account-registration/src/assets";
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class VeichleList extends VeichleListController {
  async componentDidMount() {
    this.getVehicle();
    localStorage.removeItem("selectCar"); // remove car from local storage
  }

  render() {
    const { t, i18n }: any = this.props;
    // @ts-ignore
    const language = i18n?.language;

    return (
      <>
        <Grid container className="auth-container VL" style={{ padding: "0" }} dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols VL" style={{ justifyContent: "unset" }}>
            <Grid container style={{ minHeight: "60px", padding: "10px" }} className="VL">
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "12px" }} className="VL">
                <ArrowBackIcon
                  onClick={this.redirectToDashboard}
                />
                <p className="bold-text VL" style={{ fontWeight: 600, fontSize: "18px" }}>
                  {t("My Vehicles")}
                </p>
              </Grid>
            </Grid>
            <Divider />
            {this.state.allVehcile?.length > 0 ? (
              <>
                <Grid
                  className="vehicle-list-owner VL"
                  container
                  style={{
                    height: "81vh",
                    display: "block",
                    overflow: "hidden",
                    overflowY: "auto",
                    background: "#F7F9FE",
                  }}
                >
                  {this.state.allVehcile.map((item, i) => (
                    <>
                      <Grid item xs={12} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div
                          className="card VL vehicleListCard"
                          style={{
                            cursor: "pointer",
                            width: "90%",
                            border: "1px solid #f0f0f0",
                            backgroundColor: "white",
                            paddingTop: "15px",
                          }}
                          onClick={() => this.addVehicle(item)}
                        >
                          <div className="card-content VL">
                            <img
                              className="VL"
                              src={item.attributes.car_manufacturer.attributes?.image?.url}
                              style={languageCondition(language,{
                                  marginLeft: 10,
                                  width: 60,
                                  height: 40,
                                  padding: "10px",
                                  border: "1px solid #f2f2f2",
                              },{
                                  marginRight: 10,
                                  width: 60,
                                  height: 40,
                                  padding: "10px",
                                  border: "1px solid #f2f2f2",
                              })}
                            />
                            <div
                              className="content VL"
                              style={{
                                padding: "0px 0px 0px 5px",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <p className="title bold-text VL" style={{ padding: 0, marginBottom: 5 }}>
                                {LanguageAttributeHandler(language, item.attributes.car_manufacturer.attributes.title, item.attributes.car_manufacturer.attributes.title_ar)}
                              </p>
                              <p className="sub-title VL">{LanguageAttributeHandler(language, item.attributes.car_model.title, item.attributes.car_model.title_ar)}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
                <Grid container style={{ backgroundColor: "#f6f7fc" }} className="register-another-vehicle-button VL">
                  <Grid
                    item
                    xs={12}
                    style={{ width: "100%", display: "flex", justifyContent: "center" }}
                    className="VL"
                  >
                    <Button
                      fullWidth={true}
                      className="btn VL"
                      variant="contained"
                      onClick={() => this.checkVehicle()}
                      style={{
                        backgroundColor: "#2B6FEC",
                        borderRadius: 25,
                        height: 50,
                        marginBottom: 10,
                        boxShadow: "none",
                        color: "#F7F7FC",
                        fontWeight: 600,
                        fontSize: 16,
                        marginTop: 10,
                        width: "90%",
                      }}
                    >
                      {t("Register Another Vehicle")}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <div className="VL">
                <NoVehicle item={this.props} t={t} />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols register-another-vehicle-button VL">
            <Box className="right-block VL" display={{ xs: "none", md: "flex" }}>
              <img src={Building1.default} className="building-logo" alt="" />
            </Box>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          className="diloag-wrapper VL"
          PaperProps={{
            style: {
              borderRadius: "15px",
              padding: "2rem",
            },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}
            >
              <img src={info} className="VL" />
            </Grid>
          </Grid>
          <Grid container className="VL">
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}
            >
              <p style={{ fontWeight: 600, fontSize: "1.25rem", textAlign: "center" }} className="VL">
                {t("Unable to add vehicle")} <br /> {t("request")}
              </p>
            </Grid>
          </Grid>
          <Grid container className="VL">
            <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
              <p style={{ fontWeight: 400, fontSize: "0.8rem", textAlign: "center" }} className="VL">
                {t(
                  "Sorry! You have reached the maximum number of of vehicles. consider removing some vehicles to be able to add new ones"
                )}
                .
              </p>
            </Grid>
          </Grid>
          <Grid container className="VL">
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} className="VL">
              <Button
                type="submit"
                className="btn VL vehicle-dialog-button"
                fullWidth={true}
                variant="contained"
                onClick={() => this.setState({ showDialog: false })}
                style={{
                  height: 50,
                  marginBottom: 14,
                  boxShadow: "none",
                  marginTop: 30,
                  color: "#F7F7FC",
                  maxWidth: "14rem",
                  fontWeight: 600,
                  borderRadius: 16,
                  fontSize: 16,
                  backgroundColor: "#2B6FEC",
                }}
              >
                {t("Okay, Got It")}
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(VeichleList));

const NoVehicle = (props: any) => {
  const { item } = props;
  return (
    <>
      <div
        className="LV"
        style={{
          height: "76vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "10px 20px",
        }}
      >
        <Grid container className="LV">
          <Grid
            item
            xs={12}
            className="LV"
            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}
          >
            <img src={NoVehicles} className="LV" />
          </Grid>
        </Grid>
        <Grid container className="LV">
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}
          >
            <p className="bold-text LV" style={{ fontWeight: 900, fontSize: "1.5rem" }}>
              {props.t("No Vehicle Registered")}
            </p>
          </Grid>
        </Grid>
        <Grid container className="LV">
          <Grid
            item
            xs={12}
            className="LV"
            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}
          >
            <p className="LV" style={{ fontWeight: 400, fontSize: "14px", textAlign: "center" }}>
              {props.t(
                "Looks like you haven't registered any vehicle! You can register a new vehicle by tapping the below button"
              )}
              .
            </p>
          </Grid>
        </Grid>
      </div>
      <Grid container className="register-new-vehicle-btn LS">
        <Grid item xs={12} className="LV" style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => item.history.push("/newVeichleList")}
            fullWidth={true}
            type="submit"
            className="btn register-vehicle-btn"
            variant="contained"
            style={{
              fontSize: 16,
              height: 50,
              borderRadius: 25,
              position: "fixed",
              bottom: "0",
              color: "#F7F7FC",
              fontWeight: 600,
              maxWidth: 350,
              backgroundColor: "#2B6FEC",
              boxShadow: "none",
              margin: "10px",
            }}
          >
            {props.t("Register Vehicle")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

// Customizable Area End
