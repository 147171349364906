// Customizable Area Start
import { Box, InputLabel } from "@material-ui/core";
import React, { useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

const MultipleSelect = ({ t, language, label, icon, option, ...props }: any) => {
  useEffect(() => {
    let heading = document.getElementsByClassName("gray")[0];
    if (heading) {
      heading.textContent = label;
    }
  }, [label]);

  return (
    <Box className={`multi_select__form_box`}>
      {label && <InputLabel>{t(label)}</InputLabel>}
      <MultiSelect options={option} labelledBy={label} {...props} />
    </Box>
  );
};

export default MultipleSelect;
// Customizable Area End
