// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "./ApiCallCommon.web";
import {ROLE} from "../../framework/src/Enum";

export interface Props {
  navigation: any;
  id: string;
  history?:any
  t?:any
  i18n?:any
  match?:any
  location?:any
  isBuilding?:boolean
}

interface S {
  loading: boolean;
  complexList:any;
  selectedComplex:any;
  isShow:boolean
}

interface SS {
  id: any;
}

export default class ComplexDropdownController extends CommonApiCallForBlockComponent<Props, S, SS> {
  providerComplexListId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      selectedComplex:localStorage.getItem("society_id"),
      complexList:[],
      isShow:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const userType = localStorage.getItem("userType")
    if(userType === ROLE.SERVICE_PROVIDER){
      this.getComplexList()
      this.setState({
        isShow:true
      })
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.providerComplexListId) {
        if(responseJson?.hasOwnProperty("complex")){
          this.setState({
            complexList:responseJson.complex
          })
        }
      }
    }
  }

  handleChange = (e:any) => {
    localStorage.setItem("society_id",e.target.value)
    this.setState({
      selectedComplex:e.target.value
    })
    if(this.props.isBuilding){
      this.props.history.push("/Complex")
    }else{
      window.location.reload()
    }
  }


  getComplexList = async () => {
    const userType = localStorage.getItem("providerUserType")
    this.providerComplexListId = await this.apiCallForFileUpload({
      method: "GET",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`
    });
  }
}
// Customizable Area End
