// Customizable Area Start
import * as React from "react";
import { Grid, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import FeesAndPaymentController, { Props } from "./FeesAndPaymentController";
import "./style.css";
import { withTranslation } from "react-i18next";
import ListingComponent from "./LisitingComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class FishAndPaymentOwner extends FeesAndPaymentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols forFees">
          <Grid container className="gridFees fee-payment-top-bar">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1.1rem", justifyContent: "space-between" }}
              className="SubgridFees"
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1.1rem" }} className="gridBoxFees">
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "gridFeesArrow back-icon")} onClick={() => this.props.history.push("/")} />
                <p style={{ fontSize: "1.3rem", fontWeight: 600 }} className="gridFeesArrowPTag">
                  {t("Budget")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <ListingComponent language={language} name="Review Building Budget" link="/BuildingBudget/ComplexList" label="BuildingBudget" />
              <ListingComponent language={language} name="Review Management Fee" link="/MyManagementFee/ComplexList" label="MyManagementFee" />
              <ListingComponent language={language} name="View Budget Spending" link="/BudgetSpending/ComplexList" label="BudgetSpending" />
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(FishAndPaymentOwner));
// Customizable Area End
