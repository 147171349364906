// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import UnitTotalExpenseController, { Props } from "./UnitTotalExpenseController.web";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Link,
  withStyles,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { ExpenseDateIcon, FilterIcon } from "../assets";
import SidebarImageComponent from "../../../../components/src/OwnerSidebarImage.web";
import { TotalExpenseStyle } from "./TotalExpenseStyle.web";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import moment from "moment";
import { IExpense, IExpenseCategory } from "../../../../framework/src/Interfaces/IExpenseReport.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { withRouter } from "react-router";
import { languageCondition } from "../../../../components/src/ConditionManager.web";

class UnitTotalExpense extends UnitTotalExpenseController {
  constructor(props: Props) {
    super(props);
  }

  checkExpenseFilterDate = () => {
    const startDateObj = new Date(this.state.startDate);
    const endDateObj = new Date(this.state.endDate);
    if (startDateObj < endDateObj) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "#F7F9FE" }} className={classes.totalExpense}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <Box className="left-icon">
                    <Link href="/TotalExpense">
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                    </Link>
                    <span className="bold-text">{`${t("Unit")} ${this.state.unitName} ${t("Building")} ${this.state.buildingName}`}</span>
                  </Box>
                  <Box className="right-icon">
                    <IconButton onClick={() => this.handleFilterModal()}>
                      <img src={FilterIcon} alt="filter" />
                    </IconButton>
                  </Box>
                </Box>
                <Container>
                  <Box className="tenant-list-box">
                    <Box className="tenant-list total-expense">
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Input
                            value={this.state.startDate}
                            name="date"
                            placeholder={t("Start Date")}
                            type="date"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={ExpenseDateIcon} alt="" />
                              </InputAdornment>
                            }
                            className={!this.state.startDate && is_mobile ? "date-box placeHolderForDate" : "date-box"}
                            onChange={(e: any) => this.setState({ startDate: e.target.value })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            value={this.state.endDate}
                            name="date"
                            placeholder={t("End Date")}
                            type="date"
                            className={!this.state.endDate && is_mobile ? "date-box placeHolderForDate" : "date-box input"}
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={ExpenseDateIcon} alt="" />
                              </InputAdornment>
                            }
                            inputProps={{
                              min: this.state.startDate,
                            }}
                            onChange={(e: any) => this.setState({ endDate: e.target.value })}
                          />
                        </Grid>
                      </Grid>
                        {this.state.startDate && this.state.endDate && this.checkExpenseFilterDate() && (
                          <Box style={{ color: "red", padding: "10px 0 0" }}><span>{t("End date should be greater than start date.")}</span></Box>
                        )}
                      <br />
                      <Box>
                        <Grid container spacing={2} style={{ paddingBottom: "80px" }}>
                          {this.state.expenseList.length === 0 && (
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Card style={{ background: "white", padding: "10px", borderRadius: "8px", boxShadow: "none" }}>
                                    <Box className="heading">{t("No Expense Available")}</Box>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {this.state.expenseList.map((expense: IExpense) => {
                            return (
                              <Grid item xs={12} key={expense.id}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Box className="heading">
                                      <h4 className="bold-text">
                                        {moment(expense.attributes.expense_date, "YYYY-MM-DD").format("MMMM DD, YYYY")}
                                      </h4>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Card className="expense-card">
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Cost")}</span>
                                            <p className="bold-text">{`${LanguageAttributeHandler(language, expense.attributes.address?.currency?.currency, expense.attributes.address.currency.currency_ar)} ${Number(expense.attributes.expense_amount).toLocaleString()}`}</p>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Issue")}</span>
                                            <p className="bold-text">{expense.attributes.issue_title}</p>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Category")}</span>
                                            <p className="bold-text">{expense.attributes.expense_category.title}</p>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Building Name")}</span>
                                            <p className="bold-text">{expense.attributes.building_management.name}</p>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Unit Number")}</span>
                                            <p className="bold-text">{expense.attributes.apartment_management.apartment_name}</p>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box className="unit-expense-card-box">
                                            <span>{t("Summary")}</span>
                                            <p className="bold-text">{expense.attributes.summary}</p>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Drawer
          dir={languageCondition(language, "rtl", "ltr")}
          anchor="bottom"
          className="condition-modal"
          open={this.state.isFilterOpen}
          onClose={() => this.handleFilterModal()}
        >
          <Box className="condition-box filter-box">
            <Box className="heading">
              <p className="bold-text">{t("Filter")}</p>
              <span className="clear-all-text bold-text" onClick={() => this.handleClearFilter()}>
                {t("Clear All")}
              </span>
            </Box>
            <Box className="content-box">
              {this.state.expenseCategoryList.map((category: IExpenseCategory) => {
                return (
                  <Box className="condition" key={category.id}>
                    <p>{languageCondition(language, category.title_ar, category.title)}</p>
                    <Checkbox
                      className="condition-check"
                      checked={this.state.categoryList.includes(category.id)}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleChangeCheckboxEvent(e, category.id);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <br />
            <Divider />
            <Box className="button-group">
              <Button className="add-button" onClick={() => this.handleApplyFilter()}>
                {t("Apply")}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withStyles(TotalExpenseStyle)(withRouter(UnitTotalExpense)));
// Customizable Area End
