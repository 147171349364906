// Customizable Area Start
import React from "react";
import "../../Polling/src/Polling.web.css";
import "draft-js/dist/Draft.css";
import {
    Container,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import InfoIcon from "@material-ui/icons/Info";
import GenerateBudgetReportController, {Props} from "./GenerateBudgetReportController";
import TextEditor from "./TextEditorBudgetReport.web";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import AlertError from "../../../components/src/AlertError.web";
// @ts-ignore
import ConfirmModalPopupWeb from "./ConfirmModalPopup.web";
// @ts-ignore
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class GenerateBudgetReport extends GenerateBudgetReportController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {t, i18n}: any = this.props;
        const {language} = i18n
        // @ts-ignore
        return (
            <>
                <GeneralHeader>
                    <Container className="GenerateBudgetReport">
                        <Box className="navigation GenerateBudgetReport">
                            <Box>
                                <Typography variant="body1" className="GenerateBudgetReport">
                                    {t("Documents & Reports")} /{" "}
                                    <Box component="span" className="breadcrumsGenerateBugetReport" onClick={() => this.props.history.push("/Reports")}
                                         style={{cursor: "pointer"}}>
                                        {" "}
                                        {t("Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box
                                        component="span"
                                        onClick={() => this.props.history.push("/BudgetReports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {" "}
                                        {t("Budget Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" style={{color: "blue"}}>
                                        {t("Generate Budget Report")}
                                    </Box>
                                </Typography>
                                <Typography variant="h4" className="subHeading bold-text" style={{fontSize: "28px"}}>
                                    {t("Generate Budget Report")}
                                </Typography>
                            </Box>
                        </Box>
                        <form>
                            <Grid container spacing={4}>
                                <Grid item sm={12} md={12} xs={12}>
                                    <Box className="createPSCards">
                                        <TextField
                                            label={t("Enter budget year Ex: 2023")}
                                            className={languageCondition(language, "placeHolderAlignment", "")}
                                            variant="outlined"
                                            name="title"
                                            id="BudgetReportYear"
                                            type="number"
                                            value={this.state.budgetYear}
                                            onChange={(e) => this.setState({
                                                budgetYear: e.target.value,
                                                budgetYearError: ""
                                            })}
                                            // @ts-ignore
                                            onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                                            inputProps={{
                                                min: 2020,
                                                max: 2099,
                                            }}
                                            required
                                            fullWidth
                                        />
                                        <p style={{color: "red"}}>{t(this.state.budgetYearError)}</p>
                                    </Box>
                                </Grid>
                                {this.state.budgetItems.map((item: any, key: any) => {
                                    return (
                                        <Grid item sm={12} md={12} xs={12}
                                              style={key === 0 ? {marginTop: "-20px"} : {marginTop: "35px"}} key={key}>
                                            <Box className="createPSCards">
                                                <Grid container spacing={2}
                                                      style={{marginTop: "-20px", marginBottom: "15px"}}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label={t("Enter Budget Category")}
                                                            className={languageCondition(language, "placeHolderAlignmentSpace", "")}
                                                            variant="outlined"
                                                            name="question"
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            id="CategoryItem"
                                                            value={item.budget_category}
                                                            onChange={(e) => this.handleBudgetCategory(key, e)}
                                                            required
                                                            fullWidth
                                                            style={{marginTop: 20, borderRadius: "10px"}}
                                                        />
                                                        <p style={{color: "red"}}>{t(item.budgetCategoryError)}</p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label={t("Enter Amount")}
                                                            className={languageCondition(language, "placeHolderAlignmentSpace", "")}
                                                            variant="outlined"
                                                            name="amount"
                                                            type="number"
                                                            inputProps={{
                                                                maxLength: 255,
                                                            }}
                                                            // @ts-ignore
                                                            onKeyDown={(evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()}
                                                            id="SurveyQuestion"
                                                            value={item.allocate_budget}
                                                            onChange={(e) => this.handleBudgetAmount(key, e)}
                                                            required
                                                            fullWidth
                                                            style={{marginTop: 20, borderRadius: "10px"}}
                                                        />
                                                        <p style={{color: "red"}}>{t(item.amountError)}</p>
                                                    </Grid>
                                                </Grid>
                                                <Box className="infoIcon">
                                                    <Typography variant="subtitle1">{t("Description")}</Typography>
                                                    <InfoIcon style={{color: "grey", fontSize: 18}}/>
                                                </Box>
                                                <Box className="descriptionEditor"
                                                     style={{maxHeight: "200px", overflow: "hidden"}}>
                                                    <TextEditor
                                                        // @ts-ignore
                                                        markup={item.description}
                                                        itemKey={key}
                                                        onChange={(value: any) => this.onChangeTextEditor(value, key)}
                                                    />
                                                </Box>
                                                <p style={{color: "red"}}>{item.descriptionError}</p>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        className="removeOptions"
                                                        style={{marginTop: "20px"}}
                                                        onClick={() => this.deleteQuestion(key)}
                                                    >
                                                        {t("Remove Budget Item")}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                                <Grid item sm={12} md={12} xs={12} style={{marginTop: "50px"}}>
                                    {/*@ts-ignore*/}
                                    <Button
                                        className="GenerateBudgetReport"
                                        onClick={this.addQuestionFields}
                                        fullWidth
                                        size="large"
                                        colo="primary"
                                        variant="outlined"
                                        style={{
                                            borderRadius: "8px",
                                            border: " 1px dashed #2b6fed",
                                            color: "#2b6fed",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        + {t("Add Another Category")}
                                    </Button>
                                </Grid>
                                <Grid item sm={12} md={12} xs={12} className="GenerateBudgetReport">
                                    <Box className="BottomButtonSurvey GenerateBudgetReport">
                                        <Box className="Previewbtn GenerateBudgetReport">
                                            <PreviewReportButton
                                                className="GenerateBudgetReport GenerateBudgetReportMainCancel"
                                                onClick={this.handlePriviewData}
                                                variant="contained"
                                                color="primary"
                                                style={{width: "100px"}}
                                            >
                                                {t("Preview")}
                                            </PreviewReportButton>
                                        </Box>
                                        <Box className="Publishbtn GenerateBudgetReport">
                                            <PublishBudgetButton
                                                className="GenerateBudgetReport GenerateBudgetReportMainSubmit"
                                                onClick={this.handleGenerateConfirm}
                                                disabled={this.state.loading}
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                style={{width: "100px"}}
                                            >
                                                {this.state.loading &&
                                                    <CircularProgress color="inherit" size={20}/>} {t("Submit")}
                                            </PublishBudgetButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                    <ConfirmModalPopupWeb
                        t={t}
                        handelClose={() => this.setState({confirmSubmit: false})}
                        confirmModal={this.state.confirmSubmit}
                        handleGenerateReport={this.handleGenerateReport}
                    />
                </GeneralHeader>
                <AlertError
                    show={this.state.showError}
                    handleClose={() => this.setState({showError: false})}
                    message={this.state.error}
                />
            </>
        );
    }
}

export default withTranslation()(withRouter(GenerateBudgetReport));

const PreviewReportButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight: "bold",
        height: "45px",
        "&:hover": {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const PublishBudgetButton = withStyles((theme) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        fontWeight: "bold",
        height: "45px",
        "&:hover": {
            color: "#2b6fef",
        },
    },
}))(Button);
// Customizable Area End
