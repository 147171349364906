export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const building = require("../assets/building.png");
export const unit = require("../assets/unit.png");
export const shortIcon = require("../assets/shortIcon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const exampleImg = require("../assets/exampleImg.png");
export const calendar = require("../assets/Calendar.png");
export const user = require("../assets/User.png");
export const modalDeleteIcon = require("../assets/deleteIconModal.png");
export const bitMap = require("../assets/image_Bitmap.png");
export const buildings = require("../assets/image_buildings.png");
export const calenders = require("../assets/image_calender.png");
export const description = require("../assets/image_description.png");
export const pool = require("../assets/image_pool.png");
export const users = require("../assets/image_user.png");

