// Customizable Area Start
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CheckIcon } from "../../user-profile-basic/src/assets";

const ConfirmModalPopupWeb = (props: any) => {
  const { handelClose, confirmModal, handleGenerateReport, t } = props;
  return (
    <div>
      <Dialog fullWidth onClose={handelClose} open={confirmModal} className="cancel-meeting-dialog budgetReportGenerate">
        <DialogContent style={{ margin: "15px 0" }} className="BudgetReportGenerate">
          <Box textAlign="center" className="BudgetReportGenerate">
            <img className="comment-image BudgetReportGenerate" src={CheckIcon} alt="check" />
            <Typography className="bold-text BudgetReportGenerateText" variant="h6" style={{ fontSize: "22px" }}>
              {t("Submit Report")}
            </Typography>
            <Typography
              variant="body1"
              className="BudgetReportGenerateSubText"
              style={{ marginBottom: "15px", fontSize: "16px" }}
            >
              {t("Are you sure you want to submit the budget report for approval")}?
            </Typography>
            <DialogActions className="dialog-button-group BudgetReportGenerateButtons">
              <Button
                className="cancel-button bugetReportCloseButton"
                style={{ width: "200px", marginRight: "15px", height: "55px" }}
                onClick={handelClose}
              >
                {t("Close")}
              </Button>
              <Button
                style={{ width: "200px", height: "55px" }}
                className="add-button bugetReportConfirmButton"
                onClick={handleGenerateReport}
              >
                {t("Confirm")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmModalPopupWeb;

// Customizable Area End
