import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;

  match: any;
  i18n: any;
  t: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  duration:string;
  accountData:any;

  statusFilter:string;
  cityFilter:string,
  companyFilter:string,
  countryFilter:string,
  buildingFilter:string,
  categoryFilter:string,
  dateFilter:any;
  ticketFilter:any;
  page: any;
  sortFilter:any;
  pagination: any;
  loading:boolean;
  error:any;
  showError:boolean
  ids:any;
  receivedList:any;
  countryList:any;
  accountType:any;
  openDays:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientTicketController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getList:any;
  getDurationList:any;
  getCountryApiCallId :any;
  getSalesList:any;
 
  getReceivedListData:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      duration:"",
      accountData:[],
      loading:false,
      statusFilter:"",
      cityFilter:"",
      companyFilter:"",
      countryFilter:"",
      buildingFilter:"",
      categoryFilter:"",
      dateFilter:"",
      ticketFilter:"",
      accountType:"",
      sortFilter:"",
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      ids:null,
      page:1,
     
      error:null,
      showError:false,
      receivedList:[],
      countryList:[],
      openDays:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (responseJson) {
        
        switch (apiRequestCallId) {
        
          case this.getList:
            return this.handleListSucc(responseJson);
          case this.getDurationList:
            return this.handleDurationList(responseJson);
          case this.getSalesList:
            return this.handleSaleListSucc(responseJson)
          
         
          default:
            break

         
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const accountRole = await localStorage.getItem("role")
    
    this.setState({ accountType: accountRole })
  
    if(accountRole==="coo"){
      this.getTicketList();
    }else{
      this.getSalesTicketList()
    }
    
    
    this.getCountryList()

  }
  handleSearch=()=>{
    if(this.state.accountType==="coo"){
      this.getTicketList();
    }else{
      this.getSalesTicketList()
    }
  }
  getCountryList = async () => {
    this.getCountryApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_form/incident_managements/country_list`,
    });
  };

  
  handleNext=(item:any)=>{
    this.setState({ids:item.attributes.account_manager_ids},()=>{this.props.history.push(`/CompanyEmployee/ReceivedClientTicket/${item.attributes.account_manager_ids}`)})
    
  }
  getSalesTicketList = async () => {
 
    this.setState({loading: true})
    this.getSalesList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contact_us/client_tickets/account_manager_tickets_for_sales_manager?days_since_open=${this.state.openDays}`,
    });
  };
 
  getTicketList = async () => {
  
    this.setState({loading: true})
    this.getList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contact_us/client_tickets/account_manager_tickets_for_coo?days_since_open=${this.state.openDays}`,
    });
  };
handleListSucc=(responseJson:any)=>{
 
  this.setState({loading:false,accountData:responseJson.data})
}
handleSaleListSucc=(responseJson:any)=>{
  this.setState({loading:false,accountData:responseJson.data})
}
handleDurationList=(responseJson:any)=>{}
  // Customizable Area End
}
