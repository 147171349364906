// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  isShareModalOpen: boolean;

  invoiceId: any;
  type: any;
  date: any;
  building: any;
  unit: any;
  amount: any;
  dueDate: any;
  tenant: any;
  tenantId: any;
  status: any;
  currency: any;
  currency_ar: any;
  owner: any;
  ownerId: any;

  getMyInvoicesList: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getMyInvoicesCallId: any;
  downloadIDPdf: any;
  getMyRentInvoicesCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      isShareModalOpen: false,

      invoiceId: "",
      type: "",
      date: "",
      building: "",
      unit: "",
      amount: "",
      dueDate: "",
      tenant: "",
      tenantId: "",
      owner: "",
      ownerId: "",
      status: "",
      currency: "",
      currency_ar: "",

      getMyInvoicesList: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const invoice_id = this.props.navigation.getParam("id");
    const type = this.props.navigation.getParam("type");

    this.setState({ invoiceId: invoice_id, type: type }, () => {
      if (type === "rent") {
        this.getMyShowRentInvoices();
      } else if (type === "management fee" || type === "facility rental") {
        this.getMyShowInvoices();
      }
    });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.getMyInvoicesCallId:
          this.getMyInvoicesResponse(responseJson);
          break;
        case this.getMyRentInvoicesCallId:
          this.getMyRentInvoicesResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  getMyShowInvoices = async () => {
    const { invoiceId, type } = this.state;

    this.getMyInvoicesCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/tenant_invoice_details?id=${invoiceId}&invoice_type=${type}`,
    });
  };

  getMyShowRentInvoices = async () => {
    const { invoiceId, type } = this.state;
    this.getMyRentInvoicesCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/tenant_invoice_details?id=${invoiceId}&invoice_type=${type}`,
    });
  };

  getMyInvoicesResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        date: responseJson.data.attributes.title,
        building: responseJson.data.attributes.building_name,
        unit: responseJson.data.attributes.unit_number,
        amount: responseJson.data.attributes.due_amount,
        dueDate: responseJson.data.attributes.due_date,
        tenant: responseJson.data.attributes.tenant_details.tenant_name,
        tenantId: responseJson.data.attributes.tenant_details.tenant_id,
        status: responseJson.data.attributes.status,
        currency: responseJson.data.attributes.currency.currency,
        currency_ar: responseJson.data.attributes.currency.currency_ar,
        ownerId: responseJson.data.attributes.owner_details.owner_id,
        owner: responseJson.data.attributes.owner_details.owner_name,
      });
    }
  };

  getMyRentInvoicesResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        date: responseJson.data.attributes.title,
        building: responseJson.data.attributes.building_name,
        unit: responseJson.data.attributes.apartment_name,
        amount: responseJson.data.attributes.due_amount,
        dueDate: responseJson.data.attributes.due_date,
        tenant: responseJson.data.attributes.tenant_details.tenant_name,
        tenantId: responseJson.data.attributes.tenant_details.tenant_id,
        status: responseJson.data.attributes.status,
        currency: responseJson.data.attributes.currency.currency,
        currency_ar: responseJson.data.attributes.currency.currency_ar,
      });
    }
  };

  invoiceDetailsDownload = async (selectedId: any) => {
    if (this.state.type === "rent") {
      await this.downloadPdf(`/bx_block_rent_payment/download_rent_invoice/${selectedId}`, `rent_invoice.pdf`);
    } else {
      await this.downloadPdf(`/bx_block_fees_payment/invoices/${selectedId}/download_invoice.pdf`, `invoice.pdf`);
    }
  };

  handleInvoiceShareModal = () => {
    this.setState({
      ...this.state,
      isShareModalOpen: !this.state.isShareModalOpen,
    })
  }
}

// Customizable Area End
