//@ts-ignore
//@ts-nocheck
import React from "react";
//components
import {
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
} from "@material-ui/core";

//resources
import { Building1, Delete_Icon,  Landing_Banner, request } from "./assets";
import { withRouter } from 'react-router';
import { Formik, Form, Field } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Loader from "../../../components/src/Loader.web";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController.web.tsx";
class RegistrationRequestSignup extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    console.log("getRegistrationRequest===================>", this.state?.registrationRequest?.attributes);
    const building_name = this.state?.registrationRequest?.attributes?.building_name;
    const apartment_name = this.state?.registrationRequest?.attributes?.apartment_name;
    //console.log("getRegistrationRequest===================>",building_name ,apartment_name);
    return (
      <>
        <Box className="login-wrapper reg-wrapper classWhite" style={{background:'white'}}>
          {/* <Box display={{ xs: 'flex', md: 'none' }} className="backIcon" onClick={() => window.history.back()}><KeyboardBackspaceIcon /></Box> */}
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block">
                <Box className="logo-block common-top-padding" display={{ xs: 'none', md: 'flex' }}>
                  <Link href="/EmailAccountLogin">
                    {/* <img src={Building_Logo} className="head-logo" alt="" /> */}
                    <h4>Building Name</h4>
                  </Link>
                </Box>
                <Box className="main-content-block" style={{justifyContent:"center",alignItems:"center",height:"70vh"}}>
                  <Box className="reg-content-block">
                    <Box className="header-block chairmanHeaderBlock">
                      {/* <img src={Tenant_Logo} className="tenant-logo" alt="Tenant Logo" /> */}
                      <img src={request} className="bank-logo" alt="Tenant Logo" />
                      <h1 className='bold-text'>Registration Request Sent</h1>
                      <h6>Your registration request sent to the management of the selected building. You will be notified and given access to the platform once approved.</h6>
                    </Box>
                  </Box>
                </Box>
                <Box className="footer-block">
                  <Box className="row-btn customButton desktop-ui" style={{position:"fixed",bottom:"10",left:"20"}}>
                    <Button variant="contained" onClick={() => this.props.history.push('/')} style={{width:"90%"}}>
                      Okay
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        < Loader loading={this.state.loading} />
      </>
    );
  }
}
export default withRouter(RegistrationRequestSignup)

// Customizable Area End
