//Customizable Area Start
import React from "react";
import CompEmpAnnouncementController, {
    Props
} from "../CompanyEmployee/CompEmpAnnouncementController";
import SearchIcon from '@material-ui/icons/Search';
import "./CompEmpAnnouncement.web.css"
import 'web/src/i18n.js';
import { Box, Button, TextField, IconButton, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, Modal, Fade, Backdrop, FormControl, Grid } from "@material-ui/core";
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import AlertErrorWeb from "../../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import Loader from "../../../../components/src/Loader.web";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { CSSProperties } from '@material-ui/styles';
import { bitMap, buildings, calenders, description, users } from "../assets"
import { AnnouncementsFilter } from "../../../../components/src/AnnouncementsFilter";

export class CompEmpAnnouncement extends CompEmpAnnouncementController {
    constructor(props: Props) {
        super(props);
    }
    announcementsDetails = (t: any) => {
        return (
            <>
                <Box style={webStyle.companyBox}>
                    <Box>
                        <Typography style={{ fontSize: '18px' }}> {t("Activities")} /
                            <span style={{ fontSize: '18px' }} id="backToAnnouncementsBtn" onClick={this.backToAnnouncements}> {t("Announcements")}</span> / <span style={webStyle.linkColor}> {t("Announcement Details")}</span></Typography>
                    </Box>
                    <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        <Typography style={webStyle.bigTitle} className="bold-text">{t("Announcement Details")}</Typography>
                        {
                            this.state.accountType === "coo" ?
                                <button id="announcementOpenBtn"
                                    className="withdraw_Announcement bold-text"
                                    onClick={this.announcementOpen}
                                >
                                    {t('withdraw Announcement')}
                                </button>
                                : null
                        }
                    </Box>
                    <Box style={webStyle.detailsContainer}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={webStyle.lareTitle} className="bold-text">{this.state.announcementDetail.attributes.title}</Typography>
                            <Typography className="statusOngoingBlue" style={{fontSize:"12px"}}>{this.state.announcementDetail.attributes.category}</Typography>
                        </Box>
                        <div
                            className={
                                this.state.announcementDetail.attributes?.image != null && this.state.announcementDetail.attributes?.image?.url !== ''
                                    ? "Description_Image_Container Show"
                                    : "Hide"
                            }
                        >
                            <img
                                src={this.state.announcementDetail.attributes?.image?.url}
                                className="Description_Image"
                            />
                        </div>
                        <Typography style={{ fontSize: '16px' }}>{t('Description')}</Typography>
                        <Typography style={{ fontSize: '14px' }}>{this.state.announcementDetail.attributes.description}</Typography>
                        <Box style={{ display: 'flex', marginTop: '24px' }}>
                            <Box style={webStyle.boxOpton}>
                                <div className="announcements_Details_Img_Div ">
                                    <img
                                        src={buildings}
                                        style={{width:"25px",height:"25px"}}
                                        alt="Building"
                                        className="announcements_Details_Img"
                                    />
                                </div>
                                <Box style={{ marginLeft: "8px" }}>
                                    <Typography style={{ fontSize: '12px',color:"rgba(24,29,37,0.5)" }}>{t('Complex')}</Typography>
                                    <Typography style={{ fontSize: '14px' }} className="bold-text">{this.state.announcementDetail.attributes.complex_name}</Typography>
                                </Box>
                            </Box>
                            <Box style={webStyle.boxOpton}>
                                <div className="announcements_Details_Img_Div ">
                                    <img
                                        src={buildings}
                                        style={{width:"25px",height:"25px"}}
                                        alt="Building"
                                        className="announcements_Details_Img"
                                    />
                                </div>
                                <Box style={{ marginLeft: "8px" }}>
                                    <Typography style={{fontSize: '12px',color:"rgba(24,29,37,0.5)" }}>{t('Building')}</Typography>
                                    <Typography style={{ fontSize: '14px' }} className="bold-text">{this.state.announcementDetail.attributes.building_name}</Typography>
                                </Box>
                            </Box>
                            <Box style={webStyle.boxOpton}>
                                <div className="announcements_Details_Img_Div ">
                                    <img
                                        src={users}
                                        alt="Building"
                                        className="announcements_Details_Img"
                                    />
                                </div>
                                <Box style={{ marginLeft: "8px" }}>
                                    <Typography style={{ fontSize: '12px',color:"rgba(24,29,37,0.5)" }}>{t('Announced By')}</Typography>
                                    <Typography style={{ fontSize: '14px' }} className="bold-text">{this.state.announcementDetail.attributes.announcement_by}</Typography>
                                </Box>
                            </Box>
                            <Box style={webStyle.boxOpton}>
                                <div className="announcements_Details_Img_Div ">
                                    <img
                                        src={calenders}
                                        alt="Building"
                                        className="announcements_Details_Img"
                                    />
                                </div>
                                <Box style={{ marginLeft: "8px" }}> 
                                    <Typography style={{ fontSize: '12px',color:"rgba(24,29,37,0.5)" }}>{t('Announced On')}</Typography>
                                    <Typography style={{ fontSize: '14px' }} className="bold-text">{this.state.announcementDetail.attributes.announcement_on}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={this.state.withdrawAnnouncement}
                    onClose={this.announcementClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.withdrawAnnouncement}>
                        <div className="paper paper_Delete_Category ">
                            <div className="Delete_Category_Container">
                                <img src={bitMap} alt="Msg" />
                            </div>
                            <p className="Delete_Category_Heading bold-text">{t('Withdraw Announcement')}</p>
                            <p className="Delete_Category_Text Description_Withdraw_Text">
                                {t('Are you sure you want to withdrawn the announcement?')}
                            </p>
                            <p className="Delete_Category_Text Description_Withdraw">
                                {t('If you withdraw the announcement it will be deleted from the system.')}
                            </p>
                            <div className="Modal_Button_Container Delete_Category_Button_Div">
                                <div id="announcementCloseBtn"
                                    className="ModalCancelDiv Button_Size_Delete bold-text"
                                    onClick={this.announcementClose}
                                >
                                    {t('Close')?.toUpperCase()}
                                </div>
                                <div id="announcementConfirmBtn" className="ModalConfirmDiv Button_Size_Delete bold-text"
                                    onClick={this.announcementConfirm}
                                >
                                    {t('Confirm')?.toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    };

    announcementsTable = (t: any, i18n: any) => {
        return (
            <>
                <Box style={webStyle.companyBox}>
                    <Box>
                        <Typography style={{ fontSize: '18px' }}>{t("Activities")} / {<span style={webStyle.linkColor}> {t("Announcements")}</span>}</Typography>
                    </Box>
                    <Box>
                        <Typography style={webStyle.bigTitle} className="bold-text">{t("Announcements")}</Typography>
                    </Box>
                    <Box style={webStyle.selectFilter} className="estate_table_select_boxes">
                        <Box style={webStyle.selectBoxLeft} className="estate_table_select_boxes_left">
                            <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                                <AnnouncementsFilter t={t} classComponent={this} filterList="annoucementsFilter" i18n={this} />
                                <Box className="filter-filter">
                                    <Button
                                        className="handleSearchFilterForInvoices search-btn-estate"
                                        onClick={this.handleSubmit}
                                        startIcon={<SearchIcon style={{ marginLeft: "10px" }} />}
                                    >
                                        {t("Search")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={webStyle.table} className="estate_table_select_boxes_container">
                        <Box style={webStyle.tableH} className="estate_table_select_boxes_header">
                            <h2 style={webStyle.tableHead} className="bold-text">
                                {t("Announcements")}</h2>
                            <Box style={{ display: 'flex' }}>
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    className="table_search"
                                    value={this.state.searchQuery}
                                    onChange={(e) => this.onChange(e)}
                                    style={webStyle.inputSearch}
                                    placeholder={`${t("Search Announcement Title")}`}
                                    data-testid="searchquery"
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton
                                                id={"searchTextInput"}
                                                style={webStyle.searchIcon}>
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                        style: webStyle.inner
                                    }}
                                />
                                <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                                    <AnnouncementsFilter className={`${this.state.sortBy ? "" : "filter_select_color"}`} t={t} classComponent={this} filterList="dropdown_filter" i18n={this} />
                                </Box>
                            </Box>
                        </Box>
                        {
                            this.state.announcements.length ? <Table className="table-box" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="bold-text">{t("#")}</TableCell>
                                        <TableCell className="bold-text">{t("Title")}</TableCell>
                                        <TableCell className="bold-text">{t("Announced On")}</TableCell>
                                        <TableCell className="bold-text">{t("Complex")}</TableCell>
                                        <TableCell className="bold-text">{t("Building")}</TableCell>
                                        <TableCell className="bold-text">{t("Category")}</TableCell>
                                        <TableCell className="bold-text">{t("Announcement By")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.announcements.map((announcement: any, i: number) => (
                                        <TableRow key={announcement.id} id={'selectedItem'} onClick={() => this.onSelectRow(announcement)}>
                                            <TableCell className={'cell-one'}>{(this.state.page - 1) * 10 + i + 1}</TableCell>
                                            <TableCell className={'cell-two'}>{announcement.attributes.title.toString().length > 20 ? announcement.attributes.title.slice(0, 20) : announcement.attributes.title}</TableCell>
                                            <TableCell className={'cell-three'}>{this.convertTime(announcement.attributes.announcement_on)}</TableCell>
                                            <TableCell className={'cell-four'}>{announcement.attributes.complex_name}</TableCell>
                                            <TableCell className={'cell-four'}>{announcement.attributes.building_name || "_"}</TableCell>
                                            <TableCell className={'cell-four'}>{announcement.attributes.announcement_category}</TableCell>
                                            <TableCell className={'cell-three'}>{announcement.attributes.announcement_by || 'N/A'}</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table> : <Box style={webStyle.noRecord}>
                                <Typography variant="body1">
                                    {t("No Records Found")}
                                </Typography>
                            </Box>
                        }
                        <Divider />
                        <Box style={webStyle.tableF}>
                            <PaginationModule
                                pagination={this.state.pagination}
                                handlePagination={this.handlePagination}
                                page={this.state.page}
                            />
                        </Box>
                    </Box>
                </Box>
                <Loader loading={this.state.loading} />
            </>
        )
    }

    render() {
        const { t, i18n } = this.props

        return (
            <MainScreen>
                <div className={this.state.details ? "Hide" : "Show"}>
                    {this.announcementsTable(t, i18n)}
                </div>
                <div className={this.state.details ? "Show" : "Hide"}>
                    {this.state.announcementDetail?.attributes && this.announcementsDetails(t)}
                </div>
                <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
            </MainScreen>
        )
    }
}
const webStyle: any = {
    companyBox: {
        padding: '30px',
    },
    bigTitle: { fontSize: '32px', fontWeight: '700' as CSSProperties['fontWeight'] },
    lareTitle: { fontSize: '18px', fontWeight: '700' as CSSProperties['fontWeight'] },
    noRecord: {
        padding: '25px',
        display: 'flex',
        textAlign: 'center' as CSSProperties['textAlign']
    },
    selectBoxLeft: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectFilter: {
        marginTop: '30px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchButton: {
        marginTop: '5px',
        marginLeft: '15px',
        height: 'calc(100% - 20px)',
        background: '#2B6FED',
        borderColor: '#2B6FED',
        fontSize: '14px',
        boxShadow: 'unset',
        minHeight: '50px'
    },
    searchTextInput: {
        marginLeft: '10px'
    },
    table: {
        background: '#fff'
    },
    tableH: {
        padding: '30px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tableF: {
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: '22px'
    },
    inputSearch: {
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '8px',
        height: '46px',
        padding: '0px',
        color: '#212121',
        marginRight: '16px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchIcon: {
        padding: '0px'
    },
    inner: {
        padding: '0px',
        color: '#212121'
    },
    detailsContainer: {
        background: '#fff',
        padding: '15px',
        marginTop: '30px',
        borderRadius: '8px',
    },
    linkColor: {
        color: 'rgb(43, 111, 237)'
    },
    boxOpton: { display: 'flex', marginRight: '20px' },
    searchBtnmain: {
        marginTop: "8px",
        marginLeft: "10px",
        fontWeight: "bold",
        borderRadius: "10px",
        fontSize: "15px",
        backgroundColor: "rgb(43, 111, 237)"
    },
    subBox: {
        display: "flex",
        width: "100%",
        margin: 0,
        flexDirection: "row",
        flexWrap: "wrap",
    },
}
export default withTranslation()(withRouter(CompEmpAnnouncement))
//Customizable Area End