import React from "react";
import {
  Container,
  Typography,
  Card,
  Button,
  Dialog,
  withStyles,
  TextareaAutosize,
  Link,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityManagerContorller, { Props } from "./FacilityManagerContorller.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import { Classified_CorrectIcon } from "./assets";
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
class FacilityManagerDetail extends FacilityManagerContorller {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    const facility_id = this.props.navigation.getParam("id");
    if (facility_id) {
      this.getFacilityDetailsById(facility_id);
    }
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const {language} = i18n
    const attributes = this.state?.getFacilityDetails?.attributes;

    return (
      <>
        <GeneralHeader>
              <Container className=" incident-Listing-wrapper desktop-ui facility-manager-details facility-manager-container">
                <Box className={classes.navigation}>
                  <Box>
                    <Typography variant="body1" >
                      {t("My Dashboard")} / <Link href="/DashboardGeneral">{t("General Dashboard")}</Link> / <Link href="/ManagerFacilityReservation">{t("Facility Reservation")}</Link> /<Box component="span" style={{ color: "blue" }}> {t("Facility Reservation Details")}</Box>
                    </Typography>
                    <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>{t("Facility Reservation Details")}</Typography>
                  </Box>
                </Box>
                <Box className="content-block-wrapper incident-detail-card-block">
                  <Card className="incident-detail-card card">
                    <Box className="card-header">
                      <Typography component="h4" className="bold-text">
                        {attributes?.common_area?.name}
                      </Typography>
                      <Box className="customButton">
                        <Button variant="contained" className={attributes?.status === 'Pending' ? "contain warning" : attributes?.status === 'Upcoming' ? 'contain success' : attributes?.status === 'Completed' ? 'contain blue' : 'contain danger'} type="submit">
                          {t(attributes?.status)}
                        </Button>
                      </Box>
                    </Box>
                    <Box style={{ padding: "15px 15px 0 15px" }}>
                      <Typography component="h4" className="bold-text" style={{ paddingBottom: "10px" }}>
                        {t("Reservation Details")}
                      </Typography>
                      <Box className="row-block">
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Owner Name")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.Owner_name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Building Name")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.building?.name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Unit Number")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.unit_number?.apartment_name}</h4>
                        </Box>
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Date")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.date}</h4>
                        </Box>
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Duration")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.start_time} - {attributes?.end_time}</h4>
                        </Box>
                        <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                          <Typography className="title-span" component="span">
                            {t("Rent")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.currency?.currency}{" "}{Number(attributes?.rent).toLocaleString()} </h4>
                        </Box>
                        {
                          attributes?.status === "Completed" &&
                          <Box className="card-rows" style={{ margin: "0", padding: "10px 0" }}>
                            <Typography className="title-span" component="span">
                              {t("Paid On")}:
                            </Typography>
                            <h4 className="bold-text">{attributes?.paid_on}</h4>
                          </Box>
                        }
                      </Box>
                    </Box>
                  </Card>
                  {(attributes?.status === "Rejected" || attributes?.status === "Cancelled") &&
                    <div className="card" style={{ backgroundColor: "white", padding: "15px", boxShadow: "4px 0px 14px 0 #ececec", border: "2px solid #ececec" }} >
                      <Typography className="bold-text" style={{ color: "red", fontSize: "16px" }}>{attributes?.status === "Rejected" ? `${t("Rejection")}` : `${t("Cancellation")}`} {t("Reason")}:</Typography>
                      <Typography style={{ fontSize: "16px" }}>{attributes?.note || "N/A"}</Typography>
                    </div>
                  }

                  <Box className="incident-button-row customButton" style={{ paddingTop: "50px", paddingRight: "0" }}>
                    {attributes?.status === 'Pending' &&
                      <>
                        <Button className="reject-btn-btn" variant="outlined" style={{ borderRadius: "8px", width: '150px' }}
                          onClick={() => this.setState({ showDialog: true })}
                        >{t("Reject")}</Button>
                        <Button variant="contained" style={{ borderRadius: "8px", width: '150px' }}
                          onClick={() => this.setState({ statusShowDialog: true })}
                        >{t("Approve")}</Button>
                      </>
                    }
                    {attributes?.status === 'Upcoming' &&
                      <Box className="outline-danger">
                        <Button variant="outlined" style={{ borderRadius: "8px", width: '150px' }}
                          onClick={() => this.setState({ ignoreShowDialog: true })}
                        >{t("Cancel")}</Button>
                      </Box>
                    }
                  </Box>
                </Box>
              </Container>
        </GeneralHeader>

        {/* view reject dialog */}
        <Dialog fullWidth className="add-meeting reject-facility-dialog" open={this.state.showDialog} dir={languageCondition(language,"rtl","ltr")}>
          <MuiDialogTitle disableTypography className="dialog-heading" >
            <Typography variant="h6" className="bold-text">
              {t("Reject Reservation Request")}
            </Typography>
            <IconButton className="icon-button" onClick={() => { this.setState({ showDialog: false, addNote: null }); }}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <TextareaAutosize
                className="reject-note"
                placeholder={t("Add Notes")}
                onChange={(e: any) => this.setState({ addNote: e.target.value })}
                value={this.state?.addNote}
              />
            </FormControl>
          </DialogContent>
          <br />
          <br />
          <DialogActions className="dialog-button-group" style={{gap:"10px"}}>
            <Button className="cancel-button" onClick={() => { this.setState({ showDialog: false, addNote: null }); }}>
              {t("Cancel")}
            </Button>
            <Button
              className="add-button"
              onClick={() => this.rejectedOrCompleted("Rejected")}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* view cancel dialog */}
        <Dialog fullWidth className="add-meeting" open={this.state.ignoreShowDialog} dir={languageCondition(language,"rtl","ltr")}>
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Cancel Reservation")}
            </Typography>
            <IconButton className="icon-btn-btn" onClick={() => { this.setState({ ignoreShowDialog: false, addNote: null }); }}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <TextareaAutosize
                className="reject-note"
                placeholder={t("Add Notes")}
                onChange={(e: any) => this.setState({ addNote: e.target.value })}
                value={this.state?.addNote}
              />
            </FormControl>
          </DialogContent>
          <br />
          <br />
          <DialogActions className="dialog-button-group">
            <Button className="cancel-button" onClick={() => { this.setState({ ignoreShowDialog: false, addNote: null }); }}>
              {t("Cancel")}
            </Button>
            <Button
              className="add-button"
              onClick={() => this.rejectedOrCompleted("Cancel")}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          onClose={() => this.setState({ statusShowDialog: false })}
          open={this.state.statusShowDialog}
          className="cancel-meeting-dialog delete-document-dialog"
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={Classified_CorrectIcon} alt="check" />
              <Typography variant="h6" className="bold-text">
                {t("Approve Reservation Request")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0px" }}>
                {t("Are you sure you want to approve reservation request")}?
              </Typography>
              <br />
              <DialogActions className="dialog-button-group">
                <Button
                  className="cancel-button"
                  style={{ width: "200px", height: "50px" }}
                  onClick={() => { this.setState({ statusShowDialog: false }); }}
                >
                  {t("Close")}
                </Button>
                <Button
                  style={{ width: "200px", height: "50px" }}
                  className="add-button"
                  onClick={() => this.rejectedOrCompleted("Upcoming")}
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export const dashBoard: any = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};

export default withTranslation()(withStyles(dashBoard)(withRouter(FacilityManagerDetail)));
