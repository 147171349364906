// Customizable Area Start
import * as React from "react";
// custom components
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { Share } from "./assets";
import ReceiptDetailsController, { Props } from "./ReceiptDetailsController";
import "./style.css";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";
import { withTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import moment from "moment";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

class Visitors extends ReceiptDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
  
    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="receipt-details-page auth-cols">
          <Grid container className="receipt-details-navbar fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                <ArrowBackIcon onClick={() => window.history.back()} className={languageCondition(language, "KeyboardForwardIconAR", "receipt-details-navigation-icon")} />
                <Box className="ellipse-nav-bar">
                  <p style={{ fontSize: "1.2rem" }} className="bold-text receipt-details-top-header">
                    {t(this.state.payment_type?.split("_").join(" "))} {t("Receipt")} :{" "}
                    {moment(this.state.paid_on).format("MMMM - YYYY")}
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list" style={{ paddingBottom: "120px" }}>
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text receipt-details-top-content-bar">
                    {t("Invoice Details")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={`receipt-details-status ${this.state.status}`}
                  >
                    {t(this.state.status?.split("_").join(" "))}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  padding="15px"
                  justifyContent="space-between"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                  borderRadius="8px"
                  bgcolor="white"
                  alignItems="center"
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-building">
                        {t("Building Name")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.building_name}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-unit-number">
                        {t("Unit")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.unit_number}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-rent-amount">
                        {this.state.payment_type?.split("_").join(" ")} {t("Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.rent_amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-late-charge">
                        {t("Late Charges")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.late_charge).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-paid-amount">
                        {t("Paid Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.paid_amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-paid-date">
                        {t("Paid Date")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(this.state.paid_on).format("MMM DD, YYYY")}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-details-invoice-type">
                        {t("Invoice Type")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {t(this.state.payment_type?.split("_").join(" "))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text">
                    {t("Tenant Details")}
                  </Typography>
                </Box>
                <Box borderRadius="8px" bgcolor="white" padding="15px" style={{ boxShadow: "4px 0px 14px #e9e9e9" }}>
                  <Box style={{ marginBottom: "10px" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t("Tenant")}:
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.tenant_name || "-"}
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t("ID Number")}:
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.tenant_id || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="receipt-details-download-share-btn download-share-bar">
                <CloseButton
                  className="receipt-details-download-btn donwload-btn"
                  onClick={() => this.ReciptsDownload(this.state.receiptId)}
                  fullWidth
                >
                  {t("Download Receipt")}
                </CloseButton>
                <Box
                  className="receipt-share-btn shareIcon"
                  onClick={this.handleReceiptShareModal}
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                >
                  <img src={Share} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleReceiptShareModal}
          heading={t("Share")}
          documentURL={`${baseURL}/bx_block_fees_payment/receipts/${this.state.receiptId}/download_receipt.pdf`}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));
// Customizable Area End
