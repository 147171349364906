import {tokenManagerP1P2} from "../ConditionManager.web";

export const downloadFileFromURL = async (path: any, fileName: any) => {
  const token: any = tokenManagerP1P2()
  const myHeaders = new Headers();
  myHeaders.append("token", token);
  let requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
  };
  const response = await fetch(`${path}`, requestOptions)
  const resBlob = await response.blob()
  const url = window.URL.createObjectURL(
    new Blob([resBlob]),
  );
  
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    fileName,
  );
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  // @ts-ignore
  link.parentNode.removeChild(link);
  return;
}