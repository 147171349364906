// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Box,
  Grid,
  Divider,
  Card,
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  FormControl,
  TextareaAutosize,
} from "@material-ui/core";
import ExpenseDetailsController, { Props } from "./ExpenseDetailsController";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { SuggestionStyleWeb } from "../../user-profile-basic/src/SuggestionStyle.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { expenseDownloadButton, pdfLogo } from "./assets";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { CheckIcon } from "../../user-profile-basic/src/assets";
import AlertSuccessWeb from "../../../components/src/AlertSuccess.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import GeneralSideBar from "../../dashboard/src/GeneralSideBar.web";
import { ROLE } from "../../../framework/src/Enum";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class ExpenseDetails extends ExpenseDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const userTypes = this.state.userType;
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container className={classes.announcements}>
                <Box className="navigation">
                  <Box>
                    {userTypes === ROLE.AUDITOR ? (
                      <Typography variant="body1" className="expeseReportAuditor">
                        {t("Reports")} /{" "}
                        <Box
                          component="span"
                          className="expeseReportsAuditor"
                          onClick={() => this.props.history.push("/ExpenseReports")}
                          style={{ cursor: "pointer" }}
                        >
                          {t("Expense Reports")}
                        </Box>{" "}
                        /{" "}
                        <Box component="span" className="expeseReportDetailsAuditor" style={{ color: "blue" }}>
                          {t("Expense Report Details")}
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant="body1" className="documentsAndReportsMain">
                        {t("Documents & Reports")} /
                        <Box
                          component="span"
                          className="expeseReportMain"
                          onClick={() => this.props.history.push("/Reports")}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {t("Reports")}
                        </Box>{" "}
                        /{" "}
                        <Box
                          component="span"
                          className="expeseReportsMain"
                          onClick={() => this.props.history.push("/ExpenseReports")}
                          style={{ cursor: "pointer" }}
                        >
                          {t("Expense Reports")}
                        </Box>{" "}
                        /{" "}
                        <Box component="span" className="expeseReportsDetailsMain" style={{ color: "blue" }}>
                          {t("Expense Report Details")}
                        </Box>
                      </Typography>
                    )}
                    <Typography variant="h5" className="sub-heading bold-text" style={{ marginBottom: "0px", fontSize: "28px" }}>
                      {t("Expense Report Details")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h5 className="bold-text">{t("Report Details")}</h5>
                    </Box>
                    <Divider />
                    <Box width="100%" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Box style={{ width: "95%" }}>
                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Building")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state?.expenseDetails?.Building}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Title")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state?.expenseDetails?.title}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Expense Number")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state.expenseDetails?.Expence_Number}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Expense Registered on")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state.expenseDetails?.Expence_Registered_on}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Amount")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {languageCondition(language,this.state.expenseDetails?.currency?.currency_ar,this.state.expenseDetails?.currency?.currency) } {this.state.expenseDetails?.Amount?.toLocaleString()}
                          </Typography>
                        </Box>
                        <Divider />
                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Category")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state.expenseDetails.Category}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box style={{ display: "flex", margin: "10px 0px" }}>
                          <Typography style={{ marginRight: "5px" }}>{t("Description")} : </Typography>
                          <Typography className="bold-text" style={{ fontWeight: "bold" }}>
                            {this.state?.expenseDetails?.description}
                          </Typography>
                        </Box>
                        {this.state.expenseDetails.pdf_url?.map((item: any, key: any) => {
                          return (
                            <Box
                              key={key}
                              onClick={() => window.open(item.download_url, "_blank")}
                              style={{
                                margin: "15px 0px",
                                width: "100%",
                                height: "70px",
                                border: "1px solid #e4e4e4",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                            >
                              <Box style={{ display: "flex", alignItems: "center", marginLeft: "20px",marginRight: "20px" }}>
                                <img src={pdfLogo} />
                                <Typography style={{ marginLeft: "20px" }}>{item?.file_name}</Typography>
                              </Box>
                              <Box style={{ display: "flex" }}>
                                <img style={{ marginRight: "20px",marginLeft:"10px" }} src={expenseDownloadButton} />
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
                {this.state?.expenseDetails?.status == "Rejected" && (
                  <Grid className="rejection-box">
                    <Card style={{ margin: "0 0 20px", padding: "20px" }}>
                      <h4 className="bold-text" style={{ color: "red" }}>
                        {t("Rejection Reason")}
                      </h4>
                      <p style={{ marginTop: "10px" }}>{this.state?.expenseDetails?.note}</p>
                    </Card>
                  </Grid>
                )}
                {this.state?.expenseDetails?.status === "Pending" && userTypes === "Chairman" && (
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ApproveButton className="RejectExpenseReportButton" onClick={() => this.setState({ isRejectReportModalOpen: true })}>{t("Reject")}</ApproveButton>
                    <RejectButton className="expenseReportApproveConfirmation" onClick={() => this.setState({ isApproveReportModalOpen: true })}>{t("Approve")}</RejectButton>
                  </Box>
                )}
              </Container>
            <Dialog
              fullWidth
              onClose={() => this.setState({ isApproveReportModalOpen: false })}
              open={this.state.isApproveReportModalOpen}
              className="cancel-meeting-dialog"
            >
              <DialogContent style={{ margin: "15px 0" }}>
                <Box textAlign="center">
                  <img className="comment-image" src={CheckIcon} alt="check" />
                  <Typography variant="h6">{t("Approve Expense Report")}</Typography>
                  <Typography variant="body1" style={{ marginBottom: "0px" }}>
                    {t("Are you sure you want to approve Expense report")}?
                  </Typography>
                  <DialogActions className="dialog-button-group">
                    <RejectButton
                      className="cancel-button expenseReportApproveCancelButton"
                      style={{ width: "200px" }}
                      onClick={() => this.setState({ isApproveReportModalOpen: false })}
                    >
                      {t("Close")}
                    </RejectButton>
                    <ApproveButton style={{ width: "200px" }} className="add-button expenseReportApproveSubmitButton" onClick={() => this.manageExpenseApproval()}>
                      {t("Approve")}
                    </ApproveButton>
                  </DialogActions>
                </Box>
              </DialogContent>
            </Dialog>
            <Dialog fullWidth className="add-meeting" open={this.state.isRejectReportModalOpen}>
              <MuiDialogTitle disableTypography className="dialog-heading">
                <Typography variant="h6">{t("Reject Expense Report")}</Typography>
                <IconButton onClick={() => this.setState({ isRejectReportModalOpen: false })}>
                  <CloseIcon />
                </IconButton>
              </MuiDialogTitle>
              <DialogContent dividers>
                <FormControl fullWidth>
                  <TextareaAutosize
                    className="reject-note RejectExpenseReportNote"
                    placeholder={t("Add Notes")}
                    value={this.state.rejectReason}
                    onChange={(e) => this.setState({ rejectReason: e.target.value })}
                  />
                </FormControl>
                <p style={{ color: "red" }}>{this.state.RejectReasonError}</p>
              </DialogContent>
              <DialogActions className="dialog-button-group">
                <RejectButton className="cancel-button ExpenseReportRejectCancelButton" onClick={() => this.setState({ isRejectReportModalOpen: false })}>
                  {t("Cancel")}
                </RejectButton>
                <ApproveButton className="add-button ExpenseReportRejectSubmitButton" onClick={() => this.handleRejectExpense()}>
                  {t("Submit")}
                </ApproveButton>
              </DialogActions>
            </Dialog>
        </GeneralHeader>
        <AlertSuccessWeb
          show={this.state.showSuccess}
          handleClose={() => this.setState({ showSuccess: false })}
          message={this.state.successMessage}
        />
        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false })}
          message={this.state.error}
        />
      </>
    );
  }
}

const RejectButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    height: "55px",
    width: "200px",
    "&:hover": {
      backgroundColor: "#2b6fef",
    },
  },
}))(Button);

const ApproveButton = withStyles((theme) => ({
  root: {
    color: "#2b6fed",
    backgroundColor: "white",
    fontWeight: "bold",
    height: "55px",
    width: "200px",
    border: "#2B6FED 1px solid",
    marginRight: "20px",
  },
}))(Button);

export default withTranslation()(withStyles(SuggestionStyleWeb)(withRouter(ExpenseDetails)));
// Customizable Area End
