// Customizable Area Start
import React from "react";
import "./Dashboard.web.css";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TextField,
  InputAdornment,
  Divider,
  Link,
  Tab,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import DashboardTicketController, { Props } from "./DashboardTicketController.web";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import { ROLE } from "../../../framework/src/Enum";
import moment from "moment";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class AvgResolutionTicket extends DashboardTicketController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const role = localStorage.getItem("userType");
    const society_id = localStorage.getItem("society_id");
    this.setState({ pageContent: "avgResolution", society_id: role === ROLE.SERVICE_PROVIDER ? "" : society_id  }, async () => {
      await this.getTicketDashboardYearList();
      await this.getAllBuildingList();
      await this.getIncidentCategoryList();
      await this.getAverageResolutionTicket();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.searchResident !== this.state.searchResident ||
      prevState.category !== this.state.category ||
      prevState.filterYear !== this.state.filterYear ||
      prevState.filterBuilding !== this.state.filterBuilding ||
      prevState.sort !== this.state.sort ||
      prevState.page !== this.state.page
    ) {
      await this.getAverageResolutionTicket();
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const userType = localStorage.getItem("userType");

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.generalDashboard}>
                <Box className="navigation avg-ticket-navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("My Dashboards")} / <Link href="/DashboardTicket">{t("Ticket Dashboard")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Average Resolution Time")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Average Resolution Time")}
                    </Typography>
                    <Box className="select-box">
                      {userType === ROLE.MANAGER && (
                        <>
                          <select
                            onChange={(e: any) => this.setState({ filterBuilding: e.target.value })}
                            value={this.state.filterBuilding}
                            className="select-year avg-ticket-select-building"
                          >
                            <option value="" disabled>
                              {t("Select Building")}
                            </option>
                            {this.state.buildingList.map((building: any) => {
                              return (
                                <option value={building.id} key={building.id}>
                                  {building.attributes.name}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            className="select-year avg-ticket-filter-sort"
                            value={this.state.sort}
                            onChange={(e: any) => this.setState({ sort: e.target.value })}
                          >
                            <option value="">{t("Sort By")}</option>
                            <option value="asc">{t("Asc")}</option>
                            <option value="desc">{t("Desc")}</option>
                          </select>
                          <select
                            className="select-year avg-ticket-select-year"
                            value={this.state.filterYear}
                            onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                          >
                            {this.state.yearList.map((year: any) => {
                              return (
                                <option value={year} key={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box className="category-box">
                  <Box className="category">
                    {this.state.categoryList.map((category: any) => {
                      return (
                        <Tab
                          key={category.id}
                          onClick={() => {
                            this.setState({ category: category.id, page: 1 }, () => {});
                          }}
                          label={category.name}
                          className={category.id === this.state.category ? "active" : ""}
                        />
                      );
                    })}
                  </Box>
                </Box>

                <Box className="content-boxes ticket-table">
                  <Box className="top-content">
                    <Box className="heading">
                      <h2 className="bold-text">
                        {this.state.totalTicket} {t("Tickets")}
                      </h2>
                      <p>
                        {t("Average Resolution Time")}{" "}
                        <span>
                          {this.state.avgDays} {t("Days")}
                        </span>
                      </p>
                    </Box>
                    <Box className="right-content avg-ticket-table-search">
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        className="search-unit avg-ticket-search-ticket"
                        value={this.state.searchResident}
                        onChange={(e: any) => this.setState({ searchResident: e.target.value })}
                        placeholder={t("Search by ticket number")}
                      />
                    </Box>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table className="unit-table">
                      <TableHead>
                        <TableRow className="avg-ticket-head-row">
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text avg-ticker-head-col avg-ticket-table-head">
                            {t("#")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text avg-ticket-table-head avg-ticker-head-col">
                            {t("Ticket No")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="avg-ticker-head-col bold-text avg-ticket-table-head">
                            {t("Raised By")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text avg-ticket-table-head avg-ticker-head-col">
                            {t("Open Date")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text avg-ticker-head-col avg-ticket-table-head">
                            {t("Close Date")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text avg-ticket-table-head avg-ticker-head-col">
                            {t("Resolution Time")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.ticketList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={8}>{t("No ticket found")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.ticketList.map((incident: any, index: number) => {
                          return (
                            <TableRow key={incident.id}>
                              <TableCell align={languageCondition(language,"right","left")}>{index + 1}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.id}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.attributes.raised_by}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{moment(incident.attributes.open_date, "DD-MM-YYYY").format("DD MMM YYYY")}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                {incident.attributes.close_date &&
                                  moment(incident.attributes.close_date, "DD-MM-YYYY").format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>{incident.attributes.resolutions_time + "" || "-"} {t("Day(s)")}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="unit-pagination avg-ticket-pagination-bar">
                    <p>
                      {t("Showing")} <span>{this.state.ticketList.length}</span> {t("of")}{" "}
                      <span>{this.state.pagination ? this.state.pagination.total_count : 0}</span> {t("results")}
                    </p>
                    {this.state.pagination && (
                      <Pagination
                        className="avg-ticket-pagination"
                        count={this.state.pagination.total_pages}
                        page={this.state.pagination.current_page}
                        siblingCount={2}
                        variant="outlined"
                        shape="rounded"
                        onChange={(event: any, value: any) => this.setState({ page: Number(value) })}
                      />
                    )}
                  </Box>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(AvgResolutionTicket)));
// Customizable Area End
