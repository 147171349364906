// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon, filterIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import ViewMyInvoicesController, { Props } from "./ViewMyInvoicesController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { Menu, MenuItem } from "@szhsin/react-menu";
import moment from "moment";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends ViewMyInvoicesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols view-my-invoice-content-box">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "back-icon-button")} onClick={() => this.props.history.push("/FeesAndPayment")} />
                <p className="bold-text view-my-invoice-navigation" style={{ fontSize: "1.2rem" }}>
                  {t("View my invoices")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <Box className="invoice-sort-menu">
                  <Menu menuButton={<img src={shortIcon} alt="" />} className="chairman-lang-menu">
                    <MenuItem className="invoice-asc-menu" onClick={() => this.setState({ toggleAscDesc: "asc" }, () => this.getMyInvoices())}>
                      {t("Asc")}
                    </MenuItem>
                    <MenuItem className="invoice-desc-menu" onClick={() => this.setState({ toggleAscDesc: "desc" }, () => this.getMyInvoices())}>
                      {t("Desc")}
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className={languageCondition(language, "", "expense-report-menu")}>
                  <Menu menuButton={<img src={filterIcon} alt="" />}>
                    <MenuItem className="all-filter-menu" onClick={() => this.setState({ anchorEl: "" }, () => this.getMyInvoices())}>{t("All")}</MenuItem>
                    <MenuItem className="paid-filter-menu" onClick={() => this.setState({ anchorEl: "Paid" }, () => this.getMyInvoices())}>
                      {t("Paid")}
                    </MenuItem>
                    <MenuItem className="due-filter-menu" onClick={() => this.setState({ anchorEl: "Due" }, () => this.getMyInvoices())}>{t("Due")}</MenuItem>
                    <MenuItem className="overdue-filter-menu" onClick={() => this.setState({ anchorEl: "Overdue" }, () => this.getMyInvoices())}>
                      {t("Overdue")}
                    </MenuItem>
                    <MenuItem className="partial-filter-menu" onClick={() => this.setState({ anchorEl: "Partially_Paid" }, () => this.getMyInvoices())}>
                      {t("Partially Paid")}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              {this.state.myInvoicesList.length === 0 && (
                <Grid item xs={12} className="ViewMyInvoice">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderRadius="8px"
                    bgcolor="white"
                    padding="15px"
                    style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                  >
                    {t("No data available")}
                  </Box>
                </Grid>
              )}
              {this.state.myInvoicesList.map((item: any, index: number) => {
                return (
                  <Grid item xs={12} className="ViewMyInvoice view-my-invoice-list">
                    <Box
                      display="flex"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/Invoice/${item.id}/${item.attributes.invoice_type}`,
                          state: { selectedInvoice: item },
                        });
                      }}
                      justifyContent="space-between"
                      alignItems="center"
                      className="view-invoice-box view-invoice-card-box"
                      borderRadius="8px"
                      bgcolor="white"
                      padding="15px"
                      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                    >
                      <Box style={{ minWidth: "100%" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant={"subtitle2"}>
                            {t("Invoice")}: {moment(item.attributes.title, "DD-MM-YYYY").format("MMMM YYYY")}
                          </Typography>
                          <Typography variant="subtitle2" className={item.attributes.status}>
                            {item.attributes.status === "partially_paid" ? `${t("Partially Paid")}` : `${t(item.attributes.status)}`}
                          </Typography>
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item xs={6} style={{ marginTop: "15px" }}>
                            <Typography variant={"subtitle2"}>{t("Landlord")}</Typography>
                            <Typography variant={"body1"} className="bold-text view-invoice-value" style={{ marginTop: "5px" }}>
                              {item.attributes.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "15px" }} className="ViewMyinfo">
                            <Typography variant={"subtitle2"}>{t("Building Name")}</Typography>
                            <Typography variant={"body1"} className="bold-text view-invoice-value" style={{ marginTop: "5px" }}>
                              {item.attributes.building_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Unit Number")}</Typography>
                            <Typography variant={"body1"} className="bold-text view-invoice-value" style={{ marginTop: "5px" }}>
                              {item.attributes.unit_number}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Amount")}</Typography>
                            <Typography variant={"body1"} className="bold-text view-invoice-value" style={{ marginTop: "5px" }}>
                              {LanguageAttributeHandler(language, item.attributes.currency?.currency, item.attributes.currency?.currency_ar)}{" "}
                              {Number(item.attributes.total_amount.toFixed(2)).toLocaleString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
