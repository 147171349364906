import React from "react";
import {
    Box,
    Typography,
    Button,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { CSSProperties } from '@material-ui/styles';

export const ModalWrapper = (props: any) => {
    const { 
        open, 
        closeFn,
        children
    } = props;
    return <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="modalStyle"
    // @ts-ignore
    open={open}
    onClose={closeFn}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{timeout: 500}}
>
    {/*@ts-ignore*/}
    <Fade in={open}>
        {children}
    </Fade>
</Modal>
}

const webStyle:any = {
    closeDeleteBtn:{
        borderColor: 'rgb(43, 111, 237) none repeat scroll 0% 0%',
        color: 'rgb(43, 111, 237)',
        width:'47.5%',
        height: '45px',
    borderRdaius: '12px',
    fontWeight: '700' as CSSProperties['fontWeight']
    }, 
    confirmDeleteBtn:{
        background: 'rgb(43, 111, 237)',
        borderColor:'rgb(43, 111, 237)',
        color:'#fff',
        boxShadow:'unset',
        width:'47.5%',
        height: '45px',
    borderRdaius: '12px',
    fontWeight: '700' as CSSProperties['fontWeight']
    },
  };