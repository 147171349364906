// Customizable Area Start
import {dashBoardActions} from "./chairmanUIStyles";
import {Box, Button, Divider, Grid, IconButton, Paper, TextField, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import moment from "moment/moment";
import {DownloadIcon} from "./assets";
import React from "react";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import {useTranslation} from "react-i18next";

const InvoicesModal = (props:any) => {
    let {t,invoiceDetails,handleModalClose,paymentType,partialPaymentError,manageDownload,downloadId,generateReceipt,generateReceiptFalse,changePaymentType,generateReceiptTrue,registerPaymentConfirmation,partialPaymentAmount,managePartialAmount,invoiceType} = props
    // console.log("MOHSIN-123",invoiceDetails)
    if(!invoiceDetails) {
        invoiceDetails = {
            "others": 0,
            "status": "due",
            "partial_paid_amount": 0,
            "rent_amount": 0,
            "generated_on": "2023-09-04T18:00:42.519Z",
            "due_date": "2023-09-16T18:00:42.524Z",
            "late_charge": 0,
            "building_name": "",
            "paid_amount": 0,
            "unit_number": "13",
            "month_year": "September 2023",
            "management_fee_amount": 0,
            "total_amount": 0,
            "tax": 0,
            "due_amount": 0,
            "currency": {
                "currency": "USD",
                "currency_ar": "دولار أمريكي"
            },
            "resident_details": {
                "resident_name": "",
                "resident_id": 0
            },
            "owner_details": {
                "owner_name": "",
                "owner_id": 0
            }
        }
    }
    const {i18n} = useTranslation()
    const language = i18n.language

    const checkPaymentType = (invoiceDetails:any) => {
        return invoiceDetails.status === "partially_paid" ? t("Remaining amount to be paid") :t("Total amount to be paid")
    }

    return(
        <div style={dashBoardActions.paper}>
            <div style={dashBoardActions.modalHeader}>
                <Typography variant="h5" className="bold-text" style={dashBoardActions.subHeadingFont}><InvoiceTypeHeading invoiceType={invoiceType} t={t}/> - {invoiceDetails.month_year}</Typography>
                <IconButton onClick={handleModalClose} className="handleCloseGenerateInvoice">
                    <CloseIcon/>
                </IconButton>
            </div>
            <Divider style={{marginLeft:"-100px",width:"200%"}}/>
            {
                generateReceipt ?
                    <Box>
                        <Box style={{marginTop:"15px",display:'flex',alignItems:"center"}}>
                            <IconButton onClick={()=> generateReceiptFalse()} style={{padding:"2px",marginLeft:'-3px',marginRight:'5px'}} className="goBackGenerateInvoice">
                                {languageCondition(language,<ArrowForwardIcon/>,<ArrowBackIcon/>)}
                            </IconButton>
                            <Typography className="bold-text" variant="h6" style={{fontWeight:"bold",marginLeft:"5px",marginRight:"10px"}}>{t("Generate Receipts")}</Typography>
                        </Box>
                        <Box style={{margin:"20px 0px"}}>
                            <Typography style={{fontSize:"16px"}}>
                                {t("Do you want to register the the payment for this invoice and generate a receipt for the payment? you can chose to recognize the full payment or partial payment by selecting one of the two payment options below")}
                            </Typography>
                        </Box>
                        <Box style={{display:"flex"}}>
                            <Typography style={{fontSize:"16px",color:"#181D25",opacity:"0.5"}}>
                                {t("Resident ID:")}
                            </Typography>
                            <Typography className="bold-text" style={{marginLeft:"5px",fontWeight:"bold",fontSize:"16px"}}>
                                {invoiceDetails.resident_details?.resident_id}
                            </Typography>
                        </Box>
                        <Box style={{display:"flex"}}>
                            <Typography style={{fontSize:"16px",color:"#181D25",opacity:"0.5"}}>
                                {t("Resident Name")}:
                            </Typography>
                            <Typography className="bold-text" style={{marginLeft:"5px",fontWeight:"bold",fontSize:"16px"}}>
                                {invoiceDetails.resident_details?.resident_name}
                            </Typography>
                        </Box>

                        <Box style={{display:"flex",marginTop:"20px"}}>
                            <Typography>
                                {checkPaymentType(invoiceDetails)}:
                            </Typography>
                            <Typography className="bold-text" style={{marginLeft:"5px",fontWeight:"bold",color:"#FC8434"}}>
                                {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)} {invoiceDetails.status === "partially_paid" ? invoiceDetails.due_amount.toLocaleString(): invoiceDetails.total_amount.toLocaleString()}
                            </Typography>
                        </Box>
                        <Box style={{display:"flex",marginTop:"20px"}}>
                            <Typography style={{marginLeft:"5px",fontSize:"16px",color:"#181D25",opacity:"0.5"}}>
                                {t("Select Payment Type")}
                            </Typography>
                        </Box>
                        <Box>
                            <FormControl component="fieldset" style={{marginLeft:"10px",marginTop:"5px"}}>
                                <RadioGroup style={{display:"flex",flexDirection:"row"}} aria-label="quiz" name="quiz" value={paymentType} onChange={changePaymentType}>
                                    <FormControlLabel value="full" control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#CCCCCC"}}/>} checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}}/>} />} label={invoiceDetails.status === "partially_paid" ? t("Register Remaining Full payment") : t("Register Full payment")} />
                                    <FormControlLabel value="partial" control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#CCCCCC"}}/>} checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}}/>} />} label={t("Register Partial Payment")} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        {
                            paymentType === "partial" &&
                            <>
                                <Box style={{display:'flex',alignItems:"center",marginTop:"20px"}}>
                                    <TextField type="number" className="partailAmount" placeholder={t("Enter Partial paid amount")} value={partialPaymentAmount} onChange={(e)=> managePartialAmount(e.target.value,invoiceDetails.status === "partially_paid" ? invoiceDetails.due_amount : invoiceDetails.total_amount )} id="reminAmountFiled"  variant="outlined" style={{border:"0.1px solid rgb(209,209,209)",borderRadius:"8px"}}/>
                                    {
                                        !partialPaymentError &&
                                        <Typography style={{marginLeft:"30px"}}>
                                            {t("Remaining Amount")} :  {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)} {(parseFloat(invoiceDetails.status === "partially_paid" ? invoiceDetails.due_amount :invoiceDetails.total_amount) - parseFloat(partialPaymentAmount || 0)).toFixed(2)}
                                        </Typography>
                                    }
                                </Box>
                                <Typography color="error">{partialPaymentError}</Typography>
                            </>
                        }
                        <Box style={{marginTop:"50px",width:"100%",display:"flex",justifyContent:"flex-end"}}>
                            <Button variant="contained" style={dashBoardActions.receiptCancel} color="primary" onClick={()=> generateReceiptFalse()}>{t("Close")}</Button>
                            <Button variant="contained" style={dashBoardActions.paymentbtn} color="primary" onClick={registerPaymentConfirmation}>{t("Register Payment")}</Button>
                        </Box>
                    </Box>
                    :
                    <Box style={{marginTop:"20px"}}>
                        <Grid container spacing={2} style={dashBoardActions.residetails}>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Owner Name:")}</Typography>
                                <b className="bold-text">{invoiceDetails.owner_details.owner_name}</b>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Resident Name:")}</Typography>
                                <b className="bold-text">{invoiceDetails.resident_details?.resident_name}</b>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Building Name:")}</Typography>
                                <b className="bold-text">{invoiceDetails.building_name}</b>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Unit Number:")}</Typography>
                                <b className="bold-text" >{invoiceDetails.unit_number}</b>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} xs={12} style={dashBoardActions.residetails}>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Resident ID:")}</Typography>
                                <b className="bold-text">{invoiceDetails.resident_details?.resident_id}</b>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={dashBoardActions.commonColor} component="h5">{t("Generated on:")}</Typography>
                                <b className="bold-text">{moment(invoiceDetails.generated_on).format("DD MMM YYYY")}</b>
                            </Grid>
                        </Grid>
                        <div style={{marginTop:"30px",marginBottom:"5px"}}>
                            <b className="bold-text" style={{color:"#2b6fed"}}>{t("Summary")} </b>
                        </div>
                        <Paper elevation={0} style={dashBoardActions.summary}>
                            <div>
                                <div className='resident-data'>
                                    <InvoiceTypeAmount invoiceType={invoiceType} t={t} />
                                    <b className="bold-text"> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.management_fee_amount?.toLocaleString() || 0}</b>
                                </div>
                                <div className='resident-data'>
                                    <Typography component="h5">{t("Late Charges")}:</Typography>
                                    <b className="bold-text"> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.late_charge?.toLocaleString() || 0 }</b>
                                </div>
                                <div className='resident-data'>
                                    <Typography component="h5">{t("Tax")}:</Typography>
                                    <b className="bold-text"> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}   {invoiceDetails.tax?.toLocaleString() || 0}</b>
                                </div>
                                <div className='resident-data'>
                                    <Typography component="h5">{t("Others")}:</Typography>
                                    <b className="bold-text"> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.others?.toLocaleString() || 0}</b>
                                </div>
                                <hr />
                                <div className='resident-data' style={{marginTop:"15px",marginBottom:"0px"}}>
                                    <Typography component="h5">{t("Total Amount")}</Typography>
                                    <b className="bold-text" style={{color:"#FC8434"}}> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.total_amount.toLocaleString()}</b>
                                </div>
                                <PartiallyPaidAmountDisplay t={t} invoiceDetails={invoiceDetails} language={language}/>
                            </div>
                        </Paper>
                        <Grid container xs={12} style={dashBoardActions.residetails}>
                            <Grid item xs={8} style={{display:"flex", marginTop:"0px",alignItems:"center",cursor:"pointer"}} onClick={()=> manageDownload(downloadId)}>
                                <img src={DownloadIcon} width="20px" height="20px" style={{marginRight:"10px"}}/>
                                <Typography component="h5" style={{fontWeight:"bold"}}>{t("Download Invoice")}</Typography>
                            </Grid>
                            <Grid item xs={4} style={{display:"flex",justifyContent:'flex-end'}}>
                                <GenerateButton t={t} invoiceDetails={invoiceDetails}  generateReceiptTrue={generateReceiptTrue}/>
                            </Grid>
                        </Grid>
                    </Box>
            }
        </div>
    )
}

const PartiallyPaidAmountDisplay = (props:any) => {
    const {t,invoiceDetails,language} = props;
    return(
        <>
        {
            invoiceDetails.status === "partially_paid" &&
            <>
                <div className='resident-data' style={{marginTop:"15px",marginBottom:"0px"}}>
                    <Typography component="h5">{t("Received Amount")}</Typography>
                    <b className="bold-text" style={{color:"#FC8434"}}> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.paid_amount.toLocaleString()}</b>
                </div>
                <div className='resident-data' style={{marginTop:"15px",marginBottom:"0px"}}>
                    <Typography component="h5">{t("Remaining Amount")}</Typography>
                    <b className="bold-text" style={{color:"#FC8434"}}> {languageCondition(language,invoiceDetails.currency.currency_ar,invoiceDetails.currency.currency)}  {invoiceDetails.due_amount.toLocaleString()}</b>
                </div>
            </>
        }
        </>
    )
}
const GenerateButton = (props:any) => {
    const {t,invoiceDetails,generateReceiptTrue} = props;
    return(
        <>
            {
                invoiceDetails?.status === "partially_paid" &&
                <Button className="PartialPaymentGenerateButton" variant="contained" style={dashBoardActions.receiptbtn} color="primary" onClick={()=> generateReceiptTrue()}>{t("GENERATE RECEIPT")}</Button>
            }
            {
                invoiceDetails?.status === "due" &&
                <Button variant="contained" className="duePaymentGenerateButton" style={dashBoardActions.receiptbtn} color="primary" onClick={()=> generateReceiptTrue()}>{t("GENERATE RECEIPT")}</Button>
            }
            {
                invoiceDetails?.status === "overdue" &&
                <Button variant="contained" className="overduePaymentbuttonforGenerate" style={dashBoardActions.receiptbtn} color="primary" onClick={()=> generateReceiptTrue()}>{t("GENERATE RECEIPT")}</Button>
            }
            {
                invoiceDetails?.status === "paid" &&
                <Typography variant="subtitle2" className="statusOngoingGreen paidStatusfor Invoice" style={{textAlign:"center",textTransform:"uppercase",justifySelf:"flex-end"}}>{invoiceDetails?.status}</Typography>
            }
        </>
    )
}



const InvoiceTypeHeading = (props:any) => {
    const invoiceType = props.invoiceType
    const t = props.t
    return(
        <>
            {invoiceType === "facility_rental" ? t("Facility Rental"): t("Management Fee Invoice")}
        </>
    )
}

const InvoiceTypeAmount = (props:any) => {
    const invoiceType = props.invoiceType
    const t = props.t
    return(
        <>
            {
                invoiceType === "facility_rental" ?
                    <Typography component="h5">{t("Facility Rental Amount")}:</Typography>
                    :
                    <Typography component="h5">{t("Management Fee Amount")}:</Typography>
            }
        </>
    )
}
export default InvoicesModal

// Customizable Area End