// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

interface S {}

export interface Props {
  match: any;
  navigation: any;
  t: (val: string) => string; 
  history: any;
  location: any;
  i18n: {
    language: string;
  };
  id: string;
}

interface SS {
  id: any;
}

export default class FeeAndPaymentController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.state = {};

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}

// Customizable Area End
