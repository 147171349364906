// Customizable Area Start
//@ts-nocheck
//@ts-ignore

import React from "react";
import "../../dashboard/src/Dashboard.web.css";
import {Container, FormControl, InputBase, Typography, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import '../../../web/src/i18n.js';
import AuditorController, {Props,} from "./AuditorDasController.web";
import GeneralSideBar from "../../dashboard/src/GeneralSideBar.web";
import SearchIcon from "@material-ui/icons/Search";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class AuditorDashboardGeneral extends AuditorController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {t} = this.props
    return (
      <>
        <GeneralHeader style={{ background: "#F7F9FE" }}>
              <Container>
                <Box>
                  <Box>
                    <Typography variant="body1">
                      {t("My Dashboards")} /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("General Dashboard")}
                      </Box>
                    </Typography>
                    <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                      <Typography className="bold-text AuditorDashboard" style={{...dashBoard.subHeading,marginTop:"10px",fontSize:"28px"}}>
                        {t("General Dashboard")}
                      </Typography>
                      <Box>
                        <Box style={{display:"flex"}} className="table-top">
                          <div className="search-box" style={dashBoard.searchBox}>
                            <SearchIcon />
                            <InputBase placeholder={t("Search Building")} style={dashBoard.search} className="search searchBuildingInput" value={this.state.searchName}  onChange={this.handleSearch}/>
                          </div>
                          <FormControl style={dashBoard.YearMain} className="yearTab">
                            <NativeSelect
                                className="yearSelection"
                                value={this.state.Year}
                                onChange={(e)=> this.setState({Year:e.target.value},()=>{this.getBudgetBuildingList(this.state.Year,this.state.searchName)})}
                            >
                              <option value="">{t("Select Year")}</option>
                              <option value={(new Date().getFullYear()) - 3}>{(new Date().getFullYear()) - 3}</option>
                              <option value={(new Date().getFullYear()) - 2}>{(new Date().getFullYear()) - 2}</option>
                              <option value={(new Date().getFullYear()) - 1}>{(new Date().getFullYear()) - 1}</option>
                              <option value={(new Date().getFullYear())}>{(new Date().getFullYear())}</option>
                              <option value={(new Date().getFullYear()) + 1}>{(new Date().getFullYear()) + 1}</option>
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={4} style={{ marginTop: 15 }}>
                  {
                    this.state.dashboardData?.map((item:any,index:any)=>{
                      return(
                          <Grid item sm={4} key={index}>
                            <Box style={dashBoard.Cards} onClick={()=> this.props.history.push(`/AuditReport/${item.building_id}`)}>
                              <Typography style={dashBoard.subHeading}>
                                {item?.building_name}
                              </Typography>
                              <Box style={dashBoard.cardBottom}>
                                <Typography variant="body2" style={{marginRight:"10px"}}>{t("Generated Audit Report")}</Typography>
                                <Box component="span" style={dashBoard.bottomColor}>
                                  {item?.audit_report_generated}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                      )
                    })
                  }
                </Grid>
              </Container>
        </GeneralHeader>
      </>
    );
  }
}

const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 0,
  },
  cardBottom: {
    display: "flex",
    gap: 0,
    marginTop: 10,
  },
  bottomColor: {
    color: "red",
    marginRight:"10px"
  },
  searchBox:{
    display: "flex",
    alignItems: "center",
    border: "1px solid #f0f0f0",
    padding: "5px 10px",
    borderRadius: "8px",
    width: "250px",
    backgroundColor:"white",
    marginRight:'10px'
  },
  search:{
    marginLeft: "5px",
    width: "100%",
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10,
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
    cursor: "pointer",
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop:7,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};


export default withTranslation()(withStyles(dashBoard)(withRouter(AuditorDashboardGeneral)));

// Customizable Area End
