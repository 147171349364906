// Customizable Area Start

//@ts-ignore
//@ts-nocheck
import * as React from "react";
// custom components
import {
    Button,
    Grid,
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControl,
    TextField,
    IconButton,
} from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import "../assets/css/style.scss";
import {Formik, Form, Field, ErrorMessage} from "formik";
import EmailAccountRegistrationController, {Props} from "./EmailAccountRegistrationController.web.tsx";
import {withRouter} from "react-router";
import {Back_btn, building, Building1, ReqHome, searchIcon, unit} from "./assets";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GoogleMapReact from 'google-map-react';
import {withTranslation} from "react-i18next";
// @ts-ignore
import {dummyVal1, dummyVal2} from "../__mock__/MockData";

const MapPinManual = ({societyId, society, manageClick}) => {
    return (
        <>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}
                 className="mapPinManualRegistration" onClick={() => manageClick(societyId)}>
                <IconButton style={{padding: 0}} className="mapPinManualRegistration">
                    <RoomIcon style={{color: "#DD946A"}} className="mapPinManualRegistration"/>
                </IconButton>
            </Box>
        </>
    )
};

class ManuallyRegisterUnit extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.getSocietyList();
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <Grid container spacing={2} className="auth-container manualRegistration">
                    <Grid item xs={12} md={7}
                          className="auth-cols inputPlaceholderRegistration manualRegistrationmanualRegistration"
                          style={{justifyContent: "unset"}}>
                        <div className="manualRegistration">
                            <Grid container style={{margin: "1rem", width: "90%"}} className="manualRegistration">
                                <Grid xs={12}>
                                    <img src={Back_btn} alt="Back Button" onClick={() => window.history.back()}
                                         style={{marginTop: "1rem", marginLeft: "0rem"}}
                                         className="manualRegistration manualUnitBackButton"/>
                                </Grid>
                            </Grid>
                            <Grid container style={{margin: "1rem", width: "90%", marginBottom: ".5rem"}}
                                  className="manualRegistration">
                                <Grid xs={12} className="manualRegistration">
                                    <p className="text-left bold-text manualRegistration"
                                       style={{fontSize: "26px", fontWeight: 700, marginTop: "1rem"}}>
                                        {t("Register the Unit Manually")}
                                    </p>
                                </Grid>
                            </Grid>
                            <Grid container className="manualRegistration"
                                  style={{margin: "1rem", width: "90%", marginTop: "0.5rem"}}>
                                <Grid xs={12} className="manualRegistration">
                                    <p className="text-left manualRegistration"
                                       style={{marginBottom: "0.5rem", fontSize: "15px"}}>
                                        {t("Please select the location of the building")}
                                        <span className="manualRegistration" style={{color: "#DD946A"}}/>
                                    </p>
                                </Grid>
                            </Grid>
                            <Formik
                                initialValues={{selectComplex: "", selectBuilding: " ", selectUnit: ""}}
                                validationSchema={this.addressSchemaManual()}
                                onSubmit={() => {
                                    this.setState({showDialog: true});
                                }}
                            >
                                {({values, setFieldValue}) => (
                                    <Form translate="yes" className="commonForm manualRegistration">
                                        <Grid container className="manualRegistration">
                                            <Grid xs={12} className="flex manualRegistration" justifyContent="center">
                                                {/*<img src={Map.default} />*/}
                                                <div style={{
                                                    height: '300px',
                                                    width: '90%',
                                                    position: "relative",
                                                    borderRadius: "15px"
                                                }} className="manualRegistration">
                                                    <GoogleMapReact key={new Date().getTime()}
                                                                    defaultCenter={this.state.location}
                                                                    defaultZoom={this.state.defaultZoom}
                                                                    style={{height: '400px', width: '400px'}}>
                                                        {this.state.allComplex && this.state.allComplex.map((item) => (
                                                            <MapPinManual
                                                                key={item.value} lat={item.lat}
                                                                lng={item.long} society={item.label}
                                                                societyId={item} manageClick={(text) => {
                                                                this.handleInputChangeCOm("", text, false);
                                                                setFieldValue("selectComplex", text)
                                                            }}
                                                            />
                                                        ))}
                                                    </GoogleMapReact>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="manualRegistration">
                                            <Grid xs={12} className="manualRegistration"/>
                                        </Grid>
                                        <Box className="commonForm manualRegistration"
                                             style={{marginTop: 0, marginBottom: 15,}}>
                                            <Box className="formGroup customSelect manualRegistration"
                                                 style={{marginBottom: "5px"}}>
                                                <Box className="formInputGrp manualRegistration">
                                                   <span className="frmLeftIcons manualRegistration" style={{left: '38px', top: '27%'}}>
                                                       <img src={searchIcon} className="frm-icons manualRegistration" alt="House Icon" height="24px" style={{marginTop: "2px"}}/>
                                                    </span>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        className={this.state.selectComplex === "" && "autoCompleteSearchComplex"}
                                                        options={this.state.allComplex}
                                                        getOptionLabel={(option: any) => option.label || ""}
                                                        style={{
                                                            borderRadius: 25,
                                                            color: "#b5b5b5",
                                                            paddingLeft: 20,
                                                            width: '89%'
                                                        }}
                                                        onChange={(e: any, newValue) => {
                                                            this.handleInputChangeCOm(e, newValue, true, setFieldValue);
                                                            setFieldValue("selectComplex", newValue);
                                                        }}
                                                        value={values.selectComplex} autoSelect
                                                        placeholder={t("Search Building / Complex")}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                className={this.state.selectComplex ? 'complex-input' : ''}
                                                                placeholder={t("Search Complex")}
                                                                variant="outlined"/>}
                                                    />

                                                </Box>
                                            </Box>
                                            <ErrorMessage className="text-error SelectManuall" component="Typography"
                                                          name="selectComplex"
                                                          style={{marginLeft: "30px", marginBottom: "10px"}}/>
                                        </Box>
                                        {
                                            this.state.showBuildings ?
                                                <Grid container className="selectManualBuilding" style={{
                                                    margin: '1rem', marginTop: 0, marginBottom: '-1.5rem', width: '90%'
                                                }}>
                                                    <Grid xs={12} className='formGroup customSelect selectManualBuilding'>
                                                        <FormControl variant="outlined" fullWidth className="selectManualBuilding">
                                                            <span className="frmLeftIcons selectManualBuilding" >
                                                              <img src={building} className="frm-icons selectManualBuilding" alt="House Icon"/>
                                                            </span>
                                                            <Select
                                                                name='selectBuilding' className="SelectBuildingManualUnit"
                                                                value={values.selectBuilding}
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                onChange={(e) => {
                                                                    this.handleChange(e);
                                                                    setFieldValue("selectBuilding", e.target.value);
                                                                }}
                                                                label={t("Building")}
                                                                style={{
                                                                    borderRadius: 25,
                                                                    border: "0.1px solid rgb(209 209 209 / 100%)",
                                                                    color: "#BEBEBE",
                                                                    paddingLeft: 55,
                                                                }}
                                                            >
                                                                <MenuItem className="selectManualBuilding" value=" ">{t("Select building")}</MenuItem>
                                                                {this.state.allBuilding &&
                                                                    this.state.allBuilding.map((item) => (
                                                                        <MenuItem className="selectManualBuilding" key={item.id} value={item}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                        <ErrorMessage className="text-error selectManualBuilding" component="Typography"
                                                                      name="selectBuilding"/>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid container className="selectManualBuildingNo" style={{
                                                    margin: '1rem',
                                                    marginTop: 0,
                                                    marginBottom: '-1.5rem',
                                                    width: '90%'
                                                }}/>
                                        }

                                        <Grid container
                                              style={{margin: "1rem", marginTop: 0, marginBottom: 0, width: "90%"}}>
                                            <Grid xs={12} className="formGroup customSelect">
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    className="formInputGrp selectUnitManualRegistration"
                                                    style={{position: "relative"}}
                                                >
                                                  <span className="frmLeftIcons selectUnitManualRegistration">
                                                    <img src={unit} className="frm-icons selectUnitManualRegistration" alt="House Icon"/>
                                                  </span>
                                                    <Field
                                                        name="selectUnit"
                                                        value={values.selectUnit || ""}
                                                        placeholder="Enter Unit" className="formInput1 unitListLinkageunit"
                                                        style={{
                                                            color: "#BEBEBE",
                                                            paddingLeft: 55,
                                                            marginTop: 7,
                                                            paddingBottom: 18,
                                                            width: "100%",
                                                            border: "0.1px solid rgb(209 209 209 / 100%)",
                                                            borderRadius: 25,
                                                        }}
                                                        onChange={(e) => {
                                                            this.handleChange2(e);
                                                            setFieldValue("selectUnit", e.target.value);
                                                        }}
                                                    />
                                                </FormControl>
                                                <ErrorMessage className="text-error selectUnitManualRegistration" component="Typography"
                                                              name="selectUnit"/>
                                            </Grid>
                                        </Grid>
                                        <Box className="customButton selectUnitManualRegistration" style={{width: "90%", margin: "1rem"}}>
                                            <Button variant="contained" type="submit" className="selectUnitManualRegistration">
                                                {t("SEND REGISTRATION REQUEST")}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} className="auth-cols ManualRegistration">
                        <Box className="right-block ManualRegistration" display={{xs: "none", md: "flex"}}>
                            <img src={Building1.default} className="building-logo ManualRegistration" alt=""/>
                        </Box>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.showDialog} onClose={() => this.setState({showDialog: false})}
                    aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                    className="diloag-wrapper ManualRegistration"
                    PaperProps={{
                        style: {
                            borderRadius: "15px", margin: 0,
                            padding: "10px 25px 0px 25px",
                        },
                    }}
                >
                    <Box className="diloag-body ManualRegistration">
                        <Box className="diloag-header ManualRegistration" style={{flexDirection: "column", border: "none"}}>
                            <img src={ReqHome} className="tenet-logo ManualRegistration" alt="" style={{width: 50, marginBottom: 10}}/>
                            <DialogTitle
                                className="alert-dialog-title1 bold-text ManualRegistration" id="alert-dialog-title" style={{overflow: "visible", width: "auto", fontSize: 20}}
                            >
                                <h1 className="bold-text ManualRegistration">{t("Sure want to register this unit?")}</h1>
                            </DialogTitle>
                            <p style={{paddingTop: 20}} className="ManualRegistration">
                                {t("Are you sure that you want to register the unit")} {this.state.selectUnit} {t("of")}{" "}{this.state.selectBuilding.name}?
                            </p>
                        </Box>
                        <Box className="dialog-footer desktop-ui ManualRegistration" style={{display: "flex", justifyContent: "center"}}>
                            <DialogActions className="customButton ManualRegistration">
                                <Button variant="contained" className="ManualRegistration SubmitButtonLinkage" onClick={() => this.createRequestManual(this.state.values)}>
                                    {t("Yes, Register")}
                                </Button>
                                <Button onClick={() => this.setState({showDialog: false})} variant="text" className="dialogClose">
                                    {t("No, Don’t Register")}
                                </Button>
                            </DialogActions>
                        </Box>
                    </Box>
                </Dialog>
                <Button style={{display:"none"}} className="manualRegistrationClick1" onClick={()=> this.handleInputChangeCOm({},dummyVal1,true,(check)=>{console.log(check)})}></Button>
                <Button style={{display:"none"}} className="manualRegistrationClick2" onClick={()=> this.handleInputChangeCOm({},dummyVal2,false,(check)=>{console.log(check)})}></Button>
            </>
        );
    }
}

export default withTranslation()(withRouter(ManuallyRegisterUnit));


// Customizable Area End