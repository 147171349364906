Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LeadManagement";
exports.labelBodyText = "LeadManagement Body";

exports.btnExampleTitle = "CLICK ME";

exports.ApiContentType = "application/json";

exports.buildingName = 'Building Name is required'
exports.totalArea = 'Total Area is required'
exports.totalFloors = 'Total Floors is required'
exports.totalUnits = 'Total Units is required'
exports.locationLink = 'Location Link'
exports.latitude = 'Latitude is required'
exports.longitude = 'Longitude is required'
exports.aboutUs = 'About Us is required'
exports.unitNumber = 'Unit Number is required'

exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.apiMethodTypePatch = "PATCH";
exports.apiMethodTypePut = "PUT";
// Customizable Area End
