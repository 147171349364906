// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation?: any;
  id?: string;
  location: any;
  match: any;
  classes?: any;
  history: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  optionId: string;
  id: string;
  type: string;
  complexId: string;

  role: any;
  targetAudience: any;

  poll: {
    question: string;
    option: any[];
  };

  surveys: any[];

  isPollAnonyms: boolean;

  name: string;
  description: string;
  complex: string;
  building: string;
  startDate: string;
  endDate: string;
  status: string;

  awaited: number;
  received: number;

  isDrawerOpen: boolean;

  drawerItem: any;
}

interface SS {}

export default class CEPollSurveyController extends BlockComponent<Props, S, SS> {
  GetPollSurveyDetailsCallId: string = "";
  EndPollStatusCallId: string = "";
  EndResponseOptionUserCallId: string = "";
  classes: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      id: "",
      optionId: "",
      type: "Poll",
      complexId: "",

      role: "",
      targetAudience:"",

      name: "",
      description: "",
      complex: "",
      building: "",
      startDate: "",
      endDate: "",
      status: "",

      poll: {
        question: "",
        option: [],
      },

      surveys: [],

      isPollAnonyms: false,

      awaited: 0,
      received: 0,

      isDrawerOpen: false,

      drawerItem: {
        vote: "Yes",
        people: "12",
        data: [],
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
        this.checkDetailsAPICallResponse(apiRequestCallId, responseJson);
      }
    }
  }

  checkDetailsAPICallResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.GetPollSurveyDetailsCallId) {
      this.handlePollSurveyDetailsResponse(responseJson);
    } else if (apiRequestCallId === this.EndPollStatusCallId) {
      this.handleEndPollResponse(responseJson);
    } else if (apiRequestCallId === this.EndResponseOptionUserCallId) {
      this.handleResponseOptionUserResponse(responseJson);
    }
  }

  async componentDidMount() {
    const userRole = localStorage.getItem("role");
    const society_id = localStorage.getItem("society_id") || "";
    this.setState({ role: userRole, complexId: society_id }, () => {
      this.getDataId();
    });
  }

  getDataId = () => {
    let { location } = this.props.navigation.history;
    if (location.state && location.state.id) {
      const { id, type } = location.state;
      this.setState({ id, type }, () => {
        console.log(id, type);
        this.getPollSurveyDetails();
      });
    }
  };

  getPollSurveyDetails = async () => {
    let endpoint = "";
    const { id, type, complexId } = this.state;

    if (type === "Polls") {
      endpoint = `society_managements/${complexId}/bx_block_polling/polls/${id}`;
    } else {
      endpoint = `society_managements/${complexId}/bx_block_survey/surveys/${id}`;
    }

    this.GetPollSurveyDetailsCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${endpoint}`,
    });
  };

  handlePollSurveyDetailsResponse = (responseJson: any) => {
    const { type } = this.state;
    if (type === "Polls") {
      if (responseJson && responseJson.poll) {
        const poll = responseJson.poll;
        this.setState({
          name: poll.title,
          description: poll.description,
          complex: poll.complex_name.toString(),
          building: poll.building_name.toString(),
          startDate: poll.start_date,
          endDate: poll.end_date,
          status: poll.status,

          poll: {
            question: poll.question,
            option: poll.polling_options,
          },

          isPollAnonyms: poll.poll_type,

          awaited: poll.awaited,
          received: poll.completed_answers,
        });
      }
    } else {
      if (responseJson && responseJson.surveys) {
        const survey = responseJson.surveys.data;
        this.setState({
          name: survey.attributes.title,
          description: survey.attributes.description,
          complex: survey.attributes.complex.name,
          building: survey.attributes.building_name,
          startDate: survey.attributes.start_date,
          endDate: survey.attributes.end_date,
          status: survey.attributes.status,
          awaited: survey.attributes.awaited,
          received: survey.attributes.total_response,
          surveys: survey.attributes.survey_questions,
          targetAudience: survey.attributes.audience_name.join(", "),
        });
      }
    }
  };

  endPollStatus = async () => {
    const { id, complexId } = this.state;
    this.EndPollStatusCallId = await CEApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `/society_managements/${complexId}/bx_block_polling/polls/${id}/poll_preview_update?end_poll=true`,
    });
  };

  endSurveyStatus = async () => {
    const { id, complexId } = this.state;
    this.EndPollStatusCallId = await CEApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `/society_managements/${complexId}/bx_block_survey/surveys/${id}/survey_preview_update?end_survey=true`,
    });
  };

  handleEndPollResponse = (responseJson: any) => {
    this.getPollSurveyDetails();
  };

  getResponseOptionUser = async (search: string) => {
    const { id, optionId, complexId } = this.state;
    this.EndResponseOptionUserCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_polling/answers?poll_id=${id}&polling_option_id=${optionId}&search=${search}`,
    });
  };

  handleResponseOptionUserResponse = (responseJson: any) => {
    if (responseJson && responseJson.answers) {
      this.setState({
        drawerItem: {
          ...this.state.drawerItem,
          data: responseJson.answers.data,
        },
      });
    }
  };

  navigateToPollSurveyReports = () => this.props.history.push("/CompanyEmployee/CEPollSurveyReports", { id: this.state.id, type: this.state.type });

  navigateToPollSurvey = () => this.props.history.push("/CompanyEmployee/CEPoll");

  getBackgroundColors = (status: string) => {
    if (status === "ongoing") {
      return "#FC8434";
    } else if (status === "upcoming") {
      return "#2B6FED";
    } else {
      return "#1EC65B";
    }
  };

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  };

  getPollCardColor = () => {
    return "#1BCD47";
  };
}

// Customizable Area End
