// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openclientTicket: any;
}
export const ClientTicketImageComponent: React.FC<MyComponentProps>  = ({ openclientTicket }) => {
  const strokeColor = openclientTicket 
  return (
<svg xmlns="http://www.w3.org/2000/svg"  id="body_1" width="29" height="29">

<g transform="matrix(0.5576923 0 0 0.5576923 0 0)">
	<g transform="matrix(0.1 0 -0 -0.1 0 52)">
        <path d="M168 373C 86 291 14 217 8 206C 0 192 4 182 27 158C 50 134 60 130 86 135C 125 142 144 123 134 85C 129 64 133 52 156 29C 171 13 189 0 195 0C 208 0 520 313 520 326C 520 332 507 349 491 364C 468 387 456 391 435 386C 397 376 377 396 386 433C 391 456 387 468 364 491C 349 507 332 520 327 520C 322 520 250 454 168 373zM352 425C 350 411 355 389 364 375C 377 355 389 350 419 350C 477 350 487 330 447 292C 422 268 409 262 391 267C 369 272 368 271 373 249C 379 229 366 211 288 133C 190 34 170 28 170 96C 170 140 139 172 101 168C 85 167 65 172 56 179C 40 192 47 202 131 286C 211 366 228 378 249 373C 271 368 272 370 267 391C 262 409 268 422 292 447C 321 477 325 478 340 465C 351 455 355 440 352 425z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M276 351C 267 336 299 315 312 328C 317 333 319 343 316 349C 307 362 283 363 276 351z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M324 309C 316 295 345 266 359 274C 367 279 367 287 360 301C 348 322 335 326 324 309z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
	</g>
</g>
</svg>
  );
};
// Customizable Area End
