// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  withStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  Input,
  FormControl,
  Drawer,
  Link,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  BuildingIcon,
  BuildingLogo,
  CityIcon,
  CompanyIcon,
  ComplexIcon,
  CountryIcon,
  DeleteIcon,
  EditIcon,
  EmailIcon,
  FeeTypeIcon,
  IDDateIcon,
  IDNumberIcon,
  IDTypeIcon,
  ManagerIcon,
  MobileIcon,
  PdfIcon,
  UnitIcon,
  UploadIcon,
} from "./assets";
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
import { PropertyManagerStyleWeb } from "./PropertyManagerStyle.web";
import RegisterPropertyManagerController, { Props } from "./RegisterPropertyManagerController.web";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { LanguageAttributeHandler, ErrorHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class RegisterPropertyManager extends RegisterPropertyManagerController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const propertyList = JSON.parse(sessionStorage.getItem("propertyList") as any);
    if (propertyList) {
      this.setState({ propertyList: propertyList }, () => {
        this.getPMCountryList();
        this.getIdTypeList();
      });
    } else {
      this.setState({ propertyList: [] }, () => {
        this.getPMCountryList();
        this.getIdTypeList();
      });
    }
  }

  render() {
    const { classes, t, i18n }: any = this.props;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh" }} className={classes.registerManager}>
          <Grid container className={languageCondition(language, "arabic-grid", "")}>
            <Grid item xs={12} md={7}>
              <Box>
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <Link href="/PropertyManagers">
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                    </Link>
                    <span className="bold-text">{t("Add Property Manager")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content form">
                    <Formik
                      enableReinitialize={true}
                      initialValues={this.state.propertyManagerForm}
                      validationSchema={this.registerPropertyManagerFormSchema}
                      onSubmit={(values: any, { resetForm }) => {
                        if (this.checkPropertyPhoneValidation(values) && this.checkProperty()) {
                          this.setState({ loading: true }, () => {
                            this.createPropertyManager(values);
                            resetForm();
                          });
                        }
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                          <Form onSubmit={handleSubmit} translate="yes">
                            <Box className="select-input-box">
                              <FormControl fullWidth>
                                <Input
                                  value={values.companyName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="companyName"
                                  className="select-input input"
                                placeholder={t("Company Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={CompanyIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {ErrorHandler(t, touched.companyName, errors.companyName)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.managerName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="managerName"
                                  className="select-input input"
                                  placeholder={t("Manager Full Name")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={ManagerIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {ErrorHandler(t, touched.managerName, errors.managerName)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="email"
                                  className="select-input input"
                                  placeholder={t("Email ID (will be your user name)")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={EmailIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {ErrorHandler(t, touched.email, errors.email)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="mobile-box">
                                  <Box>
                                    <FormControl variant="outlined">
                                      <PhoneInput
                                        inputProps={{ name: "selectCode" }}
                                        enableSearch={true}
                                        value={values.countryCode}
                                        onChange={(e: any) => {
                                          setFieldValue("countryCode", `+${e}`);
                                        }}
                                        country={"us"}
                                      />
                                    </FormControl>
                                  </Box>
                                  <Box className="divider" />
                                  <Input
                                    value={values.mobileNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="mobileNumber"
                                    className="mobile-input"
                                    placeholder={t("Mobile")}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <img src={MobileIcon} alt="" />
                                      </InputAdornment>
                                    }
                                  />
                                </Box>
                                {ErrorHandler(t, touched.mobileNumber, errors.mobileNumber)}
                              </FormControl>
                              <h4 className="bold-text" style={{ marginTop: "18px" }} >
                                {t("Property Details")}
                              </h4>
                              {this.state.propertyList.map((property: any, index: number) => {
                                return (
                                  <Box className="rent-history-box unit-box" key={index}>
                                    <Box className="heading">
                                      <h4 className="bold-text">
                                        {t("Building")} {property.buildingName} {t("Unit")} {property.unitName}
                                      </h4>
                                      <Box className="box-icons">
                                        <img
                                          className="edit-property-icon"
                                          src={EditIcon}
                                          alt="edit"
                                          onClick={() => {
                                            this.setState({ propertyId: index + "", propertyForm: property }, () => {
                                              this.handleOpenEditPropertyModal();
                                            });
                                          }}
                                        />
                                        <img
                                          className="delete-property-icon"
                                          src={DeleteIcon}
                                          alt="delete"
                                          onClick={() => {
                                            const newPropertyList = this.state.propertyList.filter(
                                              (property: any, id: number) => id !== index
                                            );
                                            this.setState({ propertyList: newPropertyList }, () => {
                                              sessionStorage.setItem(
                                                "propertyList",
                                                JSON.stringify(this.state.propertyList)
                                              );
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                    <Box className="unit-info">
                                      <span>{t("Complex / Building")}</span>
                                      <p>{property.buildingName}</p>
                                    </Box>
                                    <Box className="unit-info">
                                      <span>{t("Contract")}</span>
                                      <p>
                                        {moment(property.startDate, "YYYY-MM-DD").format("MMMM DD, YYYY")} -{" "}
                                        {moment(property.endDate, "YYYY-MM-DD").format("MMMM DD, YYYY")}
                                      </p>
                                    </Box>
                                    <Box className="unit-info">
                                      <span>{t("Charges")}</span>
                                      <p>
                                        {property.rent}{" "}
                                        {property.feeType === "Fixed Percentage" ? "%" : property.currency} / Month
                                      </p>
                                    </Box>
                                  </Box>
                                );
                              })}
                              <Button
                                className="add-rent-history-btn"
                                onClick={() => this.handleOpenAddPropertyModal()}
                              >
                                {t("+ Add Another Property")}
                              </Button>
                              <FormControl fullWidth>
                                <Box className="select-box">
                                  <Select
                                    displayEmpty
                                    value={values.idType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="idType"
                                    className="select-input"
                                    input={<OutlinedInput />}
                                  >
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "property-register-id-select")}>
                                      {t("Manager ID Type")}
                                    </MenuItem>
                                    {this.state.idTypeList.map((idType: any) => {
                                      return (
                                        <MenuItem value={idType.id} key={idType.id} className={languageCondition(language, "select-arabic-menu", "property-register-id-menu")}>
                                          {LanguageAttributeHandler(language, idType.name, idType.name_ar)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <img src={IDTypeIcon} alt="" />
                                </Box>
                                {ErrorHandler(t, touched.idType, errors.idType)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.idNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="idNumber"
                                  className="select-input input"
                                  placeholder={t("Manager ID Number")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={IDNumberIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {ErrorHandler(t, touched.idNumber, errors.idNumber)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Input
                                  value={values.idDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="idDate"
                                  type="date"
                                  className={
                                    !values.idDate && is_mobile
                                      ? "select-input input placeHolderForDate"
                                      : "select-input input"
                                  }
                                  placeholder={t("ID Expectation Date")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={IDDateIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                {ErrorHandler(t, touched.idDate, errors.idDate)}
                              </FormControl>
                              <FormControl fullWidth>
                                <Box className="upload-box" onClick={() => this.uploadIDCard.click()}>
                                  <img src={UploadIcon} alt="" />
                                  <p>{t("Upload Manager ID copy")}</p>
                                </Box>
                                <input
                                  onChange={(e: any) => setFieldValue("idCardFile", e.target.files[0])}
                                  onBlur={handleBlur}
                                  name="idCardFile"
                                  style={{ display: "none" }}
                                  ref={(ref: any) => (this.uploadIDCard = ref)}
                                  accept=".pdf"
                                  type="file"
                                  className="register-document-input"
                                />
                                {values.idCardFile && (
                                  <Box className="pdf-box">
                                    <img src={PdfIcon} alt="" />
                                    <Box className="pdf-info">
                                      <h4>{values.idCardFile.name}</h4>
                                      <CloseIcon className="rpm-close-icon" onClick={() => setFieldValue("idCardFile", null)} />
                                    </Box>
                                  </Box>
                                )}
                                {ErrorHandler(t, touched.idCardFile, errors.idCardFile)}
                              </FormControl>
                              <div className="next-button">
                                <Button type="submit">{t("Submit")}</Button>
                              </div>
                            </Box>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="right-block right-image" display={{ xs: "none", md: "flex" }}>
                <img src={BuildingLogo.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Drawer
          anchor="bottom"
          dir={languageCondition(language, "rtl", "ltr")}
          className="condition-modal penalty-dialog rent-history-dialog property-manager-dialog"
          open={this.state.isAddPropertyModalOpen}
          onClose={() => this.handleCloseAddPropertyModal()}
        >
          <Formik
            enableReinitialize
            initialValues={this.state.propertyForm}
            validationSchema={this.registerPropertyFormSchema}
            onSubmit={(values, { resetForm }) => {
              if (this.state.propertyId) {
                let newList = this.state.propertyList;
                newList[Number(this.state.propertyId)] = values;
                this.setState({ propertyList: newList }, () => {
                  this.handleCloseAddPropertyModal();
                  sessionStorage.setItem("propertyList", JSON.stringify(this.state.propertyList));
                });
              } else {
                const newUnitList = this.state.unitList.filter((unit: any) => unit.id !== values.unitId);
                this.setState({ propertyList: [...this.state.propertyList, values], unitList: newUnitList }, () => {
                  this.handleCloseAddPropertyModal();
                  sessionStorage.setItem("propertyList", JSON.stringify(this.state.propertyList));
                });
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes">
                  <Box className={languageCondition(language, "arabic-grid", "")}>
                    {this.state.propertyId ? (
                      <h4 className="bold-text">{t("Edit Property")}</h4>
                    ) : (
                      <h4 className="bold-text">{t("Add Another Property")}</h4>
                    )}
                    <FormControl fullWidth>
                      <Box className="select-box">
                        <Select
                          displayEmpty
                          value={values.country}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("country", value);
                            this.getPMCityList(value);
                          }}
                          onBlur={handleBlur}
                          name="country"
                          className="select-input property-country"
                          input={<OutlinedInput />}
                          readOnly={Boolean(this.state.propertyId)}
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                            {t("Country")}
                          </MenuItem>
                          {this.state.countryList.map((country: any) => {
                            return (
                              <MenuItem value={country} key={country} className={languageCondition(language, "select-arabic-menu", "")}>
                                {country}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <img src={CountryIcon} alt="" />
                      </Box>
                      {ErrorHandler(t, touched.country, errors.country)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="select-box">
                        <Select
                          displayEmpty
                          value={values.city}
                          readOnly={Boolean(this.state.propertyId)}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("city", value);
                            this.getPMComplexList(value);
                          }}
                          input={<OutlinedInput />}
                          name="city"
                          className="select-input property-city"
                          onBlur={handleBlur}
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                            {t("City")}
                          </MenuItem>
                          {this.state.cityList.map((city: any) => {
                            return (
                              <MenuItem value={city} key={city} className={languageCondition(language, "select-arabic-menu", "")}>
                                {city}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <img src={CityIcon} alt="register-pm-city-icon" />
                      </Box>
                      {ErrorHandler(t, touched.city, errors.city)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="select-box">
                        <Select
                          readOnly={Boolean(this.state.propertyId)}
                          displayEmpty
                          value={values.complex}
                          onBlur={handleBlur}
                          name="complex"
                          className="select-input"
                          input={<OutlinedInput />}
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                            {t("Complex Name")}
                          </MenuItem>
                          {this.state.complexList.map((complex: any) => {
                            return (
                              <MenuItem
                                value={complex.id}
                                key={complex.id}
                                className={languageCondition(language, "select-arabic-menu", "")}
                                onClick={() => this.handleRegisterPropertyComplex(complex, setFieldValue)}
                              >
                                {complex.attributes.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <img src={ComplexIcon} alt="" />
                      </Box>
                      {ErrorHandler(t, touched.complex, errors.complex)}
                    </FormControl>
                    {this.state.isBuildingAvail && (
                      <FormControl fullWidth>
                        <Box className="select-box">
                          <Select
                            displayEmpty
                            value={values.buildingId}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("buildingId", value);
                              this.getUnitList(values.complex, value);
                            }}
                            readOnly={Boolean(this.state.propertyId)}
                            onBlur={handleBlur}
                            name="buildingId"
                            className="select-input property-building"
                            input={<OutlinedInput />}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Building Name")}
                            </MenuItem>
                            {this.state.buildingList.map((building: any) => {
                              return (
                                <MenuItem value={building.id} key={building.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                  {building.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <img src={BuildingIcon} alt="" />
                        </Box>
                        {ErrorHandler(t, touched.buildingId, errors.buildingId)}
                      </FormControl>
                    )}
                    <FormControl fullWidth>
                      <Box className="select-box">
                        <Select
                          displayEmpty
                          value={values.unitId}
                          readOnly={Boolean(this.state.propertyId)}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("unitId", value);
                            this.checkPropertyManagerAvailable(value);
                          }}
                          onBlur={handleBlur}
                          input={<OutlinedInput />}
                          name="unitId"
                          className="select-input property-unit"
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                            {t("Unit Number")}
                          </MenuItem>
                          {this.state.unitList.map((unit: any) => {
                            return (
                              <MenuItem
                                value={unit.id}
                                key={unit.id}
                                className={languageCondition(language, "select-arabic-menu", "")}
                                onClick={() => setFieldValue("unitName", unit.apartment_name)}
                              >
                                {unit.apartment_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <img src={UnitIcon} alt="" />
                      </Box>
                      {ErrorHandler(t, touched.unitId, errors.unitId)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="startDate"
                        placeholder={t("Contract Start Date")}
                        type="date"
                        className={
                          !values.startDate && is_mobile
                            ? "select-input input placeHolderForDate"
                            : "select-input input"
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={IDDateIcon} alt="" />
                          </InputAdornment>
                        }
                      />
                      {ErrorHandler(t, touched.startDate, errors.startDate)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="endDate"
                        className={
                          !values.endDate && is_mobile ? "select-input input placeHolderForDate" : "select-input input"
                        }
                        placeholder={t("Contract End Date")}
                        type="date"
                        inputProps={{ min: values.startDate }}
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={IDDateIcon} alt="" />
                          </InputAdornment>
                        }
                      />
                      {ErrorHandler(t, touched.endDate, errors.endDate)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Box className="select-box">
                        <Select
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="feeType"
                          displayEmpty
                          input={<OutlinedInput />}
                          value={values.feeType}
                          className="select-input register-fee-type"
                        >
                          <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "property-select-fee-type")}>
                            {t("Select Fee Type")}
                          </MenuItem>
                          <MenuItem value="Fixed Percentage" className={languageCondition(language, "select-arabic-menu", "property-fee-type-menu")}>{t("Fixed Percentage of Rent")}</MenuItem>
                          <MenuItem value="Fixed Amount" className={languageCondition(language, "select-arabic-menu", "property-fee-type-menu")}>{t("Fixed Amount")}</MenuItem>
                        </Select>
                        <img src={FeeTypeIcon} alt="fee-type-icon" />
                      </Box>
                      {ErrorHandler(t, touched.feeType, errors.feeType)}
                    </FormControl>
                    <FormControl fullWidth>
                      <Input
                        value={values.rent}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="rent"
                        className="select-input input"
                        placeholder={
                          values.feeType === "Fixed Amount"
                            ? `${t("Enter Fixed Amount")}`
                            : `${t("Enter Fixed Percentage of Rent")}`
                        }
                        type="text"
                        startAdornment={
                          <InputAdornment position="start">
                            <img src={FeeTypeIcon} alt="" />
                          </InputAdornment>
                        }
                      />
                      {ErrorHandler(t, touched.rent, errors.rent)}
                    </FormControl>
                  </Box>
                  <Box className="button-group">
                    {this.state.propertyId ? (
                      <Button className="add-button" type="submit">
                        {t("Edit")}
                      </Button>
                    ) : (
                      <Button className="add-button" type="submit">
                        {t("Add")}
                      </Button>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Drawer>

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(PropertyManagerStyleWeb)(RegisterPropertyManager));
// Customizable Area End
