// Customizable Area Start
import React from "react";
import CEPollSurveyReports from "./CEPollSurveyReports.web";
import CEPollSurveyReportsWrapperController, { Props } from "./CEPollSurveyReportsWrapperController.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

export default class CEPollSurveyReportsWrapper extends CEPollSurveyReportsWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <CEHeaderComponentWeb>
        <CEPollSurveyReports navigation={this.props.navigation} />
      </CEHeaderComponentWeb>
    );
  }
}

// Customizable Area End
