export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const AuditReportIcon = require("../assets/audit-report.png");
export const BudgetReportIcon = require("../assets/budget-report.png");
export const ExpenseReportIcon = require("../assets/expense-report.png");
export const FeeReportIcon = require("../assets/fee-report.png");
export const InvitationReportIcon = require("../assets/invitation-report.png");
export const SearchIconImage = require("../assets/search.png");
export const buildingLogo = require("../../StoreCredits/assets/buildingImage.png");
export const manageLogo = require("../../StoreCredits/assets/manageLogo.png");
export const GroupLogo = require("../../StoreCredits/assets/groupLogo.png");
export const FilterIcon = require("../assets/filter.png");
export const RightArrowIcon = require("../assets/right-arrow.png");
export const SortIcon = require("../assets/sort.png");
export const EditIcon = require("../assets/edit.png");
export const DeleteIcon = require("../assets/delete.png");
export const DeleteExpenseIcon = require("../assets/delete-expense.png");
export const ExpenseDateIcon = require("../assets/calender.png");
export const ExpenseAmountIcon = require("../assets/expense-amount.png");
export const IssueIcon = require("../assets/issue.png");
export const CategoryIcon = require("../assets/category.png");
export const BuildingIcon = require("../assets/building.png");
export const UnitIcon = require("../assets/unit.png"); 
export const ResolvedByIcon = require("../assets/resolved-by.png");
export const SummaryIcon = require("../assets/summary.png");
export const SuccessExpenseIcon = require("../assets/success-expense.png");
export const ExpenseIcon = require("../assets/expense.png");
export const DashboardVs = require("../assets/dash-vs.png");
