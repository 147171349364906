// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// import {toast} from "react-toastify";
import {tokenManagerP1P2,pathRedirectManager} from "../../../components/src/ConditionManager.web";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  match:any;
  location:any;
}

interface S {
  anchorEl :any ;
  anchorEl_1 :any ;
  loading: boolean;
  sortBy:any;
  status:any;
  SurveyData: any;
  audienceModal:any;
  surveyQuestions:any;
  audienceType:any;
  isSubmitted:any;
  PreViewSurveyData:any;
  SurveyTitleError:any;
  textEditor:any;
  initialtextEditorVal:any;
  pollTitleError:string;
  pollDateError:string;
  pollEndDateError:string;
  pollDescriptionError:string;
  selectedAudience:any[];
  selectedAudienceId:any[];
  selectedAudienceName:any[];
  PreViewPollData:any;
  audienceIds:any;
}

interface SS {
  id: any;
}

export default class SurveyPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiEmailLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  createSurveyFromPreview:string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]
    
    this.state = {
      anchorEl:null,
      anchorEl_1:null,
      loading:false,
      sortBy : "" ,
      status:"",
      SurveyData: {
        title:'',
        startDate:'',
        endDate:'',
        description:'',
        question:'',
      },
      audienceModal:false,
      surveyQuestions: [
        {
          question_type: "",
          question_typeError:"",
          title:"",
          questionError:"",
          survey_options_attributes: [
            {text: "",_destroy: "false",error:""},
            {text: "",_destroy: "false",error:""}
          ],
          error:false,
          _destroy: "false",
        }
      ],
      audienceType:"",
      isSubmitted:false,
      SurveyTitleError:"",
      PreViewSurveyData: {},
      textEditor: '',
      initialtextEditorVal: '',
      pollDateError:"",
      pollEndDateError:"",
      pollDescriptionError:"",
      pollTitleError:"",
      PreViewPollData:"",
      selectedAudience:[],
      selectedAudienceId:[],
      selectedAudienceName:[],
      audienceIds:[],
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.handleSurveyDataSubmit = this.handleSurveyDataSubmit.bind(this)
    this.handlePriviewData = this.handlePriviewData.bind(this)

  }

  async componentDidMount() {
    if(localStorage.getItem("Survey_Data")){
      const surveyPreview:any = JSON.parse(localStorage.getItem("Survey_Data") || "")
      if(surveyPreview){
        this.setState({
          textEditor:surveyPreview.PollDescription,
          SurveyData:surveyPreview.PollFormData,
          surveyQuestions:surveyPreview.PollOptions,
          selectedAudience:surveyPreview.selectedAudience,
          selectedAudienceId:surveyPreview.selectedAudienceId,
          selectedAudienceName:surveyPreview.selectedAudienceName,
          audienceIds:surveyPreview.audienceIds,
        })
      }
      localStorage.removeItem("Survey_Data")
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(this.apiEmailLoginCallId === apiRequestCallId ){
        console.log(responseJson,errorReponse)
      }
      if(this.createSurveyFromPreview === apiRequestCallId){
        if(responseJson.code === 200){
          this.setState({
            loading:false
          })
          pathRedirectManager(this.props.history, "/polling","/CompanyEmployee/CreateSurvey")
        }else{
          console.log("SOMETHING WENT WRONG")
        }
      }
    }
  }

  handlePriviewData = () => {
    this.setState({
      isSubmitted:true
    })
    localStorage.setItem('Survey_Data', JSON.stringify({
      PollFormData:this.state.SurveyData,
      PollOptions:this.state.surveyQuestions,
      PollDescription:this.state.textEditor,
      selectedAudience:this.state.selectedAudience,
      selectedAudienceId:this.state.selectedAudienceId,
      selectedAudienceName:this.state.selectedAudienceName,
      audienceIds:this.state.audienceIds,
    }))
    pathRedirectManager(this.props.history, "/CreateSurveys","/CompanyEmployee/CreateSurvey")
  }

  handleSurveyDataSubmit =  async (event:any,preview?:boolean) => {
    let societyID = localStorage.getItem("society_id")
    this.setState({
      isSubmitted: true,
      loading:true,
    })
    let reqPayload = {
      "society_id": societyID,
      "survey":
          {
            "type_name": "survey",
            "title": this.state.SurveyData.title,
            "description": this.state.SurveyData.description,
            "schedule": "1",
            "target_audience_type":this.state.selectedAudience,
            "survey_audience_ids":this.state.selectedAudienceId,
            "start_date": this.state.SurveyData.startDate,
            "end_date": this.state.SurveyData.endDate,
            "survey_questions_attributes": this.state.surveyQuestions,
            "target_audience_ids":this.state.audienceIds,
          }
    }
    await this.addSurveyData(reqPayload);
    await localStorage.removeItem('Survey_Data');
  }

  addSurveyData = async (data:any) => {
    const societyID = localStorage.getItem("society_id")
    this.createSurveyFromPreview = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: `/society_managements/${societyID}/bx_block_survey/surveys`,
      body:JSON.stringify(data)
    });
    localStorage.removeItem("Survey_Data")
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;

    const token = tokenManagerP1P2();

    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body && requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // console.log("Called",requestMessage);
    return requestMessage.messageId;
  };
  handleClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget })
  };

  handleClose = (e?:any, v?:any) => {
    let sortBy : any ;
    console.log("v=========>",v)
    if(v === undefined || v === null){
      sortBy =this.state.sortBy
    }
    else {
      sortBy =v;
    }
    this.setState({anchorEl:null,sortBy : sortBy})
  };

  handleClick_1 = (event:any) => {
    this.setState({anchorEl_1:event.currentTarget})
  };

  handleClose_1 = (e?:any, v?:any) => {
    let status : any ;
    if(v === undefined || v === null){
      status =this.state.status;
    }
    else {
      status =v;
    }
    this.setState({anchorEl_1:null ,status :status})
  };
}

// Customizable Area End
