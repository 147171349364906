import React from "react"
// Customizable Area Start
import { Box, Typography, withStyles, Divider, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment } from "@material-ui/core";
import CEPollSurveyReportsController, { Props } from './CEPollSurveyReportsController.web';
import { withTranslation } from "react-i18next";
import { Tenant_Logo } from "../assets";
import {withRouter} from 'react-router-dom';
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
// @ts-ignore
import DOMPurify from 'dompurify';
import Backdrop from "@material-ui/core/Backdrop";
import SurveryResponse from "../SurveyResponseModal.web";
import Modal from "@material-ui/core/Modal";
// Customizable Area End

class CEPollSurveyReports extends CEPollSurveyReportsController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  getDetailSection = (label: string, value: string) => (
    <Box>
        <Typography className="bold-text" style={{ fontSize: "14px", fontWeight: "bold"}}>{label}</Typography>
        <Typography style={{ fontSize: "14px", marginTop: '4px' }}>{value}</Typography>
    </Box>
  )
    // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={{ padding: '40px', paddingRight: '40px',paddingTop:"0px" }}>
                <Typography style={{ fontSize: '16px' }}> 
                    <Typography style={{ cursor: "pointer" }} component="span" data-test-id="poll-survey-page-button" onClick={this.navigateToPollSurvey}>Activities / Poll and survey</Typography><Typography style={{ cursor: "pointer" }} data-test-id="poll-survey-details-page" onClick={this.navigateToPollSurveyDetails} component="span">/ {this.state.type} Details</Typography><Typography component="span" style={{ color: "rgb(43, 111, 237)"}}>/ {this.state.type} Reports</Typography></Typography>
                <br />
                <Box className="Box12" style={webStyle.pageAction}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography
                            className="login-h1 bold-text"
                            style={{ fontSize: "30px" }}
                        >
                            {this.state.type} Reports
                        </Typography>
                        <Button style={webStyle.downloadReportButton} className="downloadReportButton" onClick={this.manageDownload}>Download Report</Button>
                    </Box>
                </Box>
                <br />
                <Box className="dashboard_card" p={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb="30px">
                        <img height={49} src={Tenant_Logo.default} alt="tenant_logo" />
                        <Typography>Report Generated on {this.state.reportGeneratedOn} By {this.state.reportGeneratedBy}</Typography>
                    </Box>
                    <Box style={webStyle.nameSection}>
                        <Typography className="bold-text" style={webStyle.nameSectionText}>{this.state.type} Report of {this.state.name}</Typography>
                    </Box>
                    <Box style={{ margin: "25px 0", display: "flex", gap: "50px"}}>
                        {this.getDetailSection('Country', this.state.detail.country)}
                        {this.getDetailSection('City', this.state.detail.city)}
                        {this.getDetailSection('Complex', this.state.detail.complex)}
                        {this.getDetailSection('Building', this.state.detail.building)}
                        {this.getDetailSection('Start Date', this.state.detail.startDate)}
                        {this.getDetailSection('End Date', this.state.detail.endDate)}
                    </Box>
                    <Box mb="25px">
                        <Typography className="bold-text" style={{ marginBottom: "8px", fontSize: "14px" }}>Description</Typography>
                        <Typography style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.description) }}/>
                    </Box>
                    <Typography className="bold-text" style={{ fontSize: "20px" }}>{this.state.type === "Survey" ? "Response Statistics" : this.state.que}</Typography>
                    <Box style={{ margin: "25px 0", display: "flex", gap: "50px"}}>
                        {this.state.type === "Poll" && this.getDetailSection('Yes', this.state.response.yes + ' People Voted')}
                        {this.state.type === "Poll" && this.getDetailSection('No', this.state.response.no + ' People Voted')}
                        {this.getDetailSection('Awaited', this.state.response.awaited ?? '')}
                        {this.getDetailSection('Response Received', this.state.response.responseReceived ?? '')}
                    </Box>
                    <Divider></Divider>
                    <TableContainer>
                        <Table className="table-box">
                            <TableHead>
                                <TableRow className="login-h1 bold-text">
                                    <TableCell className="login-h1 bold-text">#</TableCell>
                                    <TableCell className="login-h1 bold-text">Name</TableCell>
                                    <TableCell className="login-h1 bold-text">Unit Number</TableCell>
                                    <TableCell className="login-h1 bold-text">Response</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.length > 0 ? (
                                    this.state.data.map((item: any, index: any) => {
                                        const { attributes } = item;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.name}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.unitNumber}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        this.state.type !== "Polls" ?
                                                            <Button variant={"contained"} className="viewResponse" color={"default"} onClick={() => this.setState({responseModal:true,responseModalData:attributes.Response})}>View Response</Button>
                                                            :
                                                            attributes.Response
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7} >No Record Found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box style={webStyle.tableFooterSection}>
                        <PaginationModule handlePagination={this.handleChangePage} pagination={this.state.pagination} page={this.state.page} />
                    </Box>
                    <Modal
                        aria-labelledby="filterModal"
                        aria-describedby="filtermodal"
                        className="modalStyle"
                        open={this.state.responseModal}
                        onClose={this.handleCloseResponseModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <div>
                            <SurveryResponse handleClose={this.handleCloseResponseModal} response={this.state.responseModalData} />
                        </div>
                    </Modal>
                </Box>
            </Box>
      // Customizable Area End
    )
  }

}
// Customizable Area Start
const webStyle: any = {
    greyLetter: {
        color: '#a5a5a5',
        fontWeight: 600,
        letterSpacing: "2px",
    },
    nameSection: {
        textAlign: "center",
        margin: "30px 0",
        background: "#f7f7f7",
        padding: "15px"
    },
    nameSectionText: {
        fontWeight: 600,
        fontSize: "24px",
    },
    downloadReportButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        borderRadius: "8px",
        background: "rgb(252, 132, 52)",
        color: "rgb(255, 255, 255)",
        height: "40px",
        fontWeight: "bold"
    },
    tableFooterSection: {
        display: "flex",
        alignItems: "center",
        padding: "25px 0px",
        justifyContent: "space-between",
    },
};

export default withTranslation()(withStyles({})(withRouter(CEPollSurveyReports)))
//Add a style or MUI customized style
// Customizable Area End