//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Button,
    Hidden,
    Grid, MenuItem, Menu, Typography
} from "@material-ui/core";


import {
    Tenant_Logo, Building1, globalIcon, Lock_Icon, downArrow
} from "../../src/assets";
import CompEmpChangePassSucessController, {
    Props,
} from "./CompEmpChangePassSucessController.web";


export default class CompEmpChangePassSucess extends CompEmpChangePassSucessController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <Box>
                <Grid container spacing={5}>
                    <Grid item sm={7} xs={12} style={{height: '100%'}} className="rightbox b">
                        <Hidden>
                            <Box p={2} style={{height: '100%'}} className="rightbox c">
                                <Box style={{
                                    display: 'flex',
                                    height: '80px',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row'
                                }}>

                                    <img src={Tenant_Logo.default} style={webStyle.login_img}/>
                                    <Box style={{height: '25px'}} onClick={(e) => {
                                        this.handleOpen(e)
                                    }} data-test-id="menu" id='myExpand'>
                                        <p style={{verticalAlign: 'middle'}}>
                                            <img src={globalIcon} alt="GlobalIcon"
                                                 style={{position: 'relative', top: '5px'}}
                                                 className="login-h1 bold-text "/>
                                            <span style={{fontWeight: 'bolder', paddingLeft: '3px'}}
                                                  className="login-h1 bold-text ">{this.state.language} </span>
                                            <img src={downArrow} style={{position: 'relative', height: '7px', color: 'grey',top:"-2px"}}/>
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                            <Menu open={this.state.open3}

                                  anchorOrigin={{
                                      vertical: 'bottom', // Change this to 'bottom'
                                      horizontal: 'left',
                                  }}
                                  style={{
                                      position: 'absolute',
                                      top: '30px'
                                  }}
                                  transformOrigin={{
                                      vertical: 'bottom', // Change this to 'top'
                                      horizontal: 'left',
                                  }}
                                  anchorEl={this.state.field}>
                                <MenuItem onClick={() => {
                                    this.setState({language: 'ENG', open3: false})
                                }} data-test-id="english">English</MenuItem>
                                <MenuItem onClick={() => {
                                    this.setState({language: 'ARB', open3: false})
                                }} data-test-id="arabic">Arabic</MenuItem>
                            </Menu>
                            <Box className="middle_box e">
                                <Box className="middleinner_box f">
                                    <Box>
                                        <img src={Lock_Icon} alt="Lock_Icon" style={{color: 'green',marginBottom:"20px"}}/>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6" className="login-h1 bold-text" style={{fontSize:"22px",marginBottom:"10px"}}> Password Changed
                                            Successfully </Typography>
                                    </Box>
                                    <Box className="middlebox_end g" style={{marginBottom: '185px'}}>
                                        <Typography> You have successfully changed
                                            your </Typography>
                                        <Typography>password. Please use your new
                                            password </Typography>
                                        <Typography>
                                            when logging in.
                                        </Typography>
                                    </Box>
                                    <Button color="primary" variant="contained" data-test-id='LogIn' style={{
                                        height: '56px',
                                        backgroundColor: '#2B6FED',
                                        color: '#ffffff',
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        lineHeight: '20px'
                                    }} fullWidth size="large" onClick={this.changePassSucess}>
                                        <Typography style={{fontWeight: 'bolder', color: '#ffffff'}}
                                                  className="login-h1 bold-text "> BACK to LOGIN</Typography></Button>
                                </Box>

                            </Box>


                        </Hidden>
                    </Grid>
                    <Grid item sm={5} className="leftBox h">
                        <Hidden smDown>
                            <Box pt={2} className="right_image i" visibility={{xs: 'none', md: 'flex'}}>
                                <img src={Building1.default} style={webStyle.side_img}/>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const webStyle = {

    login_img: {
        width: '116px',
        height: '45px',
    },
    side_img: {
        height: '95vh',
        width: '39vw',
        borderRadius: '40px'

    },
    img: {fontSize: '25px'}
};
// Customizable Area End

