// Customizable Area Start
import React from "react";
//components
import {Box, Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import {FacebookIcon, InstagramIcon, SnapchatIcon, TwitterIcon} from "../../Settings5/src/assets"
//resources

import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import NeighboursController, {Props} from "./NeighboursController.web";

//resorces
import {
  Building1,
  Chat_Disable_Icon,
  Chat_Icon,
  Contact_Disable_Icon,
  Contact_Icon,
  Email_Disable_Icon,
  Email_Msg_Icon,
  NoProfile_Img,
  User1_Img,
} from "../src/assets";

import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import moment from "moment";


class NeighboursDetails extends NeighboursController {
  //@ts-ignore
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    const id = localStorage.getItem("neighboursDetailsId");
    if (id)
      this.getNeighboursDetailsById(id);
    else
      //@ts-ignore
      this.props.history.push("/NeighboursListing")
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    const attributes = this.state.getNeighboursDetails?.attributes;
    return (
      <>
        <Box className="login-wrapper incident-wrapper neighbour-listing-wrapper neighborDetails" style={{backgroundColor:"#ffffff"}} dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container neighborDetails">
            <Grid item xs={12} md={7} className="auth-cols neighborDetails">
              <Box className="content-block common_content_block neighborDetails" style={{ backgroundColor: "#ffffff" }}>
                <Box className="content-header neighborDetails">
                  <Box className="left-block blocks neighborDetails">
                    <ArrowBackIcon onClick={() => window.history.back()} style={{fontSize:"20px"}} />
                    {
                      attributes?.full_name.publilc_access && attributes.apartment_number.publilc_access ?
                        <h4 className="bold-text neighborDetails" style={{fontSize:"18px"}}>{attributes.apartment_number.apartment_number}</h4>
                        :
                        <h4 className="bold-text neighborDetails" style={{fontSize:"18px"}}>{t("My Neighbours")}</h4>
                    }
                  </Box>
                </Box>
                <Box className="content-block-wrapper neighbor-content-block-wrapper common-incident-block desktop-ui neighborDetails" style={{backgroundColor:"white"}}>
                  {/* neighbour detail section */}
                  {
                    attributes?.full_name.publilc_access ?
                      <Box className="neighbour-detail-section neighborDetails">
                        <Card className="neighbour-card neighbour-detail-card card neighborDetails">
                          <CardContent className="card-content neighborDetails">
                            <Box className="card-top-block neighborDetails">
                              <img src={attributes.profile_pic.url || NoProfile_Img} className="info-icon neighborDetails" alt="info-icon" />
                              <Typography component="h4" className="bold-text neighborDetails" style={{fontSize:"18px"}}>
                                {attributes?.full_name.name}
                              </Typography>
                              <Box className="social-raw neighborDetails">
                               {
                                 attributes.disable_chat ?
                                <Box className="blocks chatDisabled" style={languageCondition(language,{borderLeft:"2px solid #d7dbe4",borderRight:"none"},{borderRight:"2px solid #d7dbe4",borderLeft:"none"})}>
                                      <img src={Chat_Disable_Icon}  className="icons" alt="info-icon" style={{height:"17px",width:"17px"}} />
                                </Box>
                                :
                                <Box className="blocks enabled" style={languageCondition(language,{borderLeft:"2px solid #d7dbe4",borderRight:"none"},{borderRight:"2px solid #d7dbe4",borderLeft:"none"})}>
                                      <img src={Chat_Icon} onClick={() => this.createChatRoom(attributes.id)} className="icons startChat" alt="info-icon" style={{height:"17px",width:"17px"}}/>
                                </Box>
                               }
                                {attributes?.full_phone_number.publilc_access ?
                                  <Box className="blocks contactEnable" style={languageCondition(language,{borderLeft:"2px solid #d7dbe4",borderRight:"none"},{borderRight:"2px solid #d7dbe4",borderLeft:"none"})}>
                                    <a href={`tel:${attributes.full_phone_number.full_phone_number}`}>
                                      <img src={Contact_Icon} className="icons" alt="info-icon" style={{height:"16px",width:"16px"}} />
                                    </a>
                                  </Box>
                                  :
                                  <Box className="blocks contactDisable" style={languageCondition(language,{borderLeft:"2px solid #d7dbe4",borderRight:"none"},{borderRight:"2px solid #d7dbe4",borderLeft:"none"})}>
                                    <img src={Contact_Disable_Icon} className="icons" alt="info-icon" style={{height:"16px",width:"16px"}} />
                                  </Box>
                                }
                                {attributes?.email.publilc_access ?
                                  <Box className="blocks mailEnable" style={languageCondition(language,{borderRight:"none"},{borderLeft:"none"})}>
                                    <a href={`mailto:${attributes.email.email}`}>
                                      <img src={Email_Msg_Icon} className="icons" alt="info-icon" style={{height:"17px",width:"17px"}} />
                                    </a>
                                  </Box>
                                  :
                                  <Box className="blocks mailDisbale" style={languageCondition(language,{borderRight:"none"},{borderLeft:"none"})}>
                                    <img src={Email_Disable_Icon} className="icons" alt="info-icon" style={{height:"17px",width:"17px"}} />
                                  </Box>
                                }
                              </Box>
                              <Box className="relation-row neighborDetails">
                                <Box className="blocks neighborDetails" style={{ display: 'flex' }}>
                                  {
                                    attributes?.gender.publilc_access ?
                                      <Typography component="h4" style={{fontSize:"14px"}} className="bold-text neighborDetails">
                                        {t("Gender")}:
                                        <span className="title gender" style={languageCondition(language,{paddingLeft:"20px",paddingRight:"0px"},{})}>{attributes.gender.gender}</span>
                                      </Typography>
                                      : null
                                  }
                                  {
                                    attributes?.date_of_birth?.publilc_access ?
                                      <Typography component="h4" style={{fontSize:"14px",padding:"0px"}} className="bold-text neighborDetails">
                                        {t("DOB")}:
                                        <span className="title dob" style={languageCondition(language,{paddingLeft:"20px",paddingRight:"0px"},{})}>{moment(attributes.date_of_birth.date_of_birth).format("DD/MM/YYYY")}</span>
                                      </Typography>
                                      :
                                      null
                                  }
                                </Box>
                              </Box>
                            </Box>
                            <Box className="bio-content-row neighborDetails">
                              <Box style={{marginBottom:"10px"}}>
                                {
                                  attributes?.bio.publilc_access ?
                                      <Box className="bio-row neighborDetails">
                                        <Typography component="h4" style={languageCondition(language,{fontSize:"18px",marginBottom:"5px",textAlign:"right"},{fontSize:"18px",marginBottom:"5px"})} className="bold-text bio">
                                          {t("Bio")}:
                                        </Typography>
                                        <Typography component="p" style={{fontSize:"14px"}}>
                                          {attributes.bio.bio || t("No Bio added yet!")}
                                        </Typography>
                                      </Box>
                                      : null
                                }
                              </Box>
                              <Box>
                                {
                                  attributes?.hobbies.publilc_access ?
                                      <Box className="bio-row neighborDetails">
                                        <Typography component="h4" style={languageCondition(language,{fontSize:"18px",marginBottom:"5px",textAlign:"right"},{fontSize:"18px",marginBottom:"5px"})} className="bold-text HobbiesHeading">
                                          {t("Hobbies")}:
                                        </Typography>
                                        <Grid container>
                                          {
                                            attributes.hobbies.hobbies?.length > 0 ?
                                                attributes.hobbies.hobbies.map((val: any, index: any) => (
                                                    <Grid item xs={6} md={4} className="neighborDetails">
                                                      <Box className="customButton neighborDetails" >
                                                        <Button variant="contained" className="contain warning neighborDetails" key={index}>{val}</Button>
                                                      </Box>
                                                    </Grid>
                                                ))
                                                :
                                                <Typography style={{fontSize:"14px"}}>{t("No hobbies added yet!")}</Typography>
                                          }
                                        </Grid>
                                      </Box>
                                      :
                                      null
                                }
                              </Box>
                              <Box>
                                {
                                  attributes?.website[0].publilc_access || attributes?.website[1].publilc_access || attributes?.website[2].publilc_access || attributes?.website[3].publilc_access ?
                                      <Box className="social-data-row neighborDetails">
                                        <Typography component="h4" style={languageCondition(language,{fontSize:"18px",marginBottom:"5px",textAlign:"right"},{fontSize:"18px",marginBottom:"5px"})} className="bold-text followMeOn">
                                          {t("Follow me on")}:
                                        </Typography>
                                        {
                                          attributes?.website.length !== 0 ?
                                              <Box className="icons-row icons neighborDetails">
                                                {
                                                  attributes?.website[0].twitter_link === null ? null :
                                                    <Button className="twitterButton" href={attributes?.website[0]?.twitter_link} target="_blank" style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                                                        <img src={TwitterIcon} alt="twitter" style={{width:"34px",height:"34px"}}/>
                                                    </Button>
                                                }
                                                {
                                                  attributes?.website[1].instagram_link === null ? null :
                                                      <Button className="instaButton" href={attributes?.website[1].instagram_link} target="_blank" style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                                                      <img src={InstagramIcon} alt="instagram" style={{width:"34px",height:"34px"}}/>
                                                      </Button>
                                                }
                                                {
                                                  attributes?.website[2].fb_link === null ? null :
                                                      <Button className="facebookButton" href={attributes?.website[2].fb_link} target="_blank" style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                                                        <img src={FacebookIcon} alt="facebook" style={{width:"34px",height:"34px"}}/>
                                                      </Button>
                                                }
                                                {
                                                  attributes?.website[3].snapchat_link === null ? null :
                                                      <Button className="twitterSnapchat" href={attributes?.website[3].snapchat_link} target="_blank" style={{minWidth:"0px",padding:"6px 0px"}}>
                                                        <img src={SnapchatIcon} alt="snapchat" style={{width:"34px",height:"34px"}}/>
                                                      </Button>
                                                }
                                              </Box>
                                              : <Typography style={{fontSize:"14px"}} className="neighborDetails">{t("No social account added yet!")}</Typography>
                                        }
                                      </Box>
                                      :
                                      null
                                }
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                      :
                      <>
                        {/* anonymous section */}
                        <Box className="anonymous-section neighborDetails">
                          <Card className="neighbour-card neighbour-detail-card card neighborDetails">
                            <CardContent>
                              <img src={User1_Img} className="info-icon neighborDetails" alt="info-icon" />
                              <Typography component="h4" className="neighborDetails">
                                {t("Anonymous")}
                              </Typography>
                              {/* <Typography component="h5">
                          B-1405
                        </Typography> */}
                              <Box className="social-raw neighborDetails">
                                <Box className="blocks neighborDetails">
                                  <img src={Chat_Disable_Icon} className="icons" alt="info-icon" />
                                </Box>
                                <Box className="blocks neighborDetails">
                                  <img src={Contact_Disable_Icon} className="icons" alt="info-icon" />
                                </Box>
                                <Box className="blocks neighborDetails">
                                  <img src={Email_Disable_Icon} className="icons" alt="info-icon" />
                                </Box>
                              </Box>
                              <Box className="note-row neighborDetails">
                                <Typography component="h5" className="neighborDetails">
                                  <span className="title neighborDetails">{t("Note")}:</span> {t("Resident does’t want to share his/her name.")}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </>
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols neighborDetails">
              <Box className="right-block neighborDetails" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo neighborDetails" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}
//@ts-ignore
export default withTranslation()(withRouter(NeighboursDetails))
// Customizable Area End