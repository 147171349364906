// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { Share } from "./assets";
import PaymentDetailsController, { Props } from "./PaymentDetailsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { CloseButton } from "./CloseButton";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";

class Visitors extends PaymentDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    const { getMyPrePaymentList } = this.state;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "previous-back-icon")} onClick={() => this.props.history.push("/PreviousPayments")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {moment(getMyPrePaymentList?.attributes?.title, "DD-MM-YYYY").format("MMMM YYYY")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid
              container
              spacing={2}
              style={{ width: "90%", height: "100vh", display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text">
                    {t("Payment Details")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  padding="15px"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Payment Name")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {getMyPrePaymentList?.attributes?.title}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid For")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary" style={{ textTransform:"capitalize"}}>
                        {getMyPrePaymentList?.attributes?.invoice_type}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, getMyPrePaymentList?.attributes?.currency?.currency, getMyPrePaymentList?.attributes?.currency?.currency_ar)}{" "}
                        {Number(Number(getMyPrePaymentList?.attributes?.paid_amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Date")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(getMyPrePaymentList?.attributes?.title, "DD-MM-YYYY").format("MMM DD, YYYY hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="previous-details-download-share-btn download-share-bar">
                <CloseButton
                  className="payment-details-download-btn payment-download-btn"
                  fullWidth
                  onClick={() => this.prePaymentDetailsDownload()}
                >
                  {t("Download Invoice")}
                </CloseButton>
                <Box
                  className="previous-details-share-btn shareIcon"
                  onClick={this.handlePaymentShareModal}
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                >
                  <img src={Share} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          isOpen={this.state.isShareModalOpen}
          heading={t("Share")}
          language={language}
          handleClose={this.handlePaymentShareModal}
          documentURL={`${baseURL}/bx_block_fees_payment/invoices/${this.state.paymentId}/download_invoice.pdf`}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
