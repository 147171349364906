// Customizable Area Start
import React from "react";

import ComplexDropdownController from "./ComplexDropdownController.web";
import {withRouter} from "react-router-dom";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import {FormControl, withStyles} from "@material-ui/core";
import {DashboardStyleWeb} from "../../blocks/dashboard/src/DashboardStyle.web";

class ExampleBlock extends ComplexDropdownController {
  render() {
    const { classes }: any = this.props
    return (
      <>
        {
          this.state.isShow &&
            <Box className={classes.generalDashboard}>
              <Box className="formGroup customSelect" >
                <FormControl variant="outlined" >
                  <select
                      name="complex Select"
                      className="select-year"
                      id={"ServiceTypeSelect"}
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.selectedComplex}
                  >
                    {
                      this.state.complexList.map((item:any)=> {
                        return(
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                        )
                      })
                    }
                  </select>
                </FormControl>
              </Box>
            </Box>
        }
      </>
    );
  }
}
// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(ExampleBlock)));
// Customizable Area End
