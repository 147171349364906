// Customizable Area Start
import React from "react";
import {
    Container,
    Typography,
    withStyles,
    Button,
    IconButton,
    Divider,
    Box,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Card,
    FormControl,
    TextareaAutosize,
} from "@material-ui/core";
import AuditReportDetailsController, {Props} from "./AuditReportDetailsController.web";
import {withTranslation} from "react-i18next";
import "web/src/i18n";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import {CheckIcon} from "../../user-profile-basic/src/assets";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {DownloadIcon, PdfIcon} from "./assets";
import {ROLE} from "../../../framework/src/Enum";
import {withRouter} from "react-router";
import AlertSuccessWeb from "../../../components/src/AlertSuccess.web";
import AlertError from "../../../components/src/AlertError.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class AuditReportDetails extends AuditReportDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {t, classes}: any = this.props;
        const userType = this.state.userType;
        return (
            <>
                <GeneralHeader>
                    <Container className={classes.reportDetails}>
                        <Box className="navigation">
                            {userType === ROLE.AUDITOR ? (
                                <Typography variant="body1">
                                    {t("Reports")} /{" "}
                                    <Box
                                        className="report-ExpenseReport onClickAuditReportBreadcrums"
                                        component="span"
                                        onClick={() => this.props.history.push("/AuditReports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {t("Audit Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" style={{color: "blue"}}>
                                        {t("Audit Report Details")}
                                    </Box>
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    {t("Documents & Reports")} /
                                    <Box
                                        className="report-ExpenseReport onClickAuditReportBreadcrums"
                                        component="span"
                                        onClick={() => this.props.history.push("/Reports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {" "}
                                        {t("Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box
                                        className="report-ExpenseReport AuditReportClick2"
                                        component="span"
                                        onClick={() => this.props.history.push("/AuditReports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {t("Audit Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" style={{color: "blue"}}>
                                        {t("Audit Report Details")}
                                    </Box>
                                </Typography>
                            )}
                            <Typography variant="h5" className="sub-heading bold-text"
                                        style={{marginTop: "10px", fontSize: "28px"}}>
                                {t("Audit Report Details")}
                            </Typography>
                        </Box>

                        <Box className="budget-box audit-box">
                            <Card>
                                <Box className="heading">
                                    <h4 className="bold-text">{t("Report Details")}</h4>
                                    {this.state?.auditReportDetails?.status == "Pending" && (
                                        <Typography variant="body1" className={"statusOngoingRed"}>
                                            {t("Pending Approval")}
                                        </Typography>
                                    )}
                                    {this.state?.auditReportDetails?.status == "Rejected" && (
                                        <Typography variant="body1" className={"statusRejected"}>
                                            {t("Rejected")}
                                        </Typography>
                                    )}
                                    {this.state?.auditReportDetails?.status == "Approved" && (
                                        <Typography variant="body1" className={"statusOngoingGreen"}>
                                            {t("Approved")}
                                        </Typography>
                                    )}
                                </Box>
                                <Divider/>
                                <Box className="budget-content-box">
                                    <Box className="content-line">
                                        <p>{t("Year")}</p>
                                        <span>{this.state?.auditReportDetails?.year}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="content-line">
                                        <p>{t("Report Generated on")}</p>
                                        <span>{this.state.auditReportDetails?.report_generated_on}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="content-line">
                                        <p>{t("Report Generated By")}</p>
                                        <span>{this.state.auditReportDetails?.report_rejected_by}</span>
                                    </Box>
                                    {this.state.auditReportDetails.pdf_url?.map((item: any, key: any) => {
                                        return (
                                            <Box className="report-pdf-box" key={key}>
                                                <Box className="left-side">
                                                    <img src={PdfIcon} alt=""/>
                                                    <span>{item.file_name}</span>
                                                </Box>
                                                <img
                                                    src={DownloadIcon}
                                                    alt=""
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => window.open(item.download_url, "_blank")}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Card>
                        </Box>
                        {this.state.auditReportDetails.status === "Rejected" && (
                            <Box className="rejection-box">
                                <Card>
                                    <h4>{t("Rejection Reason")}</h4>
                                    <p>{this.state.auditReportDetails.comments}</p>
                                </Card>
                            </Box>
                        )}
                        {this.state.auditReportDetails.status === "Pending" && userType === ROLE.CHAIRMAN && (
                            <Box className="button-box">
                                <Button className="cancel RejectAuditReportButton"
                                        onClick={() => this.handleRejectReportModal()}>
                                    {t("Reject")}
                                </Button>
                                <Button className="edit auditReportRejectButton auditReportApproveConfirmation"
                                        onClick={() => this.handleApproveReportModal()}>
                                    {t("Approve")}
                                </Button>
                            </Box>
                        )}
                    </Container>
                </GeneralHeader>

                <Dialog fullWidth className="add-meeting" open={this.state.isRejectReportModalOpen}>
                    <MuiDialogTitle disableTypography className="dialog-heading">
                        <Typography variant="h6">{t("Reject Audit Report")}</Typography>
                        <IconButton onClick={() => this.handleRejectReportModal()}>
                            <CloseIcon/>
                        </IconButton>
                    </MuiDialogTitle>
                    <DialogContent dividers>
                        <FormControl fullWidth>
                            <TextareaAutosize
                                className="reject-note RejectAuditReportNote"
                                placeholder={t("Add Notes")}
                                onChange={(e) => this.setState({rejectReason: e.target.value})}
                                value={this.state.rejectReason}
                            />
                            <p style={{color: "red"}}>{this.state.rejectReasonError}</p>
                        </FormControl>
                    </DialogContent>
                    <DialogActions className="dialog-button-group ">
                        <Button className="cancel-button AuditReportRejectCancelButton"
                                onClick={() => this.handleRejectReportModal()}>
                            {t("Cancel")}
                        </Button>
                        <Button className="add-button AuditReportRejectSubmitButton"
                                onClick={this.handleRejectAuditReport}>
                            {t("Submit")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    onClose={() => this.handleApproveReportModal()}
                    open={this.state.isApproveReportModalOpen}
                    className="cancel-meeting-dialog"
                >
                    <DialogContent style={{margin: "15px 0"}}>
                        <Box textAlign="center">
                            <img className="comment-image" src={CheckIcon} alt="check"/>
                            <Typography variant="h6">{t("Approve Audit Report")}</Typography>
                            <Typography variant="body1" style={{marginBottom: "0px"}}>
                                {t("Are you sure you want to approve audit report")}?
                            </Typography>
                            <DialogActions className="dialog-button-group">
                                <Button
                                    className="cancel-button AuditReportApproveCancelButton"
                                    style={{width: "200px", height: "55px"}}
                                    onClick={() => this.handleApproveReportModal()}
                                >
                                    {t("Close")}
                                </Button>
                                <Button
                                    style={{width: "200px", height: "55px"}}
                                    className="add-button AuditReportApproveSubmitButton"
                                    onClick={this.manageAuditReportApproval}
                                >
                                    {t("Approve")}
                                </Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                <AlertSuccessWeb
                    show={this.state.showSuccess}
                    handleClose={() => this.setState({showSuccess: false})}
                    message={this.state.successMessage}
                />
                <AlertError
                    show={this.state.showError}
                    handleClose={() => this.setState({showError: false})}
                    message={this.state.error}
                />
            </>
        );
    }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(AuditReportDetails)));

// Customizable Area End
