// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  anchorEl: any;
  loading: boolean;
  sortBy: any;
  budgetYear: any;
  status: any;
  pollListing: any;
  toggleAscDesc: string;
  isOpen: boolean;
  complexId: any;

  // budgetSpendingResponse:any,
  buildingBudgetResponse: any[];
  total: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getBudgetSpendingListId: string = "";
  apiEmailLoginCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      anchorEl: "",
      loading: false,
      complexId: "",
      sortBy: "",
      toggleAscDesc: "asc",
      isOpen: false,
      status: "due",
      buildingBudgetResponse: [],
      budgetYear: new Date().getFullYear(),
      pollListing: [],
      total: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    this.setState({ complexId: complex_id }, () => {
      this.getBudgetSpendingList();
    });
  }

  getBudgetSpendingList = async () => {
    const { complexId } = this.state;
    this.getBudgetSpendingListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${complexId}/bx_block_fees_payment/building_budgets/budget_spending?search_year=${this.state.budgetYear}&status=${this.state.anchorEl}&sort_by=${this.state.toggleAscDesc}`,
    });
  };
  
  budgetSpendingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.expence_report) {
      this.setState({
        buildingBudgetResponse: responseJson?.expence_report?.data,
        total: responseJson.Totals,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (this.getBudgetSpendingListId === apiRequestCallId) {
        this.budgetSpendingListResponse(responseJson);
      }
    }
  }

  handleChange = (e: any) => {
    this.setState({ budgetYear: e.target.value }, () => {
      this.getBudgetSpendingList();
    });
  };
}

// Customizable Area End
