// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  location:any;
}

interface S {
  loading:boolean;
  setOpen:boolean;
  teamAddData:any;
  filterBuilding:any;
  filterShortBy:any;
  filterCategory:any;
  taskList:any;
  filterStatus:any;
  roleList:any;
  buildingList:any;
  categoryList:any;
  userList:any;
  startDateName:any;
  endDateName:any;
}

interface SS {
  id: any;
}

export default class FriendListController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  getTaskManagementBuildingListCallId: string = "";
  getTaskManagementCategoryListCallId: string = "";
  getTaskManagementRoleListCallId: string = "";
  getTaskManagementUserListCallId: string = "";
  getTaskManagementTaskListCallId: string = "";
  createTaskManagementTaskAddCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      loading:false,
      setOpen:false,
      teamAddData:{},
      filterBuilding:"",
      filterShortBy:"",
      filterCategory:"",
      filterStatus:"",
      taskList:[],
      roleList:[],
      buildingList:[],
      categoryList:[],
      userList:[],
      startDateName:"text",
      endDateName:"text",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  TaskAddSchema() {
    return Yup.object().shape({
      // @ts-ignore
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date().required("End Date is required"),
      buildingId: Yup.string().required("Building is required"),
      roleId: Yup.string().required("Role is required"),
      assignedTo: Yup.string().required("Assigned To is required"),
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
    })
  }

  async componentDidMount() {
    this.getTaskManagementBuildingList()
    this.getTaskManagementRoleList()
    this.getTaskManagementCategoryList()
    this.getTaskManagementTaskList()
  }

  handleClose = () => {
    this.setState({setOpen:false});
  };

  getTaskManagementRoleListResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("data")){
      let filterTeamRole = responseJson?.data?.filter((item:any) => item.attributes.dashboard_name !== null)
      this.setState({
        roleList:filterTeamRole,
      })
    }else{
      this.setState({
        roleList:[]
      })
    }
  }

  handleEndDate = () => {
    this.setState({
      endDateName:"date"
    })
  }

  handleStartDate = () => {
    this.setState({
      startDateName:"date"
    })
  }
  getTaskManagementBuildingListResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("buildings")){
      this.setState({
        buildingList:responseJson?.buildings,
      })
    }else{
      this.setState({
        buildingList:[]
      })
    }
  }

  getTaskManagementCategoryListResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("categories")){
      this.setState({
        categoryList:responseJson?.categories,
      })
    }else{
      this.setState({
        categoryList:[]
      })
    }
  }

  getTaskManagementUserListResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("account")){
      this.setState({
        userList:responseJson?.account,
      })
    }else{
      this.setState({
        userList:[]
      })
    }
  }

  createNewTask = async (values:any) => {
    const societyID = localStorage.getItem("society_id")
    console.log("VALUES",values)
    const body = {
      "title": values.title,
      "description": values.description,
      "society_management_id":societyID,
      "building_management_id": values.buildingId,
      "assign_actions_type": "Manual Task",
      "assignee_id": values.assignedTo,
      "manual": true,
      "start_date": values.startDate,
      "end_date": values.endDate
    }
    this.createTaskManagementTaskAddCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `/bx_block_dashboard/assign_actions`,
      body: JSON.stringify(body),
    });
  }

  createTaskManagementTaskAddResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("data")){
      this.setState({
        setOpen:false,
      })
      this.getTaskManagementTaskList()
    }
  }
  getTaskManagementTaskListResponse = (responseJson: any) => {
    if(responseJson.hasOwnProperty("data")){
      this.setState({
        taskList:responseJson?.data,
      })
    }else{
      this.setState({
        taskList:[]
      })
    }
  }
  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getTaskManagementRoleListCallId){
        this.getTaskManagementRoleListResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskManagementBuildingListCallId){
        this.getTaskManagementBuildingListResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskManagementCategoryListCallId){
        this.getTaskManagementCategoryListResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskManagementUserListCallId){
        this.getTaskManagementUserListResponse(responseJson)
      }
      if(apiRequestCallId === this.createTaskManagementTaskAddCallId){
        this.createTaskManagementTaskAddResponse(responseJson)
      }
      if(apiRequestCallId === this.getTaskManagementTaskListCallId){
        this.getTaskManagementTaskListResponse(responseJson)
      }
    }
  }

  getTaskManagementBuildingList = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getTaskManagementBuildingListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${societyID}/bx_block_report/management_fees/building_list`,
    });
  }

  getTaskManagementRoleList = async () => {
    this.getTaskManagementRoleListCallId = await this.apiCall({
      contentType: "application/json",
      method:"GET",
      endPoint: `/bx_block_roles_permissions/roles`,
    });
  }

  getTaskManagementCategoryList = async () => {
    this.getTaskManagementCategoryListCallId = await this.apiCall({
      contentType: "application/json",
      method:"GET",
      endPoint: `/bx_block_dashboard/assign_actions/list_categories`,
    });
  }

  getTaskManagementUserList = async (id:any) => {
    const societyID = localStorage.getItem("society_id")
    this.getTaskManagementUserListCallId = await this.apiCall({
      contentType: "application/json",
      method:"GET",
      endPoint: `bx_block_dashboard/user_list?society_management_id=${societyID}&role_id=${id}`,
    });
  }

  getTaskManagementTaskList = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getTaskManagementTaskListCallId = await this.apiCall({
      contentType: "application/json",
      method:"GET",
      endPoint: `/bx_block_dashboard/assign_actions/index_chairman?society_management_id=${societyID}&assign_actions_type=${this.state.filterCategory}&building_management_id=${this.state.filterBuilding}&status=${this.state.filterStatus}&sort_by=${this.state.filterShortBy}`,
    });
  }
}

// Customizable Area End