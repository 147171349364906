// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Box,
  withStyles,
  Button,
  Divider,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
  IconButton,
  MenuItem,
  Link as NavLink,
} from "@material-ui/core";
import CEMeetingMinutesController, { Props } from "./CEMeetingMinutesController.web";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import Loader from "../../../../components/src/Loader.web";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import Pagination from "@material-ui/lab/Pagination";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShareDocumentModal from "../../../../components/src/DocumentComponent/ShareModal.web";

class CEMeetingMinutes extends CEMeetingMinutesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />

        <Container className={classes.scheduledMeeting}>
          <Box className="navigation">
            <Box>
              <Typography variant="body1">
                {t("Activities")} /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Meeting Minutes")}
                </Box>
              </Typography>
              <Typography variant="h5" className="sub-heading bold-text">
                {t("Meeting Minutes")}
              </Typography>
            </Box>
          </Box>

          <Box className="top-bar-filter">
            <Box className="filter-head-box">
              <FilterSelect
                t={t}
                language={language}
                option={[{ label: "All", value: "all" }, ...this.state.reactStateList]}
                value={this.state.filter.company}
                onChange={(e: any) => this.handleFilterChange(e, "company")}
                label="Select Company"
              />
              <FilterSelect
                value={this.state.filter.isComplex}
                t={t}
                language={language}
                option={[
                  { label: "All", value: 0 },
                  { label: "Complex", value: 1 },
                  { label: "Individual Building", value: 2 },
                ]}
                style={{ fontWeight: "bold", color: "black" }}
                onChange={(e: any) => this.handleFilterChange(e, "isComplex")}
              />
              <FilterSelect
                t={t}
                option={[{ label: "All", value: "all" }, ...this.state.countriesList]}
                language={language}
                value={this.state.filter.country}
                onChange={(e: any) => this.handleFilterChange(e, "country")}
                label="Select Country"
              />
              <FilterSelect
                t={t}
                option={[{ label: "All", value: "all" }, ...this.state.citiesList]}
                language={language}
                value={this.state.filter.city}
                label="Select City"
                onChange={(e: any) => this.handleFilterChange(e, "city")}
              />
              <FilterSelect
                t={t}
                option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                language={language}
                value={this.state.filter.complex}
                label={this.state.filter.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
                onChange={(e: any) => this.handleFilterChange(e, "complex")}
              />
              {this.state.filter.isComplex !== 2 && (
                <FilterSelect
                  t={t}
                  language={language}
                  onChange={(e: any) => this.handleFilterChange(e, "building")}
                  value={this.state.filter.building}
                  label="Select Building"
                  option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                />
              )}
              <FilterSelect
                t={t}
                value={this.state.filter.status}
                language={language}
                label="Select Status"
                onChange={(e: any) => this.handleFilterChange(e, "status")}
                option={[
                  { label: "All", value: "all" },
                  { label: "Pending", value: "pending" },
                  { label: "Approved", value: "approved" },
                  { label: "Rejected", value: "rejected" },
                ]}
              />
              <FilterSelect
                t={t}
                value={this.state.filter.sortBy}
                language={language}
                option={[
                  { label: "All", value: "all" },
                  { label: "Asc", value: "ASC" },
                  { label: "Desc", value: "DESC" },
                ]}
                onChange={(e: any) => this.handleFilterChange(e, "sortBy")}
                label="Sort By"
              />
              <FilterSelect
                t={t}
                onChange={(e: any) => this.handleFilterChange(e, "addedBy")}
                label="Added By"
                value={this.state.filter.addedBy}
                language={language}
                option={[{ label: "All", value: "all" }, ...this.state.addedByList]}
              />
              <Button
                className="action-search-btn"
                startIcon={<SearchIcon />}
                onClick={() => this.getAllMeetingMinutes()}
              >
                {t("Search")}
              </Button>
            </Box>
          </Box>

          <Grid className="meeting-table">
            <Grid item sm={12} md={12} xs={12}>
              <Box className="table-top">
                <h4 className="bold-text">{t("Meeting Minutes")}</h4>
                <SearchInput
                  label="Search by title"
                  t={t}
                  value={this.state.filter.search}
                  language={language}
                  onChange={(e: any) =>
                    this.setState({ filter: { ...this.state.filter, search: e.target.value } }, () =>
                      this.getAllMeetingMinutes()
                    )
                  }
                />
              </Box>
              <Divider />
              <TableContainer>
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("#")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Title")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Agenda")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Complex")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Building")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Date & Time")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Status")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.meetingMinutes.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8} align={languageCondition(language, "right", "left")}>
                          {t("No Meeting Minute Available!")}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.meetingMinutes.map((minutes: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className="ellipse-one"
                            title={minutes.attributes.title}
                          >
                            {minutes.attributes.title}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                            {minutes.attributes.agenda}
                          </TableCell>
                          <TableCell
                            className="ellipse-one"
                            title={minutes.attributes.society_management || "All"}
                            align={languageCondition(language, "right", "left")}
                          >
                            {minutes.attributes.society_management || "All"}
                          </TableCell>
                          <TableCell
                            className="ellipse-one"
                            title={minutes.attributes?.building?.name}
                            align={languageCondition(language, "right", "left")}
                          >
                            {minutes.attributes?.building?.name || "All"}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className="ellipse-one"
                            title={moment(minutes.attributes.meeting_date_time, "DD-MM-YYYY HH:mm").format(
                              "MMMM DD, YYYY HH:mm"
                            )}
                          >
                            {moment(minutes.attributes.meeting_date_time, "DD-MM-YYYY HH:mm").format(
                              "MMMM DD, YYYY HH:mm"
                            )}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            <span className={minutes.attributes.meeting_mins_status}>
                              {t(minutes.attributes.meeting_mins_status)}
                            </span>
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={LanguageAttributeHandler(language, "meeting-menu", "")}
                          ></TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem>
                                <Link to={`/CompanyEmployee/CEMeetingDetail/${minutes.id}`}>{t("View")}</Link>
                              </MenuItem>
                              {minutes.attributes.meeting_mins_pdf?.url && (
                                <>
                                  <MenuItem>
                                    <NavLink href={minutes.attributes.meeting_mins_pdf.url} target="_blank">
                                      {t("Download")}
                                    </NavLink>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      this.setState({ shareUrl: minutes.attributes.meeting_mins_pdf.url }, () =>
                                        this.handleShareModal()
                                      )
                                    }
                                  >
                                    {t("Share")}
                                  </MenuItem>
                                </>
                              )}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <Box className="table-bottom">
                <p>
                  {t("Showing")} <span className="current-page">{this.state.meetingMinutes.length}</span> {t("of")}{" "}
                  <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span>{" "}
                  {t("results")}
                </p>
                {this.state.pagination && (
                  <Pagination
                    variant="outlined"
                    onChange={this.handlePagination}
                    siblingCount={2}
                    count={this.state.pagination.total_pages}
                    shape="rounded"
                    page={this.state.pagination.current_page}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
        />
      </CEHeaderComponentWeb>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEMeetingMinutes)));
// Customizable Area End
