// Customizable Area Start
// @ts-ignore
// @ts-nocheck

import React from "react";
import "./Dashboard.web.css";
import "../../../web/src/assets/css/style.scss";
import {
  Card,
  CardContent,
  Container,
  FormControl,
  Link,
  Typography,
  withStyles
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { incedentBuilding, incedentUnit, incedentUser } from "../../ContentManagement/src/assets";
import ServiceProviderController, { Props } from "./ServiceProviderController.web";
import { withRouter } from "react-router";
import {keyhand, keyrented, money} from "./assets";
import ChairmanNumberCard from "../../../components/src/DashboardCard/ChairmanNumberCard.web";
import {withTranslation} from "react-i18next";
import {DashboardStyleWeb} from "./DashboardStyle.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class ServiceProviderDashboard extends ServiceProviderController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    // test case try code change
    const { t, classes,i18n }: any = this.props;
    const language = i18n.language;
    return (
      <>
        <GeneralHeader  >
              <Box  className={classes.generalDashboard}>
              <Container>
                <Box style={ServiceDashboard.navigation}>
                  <Box>
                    <Typography variant="body1" >
                      <Link color="inherit"> {t("My Dashboard")}</Link> /
                      <Link color="inherit"> {t("General Dashboard")}</Link>
                    </Typography>
                    <Typography variant="h5" className="bold-text" style={ServiceDashboard.subHeading}>{t("Dashboard")}</Typography>
                  </Box>
                  <Box className={ServiceDashboard.sorting_header}>
                    <Box className="formGroup customSelect" >
                      <FormControl variant="outlined" >
                        <select
                          name="yearSelect"
                          className="select-year"
                          id={"ServiceTypeSelect"}
                          onChange={(e) => this.handleChange(e)}
                          value={this.state?.selectedValue}
                        >
                          <option value="">
                            {t("Show All")}
                          </option>
                          <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                          <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                          <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                        </select>
                      </FormControl>
                    </Box>
                    <Box>
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={2} style={{ marginTop: 15, marginBottom: 15 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <ChairmanNumberCard
                        image={keyhand}
                        heading={t("Total Assigned Tickets")}
                        titleOne=""
                        valueOne={this.state.dashboardServiceProvider?.total_assinged_tickets || 0 + ""}
                        titleTwo=""
                        valueTwo=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ChairmanNumberCard
                        image={keyrented}
                        heading={t("Open Tickets")}
                        titleOne=""
                        valueOne={this.state.dashboardServiceProvider?.open_tickets || 0 + ""}
                        titleTwo=""
                        valueTwo=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ChairmanNumberCard
                        image={money}
                        heading={t("Closed Tickets")}
                        titleOne=""
                        valueOne={this.state.dashboardServiceProvider?.closed_tickets|| 0 + ""}
                        titleTwo=""
                        valueTwo=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ChairmanNumberCard
                        image={keyhand}
                        heading={t("Pending Confirmation")}
                        titleOne=""
                        valueOne={this.state.dashboardServiceProvider?.pending_confirmation_tickets || 0 + ""}
                        titleTwo=""
                        valueTwo=""
                    />
                  </Grid>
                </Grid>
              </Container>
              <Container>
                <Box style={ServiceDashboard.navigation}>
                  <Box>
                    <Typography variant="h5" className="bold-text" style={ServiceDashboard.subHeading}>{t("Open Tickets")}</Typography>
                  </Box>
                </Box>
                <Grid container spacing={2} style={{ marginTop: 15, marginBottom: 15 }}>
                  {this.state?.openTickets.length > 0 ?
                    this.state?.openTickets.map((val, index) => {
                      return (
                          <Grid item sm={4} key={index} id="OpenTicket" onClick={() => this.getProviderDetails(val?.incident_id)} >
                            <Card className="management-card card">
                              <CardContent className="costom-card-content">
                                <ButtonStatus val={t(val?.incident_status)}></ButtonStatus>
                                <Typography component="h4" className="bold-text" style={{ fontSize: "20px" }}>
                                  {val?.incident_related_to}
                                </Typography>
                                <Box className="card-rows">
                                  <img src={incedentBuilding} alt="Bank Icon" />
                                  <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>
                                    {val?.building_name}
                                  </p>
                                </Box>
                                <Box className="card-rows">
                                  <img src={incedentUnit} alt="Bank Icon" />
                                  <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>
                                    {val?.unit_number}
                                  </p>
                                </Box>
                                <Box className="card-rows">
                                  <img src={incedentUser} alt="Bank Icon" />
                                  <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>
                                    {val?.reported_by}
                                  </p>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                      );
                    })
                    : <>
                        <Typography variant="body1" style={{marginLeft:"10px"}} color="textSecondary">{t("No Open Tickets found")}</Typography>
                      </>
                  }
                </Grid>
              </Container>
              </Box>
        </GeneralHeader>
      </>
    );
  }
}


const ButtonStatus = (props: any) => {
  return (
    <>
      <Box className="customButton" >
        <Typography  variant="contained" className="statusOngoingBlue" type="submit" style={{ borderRadius: "25px",fontSize:"13px" }}>
          {props?.val}
        </Typography>
      </Box>
    </>
  );
};

const ServiceDashboard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    width: "100%",
    maxWidth: "250px",
    padding: "10px 10px",
    borderRadius: 10,
    margin: "0px 15px 15px 0px",
    cursor: "pointer"
  },
  custom_card_content: {
    padding: "10px 5px"
  },
  sorting_header: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

// Customizable Area End

export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(ServiceProviderDashboard)));