// Customizable Area Start
import React from "react";
import { Container, Typography, Button, withStyles, Card, Link } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DashboardActionsController, { Props } from "../../../blocks/dashboard/src/DashboardActionsController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import SearchIcon from "@material-ui/icons/Search";
import { ActionAssign, ActionBuilding, ActionCalender } from "./assets";
import moment from "moment";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class DashboardActions extends DashboardActionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes }: any = this.props;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.generalDashboard}>
                <Box className="navigation action-header-box">
                  <Typography variant="body1">
                    {t("My Dashboards")} /{" "}
                    <Box component="span" style={{ color: "blue" }}>
                      {t("Action Assign to me")}
                    </Box>
                  </Typography>
                  <Box className="sub-heading-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Action Assign to me")}
                    </Typography>
                  </Box>
                  <Box className="action-filter-box">
                    <select className="select-year" onChange={(e: any) => { this.setState({ filter: { ...this.state.filter, building: e.target.value } }); }}>
                      <option value="">{t("Select Building")}</option>
                      {this.state.buildingList.map((building: any) => {
                        return (
                          <option value={building.id} key={building.id}>
                            {building.attributes.name}
                          </option>
                        );
                      })}
                    </select>
                    <select className="select-year" onChange={(e: any) => { this.setState({ filter: { ...this.state.filter, sort: e.target.value } }); }}>
                      <option value="">{t("Sort By")}</option>
                      <option value="asc">{t("Asc")}</option>
                      <option value="desc">{t("Desc")}</option>
                    </select>
                    <Button startIcon={<SearchIcon />} onClick={() => {
                      this.setState({
                        filterBuilding: this.state.filter.building,
                        filterSort: this.state.filter.sort,
                        filterCategory: this.state.filter.category,
                      });
                    }}>{t("Search")}</Button>
                  </Box>
                </Box>
                <Box className="action-content-main-box">
                  <Grid container spacing={2}>
                    {this.state.actionList.map((action) => {
                      return (
                        <Grid item sm={12} key={action.id}>
                          <Card className="action-card">
                            <h4 className="bold-text">{action.attributes.title}</h4>
                            <p className="description">{action.attributes.description}</p>
                            <Box className="action-info-box">
                              <Box className="action-content-box">
                                <Box className="action-info">
                                  <img src={ActionBuilding} alt="" />
                                  <Box>
                                    <span>{t("Building")}</span>
                                    <p className="bold-text">{action.attributes?.building_management?.name}</p>
                                  </Box>
                                </Box>
                                <Box className="action-info">
                                  <img src={ActionAssign} alt="" />
                                  <Box>
                                    <span>{t("Assigned by")}</span>
                                    <p className="bold-text">{action.attributes.assign_by}</p>
                                  </Box>
                                </Box>
                                <Box className="action-info">
                                  <img src={ActionCalender} alt="" />
                                  <Box>
                                    <span>{t("Assigned on")}</span>
                                    <p className="bold-text">{moment(action.attributes.assign_on, "DD-MM-YYYY").format("MMMM DD, YYYY")}</p>
                                  </Box>
                                </Box>
                                <Box className="action-info">
                                  <img src={ActionCalender} alt="" />
                                  <Box>
                                    <span>{t("Due till")}</span>
                                    <p className="bold-text">{moment(action.attributes.end_date, "DD-MM-YYYY").format("MMMM DD, YYYY")}</p>
                                  </Box>
                                </Box>
                              </Box>
                              {action.attributes.assign_actions_type !== "Manual Task" && (
                                <Link href={`${action.attributes.app_url}`}><Button>{t("View Assigned Task")}</Button></Link>
                              )}
                            </Box>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(DashboardActions)));
// Customizable Area End
