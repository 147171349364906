import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import {  RouteComponentProps } from "react-router-dom";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {apiCall} from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  suggestionList:any
  loading:boolean
  error: string | null;
  realtedData:any
  complexList:any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SuggestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[] =[];
  createAccountApiCallId: any;
  apiupdateIncidentCallId:any;
  apicreateIncidentCallId: any;
  validationApiCallId: any;
  getSuggestionListingApiCallId: any;
  getIncidentDetailsByIdApiCallId : any ;
  getCommonAreaApiCallId : any ;
  getIncidentRelatedApiCallId:any;
  getMyApartmentListApiCallId:any;
  getRelatedToDataAPICallId:any;
  createSuggestionApiCall:any
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any= "";
  labelFirstName: string= "";
  lastName: string= "";
  labelEmail: string= "";
  labelPassword: string= "";
  labelRePassword: string= "";
  labelLegalText: string= "";
  labelLegalTermCondition: string= "";
  labelLegalPrivacyPolicy: string= "";
  btnTextSignUp: string= "";

  currentCountryCode: any= "";
  getComplexListId:any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  //@ts-ignore
    this.state = {
      // Customizable Area Start
      suggestionList:[],
      loading:false,
      error: null,
      realtedData:[],
      complexList:[]
      // Customizable Area End
    };

    // Customizable Area Start
 
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      

      if (apiRequestCallId && responseJson) {
        
    
        if (apiRequestCallId === this.getSuggestionListingApiCallId) {
          this.getSuggestionListData(responseJson)
         
        }
        else if (apiRequestCallId === this.getRelatedToDataAPICallId) {
          this.getRealtedData(responseJson)
          
   
         }
         else if (apiRequestCallId === this.createSuggestionApiCall) {
          this.createSuggestionHandle(responseJson)
         }
         else if(this.getComplexListId === apiRequestCallId){
          this.getComplexResponse(responseJson)
        }

    }

   

  
    // Customizable Area End
  }
}

  // Customizable Area Start

  getComplexList = async () => {
    const userType = localStorage.getItem("userType")
    this.getComplexListId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,
    });
  }

  getComplexResponse = (responseJson:any) => {
    if(responseJson.hasOwnProperty("complex")){
      this.setState({complexList:responseJson.complex})
    }
  }
  getSuggestionListData(responseJson:any){
    if (responseJson?.data ) {
      console.log("getIncidentListingApiCallId ========================>",responseJson)
      this.setState({suggestionList :responseJson?.data})
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }
  createSuggestionHandle(responseJson:any){
    if (responseJson?.data ) {
     //@ts-ignore
     localStorage.setItem('selectSuggestion',JSON.stringify(responseJson))
      this.props.history.push("/NewRequestSuggestion");
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
    
  }
  getRealtedData(responseJson:any){
    if (responseJson?.data ) {
      console.log("getrealtedData ========================>",responseJson)
      this.setState({realtedData :responseJson?.data},()=>this.state.realtedData)
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
    
  
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
     
      
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
     
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
      //@ts-ignore
    onPress: () => this.createAccount()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
  

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
    

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
     

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
     

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


clear= () => {
  localStorage.clear()
  //@ts-ignore
  this.props.history.push("/");
}

onSubmit =(values:any)=>{
  localStorage.setItem("incidentPreview", JSON.stringify(values))
  console.log("onsbumit=========>", values);
    this.setState({ loading: true })
    //@ts-ignore
    this.props.history.push("/IncidentPreview")
}

  getSuggtionListing= ()  => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getSuggestionListingApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

     const  getSortByOrStatus = `bx_block_suggestion/suggestions?chairman=false`

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getSortByOrStatus
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  

createSuggtionSchema() {
  // @ts-ignore
  const {t} = this.props
    const validations = Yup.object().shape({
      selectedComplex:Yup.string().required(t(`This field is required`)).trim(),
      title: Yup.string().required(t(`This field is required`)).trim(),
      relatedTo: Yup.string().required(t(`This field is required`)).trim(),
      description: Yup.string().required(t(`This field is required`)).max(200, t("Too Long!")),
    });

    return validations ;
  }
  getRealtedToData = async () => {

    try {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getRelatedToDataAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_suggestion/suggestions/suggestion_related_list `
      );

      const header = {
        token: localStorage.getItem("userToken"),
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );



      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } catch (error) {
      console.log(error);
    }

  }
  redirectToDashboard = () => {
    let userType = localStorage.getItem('userType')
    if (userType == 'Owner') {
      //@ts-ignore
      this.props.history.push('/OwnerDashboard')
    } else {
      //@ts-ignore
      this.props.history.push('/residentDashboard')
    }

  }
  createSuggestion=(values:any)=>{
    console.log(values)
    try {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const httpBody={
        "title": values.title,
        "description": values.description,
        "account_id": localStorage.getItem('userId'),
        "building_management_id": values.selectedComplex,
        "suggestion_related_id": values.relatedTo
      }
      this.createSuggestionApiCall = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_suggestion/suggestions`
      );

      const header = {
        token: localStorage.getItem("userToken"),
        "content-type":'application/json'
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } catch (error) {
      console.log(error);
    }

  }

  openSuggestion=(item:any)=>{
    console.log(this.props)
    localStorage.setItem('selectSuggestion',JSON.stringify(item))
    // @ts-ignore
    this.props.history.push('/SuggestionData')
  }

  // Customizable Area End
}
