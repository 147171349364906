// Customizable Area Start
import * as React from "react";
// custom components
import {
    Grid, Box, Divider,Typography,Button,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { sceduledVisitor,pastVisitor  } from "./assets";
import VisitorController, {
    Props
} from "./VisitorController";
import './style.css'
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class Visitors extends VisitorController{
    constructor(props: Props) {
        super(props);
    }

    render() {
        // @ts-ignore
        const {t,i18n} = this.props
        const {language} = i18n;
        return (
            <>
                <Grid item xs={12} md={12} className="auth-cols VisitorsMain" dir={languageCondition(language,"rtl","ltr")}>
                    <Grid container style={{ margin: '1rem', width: '90%' }} className="VisitorsMain">
                        <Grid item xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}}  className="VisitorsMain">
                            <Box style={{ display:"flex", alignItems:"center", gap:"1rem"}}  className="VisitorsMain">
                                <ArrowBackIcon onClick={() => this.props.history.push("/")} />
                                <p className="bold-text VisitorsMain" style={{fontSize: '18px'}} >
                                    {t("My Visitors")}
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Box style={{background: "#F7F9FE",minHeight:"90%",display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"space-between"}}  className="VisitorsMain">
                        <Grid container spacing={2} style={{width:"90%"}}  className="VisitorsMain">
                            <Grid item xs={12}  className="VisitorsMain">
                                <Box
                                    display="flex"
                                    justifyContent='space-between'
                                    alignItems="center"
                                    borderRadius="15px"
                                    bgcolor="white"
                                    marginTop='1.5rem'
                                    padding='1.5rem'
                                    style={{boxShadow:"4px 0px 14px #ececec"}}
                                    className="VisitorsMainSceduled"
                                    onClick={()=>this.props.history.push("/ScheduledVisitors")}
                                >
                                    <Box style={{minWidth:"100%"}}  className="VisitorsMain">
                                        <Box style={{display:"flex",alignItems:"center"}}  className="VisitorsMain">
                                            <img src={sceduledVisitor} style={languageCondition(language,{marginLeft:"20px"},{marginRight:"20px"})}  className="VisitorsMain"/>
                                            <Typography variant={"body1"} style={{fontSize:"17px"}} className="bold-text VisitorsMain">
                                                {t("Scheduled Visitors")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}  className="VisitorsMain">
                                <Box
                                    display="flex"
                                    justifyContent='space-between'
                                    alignItems="center"
                                    borderRadius="15px"
                                    bgcolor="white"
                                    marginTop='1rem'
                                    padding='1.5rem'
                                    style={{boxShadow:"4px 0px 14px #ececec"}}
                                    onClick={()=>this.props.history.push("/PastVisitors")}
                                    className="VisitorsMainPastClick"
                                >
                                    <Box style={{minWidth:"100%"}}  className="VisitorsMainPast">
                                        <Box style={{display:"flex",alignItems:"center"}} className="VisitorsMainPast">
                                            <img src={pastVisitor} style={languageCondition(language,{marginLeft:"20px"},{marginRight:"20px"})} className="VisitorsMainPast" />
                                            <Typography variant={"body1"} style={{fontSize:"17px"}} className="bold-text VisitorsMainPast">
                                                {t("Past Visitors")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box style={{width:"90%",marginBottom:"20px",marginTop:"10px",position:'fixed',bottom:"0"}} className="VisitorsMainPast">
                            <AddVisitor variant="contained" fullWidth size="large" onClick={()=> this.props.history.push("/AddVisitor")} className="AddVisitorButton">
                                {t("Add Visitor Request")}
                            </AddVisitor>
                        </Box>
                    </Box>
                    <Box style={{background: "#F7F9FE",minHeight:"auto"}}></Box>
                </Grid>
            </>
        );
    }
}
export default withTranslation()(withRouter(Visitors))

const AddVisitor = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"100px",
        height:"55px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

// Customizable Area End
