// Customizable Area Start
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { languageCondition } from "./ConditionManager.web";
interface IProps {
  logo: any;
  isOpen: boolean;
  heading: any;
  message: any;
  confirmButton: any;
  cancelButton: any;
  closeFunction: any;
  confirmFunction: any;
  language?: any;
}

const ModalComponent = (props: IProps) => {
  const {
    logo,
    heading,
    message,
    isOpen,
    confirmButton,
    cancelButton,
    closeFunction,
    confirmFunction,
    language,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        fullWidth
        onClose={closeFunction}
        dir={languageCondition(language, "rtl", "ltr")}
        open={isOpen}
        className="cancel-meeting-dialog"
      >
        <DialogContent style={{ margin: "15px 0" }}>
          <Box textAlign="center">
            <img className="comment-image" src={logo} alt="check" />
            <Typography variant="h6" className="bold-text" style={{ fontSize: "22px" }}>
              {t(heading)}
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "16px" }}>
              {t(message)}
            </Typography>
            <DialogActions className="dialog-button-group">
              <Button
                className="cancel-button"
                style={{ width: "200px", height: "55px", marginRight: "10px" }}
                onClick={closeFunction}
              >
                {t(cancelButton)}
              </Button>
              <Button style={{ width: "200px", height: "55px" }} className="add-button" onClick={confirmFunction}>
                {t(confirmButton)}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalComponent;
// Customizable Area End
