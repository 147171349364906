// Customizable Area Start
import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import SuggestionController, { Props } from "./SuggestionController.web";
import { Building1, Tick_Circle_Icon } from "../../ContentManagement/src/assets";
import {withTranslation} from "react-i18next";

class NewRequestSuggestion extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    //@ts-ignore
    let data: any = JSON.parse(localStorage.getItem("selectSuggestion"));
    // @ts-ignore
    const {t} = this.props;
    return (
      <>
        <Box className="login-wrapper auth-wrapper" style={{ background:"white"}}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols" style={{height:"90%",display:'flex',alignItems:"center",justifyContent:"center"}}>
              <Box className="content-block" style={{height:"auto"}}>
                <Box className="logo-block common-top-padding" display={{ xs: "none", md: "flex" }}>
                  {/* <Link href="/EmailAccountLogin">
                    <img src={Building_Logo.default} className="head-logo" alt="" />
                    <h4>Building Name</h4>
                  </Link> */}
                </Box>
                <Box className="main-content-block change-password-mainblock">
                  <Box className="header-block header-block-changepassword">
                    <img src={Tick_Circle_Icon} className="lock-logo" alt="Lock_Icon" />
                    <h1 className="bold-text">
                      {t("Suggestion Sent")} <br />{t("Successfully")}
                    </h1>
                    <p>{t("Your suggestion has been sent successfully. Your ticket id for sent suggestion is")} {data.data.id}. </p>
                  </Box>
                </Box>
                <Box className="footer-block desktop-ui success-suggestion-btn">
                  <Box className="row-btn customButton">
                    <Button
                      variant="contained"
                      onClick={() => {
                        //@ts-ignore
                        this.props.history.push("/SuggestionListing");
                      }}
                    >
                      {t("Okay")}
                    </Button>
                  </Box>
                </Box>
                {/* desktop footer block */}
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}
// @ts-ignore
export default withTranslation()(withRouter(NewRequestSuggestion));
// Customizable Area End