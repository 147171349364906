// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "./ApiCallCommon.web";
import {ROLE} from "../../framework/src/Enum";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history:any;
  location:any;
}

interface S {
  error:any;
  showError:boolean;
  showSuccess:boolean;
  successMessage:any;
}

interface SS {
  id: any;
}

export default class SessionManagerController extends CommonApiCallForBlockComponent<Props, S, SS> {
  myProfileAPICallID:string
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.RestAPIRequestMessage),
        getName(MessageEnum.PostDetailDataMessage)
    ];

    this.state = {
        error:"",
        showError:false,
        showSuccess:false,
        successMessage:""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(){
    const token = localStorage.getItem("userToken")
    if(token){
      this.checkSession()
    }
    // CHECK SESSION API CALL HAPPENING IN EVERY 10 MINUTES
    setInterval(() => {
        if(token){
            this.checkSession();
        }
    }, 600000);
  }

  // Check Session API Call.
  checkSession = async () => {
    const token = localStorage.getItem("userToken")
    if(token){
      this.myProfileAPICallID = await this.apiCall({
        contentType: "application/json",
        method:"GET",
        endPoint: `/bx_block_profile/my_profile`,
      });
    }
  }

  showError = (error:string) => {
    this.setState({
      showError:true,
      error:error,
    })
  }

  showSuccess = (success:string) => {
    this.setState({
      showSuccess:true,
      successMessage:success,
    })
  }

  manageMessage = (message:any) => {
    if(message.id === "UNIVERSAL_ALERT_MESSAGE_FOR_SUCCESS"){
      this.showSuccess(message.message)
    }else if(message.id === "UNIVERSAL_ALERT_MESSAGE_FOR_ERROR"){
      this.showError(message.message)
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage)=== message.id){
      if(message.properties.hasOwnProperty("message")){
        this.manageMessage(message.properties.message)
      }
    }
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    // Check is there any Error in the API
    if(errorResponse){
      this.setState({
        error:"Sorry, something went wrong! Please contact the system admin if this happens again.",
        showError:true
      })
    }
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id && !errorResponse) {
      if(apiRequestCallId === this.myProfileAPICallID){
        if(!responseJson?.hasOwnProperty("data")){
        console.log("LOGOUT DONE VIA SESSION MANAGER")
        this.setState({
            error:"Session Expired! Please login again.",
            showError:true
        })
        const currentUser = localStorage.getItem("userType")
        if(currentUser === ROLE.CHAIRMAN || currentUser === ROLE.MANAGER || currentUser === ROLE.AUDITOR || currentUser === ROLE.SECURITY || currentUser === ROLE.SERVICE_PROVIDER){
          this.props.history.push("/ManagementLogin")
        }else{
          this.props.history.push("/")
        }
        localStorage.clear();
      }
    }
  }}
}
// Customizable Area End
