// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon, filterIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import ViewMyReceiptsController, { Props } from "./ViewMyReceiptsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends ViewMyReceiptsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols Viewmy receipts view-my-receipt-page">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "back-view-button")} onClick={() => this.props.history.push("/FeesAndPayment")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text receipt-navigation-bar">
                  {t("View my Receipts")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <Box className="receipt-sort-menu">
                  <Menu menuButton={<img src={shortIcon} alt="" />} className="chairman-lang-menu">
                    <MenuItem className="receipt-asc-menu" onClick={() => this.setState({ toggleAscDesc: "asc" }, () => this.getMyRecepits())}>
                      {t("Asc")}
                    </MenuItem>
                    <MenuItem className="receipt-desc-menu" onClick={() => this.setState({ toggleAscDesc: "desc" }, () => this.getMyRecepits())}>
                      {t("Desc")}
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className={languageCondition(language, "", "management-fee-menu")}>
                  <Menu menuButton={<img src={filterIcon} alt="" />}>
                    <MenuItem className="all-filter-menu" onClick={() => this.setState({ anchorEl: "" }, () => this.getMyRecepits())}>{t("All")}</MenuItem>
                    <MenuItem className="rent-filter-menu" onClick={() => this.setState({ anchorEl: "Rent" }, () => this.getMyRecepits())}>
                      {t("Rent")}
                    </MenuItem>
                    <MenuItem className="fee-filter-menu" onClick={() => this.setState({ anchorEl: "Management Fee" }, () => this.getMyRecepits())}>
                      {t("Management Fee")}
                    </MenuItem>
                    <MenuItem className="service-filter-menu" onClick={() => this.setState({ anchorEl: "Facility Rental" }, () => this.getMyRecepits())}>
                      {t("Facility Charge")}
                    </MenuItem>
                    {/* <MenuItem className="other-filter-menu" onClick={() => this.setState({ anchorEl: "Others" }, () => this.getMyRecepits())}>
                      {t("Others")}
                    </MenuItem> */}
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              {this.state.myReceiptsList.length === 0 && (
                <Box
                  style={{
                    borderRadius: "8px",
                    background: "white",
                    padding: "15px",
                    boxShadow: "4px 0px 14px #e9e9e9",
                    width: "100%",
                    margin:"10px 0"
                  }}
                >
                  {t("No data available")}
                </Box>
              )}
              {this.state.myReceiptsList.map((item: any, index: number) => {
                return (
                  <Grid item xs={12} className="ViewMyreceipts view-my-receipt-list">
                    <Box
                      className="view-my-receipt-box view-receipt-card-box"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/Receipt/${item.id}/${item.attributes.invoice_type}`,
                          state: { selectedInvoice: item },
                        });
                      }}
                      borderRadius="8px"
                      bgcolor="white"
                      padding="15px"
                      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                    >
                      <Box style={{ minWidth: "100%" }} className="view-my-receipt-content-box">
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant={"subtitle2"}>
                            {t("Receipt")} : {item.attributes.month_year}
                          </Typography>
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item xs={6} style={{ marginTop: "15px" }}>
                            <Typography variant={"subtitle2"}>{t("Tenant")}</Typography>
                            <Typography variant={"body1"} className="bold-text receipt-col-value" style={{ marginTop: "5px" }}>
                              {item.attributes.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "15px" }}>
                            <Typography variant={"subtitle2"}>{t("Building Name")}</Typography>
                            <Typography variant={"body1"} className="bold-text receipt-col-value" style={{ marginTop: "5px" }}>
                              {item.attributes.building_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Unit Number")}</Typography>
                            <Typography variant={"body1"} className="bold-text receipt-col-value" style={{ marginTop: "5px" }}>
                              {item.attributes.unit_number}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Paid Amount")}</Typography>
                            <Typography variant={"body1"} className="bold-text receipt-col-value" style={{ marginTop: "5px" }}>
                              {LanguageAttributeHandler(language, item.attributes?.currency?.currency, item.attributes?.currency?.currency_ar)}{" "}
                              {Number(Number(item?.attributes?.paid_amount).toFixed(2)).toLocaleString()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Receipt Type")}</Typography>
                            <Typography variant={"body1"} className="bold-text receipt-col-value" style={{ marginTop: "5px" }}>
                              {t(item.attributes.invoice_type?.split("_").join(" "))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));
// Customizable Area End
