import React from "react";

// Customizable Area Start
import { withTranslation } from "react-i18next";
import UnitExpenseListController, { IExpense, IExpenseCategory, Props } from "./UnitExpenseListController.web";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  withStyles,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FilterIcon, SortIcon } from "./assets";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import { ExpenseTrackingStyle } from "./ExpenseTrackingStyle.web";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class UnitExpenseList extends UnitExpenseListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "#F7F9FE" }} className={classes.myExpenseList}>
          <Grid container className="unit-expense-container">
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <Box className="left-icon">
                    <IconButton className="unit-expense-back-btn" onClick={() => this.handleNavigationToExpense()}>
                      <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">
                      {t("Building")} {this.state.buildingName} {t("Unit")} {this.state.unitName}
                    </span>
                  </Box>
                  <Box className={languageCondition(language, "right-icon", "right-icon expense-filter-menu")}>
                    <Menu
                      menuButton={
                        <IconButton>
                          <img src={SortIcon} alt="" />
                        </IconButton>
                      }
                    >
                      <MenuItemMenu onClick={() => this.handleSortFilter("asc")}>{t("Acs")}</MenuItemMenu>
                      <MenuItemMenu onClick={() => this.handleSortFilter("desc")}>{t("Desc")}</MenuItemMenu>
                    </Menu>
                    <IconButton onClick={() => this.handleFilterModal()}>
                      <img src={FilterIcon} alt="filter" />
                    </IconButton>
                  </Box>
                </Box>
                <Container>
                  <Box className="tenant-list-box">
                    <Box className="tenant-list">
                      <Grid container spacing={2}>
                        {this.state.expenseList.length === 0 && (
                          <Grid item xs={12}>
                            <Card className="tenant">{t("No Expense Available")}</Card>
                          </Grid>
                        )}
                        {this.state.expenseList.map((expense: IExpense) => {
                          return (
                            <Grid item xs={12} key={expense.id}>
                              <Card className="tenant">
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Box className="header">
                                      <h4 className="bold-text">
                                        {moment(expense.attributes.expense_date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                                      </h4>
                                      <Box className={languageCondition(language, "right-menu", "right-menu expense-menu")}>
                                        <Menu
                                          menuButton={
                                            <IconButton>
                                              <MoreVertIcon />
                                            </IconButton>
                                          }
                                        >
                                          <MenuItemMenu onClick={() => this.handleNavigationToDetails(expense.id)}>
                                            {t("View")}
                                          </MenuItemMenu>
                                          <MenuItemMenu onClick={() => this.handleNavigationToEditExpense(expense.id)}>
                                            {t("Edit")}
                                          </MenuItemMenu>
                                          <MenuItemMenu onClick={() => this.handleDeleteExpense(expense.id)}>
                                            {t("Delete")}
                                          </MenuItemMenu>
                                        </Menu>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} className="info">
                                  <Grid item xs={6}>
                                    <span>{t("Cost")}:</span>
                                    <p>{LanguageAttributeHandler(language, expense.attributes.address.currency?.currency, expense.attributes.address.currency?.currency_ar) + " " + Number(expense.attributes.expense_amount).toLocaleString()}</p>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <span>{t("Issue")}:</span>
                                    <p>{expense.attributes.issue_title}</p>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <span>{t("Category")}:</span>
                                    <p>{LanguageAttributeHandler(language, expense.attributes.expense_category.title, expense.attributes.expense_category.title_ar)}</p>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                    <Box className="upload-button">
                      <Grid container className="unit-expense-btn-container">
                        <Grid item xs={12} md={12}>
                          <Button onClick={() => this.handleNavigationToAddExpense()}>{t("Add Another Expense")}</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Drawer
          onClose={() => this.handleFilterModal()}
          open={this.state.isFilterOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          className="condition-modal"
          anchor="bottom"
        >
          <Box className="condition-box filter-box expense-filter-content-box">
            <Box className="heading expense-filter-heading">
              <p className="bold-text">{t("Filter")}</p>
              <span className="clear-all-text bold-text" onClick={() => this.handleClearFilter()}>
                {t("Clear All")}
              </span>
            </Box>
            <Box className="content-box expense-filter-contents">
              {this.state.expenseCategoryList.map((category: IExpenseCategory) => {
                return (
                  <Box className="condition expense-filter-condition-box" key={category.id}>
                    <p>{LanguageAttributeHandler(language, category.title, category.title_ar)}</p>
                    <Checkbox
                      className="condition-check expense-filter-check-box"
                      checked={this.state.categoryList.includes(category.id)}
                      icon={<CircleUnchecked style={{ color: "#f0f0f0" }} />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleChangeCheckboxEvent(e, category.id);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            <br />
            <Divider />
            <Box className="button-group expense-filter-btn-box">
              <Button className="add-button" onClick={() => this.handleApplyFilter()}>
                {t("Apply")}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withStyles(ExpenseTrackingStyle)(UnitExpenseList));
