// Customizable Area Start
import React from "react";
import GeneralHeaderController from "./GeneralHeaderController.web";
import {withRouter} from "react-router-dom";
import { withTranslation } from "react-i18next";
import {Grid,Box} from "@material-ui/core";
import DashboardHeader from "../../../blocks/dashboard/src/DashboardHeader.web";
import GeneralSideBar from "../../../blocks/dashboard/src/GeneralSideBar.web";

class GeneralHeader extends GeneralHeaderController {
    render() {
        const { i18n }: any = this.props;
        // @ts-ignore
        const language = i18n?.language;
        return(
            <Box style={{ background: "#F4F7FF" }} dir={language === "ar" ? "rtl" : "ltr"}>
                {/*@ts-ignore*/}
                <DashboardHeader />
                <Box style={{ display: "flex" }}>
                    <Grid item xs={3} md={3} sm={3} className="SideBar">
                        <GeneralSideBar />
                    </Grid>
                    <Grid item xs={9} md={9} sm={9} style={{ paddingTop: 35,minHeight:"92vh" }}>
                        {this.props.children}
                    </Grid>
                </Box>
            </Box>
        )
    }
}

// @ts-ignore
export default withTranslation()(withRouter(GeneralHeader));

// Customizable Area End