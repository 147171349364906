// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from "@material-ui/core";
import PaymentHistoryController, {Props} from "./PaymentHistoryController.web";
import "@szhsin/react-menu/dist/core.css";
import {withTranslation} from "react-i18next";
import {ReportsStyleWeb} from "../../StoreCredits/src/ReportsStyle.web";
import {SearchIconImage} from "../../StoreCredits/src/assets";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web"
import {withRouter} from "react-router-dom";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class PaymentHistory extends PaymentHistoryController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {t, classes,i18n}: any = this.props;
        const {language} = i18n
        return (
            <>
                <GeneralHeader>
                            <Container className={classes.reportList}>
                                <Box className="navigation">
                                    <Box>
                                        <Typography variant="body1">
                                            {t("Invoices & Receipts")}
                                            {" "}/{" "}
                                            <Box component="span"
                                                 style={{color: "blue"}}>
                                                {t("Payment History")}</Box></Typography>
                                        <Typography
                                            variant="h5"
                                            className="sub-heading bold-text paymentHistoryHeading"
                                        >{t("Payment History")}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                    className={languageCondition(language, "arabic-grid top-bar", "top-bar paymentHistory")}
                                >
                                    <Box className="filter">
                                        <Select displayEmpty value={this.state.filterPaymentBuilding || ""}
                                                className="select-input selectPaymentBuilding test "
                                                id="selectBuildingForPaymentHistory" onChange={this.selectPaymentBuilding}>
                                            <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Building")}</MenuItem>
                                            {
                                                this.state.buildingPaymentList?.length > 0 &&
                                                <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                                            }
                                            {
                                                this.state.buildingPaymentList?.map((item: any, key: any) => {
                                                    return (
                                                        <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>{item.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <Select displayEmpty value={this.state.filterPaymentFloor || ""} className="select-input SelecFloor"
                                                id="selectFloorForPaymentHistory" onChange={this.selectFloorPayment}>
                                            <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Floor")}</MenuItem>
                                            {
                                                this.state.floorPaymentList?.length > 0 &&
                                                <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "selectAllReceiptUnits")}>{t("All")}</MenuItem>
                                            }
                                            {
                                                this.state.floorPaymentList?.map((item: any, key: any) => {
                                                    return (
                                                        <MenuItem key={key} value={item} className={languageCondition(language, "select-arabic-menu", "")}>{item}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <Select displayEmpty value={this.state.filterPaymentUnit || ""} className="select-input SelectUnit"
                                                id="selectUnitForPaymentHistory" onChange={(e: any) => this.setState({filterPaymentUnit: e.target.value,filterPaymentStatus:""})}>
                                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}> {t("Select Unit")}  </MenuItem>
                                            {
                                                this.state.unitPaymentList?.map((item: any, key: any) => {
                                                    return (
                                                        <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>  {item.apartment_name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <Select displayEmpty value={this.state.filterPaymentStatus || ""} className="select-input SelectStatus"
                                                id="selectTypeForPaymentHistory" onChange={(e: any) => this.setState({filterPaymentStatus: e.target.value})}>
                                            <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}> {t("Select Status")} </MenuItem>
                                            <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}> {t("All")} </MenuItem>
                                            <MenuItem value="due" className={languageCondition(language, "select-arabic-menu", "")}>{t("Due")} </MenuItem>
                                            <MenuItem value="over_due" className={languageCondition(language, "select-arabic-menu", "")}>{t("Over Due")} </MenuItem>
                                            <MenuItem value="paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Paid")} </MenuItem>
                                            <MenuItem value="partially_paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Partially Paid")} </MenuItem>
                                        </Select>
                                        <Button id="GeneratePaymentHistory"
                                                startIcon={<img src={SearchIconImage}/>}
                                                onClick={this.handlePaymentFilterBy}>
                                                {t("GENERATE PAYMENT HISTORY")}</Button>
                                    </Box>
                                </Box>
                                <Grid className="meeting-table">
                                    <Grid item sm={12} md={12}  xs={12}>
                                        <Box className="table-top"  bold-text>
                                            <h4 className="bold-text paymentHisotry"> {t("Payment History")}</h4>
                                            <Button style={{
                                                backgroundColor: "#FC8434", color: "white",
                                                height: 40, borderRadius: "8px", fontSize: 16,
                                                fontWeight: "bold",
                                            }} className="table-top bold-text paymentDownload "
                                                    id="paymentHistoryDownloadButton"
                                                    onClick={this.paymentHistoryDownload}>{t("DOWNLOAD REPORT")}
                                            </Button>
                                        </Box>
                                        <Divider/>
                                        <Table className="table-box paymenttable">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align={languageCondition(language,"right","left")}> #</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Name")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Unit No.")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Title")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Paid Amount")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Type")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}> {t("Status")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.paymentHistoryList?.length > 0 ?
                                                        this.state.paymentHistoryList?.map((row: any, key: any) => {
                                                            return (
                                                                <TableRow key={key}>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{row.attributes.name}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{row.attributes.unit_number}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{row.attributes.title}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{row.attributes.paid_amount}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.payment_type.split("_").join(" "))}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.status.split("_").join(" "))}</TableCell>
                                                                </TableRow>
                                                            )


                                                        })
                                                        :
                                                        <TableRow>
                                                            <TableCell
                                                                align={languageCondition(language,"right","left")}
                                                                colSpan={6}>{t("No records found. Please select Building and Unit to view payment history")}</TableCell>
                                                        </TableRow>
                                                }

                                            </TableBody>
                                        </Table>
                                        <Divider/>
                                        <Box style={{
                                            width: "95%",
                                            height: "70px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }} className="table-bottom">
                                            <PaginationModule handlePagination={this.handleInvoicesPagination}
                                                              pagination={this.state.pagination}
                                                              page={this.state.page || 1}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                </GeneralHeader>
            </>
        );
    }
}

// @ts-ignore
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(PaymentHistory)));
// Customizable Area End
