// Customizable Area Start
import React from "react";
import { Container, withStyles, Card, IconButton } from "@material-ui/core";
import "./DialogStyle.web.css"
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FaqComplexListController, { Props } from "./FaqComplexListController.web";
import { withTranslation } from "react-i18next";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { FaqChairmanStyleWeb } from "./FaqChairmanStyle.web";
import { withRouter } from "react-router-dom";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class FaqComplexList extends FaqComplexListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box style={{ background: "#F7F9FE", height: "100vh" }} className={classes.faqOwner} dir={languageCondition(language,"rtl","ltr")}>
          <Grid container className="faq-complex-container">
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="backIcon">
                  <IconButton className="faq-complex-back-btn" onClick={() => this.handleBack()}>
                    <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                  </IconButton>
                  <span className="bold-text heading">{t("FAQ Complex List")}</span>
                </Box>
                <Container className="page-container faq-page-container">
                  <Box className="details-box">
                    <Box
                      className="faq-complex-boxes faq-complex-box"
                      style={{ marginTop: "8px", marginBottom: "30px" }}
                    >
                      <Grid container spacing={2} className="faq-container">
                        {this.state.faqComplexList.map((complex: any) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              className="faq-grid"
                              key={complex.id}
                              onClick={() => this.handleGotoFaq(complex.id)}
                            >
                              <Card className="document faq-box">
                                <h6 className="bold-text">{complex.name}</h6>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(FaqChairmanStyleWeb)(withRouter(FaqComplexList)));
// Customizable Area End
