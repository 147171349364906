// Customizable Area Start
import React from "react";
import { Box, InputAdornment, InputLabel, TextareaAutosize } from "@material-ui/core";

const NormalTextArea = ({ t, language, label, icon, isLabel, minRows = 4, maxRows = 4, ...props }: any) => {
  return (
    <Box className="input_icon__form_box">
      {isLabel && <InputLabel>{t(label)}</InputLabel>}
      <TextareaAutosize
        className="input_box textarea-box"
        fullWidth
        placeholder={t(label)}
        minRows={minRows}
        maxRows={maxRows}
        {...props}
        startAdornment={
          icon && (
            <InputAdornment position="start">
              <img src={icon} alt="icon" />
            </InputAdornment>
          )
        }
      />
    </Box>
  );
};

export default NormalTextArea;
// Customizable Area End
