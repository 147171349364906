// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import {getUserType} from "../../../components/src/ConditionManager.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?:any
  t?:any
  i18n?:any
  match?:any
  location?:any
}

interface S {
  loading: boolean;
  isLogoutModalOpen: boolean;
  isNewNotification: boolean;
  complexList: any[];
  complexName:any;
  showComplexList:boolean;
}

interface SS {
  id: any;
}

export default class LocationController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getComplexListId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isLogoutModalOpen: false,
      isNewNotification: false,
      complexList: [],
      complexName:localStorage.getItem("complexName"),
      showComplexList:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getComplexList()
  }

  manageComplexChange = (complex:any) => {
    localStorage.setItem("society_id",complex.id)
    localStorage.setItem("complexName",complex.name)
    window.location.reload()
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getComplexListId) {
        // Take Some action on call id
        if(responseJson.hasOwnProperty("complex")){
          this.setState({complexList:responseJson.complex})
          if(responseJson.complex.length == 1){
            this.setState({complexName:responseJson.complex[0].name,showComplexList:false})
          }else{
            this.setState({showComplexList:true})
          }

        }
      }
    }
  }

  getComplexList = async () => {
    let userType = getUserType()
    this.getComplexListId = await this.apiCall({
      method: "get",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,
    });
  }
}
// Customizable Area End
