// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history:any
  t: any;
  i18n: any;
}
interface S {
  expanded: any;
  anchorEl: any;
  isMenuOpen: boolean;
}
interface SS {
  id: any;
}

export default class AuditorController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      expanded: "",
      anchorEl: null,
      isMenuOpen: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  slider: any;

  handleAccordinoChange = (panel: string) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : "" });
  };
}


// Customizable Area End