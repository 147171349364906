// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityReservationController, { Props } from "./FacilityReservationController.web";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  House_Icon,
  Building1,
  LEADING_ICON,
  TimeIcon,
  GreyCalenderIcon,
  GreyBuildingName
} from "../src/assets";
import moment from "moment";
import { withTranslation } from "react-i18next";
import './facility-manager.css';
// @ts-ignore
import {facilityData} from "../__mock__/mockData";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class CreateFacilityReservation extends FacilityReservationController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    //@ts-ignore
    const id = this.props.history?.location?.id;
    if (id) {
      this.getFacilityReservationDetailsById(id);
    }
    this.getComplexListFacility()
  }

  handleEmptyText = (value: any) => {
    if (value) {
      return value;
    } else {
      return "";
    }
  };

  handleClass = (value: any, mobile: any,language:any) => {
    if (value && mobile) {
      return languageCondition(language,"facility-date placeHolderForDate placeHolderAlignmentSpace","facility-date placeHolderForDate");
    } else {
      return languageCondition(language,"facility-date placeHolderAlignmentSpace","facility-date");
    }
  };

  render() {
    const { t }: any = this.props;
    // @ts-ignore
    const {language}:any = this.props.i18n
    const id = this.state?.getFacilityReservationDetails?.id;
    const attributes = this.state?.getFacilityReservationDetails?.attributes;
    //@ts-ignore
    const FacilityId = this.props.history?.location?.id;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;

    return (
      <>
        <Box className="login-wrapper incident-wrapper facility-manager">
          <Grid container spacing={2} className={languageCondition(language, "arabic-grid auth-container", "auth-container addRentPayments")}>
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="common_content_block content-block createUpdateFacality" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks">
                    <ArrowBackIcon onClick={() => window.history.back()}/>
                    {FacilityId ? <h4 className="bold-text">{t("Update Facility Reservation")}</h4> : <h4 className="bold-text">{t("Facility Reservation")}</h4>}
                  </Box>
                </Box>
                <Box className="content-block-wrapper common-incident-block desktop-ui create-reservation-wrapper">
                  <Formik
                    initialValues={{
                      ComplexId: this.handleEmptyText(attributes?.building?.society_management_id),
                      unitId: this.handleEmptyText(attributes?.unit_number?.id),
                      areaReserve: this.handleEmptyText(attributes?.common_area?.id),
                      buildingName: this.handleEmptyText(attributes?.building?.id),
                      date: attributes?.date ? moment(attributes?.date, 'DD-MMM-YYYY').format('YYYY-MM-DD'):"",
                      timeFrom: this.handleEmptyText(attributes?.start_time),
                      timeTo: this.handleEmptyText(attributes?.end_time),
                      id: this.handleEmptyText(id + "")
                    }}
                    enableReinitialize
                    validationSchema={this.CreateFacilityReservationSchema()}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      if (id) {
                        this.updateFacilityReservation(values);
                      } else {
                        this.CreateFacilityReservation(values);
                      }
                    }}
                  >
                    {({ values, touched, errors, isValid, setFieldError, setFieldValue, handleChange, handleBlur }) => {
                      return <Form translate="yes" className="commonForm CreateClassifiedFrm">
                        <h5 className="frm-title incident-preview-title"></h5>
                        <Box className="formGroup customSelect SelectComplex">
                          <FormControl variant="outlined" className="SelectComplex" >
                            <Select
                                displayEmpty name="Complex Name"
                                labelId="ComplexName"
                                className="formInput_select_s faclityReservatoiiSelectComplex"
                                startAdornment={
                                  <img src={GreyBuildingName} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                                }
                                style={{marginTop: -3 }}
                                onChange={(e: any) => {
                                  setFieldValue("ComplexId", e.target.value);
                                  this.getCommonArea(e.target.value);
                                  this.getMyApartmentList(e.target.value);
                                }}
                                value={values.ComplexId}
                            >
                              <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu", "SelectComplexFacility")}>
                                {t("Select Building / Complex")}
                              </MenuItem>
                              {
                                this.state?.facilityComplexList?.map((val: any, index: any) => (
                                    <MenuItem className={languageCondition(language, "select-arabic-menu", "SelectComplexFacility")} key={index} value={val?.id}>{val?.name}</MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error selectComplexError" component="Typography" name="ComplexId" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect SelectBuilding">
                          <FormControl variant="outlined" className="SelectBuilding" >
                            <Select
                              displayEmpty
                              name="buildingName"
                              className="formInput_select_s facilityReservationSelectBuilding"
                              labelId="BuildingName"
                              startAdornment={
                                <img src={LEADING_ICON} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                              }
                              style={{marginTop: -3 }}
                              onChange={(e: any) => {
                                setFieldValue("buildingName", e.target.value);
                                this.getUnitListFacility(e.target.value);
                              }}
                              value={values.buildingName}
                            >
                              <MenuItem disabled value=""  className={languageCondition(language, "select-arabic-menu", "SelectBuildingMenu")}>
                                {t("Select Building")}
                              </MenuItem>
                              {
                                this.state?.myApartmentList?.map((val: any, index: any) => (
                                  <MenuItem key={index} value={val?.id} className={languageCondition(language, "select-arabic-menu", "SelectBuildingMenu")}>{val?.name}</MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="buildingName" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect selectCity">
                          <FormControl variant="outlined" >
                            <Select
                                displayEmpty
                                name="UnitSelect"
                                className="formInput_select_s FacilityReservationUnitSelect"
                                labelId="Unit Select"
                                startAdornment={
                                  <img src={House_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                                }
                                id="unitSelect"
                                style={{ marginTop: -3 }}
                                onChange={(e: any) => {
                                  setFieldValue("unitId", e.target.value);
                                }}
                                value={values.unitId}
                            >
                              <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu", "SelectBuildingMenu")}>
                                {t("Select Unit")}
                              </MenuItem>
                              {
                                this.state?.unitList?.map((val: any, index: any) => (
                                    <MenuItem key={index} value={val?.id} className={languageCondition(language, "select-arabic-menu", "SelectBuildingMenu")}>{val?.apartment_name}</MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="unitId" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect">
                          <FormControl variant="outlined" >
                            <Select
                              displayEmpty
                              name="areaReserve"
                              startAdornment={
                                <img src={House_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                              }
                              className="formInput_select_s facilityReservationSelectArea"
                              labelId="demo-simple-select-outlined-label"
                              onChange={(e) => {
                                this.onChange(e.target.value);
                                setFieldValue("areaReserve", e.target.value);
                              }}
                              value={values.areaReserve}
                            >
                              <MenuItem disabled value=""  className={languageCondition(language, "select-arabic-menu", "areaReserve")}>
                                {t("Area to Reserve")}
                              </MenuItem>
                              {
                                this.state?.commonAreaData?.map((val: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    value={val?.id}
                                    className={languageCondition(language, "select-arabic-menu", "areaReserve")}
                                  >
                                    {val?.attributes?.name}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="areaReserve" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect">
                          <FormControl fullWidth>
                            <Input
                              value={values.date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="date"
                              type="date"
                              className={this.handleClass(!values.date, is_mobile,language)}
                              placeholder={t("Date")}
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={GreyCalenderIcon} alt="" />
                                </InputAdornment>
                              }
                            />
                            <ErrorMessage className="text-error" component="Typography" name="date" />
                          </FormControl>
                        </Box>
                        <Grid container>
                          <Grid xs={6}>
                            <Box className="formGroup classifiedFormGroup" >
                              <FormControl fullWidth>
                                <Input
                                  value={values.timeFrom}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="timeFrom"
                                  type="time"
                                  className={this.handleClass(!values.timeFrom, is_mobile,language)}
                                  placeholder={t("From")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={TimeIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                <ErrorMessage className="text-error" component="Typography" name="timeFrom" />
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid xs={6}>
                            <Box className="formGroup">
                              <FormControl fullWidth>
                                <Input
                                  value={values.timeTo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="timeTo"
                                  type="time"
                                  className={this.handleClass(!values.timeTo, is_mobile,language)}
                                  placeholder={t("To")}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <img src={TimeIcon} alt="" />
                                    </InputAdornment>
                                  }
                                />
                                <ErrorMessage className="text-error" component="Typography" name="timeTo" />
                              </FormControl>
                            </Box>
                          </Grid>
                          {values.areaReserve &&
                            <Box className="reservationDec" style={{ width: "100%" }}>
                              <h5 className="bold-text" style={{ marginBottom: "5px" }}>{t("Description")}:</h5>
                              <p style={{ marginBottom: "5px" }}>{this.state.areaReserveDetail}</p>
                              <p>{t("You will be charged")} <span>{this.state?.currency} {this.state?.reservationFees} {t("per hour")}</span> {t("for")} {this.state.areaReserveName} {t("Facility")}.</p>
                            </Box>
                          }
                        </Grid>
                        <Box className="customButton facility-create-button">
                          {FacilityId ? <Button variant="contained" type="submit">{t("Update Request")}</Button> : <Button variant="contained" type="submit" className="createFacility">{t("Submit")}</Button>}
                        </Box>
                      </Form>;
                    }}
                  </Formik>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Button style={{display:"hidden"}} className="submitCreateFacility" onClick={()=> this.CreateFacilityReservation(facilityData) }></Button>
        <Button style={{display:"hidden"}} className="updateCreateFacility" onClick={()=> this.updateFacilityReservation(facilityData) }></Button>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(CreateFacilityReservation));
// Customizable Area End