// Customizable Area Start
import React from "react";
import { Container, IconButton, Link, withStyles, Box, Grid, Card } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IssueContractController, { Props } from "./IssueContractController.web";
import { ContractsStyleWeb } from "./ContractsStyle.web";
import { TemplateIcon } from "./assets";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class IssueContract extends IssueContractController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getTemplateListFromAdmin();
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.selectTemplate}>
          <Grid container className="issue-lease-grid-container">
            <Grid item xs={12} md={7}>
              <Box className="faq-step issue-lease-content-box">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar issue-lease-top-bar">
                  <div className="left-icon issue-lease-header-top">
                    <Link href="/Contracts">
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")}/>
                      </IconButton>
                    </Link>
                    <span className="bold-text issue-lease-heading">{t("Issue a Lease")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <Box className="issue-lease-content" marginBottom="80px">
                    <Box className="templates-list issue-lease-template-list">
                      <h4 className="bold-text">{t("Select Lease Template")}</h4>
                      <Grid container spacing={2}>
                        {this.state.templatesList.length === 0 && (
                          <Grid item xs={12}>
                            <Card className="template issue-lease-no-temp-msg">{t("No Template Available!")}</Card>
                          </Grid>
                        )}
                        {this.state.templatesList.map((template: any, index: number) => {
                          return (
                            <Grid item xs={6} key={template.id}>
                              <Card className="template issue-contract-admin-temp" onClick={() => this.handleGotoTemplateLease(template.id)}>
                                <div className="content">
                                  <div className="image">
                                    <img src={TemplateIcon} alt="" />
                                  </div>
                                  <h4 className="bold-text">{template.attributes.title}</h4>
                                </div>
                                {index === 0 && (
                                  <div className="right-menu">
                                    <span>{t("Default")}</span>
                                  </div>
                                )}
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(ContractsStyleWeb)(IssueContract));
// Customizable Area End
