import * as React from "react";
import NominationDetailsController from "./NominationDetailsController";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {TenantLogo} from "../../dashboard/src/assets";

class ChairmanNominationPrint extends NominationDetailsController {
    constructor(props: any) {
        super(props);
    }

    render() {

        // @ts-ignore
        const {t} = this.props

        // @ts-ignore
        const {nominationData} = this.props
        return (
            <Container style={{marginTop: "10px"}}>
                <Grid container>
                    <Grid item xs={12} md={12} style={{display: 'flex', justifyContent: "space-between"}}>
                        <img src={TenantLogo.default} alt="logo" style={{height: "50px"}}/>
                        <Typography>{t("Report Generated on")} : {moment().format("DD/MM/YYY hh:mm a")}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: "center", marginTop: "20px"}}>
                        <Typography variant="h4" style={{fontWeight: "bold", textAlign: "center"}}>
                            {t("Chairman and Vice Chairman Nomination Report")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',justifyContent: "center",
                        marginTop: "20px",background: "white",
                        borderRadius: "20px",border: "1px solid #f9f9f9"
                    }}>
                        <Grid container style={{padding: "20px"}}>
                            <Grid xs={12} style={{display: 'flex', justifyContent: "space-between"}}>
                                <Typography>{t("Nomination Name")}: <span
                                    className="bold-text">{nominationData?.title}</span></Typography>
                                <Typography className="bold-text">{nominationData?.status}</Typography>
                            </Grid>
                            <Grid xs={12} style={{marginTop: "20px"}}>
                                <Grid container>
                                    <Grid xs={3}>
                                        <Typography>{t("Complex Name")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsComplexName">{nominationData?.complex_name}</Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                        <Typography>{t("City Name")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsCity">{nominationData?.city}</Typography>
                                    </Grid>
                                    <Grid xs={3}>
                                        <Typography>{t("Total Nominations")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsTotalNomination">{nominationData?.total_nomination}</Typography>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography>{t("Number of Participants")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsParticipets">{nominationData?.participent_number}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} style={{marginTop: "15px"}}>
                                <Grid container>
                                    <Grid xs={3}>
                                        <Typography>{t("Start Date")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsStartDate">{moment(nominationData?.start_date).format("DD/MM/YYYY")}</Typography>
                                    </Grid>
                                    <Grid xs={2}>
                                        <Typography>{t("End Date")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsEndDate">{moment(nominationData?.end_date).format("DD/MM/YYYY")}</Typography>
                                    </Grid>
                                    <Grid xs={3}>
                                        <Typography>{t("Total Votes")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsTotalVotes">{nominationData?.totle_vote}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} style={{marginTop: "15px"}}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <Typography>{t("Description")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsDescription">{nominationData?.description}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',justifyContent: "center",marginTop: "20px",
                        background: "white",borderRadius: "20px",border: "1px solid #f9f9f9"
                    }}>
                        <Grid container style={{padding: "20px"}}>
                            <Grid xs={12}>
                                <Grid container>
                                    <Grid xs={4}>
                                        <Typography>{t("Selected Chairman")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsSelectedChairman ">{nominationData?.selected_chairman?.name || "-"}</Typography>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography>{t("Unit Number")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsCity">{nominationData?.selected_chairman?.unit_number || "-"}</Typography>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography>{t("Received Votes")}:</Typography>
                                        <Typography
                                            className="bold-text nominationCityCount">{nominationData?.selected_chairman?.recived_vote || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex',justifyContent: "center",marginTop: "20px",background: "white",
                        border: "1px solid #f9f9f9",borderRadius: "20px",
                    }}>
                        <Grid container style={{padding: "20px"}}>
                            <Grid xs={12}>
                                <Grid container>
                                    <Grid xs={4}>
                                        <Typography>{t("Selected Vice Chairman")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsViceChairmanName">{nominationData?.selected_vice_chairman?.name || "-"}</Typography>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography>{t("Unit Number")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsViceChairmanCity">{nominationData?.selected_vice_chairman?.unit_number || "-"}</Typography>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Typography>{t("Received Votes")}:</Typography>
                                        <Typography
                                            className="bold-text nominationDetailsViceChairmanNominationCount">{nominationData?.selected_vice_chairman?.recived_vote || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{
                        display: 'flex', border: "1px solid #f9f9f9",
                        marginTop: "20px",justifyContent: "center",
                        borderRadius: "20px",background: "white",
                    }}>
                        <Grid container>
                            <Grid xs={12}>
                                <Grid container>
                                    <Grid xs={6}>
                                        <table style={{
                                            border: "1px solid black",
                                            borderCollapse: "collapse",
                                            width: "99%"
                                        }}>
                                            <tr>
                                                <th colSpan={2}
                                                    style={{border: "1px solid black", borderCollapse: "collapse"}}>
                                                    <Typography id="ChairmanNominationResult"
                                                                className={"bold-text"}>{t("Chairman Nomination Results")}</Typography>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    textAlign: "center",border: "1px solid black",borderCollapse: "collapse",
                                                }}>
                                                    <Typography id="ChairmanNominationResultName"
                                                                className={"bold-text"}>{t("Name")}</Typography>
                                                </td>
                                                <td style={{
                                                    border: "1px solid black",
                                                    textAlign: "center",borderCollapse: "collapse",

                                                }}>
                                                    <Typography id="ChairmanNominationResultVotes"
                                                                className={"bold-text"}>{t("Number of Votes")}</Typography>
                                                </td>
                                            </tr>
                                            {
                                                nominationData?.chairman_nomination_results?.length > 0 ?
                                                    nominationData?.chairman_nomination_results?.map((item: any, index: any) => {
                                                        return (
                                                            <tr>
                                                                <td style={{
                                                                    border: "1px solid black",
                                                                    borderCollapse: "collapse"
                                                                }} key={index}>
                                                                    <Typography>{" "} {index + 1}.{" "}{item.name}</Typography>
                                                                </td>
                                                                <td style={{
                                                                    border: "1px solid black",
                                                                    borderCollapse: "collapse",textAlign: "center"
                                                                }}>
                                                                    <Typography>{" "}{item.vote}</Typography>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td id="ChairmanNoData" style={{
                                                            border: "1px solid black",
                                                            borderCollapse: "collapse",
                                                            textAlign: 'center'
                                                        }} colSpan={2}>
                                                            <Typography>{t("No Nomination Found for Chairman")}</Typography>
                                                        </td>
                                                    </tr>
                                            }
                                        </table>
                                    </Grid>
                                    <Grid xs={6}>
                                        <table style={{
                                            border: "1px solid black", width: "100%",borderCollapse: "collapse",
                                        }}>
                                            <tr>
                                                <th colSpan={2}
                                                    style={{border: "1px solid black", borderCollapse: "collapse"}}>
                                                    <Typography id="ViceChairmanNominationResult"
                                                                className={"bold-text"}>{t("Vice Chairman Nomination Results")}</Typography>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{
                                                    border: "1px solid black",borderCollapse: "collapse", textAlign: "center"
                                                }}>
                                                    <Typography id="ViceChairmanNominationResultName"
                                                                className={"bold-text"}>{t("Name")}</Typography>
                                                </td>
                                                <td style={{
                                                    border: "1px solid black", textAlign: "center",
                                                    borderCollapse: "collapse"
                                                }}>
                                                    <Typography id="ViceChairmanNominationResultVote"
                                                                className={"bold-text"}>{t("Number of Votes")}</Typography>
                                                </td>
                                            </tr>
                                            {
                                                nominationData?.vice_chairman_nomination_results?.length > 0 ?
                                                    nominationData?.vice_chairman_nomination_results?.map((item: any, index: any) => {
                                                        return (
                                                            <tr>
                                                                <td style={{
                                                                    border: "1px solid black",borderCollapse: "collapse"
                                                                }} key={index}>
                                                                    <Typography>{" "} {index + 1}.{" "}{item?.name}</Typography>
                                                                </td>
                                                                <td style={{
                                                                    border: "1px solid black",textAlign: "center",
                                                                    borderCollapse: "collapse",
                                                                }}>
                                                                    <Typography>{" "}{item?.vote}</Typography>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td id="ViceChairmanNoData" style={{
                                                            border: "1px solid black",
                                                            borderCollapse: "collapse",
                                                            textAlign: 'center'
                                                        }} colSpan={2}>
                                                            <Typography>{t("No Nomination Found for Vice Chairman")}</Typography>
                                                        </td>
                                                    </tr>
                                            }
                                        </table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box style={{position: "fixed", bottom: "10px", right: "15px", textAlign: "center"}}>
                    <Typography variant="caption" className="ReportGeneratedBy" color="textSecondary" style={{
                        fontWeight: "bold",color: "#181D25",
                        opacity: "0.5"
                    }}>{t("Report Generated By")}</Typography> <br/>
                    <Typography variant="caption" color="textSecondary" style={{
                        fontWeight: "bold",color: "#181D25", opacity: "0.5"
                    }}>{localStorage.getItem("username")}</Typography>
                </Box>
            </Container>
        );
    }
}

// @ts-ignore
export default withTranslation()(withRouter(ChairmanNominationPrint))