import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import toast from "react-hot-toast";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  loading: boolean;
  isDeleteUnitModalOpen: boolean;

  unitId: string;

  myUnitList: any[];

  filter: {
    status: string;
  };
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyUnitListController extends BlockComponent<Props, S, SS> {
  GetMyUnitListCallId: any;
  DeLinkUnitCallId: any;
  DeleteRequestUnitCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isDeleteUnitModalOpen: false,

      unitId: "",

      myUnitList: [],

      filter: {
        status: "",
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetMyUnitListCallId:
          this.setState({ loading: false }, () => {
            this.handleGetUnitListResponse(responseJson);
          });
          break;
        case this.DeLinkUnitCallId:
        case this.DeleteRequestUnitCallId:
          this.setState({ loading: false }, () => {
            if (responseJson && responseJson.code === 200) {
              toast.success(responseJson.message);
              this.getMyUnitList();
            }
          });
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getMyUnitList();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.filter.status !== this.state.filter.status) {
      await this.getMyUnitList();
    }
  }

  getMyUnitList = async () => {
    const { status } = this.state.filter;

    this.GetMyUnitListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_request_management/my_apartment?status=${status}&No_Own=true`,
    });
  };

  handleGetUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ myUnitList: responseJson.data });
    }
  };

  handleOpenDeleteUnitModal = (unit: any) => {
    this.setState({ isDeleteUnitModalOpen: !this.state.isDeleteUnitModalOpen, unitId: unit });
  };

  handleCloseDeleteUnitModal = () => {
    this.setState({ isDeleteUnitModalOpen: !this.state.isDeleteUnitModalOpen });
  };

  deLinkUnitFromOwner = async () => {
    const owner_id = localStorage.getItem("userId");
    this.DeLinkUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_request_management/delink_user?apartment_management_id=${
        this.state.unitId
      }&account_id=${owner_id}`,
    });
  };

  deleteRequestUnit = async (unit: any) => {
    this.DeleteRequestUnitCallId = await apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `bx_block_request_management/requests/destroy_request/${unit}`,
    });
  };
}
