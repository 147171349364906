// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  selectedTab: string;
  selectedStatus: string;
  selectedBuilding: string;
  selectedSort: string;
  selectedUnit: string;
  buildingList: any[];
  unitList: any[];
  invoiceList: any[];
  tenantInvoiceList: any[];
  tenantRentInvoiceList: any[];
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getInvoiceListCallId: any = "";
  getBuildingListCallId: any = "";
  getUnitListCallId: any = "";
  getTenantInvoiceListCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      selectedTab: "MyInvoices",
      selectedStatus: "",
      selectedSort: "asc",
      selectedBuilding: "",
      selectedUnit: "",
      buildingList: [],
      unitList: [],
      invoiceList: [],
      tenantInvoiceList: [],
      tenantRentInvoiceList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.getInvoiceListCallId:
          this.getInvoiceListResponse(responseJson);
          break;
        case this.getTenantInvoiceListCallId:
          this.getTenantInvoiceListResponse(responseJson);
          break;
        case this.getBuildingListCallId:
          this.getBuildingListResponse(responseJson);
          break;
        case this.getUnitListCallId:
          this.getUnitListResponse(responseJson);
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  getInvoiceListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ invoiceList: responseJson.data });
    }
  };

  getTenantInvoiceListResponse = (responseJson: any) => {
    if (responseJson && responseJson.invoices && responseJson.rent_payments) {
      this.setState({ tenantInvoiceList: responseJson.invoices, tenantRentInvoiceList: responseJson.rent_payments });
    }
  };

  getBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ buildingList: responseJson.data });
    }
  };

  getUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.units) {
      this.setState({ unitList: responseJson.units });
    }
  };

  async componentDidMount() {
    this.getInvoiceList();
    this.getTenantInvoiceList();
    this.getBuildingList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.selectedStatus !== this.state.selectedStatus ||
      prevState.selectedSort !== this.state.selectedSort ||
      prevState.selectedBuilding !== this.state.selectedBuilding ||
      prevState.selectedUnit !== this.state.selectedUnit
    ) {
      await this.getInvoiceList();
      await this.getTenantInvoiceList();
    }
  }

  getInvoiceList = async () => {
    const { selectedStatus, selectedSort, selectedBuilding, selectedUnit } = this.state;
    this.getInvoiceListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/my_invoice_list?select_status=${selectedStatus}&sort_by=${selectedSort}&building_management_id=${selectedBuilding}&unit_id=${selectedUnit}`,
    });
  };

  getTenantInvoiceList = async () => {
    const { selectedStatus, selectedSort, selectedBuilding, selectedUnit } = this.state;
    this.getTenantInvoiceListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/tenant_invoice_list?select_status=${selectedStatus}&sort_by=${selectedSort}&building_management_id=${selectedBuilding}&unit_id=${selectedUnit}`,
    });
  };

  getBuildingList = async () => {
    this.getBuildingListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_rent_payment/buildings`,
    });
  };

  getUnitList = async (building: any) => {
    this.getUnitListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/my_unit_list?building_management_id=${building}`,
    });
  };
}

// Customizable Area End
