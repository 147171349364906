// Customizable ble Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import * as Yup from "yup";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

export interface DetailBox {
  title: string;
  status: string;
  date: string;

  note: string;
  pdfUrl: string;

  rejectNote: string;
}

interface S {
  loading: boolean;
  minuteId: any;

  minuteDetails: DetailBox;

  isMinuteApproveOpen: boolean;
  isMinuteRejectOpen: boolean;
}

interface SS {
  id: any;
}

export default class CEMeetingDetailController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetMeetingMinuteDetailsByIdCallId: string = "";
  UpdateStatusMeetingCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      minuteId: "",

      minuteDetails: {
        title: "",
        status: "",
        date: "",

        note: "",
        pdfUrl: "",

        rejectNote: "",
      },

      isMinuteApproveOpen: false,
      isMinuteRejectOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetMeetingMinuteDetailsByIdCallId:
        return this.handleMeetingMinuteDetailsResponse(responseJson);
      case this.UpdateStatusMeetingCallId:
        return this.handleStatusChangeMeetingResponse(responseJson);
      default:
        break;
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJson);
    }
    ApiCatchErrorResponse(errorResponse);
  }

  async componentDidMount(): Promise<void> {
    const minute_id = this.props.navigation.getParam("id");
    this.setState({ minuteId: minute_id }, () => {
      this.getMeetingMinuteDetailsById();
    });
  }

  getMeetingMinuteDetailsById = async () => {
    this.setState({ loading: true }, async () => {
      this.GetMeetingMinuteDetailsByIdCallId = await CEApiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `/bx_block_meeting/company_employee_meetings/${this.state.minuteId}`,
      });
    });
  };

  handleMeetingMinuteDetailsResponse = (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      if (responseJson && responseJson.code === 200) {
        const meeting = responseJson.meeting.data;
        this.setState({
          minuteDetails: {
            title: meeting.attributes.title,
            status: meeting.attributes.meeting_mins_status,
            date: this.handleAllEmptyFilter(meeting.attributes.meeting_date_time),

            note: meeting.attributes.meeting_mins_notes,
            pdfUrl: meeting.attributes.meeting_mins_pdf?.url,

            rejectNote: meeting.attributes.meeting_reject_note?.note,
          },
        });
      }
    });
  };

  handleEditMinute = () => {
    sessionStorage.setItem("isMinuteMeeting", this.state.minuteId);
    this.props.navigation.navigate("CEAddScheduleMeetingMinute", { id: this.state.minuteId });
  };

  handleStatusChange = (status: string, note: string) => {
    const body: any = {
      meeting: {
        meeting_mins_status: status,
      },
    };
    if (status === 'rejected') {
      body.meeting.meeting_reject_note_attributes = {
        note: note,
      };
    }
    this.setState({ loading: true }, async () => {
      this.UpdateStatusMeetingCallId = await CEApiCall({
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_meeting/compney_employee_meeting_mins/${this.state.minuteId}`,
        body: JSON.stringify(body),
      });
    });
  };

  handleStatusChangeMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      this.getMeetingMinuteDetailsById();
    });
  };

  handleMinuteApproveDialog = () => {
    this.setState((prevState) => ({ isMinuteApproveOpen: !prevState.isMinuteApproveOpen }));
  };

  handleMinuteRejectDialog = () => {
    this.setState((prevState) => ({ isMinuteRejectOpen: !prevState.isMinuteRejectOpen }));
  };

  handleAllEmptyFilter = (value: string) => (value === "all" ? "" : value);

  rejectNoteValidation: any = Yup.object().shape({
    note: Yup.string().required("Required").matches(/\S/, "Required"),
  });
}
// Customizable Area End
