//Customizable area start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";

export interface Props {
  navigation?: any;
  id?: string;
  dataFromComponent1: any;
  handleModalClose: any;
  handleRPChangeSuccess: any;
  data: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  initialValues: any;
  empformModal: any;
  reportingPersonList: any[];
}

interface SS {
  id: any;
}

export default class CEChangeRPController extends CommonApiCallForBlockComponent<Props, S, SS> {
  LoadDataCallID: any;
  PostCallId: any;
  reportingPersonList: any;
  changeReportingPersonCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      initialValues: {
        NRP: "",
        CRP: this.handleName(this.props.data),
        EmpName: this.props.data && this.props.data.attributes ? this.props.data.attributes.employee_name : "",
      },
      empformModal: false,
      reportingPersonList: [],
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.setState({ empformModal: this.props.dataFromComponent1 });
    this.getTeamReportingPersonListAPI();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.reportingPersonList) {
          this.handleTeamReportingPersonList(responseJson);
        } else if (apiRequestCallId === this.changeReportingPersonCallId) {
          this.props.handleModalClose();
          this.props.handleRPChangeSuccess(this.props.data.id);
        }
        ApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleTeamReportingPersonList = (responseJson: any) => {
    let reportList = [];
    if (!responseJson.errors) {
      if (responseJson.data) {
        reportList = responseJson.data.map((item: any) => ({
          label: item.attributes.first_name + " " + item.attributes.last_name,
          value: item.id,
        }));
      }
      this.setState({ reportingPersonList: reportList });
    }
  };

  getTeamReportingPersonListAPI = async () => {
    this.reportingPersonList = await apiCallWithToken({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/reporting_manager_list?role=${
        this.props.data.attributes.role
      }`,
    });
  };

  changeReportingPerson = async (formData: any) => {
    const initialFormData = new FormData();
    initialFormData.append("team_member[reporting_person_id]", formData.NRP);
    initialFormData.append("team_member[employee_name]", formData.EmpName);

    this.changingReportingPerson(initialFormData);
  };

  changingReportingPerson = async (formData: any) => {
    this.changeReportingPersonCallId = await apiCallWithToken({
      method: "PATCH",
      body: formData,
      endPoint: `bx_block_my_team/company_employee/employee_team_members/${this.props.data.id}`,
    });
  };

  handleName = (data: any) => {
    if (data && data.attributes) {
      if (data.attributes.reporting_person) {
        return data.attributes.reporting_person.first_name + " " + data.attributes.reporting_person.last_name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
}
// Customizable Area End
