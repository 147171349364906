//Customizable area start
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import React from "react";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  
}
interface S {
  otp: any;
  email: string;
  showPassword: any;
  password: string;
  emailopen: any;
  emailfield: any;
  initialValues: any;
  emailError: string;
  passwordError: string;
  error: string
  showError: boolean;
  apiEmailLoginCallId: any;
  stay_login:boolean;
  language:string;
  
}interface SS {
  id: any;
}
export default class EmailAccountController extends CommonApiCallForBlockComponent<Props, S, SS> {
  LoginCallId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
      otp: '',
      email: '',
      password: '',
      showPassword: false,
      emailopen: false,
      emailfield: "",
      initialValues: {
        email: "",
        password: ''
      },
      emailError: '',
      passwordError: '',
      error: '',
      showError: false,
      apiEmailLoginCallId: '',
      stay_login:false,
      language:"ENG",
      
    };
   
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }
  async componentDidMount() {
    await super.componentDidMount()
    localStorage.setItem("language", "en");
   
   
  }
 
  
  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.LoginCallId) {
          if (!responseJson.errors) {
            localStorage.setItem("loginSuccessToken", responseJson.meta.token);
            localStorage.setItem("role", responseJson.meta.role);
            localStorage.setItem("user_id", responseJson.meta.id)
            this.props.navigation.navigate("DashboardCompanyEmployee")

          }
          else if (responseJson.errors[0].failed_login === 'Account Not Found or Not Activated') {
              this.errorModal(responseJson.errors[0].failed_login)
            }

          
          else if (responseJson.errors[0].failed_login === 'Invalid Password or email') {
              this.errorModal(responseJson.errors[0].failed_login)
            }

          
        }
      }

    }
  }
  loginSubmit = async (values: any) => {
    this.LoginCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: 'bx_block_login/employee_login',
      body: JSON.stringify({
        data: {
          stay_login: this.state.stay_login,
          email: values.email,
          password: values.password,
        }
      })
    });
    
  }
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  handleOpen = () => {
    const target=document.querySelector('#closeLocation')
    this.setState({
      emailopen: true,
    }); 
    this.setState({
            emailfield: target
          }) 
  }
}
// Customizable Area End

