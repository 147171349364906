import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import {runEngine} from "../../../framework/src/RunEngine";
import MessageEnum, {
   getName
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import * as Yup from 'yup';
import {imgPasswordInVisible, imgPasswordVisible} from "./assets";
import {valueContainerCSS} from "react-select/src/components/containers";
// Customizable Area End


export const configJSON = require("./config");


export interface Props {
    id: string;
   navigation: any;
   
}
export interface S {
   // Customizable Area Start
   sortBy: any;
   status: any;
   error: any;
   loading: boolean;
   userTypeData: any;
   anchorEl: any;
   anchorEl_1: any;
   getIncidentDetails: any;
   email: string;
   password: string;
   otpAuthToken: string;
   reTypePassword: string;

   passwordHelperText: string;
   
   userType: string | null;
   phone: string;
   enablePasswordField: boolean;

   enableReTypePasswordField: boolean;
   countryCodeSelected: string;
   data: any[];
   firstName: string;
   lastName: string;
   buildingName: any;
   statusDetail: any;
   providerWork: any;
   providerName: any;
   provider_id: any;
   imageShowDialog: any;
   statusShowDialog: any;
   file: any;
   commonAreaData: any;
   incidentRelatedData: any;
   incidentListing: any;
   unitName: any;
   serachBuildingName: any;
   SearchIncident: any;
   showDialog: any;
   unitNameData: any[];
   providerNameListing: any;
   providerListing: any;
   buildingNameData: any[];
   chatDrawer: any;
   showError: boolean;


   assignProvider: any,
   assignProviderName: any
   selectProviderError:any
   // Customizable Area End
}


export interface SS {
   // Customizable Area Start
   id: any;
   // Customizable Area End
}


export default class IncidentManagementController extends BlockComponent<
   Props,
   S,
   SS
> {
   // Customizable Area Start
   arrayholder: any[];
   createAccountApiCallId: any;
   passwordReg: RegExp;
   emailReg: RegExp;
   getIncidentListingApiCallId: any;
   getIncidentDetailsByIdApiCallId: any;
   getBuildingNameApiCallId: any;
   getUnitRelatedApiCallId: any;
   validationApiCallId: any;
   labelLegalTermCondition: string;
   labelLegalPrivacyPolicy: string;
   btnTextSignUp: string;
   currentCountryCode: any;
   imgPasswordVisible: any;
   imgPasswordInVisible: any;
   apiUpdateStatusCallId: any;
   getProviderNameApiCallId: any;
   getProviderListingApiCallId: any;
   createChatRoomAPIId: any;
   labelHeader: any;
   labelFirstName: string;
   lastName: string;
   labelEmail: string;
   labelPassword: string;
   labelRePassword: string;
   labelLegalText: string;
   getIncidentRelatedApiCallId: any;
   searchIncidentListingApiCallId: any;
   apiAssginProviderCallId: any;
   apiUpdateProviderCallId: any;

   // Customizable Area End
   constructor(props: Props) {
       super(props);
       this.subScribedMessages = [
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.CountryCodeMessage),
           getName(MessageEnum.RestAPIResponceMessage),
           
           
       ];
       this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
       this.receive = this.receive.bind(this);
       


       runEngine.attachBuildingBlock(this, 
        this.subScribedMessages);
       //@ts-ignore
       this.state = {
           // Customizable Area Start
          
           unitName: "",
           status: "",
           serachBuildingName: " ",
           statusDetail: " ",
           providerWork: " ",
           providerName: " ",
           provider_id: null,
           imageShowDialog: false,
           file: {},
           statusShowDialog: false,
           SearchIncident: null,
           showDialog: false,
           firstName: "",
           lastName: "",
           email: "",
           password: "",
           reTypePassword: "",
           otpAuthToken: "",
           data: [],
           passwordHelperText: "",
           enablePasswordField: true,
           error: null,
           enableReTypePasswordField: true,
           countryCodeSelected: "",
           phone: "",
           userType: '',
           loading: false,
           commonAreaData: null,
           incidentRelatedData: null,
           incidentListing: null,
           anchorEl: null,
           anchorEl_1: null,
           getIncidentDetails: null,
           buildingName: "",
           unitNameData: [],
           providerNameListing: null,
           providerListing: null,
           buildingNameData: [],
           chatDrawer: false,
           showError: false,
           assignProvider: "",
           selectProviderError:"",
           assignProviderName: "",
          
           // Customizable Area End
       };



       // Customizable Area Start
       this.passwordReg = new RegExp("\\w+");
       this.emailReg = new RegExp("\\w+");
       this.arrayholder = [];
       
       this.labelHeader = configJSON.labelHeader;
       this.labelFirstName = configJSON.labelFirstName;
       this.lastName = configJSON.lastName;
       this.labelEmail = configJSON.labelEmail;
       this.labelPassword = configJSON.labelPassword;
       this.labelRePassword = configJSON.labelRePassword;
       this.labelLegalText = configJSON.labelLegalText;
       this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
       this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
       this.btnTextSignUp = configJSON.btnTextSignUp;



       this.imgPasswordVisible = imgPasswordVisible;
       this.imgPasswordInVisible = imgPasswordInVisible;


      
       // Customizable Area End
   }


   showError = () => 
   {
       if (this.state.error) 
       {
           this.setState({
               showError: true,
               loading:false
           })
       }
   }


   assignServiceProviderResponse = (responseJson: any) => {
       if (responseJson && responseJson.data) {
           const id = localStorage.getItem("incidentManagementDetailId")
           this.getIncidentDetailsById(id);
           this.setState({loading: false})
       } else if (responseJson.errors) {
           let error = Object.values(responseJson.errors[0])[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
   }


   updaterProviderResponse = (responseJson: any) => {
       if (responseJson && responseJson.data) {
           const id = localStorage.getItem("incidentManagementDetailId")
           this.getIncidentDetailsById(id);
           this.setState({loading: false})
       } else if (responseJson.errors) {
           let error = responseJson.errors[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
   }


   updateStatusResponse = (responseJson: any) => {
       if (responseJson && responseJson.data) {
           this.setState({loading: false})
       } else if (responseJson.errors) {
           let error = Object.values(responseJson.errors[0])[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
   }


   getProviderNameResponse = (responseJson: any) => {
       if (responseJson && responseJson.data) {
           this.setState({providerNameListing: responseJson.data,loading: false})
       } else if (responseJson.errors) {
           let error = responseJson.errors[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
   }


   getProviderListingResponse = (responseJson: any) => {
       if (responseJson || responseJson.data) {
           this.setState({providerListing: responseJson,loading: false})
       } else if (responseJson?.errors) {
           let error = responseJson.errors[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson?.error || "Something went wrong!"});
       }
       this.showError()
   }


   getIncidentListingResponse = (responseJson: any) => {
       if (responseJson && responseJson.data) {
           this.setState({incidentListing: responseJson.data,loading: false})
       } else if (responseJson.errors) {
           let error = Object.values(responseJson.errors[0])[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
       this.setState({loading: false})
   }


   createChatRoomResponse = (responseJson: any) => 
   {
       if (responseJson 
        && responseJson.data) 
        {
           localStorage.setItem('selectedChat', JSON.stringify(responseJson.data))
           this.setState(
            {loading: false, chatDrawer: true}
            )
       } else if (responseJson.errors) 
       {
           let error = responseJson.errors[0] as string;
           this.setState({error});
       } else 
       {
           this.setState({error: responseJson.error || "Something went wrong!"});
       }
       this.showError()
       this.setState(
        {loading: false, error: null}
        )
   }


   getIncidentDetailsResponse = (responseJson:any) => {
       this.setState({
           loading:false
       })
       if (responseJson && responseJson.data) {
           const statusDetail = responseJson.data.attributes.incident_status;
           if (responseJson.data.attributes.assign_incidents.data === null)
               this.setState({getIncidentDetails: responseJson?.data, statusDetail})
           else {
               const work_type = responseJson.data.attributes.assign_incidents.data.attributes.provider.work_type;
               const apartment_management_id = responseJson.data.attributes.apartment_management.apartment_management_id;
               const providerWork = `${apartment_management_id},${work_type}`;
               const providerName = responseJson.data.attributes.assign_incidents.data.attributes.provider.id;
               this.getProviderName(apartment_management_id, work_type)
               this.setState({
                   getIncidentDetails: responseJson?.data,
                   statusDetail,
                   providerWork,
                   providerName

               })
           }
       } else if (responseJson?.errors) {
           //@ts-ignore
           this.props.history.push("/IncidentManagementDetail")
           let error = responseJson.errors[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson?.error || "Something went wrong!"});
       }
       this.showError()
   }


   getUnitRelatedResponse = (responseJson:any) => {
       if (responseJson && responseJson?.data) {
           this.setState({unitNameData: responseJson?.data?.unit_apartments})
           this.setState({loading: false})
       } else if (responseJson?.errors) {
           let error = Object.values(responseJson.errors[0])[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson?.error || "Something went wrong!"});
       }
       this.showError()
   }


   getBuildingNameResponse = (responseJson:any) => {
       if (responseJson.data) {
           this.setState({buildingNameData: responseJson.data})
           this.setState({loading: false})
       } else if (responseJson?.errors) {
           let error = Object.values(responseJson.errors[0])[0] as string;
           this.setState({error});
       } else {
           this.setState({error: responseJson?.error || "Something went wrong!"});
       }
       this.showError()
       this.setState({loading: false})
   }


   async receive(from: string, message: Message) {
       // Customizable Area Start
       if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
           const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
           let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
           if (apiRequestCallId === this.apiAssginProviderCallId) {
               this.assignServiceProviderResponse(responseJson)
           } else if (apiRequestCallId === this.apiUpdateProviderCallId) {
               this.updaterProviderResponse(responseJson)
           } else if (apiRequestCallId === this.apiUpdateStatusCallId) {
               this.updateStatusResponse(responseJson)
           } else if (apiRequestCallId === this.getProviderNameApiCallId) {
               this.getProviderNameResponse(responseJson)
           } else if (apiRequestCallId === this.getProviderListingApiCallId) {
               this.getProviderListingResponse(responseJson)
           } else if (apiRequestCallId === this.getIncidentListingApiCallId) {
               this.getIncidentListingResponse(responseJson)
           } else if (apiRequestCallId === this.createChatRoomAPIId) {
               this.createChatRoomResponse(responseJson)
           } else if (apiRequestCallId === this.getIncidentDetailsByIdApiCallId) {
            this.setState({getIncidentDetails:responseJson.data})
                this.getIncidentDetailsResponse(responseJson)
           } else if (apiRequestCallId === this.getUnitRelatedApiCallId) {
                this.getUnitRelatedResponse(responseJson)
           } else if (apiRequestCallId === this.getBuildingNameApiCallId) {
                this.getBuildingNameResponse(responseJson)
           }
                    }
      
                    // Customizable Area End
   }


   // Customizable Area Start
   goToPrivacyPolicy() 
   {
       const msg: Message = 
       new Message(
           getName(MessageEnum.
            NavigationPrivacyPolicyMessage)
       );
       msg.addData(getName(MessageEnum.NavigationPropsMessage), 
       this.props);
       this.send(msg);
   }


   goToTermsAndCondition()
    {
       const msg: Message = 
       new Message(
           getName(MessageEnum.NavigationTermAndConditionMessage)
       );
       msg.addData(getName(MessageEnum.NavigationPropsMessage), 
       this.props);
       this.send(msg);
      }


   isStringNullOrBlank(str: string) 
   {
       return str === null || 
       str.length === 0;
         }


   isValidEmail(email:    string) 
   {
       return this.emailReg.test(email);
              }


   isNonNullAndEmpty(value:   string) 
   {
    return (
        value !== 
        undefined &&
        value !== null 
        &&
        value !== "null" 
        &&
        value.trim().length > 0
            );
    }   



   validateCountryCodeAndPhoneNumber(countryCode: string, 
    phoneNumber: string) 
    {
        let error = null;
if (this.isNonNullAndEmpty(phoneNumber)) 
{
           if (!this.isNonNullAndEmpty(String(countryCode))) 
           {
               error = configJSON.errorCountryCodeNotSelected;
           }
       } else if (this.isNonNullAndEmpty(countryCode)) 
       {
           if (!this.isNonNullAndEmpty(phoneNumber)) 
           {
              
            error = "Phone " + configJSON.errorBlankField;
           }
       }
       return error;
   }

   imgEnableRePasswordFieldProps = 
   {
       source: imgPasswordVisible
   };


   imgEnablePasswordFieldProps = {
       source: imgPasswordVisible
  
   };


   clear = () => {
       localStorage.clear()
       //@ts-ignore
       this.props.history.push("/");
   }


   getIncidentDetails = (id: any) => {
       localStorage.setItem("incidentManagementDetailId", id)
       //@ts-ignore
       this.props.history.push({
           pathname: "/IncidentManagementDetail",
       });
   }


   serachHandle = () => {
       this.getIncidentListing()
   }


   onChange = (e: any) => 
   {
       if (e.target.name === 'buildingName') 
       {
           const array = 
           e.target?.value?.split(",");
           const id = 
           array [0]
           this.getUnit(id)
           this.setState(
            {buildingName: e.target.value,unitName:"",status:""})
           this.setState(
            {serachBuildingName: id})
       } else if (e.target.name === "statusDetail") 
       {
           //@ts-ignore
           this.setState({[e.target.name]: e.target.value})
           this.setState({
            statusShowDialog: true})


       } else if (e.target.name === 'providerWork') 
       {
           const array =
            e.target?.value?.split(",");
           const id = array [0]
           const name = array[1]
           this.getProviderName(id, name)
           this.setState(
            {providerWork: e.target?.value})
       }
       else
        {
           //@ts-ignore
           this.setState({[e.target.name]: e.target.value})
       }
   }




   updateStatus = (val: any) => {


       const header = {
           token: localStorage.getItem("userToken")
       };
       const id = localStorage.getItem("incidentManagementDetailId")
       const formData = new FormData();
       console.log("this.state?.statusDetail=========>", val)


       if (val === "Resolved")
           //@ts-ignore
           formData.append('incident[mark_resolved_by_reporter]', true);
       else
           //@ts-ignore
           formData.append('incident[mark_resolved_by_reporter]', false);


       formData.append('incident[incident_status]', val);
       const httpBody = formData;


       this.setState({loading: true, statusShowDialog: false})
       const requestMessage = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
       );


       this.apiUpdateStatusCallId = requestMessage.messageId;
       requestMessage.addData(
           getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_custom_form/incidents/${id}`
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
           httpBody
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
           configJSON.PatchAPiMethod
       );


       runEngine.sendMessage(requestMessage.id, requestMessage);


       return true;
   };




   getIncidentListing = () => {
       try {
           const header = {
               "Content-Type": configJSON.validationApiContentType,
               token: localStorage.getItem("userToken")
           };
           const requestMessage = new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           );
           this.getIncidentListingApiCallId = requestMessage.messageId;
           this.setState({loading: true});
           const society_id = localStorage.getItem("society_id")
           const getSortByOrStatus = `bx_block_custom_form/incidents?society_management_id=${society_id}&search_building=${this.state?.serachBuildingName}&search_unit=${this.state?.unitName}&filter_by=${this.state?.status}`


           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               getSortByOrStatus
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );


           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) {
       }
   };


   getBuildingName = 
   () => 
   {
       try 
       {
           const header = {
               "Content-Type": 
               configJSON.validationApiContentType,
               token: localStorage.getItem("userToken")
           };
           const society_id = localStorage.getItem("society_id")
           const requestMessage = 
           new Message(
               
            getName(MessageEnum.RestAPIRequestMessage)
           );
           this.getBuildingNameApiCallId = 
           requestMessage.messageId;
           this.setState({loading: true});
           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               `bx_block_settings/building_managements?society_management_id=${society_id}`
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );
           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
        } 
       catch (error) 
       {
           }
   };


   getUnit = (id: any) => 
   {
       try 
       {
           const header =
            {
               "Content-Type": 
               configJSON.validationApiContentType,
               token: 
               localStorage.getItem("userToken")
           };
           const requestMessage = 
           new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           );
           this.getUnitRelatedApiCallId = 
           requestMessage.messageId;
           this.setState({loading: true});


           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               `bx_block_address/all_apartment_list?building_management_id=${id}`
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );


           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) 
       {
       }
   };


   getIncidentDetailsById = (id: any,) => {
       try {
           const header = {
               "Content-Type": configJSON.validationApiContentType,
               token: localStorage.getItem("userToken")
           };
           const requestMessage = new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           );
           this.getIncidentDetailsByIdApiCallId = requestMessage.messageId;
           this.setState({loading: true});


           const society_id = localStorage.getItem("society_id")
           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               `bx_block_custom_form/incidents/${id}?society_management_id=${society_id}`
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );


           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) {
       }
   };


   updateProvider = (assign_incidentsi_id: any) => {


       const header = {
           token: localStorage.getItem("userToken")
       };
       const incident_id: any = localStorage.getItem("incidentManagementDetailId")
       const formData = new FormData();
       formData.append('assign_incident[incident_id]', incident_id);
       formData.append('assign_incident[account_id]', this.state.assignProviderName);
       const httpBody = formData;


       this.setState({loading: true, showDialog: false})
       const requestMessage = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
       );


       this.apiUpdateProviderCallId = requestMessage.messageId;
       requestMessage.addData(
           getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_custom_form/assign_incidents/${assign_incidentsi_id}`
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
           httpBody
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
           configJSON.PatchAPiMethod
       );


       runEngine.sendMessage(requestMessage.id, requestMessage);


       return true;
   };




   assginProvider = () => {
       const header = {
           token: localStorage.getItem("userToken")
       };
       const id: any = localStorage.getItem("incidentManagementDetailId")
       const provider_id = this.state.assignProviderName;
       const formData = new FormData();
       formData.append('assign_incident[incident_id]', id);
       formData.append('assign_incident[account_id]', provider_id);


       const httpBody = formData;


       this.setState({loading: true, showDialog: false})
       const requestMessage = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
       );


       this.apiAssginProviderCallId = requestMessage.messageId;
       requestMessage.addData(
           getName(MessageEnum.RestAPIResponceEndPointMessage),
           `/bx_block_custom_form/assign_incidents`
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestBodyMessage),
           httpBody
       );


       requestMessage.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
           configJSON.exampleAPiMethod
       );


       runEngine.sendMessage(requestMessage.id, requestMessage);


       return true;
   };
   getProviderName = 
   (id: any, name: any) => 
   {
       try
        {
           const 
           header = {
               "Content-Type": 
               configJSON.validationApiContentType,
               token: localStorage.getItem("userToken")
           };
           const requestMessage = 
           new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           );
           const societyId = 
           localStorage.getItem("society_id")


           this.getProviderNameApiCallId = 
           requestMessage.messageId;


           this.setState({loading: true, 
            providerName: " "});
           const providerList 
           = `bx_block_custom_form/assign_incidents/provider_account_list?society_management_id=${societyId}&role_id=${this.state.assignProvider}`


           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               providerList
           );


           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );
           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) {
       }
   };


   providerList = (id: any) => {
       try {


           const header = {
               "Content-Type": configJSON.validationApiContentType,
               token: localStorage.getItem("userToken")
           };
           const requestMessage = new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           );
           this.getProviderListingApiCallId = requestMessage.messageId;
           this.setState({loading: true, showDialog: true,});


           const providerList = `bx_block_custom_form/assign_incidents/provider_role_list`
           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage),
               providerList
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage),
               configJSON.validationApiMethodType
           );
           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) {
       }
   };
   createChatRoom = 
   async (id: any) => 
         {
       try  {
           const requestMessage
            = 
           new Message(
               getName(MessageEnum.RestAPIRequestMessage)
           ); this.createChatRoomAPIId 
           =
            requestMessage.messageId;


           requestMessage.addData(
               getName(MessageEnum.RestAPIResponceEndPointMessage)
               ,
               `bx_block_chat/chats`
           );
           const 
           header 
           =
            {
               token: localStorage.getItem("userToken"),
           };
requestMessage.addData(
               getName(MessageEnum.RestAPIRequestHeaderMessage),
               JSON.stringify(header)
           );
           const 
           formData 
           =
            new FormData();
           formData.append("chat[chatable_type]", 'BxBlockCustomForm::Incident');
           // @ts-ignore
           formData.append("chat[chatable_id]", id);
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestBodyMessage),
               formData
           );
           requestMessage.addData(
               getName(MessageEnum.RestAPIRequestMethodMessage)
               ,'POST'
           );
           runEngine.sendMessage(requestMessage.id, requestMessage);
           return true;
       } catch (error) 
       {
       }


   }
   // Customizable Area End
}


