//@ts-ignore
//@ts-nocheck

import * as React from "react";
//Customizable Area Start
// custom components
import {
  Button, Grid, Box, Typography, FormControl, InputLabel, Select, MenuItem, Avatar
} from "@material-ui/core";
import {Building1, Car, Hash, Hyperlink, user, User3} from "./assets";
import { withRouter } from 'react-router';
import '../assets/css/style.scss';
import { Formik, Form, Field, ErrorMessage } from "formik";
import FamilyController from "./FamilyController.web";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class editOwnerFamily extends FamilyController {

  async componentDidMount() {

    this.getRelation()
    this.getIdType();

  }
  render() {
    // @ts-ignore
    let item = JSON.parse(localStorage.getItem('selectFamily'))
    // @ts-ignore
    const { t, i18n } = this.props
    const language = i18n.language;
    return (
      <>
        <Grid container spacing={2} className="auth-container editOwnerFamily" dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols" style={{ justifyContent: 'unset' }}>
            <Grid container>
              <Grid className="editOwnerFamily editOwnerFamily" xs={12} style={{ display: 'flex', alignItems: 'center',paddingBottom:'10px',paddingTop:'10px',paddingLeft:"8px" }}>
                <ArrowBackIcon onClick={() => window.history.back()} className='btn' style={{fontSize:"27px",marginRight:"10px"}}/>
                <p style={{ fontWeight: 600, fontSize: '18px' }} className="bold-text editOwnerFamily">
                  {t("Family Members")}
                </p>
              </Grid>
            </Grid>
            <div >
              <Grid container className="main-content-block editOwnerFamily" style={{marginTop:"15px",width:"95%"}}>
                <Grid xs={12} className={languageCondition(language, "arabic-grid inputPlaceholderRegistration", "inputPlaceholderRegistration")}>
                  <Formik initialValues={{
                    full_name: item?.attributes.name, relation: item?.attributes.relation.id,
                    IDoption: item?.attributes.id_proof.id, bannerUrl:item?.attributes.member_pic.url,
                    IDnumber: item?.attributes.id_number,
                  }}
                    id="formik"
                    validationSchema={this.addVehicleSchema()}
                    validateOnMount={true}
                    onSubmit={(values) => { this.updateVehicle(values) }}
                  >
                    {({ values, errors,
                      touched, setFieldValue, setFieldError }) => (
                      <Form className="commonForm" translate="yes" >
                        <Box className='formGroup' style={{ height: '121%' }}>
                          <Box style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            marginTop: '1rem',
                            marginBottom: '1.5rem'
                          }}>
                            <Avatar src={values.bannerUrl} style={{height:"65px",width:"65px"}} />
                            <label for="file1" style={{ color: '#FC8434', fontWeight: 'bold' }}> {t("Change Profile Picture")}</label>
                            <input
                              id="file1" type="file" accept="image/png, image/jpeg, image/jpg"
                              onChange={(e: any) => {
                                this.handleSelectBanner(
                                  e, setFieldValue, setFieldError
                                );
                              }}
                              style={{position: "absolute", zIndex: 2,cursor: "pointer", opacity: 0}}
                            />
                          </Box>
                            <Typography
                              style={{
                                color: "#F14E24", fontFamily: "Poppins",
                                fontWeight: 300, fontSize: 14,
                                marginTop: 5, marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="banner" />
                            </Typography>
                            <Typography
                              style={{
                                color: "#F14E24", fontFamily: "Poppins",
                                fontWeight: 300, fontSize: 14, marginTop: 5
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="bannerUrl" />
                            </Typography>
                          <Box
                            className="formInputGrp"
                            style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",backgroundColor:"#f9f9f9",marginTop:"14px"}}
                          >
                            <Field
                              className={languageCondition(language, "formInput_ar", "formInput fullName")}
                              name="full_name" placeholder={t("Family Member Name")}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons fullName")}>
                              <img src={user} />
                            </span>
                          </Box>
                            <Typography
                              style={{
                                color: "#F14E24", fontWeight: 300,
                                fontSize: 14, marginTop: 5, marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="full_name" />
                            </Typography>
                          {/* R4ealtion */}
                          <Box className="formGroup customSelect" marginTop="20px">
                            <FormControl variant="outlined" >
                              <InputLabel id="demo-simple-select-outlined-label" style={{ display: 'flex', alignItems: 'center', gap: '1rem',marginLeft:"40px" }}>
                                {t("Relation")}</InputLabel>
                              {/* <InputLabel id="demo-simple-select-outlined-label">Select User Type</InputLabel>  */}
                              <Select
                                  name="userType" className="formInput_select_family familyRelationEdit selectRelation"
                                  labelId="demo-simple-select-outlined-label" id="family-select-outlined"
                                  placeholder="Relation" value={values.relation}
                                  style={{borderRadius:"25px"}} label="Relation"
                                  onChange={(e) => {  setFieldValue("relation", e.target.value) }}
                                  startAdornment={
                                    <img src={Hyperlink} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"20px"},{marginLeft:"5px",marginRight:"10px"})}/>
                                  }>
                                <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "Relation")}>
                                  {t("Relation")}
                                </MenuItem>
                                {this.state.allRelation && this.state.allRelation.map((item) =>
                                    <MenuItem className={languageCondition(language, "select-arabic-menu", "Relation")} key={item.id} value={item.id}>{languageCondition(language,item.name_ar,item.name)}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                            <ErrorMessage className="text-error" component="Typography" name="relation" />
                          </Box>
                          {/* Id type */}
                          <Box className="formGroup customSelect" marginTop="20px">
                            <FormControl variant="outlined" >
                              <InputLabel id="demo-simple-select-outlined-label" style={{ display: 'flex', alignItems: 'center', gap: '1rem',marginLeft:"40px" }}>
                                {t("ID Type")}</InputLabel>
                              <Select
                                  name="userType" labelId="demo-simple-select-outlined-label"
                                  className="formInput_select_family familyIDEdit selectID"
                                  id="family-select-outlined" value={values.IDoption}
                                  style={{borderRadius:"25px"}}
                                  label="Relation"  placeholder={t("Relation")}
                                  startAdornment={
                                    <img src={User3} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"20px"},{marginLeft:"5px",marginRight:"10px"})}/>
                                  }
                                  onChange={(e) => { setFieldValue("IDoption", e.target.value) }}
                              >
                                <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "Relation")}>
                                  <em>{t("None")}</em>
                                </MenuItem>
                                {this.state.allIdType && this.state.allIdType.map((item) =>
                                    <MenuItem key={item.id} value={item.id} className={languageCondition(language, "select-arabic-menu", "Relation")}>{languageCondition(language,item.name_ar,item.name)}</MenuItem>)}
                              </Select>
                            </FormControl>
                            <ErrorMessage className="text-error" component="Typography" name="IDoption" />
                          </Box>
                          {/* car model */}
                          <Box
                            className="formInputGrp"
                            style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",backgroundColor:"#f9f9f9",marginTop:"14px"}}
                          >
                            <Field
                              name="IDnumber"
                              placeholder={"Enter ID Number"}
                              className={languageCondition(language, "formInput_ar", "formInput IDNumber")}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons fullName")}>
                              <img src={Hash} />
                            </span>
                          </Box>
                            <Typography
                              style={{
                                color: "#F14E24", fontFamily: "Poppins",
                                fontWeight: 300,fontSize: 14,marginTop: 5,marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="IDnumber" />
                            </Typography>
                        </Box>
                          <Box className={languageCondition(language,"customButton familyFormButton_ar","customButton familyFormButton")}>
                            <Button
                              variant="contained"
                              type="submit"
                              className="familyFormBottomButton"
                            >
                              {t("Save Details")}
                            </Button>
                          </Box>
                      </Form>)}
                  </Formik>
                </Grid>
              </Grid>

            </div>
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols familyEdit">
            <Box className="right-block familyEdit" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo familyEdit" alt="" />
            </Box>
          </Grid>
        </Grid>
      </>

    )
  }
}
export default withTranslation()(withRouter(editOwnerFamily));
//Customizable Area End
