// Customizable Area Start

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BuildingImage = require("../assets/building.png");
export const NoTenant = require("../assets/no-tenant.png");
export const EditIcon = require("../assets/edit.png");
export const DeleteIcon = require("../assets/delete.png");
export const DeleteIcon2 = require("../assets/delete_icon.png");
export const InfoIcon = require("../assets/info-icon.png");
export const TenantName = require("../assets/tenant-name.png");
export const TenantType = require("../assets/tenant-type.png");
export const BuildingName = require("../assets/building-name.png");
export const UnitNumber = require("../assets/unit-number.png");
export const CityIcon = require("../assets/city.png");
export const PhoneNumber = require("../assets/phone.png");
export const EmailIcon = require("../assets/email.png");
export const GreyTenantName = require("../assets/grey-tenant-name.png");
export const GreyTenantType = require("../assets/grey-tenant-type.png");
export const GreyBuildingName = require("../assets/grey-building-name.png");
export const GreyUnitNumber = require("../assets/grey-unit-number.png");
export const GreyPhoneNumber = require("../assets/grey-phone.png");
export const GreyEmailIcon = require("../assets/grey-email.png");
export const LeaseIcon = require("../assets/lease.png");
export const IdType = require("../assets/id-type.png");
export const GreyIdType = require("../assets/grey-id-type.png");
export const IdNumber = require("../assets/id-number.png");
export const GreyIdNumber = require("../assets/grey-id-number.png");
export const CalenderIcon = require("../assets/calender.png");
export const GreyCalenderIcon = require("../assets/grey-calender.png");
export const PdfIcon = require("../assets/pdf.png");
export const DownloadIcon = require("../assets/download.png");
export const UploadIcon = require("../assets/upload.png");
export const DocumentIcon = require("../assets/document.png");
export const ComplexIcon = require("../assets/complex.png");
//FacilityReservation
export const Building1 = require("../assets/building1.png");
export const Tenant_Logo = require("../assets/TenantLogo.png");
export const Grid_Icon = require("../assets/grid-icon.png");
export const Filter_Icon = require("../assets/filter-icon.png");
export const Building_Logo = require("../assets/BuildingLogo.png");
export const Tick_Circle_Icon = require("../assets/tick-circle-icon.png");
export const TimeIcon = require("../assets/time_icon.png");
export const House_Icon = require("../assets/house-icon.svg");
export const LEADING_ICON = require("../assets/LEADING-ICON.png");
export const upcoming = require("../assets/upcoming.png");
export const pending = require("../assets/pending.png");
export const previous = require("../assets/previous.png");
export const Pencil = require("../assets/Pencil.png");
export const wrong = require("../assets/wrong.png");
export const Bin = require("../assets/Bin.png");
export const Close_Icon = require("../assets/icon-close.png");
export const Classified_CorrectIcon = require("../assets/classified_correct_icon.png");
export const User_Icon = require("../assets/user-icon.png");
export const Users_Icon = require("../assets/users-icon.png");
export const CROSS = require("../assets/cross.png");
export const FacilityLocation = require("../assets/facility-location.png");
export const FacilityCalender = require("../assets/facility-calender.png");
export const FacilityUser = require("../assets/facility-user.png");
export const FacilityBuilding = require("../assets/facility-building.png");
export const FacilityCheck = require("../assets/facility-check.png");


// Customizable Area End