export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profileExp = require("../assets/teamUser.svg");
export const chat = require("../assets/chat.png");
export const telephone = require("../assets/telephone.png");
export const email = require("../assets/email.png");
export const edit = require("../assets/chairmanEdit.png");
export const info = require("../assets/info.png");
export const calanderIcon = require("../assets/calanderIcon.png")
export const userIcon = require("../assets/userIcon.png")
export const buildings = require("../assets/buildings.png")
export const pencil = require("../assets/Pencil.png")

export const teamUser = require("../assets/teamUser.svg")
export const pollSurvey = require("../assets/pollsurvey.png")

export const warningIcon = require("../assets/warningIcon.png")