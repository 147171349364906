// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  Link,
  Typography,
  withStyles,
  Box,
  Grid,
  Dialog,
  DialogContent,
  FormControl,
  DialogActions,
} from "@material-ui/core";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import PersonalDocumentListController, { Props } from "./PersonalDocumentListController.web";
import { DocumentReportStyleWeb } from "./DocumentReportStyle.web";
import { UploadImage, DeleteImage, NoPdf, PdfImage, BackIcon, Document } from "./assets";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import AlertError from "../../../components/src/AlertError.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { downloadFileFromURL } from "../../../components/src/APICallComponent/FileDownload.web";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";

class PersonalDocumentList extends PersonalDocumentListController {
  constructor(props: Props) {
    super(props);
  }

  documentType = (t: any) => {
    if (this.state.documentType === "rent-contract") {
      return `${t("rent contract")}`;
    } else if (this.state.documentType === "unit-plan") {
      return `${t("unit plan")}`;
    } else if (this.state.documentType === "other-documents") {
      return `${t("other documents")}`;
    } else {
      return "";
    }
  };

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box
          dir={languageCondition(language, "rtl", "ltr")}
          className={classes.personalDocument}
          style={{ background: this.state.documentsList.length === 0 ? "white" : "#F7F9FE", height: "100vh" }}
        >
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box display={{ xs: "flex", md: "flex" }} className="menu">
                <Link href="/PersonalDocument">
                  <IconButton>
                    <img src={BackIcon} alt="" className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                  </IconButton>
                </Link>{" "}
                <span className="bold-text">
                  {this.state.documentType === "rent-contract" && `${t("Rent Contract")}`}
                  {this.state.documentType === "unit-plan" && `${t("Unit Plan")}`}
                  {this.state.documentType === "other-documents" && `${t("Other Documents")}`}
                </span>
              </Box>
              <Container className="content-area document-box list">
                <div className="personal-documents">
                  {this.state.documentsList.length === 0 && (
                    <div className="empty-list">
                      <div className="content-box">
                        <img src={NoPdf} />
                        <h3 className="bold-text">
                          {t("No Documents")} <br /> {t("Found")}
                        </h3>
                        <p>
                          {t("Looks like you haven't uploaded any documents! you can upload")}{" "}
                          {this.state.documentType === "rent-contract" && `${t("rent contract")}`}
                          {this.state.documentType === "unit-plan" && `${t("unit plan")}`}
                          {this.state.documentType === "other-documents" && `${t("other documents")}`}{" "}
                          {t("by tapping on below button.")}
                        </p>
                      </div>
                    </div>
                  )}
                  <Grid container spacing={2}>
                    {this.state.documentsList.map((document: any) => {
                      return (
                        <Grid item xs={12} md={12} lg={12} key={document.id}>
                          <Box className="item document personal-document">
                            <Link href={`/PersonalDocument/${this.state.documentType}/${document.id}/view`}>
                              <div className="left-side" style={{ gap: "10px" }}>
                                <div className="image">
                                  <img src={PdfImage} />
                                </div>
                                <div className="info">
                                  <h4 className="bold-text">{document.attributes.title}</h4>
                                  <div className="more-info" style={{ gap: "10px" }}>
                                    <p style={{ margin: "0px" }}>
                                      <span>{document.attributes.images[0].file_size}</span>
                                      {t("MB")}
                                    </p>
                                    <p style={{ margin: "0px" }}>
                                      <span>{document.attributes.images[0].count}</span>
                                      {t("Pages")}
                                    </p>
                                    <p style={{ margin: "0px" }}>
                                      {moment(document.attributes.created_at).format("DD/MM/YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            <div className={languageCondition(language, "", "right-menu personal-document-menu")}>
                              <Menu
                                menuButton={
                                  <IconButton>
                                    <MoreVertIcon />
                                  </IconButton>
                                }
                              >
                                <MenuItem
                                  className="personal-doc-download"
                                  onClick={() =>
                                    downloadFileFromURL(
                                      document.attributes.images[0].download_url,
                                      document.attributes.title
                                    )
                                  }
                                >
                                  {t("Download")}
                                </MenuItem>
                                <MenuItem
                                  className="personal-doc-delete"
                                  onClick={() => {
                                    this.setState({ selectedDocumentId: document.id }, () => {
                                      this.handleDeleteDocumentModal();
                                    });
                                  }}
                                >
                                  {t("Delete")}
                                </MenuItem>
                                <MenuItem
                                  className="personal-doc-share"
                                  onClick={() => {
                                    this.setState({ shareUrl: document.attributes.images[0].url }, () => {
                                      this.handleShareModal();
                                    });
                                  }}
                                >
                                  {t("Share")}
                                </MenuItem>
                              </Menu>
                            </div>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
                <div className="upload-button">
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Button onClick={() => this.handleAddDocumentModal()}>{t("Upload Document")}</Button>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.handleAddDocumentModal()}
          open={this.state.isAddDocumentModalOpen}
          className="add-document"
        >
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Add New Document")}
            </Typography>
            <IconButton className="person-add-doc-close-icon" onClick={() => this.handleAddDocumentModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: this.state.title,
              file: this.state.file,
            }}
            validationSchema={this.personalDocumentFormSchema}
            onSubmit={(values: any, { resetForm }) => {
              this.createPersonalDocument(values);
              resetForm();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate="yes" className="edit-property-form">
                  <DialogContent dividers>
                    <FormControl fullWidth>
                      <input
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="title"
                        placeholder={t("Title")}
                        className="dialog-input"
                        style={{
                          marginTop: "0",
                        }}
                      />
                      {ErrorHandler(t, touched.title, errors.title)}
                    </FormControl>
                    {values.file.length > 0 ? (
                      <Box className="modal-document-box">
                        <Box className="left-side-document">
                          <img src={Document} alt="pdf-img" />
                          <p>{values.file[0].name}</p>
                        </Box>
                        <Box className="right-side-document">
                          <CloseIcon className="close-icon" onClick={() => setFieldValue("file", [])} />
                        </Box>
                      </Box>
                    ) : (
                      <FormControl fullWidth>
                        <div
                          className="image-box"
                          onClick={() => {
                            this.upload.click();
                          }}
                        >
                          <img src={UploadImage} />
                          <Typography variant="body1">{t("Upload file")}</Typography>
                        </div>
                        <input
                          className="personal-input-file"
                          id="myInput"
                          type="file"
                          ref={(ref: any) => (this.upload = ref)}
                          style={{ display: "none" }}
                          onChange={(e: any) => setFieldValue("file", e.target.files)}
                          onBlur={handleBlur}
                          accept="application/pdf, .pdf"
                        />
                        {ErrorHandler(t, touched.file, errors.file)}
                      </FormControl>
                    )}
                  </DialogContent>
                  <DialogActions className="dialog-button-group">
                    <Button onClick={() => this.handleAddDocumentModal()} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Create")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          className="delete-document personal"
          fullWidth
          onClose={() => this.handleDeleteDocumentModal()}
          open={this.state.isDeleteDocumentModalOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={DeleteImage} alt="delete" />
              <Typography variant="h6" className="bold-text">
                {t("Delete uploaded")} {this.documentType(t)}
              </Typography>
              <Typography variant="body1">
                {t("Are you sure want to delete uploaded")} {this.documentType(t)}{" "}
                {t("from this app? Once deleted you won't be able to view deleted contract again.")}
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button onClick={() => this.deletePersonalDocument()}>{t("Yes Delete")}</Button>
                <Button onClick={() => this.handleDeleteDocumentModal()}>{t("No, Don't Delete")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
        />

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: "" })}
          message={t(this.state.error)}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(PersonalDocumentList));
// Customizable Area End
