import React from "react";
// Customizable Area Start
import {
    Box,
    Button, 
    Grid, Typography,
    Modal, Fade, Backdrop
} from "@material-ui/core";
//@ts-ignore
import DeleteIcon from '../../assets/delete.png'
//@ts-ignore
import CompEmpBuildingBlockStep6Controller from "./CompEmpBuildingBlockStep6Controller.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import { alphaNumericOnly, numbersOnly } from "../../../../components/src/KeyBlockFunctions";
import "../../assets/commonForm.css"
import * as Yup from 'yup';
// Customizable Area End

const validationSchema = Yup.object().shape({
    Details: Yup.string().required('Details are required'),
    sharedFacility: Yup.string().min(3, 'Facility name must be at least 3 characters')
    .max(50, 'Facility name must be at most 50 characters').required('Facility Name is required'),
    totalArea: Yup.string().required('Total Area is required'),
    fees: Yup.string().required('Reservation fees is required'),
    floorDocument: Yup.mixed().required('Please upload a Floor Document File'),
    images: Yup.array()
    .min(1, 'At least one image is required')
    .max(6, 'Maximum of 6 images allowed')
    .required('Please upload images')
  });

export default class CompEmpBuildingBlockStep6 extends CompEmpBuildingBlockStep6Controller {
    render() {
        // Customizable Area Start
        
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Formik
                        initialValues={this.props.facilityValues}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        data-testid="facilityFormik"
                        onSubmit={(values, actions) => {
                            this.props.handleSharedDetails(values)
                            actions.resetForm();
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms">
                                <Box>
                                    <Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box >
                                                <Typography className="List bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>List of Shared Area</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="Liststep" style={{ fontSize: '16px' }}>Step {this.props.stepBuilding + 1} of 7</Typography>
                                            </Box>
                                        </Box>
                                        {
                                            this.props.savedFacility.length ? <Grid container spacing={4} className="buildingBlockStep6" style={{
                                                marginTop:'15px'
                                            }}>
                                                {
                                                    this.props.savedFacility.length ? <>{this.props.savedFacility.map((building:any, i:number) => {
                                                        return <Grid className="buildingBlockStep6" item xs={4} key={i}><Box style={{
                                                            display:'flex',
                                                            justifyContent:'space-between',
                                                            border:'1px solid #E4E4E4',
                                                            borderRadius: '12px',
                                                            padding: '15px'
                                                        }} className="buildingBlockStep6">
                                                                <span style={{
                                                            color: '#181D25',
                                                            fontSize: '18px',
                                                            textTransform: 'capitalize'
                                                        }} className="buildingBlockStep6">{building.sharedFacility}</span><CloseIcon data-testid="openDeleteModal" onClick={() => this.props.handleOpenDeleteFacilityPopup(i)} style={{
                                                            cursor:'pointer'
                                                        }} />
                                                            </Box></Grid>
                                                    })}</> : <></>
                                                }
                                            </Grid> : <></>
                                        }
                                        <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                                            <Box style={{ marginTop: '20px', border: '1px solid #2B6FED', borderRadius: '8px' }}>
                                                <Box style={{ color: '#2B6FED', fontSize: '15px', fontWeight: 'bold', padding: '10px 25px', cursor: 'grabbing' }} onClick={this.props.sharedFacilityPopupBuilding} className="bold-text">+ ADD NEW SHARED AREA</Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box style={{
                                                zIndex: 9999,
                                                display: this.props.facilityPopupBuilding ? 'block' : 'none',
                                                position: 'fixed',
                                                top: 0,
                                                width: '100%',
                                                left: 0,
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            }}>
                                                <Box style={{
                                                    width: '50%',
                                                    backgroundColor: '#fff',
                                                    top: '50%',
                                                    borderRadius: '5px',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    height: '90%',
                                                    overflow: 'auto',
                                                    position: 'absolute',
                                                }}>
                                                    <Box>
                                                        <Box className="topBarName">
                                                            <Box style={{ fontSize: '22px', fontWeight: 'bold' }}><span className="bold-text">Add New Shared Area</span></Box>
                                                            <Box onClick={this.props.sharedFacilityPopupBuilding} style={{ cursor: "grabbing" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                    <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                </svg>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ borderBottom: '1px solid #e7e7e7' }}></Box>
                                                        <Box>
                                                            <Box style={{ padding: '25px' }}>
                                                                <Box style={{ width: '100%' }}>
                                                                    <Typography className="typographyLable Facility">Shared Facility's Name</Typography>
                                                                    <Box className="formGroups Facility" style={{ borderRadius: "8px", marginBottom: "0px", border: "1px solid #F0F0F0", backgroundColor: "#F0F0F0" }}>
                                                                        <Field name="sharedFacility" type="text" 
                                                                        onKeyDown={alphaNumericOnly}
                                                                        className="formInputs" placeholder="Shared Facility's Name" />
                                                                        <span className="frmLeftIcons Facility">
                                                                        </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="sharedFacility" component="div" data-testid="sharedFacility-error" /></Box>
                                                                </Box>

                                                                <Box style={{ marginTop: '20px' }} className="step6photos">
              <Typography className="typographyLable step6photos">Upload Photos</Typography>
              <Box style={{ display: 'flex' }} className="step6photos">
                {values.images.length < 6 && (
                  <>
                    <label id="label-file-upload" htmlFor="input-file-upload" style={{ marginRight: '23px', flexWrap: 'wrap' }} className="step6photos">
                                                <div className="step6photos">
                                                    <button className="upload-button step6photos" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="step6photos">
                                                        <path opacity="0.4" fill-rule="evenodd"
                                                        className="step6photos" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                    </svg></button>
                                                </div>
                                            </label>

                    <input type="file"
                            accept="image/*"
                            className="step6photos"
                      onChange={(event:any) => setFieldValue('images', [...values.images, ...event.currentTarget.files])}
                      style={{ display: 'none' }}
                      id="input-file-upload"
                      name="images"
                      data-testid="step2Images"
                      multiple
                    />
                  </>
                )}

                <div className="image-preview step6photos" style={{
                    display: 'flex'
                }}>
                  {values.images.map((image:any, index:number) => (
                    <div style={{
                        position:'relative'
                    }}>
                      <img
                        style={{
                            height: '104px',
                            width:'172px',
                            marginRight: '20px',
                            objectFit:'cover',
                            border: '1px solid #ececec'
                        }}
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index}`}
                        className="step6photos"
                      />
                      <HighlightOffIcon
                        className="faq_image_close_icon faq_image_close_icon2 step6photos"
                        style={{
                            right:'25px',
                            position:'absolute',
                            fill:'red',
                            top: '5px',
                            cursor:'pointer'
                        }}
                        data-testid="step6ImageRemove"
                        onClick={() => {
                          const newImages = [...values.images];
                          newImages.splice(index, 1);
                          setFieldValue('images', newImages);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Box>
              <Box style={webStyle.errorMsg}><ErrorMessage name="images" component="div" data-testid="images-error" /></Box>
            </Box>

                                                                <Box style={{ marginTop: '20px' }}>
                                                                    <Box style={{ width: '100%' }}>
                                                                        <Typography className="typographyLable">Details</Typography>
                                                                        <Box className="formGroups" style={{ border: "1px solid #F0F0F0", marginBottom: "0px", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                            <Field name="Details" type="address" as="textarea" placeholder="Details" className="formInputs1" />
                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="Details" component="div" data-testid="Details-error" /></Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ marginTop: '20px' }}>
                                                                    <Box style={{ width: '100%' }}>
                                                                        <Typography className="typographyLable TotalArea">Total Area</Typography>
                                                                        <Box className="formGroups TotalArea" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", backgroundColor: "#F0F0F0", borderRadius: "8px", }}>
                                                                            <Field type="text" 
                                                                            onKeyDown={numbersOnly}
                                                                            name="totalArea" placeholder="Total Area" className="formInputs totalArea" />
                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="totalArea" component="div" data-testid="totalArea-error" /></Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ marginTop: '20px' }}>
                                                                    <Box style={{ width: '100%' }}>
                                                                        <Typography className="typographyLable Reservationfees">Reservation fees (Per hour)</Typography>
                                                                        <Box className="formGroups Reservationfees" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                            <Field name="fees" type="text" 
                                                                            onKeyDown={numbersOnly}
                                                                            placeholder="SR" className="formInputs" />
                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="fees" component="div" data-testid="fees-error" /></Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ marginTop: '30px' }}>
              <Box style={{ width: '100%' }}>
                <Box>
                  <Typography className="typographyLable FloorPlan">Floor Plan</Typography>
                  <Box style={{ marginBottom: "0px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px", border: "1px solid #F0F0F0", borderRadius: "8px", }}>
                    <Box style={{ justifyContent: 'space-between', display: 'flex', }}>
                      <Box style={{ display: 'flex', flexWrap: "wrap", alignItems: 'center', }}>
                        {values.floorDocument && (
                          <Box style={{ border: "1px solid #F0F0F0", marginRight: '15px', borderRadius: "8px", background: "#FFF", display: 'flex', alignItems: 'center', padding: "13px 30px 13px 13px" }}>
                            {/* Display the file name */}
                            {values.floorDocument.name}
                            <Box style={{ paddingLeft: '22px' }} className="FloorPlan" data-test-id="handleRemoveFileFloorPlanComplex" onClick={() => setFieldValue('floorDocument', null)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                                        <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                                                    </svg>
                            </Box>
                          </Box>
                        )}
                        <Box style={{ color: '#8d8d8d', paddingLeft: "16px", fontWeight: 400, fontSize: '16px', }}>{values.floorDocument === null && "Upload PDF Document"}</Box>
                      </Box>
                      <label htmlFor="floorDocument" className="upload-button FloorPlan" style={{ margin: '8px 0px' }}>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path opacity="0.4" clip-rule="evenodd" fill-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                                                    </svg>
                                                                                    </label>
                                                                                    <input
                                                                                    data-testid="floorDocument" type="file" accept=".pdf, .doc, .docx"
                                                                                    id="floorDocument" onChange={(event:any) => setFieldValue('floorDocument', event.currentTarget.files[0])} style={{ display: 'none' }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={webStyle.errorMsg}><ErrorMessage name="floorDocument" component="div" data-testid="floorDocument-error" /></Box>
            </Box>
                                                                <Box style={{ marginTop: '15px', justifyContent: 'end', display: 'flex', }}>
                                                                    <Grid style={{ marginRight: '20px', marginTop: "30px", }}>
                                                                        <Button onClick={this.props.sharedFacilityPopupBuilding} className="buttonCancel CANCEL" ><span className="bold-text">CANCEL</span></Button>
                                                                    </Grid>
                                                                    <Grid style={{ marginTop: "30px", }}>
                                                                        <Button 
                                                                        type="submit"
                                                                        className="buttonSave SAVE"><span className="bold-text">SAVE</span></Button>
                                                                    </Grid>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
            </Form>
    )}
            </Formik>
            {this.props.multipleButton()}
            <Modal
                itemID="docDeleteCloseID"
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="modal compEmpComplexBuildingStep6Modal"
                  open={this.props.deleteFacilityPopup}
                  onClose={this.props.handleCloseDeleteFacilityPopup}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.props.deleteFacilityPopup}>
                    <div className="paper paper_Delete_Category compEmpComplexBuildingStep6Modal">
                      <div className="Delete_Category_Container compEmpComplexBuildingStep6Modal">
                        <img src={DeleteIcon} alt="Msg" />
                      </div>
                      <p className="Delete_Category_Heading bold-text compEmpComplexBuildingStep6Modal">Delete Shared Facility</p>
                      <p className="Delete_Category_Text compEmpComplexBuildingStep6Modal">
                        Are you sure you want to delete this shared facility?
                      </p>
                      <div className="Modal_Button_Container Delete_Category_Button_Div compEmpComplexBuildingStep6Modal">
                        <div
                          className="ModalCancelDiv Button_Size_Delete bold-text compEmpComplexBuildingStep6Modal"
                          onClick={this.props.handleCloseDeleteFacilityPopup}
                        >
                          NO, DON’t DELETE
                        </div>
                        <div className="ModalConfirmDiv Button_Size_Delete bold-text compEmpComplexBuildingStep6Modal"
                        data-testid="deleteFacility" 
                        onClick={() => this.props.handleRemoveFacilityDetails(this.props.deleteFacilityIndex)}
                        >
                          YES DELETE
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg:{
        color:'red',
        fontSize: '12px',
        marginTop:'5px'
      },
}
// Customizable Area End