export const dashBoard = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHeading: {
        fontWeight: 600,
        marginTop: 15,
        textTransform:"capitalize"
    },
    YearMain: {
        background: "#fff",
        border: "none",
        borderRadius: 5,
        padding: 7,
    },
    SideBar: {
        background: "#f9f6f6",
        position: "relative",
        paddingBottom: 150,
    },
    searchButton:{
        margin:8,
        border:"1px solid #f0f0f0",
        background:"white",
        width:"310px",
        paddingLeft:"0px",
        borderRadius:"5px",
    },
    backColor:{
        backgroundColor: "#2D6EED",
        color: "white",
        padding:"12px 16px",
        marginTop:"3px",
        textTransform:"capitalize",
    },
    backColorGA:{
        backgroundColor: "#2D6EED",
        color: "white",
        padding:"9px 16px",
        marginTop:"3px"
    },
    boxStyling:{
        display:"flex",
        alignItems:"center",
        marginTop:20
    },
    gaMemberMain:{
        display:"flex",
        alignItems:"baseline",
        marginTop:20,
        justifyContent:"space-between"
    },
    viewMore:{
        marginTop: 15,
        textDecoration:"underline",
        color:"#FC8434",
        fontWeight:600,
        textAlign: "right",
    },
    gaMemberCard:{
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr 3fr",
        gap: 20
    },
    relatedMemberCard:{
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr",
        gap: 20
    },
    profileImage:{
        borderRadius: "100%",
        width: 50,
        height: 50,
        margin: "20px auto"
    },
    userType:{
        backgroundColor: "aliceblue",
        borderRadius: 30,
        fontSize:"14px",
        display: "inline-block",
        padding: "3px 20px",
        color:"#2D6EED",
        fontWeight:600,
        textTransform:"capitalize"
    },
    unitno:{
        marginTop:15,
        fontWeight: 600,
        textAlign:"center"
    },
    contactIcon:{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginTop:15
    },
    cardStyle:{
        borderRadius:10,
        maxWidth:330
    },
    cursorPointer:{
        cursor:"pointer"
    }
};