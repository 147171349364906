//@ts-ignore
//@ts-nocheck
import * as React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Link,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import { CarBlue, Tick } from "./assets";
import { withRouter } from 'react-router';
import '../assets/css/style.scss';
import ManagerController from "./ManagerController.web";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class ManagerViewVeichle extends ManagerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { t }: any = this.props;
    // @ts-ignore
    const language = this.props.i18n.language;
    let item = JSON.parse(localStorage.getItem('selectCar'));

    return (
      <>
        <GeneralHeader>
              <Container id="viewVehicleDetails">
                <Box style={vehicleDetails.navigationVehicle} id="viewVehicleDetails">
                  <Box>
                    <Typography variant="body1" id="viewVehicleDetails">
                      {t("My Dashboard")} / <Link href="/DashboardGeneral">{t("General Dashboard")}</Link> / <Link href="/mv">{t("Vehicles")}</Link> / <Box component="span" style={{ color: "blue" }}>{t("Vehicle Details")}</Box>
                    </Typography>
                    <Typography id="viewVehicleDetails" variant="h5" className="bold-text" style={vehicleDetails.subHeadingVehicle}>{t("Vehicle Details")}</Typography>
                  </Box>
                </Box>
                <Grid container spacing={2} className="auth-container" style={{ backgroundColor: "#F7F9FE" }} id="viewVehicleDetails" >
                  <Grid item xs={12} md={12} className="auth-cols" style={{ justifyContent: 'unset', padding: 0 }} id="viewVehicleDetails" >
                    <div id="viewVehicleDetails" >
                      <Grid container id="viewVehicleDetails" >
                        <Grid xs={12} id="viewVehicleDetails" >
                          <div className="card" style={{ backgroundColor: "white" }} id="viewVehicleDetails" >
                            <div className="card-top" style={{ borderBottom: '1px solid #f4f6fb', padding: '1rem' }} id="viewVehicleDetails" >
                              <h4 className="bold-text" style={{ fontSize: "22px" }} id="viewVehicleDetailsTitle" >
                                {item.attributes.car_manufacturer.attributes.title}
                              </h4>
                              <div className="status" style={{ fontWeight: 600 }} id="viewVehicleDetailsTitle" >
                                {t(item.attributes.status)}
                              </div>
                            </div>
                            <div className="vehicle-details" id="viewVehicleDetails" >
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetails" >
                                <Typography className="bold-text" style={{ marginBottom: "30px", fontSize: "16px" }} id="viewVehicleDetails" >
                                  {t("Vehicle Details")}
                                </Typography>
                                <div style={{ display: 'flex', fontWeight: 500, alignItems: 'center' }} id="viewVehicleDetails" >
                                  <p>{t("Owner Name")}:</p><p style={{ marginLeft: 10}} className="bold-text" id="viewVehicleDetailsOwnerName" >
                                    {item.attributes.owner_name}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetails" >
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetailsBuildingName" >
                                <div style={{ display: 'flex', fontWeight: 500 }} id="viewVehicleDetailsBuildingName" >
                                  <p> {t("Building Name")}:</p>   <p style={{ marginLeft: 10 }} className="bold-text" id="viewVehicleDetailsBuildingName" >
                                    {item?.attributes.building_management?.name}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetailsBuildingName">
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetailsUnitNumber">
                                <div style={{ display: 'flex', fontWeight: 500 }} id="viewVehicleDetailsUnitNumber">
                                  <p> {t("Unit Number")}:</p>   <p style={{ marginLeft: 10}} className="bold-text" id="viewVehicleDetailsUnitNumber">
                                    {item.attributes.apartment_management?.apartment_name || 'N/A'}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetailsUnitNumber">
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetailsCarManufacturer">
                                <div style={{ display: 'flex', fontWeight: 500 }} id="viewVehicleDetailsCarManufacturer">
                                  <p> {t("Car Manufacturer")}:</p>
                                  <p style={{ marginLeft: 10 }} className="bold-text" id="viewVehicleDetailsCarManufacturer">
                                    {item.attributes.car_manufacturer.attributes.title}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetailsCarManufacturer">
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetailsCarModel">
                                <div style={{ display: 'flex', fontWeight: 500 }} id="viewVehicleDetailsCarModel">
                                  <p> {t("Car Model")}:</p>
                                  <p style={{ marginLeft: 10, fontWeight: 600 }} className="bold-text" id="viewVehicleDetailsCarModel">
                                    {item.attributes.car_model.title}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetailsCarModel">
                                </div>
                              </div>
                              <div style={{ borderBottom: '1px solid #f4f6fb', marginBottom: '0.5rem' }} id="viewVehicleDetailsCarColor" >
                                <div style={{ display: 'flex', fontWeight: 500 }} id="viewVehicleDetailsCarColor" >
                                  <p> {t("Car Color")}:</p>
                                  <p style={{ marginLeft: 10, fontWeight: 600 }} className="bold-text" id="viewVehicleDetailsCarColor">
                                    {item.attributes.car_color.title}
                                  </p>
                                </div>
                                <div style={{ marginLeft: 35, marginBottom: 10 }} id="viewVehicleDetailsCarColor">
                                </div>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #e4e4e4', borderRadius: 10, padding: '0.5rem 1rem 0.5rem 1rem' }} id="viewVehicleDetails">
                                <div style={{ display: 'flex', alignItems: 'center' }} id="viewVehicleDetails">
                                  <img src={CarBlue} id="viewVehicleDetails" /> <p className="bold-text" style={{ fontWeight: 600, whiteSpace: 'nowrap', marginLeft: 10 }} id="viewVehicleDetails">{t("Registration Card")}</p>
                                </div>
                                <Box className="row-btn customButton desktop-ui" id="viewVehicleDetails">
                                  <Button id="viewVehicleDetails" className="viewVehicleRegistration" variant="contained" style={{ width: 100, borderRadius: "8px", padding: "10px" }} onClick={() => this.setState({ showDialogPhoto: true })}>
                                    {t("View")}
                                  </Button>
                                </Box>
                              </div>
                            </div>
                          </div>
                          {
                              item.attributes.status === "Rejected" &&
                              <div id="viewVehicleDetails" className="card" style={{ backgroundColor: "white",padding:"15px" }} >
                                <Typography className="bold-text" style={{color:"red",fontSize:"16px"}} id="viewVehicleDetails">{t("Rejection Reason")}:</Typography>
                                <Typography style={{fontSize:"16px"}} id="viewVehicleDetails">{item.attributes.reject_note[item.attributes.reject_note?.length - 1]?.reject_note}</Typography>
                              </div>
                          }
                        </Grid>
                      </Grid>
                      {
                        item.attributes.status === "Pending Approval" &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '50px', marginTop: "50px" }} id="viewVehicleDetails">
                          <Box className="row-btn customButton desktop-ui" id="viewVehicleDetails">
                            <Button className="rejectVehicle view-vehicle-reject-btn" id="viewVehicleDetails" style={{ width: 150, height: 45, marginRight: 15, borderRadius: 8, border: '1px solid #2b6fed', color: "#2b6fed", boxShadow: '0px 6px 14px rgba(43,111,237,0.22)', background: "white" }} onClick={() => this.setState({ showDialogDelete: true })} variant='text' disabled={item.attributes.status == 'rejected'}>
                              {t("Reject")}
                            </Button>
                          </Box>
                          <Box id="viewVehicleDetails" className="row-btn customButton desktop-ui">
                            <Button variant="contained" className="approveVehicle view-vehicle-approve-btn" style={{ width: 150, height: 45, borderRadius: 8 }} onClick={() => this.setState({ showDialog: true })} disabled={item.attributes.status == 'approved'} >
                              {t("Approve")}
                            </Button>
                          </Box>
                        </div>
                      }
                    </div>
                  </Grid>
                </Grid>

              </Container>
        </GeneralHeader>

        <Dialog
          id="viewVehicleDetails"
          open={this.state.showDialogPhoto}
          onClose={() => this.setState({ showDialogPhoto: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="diloag-wrapper showDialogPhoto"
          PaperProps={{
            style: {
              borderRadius: '15px',
              padding: '2rem'
            },
          }}
          dir={languageCondition(language,"rtl","ltr")}
        >
          <img src={item.attributes?.registration_card_copy?.url} style={{ width: '600px', borderRadius: 0 }} id="viewVehicleDetails" />
          {/* <iframe src='https://yuppgg-68443-ruby.b68443.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa2NEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--670a8cdc5598c28e801317f826ac739e28a142c8/mohit.pdf' style={{ width: '600px', height: '56rem' }} /> */}
        </Dialog>

        <Dialog fullWidth className="add-meeting" open={this.state.showDialogDelete} dir={languageCondition(language,"rtl","ltr")} id="viewVehicleDetails" >
          <MuiDialogTitle disableTypography className="dialog-heading" id="viewVehicleDetails">
            <Typography variant="h6" className="bold-text" id="viewVehicleDetails">
              {t("Reject Vehicle Request")}
            </Typography>
            <IconButton className="closeRejectVehicle" onClick={() => this.setState({ showDialogDelete: false })} id="viewVehicleDetails">
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <TextareaAutosize
                id="viewVehicleDetails"
                className="reject-note"
                placeholder={t("Add Notes")}
                value={this.state.rejectNote}
                onChange={(e: any) => {
                  this.setState({
                    rejectNote: e.target.value,
                    rejectNoteError:""
                  });
                }}
              />
            </FormControl>
            <Typography color="error" variant="subtitle1" id="viewVehicleDetails">{this.state.rejectNoteError}</Typography>
          </DialogContent>
          <DialogActions className="dialog-button-group" id="viewVehicleDetails">
            <Button id="viewVehicleDetails" className="cancel-button" onClick={() => this.setState({ showDialogDelete: false })}>
              {t("Cancel")}
            </Button>
            <Button
              id="viewVehicleDetails"
              className="add-button"
              onClick={() => this.rejectRequestVehicle()}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          id="viewVehicleDetails"
          onClose={() => this.setState({ showDialog: false })}
          open={this.state.showDialog}
          className="cancel-meeting-dialog approvalDailog"
          dir={languageCondition(language,"rtl","ltr")}
        >
          <DialogContent style={{ margin: "15px 0" }} id="viewVehicleDetails">
            <Box textAlign="center" id="viewVehicleDetails">
              <img className="comment-image" src={Tick} alt="check" id="viewVehicleDetails" />
              <Typography variant="h6" className="bold-text" id="viewVehicleDetails">
                {t("Approve Vehicle Request")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0px" }} id="viewVehicleDetails">
                {t("Are you sure you want to approve vehicle request")}?
              </Typography>
              <br />
              <DialogActions className="dialog-button-group" id="viewVehicleDetails">
                <Button
                  id="viewVehicleDetails"
                  className="cancel-button cancelApprove"
                  style={{ width: "200px", height: "56px" }}
                  onClick={() => this.setState({ showDialog: false })}
                >
                  {t("Close")}
                </Button>
                <Button
                  id="viewVehicleDetails"
                  style={{ width: "200px", height: "56px" }}
                  className="add-button confirmApporve"
                  onClick={() => this.approveRequestVehicle()}
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>

    );

  }

}
export default withTranslation()(withRouter(ManagerViewVeichle));

const vehicleDetails = {
  navigationVehicle: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeadingVehicle: {
    fontWeight: 600,
    marginTop: 15,
  }
};


// Customizable Area End