// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography, MenuItem, Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import ManagementFeeController, { Props } from "./ManagementFeeController";
import "./style.css";
import Select from "@material-ui/core/Select";
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends ManagementFeeController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid item xs={12} dir={languageCondition(language, "rtl", "ltr")} md={12} className="auth-cols management-fee-block">
          <Grid container className="fee-payment-top-bar management-fee-navigation">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon
                  className={languageCondition(language, "KeyboardForwardIconAR", "back-icon-management-fee")}
                  onClick={() => {
                    this.props.history.push("/MyManagementFee/ComplexList");
                  }}
                />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {t("View Management Fee")}
                </p>
              </Box>
            </Grid>
          </Grid>

          <Box className="fee-payment-list management-fee-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="management-fee-header"
                >
                  <Typography variant={"body1"} className="bold-text">
                    {sessionStorage.getItem("complexName")}
                  </Typography>
                  <Select
                    disableUnderline
                    className="selectFYBox management-fee-year-sorting"
                    style={{ backgroundColor: "white", borderRadius: "100px" }}
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    value={this.state.budgetYear}
                    onChange={this.handleChange}
                  >
                    <MenuItem value={new Date().getFullYear() - 2} className="fee-prev-year">
                      {t("FY")} {new Date().getFullYear() - 2}-{(new Date().getFullYear() - 1).toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear() - 1} className="fee-prev-year">
                      {t("FY")} {new Date().getFullYear() - 1}-{new Date().getFullYear().toString().slice(-2)}
                    </MenuItem>
                    <MenuItem value={new Date().getFullYear()} className="fee-prev-year">
                      {t("FY")} {new Date().getFullYear()}-{(new Date().getFullYear() + 1).toString().slice(-2)}
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} className="management-fee-content-box">
                {this.state.getMyManagementList.length === 0 && (
                  <Box style={{ marginBottom: "10px" }}>
                    <Box style={{ backgroundColor: "white", borderRadius: "8px", padding: "10px" }}>
                      {t("No data available")}
                    </Box>
                  </Box>
                )}
                {this.state.getMyManagementList.map((item: any, key: any) => {
                  return (
                    <Box style={{ marginBottom: "20px" }}>
                      <Box style={{ backgroundColor: "#2b6fed", borderRadius: "8px 8px 0px 0px" }}>
                        <Grid container style={{ padding: "12px 15px" }}>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2" className="bold-text" style={{ color: "white" }}>
                              {t("Unit")}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" className="bold-text" style={{ color: "white" }}>
                              {t("Area")}{" "}{`(${t(item.attributes.area_sqft)})`}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              variant="subtitle2"
                              className="bold-text"
                              style={{ color: "white", textAlign: "right" }}
                            >
                              {t("Management Fee")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box style={{ borderRadius: "0px 0px 8px 8px" }} bgcolor="white">
                        <Grid container style={{ padding: "12px 15px" }}>
                          <Grid item xs={3}>
                            <Typography variant="subtitle2" color="textSecondary">
                              {item.attributes.unit_number}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" color="textSecondary">
                              {Number(item.attributes.my_unit).toLocaleString()}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="subtitle2" style={{ textAlign: "right" }} color="textSecondary">
                              {LanguageAttributeHandler(language, item.attributes.currency?.currency, item.attributes.currency?.currency_ar)}{" "}
                              {Number(item.attributes.yearly_management_fee).toLocaleString()}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                        <Box style={{ padding: "15px 15px 5px", display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="body2">{t("Yearly Management fee")}</Typography>
                          <Typography variant="body2" className="bold-text" style={{ fontSize: "16px" }}>
                            {LanguageAttributeHandler(language, item.attributes.currency?.currency, item.attributes.currency?.currency_ar)}{" "}
                            {Number(item.attributes.yearly_management_fee).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box style={{ padding: "5px 15px 15px", display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="body2">{t("Monthly Management Fee")}</Typography>
                          <Typography variant="body2" className="bold-text" style={{ fontSize: "16px" }}>
                            {LanguageAttributeHandler(language, item.attributes.currency?.currency, item.attributes.currency?.currency_ar)}{" "}
                            {Number(item.attributes.monthly_management_fee).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: this.state.note,
                  }}
                ></Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  padding="1rem"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Yearly Management fee")}</Typography>
                      <Typography variant="body2" className="bold-text">
                        {LanguageAttributeHandler(language, this.state.total?.currency?.currency, this.state.total?.currency?.currency_ar)}{" "}
                        {this.state.total ? Number(this.state.total.Total_yearly_management_fee).toLocaleString() : 0}
                      </Typography>
                    </Box>
                    <Box style={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Monthly Management Fee")}</Typography>
                      <Typography variant="body2" className="bold-text">
                        {LanguageAttributeHandler(language, this.state.total?.currency?.currency, this.state.total?.currency?.currency_ar)}{" "}
                        {this.state.total ? Number(this.state.total.Total_monthly_management_fee).toLocaleString() : 0}
                      </Typography>
                    </Box>
                    <Box style={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
                      <Typography variant="body2">{t("Total Area")}</Typography>
                      <Typography variant="body2" className="bold-text">
                        {this.state.total ? Number(this.state.total.Total_area).toLocaleString() : 0} {t(this.state.total?.area_sqft)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));
// Customizable Area End
