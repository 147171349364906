// Customizable Area Start
import React from "react";
import { Container, IconButton, Link, withStyles, Box, Grid, OutlinedInput, Select, MenuItem } from "@material-ui/core";
import { BackIcon, ComplexIcon, Document } from "./assets";
import { DocumentReportStyleWeb } from "./DocumentReportStyle.web";
import BuildingDocumentController, { Props } from "./BuildingDocumentsController.web";
import { withTranslation } from "react-i18next";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
import CategoryBox from "../../../components/src/DocumentComponent/CategoryBox.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class BuildingDocuments extends BuildingDocumentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} className={classes.buildingDocument} style={{ background: "#F7F9FE", height: "100vh" }}>
          <Grid container className="building-document-container">
            <Grid item xs={12} md={7}>
              <Box display={{ xs: "flex", md: "flex" }} className="menu">
                <IconButton onClick={() => this.handleGotoDashboard()}>
                  <img src={BackIcon} alt="" className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                </IconButton>{" "}
                <span className="bold-text">{t("Building Documents")}</span>
              </Box>
              <Container className="content-area document-box">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12} className={languageCondition(language, "arabic-grid", "")}>
                    <Box className="select-box change-selected-complex">
                      <Select
                        value={this.state.complexId}
                        displayEmpty
                        onChange={(e: any) => {
                          const complex = e.target.value;
                          sessionStorage.setItem("complexId", complex);
                          this.setState({ complexId: complex }, () => {
                            this.getDocumentCount();
                          });
                        }}
                        name="complex"
                        className="select-input document-complex-list"
                        input={<OutlinedInput />}
                        variant="filled"
                      >
                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                          {t("Complex")}
                        </MenuItem>
                        {this.state.complexList.map((complex: any) => {
                          return (
                            <MenuItem value={complex.id} key={complex.id} className={languageCondition(language, "select-arabic-menu", "soc-select-menu")}>
                              {complex.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <img src={ComplexIcon} alt="" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Link href="/BuildingDocuments/Policy">
                      <CategoryBox language={language} image={Document} heading={t("Policy")} value={this.state.policy} />
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Link href="/BuildingDocuments/Resolutions">
                      <CategoryBox language={language} image={Document} heading={t("Resolutions")} value={this.state.resolution} />
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Link href="/BuildingDocuments/Roles">
                      <CategoryBox language={language} image={Document} heading={t("Roles")} value={this.state.roles} />
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Link href="/BuildingDocuments/Guidelines">
                      <CategoryBox language={language} image={Document} heading={t("Guidelines")} value={this.state.guidelines} />
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Link href="/BuildingDocuments/Building-Plans">
                      <CategoryBox language={language} image={Document} heading={t("Building Plans")} value={this.state.buildingPlans} />
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(BuildingDocuments));
// Customizable Area End
