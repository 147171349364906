//@ts-ignore
//@ts-nocheck
import React from "react";
//components
import {Box, Button, Dialog, DialogActions, Grid, Link, Typography} from "@material-ui/core";

//resources
import {Bank_Icon, Building1, Building_Logo, Delete_Icon, Tenant_Logo} from "../src/assets";
import {withRouter} from 'react-router';
import EmailAccountLoginController, {Props} from "./EmailAccountLoginController.web";
import Loader from "../../../components/src/Loader.web";

class RegistrationRequest extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.getRegistrationRequest();
    // Customizable Area End
  }

  render() {
    const building_name = this.state?.registrationRequest?.attributes?.building_management?.name;
    const apartment_name = this.state?.registrationRequest?.attributes?.apartment_management?.data?.attributes?.apartment_name;
    return (
      <>
        <Box className="login-wrapper reg-wrapper" style={{backgroundColor:"white"}}>
          {/*<Box display={{ xs: 'flex', md: 'none' }} className="backIcon" onClick={() => window.history.back()}> <ArrowBackIcon onClick={() => window.history.back()} style={{ fontSize: "25px",marginLeft:"-7px"}} /></Box>*/}
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block" style={{height:"60vh"}}>
                <Box className="logo-block common-top-padding" display={{ xs: 'none', md: 'flex' }}>
                  <Link href="/EmailAccountLogin">
                    <img src={Building_Logo.default} className="head-logo" alt="" />
                    <h4>Building Name</h4>
                  </Link>
                </Box>
                <Box className="main-content-block">
                  <Box className="reg-content-block">
                    <Box className="header-block chairmanHeaderBlock">
                      <img src={Tenant_Logo.default} className="tenant-logo" alt="Tenant Logo" style={{marginBottom:"30px",marginTop:"20px"}} />
                      <h1 className="bold-text" style={{fontSize:"22px"}}>Registration Request<br></br>Under process</h1>
                      <h6 style={{fontSize:"16px"}} >Your registration request for {apartment_name} of<br></br>{building_name} is sent and under<br></br>process.You will receive notification<br></br>once it it processed.</h6>
                    </Box>
                    <Box className="reg-block">
                      <Box className="reg-row" style={{height:"60px"}}>
                        <img src={Bank_Icon} className="bank-logo" alt="Tenant Logo" style={{height:"32px"}}/>
                        <Box className="reg-right-block">
                          <h5 className="bold-text" style={{marginBottom:"3px"}}>{apartment_name || "No Unit Selected yet"}</h5>
                          <h6>{building_name || "No Building Selected yet"}</h6>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="footer-block">
                  <Box className="row-btn customButton desktop-ui familyFormButton ">
                    <Button variant="contained" className={"familyFormBottomButton"} onClick={() => { this.setState({ showDialog: true }) }} style={{marginBottom:"10px"}}>
                      Delete Registration REQUEST
                    </Button>
                    <Button className={"familyFormBottomButton"} onClick={() => this.clear()} variant='text'>
                      LOGOUT
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
          {/* <Box className="header-block">
            <img src={'#'} className="tenant-logo" alt="Lock_Icon" />
            <h1>Password Changed<br></br>Successfully!</h1>
            <h6>You have successfully changed your<br></br>password. Please use your new password when<br></br>logging in.</h6>
          </Box> */}

          <Dialog
            open={this.state.showDialog}
            onClose={() => this.setState({ showDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={{
              style: {
                borderRadius: '15px',
              },
            }}
          >
            <Box className="diloag-body">
              <Box className="diloag-header" style={{flexDirection:"column"}}>
                <img src={Delete_Icon} className="tenet-logo" alt="" />
                <Typography id="alert-dialog-title" className="bold-text" style={{textAlign:"center",fontSize:"22px"}}>
                  Sure you want to delete request?
                </Typography>
                <p>Are you sure that you want to delete the registration request for the unit ({apartment_name}) of {building_name}.</p>
              </Box>
              <Box className="dialog-footer desktop-ui">
                <DialogActions className="customButton">
                  <Button variant="contained" onClick={() => this.setState({ showDialog: false })}>
                    No, DON'T DELETE
                  </Button>
                  <Button onClick={() => this.deleteRequestById()} variant='text'>
                    YES DELETE
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
        </Box>

        < Loader loading={this.state.loading} />
      </>
    );
  }
}
export default withRouter(RegistrationRequest)

// Customizable Area End
