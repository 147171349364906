import React from "react"
// Customizable Area Start
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputAdornment,
    MenuItem,
    Popover,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
    withStyles, IconButton
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CEPollController, { Props } from './CEPollController.web';
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import {withRouter} from 'react-router-dom'
import Loader from "../../../../components/src/Loader.web";
import { Menu } from "@szhsin/react-menu";
// Customizable Area End

class CEPoll extends CEPollController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  iconComponent = (props: any) => (
    <ExpandMoreIcon style={webStyle.expandIcon} />
  );

  renderSelectMenu = (placeholder: string, name: string, menuProps: any, options: any, value: string, handleChange: any) => (
    <div className="formGroup">
    <FormControl variant="outlined" style={{ paddingTop: 0, paddingBottom: 0 }} size="small" fullWidth>
        <Select
            displayEmpty
            className="select-input cePollSurveySelect"
            value={value}
            style={{...webStyle.selectInputWrapper, fontWeight:'normal'}}
            id="PollSearch"
            MenuProps={menuProps}
            name={name}
            labelId="demo-simple-select-standard-label"
            IconComponent={this.iconComponent}
            data-test-id={name}
            onChange={handleChange}
        >
        <MenuItem disabled style={{ height: '36px' }} value=''>
            {placeholder}
        </MenuItem>
        <MenuItem value=' ' style={{ height: '36px' }}>All</MenuItem>
        {options.length > 0 && options.map((item: any) => <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>)}
        </Select>
    </FormControl>
    </div>

)

  renderSearchButton = () => (
    <div style={{ margin: "none" }}>
      <Button
        data-test-id="apply_search_filter"
        className="Myteam"
        color="primary"
        variant="contained"
        style={{
          height: "100%",
          borderRadius: "10px",
          backgroundColor: "#2B6FED",
          color: "#ffffff",
          fontSize: "16px",
          margin: "0px",
        }}
        onClick={this.handleSearch}
      >
        <SearchIcon />
        <Typography
          className="login-h1 bold-text"
          style={{ fontWeight: "bolder", color: "#ffffff" }}
        >
          Search
        </Typography>
      </Button>
    </div>
)
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { data }  = this.state
    const { classes } = this.props;
    const menuProps = {
      classes: {
          list: classes.menuList,
          paper: classes.menuPaper,
      },
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={{ padding: '40px', paddingRight: '40px' }}>
                <Typography style={{ fontSize: '16px',marginBottom:"10px" }}>
                    Activities / <Typography component="span" style={{ color: "rgb(43, 111, 237)"}}>Poll and surveys</Typography>
                </Typography>
                <Box className="Box12" style={webStyle.pageAction}>
                    <Box>
                        <Typography
                            style={{ fontSize: "32px" }} className="login-h1 bold-text">
                            Poll / Survey
                        </Typography>
                    </Box>
                </Box>
                <Box style={webStyle.selectWrapper}>
                    {this.renderSelectMenu("Select Company", "company", menuProps, this.state.companyList, this.state.company, this.handleCompanyChange)}
                    {this.renderSelectMenu("Select Country", "country", menuProps, this.state.countryList, this.state.country, this.handleCountryChange)}
                    {this.renderSelectMenu("Select City", "city", menuProps, this.state.cityList, this.state.city, this.handleCityChange)}
                    {this.renderSelectMenu("Select Complex", "complex", menuProps, this.state.complexList, this.state.complex, this.handleComplexChange)}
                    {
                        this.state.role === "coo" ?
                            this.renderSelectMenu("Select Sales Manager", "sales-manager", menuProps, this.state.salesManagerList, this.state.salesManager, this.handleSalesManagerChange)
                            :
                            this.renderSelectMenu("Select Account Manager", "sales-manager", menuProps, this.state.salesManagerList, this.state.accountManager, this.handleSalesManagerChange)
                    }
                    {this.renderSelectMenu("Sort By", "sort-by", menuProps, this.state.sortList, this.state.sortBy, this.handleSortByChange)}
                    {this.renderSearchButton()}
                </Box>
                <br />
                <br />
                <Box className="dashboard_card" p={3} style={{padding:"24px 0px"}}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            padding:"0px 24px"
                        }}
                    >
                        <Box>
                            <Typography
                                className="login-h1 bold-text"
                                style={{ fontSize: "22px" }}>
                                Poll/Survey Listing
                            </Typography>
                        </Box>
                        <Box>
                            <TextField
                                type="name"
                                className="compempemail MyteamTextField"
                                variant="outlined"
                                data-testid="searchvalue"
                                fullWidth
                                placeholder="Search By Complex Name"
                                name="search"
                                onChange={this.searchByComplex}
                                id="outlined-basic"
                                inputProps={{
                                    placeholder: "Search By Complex Name",
                                }}
                                InputProps={{
                                    style: {
                                        width: "360px",
                                        height: "48px",
                                        background: "white",
                                        border: "1px solid #f0f0f0",
                                        borderRadius: "8px",
                                        color: '#212121',
                                    },
                                    startAdornment: (
                                        <InputAdornment 
                                            position="start"
                                        >
                                            <SearchIcon style={{ color: "grey" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <br />
                    <Divider/>
                    <TableContainer style={{padding:"0px 24px",width:"95%"}}>
                        <Table className="table-box">
                            <TableHead>
                                <TableRow className="login-h1 bold-text">
                                    <TableCell className="login-h1 bold-text">Complex Name</TableCell>
                                    <TableCell className="login-h1 bold-text">
                                        Account Manager
                                    </TableCell>
                                    <TableCell className="login-h1 bold-text">
                                        Total Polls
                                    </TableCell>
                                    <TableCell className="login-h1 bold-text">
                                        Date of Last Poll
                                    </TableCell>
                                    <TableCell className="login-h1 bold-text">
                                        Total Survey
                                    </TableCell>
                                    <TableCell className="login-h1 bold-text">
                                        Date of Last Survey
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.length > 0 ? (
                                    this.state.data.map((item: any, index: any) => {
                                        const { attributes } = item;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell style={{width:"150px"}}>
                                                    {attributes.complexName}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.accountManager || "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.totalPoll}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.lastPollDate || "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.totalSurvey}
                                                </TableCell>
                                                <TableCell>
                                                    {attributes.lastSurveyDate}
                                                </TableCell>
                                                <TableCell>
                                                    <Menu
                                                        menuButton={
                                                            <IconButton>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                    >
                                                        <MenuItem
                                                            style={{border:"1px solid #f9f9f9",fontSize:"14px"}}
                                                            onClick={() => {
                                                                localStorage.setItem("society_id", attributes.id);
                                                                this.props.history.push("/CompanyEmployee/CEPollSurvey", {id: attributes.id,});
                                                            }}
                                                            data-test-id="view-details-btn"
                                                        >
                                                            See Details
                                                        </MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7} >
                                            No Record Found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box style={webStyle.tableFooterSection}>
                        <PaginationModule pagination={this.state.pagination} handlePagination={this.handleChangePage} page={this.state.page} />
                    </Box>
                </Box>
                <Loader loading={this.state.loading} />
            </Box>
      // Customizable Area End
    )
  }

}
// Customizable Area Start
const ActionSection = styled(Popover)({
    '& .MuiPaper-elevation8': {
      marginTop: '5px',
      boxShadow: 'rgb(87 148 201 / 5%) 0px 0px 2px 1px !important',
    }
})

const webStyle: any = {
    selectWrapper: {
        flexWrap: 'wrap',
        marginTop: '15px',
        display: 'flex',
        gap: '15px',
    },
    expandIcon: {
        color: 'rgb(177, 177, 177)'
    },
    tableFooterSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "30px 0px",
    },
    selectInputWrapper: {
        padding: "7px 7px 7px 12px",
        height: '48px',
        color: "#212121",
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        width: "170px",
        borderRadius: "8px"
    }
};

const styles: any = {
  menuTeamPaper: {
    maxHeight: '300px',
  },
  menuTeamList: {
    paddingRight: '0px !important'
  }
}

export default withTranslation()(withStyles(styles)(withRouter(CEPoll)))
//Add a style or MUI customized style
// Customizable Area End