import React from "react";
import {
  Box,
  Button,
  Grid
} from "@material-ui/core";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityReservationController, { Props } from "./FacilityReservationController.web";
import { Building1, FacilityCheck } from "../src/assets";
import { withTranslation } from "react-i18next";

class FacilityReservationReportedSuccessfully extends FacilityReservationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t }: any = this.props;

    //@ts-ignore
    const id = this.props.history?.location?.state?.id;
    //@ts-ignore
    const commonArea = this.props.history?.location?.state?.commonArea;
    
    if (!id) {
      //@ts-ignore
      this.props.history.push("/FacilityReservation");
      return null;
    }

    return (
      <>
        <Box className="login-wrapper incident-wrapper success-page">
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block">
                <Box className="main-content-block change-password-mainblock">
                  <Box className="header-block header-block-changepassword">
                    <img src={FacilityCheck} alt="Lock_Icon" />
                    <h3 className="bold-text">{t("Request Sent")}<br></br>{t("Successfully")}</h3>
                    <p style={{ textAlign: "center" }}>{t("Your request for")} {commonArea} {t("booking has been successfully sent to the building manager for his review and approval. Upon approval you will be notified by the system")}</p>
                  </Box>
                </Box>
                <Box className="footer-block desktop-ui success-facility-btn">
                  <Box className="row-btn customButton">
                    <Button variant="contained" onClick={() => {
                      //@ts-ignore
                      this.props.history.push("/FacilityReservation");
                    }}>{t("Okay")}</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(FacilityReservationReportedSuccessfully));
