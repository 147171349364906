// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputBase,
  Divider, Select, MenuItem,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SuggestionsController, { Props } from "./SuggestionsController.web";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import moment from "moment";

class Suggestions extends SuggestionsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getSuggtionListing();
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.reportList}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Community Management")} /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Suggestion")}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Box className="top-bar" style={{alignItems:"flex-start",height:"50px",marginTop:"15px"}}>
                  <Typography variant="h5" className="sub-heading bold-text" style={{marginTop:"5px"}}>
                    {t("Suggestion")}
                  </Typography>
                  <Box className="expense-right-heading top-bar" style={{alignItems:'flex-start'}}>
                    <Box className="filter">
                      <Select
                          style={{width:"130px"}}
                          disableUnderline
                          displayEmpty
                          className="select-input suggestionShortBy"
                          value={this.state.filter}
                          onChange={(e) => this.setState({ filter: e.target.value }, () => this.getSuggtionListing())}
                      >
                        <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}>{t("Sort By")}</MenuItem>
                        <MenuItem value="sent_by" className={languageCondition(language, "select-arabic-menu", "")}>{t("Sent By")}</MenuItem>
                        <MenuItem value="sent_date" className={languageCondition(language, "select-arabic-menu", "")}>{t("Sent Date")}</MenuItem>
                        <MenuItem value="unit_number" className={languageCondition(language, "select-arabic-menu", "")}>{t("Unit Number")}</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                </Box>
                <Box className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className="bold-text">{t("Suggestion")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search")}
                          className="search suggestionFilterSearch"
                          onChange={(e) => this.searchSuggestion(e.target.value)}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("#")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Suggestion Title")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Unit Number")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Sent By")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Date")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Status")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.suggestionList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={7}>{t("No Data Found")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.suggestionList.map((item: any, index: any) => (
                          <TableRow onClick={() => this.openSuggestion(item)} className="navigationSuggestion">
                            <TableCell align={languageCondition(language,"right","left")}>{index+1}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")} className="ellipse">{item.attributes.title}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")} className="textwrap" style={{maxWidth:"90px",overflow:"hidden"}}>{item.attributes.unit_number.toString()}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")}>{item.attributes.sent_by.name || 'N/A'}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")}>{moment(item.attributes.created_at).format("DD/MM/YYYY")}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")} style={{width:"110px",wordWrap:"normal"}}>
                              <span className={item?.attributes?.response ?"green-span":"red-span"}>{item?.attributes?.response ? item?.attributes?.response?.data.length:0} {t("Response")}</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}
// @ts-ignore
// @ts-nocheck
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(Suggestions)));
// Customizable Area End
