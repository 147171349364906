import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
    stepBuilding:any,
    multipleButton:any,
    coreMemberManager:any,
    sharedCoreMembers:any,
    coreMembers:any,
    sharedCreateNewMember:any,
    createNewMember:any,
    handleChangeBuilding:any,
    handleSave:any,
    handleNext:any,
    handleBack:any,
    userList:any,
    roleList:any,
    userValues:any,
    memberValues:any,
    savedMemberBuilding:any,
    handleMemberDetailsBuilding:any,
    handleRemoveMemberBuilding:any,
    deleteMemberPopupBuilding:any,
    handleCloseDeleteMemberPopupBuilding:any,
    handleOpenDeleteMemberPopupBuilding:any,
    deleteMemberIndexBuilding:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    handleBasicDetailsData:any;
    buildingList:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpBuildingBlockStep7Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    // Customizable Area End
}
