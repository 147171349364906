import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;

  t: (val: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  suggestionList:any
  loading:boolean
  error: string | null;
  reposne:any;
  filter:any;
  // Customizable Area End
}

interface SS {
  id: any;
  
}

 
export default class SuggestionsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSuggestionListingApiCallId: any;
  createSuggestionApiCall:any;
  updateSuggestionApiCall:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {loading:false,suggestionList:[],error:null,reposne:''
      ,filter:""};

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getSuggestionListingApiCallId) {
          this.getSuggestionListData(responseJson);
        }
        else if (apiRequestCallId === this.createSuggestionApiCall) {
          this.getResponseData(responseJson);
        }
    }

    // Customizable Area End
  }
}

  // Customizable Area Start
  getSuggestionListData(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ suggestionList: responseJson?.data });
      this.setState({ loading: false });
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({ loading: false, error: null });
  }
  getResponseData(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ loading: false });
      // @ts-ignore
      this.props.history.push('/Suggestions');
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({ loading: false, error: null });
  }

  getSuggtionListing = async () => {
    this.setState({ loading: true });

    this.getSuggestionListingApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_suggestion/suggestions?society_id=${localStorage.getItem('society_id')}&chairman=true&sort_type=${this.state.filter}`,
    });
  };

  openSuggestion(item: any) {
    localStorage.setItem('selectSuggestion', JSON.stringify(item));
    // @ts-ignore
    this.props.history.push('/SuggestionDetails');
  }

  addResponse = async (item: any) => {
    const httpBody = {
      "description": this.state.reposne,
      "suggestion_id": item?.id
    };

    this.createSuggestionApiCall = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `bx_block_suggestion/suggestion_responses`,
      body: JSON.stringify(httpBody)
    });
  };

  searchSuggestion = async (value: any) => {
    this.setState({ loading: true });

    this.getSuggestionListingApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_suggestion/suggestions?search_term=${value}&society_id=${localStorage.getItem('society_id')}&chairman=true`,
    });
  };
  // Customizable Area End
}
