// Customizable Area Start
import React from "react";
import {
  Box,
  Container,
  withStyles,
  Typography,
  Grid,
  Divider,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  MenuItem,
  TableContainer,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CEScheduleMeetingController, { Props } from "./CEScheduleMeetingController.web";
import Loader from "../../../../components/src/Loader.web";
import { Menu } from "@szhsin/react-menu";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import SearchIcon from "@material-ui/icons/Search";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import CEScheduleMeetingDialogWeb from "./CEScheduleMeetingDialog.web";
import FilterInput from "../../../../components/src/CommonComponents/FilterInput.web";
import { CommentIcon } from "../assets";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
// Customizable Area End

export class CEScheduleMeetingWrapper extends CEScheduleMeetingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getScheduleMeetingList();

    await this.getReactEstate();
    await this.getCountryListAPI();
    await this.getScheduleByList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      this.getScheduleMeetingList();
    }
  }

  handleIsOwnerMenu = (meeting: any, t: any) => {
    if (localStorage.getItem("role") === "coo" || meeting.attributes.created_by.created_by_user) {
      return (
        <>
          {meeting.attributes.status !== "completed" && (
            <>
              <MenuItem className="sm-edit-menu" onClick={() => this.handleOpenEditForm(meeting)}>
                {t("Edit")}
              </MenuItem>
              <MenuItem className="sm-cancel-menu" onClick={() => this.handleListCancelMeetingDialog(meeting)}>
                {t("Cancel")}
              </MenuItem>
            </>
          )}
        </>
      );
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <Container className={classes.scheduledMeeting}>
          <Box className="navigation schedule-meeting-navigation">
            <Box className="schedule-meeting-heading-box">
              <Typography variant="body1">
                {t("Activities")} /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Scheduled Meetings")}
                </Box>
              </Typography>
              <Typography variant="h5" className="sub-heading bold-text schedule-meeting-heading">
                {t("Scheduled Meetings")}
              </Typography>
            </Box>
            <Box className="create-meeting">
              <CommonButton t={t} onClick={this.handleOpenCreateForm} label="+ Create New Meeting" color="orange" />
            </Box>
          </Box>

          <Box className="top-bar-filter">
            <Box className="filter-head-box">
              <FilterSelect
                t={t}
                language={language}
                label="Select Company"
                value={this.state.filter.company}
                option={[{ label: "All", value: "all" }, ...this.state.reactStateList]}
                onChange={(e: any) => this.handleFilterChange(e, "company")}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.filter.isComplex}
                option={[
                  { label: "All", value: 0 },
                  { label: "Complex", value: 1 },
                  { label: "Individual Building", value: 2 },
                ]}
                onChange={(e: any) => this.handleFilterChange(e, "isComplex")}
                style={{ fontWeight: "bold", color: "black" }}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Select Country"
                value={this.state.filter.country}
                option={[{ label: "All", value: "all" }, ...this.state.countriesList]}
                onChange={(e: any) => this.handleFilterChange(e, "country")}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Select City"
                value={this.state.filter.city}
                option={[{ label: "All", value: "all" }, ...this.state.cityList]}
                onChange={(e: any) => this.handleFilterChange(e, "city")}
              />
              <FilterSelect
                t={t}
                language={language}
                label={this.state.filter.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
                value={this.state.filter.complex}
                option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
                onChange={(e: any) => this.handleFilterChange(e, "complex")}
              />
              {this.state.filter.isComplex !== 2 && (
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Building"
                  value={this.state.filter.building}
                  option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                  onChange={(e: any) => this.handleFilterChange(e, "building")}
                />
              )}
              <FilterInput
                t={t}
                language={language}
                label="Place"
                value={this.state.filter.place}
                onChange={(e: any) => this.handleFilterChange(e, "place")}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Select Status"
                value={this.state.filter.status}
                option={[
                  { label: "All", value: "all" },
                  { label: "Scheduled", value: "scheduled" },
                  { label: "Completed", value: "completed" },
                  { label: "Cancelled", value: "cancelled" },
                ]}
                onChange={(e: any) => this.handleFilterChange(e, "status")}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Scheduled By"
                value={this.state.filter.scheduleBy}
                option={[{ label: "All", value: "all" }, ...this.state.scheduleByList]}
                onChange={(e: any) => this.handleFilterChange(e, "scheduleBy")}
              />
              <Button className="action-search-btn" startIcon={<SearchIcon />} onClick={() => this.getScheduleMeetingList()}>
                {t("Search")}
              </Button>
            </Box>
          </Box>

          <Grid className="meeting-table">
            <Grid item sm={12} md={12} xs={12}>
              <Box className="table-top">
                <h4 className="bold-text">{t("Schedule Meetings")}</h4>
                <SearchInput
                  t={t}
                  language={language}
                  value={this.state.filter.search}
                  label="Search by title"
                  onChange={(e: any) => {
                    this.setState({ filter: { ...this.state.filter, search: e.target.value } }, () => this.getScheduleMeetingList());
                  }}
                />
              </Box>
              <Divider />
              <TableContainer>
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")} className="ce-meeting-list bold-text">
                        {t("#")}
                      </TableCell>
                      <TableCell className="ce-meeting-list  bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Title")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Date & Time")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Complex")}
                      </TableCell>
                      <TableCell className="ce-meeting-list  bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Building")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Place")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="ce-meeting-list bold-text">
                        {t("Agenda")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Status")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.meetingData.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={9} align={languageCondition(language, "right", "left")}>
                          {t("No Schedule Meeting Available!!")}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.meetingData.map((meeting: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={meeting.attributes.meeting_type === "ga_meeting" ? "ellipse" : "ellipse-one"}
                          >
                            <span className="ellipse-one" title={meeting.attributes.title}>
                              {meeting.attributes.title}
                            </span>
                            {meeting.attributes.meeting_type === "ga_meeting" && <span className="ga-meeting">{t("GA Meeting")}</span>}
                          </TableCell>
                          <TableCell
                            title={moment(meeting.attributes.meeting_date_time, "DD-MM-YYYY HH:mm").format("MMMM DD, YYYY HH:mm")}
                            className="ellipse-one"
                            align={languageCondition(language, "right", "left")}
                          >
                            {moment(meeting.attributes.meeting_date_time, "DD-MM-YYYY HH:mm").format("MMMM DD, YYYY HH:mm")}
                          </TableCell>
                          <TableCell
                            className="ellipse-one"
                            title={meeting.attributes.society_management || "All"}
                            align={languageCondition(language, "right", "left")}
                          >
                            {meeting.attributes.society_management || "All"}
                          </TableCell>
                          <TableCell
                            className="ellipse-one"
                            title={meeting.attributes?.building?.name}
                            align={languageCondition(language, "right", "left")}
                          >
                            {meeting.attributes?.building?.name || "All"}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                            {meeting.attributes.place}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                            {meeting.attributes.agenda}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            <span className={meeting.attributes.status}>{t(meeting.attributes.status)}</span>
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={LanguageAttributeHandler(language, "s-meeting-menu", "")}
                          >
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem>
                                <Link to={`/CompanyEmployee/CEScheduleMeetingDetails/${meeting.id}`}>{t("View")}</Link>
                              </MenuItem>
                              {this.handleIsOwnerMenu(meeting, t)}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <Box className="table-bottom">
                <p>
                  {t("Showing")} <span className="current-page">{this.state.meetingData.length}</span> {t("of")}{" "}
                  <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span> {t("results")}
                </p>
                {this.state.pagination && (
                  <Pagination
                    variant="outlined"
                    onChange={this.handlePagination}
                    siblingCount={2}
                    count={this.state.pagination.total_pages}
                    shape="rounded"
                    page={this.state.pagination.current_page}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>

        <CEScheduleMeetingDialogWeb
          {...this.props}
          {...this.state}
          isOpenForm={this.state.isFormDialogOpen}
          onFormClose={this.handleMeetingDialog}
          // Add-Edit
          isEditFormOpen={this.state.isEditFormOpen}
          // Form value
          formValue={this.state.formValue}
          // Id
          meetingId={this.state.scheduleMeetingId}
          onSubmitScheduleList={this.getScheduleMeetingList}
          onSubmitScheduleDetails={this.getMeetingDetailsById}
          page={this.state.page}
        />

        <AlertDialog
          Image={CommentIcon}
          IsOpen={this.state.isCancelMeetingDialogOpen}
          Header={t("Complete Meeting Confirmation")}
          CloseDialog={this.handleCancelMeetingDialog}
          Content={`<p>${t("Are you sure want to complete the meeting scheduled on")} <br/> ${t(
            moment(this.state.meetingDetails.scheduleOn, "DD-MM-YYYY HH:mm", true).format("MMMM DD, YYYY HH:mm")
          )} ${t("at")} ${t(this.state.meetingDetails.place)}${t(
            "? Once cancelled, attendees will receive a meeting cancelation notification."
          )}</p>`}
          AcceptText={t("Yes, Cancel")}
          DeclineText={t("No, Don't Cancel")}
          AcceptFun={this.handleCancelMeeting}
          DeclineFun={this.handleCancelMeetingDialog}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
class CEScheduleMeeting extends CEScheduleMeetingController {
  render() {
      return (
          <CEHeaderComponentWeb>
              <CEScheduleMeetingWrapper {...this.props}/>
          </CEHeaderComponentWeb>
      );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEScheduleMeeting)));
// Customizable Area End
