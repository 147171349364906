// Customizable Area Start
import React from "react";
import {
    Backdrop,
    Box,
    Button,
    Container, Dialog, DialogActions, DialogContent,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import AuditReportController, {Props} from "./AuditReportController.web";
import {Menu} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {withTranslation} from "react-i18next";
import {ROLE} from "../../../framework/src/Enum";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import {SearchIconImage, UploadLogo} from "./assets";
import {CloseButton, dashBoardActions, PublishButton} from "../../InvoiceBilling/src/chairmanUIStyles";
import CloseIcon from "@material-ui/icons/Close";
import {withRouter} from "react-router";
import PaginationModule from "./PaginationModule.web";
import {CheckIcon} from "../../user-profile-basic/src/assets";
// @ts-ignore
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class AuditReport extends AuditReportController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {t, classes, i18n}: any = this.props;
        const {language} = i18n

        return (
            <GeneralHeader>
                <Container className={classes.reportList}>
                    <Box className="navigation">
                        <Box>
                            {this.state.userType === ROLE.AUDITOR ? (
                                <Typography variant="body1" className="auditReportAuditor">
                                    {t("Reports")} /{" "}
                                    <Box component="span" style={{color: "blue"}} className="auditReportsAuditor">
                                        {t("Audit Reports")}
                                    </Box>
                                </Typography>
                            ) : (
                                <Typography variant="body1" className="auditReportMain">
                                    {t("Documents & Reports")} /{" "}
                                    <Box
                                        className="report-ExpenseReport AuditReport AuditReportBreadcrumb auditAlignTop"
                                        component="span"
                                        onClick={() => this.props.history.push("/Reports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {" "}
                                        {t("Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" style={{color: "blue"}} className="auditReportsMain">
                                        {t("Audit Reports")}
                                    </Box>
                                </Typography>
                            )}
                            <Typography variant="h5" className="sub-heading bold-text AuditReportHeading"
                                        style={{fontSize: "28px"}}>
                                {t("Audit Reports")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{alignItems: "flex-start"}}
                         className={languageCondition(language, "arabic-grid top-bar", "top-bar")}>
                        <Box className="filter">
                            {this.state.userType === ROLE.MANAGER ||
                            (this.state.userType === ROLE.AUDITOR && !this.props?.match?.params?.id) ? (
                                <Select
                                    displayEmpty
                                    value={this.state.selectedBuilding}
                                    className="select-input auditReportBuildingListSelect AnnouncmenentCateogry"
                                    id="auditReportBuildingListSelect" onChange={(e) => this.setState({selectedBuilding: e.target.value,year:"",status:""})}
                                >
                                    <MenuItem value="" disabled id="selectBuildingAuditReport"
                                              className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Building")}</MenuItem>
                                    <MenuItem value=" " id="selectBuildingAuditReportAll"
                                              className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                                    {this.state.buildingList?.map((item: any, key: any) => {
                                        return (
                                            <MenuItem key={key} value={item.id}
                                                      className={languageCondition(language, "select-arabic-menu", "")}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            ) : null}
                            <Select
                                displayEmpty
                                className="select-input auditReportYearListSelect auditAlignTopSelect"
                                value={this.state.year} onChange={(e) => this.setState({year: e.target.value,status:""})}
                            >
                                <MenuItem value="" disabled id="selectYearAuditReport"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Year")}</MenuItem>
                                <MenuItem value=" " id="selectYearAllAuditReport"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                                <MenuItem value={new Date().getFullYear() - 3}
                                          className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 3}</MenuItem>
                                <MenuItem value={new Date().getFullYear() - 2}
                                          className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 2}</MenuItem>
                                <MenuItem value={new Date().getFullYear() - 1}
                                          className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 1}</MenuItem>
                                <MenuItem value={new Date().getFullYear()}
                                          className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear()}</MenuItem>
                                <MenuItem value={new Date().getFullYear() + 1}
                                          className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() + 1}</MenuItem>
                            </Select>
                            <Select
                                displayEmpty
                                className="select-input auditReportStatusListSelect auditAlignTopSelect"
                                value={this.state.status} onChange={(e) => this.setState({status: e.target.value})}>
                                <MenuItem value="" disabled id="selectStatusAuditReport"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Status")}</MenuItem>
                                <MenuItem value=" " id="selectStatusAuditReportAll"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                                <MenuItem value="Pending"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("Pending")}</MenuItem>
                                <MenuItem value="Approved"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("Approved")}</MenuItem>
                                <MenuItem value="Rejected"
                                          className={languageCondition(language, "select-arabic-menu", "")}>{t("Rejected")}</MenuItem>
                            </Select>
                            <Button
                                startIcon={<img src={SearchIconImage} />}
                                onClick={this.manageSearch}
                                className={"auditReportSearchButton"}
                            >
                                {t("Search")}
                            </Button>
                        </Box>
                        <Box className="expense-right-heading">
                            {this.state.userType === ROLE.AUDITOR && (
                                <Box className="create-meeting">
                                    <Button className="createAuditReportButton" onClick={() => {
                                        this.setState({auditReportModal: true})
                                    }}>
                                        {t("Upload Audit Report")}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Grid className="meeting-table">
                        <Grid item sm={12} md={12} xs={12}>
                            <Box className="table-top">
                                <h4 className="bold-text">{t("Audit Reports")}</h4>
                                <div className="search-box">
                                    <SearchIcon/>
                                    <InputBase
                                        placeholder={t("Search")}
                                        className="search searchInput auditAlignSearchText"
                                        value={this.state.searchName}
                                        onChange={(e) => this.setState({searchName: e.target.value}, () => this.manageSearch())}
                                    />
                                </div>
                            </Box>
                            <Divider/>
                            <Table className="table-box">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                                        <TableCell
                                            align={languageCondition(language, "right", "left")}>{t("Year")}</TableCell>
                                        <TableCell
                                            align={languageCondition(language, "right", "left")}>{t("Report Generated On")}</TableCell>
                                        <TableCell
                                            align={languageCondition(language, "right", "left")}>{t("Building Name")}</TableCell>
                                        <TableCell
                                            align={languageCondition(language, "right", "left")}>{t("Status")}</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.auditReportList.length > 0 ? (
                                        this.state.auditReportList.map((item: any, key: any) => {
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell
                                                        align={languageCondition(language, "right", "left")}>{key + 1}</TableCell>
                                                    <TableCell
                                                        align={languageCondition(language, "right", "left")}>{item.attributes.year}</TableCell>
                                                    <TableCell
                                                        align={languageCondition(language, "right", "left")}>{item.attributes.report_generated_on}</TableCell>
                                                    <TableCell
                                                        align={languageCondition(language, "right", "left")}>{item.attributes.Building_Name}</TableCell>
                                                    <TableCell align={languageCondition(language, "right", "left")}>
                                                        {item?.attributes?.status === "Pending" &&
                                                            <Typography className="pending"
                                                                        id="auditReportApprovalPending" style={{
                                                                width: "120px",
                                                                fontSize: "12px",
                                                                textAlign: "center",
                                                                padding: "5px",
                                                                borderRadius: "50px"
                                                            }}>{t("Pending Approval")}</Typography>}
                                                        {item?.attributes?.status === "Approved" && (
                                                            <Typography className="approved"
                                                                        id="auditReportApprovalApproved" style={{
                                                                width: "120px",
                                                                fontSize: "12px",
                                                                textAlign: "center",
                                                                padding: "5px",
                                                                borderRadius: "50px"
                                                            }}>{t("Approved")}</Typography>
                                                        )}
                                                        {item?.attributes?.status === "Rejected" && (
                                                            <Typography className="cancelled"
                                                                        id="auditReportApprovalRejected" style={{
                                                                width: "120px",
                                                                fontSize: "12px",
                                                                textAlign: "center",
                                                                padding: "5px",
                                                                borderRadius: "50px"
                                                            }}>{t("Rejected")}</Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="invoice-receipts-menu"
                                                               align={languageCondition(language, "right", "left")}>
                                                        <Menu
                                                            menuButton={
                                                                <IconButton>
                                                                    <MoreVertIcon/>
                                                                </IconButton>
                                                            }
                                                            className={"auditReportMoreButton"}
                                                        >
                                                            <MenuItem
                                                                onClick={() => this.props.history.push(`/AuditReportDetails/${item.id}`)}>
                                                                {t("View")}
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => window.open(item.attributes.pdf_url[0].download_url, "_blank")}>{t("Download")}</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell align={languageCondition(language, "right", "left")}
                                                       colSpan={6}>{t("No Audit Reports Available")}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <Divider/>
                            <Box className="table-bottom">
                                <PaginationModule
                                    handlePagination={this.handleAuditReportPagination}
                                    pagination={this.state.pagination}
                                    page={this.state.page}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Modal
                    style={dashBoardActions.modal}
                    open={this.state.auditReportModal} onClose={() => this.setState({auditReportModal: false})}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    className={"addAuditReportModal"}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.auditReportModal}>
                        <div style={dashBoardActions.expensePaper} className="PaperForAddAuditReport" dir={languageCondition(language, "rtl", "ltr")}>
                            <div style={dashBoardActions.expenseModalHeader} className="HeaderForAddAuditReport">
                                <Typography variant="h6" className="bold-text UploadAuditReportText" style={dashBoardActions.subHeadingFont}>
                                    {t("Upload Audit Report")}
                                </Typography>
                                <IconButton onClick={() => this.setState({auditReportModal: false})} className="IconButtonForAddAuditReport auditModalCloseButton">
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                            <Divider/>
                            <Grid container spacing={2} style={{marginTop: "10px"}}
                                  className={languageCondition(language, "arabic-grid MainGridForAddAuditReport", "MainGridForAddAuditReport")}>
                                <Grid item xs={12} className="GridSelectYearsForAddAuditReport">
                                    <Select
                                        variant="outlined" displayEmpty fullWidth
                                        className="select-input SelectYearForAddAuditReport"
                                        value={this.state.auditTitle}
                                        onChange={(e) => this.setState({
                                        auditTitle: e.target.value,
                                        auditTitleError: ""
                                    })}
                                        style={{backgroundColor: "#F9F9F9", borderRadius: "10px"}}
                                    >
                                        <MenuItem value="" disabled
                                                  className={languageCondition(language, "select-arabic-menu Year", "Year")}>
                                            {t("Select Year")}
                                        </MenuItem>
                                        <MenuItem
                                            className={languageCondition(language, "select-arabic-menu Year", "Year")}
                                            value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</MenuItem>
                                        <MenuItem
                                            className={languageCondition(language, "select-arabic-menu Year", "Year")}
                                            value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</MenuItem>
                                        <MenuItem
                                            className={languageCondition(language, "select-arabic-menu Year", "Year")}
                                            value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                                        <MenuItem
                                            className={languageCondition(language, "select-arabic-menu Year", "Year")}
                                            value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                                        <MenuItem
                                            className={languageCondition(language, "select-arabic-menu Year", "Year")}
                                            value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                                    </Select>
                                    <p style={{color: "red"}}>{this.state.auditBuildingError}</p>
                                </Grid>
                                <Grid item xs={12} className="FileUploadForAddAuditReport">
                                    <Select
                                        variant="outlined"
                                        displayEmpty fullWidth
                                        className="select-input SelectBuildingForAddAuditReport"
                                        value={this.state.auditBuilding}
                                        onChange={(e) => this.setState({auditBuilding: e.target.value,auditBuildingError:""})}
                                        style={{backgroundColor: "#F9F9F9", borderRadius: "10px"}}
                                    >
                                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu SelectBuilding", "SelectBuilding")}>
                                            {t("Select Building")}
                                        </MenuItem>
                                        {this.state.buildingList?.map((item: any, key: any) => {
                                            return (
                                                <MenuItem key={item.id} value={item.id}
                                                          className={languageCondition(language, "select-arabic-menu SelectBuilding", "SelectBuilding")}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <p style={{color: "red"}}>{this.state.auditBuildingError}</p>
                                </Grid>
                                <Grid item xs={12} className="FileUploadAuditorAddAudit">
                                    <div
                                        className="FileUploadAuditorAddAudit2"
                                        onClick={() => {
                                            if (!this.state.selectedFile?.name) {
                                                this.upload.click();
                                            }
                                        }}
                                        style={{
                                            backgroundColor: "#F9F9F9",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "180px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        {this.state.selectedFile?.name ? (
                                            <div
                                                className="FileUploadAuditorAddAuditdiv1"
                                                style={{
                                                    position: "relative",
                                                    backgroundColor: "white",
                                                    height: "140px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    borderRadius: "10px",
                                                    width: "95%",
                                                }}
                                            >
                                                <Typography variant="body1" style={{marginLeft: "10px"}}>
                                                    {this.state.selectedFile.name}
                                                </Typography>
                                                <IconButton
                                                    style={{position: "absolute", right: "0px", top: "0px"}}
                                                    onClick={() => this.setState({selectedFile: {}})}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <Box
                                                className="FileUploadAuditorAddAudit3"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    marginTop: "5px"
                                                }}
                                            >
                                                <img src={UploadLogo}
                                                     style={{marginRight: "10px", height: "20px", width: "20px"}}/>
                                                <Typography variant="body1" color="textSecondary"
                                                            style={{marginLeft: "10px"}}>
                                                    {t("Upload Audit Document")}
                                                </Typography>
                                            </Box>
                                        )}
                                    </div>
                                    <input
                                        id="myInput"
                                        className="FileUploadAuditorAddAuditInput"
                                        type="file"
                                        ref={(ref: any) => (this.upload = ref)}
                                        style={{display: "none"}}
                                        accept=".docx, .pdf, .doc, .xlsx"
                                        onChange={(e: any) => {
                                            this.setState({
                                                selectedFile: e.currentTarget.files[0],
                                                fileUploadError: ""
                                            });
                                        }}
                                        name="document"
                                    />
                                    <p style={{color: "red"}}>{this.state.fileUploadError}</p>
                                </Grid>
                                <Grid item xs={12} className="FileUploadAuditorGridAddAuditCommentGrid">
                                    <TextField
                                        label={t("Comment")}
                                        className={languageCondition(language, "placeHolderAlignmentSpace", "FileUploadAuditorAddAuditComment")}
                                        variant="outlined"
                                        name="Comment" fullWidth multiline
                                        value={this.state.comment} onChange={(e) => this.setState({comment: e.target.value, descriptionError: ""})}
                                        rows={7}
                                        inputProps={{maxLength: 50}}
                                        style={{backgroundColor: "#F9F9F9", borderRadius: "10px"}}
                                    />
                                    <p style={{color: "red"}}>{this.state.descriptionError}</p>
                                </Grid>
                                <Grid
                                    className="FileUploadAuditorAddEndPart"
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "20px",
                                        marginTop: "5px"
                                    }}
                                >
                                    <PublishButton className="FileUploadAuditorAddPublishButton"
                                                   style={{marginRight: "10px", height: "40px"}}
                                                   onClick={() => this.setState({auditReportModal: false})}>
                                        {t("Cancel")}
                                    </PublishButton>
                                    <CloseButton className="FileUploadAuditorAddUploadButton"
                                                 style={{marginRight: "10px", height: "40px"}}
                                                 onClick={this.handleValidationAuditor}>
                                        {t("Upload Report")}
                                    </CloseButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <Dialog
                    fullWidth
                    onClose={() => this.setState({showSuccess: false})}
                    open={this.state.showSuccess}
                    className="cancel-meeting-dialog AuditReportSuccessDialog"
                >
                    <DialogContent style={{margin: "30px 50px"}} id="AuditReportSuccessDialogContent">
                        <Box textAlign="center" id="AuditReportSuccessDialogContentBox">
                            <img className="comment-image" src={CheckIcon} alt="check"
                                 id="AuditReportSuccessDialogContentImg"/>
                            <Typography className="bold-text" id="AuditReportSuccessDialogContentBoxTypo1" variant="h6"
                                        style={{fontSize: "22px"}}>{t("Audit Report Uploaded Successfully")}</Typography>
                            <Typography id="AuditReportSuccessDialogContentBoxTypo2" variant="body1"
                                        style={{marginBottom: "0px", color: "#181d25", fontSize: "16px"}}>
                                {t("Your report status will be pending approval until it gets approved by chairman.")}
                            </Typography>
                            <DialogActions id="AuditReportSuccessDialogContentBoxTypo4" className="dialog-button-group"
                                           style={{marginTop: "15px"}}>
                                <Button className="cancel-button" id="AuditReportSuccessButton"
                                        style={{width: "400px", fontSize: "16px", height: "55px", marginRight: "10px"}}
                                        onClick={() => this.setState({showSuccess: false})}>{t("Close")}</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
            </GeneralHeader>
        );
    }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(AuditReport)));
// Customizable Area End
