export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Building1 = require("../assets/building1.png");
export const Tenant_Logo = require("../assets/TenantLogo.png");
export const Search_Icon = require("../assets/search-icon.svg");
export const Setting_Icon = require("../assets/setting-icon.svg");
export const Building_Icon = require("../assets/building-icon.svg");
export const Chat_Icon = require("../assets/chat-icon.svg");
export const Cancel_Icon = require("../assets/cancel_icon.svg");
export const Chat_Disable_Icon = require("../assets/chat-disable-icon.svg");
export const Contact_Icon = require("../assets/contact-icon.png");
export const Contact_Disable_Icon = require("../assets/contact-disable-icon.svg");
export const Email_Msg_Icon = require("../assets/email-msg-icon.png");
export const Email_Disable_Icon = require("../assets/email-disable-icon.svg");
export const Box_Icon = require("../assets/box-icon.svg");
export const User1_Img = require("../assets/user1.png");
export const User2_Img = require("../assets/user2.png");
export const User3_Img = require("../assets/user3.png");
export const FB_Icon = require("../assets/facebook-icon.png");
export const Twitter_Icon = require("../assets/twitter-icon.png");
export const Instagram_Icon = require("../assets/instagram-icon.png");
export const Snapchat_Icon = require("../assets/snapchat-icon.png");
export const NoProfile_Img = require("../assets/no-profile.png");

