import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
import {apiCallWithToken as apiCall} from "../../../../components/src/APICallComponent/index.web";
import moment from "moment";
import {downloadFileFromURL} from "../../../../components/src/APICallComponent/FileDownload.web";
// @ts-ignore
import {baseURL} from '../../../../framework/src/config.js';
// Customizable Area End


export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    location: any;
    match: any;
    classes?: any;
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    id: any;
    type: string;
    role: any;
    name: string;
    reportGeneratedOn: string;
    reportGeneratedBy: string;
    description: string;
    que: string;
    reportCycle: string;
    response: {
        yes: any;
        no: any;
        awaited?: any;
        responseReceived?: any;
    },
    detail: {
        country: string;
        city: string;
        complex: string;
        building: string;
        startDate: string;
        endDate: string;
    }
    data: any;
    page: number;
    pagination: {
        total_count: number;
        total_pages: number;
        current_page: number;
    };
    responseModal: boolean;
    responseModalData:any[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CEPollSurveyReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    classes: any;
    apiCallId: string = "";
    pollReportID: string = "";
    surveyReportID: string = "";
    surveyReportDetailsID:string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            id: 0,
            type: 'Poll',
            role: '',
            name: "-",
            reportCycle: "-",
            reportGeneratedOn: '-',
            reportGeneratedBy: '-',
            que: '-',
            description: '-',
            page: 0,
            pagination:{
                total_pages:1,
                current_page:1,
                total_count:1,
            },
            response: {
                yes: '0',
                no: '0',
                awaited: '0',
                responseReceived: '0'
            },
            detail: {
                country: '-',
                city: '-',
                complex: '-',
                building: '-',
                startDate: '-',
                endDate: '-'
            },
            data: [],
            responseModal: false,
            responseModalData:[
                {
                    "survey_id": 21,
                    "survey_name": "Create a Survey",
                    "survey_question_type": "short_answers",
                    "survey_question": "Create a Survey",
                    "survey_question_id": 23,
                    "concern": "new survey  ",
                    "survey_options": [],
                    "response": []
                }
            ]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        // Customizable Area Start
        const userRole =localStorage.getItem('role');
        this.getDataIdAndType();
        this.setState({
            role: userRole 
        })
        // Customizable Area End
    }

    // Customizable Area Start

    handleCloseResponseModal = () => {
        this.setState({
            responseModal:false
        })
    }

    getDataIdAndType = () => {
        let { location }=this.props.navigation.history;
        if( location.state && location.state.id ){
            this.setState({
                id: location.state.id,
                type: location.state.type
            })
            if(location.state.type === "Polls"){
                this.getPollReport(location.state.id)
            }else{
                this.getSurveyReport(location.state.id)
                this.getSurveyReportDetails(location.state.id)
            }
        }
    }

    manageDownload = () => {
        const socityId = localStorage.getItem('society_id');
        if(this.state.type === "Polls"){
            downloadFileFromURL(`${baseURL}/society_managements/${socityId}/bx_block_polling/polls/${this.state.id}/download_report`,`PollReport-${this.state.id}.pdf`)
        }else{
            downloadFileFromURL(`${baseURL}/society_managements/${socityId}/bx_block_survey/surveys/${this.state.id}/download_report`,`SurveyReport-${this.state.id}.pdf`)
        }
    }

    getPollReport = async(id:any)=>{
        const societyId = localStorage.getItem('society_id');
        this.pollReportID = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyId}/bx_block_polling/polls/${id}/create_generate_report`
        })
    }

    getSurveyReport = async(id:any)=>{
        const societyId = localStorage.getItem('society_id');
        this.surveyReportID = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyId}/bx_block_survey/surveys/${id}/create_generate_report`
        })
    }

    getSurveyReportDetails = async(id:any)=>{
        const societyId = localStorage.getItem('society_id');
        this.surveyReportDetailsID = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyId}/bx_block_survey/surveys/${id}`
        })
    }
    navigateToPollSurveyDetails = () => this.props.history.push('/CompanyEmployee/CEPollSurveyDetails', { type: this.state.type, id: this.state.id })

    navigateToPollSurvey = () => this.props.history.push('/CompanyEmployee/CEPoll');

    handleChangePage =  () => {
        this.setState({ page: this.state.page + 1 })
    }
    
    // Add function which you required
    checkDetailsAPICallResponse(apiRequestCallId:string, responseJson:any){
        if(apiRequestCallId === this.pollReportID){
            if(responseJson.hasOwnProperty("report")){
                let pollResponseList = responseJson.report.data.map((item:any, index:any)=>{
                  return(
                      {
                          id:item.id,
                          attributes:{
                              name:item.attributes.name_and_option.data.attributes.full_name,
                              unitNumber:item.attributes.name_and_option.data.attributes.unit_number.join(", "),
                              Response:item.attributes.name_and_option.data.attributes.option,
                          }
                      }
                  )
                })
                this.setState({
                    name: responseJson.poll.title,
                    reportGeneratedOn:moment().format('MMM DD, YYYY hh:mm A'),
                    reportGeneratedBy:responseJson.poll.account_name || "-",
                    detail: {
                        country: responseJson.poll.country || "-",
                        city: responseJson.poll.city || "-",
                        complex: responseJson.poll.complex_name.join(","),
                        building: responseJson.poll.building_name.join(", "),
                        endDate: responseJson.poll.start_date,
                        startDate: responseJson.poll.end_date
                    },
                    description: responseJson.poll.description,
                    que: responseJson.poll.question,
                    response:{
                        yes:1,
                        no:1,
                        awaited:responseJson.poll.awaited,
                        responseReceived:responseJson.poll.completed_answers,
                    },
                    data:pollResponseList
                })
            }
        }
        if(apiRequestCallId === this.surveyReportID){
            const surveyResponseList = responseJson.report.data.attributes.name_and_option.responses.map((item:any)=>{
                return(
                    {
                        id:item.id,
                        attributes:{
                            name:item.name,
                            unitNumber:item.unit_number?.join(", "),
                            Response:item.response_details
                        }
                    }
                )
            })
            this.setState({
                // que: responseJson.poll.question,
                data:surveyResponseList
            })
        }
        if(apiRequestCallId === this.surveyReportDetailsID){
            const surveyDetails = responseJson.surveys.data.attributes;
            this.setState({
                name: surveyDetails.title,
                reportGeneratedOn:moment().format('MMM DD, YYYY hh:mm A'),
                reportGeneratedBy:surveyDetails.user_name || "-",
                detail: {
                    country: surveyDetails.country,
                    city: surveyDetails.city,
                    complex:surveyDetails.complex.name,
                    building: surveyDetails.building_list.map((item:any)=>item.name).join(", "),
                    endDate: surveyDetails.start_date,
                    startDate: surveyDetails.end_date
                },
                response:{
                    yes:1,
                    no:1,
                    awaited:surveyDetails.awaited,
                    responseReceived:surveyDetails.total_response,
                },
                description: surveyDetails.description,
            })
        }
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId && responseJson) {
            this.checkDetailsAPICallResponse(apiRequestCallId,responseJson) //API response check function for solve code smell issue in sonar
            // Add other response function here if required
        }
        }
        // Customizable Area End
    }
}