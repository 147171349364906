import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface BuildingData {
  buildingName: string;
  city: string;
  country: string;
  logo: string;
  photos: any[];
  aboutBuilding: string;
  buildingArea: string;
  totalFloor: string;
  totalUnit: string;
  sharedAreaList: any[];
  lat: string;
  long: string;
  areaMeasure: string;
}

interface S {
  // Customizable Area Start
  buildingId: string;
  complexId: string;
  buildingData: BuildingData;

  managementTeam: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OwnerBuildingsController extends BlockComponent<Props, S, SS> {
  GetBuildingDetailsCallId: any;
  GetBuildingManagementTeamCallId: any;
  CreateBuildingTeamChatCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      managementTeam: [],

      buildingId: "",
      complexId: "",
      buildingData: {
        buildingName: "",
        city: "",
        country: "",
        logo: "",
        photos: [],
        aboutBuilding: "",
        buildingArea: "",
        areaMeasure: "",
        totalFloor: "",
        totalUnit: "",
        sharedAreaList: [],
        lat: "",
        long: "",
      },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetBuildingDetailsCallId:
          this.handleBuildingDetailsResponse(responseJson);
          break;
        case this.GetBuildingManagementTeamCallId:
          this.handleBuildingManagementTeamResponse(responseJson);
          break;
        case this.CreateBuildingTeamChatCallId:
          this.handleBuildingCreateTeamResponse(responseJson);
          break;
        default:
          break;
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    const building_id = this.props.navigation.getParam("id");
    const complex_id = this.props.navigation.getParam("cid");
    this.setState({ buildingId: building_id, complexId: complex_id }, () => {
      this.getBuildingDetail();
      this.getBuildingManagementTeam();
    });
  }

  // Get Building Details API
  getBuildingDetail = async () => {
    this.GetBuildingDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/building_managements/${this.state.buildingId}`,
    });
  };

  handleBuildingDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        buildingData: {
          buildingName: responseJson.data.attributes.name,
          city: responseJson.data.attributes.city,
          country: responseJson.data.attributes.country,
          logo: responseJson.data.attributes.logo && responseJson.data.attributes.logo.url,
          photos: responseJson.data.attributes.photos,
          aboutBuilding: responseJson.data.attributes.description,
          buildingArea: responseJson.data.attributes.building_area,
          areaMeasure: responseJson.data?.attributes?.society_management?.measurement_unit,
          totalFloor: responseJson.data.attributes.total_floors,
          totalUnit: responseJson.data.attributes.total_units,
          sharedAreaList: responseJson.data.attributes.shared_area,
          lat: responseJson.data.attributes.lat,
          long: responseJson.data.attributes.long,
        },
      });
    }
  }

  getBuildingManagementTeam = async () => {
    this.GetBuildingManagementTeamCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/owner_dashbords/management_user_list?society_management_id=${this.state.complexId}`,
    });
  };

  handleBuildingManagementTeamResponse = (responseJson: any) => {
    if (responseJson && responseJson.management_user_list) {
      this.setState({ managementTeam: responseJson.management_user_list });
    }
  };

  createBuildingTeamChatRoom = async (id: any) => {
    let body = new FormData();
    body.append("chat[chatable_type]", "AccountBlock::Account");
    body.append("chat[chatable_id]", localStorage.getItem("userId") || "");
    body.append("chat[chat_with_account]", id);

    this.CreateBuildingTeamChatCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_chat/chats`,
      body: body,
    });
  };

  handleBuildingCreateTeamResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      localStorage.setItem("selectedChat", JSON.stringify(responseJson.data));
      this.props.navigation.navigate("Chatbox");
    }
  };

  // Handle State
  slider: any;
  // Customizable Area End
}
