// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
}

interface S {
  paymentId: any;
  loading: boolean;
  sortBy: any;
  status: any;
  getMyPrePaymentList: any;
  pollListing: any;
  selectedPrePayment: any;
  isShareModalOpen: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getMyPrePaymentCallId: any;
  downloadIDPdf: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      paymentId: "",
      loading: false,
      sortBy: "",
      status: "",
      pollListing: [],
      selectedPrePayment: null,
      getMyPrePaymentList: null,
      isShareModalOpen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const payment_id = this.props.navigation.getParam("id");
    this.setState({ paymentId: payment_id }, () => {
      this.getMyShowPrePayment();
    });
  }

  getMyShowPrePayment = async () => {
    this.getMyPrePaymentCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/${this.state.paymentId}`,
    });
  };

  getMyPrePaymentResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        getMyPrePaymentList: responseJson.data,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.getMyPrePaymentCallId) {
        this.getMyPrePaymentResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  handlePaymentShareModal = () => {
    this.setState({
      ...this.state,
      isShareModalOpen: !this.state.isShareModalOpen,
    })
  }

  prePaymentDetailsDownload = async () => {
    await this.downloadPdf(`/bx_block_fees_payment/invoices/${this.state.paymentId}/download_invoice.pdf`, `BuildingBudgethhhh.pdf`);
  };
}

// Customizable Area End
