import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography, MenuItem, FormControl, Select, Checkbox, Modal, Fade, Backdrop, Card, CardMedia
} from "@material-ui/core";
import { profile } from "../assets"
//@ts-ignore
import CompEmpBuildingBlockStep7Controller from "./CompEmpBuildingBlockStep7Controller.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
//@ts-ignore
import DeleteIcon from '../../assets/delete.png'
import "../../assets/commonForm.css"
import * as Yup from 'yup';
// Customizable Area End

const validationSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    role: Yup.string().required('Role is required'),
  });

  const validationCreateSchemaBuilding = Yup.object().shape({
    email: Yup.string().required('Email Address is required'),
    phone: Yup.string().required('Phone Number is required'),
    name: Yup.string().required('Full Name is required'),
    role: Yup.string().required('Phone Number is required'),
    buildingName: Yup.string().required('Building Name is required'),
    unit: Yup.string().required('Unit is required'),
  });

export default class CompEmpBuildingBlockStep7 extends CompEmpBuildingBlockStep7Controller {
    render() {
        // Customizable Area Start
        
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            
                                <Box>
                                    <Box>   
                                        
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box >
                                                <Typography className="Coresteps bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>Core Members</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="Coresteps" style={{ fontSize: '16px' }}>Step {this.props.stepBuilding + 1} of 7</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '30px', }}>
                                            <Box >
                                                <Box style={{ alignItems: 'center', flexWrap: "wrap", display: 'flex', }}>
                                                    {this.props.savedMemberBuilding.length ? this.props.savedMemberBuilding?.map((value: any, index: any) => (
                                                        <Box style={ index%3 === 0 ? { width: '229px', borderRadius: '8px', border: '1px solid #E4E4E4', height: '192px', padding: '20px', marginBottom: '20px' } : { width: '229px', borderRadius: '8px', border: '1px solid #E4E4E4', height: '192px', padding: '20px', marginBottom: '20px', marginLeft:'12px' }}>
                                                            <Box key={index}>
                                                                <Box data-test-id="handleRemoveFileBuildingPlans" onClick={() => this.props.handleOpenDeleteMemberPopupBuilding(index)} style={{ justifyContent: 'end', display: 'flex', }} >
                                                                    <svg viewBox="0 0 14 14" height="14" xmlns="http://www.w3.org/2000/svg" width="14" fill="none">
                                                                        <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                    </svg>
                                                                </Box>
                                                                <Box className="Coresteps" style={{ textAlign: 'center' }}>
                                                                    <Box className="Coresteps">
                                                                        <img src={profile} />
                                                                    </Box>
                                                                    <Box style={webStyle.step6}>{value.label}</Box>
                                                                    <Box style={webStyle.step6}>{value.name || '-'}</Box>
                                                                    <Box style={{ display: 'flex', justifyContent: 'center', }}>
                                                                        <Box style={{ color: '#2B6FED', fontSize: '16px', margin: '11px 0px', fontWeight: 400, background: 'rgba(43, 111, 237, 0.08)', borderRadius: '14px', padding: '8px 22px', width: 'max-content' }}>{value.position || '-'}</Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )) : <></>}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Card >
                                            <CardMedia
        
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      />
                                            </Card>
                                        </Box>
                                        <Box className="Coresteps">
                                            <Box style={{ justifyContent: 'space-between', display: 'flex', width: '100%', flexWrap: 'wrap', }}>
                                                <Box style={{ borderRadius: '8px', marginTop: '20px', border: '1px solid #2B6FED', }}>
                                                    <Box style={{ cursor: "grabbing", color: '#2B6FED', padding: '10px 25px', fontSize: '15px', fontWeight: 'bold', }} onClick={this.props.sharedCoreMembers} className="bold-text">+ ADD NEW MEMBER</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box className="coreMemersStep" style={{
                                                position: 'fixed',
                                                height: '100%',
                                                display: this.props.coreMembers ? 'block' : 'none',
                                                top: 0,
                                                width: '100%',
                                                left: 0,
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                zIndex: 9999,
                                            }}>
                                                <Box className="coreMemersStep" style={{
                                                    overflow: 'auto',
                                                    borderRadius: '5px',
                                                    left: '50%',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    backgroundColor: '#fff',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '50%',
                                                }}>
                                                    <Formik
                        initialValues={this.props.userValues}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        enableReinitialize={true}
                        data-testid="step2Form"
                        onSubmit={(values) => {
                            this.props.handleBasicDetailsData(values)
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms coreMemersStep">
                                                    <Box className="coreMemersStep ">
                                                        <Box className="topBarName Member coreMemersStep">
                                                            <Box className="coreMemersStep" style={{ fontSize: '22px', fontWeight: 400 }}>Add New Member</Box>
                                                            <Box className="coreMemersStep" onClick={this.props.sharedCoreMembers} style={{ cursor: "grabbing" }}>
                                                                <svg className="coreMemersStep" width="14"  xmlns=  "http://www.w3.org/2000/svg" fill="none" height="14" viewBox="0 0 14 14" >
                                                                    <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                </svg>
                                                            </Box>
                                                        </Box>
                                                       
                                                        <Box className="coreMemersStep" style={{ borderBottom: '1px solid #e7e7e7' }}></Box>
                                                        <Box className="coreMemersStep" style={{ display: 'flex', padding: '0px 25px' }}>
                                                            <Box className="formGroups customSelect coreMemersStep" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable Name coreMemersStep">
                                                                   Select User
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl coreMemersStep">
                                                    <Field as={Select} name="userName" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange coreMemersStep"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.name}
                                                        displayEmpty
                                                    >
                                                        <MenuItem disabled value="">User Name</MenuItem>
                                                        {this.props.userList.length && this.props.userList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu coreMemersStep" value={variant} key={i}>
                                                                    {variant.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemersStep"><ErrorMessage name="userName" component="div" data-testid="country-error" className="step4CountryError coreMemersStep" /></Box>
                                                            </Box>
                                                            <Box className="formGroups customSelect coreMemersStep" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable role coreMemersStep">
                                                                    Role
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl coreMemersStep">
                                                    <Field as={Select} name="role" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange coreMemersStep"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.label}
                                                        displayEmpty
                                                    >

                                                        <MenuItem disabled value="">Select Role</MenuItem>
                                                        {this.props.roleList.length && this.props.roleList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu coreMemersStep" value={variant} key={i}>
                                                                    {variant.label}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemersStep"><ErrorMessage name="role" component="div" data-testid="country-error" className="step4CountryError coreMemersStep" /></Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="coreMemersStep" style={{ color: '#FC8434', padding: '15px 25px', fontWeight: 400, fontSize: '15px', cursor: "grabbing" }} onClick={this.props.sharedCreateNewMember}>+ CREATE NEW MEMBER</Box>
                                                        <Box className="coreMemersStep" style={{ display: 'flex', justifyContent: 'end', padding: '15px 25px' }}>
                                                            <Grid className="coreMemersStep" style={{ marginRight: '20px' }}>
                                                                <Button onClick={this.props.sharedCoreMembers} className="buttonCancel coreMemersStep" >CANCEL</Button>
                                                            </Grid>
                                                            <Grid >
                                                                <Button className="buttonSave coreMemersStep"  type="submit">ADD</Button>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                    </Form>)}
                                </Formik>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Formik
                        initialValues={this.props.memberValues}
                        validationSchema={validationCreateSchemaBuilding}
                        validateOnMount={true}
                        enableReinitialize={true}
                        data-testid="memberForm"
                        onSubmit={(values) => {
                            this.props.handleMemberDetailsBuilding(values)
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms coreMemberBuildingStep7">
                                        <Box className="coreMemberBuildingStep7" style={{
                                            height: '100%',
                                            width: '100%',
                                            display: this.props.createNewMember ? 'block' : 'none',
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9999,

                                        }}>
                                            <Box className="coreMemberBuildingStep7" style={{
                                                left: '50%',
                                                backgroundColor: '#fff',
                                                width: '50%',
                                                position: 'absolute',
                                                top: '50%',
                                                overflow: 'auto',
                                                transform: 'translate(-50%, -50%)',
                                                borderRadius: '5px',
                                            }}>
                                                <Box className="topBarName coreMemberBuildingStep7">
                                                    <Box className="coreMemberBuildingStep7" style={{ fontSize: '22px', fontWeight: 400 }}>Create New Member</Box>
                                                    <Box className="coreMemberBuildingStep7" onClick={this.props.sharedCreateNewMember} style={{ cursor: "grabbing" }}>
                                                        <svg className="coreMemberBuildingStep7" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                        </svg>
                                                    </Box>
                                                </Box>
                                                <Box className="coreMemberBuildingStep7" style={{ borderBottom: '1px solid #e7e7e7' }}></Box>
                                                <Box className="coreMemberBuildingStep7" style={{ padding: "25px " }}>
                                                    <Box className="coreMemberBuildingStep7" style={{ marginTop: '20px' }}>
                                                        <Box className="coreMemberBuildingStep7" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box className="coreMemberBuildingStep7" style={{ width: '50%', marginRight: '25px' }}>
                                                                <Typography className="typographyLable coreMemberBuildingStep7">Email Address</Typography>
                                                                <Box className="formGroups coreMemberBuildingStep7" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="email" type="text" placeholder="Email Address" className="formInputs coreMemberBuildingStep7" />
                                                                    <span className="frmLeftIcons">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="email" component="div" data-testid="country-error" className="step4CountryError coreMemberBuildingStep7" /></Box>
                                                            </Box>
                                                            <Box className="coreMemberBuildingStep7" style={{ width: '50%' }}>
                                                                <Typography className="typographyLable coreMemberBuildingStep7">Phone Number</Typography>
                                                                <Box className="formGroups coreMemberBuildingStep7" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="phone" type="text" placeholder="Phone Number" className="formInputs coreMemberBuildingStep7" />
                                                                    <span className="frmLeftIcons coreMemberBuildingStep7">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="phone" component="div" data-testid="country-error" className="step4CountryError coreMemberBuildingStep7" /></Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="coreMemberBuildingStep7" style={{ marginTop: '20px' }}>
                                                        <Box className="coreMemberBuildingStep7" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box className="coreMemberBuildingStep7" style={{ width: '49%', marginRight: '25px' }}>
                                                                <Typography className="typographyLable coreMemberBuildingStep7">Full Name</Typography>
                                                                <Box className="formGroups coreMemberBuildingStep7" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="name" type="text" placeholder="Full Name" className="formInputs coreMemberBuildingStep7" />
                                                                    <span className="frmLeftIcons">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="name" component="div" data-testid="country-error" className="step4CountryError coreMemberBuildingStep7" /></Box>
                                                            </Box>
                                                            <Box style={{ width: '49%' }}>
                                                                <Box className="formGroups customSelect coreMemberBuildingStep7" >
                                                                    <Typography className="typographyLable coreMemberBuildingStep7">
                                                                        Role
                                                                    </Typography>
                                                                    <FormControl variant="outlined" className="step4CountryFormControl coreMemberBuildingStep7">
                                                    <Field as={Select} name="role" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange coreMemberBuildingStep7"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.label}
                                                        displayEmpty
                                                    >
                                                       
                                                        <MenuItem disabled value="">Select Role</MenuItem>
                                                        {this.props.roleList.length && this.props.roleList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu coreMemberBuildingStep7" value={variant} key={i}>
                                                                    {variant.label}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="role" component="div" data-testid="country-error" className="step4CountryError coreMemberBuildingStep7" /></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="coreMemberBuildingStep7" style={{ marginTop: '20px' }}>
                                                        <Box className="coreMemberBuildingStep7" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box className="formGroups customSelect coreMemberBuildingStep7" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable Building coreMemberBuildingStep7">
                                                                    Building
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl coreMemberBuildingStep7">
                                                    <Field as={Select} name="buildingName" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange coreMemberBuildingStep7"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.name}
                                                        displayEmpty
                                                    >
                                                        <MenuItem disabled value="">Select Building</MenuItem>
                                                        {this.props.buildingList?.length && this.props.buildingList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu coreMemberBuildingStep7" value={variant} key={i}>
                                                                    {variant.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="buildingName" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                            <Box className="coreMemberBuildingStep7" style={{ width: '50%' }}>
                                                                <Box className="coreMemberBuildingStep7" style={{}}>
                                                                    <Box className="coreMemberBuildingStep7" style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <Typography className="typographyLable coreMemberBuildingStep7">Unit</Typography>
                                                                        <Typography className="coreMemberBuildingStep7"><Checkbox /> Owner</Typography>
                                                                    </Box>
                                                                    <Box className="formGroups coreMemberBuildingStep7" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                        <Field name="unit" type="text" placeholder="Unit" className="formInputs coreMemberBuildingStep7" />
                                                                        <span className="frmLeftIcons coreMemberBuildingStep7">
                                                                        </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox coreMemberBuildingStep7"><ErrorMessage name="unit" component="div" data-testid="country-error" className="step4CountryError coreMemberBuildingStep7" /></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className="coreMemberBuildingStep7" style={{ display: 'flex', justifyContent: 'end', padding: '30px 0px' }}>
                                                        <Grid className="coreMemberBuildingStep7" style={{ marginRight: '20px' }}>
                                                            <Button onClick={this.props.sharedCreateNewMember} className="buttonCancel coreMemberBuildingStep7" >CANCEL</Button>
                                                        </Grid>
                                                        <Grid >
                                                            <Button type="submit" className="buttonSave coreMemberBuildingStep7" >CREATE</Button>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        </Form>)}
                                </Formik>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                                <Grid style={{ marginTop: "30px", marginRight: '20px' }}>
                    <Box style={{}}>
                        <Button style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.props.handleBack}><span className="bold-text">PREV</span></Button>
                    </Box>
                </Grid>
                <Box>
                <Grid style={{ marginTop: "30px", }}>
                            <Button onClick={this.props.handleSave} style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} ><span className="bold-text">Save</span></Button>
                        </Grid>
                </Box>
            </Box>

            <Modal
                itemID="docDeleteCloseID"
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="modal compEmpComplexStep6Modal"
                  open={this.props.deleteMemberPopupBuilding}
                  onClose={this.props.handleCloseDeleteMemberPopupBuilding}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.props.deleteMemberPopupBuilding}>
                    <div className="paper paper_Delete_Category compEmpComplexStep6Modal">
                      <div className="Delete_Category_Container compEmpComplexStep6Modal">
                        <img src={DeleteIcon} alt="Msg" style={{
                            cursor:'pointer'
                        }} className="compEmpComplexStep6Modal" />
                      </div>
                      <p className="Delete_Category_Heading bold-text compEmpComplexStep6Modal">Delete Core Member</p>
                      <p className="Delete_Category_Text compEmpComplexStep6Modal">
                        Are you sure you want to delete this core member?
                      </p>
                      <div className="Modal_Button_Container Delete_Category_Button_Div compEmpComplexStep6Modal">
                        <div
                          className="ModalCancelDiv Button_Size_Delete bold-text compEmpComplexStep6Modal"
                          onClick={this.props.handleCloseDeleteMemberPopupBuilding}
                        >
                          NO, DON’t DELETE
                        </div>
                        <div className="ModalConfirmDiv Button_Size_Delete bold-text compEmpComplexStep6Modal" 
                        data-testid="deleteFacility"
                        onClick={() => this.props.handleRemoveMemberBuilding(this.props.deleteMemberIndexBuilding)}
                        >
                          YES DELETE
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>

                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg:{
        color:'red',
        fontSize: '12px',
        marginTop:'5px'
      },
    step6: {
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px',
        fontWeight: 400
    }
}
// Customizable Area End