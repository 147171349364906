import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import moment from "moment";

// Customizable Area End


export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes?: any;
    history: any;
    location: any;
    match: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    id: string;
    data: any;
    openAction: boolean;
    pagination: {
        current_page: number;
        total_count: number;
        total_pages: number;
    };
    page: number;
    role: any;
    actionAnchor: any;
    company: string;
    country: string;
    complex: string;
    city: string;
    building: string;
    year: string;
    salesManager: string;
    sortBy: string;
    companyList: string[];
    countryList: string[];
    cityList: string[];
    complexList: string[];
    buildingList: string[];
    yearList: any[];
    salesManagerList: string[];
    sortList: any[];
    loading:boolean;
    accountManager:any;
    search:any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CEPollController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallId: string = "";
    classes: any;
    GetLivePollsSurveysList:any;
    GetSearchPollsSurveysList:any;
    GetCompanyList:any;
    GetCountryList:any;
    getCityList:any;
    getComplexList:any;
    getSalesManagerList:any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading:false,
            id: '',
            data: [],
            pagination:{
                current_page:1,
                total_count:1,
                total_pages:1,
            },
            page: 1,
            role: '',
            openAction: false,
            actionAnchor: null,
            company: '',
            country: '',
            complex: '',
            city: '',
            building: '',
            year: '',
            salesManager: '',
            sortBy: '',
            companyList: [],
            countryList: [],
            cityList: [],
            complexList: [],
            buildingList: ['Crystal Luxuria', 'Raghuveer Towers'],
            yearList: [],
            salesManagerList: [],
            sortList: [
                {
                    value:"complex_name",
                    name:"Complex Name"
                },
                {
                    value:"account_manager",
                    name:"Account Manager"
                }
            ],
            accountManager:"",
            search:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const userRole = localStorage.getItem('role')
        this.livePollsSurveys(1)
        this.getCompanyList()
        this.getCountryListData()
        if(userRole === 'coo'){
            this.getSalesManagerListCall()
        }else{
            this.getAccountManagerListCall()
        }
        this.setState({ role: userRole })
        let yearList = this.years()
        let list = yearList.map((value:any) => {
            return { value: value, name: value }
        })
        this.setState({
            yearList:list
        })
        // Customizable Area End
    }

    // Customizable Area Start

    years() {
        const now = new Date().getUTCFullYear();
        return Array(now - (now - 4)).fill('').map((v, idx) => now - idx);
    }

    handleChangePage =  (value:any,page:any) => {
        this.setState({ page: page},()=> this.livePollsSurveys(page))
    }


    companyPollSurveyListResponse = (responseJson:any) =>{
        if(responseJson.hasOwnProperty("society_management")){
            const newData = responseJson.society_management.data.map((value: {
                id: string;
                company_name:string;
                attributes: {
                    name:string,
                    id: string;
                    account_manager:string;
                    total_polls:string;
                    last_date_of_polls:string,
                    total_surveys:string;
                    last_date_of_surveys:string;
                }
            }) => {
                return{
                    id: value.id,
                    attributes: {
                        id: value.id,
                        complexName: value.attributes.name,
                        accountManager:value.attributes.account_manager,
                        totalPoll: value.attributes.total_polls,
                        lastPollDate: value.attributes.last_date_of_polls ? moment(value.attributes.last_date_of_polls).format("DD MMM YYYY") : "-",
                        totalSurvey: value.attributes.total_surveys,
                        lastSurveyDate: value.attributes.last_date_of_surveys ? moment(value.attributes.last_date_of_surveys).format("DD MMM YYYY") : "-",
                    }
                }
            })
            this.setState({data: newData,pagination:responseJson.meta.pagination})
        }else{
            this.setState({data: [],pagination:{current_page:1,total_count:1,total_pages:1}})
        }

    }

    cityListResponse = (responseJson:any) => {
        if(responseJson.city_list){
            const list = responseJson.city_list.map((value:any)=>{
                return {value:value.city,name:value.city}
            })
            this.setState({cityList:list})
        }
    }

    salesManagerListResponse = (responseJson:any) => {
        if(responseJson.sales_managers){
            const list = responseJson.sales_managers.map((value:any)=>{
                return {value:value.id,name:value.name}
            })
            this.setState({salesManagerList:list})
        }else{
            const list = responseJson.account_managers.map((value:any)=>{
                return {value:value.id,name:value.name}
            })
            this.setState({salesManagerList:list})
        }
    }
    // Add function which you required
    checkAPICallResponse(apiRequestCallId:string,responseJson:any){
        if (apiRequestCallId == this.GetLivePollsSurveysList) {
            this.companyPollSurveyListResponse(responseJson)
        }
        if(apiRequestCallId == this.GetCompanyList){
            const companyData=responseJson.compneys.map((value:any)=>{
                return {value:value.id,name:value.company_name}
            })
            this.setState({companyList:companyData})
        }
        if(apiRequestCallId == this.GetCountryList){
            const countries = responseJson.data.countries.map((value:any)=>{
                return {value:value,name:value}
            })
            this.setState({countryList:countries})
        }
        if(apiRequestCallId == this.getCityList){
            this.cityListResponse(responseJson)
        }
        if(apiRequestCallId == this.getComplexList){
            const list = responseJson.complexes.data.map((value:any)=>{
                return {value:value.id,name:value.attributes.name}
            })
            this.setState({complexList:list})
        }
        if(apiRequestCallId == this.getSalesManagerList){
            this.salesManagerListResponse(responseJson)
        }
        this.setState({ loading: false })
    }

    searchByComplex = async (e: any) => {
        this.setState({search:e.target.value},()=>this.livePollsSurveys(1))
    }

    handleSearch = () => {
        this.livePollsSurveys(1)
    }

    handleOpenAction = (e: any) => {
        if(this.state.openAction){
            this.setState({ openAction: false, actionAnchor: null })
        }else{
            this.setState({ openAction: true, actionAnchor: e.currentTarget })
        }
    }

    handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ company: event.target.value,country:"",city:"",complex:"",salesManager:""});
    }

    handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ country: event.target.value,city:"",complex:"",salesManager:""});
        this.getCityListCall(event.target.value)
        this.getComplexListCall(event.target.value,"")
    }

    handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ city: event.target.value,complex:"",salesManager:""});
        this.getComplexListCall(this.state.country,event.target.value)
    }

    handleComplexChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ complex: event.target.value,salesManager:""});
    }

    handleSalesManagerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ salesManager: event.target.value});
    }

    handleSortByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ sortBy: event.target.value});
    }

     livePollsSurveys=async(page:any)=>{
        this.setState({ loading: true })
        this.GetLivePollsSurveysList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/complexes?page=${page}&per_page=10&account_manager_id=${this.state.accountManager}&company_name=${this.state.company}&sales_manager_id=${this.state.salesManager}&sort_by=${this.state.sortBy}&country=${this.state.country}&city=${this.state.city}&search=${this.state.search}&society_id=${this.state.complex}`
        })
    }

    getCompanyList=async()=>{
        this.setState({ loading: true })

        this.GetCompanyList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_dashboard/assign_action_team/real_estate_company_list`
          })
    }

    getCountryListData=async()=>{
        this.setState({ loading: true })

        this.GetCountryList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_address/country_list`
          })
    }

    getCityListCall = async (country: any) => {
        this.getCityList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`
        })
    }

    getComplexListCall = async (country?: string, city?: string) => {
        this.getComplexList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_interactive_faqs/complex_list?country=${country}&city=${city}`
        })
    }

    getSalesManagerListCall = async () => {
        this.getSalesManagerList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_real_estate_companies/sales_manager_list`
        })
    }

    getAccountManagerListCall = async () => {
        this.getSalesManagerList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_real_estate_companies/account_manager_list`
        })
    }

    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (apiRequestCallId && responseJson) {
                this.checkAPICallResponse(apiRequestCallId,responseJson) //API response check function for solve code smell issue in sonar
                // Add other response function here if required
            }
        }
        // Customizable Area End
    }
}