//Customizable Area Start
import React from "react";
import CEPollSurveyDetails from "./CEPollSurveyDetails.web";
import CEPollSurveyDetailsWrapperController, { Props } from "./CEPollSurveyDetailsWrapperController.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

export default class CEPollSurveyDetailsWrapper extends CEPollSurveyDetailsWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <CEHeaderComponentWeb>
        <CEPollSurveyDetails navigation={this.props.navigation} />
      </CEHeaderComponentWeb>
    );
  }
}
//Customizable Area End
