import React from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
//@ts-ignore
import GoogleMapReact from "google-map-react";

const MapPinManual = ({ societyId, society, manageClick }: { societyId: any; society: any; manageClick: any }) => {
  return (
    <>
      <Box
        style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
        className="mapPinManualRegistration"
        onClick={() => manageClick(societyId)}
      >
        <IconButton style={{ padding: 0 }} className="mapPinManualRegistration">
          <RoomIcon style={{ color: "#DD946A" }} className="mapPinManualRegistration" />
        </IconButton>
      </Box>
    </>
  );
};

export const MapComponent = (props: any) => {
  const { defaultCenter, defaultZoom, mapPoints } = props;
  return (
    <GoogleMapReact
      key={new Date().getTime()}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      style={webStyle.mapContainer}
    >
      {mapPoints.map((point: any) => {
        return (
          <MapPinManual
            //@ts-ignore
            lat={point.lat}
            lng={point.lng}
            society={point.society}
            societyId={point.societyId}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export const ComplexMapComponent = (props: any) => {
  const { defaultCenter, defaultZoom, mapPoints, manageClick, currentPoint, isData } = props;
  return (
    <GoogleMapReact
      key={new Date().getTime()}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      onChildClick={manageClick}
      style={webStyle.mapContainer}
    >
      {mapPoints.map((point: any, i: number) => {
        return (
          <ComplexMapPinManual
            key={point.complexId}
            //@ts-ignore
            lat={point.lat}
            lng={point.lng}
            complex={point.complex}
            complexId={point.complexId}
            chairman={point.chairman}
            totalUnits={point.totalUnits}
            subscription={point.subscription}
            manageClick={manageClick}
            currentPoint={currentPoint}
            isData={isData}
            data={point.data}
            dataId={point.id}
            dataName={point.name}
          />
        );
      })}
    </GoogleMapReact>
  );
};

const ComplexMapPinManual = ({
  complexId,
  complex,
  manageClick,
  chairman,
  totalUnits,
  isData,
  data,
  dataId,
  dataName,
  subscription,
  currentPoint,
}: {
  complexId: any;
  complex: any;
  manageClick: any;
  chairman: any;
  totalUnits: any;
  subscription: any;
  currentPoint: number;
  isData?: boolean;
  data?: any;
  dataId?: any;
  dataName?: any;
}) => {
  return (
    <>
      <Box
        style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
        className="mapPinManualRegistration"
        onClick={() => manageClick(isData ? dataId : complexId)}
      >
        <IconButton style={{ padding: 0 }} className="mapPinManualRegistration">
          <RoomIcon style={{ color: "#DD946A" }} className="mapPinManualRegistration" />
        </IconButton>
        <Box style={webStyle.complexNameBox}>
          <Typography style={webStyle.complexName}>{isData ? dataName : complex}</Typography>
          <Typography style={webStyle.complexInfoText}>{chairman}</Typography>
        </Box>
      </Box>
      {!isData
        ? currentPoint === complexId && (
            <Box style={webStyle.complexMapInfo}>
              <Box>
                <Typography style={{ fontSize: "18px" }} className="bold-text">
                  {complex}
                </Typography>
              </Box>
              <Box display="flex" style={{ borderBottom: "1px solid rgb(240, 240, 240)", padding: "8px 0 8px" }}>
                <Typography style={webStyle.complexInfoText}>Complex ID:</Typography>
                <Typography style={webStyle.complexInfoText} className="bold-text">
                  {complexId}
                </Typography>
              </Box>
              <Box display="flex" style={{ borderBottom: "1px solid rgb(240, 240, 240)", padding: "8px 0 8px" }}>
                <Typography style={webStyle.complexInfoText}>Chairman:</Typography>
                <Typography style={webStyle.complexInfoText} className="bold-text">
                  {chairman}
                </Typography>
              </Box>
              <Box display="flex" style={{ borderBottom: "1px solid rgb(240, 240, 240)", padding: "8px 0 8px" }}>
                <Typography style={webStyle.complexInfoText}>Account Manager:</Typography>
                <Typography style={webStyle.complexInfoText} className="bold-text">
                  {chairman}
                </Typography>
              </Box>
              <Box display="flex" style={{ borderBottom: "1px solid rgb(240, 240, 240)", padding: "8px 0 8px" }}>
                <Typography style={webStyle.complexInfoText}>Total Units:</Typography>
                <Typography style={webStyle.complexInfoText} className="bold-text">
                  {totalUnits}
                </Typography>
              </Box>
              <Box display="flex" style={{ borderBottom: "1px solid rgb(240, 240, 240)", padding: "8px 0 8px" }}>
                <Typography style={webStyle.complexInfoText}>Subscription Ends in:</Typography>
                <Typography style={webStyle.complexInfoText} className="bold-text">
                  {subscription}
                </Typography>
              </Box>
              <Box display="flex" style={{ padding: "8px 0 8px" }}>
                <Button style={{ width: "100%", background: "#2B6FED", color: "white" }}>View Details</Button>
              </Box>
            </Box>
          )
        : currentPoint === dataId && (
            <Box style={webStyle.complexMapInfo}>
              <Box>
                <Typography className="bold-text">{dataName}</Typography>
              </Box>
              {data.map((information: any) => (
                <Box key={dataId} display="flex">
                  <Typography style={webStyle.complexInfoText}>{information.label}</Typography>
                  <Typography style={webStyle.complexInfoText} className="bold-text">
                    {information.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
    </>
  );
};

const webStyle: any = {
  complexNameBox: {
    background: "#fff",
    padding: "10px 15px",
    width: "120px",
    textAlign: "center",
  },
  complexName: {
    fontSize: "12px",
  },
  complexMapInfo: {
    width: "250px",
    background: "#fff",
    padding: "15px",
    borderRadius: "8px",
    zIndex: "111",
    position: "relative",
  },
  complexInfoText: {
    fontSize: "12px",
  },
};
