// Customizable Area Start
import * as React from "react";
import { Box, Button, Card, styled } from "@material-ui/core";

const StyledReportCard = styled(Card)({
  padding: "40px 25px 25px 25px",
  background: "#fff",
  borderRadius: 8,
  boxShadow: "4px 0px 14px #e9e9e9",
  "& .card-image": {
    width: "40px",
    height: "40px",
    padding: "10px",
    border: "1px solid #d9d4d3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "& img": {
      width: "28px",
      height: "28px",
    },
  },
  "& h4": {
    fontWeight: "600",
    fontSize: "17px",
    margin: "12px 0",
  },
  "& .card-bottom-info": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .info-box": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& button": {
      color: "#FC8434",
      background: "#FFF6F0",
      borderRadius: "25px",
    },
  },
});

const ReportCard = ({ image, heading, value }: any) => {
  return (
    <StyledReportCard>
      <Box className="card-image">
        <img src={image} alt="image" />
      </Box>
      <h4 className="bold-text">{heading}</h4>
      <Box className="card-bottom-info">
        <Box className="info-box">
          <Button>{value}</Button>
        </Box>
      </Box>
    </StyledReportCard>
  );
};

export default ReportCard;
// Customizable Area End
