// Customizable Area Start
import React from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Menu,
  MenuItem
} from "@material-ui/core";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentController, { Props } from "./IncidentController.web";
import { Building1, Grid_Icon, Filter_Icon } from "./assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {withTranslation} from "react-i18next";

class IncidentListing extends IncidentController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.getIncidentListing(this.state.sortBy, this.state.status);
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props;
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper mobile-incident" >
          <Grid container spacing={2} className="auth-container" style={{ backgroundColor: '#F7F9FE' }}>
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block" style={{ justifyContent: "flex-start" }}  dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks" style={{gap:"1rem"}}>
                    <ArrowBackIcon  
                    onClick={this.redirectToDashboard} />
                    <h4 className='bold-text' style={{fontSize:"18px"}}>{t("Incidents")}</h4>
                  </Box>
                  <Box className="incident-right-block blocks">
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                      <Button data-test-id="gridIconClick" aria-controls="simple-menu" aria-haspopup="true" onClick={(e: any) => this.handleClick(e)}>
                        <img src={Grid_Icon} className="grid-icon icons" alt="" />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        data-test-id="anchorElMenuTest"
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleClose("", "")}
                        style={{ top: "28px" }}
                      >
                        <MenuItem onClick={(e) => this.handleClose(e, "asc")} className="ascButton">{t("Ascending")}</MenuItem>
                        <MenuItem onClick={(e) => this.handleClose(e, "desc")} className="dscButton">{t("Descending")}</MenuItem>
                      </Menu>
                    </Box>

                    <Button data-test-id="filterIconClick" aria-controls="fade-menu" aria-haspopup="true" onClick={(e: any) => this.handleClick_1(e)}>
                      <img src={Filter_Icon} className="filter-icon icons" alt="" />
                    </Button>
                    <Menu
                      id="fade-menu"
                      anchorEl={this.state.anchorEl_1}
                      keepMounted
                      data-test-id="anchorEl_1Test"
                      open={Boolean(this.state.anchorEl_1)}
                      onClose={() => this.handleClose_1("", "")}
                      style={{ top: "28px" }}
                    >
                      <MenuItem onClick={(e) => this.handleClose_1(e, "Ongoing")} className="ongoingButton">{t("Ongoing")}</MenuItem>
                      <MenuItem onClick={(e) => this.handleClose_1(e, "Unresolved")} className="unresolvedButton">{t("Unresolved")}</MenuItem>
                      <MenuItem onClick={(e) => this.handleClose_1(e, "Pending Confirmation")} className="pendingConfirmationButton">{t("Pending Confirmation")}</MenuItem>
                      <MenuItem onClick={(e) => this.handleClose_1(e, "Closed")} className="closedButton">{t("Closed")}</MenuItem>
                    </Menu>

                  </Box>
                </Box>
                <Box className="content-block-wrapper common-incident-block" style={{ backgroundColor: '#f6f7fc' }}>
                  <Box className="incident-content-wrapper incident-list-owner">
                    {
                      this.state.incidentListing?.length !== 0 ?
                        this.state.incidentListing?.map((val: any, index: any) => (
                          <>
                            <Card data-test-id="incidentCardTest" className="incident-card card" key={index} onClick={() => this.getIncidentDetails(val.id)}>
                              <CardContent className="costom-card-content">
                                <Typography component="h4" style={{ marginTop: "0" }} className="bold-text">
                                  {val.attributes.incident_title}
                                </Typography>
                                <Typography component="span">
                                  {t("Incident is related to")}:
                                </Typography>
                                <Typography className="sub-title h5-title" component="h5">
                                  {val.attributes.incident_related.name}
                                </Typography>
                                <Box className="card-listing-row">
                                  <Typography component="span">
                                    {t("Building")}:
                                  </Typography>
                                  <Typography component="span">
                                    {t("Unit")}:
                                  </Typography>
                                </Box>
                                <Box className="card-listing-row" style={{ paddingTop: "0" }}>
                                  <Typography className="sub-title h5-title" component="h5">
                                    {val.attributes.apartment_management.building_name}
                                  </Typography>
                                  <Typography className="sub-title h5-title" component="h5">
                                    {val.attributes.apartment_management.apartment_name}
                                  </Typography>
                                </Box>
                                <hr />
                                <CardActions className="card-footer">
                                  <Typography className="sub-title h5-title" component="h5" style={{ paddingBottom: "0" }}>
                                    {val.attributes.common_area.name}
                                  </Typography>
                                  <ButtonStatus val={val}></ButtonStatus>
                                </CardActions>
                              </CardContent>
                            </Card>
                          </>
                        ))
                        :
                        <h1 className='bold-text' style={{ marginTop: "190px", paddingBottom: "150px" }}>{t("No Incident found..!!")}</h1>
                    }
                  </Box>
                  <Box className="customButton add-new-incident-btn" style={{ paddingTop: "10px" }}>
                    <Button data-test-id="newIncidentTest" variant="contained" onClick={() => {
                      this.setState({ loading: true });//@ts-ignore
                      this.props.history.push("/CreateIncident");
                    }} >{t("Add New Incident")}</Button>
                  </Box>
                </Box>

              </Box>
            </Grid>
            {/* desktop footer block */}
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AlertErrorWeb data-test-id="alertErrorTest" show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const ButtonStatus = (props: any) => {
  const attributes = props.val;
  const checkCl = () => {
    if (attributes.attributes.incident_status === 'Unresolved') {
      return "contain danger";
    } else if (attributes.attributes.incident_status === 'Resolved') {
      return 'contain warning';
    } else if (attributes.attributes.incident_status === 'Ongoing') {
      return 'incident-details-ongoing';
    }else if (attributes.attributes.incident_status === 'Pending Confirmation') {
      return 'contain warning';
    } else {
      return 'contain success';
    }
  };
  return (
    <>
      <Box className="customButton" style={{ paddingBottom: "0" }}>
        <Button variant="contained" className={checkCl()} type="submit">{attributes.attributes.incident_status}</Button>
      </Box>
    </>
  );
};

export default withTranslation()(withRouter(IncidentListing));
// Customizable Area End