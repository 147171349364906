// Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    InputAdornment,
    InputBase,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import VisitorsListController, { Props } from "./VisitorsListController";
import { SuggestionStyleWeb } from "../../user-profile-basic/src/SuggestionStyle.web";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { SearchIconImage } from "../../user-profile-basic/src/assets";
import { withTranslation } from "react-i18next";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import { ROLE } from "../../../framework/src/Enum";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class VisitorsList extends VisitorsListController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        // @ts-ignore
        const { t,classes,i18n } = this.props;
        const userType = localStorage.getItem("userType");
        const language = i18n.language;
        return (
            <>
                <GeneralHeader>
                            <Container className={classes.announcements} >
                                <Box className="navigation">
                                    <Box>
                                        <Typography variant="body1">
                                            {t("My Dashboards")}/{" "}
                                            {
                                                userType !== ROLE.SECURITY &&
                                                <>
                                                    <Box component="span" className="visitorGeneralDashboard" onClick={() => this.props.history.push("/DashboardGeneral")} style={{ cursor: "pointer" }}>{t("General Dashboards")}</Box>
                                                    {" "}/{" "}
                                                </>
                                            }
                                            <Box component="span" style={{ color: "blue" }}>
                                                {t("Visitors")}
                                            </Box>
                                        </Typography>
                                        <Typography variant="h5" className="sub-heading bold-text">
                                            {t("Visitors")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="top-bar">
                                    <Box className="filter">
                                        <Select displayEmpty value={this.state.buildingID} className="select-input reportsSelectButton VisitorBuildingList VisitorListBuildingListSelect" placeholder="Select Building" onChange={(e) => this.setState({ buildingID: e.target.value }, () => this.getUnitVisitorList(e.target.value))}>
                                            <MenuItem value="" disabled>
                                                {t("Select Building")}
                                            </MenuItem>
                                            <MenuItem value=" ">
                                                {t("All")}
                                            </MenuItem>
                                            {
                                                this.state?.buildingList?.length > 0 &&
                                                this.state.buildingList.map((item: any, key: any) => {
                                                    return (
                                                        <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                        <Select displayEmpty value={this.state.unitId} className="select-input reportsSelectButton VisitorUnitList visitorUnitListSelect" placeholder="Select Unit " onChange={(e) => this.setState({ unitId: e.target.value })}>
                                            <MenuItem value="" disabled>
                                                {t("Select Unit")}
                                            </MenuItem>
                                            <MenuItem value=" ">
                                                {t("All")}
                                            </MenuItem>
                                            {
                                                this.state.unitList.length > 0 &&
                                                this.state.unitList.map((item: any, key: any) => {
                                                    return (
                                                        <MenuItem key={key} value={item.id}>{item.apartment_name}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                        {
                                            localStorage.getItem("userType") === "Security" &&
                                            <Box className="classifiedFormGroup">
                                                <Box className="visitorTextfield">
                                                    <TextField
                                                        variant="outlined"
                                                        id={"nominateMySelf"}
                                                        className="visitorDateBox"
                                                        style={{ borderRadius: "8px", border: "1px solid #F0F0F0", backgroundColor: "white",padding:"0px",width:"170px" }}
                                                        type={this.state.dateType} name="date"
                                                        onFocus={() => this.setState({ dateType: "date" })}
                                                        format='DD/MM/YYYY'
                                                        placeholder={t("Select Date")}
                                                        value={this.state.date}
                                                        onChange={(e) => this.setState({ date: e.target.value })}
                                                        InputProps={{
                                                            //@ts-ignore
                                                            max: "5000-05-31",
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {/* <DateRangeOutlinedIcon /> */}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        }
                                        <Button onClick={() => this.getVisitorList(this.state.searchQuery, 1)} className="visitorListSearch" startIcon={<img style={{marginLeft:"10px"}} src={SearchIconImage}/>}>{t("Search")}</Button>
                                    </Box>
                                </Box>
                                <Box className="meeting-table">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <Box className="table-top">
                                            <h3 className="bold-text">{t("Visitors")}</h3>
                                            <Box className="filter">
                                                <Box className="search-box">
                                                    <SearchIcon />
                                                    <InputBase id={"nominateMySelf"} placeholder={t("Search")} className="search" onChange={this.manageSearch} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Table className="table-box">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align={languageCondition(language,"right","left")}>#</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Date")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Visitor Name")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Resident Name")}</TableCell>
                                                    {
                                                        userType === ROLE.SECURITY &&
                                                        <TableCell align={languageCondition(language,"right","left")}>{t("Owner Name")}</TableCell>
                                                    }
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Building")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Unit Number")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Phone Number")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.visitorList.length > 0 ?
                                                        this.state.visitorList.map((item: any, key: any) => {
                                                            return (
                                                                <TableRow key={key} onClick={() => this.props.history.push(`/VisitorsDetails?id=${item.id}`)} style={{ cursor: "pointer" }}>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.schedule_date}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")} className="ellipse">{item.attributes.name}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.resident_name}</TableCell>
                                                                    {
                                                                        userType === ROLE.SECURITY &&
                                                                        <TableCell align={languageCondition(language,"right","left")}>{item.attributes.owner_name}</TableCell>
                                                                    }
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.building_management?.name || "-"} </TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.apartment_management?.apartment_name || "-"}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.mobile_number.full_mobile_number}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                        :
                                                        <TableRow style={{ cursor: "pointer" }}>
                                                            <TableCell colSpan={4} align={languageCondition(language,"right","left")}>{t("No data found")}!</TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                        <Divider />
                                        <Box className="table-bottom">
                                            <PaginationModule pagination={this.state.pagination} handlePagination={this.handleVistorPagination} page={this.state.page} />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Container >
                </GeneralHeader>
            </>
        );
    }
}

export default withStyles(SuggestionStyleWeb)(withTranslation()(withRouter(VisitorsList)));
// Customizable Area End
