// Customizable Area Start
import React from "react";
export const UnitsImageComponent = () => {
  return (
      <svg width="21.349976px" height="24.642694px" viewBox="0 0 21.349976 24.642694" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="1214347">
              <path d="M20.2528 6.4464C20.2528 6.4464 20.2528 6.4464 20.2528 6.3504L20.1808 6.1704C20.1558 6.13221 20.1277 6.09609 20.0968 6.0624C20.0647 6.0116 20.0286 5.96345 19.9888 5.9184L19.8808 5.8344L19.6888 5.7384L10.6888 0.182403C10.2997 -0.0608009 9.80597 -0.0608009 9.41685 0.182403L0.488846 5.7384L0.380846 5.8344L0.272846 5.9184C0.233067 5.96345 0.196952 6.0116 0.164846 6.0624C0.133996 6.09609 0.105905 6.13221 0.0808458 6.1704L0.00884574 6.3504C0.00884574 6.3504 0.00884574 6.3504 0.00884574 6.4464C-0.00294858 6.55007 -0.00294858 6.65474 0.00884574 6.7584L0.00884574 17.2464C0.00801608 17.661 0.221263 18.0467 0.572846 18.2664L9.57285 23.8224L9.57285 23.8224C9.62824 23.8566 9.68914 23.881 9.75285 23.8944C9.75285 23.8944 9.81285 23.8944 9.84885 23.8944C10.0519 23.9588 10.2698 23.9588 10.4728 23.8944C10.4728 23.8944 10.5328 23.8944 10.5688 23.8944C10.6326 23.881 10.6935 23.8566 10.7488 23.8224L10.7488 23.8224L19.6888 18.2664C20.0404 18.0467 20.2537 17.661 20.2528 17.2464L20.2528 6.7584C20.2646 6.65474 20.2646 6.55007 20.2528 6.4464ZM8.88885 20.6544L2.28885 16.5744L2.28885 8.9184L8.88885 12.9864L8.88885 20.6544ZM10.0888 10.9104L3.36885 6.7584L10.0888 2.6184L16.8088 6.7584L10.0888 10.9104ZM17.8888 16.5744L11.2888 20.6544L11.2888 12.9864L17.8888 8.9184L17.8888 16.5744Z" id="Shape" stroke="#FFFFFF" stroke-width="0.7" />
          </g>
      </svg>
  );
};
// Customizable Area End
