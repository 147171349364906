// Customizable Area Start
import React, {Component} from 'react';
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import CENomination from "./CENomination.web";
import CENominationDetails from "./CENominationDetails.web";
import CENominationController from "./CENominationController.web";
// Customizable Area End
class CeNominationMain extends CENominationController {
    // Customizable Area Start
    render() {
        return (
            <MainScreen>
                <CENomination id={this.props.id} navigation={this.props.navigation}/>
            </MainScreen>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export const CENominationExport = CeNominationMain;

class CeNominationDetailsMain extends CENominationController {
    render() {
        return (
            <MainScreen>
                <CENominationDetails id={this.props.id} navigation={this.props.navigation}/>
            </MainScreen>
        );
    }
}

export const CENominationDetailsExport = CeNominationDetailsMain;

// Customizable Area End