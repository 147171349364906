// Customizable Area Start
import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css'
import Box from '@material-ui/core/Box';
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedManagerController, { Props } from "./ClassifiedManagerContorller.web";
import { withTranslation } from 'react-i18next';
import { SearchIconImage } from "../../StoreCredits/src/assets"
import { imgIcon } from "./assets"
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class ClassifiedManagerListing extends ClassifiedManagerController {
  constructor(props: Props) {
    super(props);
  }
  
  componentDidMount(): any {
    this.getClassifiedsListing();
    this.getBuildingName();
  }
  render() {
    const { t, classes, i18n }: any = this.props;
    const statusArray = ["Pending Approval", "Published", "Rejected","Ignore"]
    const { language } = i18n
    return (
      <>
        <GeneralHeader>
          <Container className="incident-Listing-wrapper desktop-ui">
            <Box className={classes.navigation}>
              <Box>
                <Typography variant="body1" >
                  <Link href="DashboardGeneral" color="inherit"> {t("My Dashboard")}</Link> /
                  <Link href="DashboardGeneral" color="inherit"> {t("General Dashboard")}</Link> /
                  <Box component="span" style={{ color: "#2c6fed" }}>
                    <Link href="ClassifiedManagerListing" color="inherit"> {t("Classifieds")}</Link>
                  </Box>
                </Typography>
                <Typography variant="h5" className={`bold-text ${classes.subHeading}`}>{t("Classifieds")}</Typography>
              </Box>
            </Box>
            <Box className="sorting-header classified-sorting-header">
              <div className="left-block">
                <Box className="formGroup customSelect">
                  <FormControl variant="outlined" >
                    <Select
                      name="classifiedType"
                      displayEmpty
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      className="selectClassifiedType"
                      onChange={(e) => this.setState({classifiedType:e.target.value}) }
                      style={{ width: "180px", height: "45px", border: "1px solid #f0f0f0", marginRight: "10px" }}
                      value={this.state.classifiedType}
                    >
                      <MenuItem value="" disabled>
                        {t("Classified Type")}
                      </MenuItem>
                      <MenuItem value="All">{
                      t("All")}</MenuItem>
                      <MenuItem value="buyer">{
                      t("Buy")}</MenuItem>
                      <MenuItem value="generic">{
                      t("Generic")}</MenuItem>
                      <MenuItem value="seller">{
                      t("Sell")
                      }</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="formGroup customSelect">
                  <FormControl variant="outlined" >
                    <Select
                      name="buildingName"
                      displayEmpty
                      labelId="demo-simple-select-outlined-label"
                      className="buildingName-testcases"
                      id="demo-simple-select-outlined"
                      style={{ width: "180px", height: "45px", border: "1px solid #f0f0f0", marginRight: "10px" }}
                      onChange={(e) => { this.onChange(e)}}
                      value={this.state.buildingName}
                    >
                      <MenuItem value="" disabled>
                        {t("Select Building")}
                      </MenuItem>
                      <MenuItem 
                      value=" ">
                        {
                        t("All")
                        }
                      </MenuItem>
                      {
                        this.state.buildingNameData.map((val: any, index: any) =>
                        (
                          <MenuItem
                            key={index}
                            value={
                              val?.id
                            }
                          >
                            {
                            val?.name
                            }
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box className="formGroup customSelect">
                  <FormControl variant="outlined" >
                    <Select
                        displayEmpty
                        name="status"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        style={{width:"180px",height:"45px",border:"1px solid #f0f0f0",marginRight:"10px"}}
                        onChange={(e) => { this.onChange(e) }}
                        value={this.state.status}
                    >
                      <MenuItem value="" disabled>
                        {t("Select Status")}
                      </MenuItem>
                      <MenuItem
                          value=" ">
                        {
                          t("All")
                        }
                      </MenuItem>
                      {
                        statusArray.map((val, index) => (
                            <MenuItem
                                key={index}
                                value={val}
                            >
                              {t(val)}
                            </MenuItem>
                        ))
                      }
                    </Select>

                  </FormControl>
                </Box>
                <Box >
                  <Button variant="contained" className="classifiedManagerListingSearch" color="primary" style={{ backgroundColor: "#2b6fed", borderRadius: "8px", width: "120px", height: "43px" }} startIcon={<img src={SearchIconImage} />} onClick={() => this.serachHandle()}>
                    {t("Search")}
                  </Button>
                </Box>
              </div>
              <Box className="formGroup classified-search-iput">
                <TextField
                  variant="outlined"
                  name="unitNo"
                  onChange={(e) => { this.onChange(e) }}
                  className="placeholderForClassified"
                  value={this.state.unitName}
                  style={{ border: "1px solid rgb(209,209,209)", borderRadius: "10px", backgroundColor: "white", marginRight: "10px" }}
                  placeholder={t("Search by unit")}
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Grid container spacing={2} style={{ marginTop: 15, marginBottom: 15 }}>
              {
                this.state.classifiedsListing.length ?
                this.state.classifiedsListing.map((val: any, index: any) => (
                  <Grid item className="classifiedsListingTest-testcases" sm={6} lg={4} key={index} onClick={() => this.getClassifiedDetails(val.id)}>
                    <Card className="classified-card  classifiedManager-card card" key={index}>
                      <CardContent className="costom-card-content" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                          <Typography component="h4" className={"bold-text"} style={{ fontSize: "18px" }}>
                            {val?.attributes?.title}
                          </Typography>
                          {val.attributes.attachments.length > 0 &&
                            <img src={imgIcon.default} style={{ height: "20px" }} />
                          }
                        </Box>
                        <Typography className="textwrap" component="p" style={{ fontSize: "17px" }}>
                          {val?.attributes?.description}
                        </Typography>
                        <Box className="content-row">
                          {val?.attributes?.classified_type === "buyer" ?
                            <Typography component="span" style={{ fontSize: "14px", color: "#181D25" }}>
                              {t("Available to buy")}:
                            </Typography> :
                            <>
                              {val?.attributes?.classified_type === "seller" ?
                                <Typography component="span" style={{ fontSize: "14px", color: "#181D25" }}>
                                  {t("Available to sell")}:
                                </Typography>
                                :
                                <Typography component="span" style={{ fontSize: "14px", color: "#181D25" }}>
                                  {t("Available")}:
                                </Typography>
                              }
                            </>
                          }
                          <Typography component="p">
                            {val?.attributes?.duration_from} to {val?.attributes?.duration_to}
                          </Typography>
                          <Box className="content-blocks-row">
                            <Box className="content-sub-row">
                              <Box className="blocks">
                                <Typography component="span" style={{ fontSize: "14px", color: "#181D25" }}>
                                  {t("Building")}:
                                </Typography>
                              </Box>
                              <Box className="blocks">
                                <Typography component="span" style={{ fontSize: "14px", color: "#181D25" }}>
                                  {t("Type")}:
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="content-sub-row" >
                              <Box className="blocks">
                                <Typography component="p" className="textwrap">
                                  {val?.attributes?.building_management?.name}
                                </Typography>
                              </Box>
                              <Box className="blocks">
                                <Typography component="p" className="textwrap">
                                  {val.attributes.classified_type}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <hr className="hr"></hr>
                        <Box className="card-footer">
                          {
                            val.attributes.classified_type === "buyer" &&
                              <div className="left-block">
                                <Typography component="h4" className="managerTitle" style={{ color: "FC8434", fontSize: "14px", width: "120px" }}>
                                  {val.attributes.price_from} {languageCondition(language, val.attributes.currency.currency_ar, val.attributes.currency.currency)} - {languageCondition(language, val.attributes.currency.currency_ar, val.attributes.currency.currency)} {val.attributes.price_to}
                                </Typography>
                              </div>
                          }
                          {
                            val.attributes.classified_type === "generic" &&
                              <div className="left-block">
                                <Typography component="h4" className="managerTitle" style={{ color: "FC8434", fontSize: "14px" }}>
                                  {val.attributes.payment_detail} {languageCondition(language, val.attributes.currency.currency_ar, val.attributes.currency.currency)}
                                </Typography>
                              </div>
                          }
                          {
                            val.attributes.classified_type === "seller" &&
                              <div className="left-block">
                                <Typography component="h4" className="managerTitle" style={{ color: "FC8434", fontSize: "14px" }}>
                                  {val.attributes.price} {languageCondition(language, val.attributes.currency.currency_ar, val.attributes.currency.currency)}
                                </Typography>
                              </div>
                          }
                          <Box className="customButton">
                            <Button variant="contained" data-test-id="checkClassName" style={languageCondition(language, { width: "150px", marginRight: "-30px" }, { width: "150px", marginLeft: "-30px" })} className={this.checkClassName(val)} type="submit">
                              {t(val.attributes.classified_status)}</Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
                    :
                    <Typography variant="h6" className="noData" color="textSecondary" style={{marginLeft:"10px"}}>
                      {t("No Data Found!")}
                    </Typography>
              }
            </Grid>
          </Container>
        </GeneralHeader>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}




const dashBoard: any = {
  
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  
};
export default withTranslation()(withStyles(dashBoard)(withRouter(ClassifiedManagerListing)));
// Customizable Area End
