// Customizable Area Start
import React from "react";
import "./Dashboard.web.css";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TextField,
  InputAdornment,
  Divider,
  Link,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import DashboardTicketController, { Props } from "./DashboardTicketController.web";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import { ROLE } from "../../../framework/src/Enum";
import moment from "moment";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class TicketGeneratedYear extends DashboardTicketController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const year = this.props.navigation.getParam("year");
    const role = localStorage.getItem("userType");
    const society_id = localStorage.getItem("society_id");
    this.setState({ ticketYear: year, filterYear: year, society_id: role === ROLE.SERVICE_PROVIDER ? "" : society_id  }, () => {
      this.getTicketDashboardYearList();
      this.getAllBuildingList();
      this.getTicketByYear();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.searchResident !== this.state.searchResident ||
      prevState.filterYear !== this.state.filterYear ||
      prevState.filterStatus !== this.state.filterStatus ||
      prevState.filterBuilding !== this.state.filterBuilding ||
      prevState.page !== this.state.page
    ) {
      await this.getTicketByYear();
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const userType = localStorage.getItem("userType");

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.generalDashboard}>
                <Box className="navigation ticket-year-navigation-content">
                  <Box className="ticket-year-navigation-heading">
                    <Typography variant="body1">
                      {t("My Dashboards")} / <Link href="/DashboardTicket">{t("Ticket Dashboard")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Tickets Generated in")} {this.state.ticketYear}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading-box ticket-year-filter-content-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Tickets Generated in")} {this.state.ticketYear}
                    </Typography>
                    <Box className="select-box ticket-year-select-filter-box">
                      {userType === ROLE.MANAGER && (
                        <>
                          <select
                            className="select-year ticket-year-status-filter"
                            onChange={(e: any) => this.setState({ filterStatus: e.target.value })}
                            value={this.state.filterStatus}
                          >
                            <option value="">{t("Status")}</option>
                            <option value="Closed">{t("Closed")}</option>
                            <option value="Unresolved">{t("Unresolved")}</option>
                            <option value="Pending Confirmation">{t("Pending Confirmation")}</option>
                            <option value="Ongoing">{t("Ongoing")}</option>
                          </select>
                          <select
                            className="select-year"
                            value={this.state.filterBuilding}
                            onChange={(e: any) => this.setState({ filterBuilding: e.target.value })}
                          >
                            <option value="" disabled>
                              {t("Select Building")}
                            </option>
                            {this.state.buildingList.map((building: any) => {
                              return (
                                <option value={building.id} key={building.id}>
                                  {building.attributes.name}
                                </option>
                              );
                            })}
                          </select>
                        </>
                      )}
                      <select
                        value={this.state.filterYear}
                        className="select-year ticket-year-filter-year"
                        onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                      >
                        {this.state.yearList.map((year: any) => {
                          return (
                            <option value={year} key={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </Box>
                  </Box>
                </Box>

                <Box className="content-boxes ticket-table">
                  <Box className="top-content ticket-year-table-top-content">
                    <Box className="heading ticket-year-table-heading">
                      <h2 className="bold-text">{t("Tickets")}</h2>
                    </Box>
                    <Box className="right-content ticket-year-table-right-box">
                      <TextField
                        onChange={(e: any) => this.setState({ searchResident: e.target.value })}
                        className="search-unit ticket-year-table-ticket-search"
                        value={this.state.searchResident}
                        placeholder={t("Search by ticket number")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider />
                  <TableContainer>
                    <Table className="unit-table">
                      <TableHead className="ticket-year-table-head-box">
                        <TableRow>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text ticket-no-head">
                            {t("#")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} style={{ color: "black" }} className="ticket-no-table bold-text">
                            {t("Ticket No")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text ticket-related-head">
                            {t("Incident related to")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} style={{ color: "black" }} className="bold-text ticket-unit-no-head">
                            {t("Unit Number")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text ticket-raised-by-head">
                            {t("Raised By")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} style={{ color: "black" }} className="bold-text ticket-open-date-head">
                            {t("Open Date")}
                          </TableCell>
                          <TableCell style={{ color: "black" }} align={languageCondition(language,"right","left")} className="bold-text ticket-chairman-table-head">
                            {t("Close Date")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} style={{ color: "black" }} className="ticket-date-head bold-text">
                            {t("Status")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.ticketList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={8}>{t("No ticket found")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.ticketList.map((incident: any, index: number) => {
                          return (
                            <TableRow key={incident.id} className="ticket-year-table-row">
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">{index + 1}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")}
                                className="ticket-number"
                                onClick={() => this.handleTicketDetails(incident.id)}
                              >
                                {incident.id}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">
                                {incident.attributes.incident_related_to}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">{incident.attributes.unit_number}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">{incident.attributes.raised_by}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">
                                {moment(incident.attributes.open_date, "DD-MM-YYYY").format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">
                                {incident.attributes.close_date &&
                                  moment(incident.attributes.close_date, "DD-MM-YYYY").format("DD MMM YYYY")}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ticket-year-table-content">
                                <span className={incident.attributes.status}>{t(incident.attributes.status)}</span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="unit-pagination ticket-year-pagination-content-box">
                    <p className="ticket-year-pagination-content">
                      {t("Showing")} <span>{this.state.ticketList.length}</span> {t("of")}{" "}
                      <span>{this.state.pagination ? this.state.pagination.total_count : 0}</span> {t("results")}
                    </p>
                    {this.state.pagination && (
                      <Pagination
                        className="ticket-year-pagination-box"
                        onChange={(event: any, value: any) => this.setState({ page: Number(value) })}
                        page={this.state.pagination.current_page}
                        siblingCount={2}
                        variant="outlined"
                        shape="rounded"
                        count={this.state.pagination.total_pages}
                      />
                    )}
                  </Box>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(withRouter(TicketGeneratedYear)));
// Customizable Area End
