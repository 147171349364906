import React from "react";
// Customizable Area Start
import ClientTicketController, { Props } from "./ClientTicketController.web";
import {
    Typography,
    Container,
    Select,
    Button,
    withStyles,
    Box,
    Grid,
    MenuItem,
    Paper,
   

} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import SearchIcon from "@material-ui/icons/Search";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { withRouter } from "react-router-dom";

import Loader from "../../../components/src/Loader.web";

// Customizable Area End
class ClientTicket extends ClientTicketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t, classes }: any = this.props;
    let days=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
    return (
      <div>
         <MainScreen>
                <Box style={{ background: "#F4F7FF" }} className={classes.reportList}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        style={{ paddingTop: 35, minHeight: "95vh" }}
                    >
                        <Container>
                            <Box className="navigation">
                                <Box>
                                    <Typography variant="body1">
                                        <Box
                                            className="reports-BudgetReportmain budgetReportBackButton"
                                            component="span"
                                            style={{ cursor: "pointer" }}
                                        >
                                            {" "}
                                            {t("Client Tickets")}
                                        </Box>


                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        className="sub-heading bold-text reports-BudgetReportmain"
                                        style={webStyle.subHeadLine}
                                    >
                                        {t("Client Tickets")}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="top-bar ChairmanInvoices" style={{ display: "flex" }}>
                                <Box className="filter" style={{ display: "flex", flexWrap: "wrap" }}>
                                    <Select displayEmpty
                                    id="durationId"
                                        value={this.state.openDays}
                                        onChange={(e:any)=>{this.setState({openDays:e.target.value})}}
                                        className="select-input selectBuildingForChairmanInvoices" >
                                        <MenuItem
                                            value=""
                                            disabled
                                            id="selectStatusBudgetReport"
                                        >
                                            {t("Select Duration")}
                                        </MenuItem>
                                         { days.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item === 0 ? "Same Day": item + " Days"}
                                  </MenuItem>
                                );
                         })
                        }

                                    </Select>


                                    <Button className="handleSearchFilterForInvoices"
                                        startIcon={<SearchIcon />}
                                        style={{ marginLeft: "10px" }}
                                        onClick={()=>this.handleSearch()}
                                        id="daySearch"
                                    >
                                        {t("Search")}</Button>
                                </Box>
                            </Box>

                            <Grid container spacing={3}>

                                {this.state.accountData?.map((item:any) => (
                                    <Grid item xs={6} onClick={()=>this.handleNext(item)} id="gridList">
                                        
                                        <Paper style={webStyle.cardAlign}>
                                            <div style={webStyle.cardSpace}>
                                                {/* <Typography>{t("Sales Manager")}</Typography> */}
                                                <Typography>{this.state.accountType==="coo"? "Sales Manager":"Account Manager"}</Typography>
                                                <Typography>{this.state.accountType==="coo"? item.attributes?.sales_manager:item.attributes?.account_manager}</Typography>
                                                <div style={{ marginTop: "20px" }}>
                                                    <Typography>{t("Total No of open tickets")}:</Typography>
                                                    <Typography>{item.attributes.open_tickets_count}</Typography>

                                                </div>
                                            </div>
                                            <div style={webStyle.cardSpace}>
                                                <Typography>{t("Tickets open for 7 days")}:</Typography>
                                                <Typography>{item.attributes.tickets_open_for_days}</Typography>
                                                <div style={{ marginTop: "20px" }}>
                                                    <Typography>{t("Total number of Closed tickets")}:</Typography>
                                                    <Typography>{item.attributes.closed_tickets_count}</Typography>
                                                </div>

                                            </div>
                                        </Paper>
                                       
                                    </Grid>
                                ))}


                            </Grid>


                        </Container>

                    </Grid>
                    <Loader loading={this.state.loading} />

                </Box>

           
            </MainScreen>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// export default ClientTicket;
const webStyle: any = {

    cardAlign: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px"
    },
    subHeadLine: {
        fontSize: "32px",
        fontWeight: "0px"
    },



};
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ClientTicket))
);
// Customizable Area End
