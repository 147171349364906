// Customizable Area Start
import React from "react";
import "./Dashboard.web.css";
import { TenantLogo } from "./assets";
import "../../../web/src/assets/css/style.scss";
import { Typography, Link, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import { withRouter } from "react-router-dom";
import DashboardController, { Props } from "./DashboardController.web";
import { withTranslation } from "react-i18next";
import {visitorIcon,unitIcon,complexIcon,teamIcon,faqIcon} from "../../customform/src/assets";
import {CommunityImageComponent} from "./SidebarImages/CommunityImage.web";
import {MyTeamImageComponent} from "./SidebarImages/MyTeamImage.web";
import {ComplexImageComponent} from "./SidebarImages/ComplexImage.web";
import {HelpImageComponent} from "./SidebarImages/HelpImage.web";
import {UnitsImageComponent} from "./SidebarImages/UnitsImage.web";

class VisitorsSidebar extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getUnreadCount();
  }
  visitorBarBlueColorHandle = (pathName: any, path: any) => {
    return path.includes(pathName) ? "highlight-blue SingleLinkSize cursor-pointer" : "SingleLinkSize cursor-pointer";
  };

  render() {
    const { t }: any = this.props;
    const { classes }: any = this.props;
    const pathName = window.location.pathname;

    return (
      <>
        <Box className="AccordinoList">
          <Box className="SingleLink" style={{marginLeft:"20px"}}>
            <Typography className={this.visitorBarBlueColorHandle(pathName,["/VisitorList","/VisitorsDetails"])}>
              <CommunityImageComponent />
            </Typography>
            <div id="VisitorsLink" onClick={() => this.props.history.push("/VisitorList")}>
              <Typography className={this.visitorBarBlueColorHandle(pathName,["/VisitorList","/VisitorsDetails"])} >{t("Visitor")}</Typography>
            </div>
          </Box>

          <Box className="SingleLink" style={{marginLeft:"20px"}}>
            <Typography className={this.visitorBarBlueColorHandle(pathName,["/Unit","/UnitGeneralDetails"])} >
              <UnitsImageComponent />
            </Typography>
            <div id="UnitsLink" onClick={() => this.props.history.push("/Unit")}>
              <Typography className={this.visitorBarBlueColorHandle(pathName,["/Unit","/UnitGeneralDetails"])}>{t("Units")}</Typography>
            </div>
          </Box>

          <Box className="SingleLink" style={{marginLeft:"20px"}}>
            <Typography className={this.visitorBarBlueColorHandle(pathName,["/TeamMembers/Core_member"])}>
              <MyTeamImageComponent />
            </Typography>
            <div
              id="MyTeamLink"
              onClick={() =>
                //@ts-ignore
                this.props.history.push("/TeamMembers/Core_member")
              }
            >
              <Typography className={this.visitorBarBlueColorHandle(pathName,["/TeamMembers/Core_member"])}>{t("My Team")}</Typography>
            </div>
          </Box>

          <Box className="SingleLink" style={{marginLeft:"20px"}}>
            <Typography className={this.visitorBarBlueColorHandle(pathName,["/Complex"])}>
              <ComplexImageComponent/>
            </Typography>
            <div id="ComplexPage" onClick={() => this.props.history.push("/Complex")}>
              <Typography className={this.visitorBarBlueColorHandle(pathName,["/Complex"])}>{t("Complex Page")}</Typography>
            </div>
          </Box>

          <Box className="SingleLink" style={{marginLeft:"20px"}}>
            <Typography className={this.visitorBarBlueColorHandle(pathName,["/FaqChairman"])}>
              <HelpImageComponent/>
            </Typography>
            <div id="FAQPage" onClick={() => this.props.history.push("/FaqChairman")}>
              <Typography className={this.visitorBarBlueColorHandle(pathName,["/FaqChairman"])}>{t("FAQ")}</Typography>
            </div>
          </Box>
        </Box>

        <Box className="SideBarBottom">
          <Box>
            <img src={TenantLogo.default} alt="TenantLogo" width={110} />
          </Box>
        </Box>
      </>
    );
  }
}

const dashBoard = {
  PremimumPlan: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginRight: 8,
  },
  ListItem: {
    // color: "black",
    marginTop: "20px",
    alignItems: "center",
  },
  ListItemText: {
    marginLeft: 15,
    fontSize: 14,
  },
  Item: {
    cursor: "pointer",
  },
};

//@ts-ignore
export default withTranslation()(withStyles(dashBoard)(withRouter(VisitorsSidebar)));
// Customizable Area End
