import React from "react";

// Customizable Area Start
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import CommunityUserProfileController, { Props } from "./communityManagementController.web";
import { withTranslation } from "react-i18next";
import { call_org, chat, email_org, userIcon } from "./assets";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { dashBoard } from "./Style.web";
// Customizable Area End

class AdminUserProfile extends CommunityUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserProfile();
    this.getBuilding();
  }

  getUserRoleData(item: any) {
    switch (item) {
      case "ga_member":
        return "GA Member";
      case "property_manager":
        return "Property Manager";
      case "resident":
        return "Residents";
      default:
        return "Owners";
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container style={{ marginBottom: "50px" }} className="ContainerForUserProfileCommunity">
            <Box style={dashBoard.navigation}>
              <Box>
                <Typography variant="body1">
                  {t("Community Management")} /
                  <Box component="span" style={{ color: "blue" }}>
                    {" "}
                    {t("User Profiles")}
                  </Box>
                </Typography>
                {
                  //@ts-ignore
                  <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>
                    {t("User Profiles")}
                  </Typography>
                }
              </Box>
            </Box>
            <Box style={dashBoard.boxStyling}>
              <Grid container xs={8} md={8} sm={8} spacing={2}>
                <Grid item className={"girdItemForTheUserProfilePageSearchPart"}>
                  <FormControl style={dashBoard.YearMain} className="yearTab">
                    <NativeSelect
                      onChange={this.handleChange}
                      // value={this.state.Year}
                      name="selectedBUilding"
                      className="yearSelection admin-select-building"
                    >
                      <option value={""}>{t("Select Building")}</option>
                      {this.state.allBuilding.map((item: any) => (
                        <>
                          <option value={item.id} className="admin-select-building-option">{item.name}</option>
                        </>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item className={"girdItemForTheUserProfilePageSearchByType"}>
                  <FormControl style={dashBoard.YearMain} className="yearTab">
                    <NativeSelect
                      className="yearSelection"
                      name="selectedUserType"
                      // value={this.state.Year}
                      onChange={this.handleChange}
                    >
                      <option value={""}>{t("Select User Type")}</option>
                      <option value={""}>{t("All")}</option>
                      <option value={"owner"}>Owner</option>
                      <option value={"resident"}>Resident</option>
                      <option value={"property_manager"}>Property Manager</option>
                      <option value={"ga_member"}>GA Member</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item>
                  {/*@ts-ignore*/}
                  <Button
                    className="btnMy"
                    variant="contained"
                    onClick={this.getUserProfile}
                    style={{  backgroundColor: "#2D6EED", color: "white", padding:"12px 16px", marginTop:"3px", textTransform:"capitalize", }}
                  >
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {t("Search")}
                  </Button>
                </Grid>
              </Grid>
              <Grid container xs={4} sm={4} spacing={2} style={{ justifyContent: "end" }}>
                <div className="search-box">
                  <TextField
                    style={dashBoard.searchButton}
                    onChange={(e) => {
                      e.preventDefault();
                      this.getUserProfileSearch(e.target.value);
                    }}
                    className="input-with-icon-textfield-for-search-userProfile placeholderForClassified"
                    placeholder={t("Search by name or unit number")}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Box>

            {/* GA MEMBERS -- */}
            {this.state.allProfileKeys.map((item: any) => (
              <>
                <Box>
                  <Grid container style={dashBoard.gaMemberMain}>
                    <Grid item xs={6}>
                      {
                        //@ts-ignore
                        //@ts-nocheck
                        <Typography variant="h6" className="bold-text" style={dashBoard.subHeading}>
                          {t(`${this.getUserRoleData(item)}`)}
                        </Typography>
                      }
                    </Grid>
                    <Grid item xs={1} style={dashBoard.cursorPointer}>
                      {/*@ts-ignore*/}
                      <Typography
                        variant="subtitle1"
                        // style={dashBoard.viewMore}
                        className="admin-user-view-all"
                        onClick={() => {
                          //@ts-ignore
                          this.props.history.push(`/${item}`);
                        }}
                      >
                        {t("View All")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                  <div style={dashBoard.gaMemberCard}>
                    <>
                      {
                        //@ts-ignore
                        this.state.allProfile[item]?.data?.slice(0, 4).map((singleProfile: any, index: any) => {
                          return (
                            <div key={index}>
                              <Card
                              className="admin-user-profile-card"
                                style={dashBoard.cardStyle}
                                onClick={(e: any) => {
                                  localStorage.setItem("selectedPofile", JSON.stringify(singleProfile));
                                  //@ts-ignore
                                  this.props.history.push({ pathname: "/UserDetailedProfile", singleProfile });
                                }}
                              >
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={singleProfile?.attributes?.profile_pic?.url || userIcon}
                                    alt="green iguana"
                                    style={dashBoard.profileImage}
                                  />
                                  <CardContent
                                    style={{ padding: "0px 16px 16px 16px" }}
                                  >
                                    <Typography
                                      variant="h6"
                                      className="bold-text"
                                      style={{ textAlign: "center", marginTop: "5px", minHeight: "30px" }}
                                    >
                                      {singleProfile?.attributes?.apartment_number?.apartment_number ||
                                        "Unit Not available"}
                                    </Typography>
                                    <Typography variant="h6" style={{ textAlign: "center", marginTop: "5px" }}>
                                      {singleProfile?.attributes?.full_name?.name}
                                    </Typography>
                                    <div style={{ textAlign: "center", marginTop: "5px" }}>
                                      {
                                        //@ts-ignore
                                        //@ts-nocheck
                                        <Typography variant="h6" style={dashBoard.userType}>
                                          {t(`${this.getUserRoleData(item)}`)}
                                        </Typography>
                                      }
                                    </div>
                                    <div style={dashBoard.contactIcon}>
                                      <div style={dashBoard.relatedMemberCard}>
                                        <img
                                          className="admin-user-chat"
                                          src={chat}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.openChat(singleProfile?.id);
                                          }}
                                          style={{ width: "40px", margin: "0 auto" }}
                                        />
                                        <a
                                        className="admin-user-mail"
                                          onClick={(e) => e.stopPropagation()}
                                          href={`mailto:${singleProfile?.attributes?.email?.email}`}
                                        >
                                          <img src={email_org} style={{ width: "40px", margin: "0 auto" }} />
                                        </a>
                                        <a
                                        className="admin-user-phone"
                                          onClick={(e) => e.stopPropagation()}
                                          href={`tel:${singleProfile?.attributes?.full_phone_number?.full_phone_number}`}
                                        >
                                          <img src={call_org} style={{ width: "40px", margin: "0 auto" }} />
                                        </a>
                                      </div>
                                    </div>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </div>
                          );
                        })
                      }
                    </>
                  </div>
                </Box>
              </>
            ))}
          </Container>
        </GeneralHeaderComponentWeb>

        <Loader loading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignor
export default withTranslation()(withRouter(AdminUserProfile));
