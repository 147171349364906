// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history:any;
  match:any;
  location:any; 
}

interface S {
  loading: boolean;
  sortBy:any;
  expenseDetails:any;
  expenseId:any;
  rejectReason:any;
  RejectReasonError:any;
  isRejectReportModalOpen: boolean;
  isApproveReportModalOpen: boolean;
  budgetDetails:any;
  showSuccess:boolean;
  successMessage:any;
  setOpen:boolean;
  ApproveModal:boolean;
  showError:boolean;
  error:any;
  userType:any;
}

interface SS {
  id: any;
}

export default class ExpenseDetailsController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {

  labelTitle: string = "";
  updateExpenseReportId:string = "";
  expenseDetailsId:string = "";
  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]
    
    this.state = {
      loading:false,
      sortBy : "" ,
      expenseDetails:{},
      expenseId:"",
      rejectReason:"",
      RejectReasonError:"",
      isRejectReportModalOpen: false,
      isApproveReportModalOpen: false,
      budgetDetails:{},
      showSuccess:false,
      successMessage:"",
      setOpen:false,
      ApproveModal:false,
      showError:false,
      error:"",
      userType:localStorage.getItem("userType"),
    };
    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.getExpenseDetails()
  }

  getExpenseDetails = async () => {
    const societyExpnseID = localStorage.getItem("society_id")
    const expenseId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.setState({expenseId:expenseId})
    this.expenseDetailsId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${societyExpnseID}/bx_block_report/expence_reports/${expenseId}`,
    });
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(this.expenseDetailsId === apiRequestCallId ){
        console.log(responseJson,errorReponse)
        if(responseJson.hasOwnProperty("data")){
          this.setState({
            expenseDetails:responseJson.data.attributes
          })
        }else{
          window.history.back()
        }
      }
      if(this.updateExpenseReportId === apiRequestCallId){
        this.expenseReportVerifyResponse(responseJson)
      }
    }
  }



  expenseReportVerifyResponse = (responseJson:any) => {
    if(responseJson.message === "Expence Report Successfully verified"){
      this.getExpenseDetails()
      if(this.state.rejectReason){
        this.setState({
          isRejectReportModalOpen:false,
          showSuccess:true,
          successMessage:"Expense Rejected Successfully!",
        })
      }else{
        this.setState({
          isApproveReportModalOpen:false,
          showSuccess:true,
          successMessage:"Expense Approved Successfully!"
        })
      }
    }
  }
  manageExpenseApproval = () => {
    const data = {
      status:true
    }
    this.updateExpenseReport(data)
  }

  handleRejectExpense = () => {
    if(this.state.rejectReason){
      const data = {
        status:false,
        note:this.state.rejectReason
      }
      this.updateExpenseReport(data)
    }else{
      this.setState({
        RejectReasonError:"Please enter reason for reject this expense"
      })
    }
  }

  updateExpenseReport = async (data:any) => {
    const societyID = localStorage.getItem("society_id")
    const expenseId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.updateExpenseReportId = await this.apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/${societyID}/bx_block_report/expence_reports/${expenseId}/verify_expence_report`,
      body:JSON.stringify(data)
    });
  }

  manageExpenseDetailsDownload = (url:any,name:any) => {
    this.downloadPdf(`${url}`,`ExpenseAttachment-${name}.pdf`)
  }
}

// Customizable Area End
