//Customizable Area Start
import React from "react";
import CEPollWrapperController, { Props } from "./CEPollWrapperController.web";
import CEPoll from './CEPoll.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";

export default class CEPollWrappepr extends CEPollWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      return (
        <MainScreen>
          <CEPoll />
        </MainScreen>
      );
   
  }
}

//Customizable Area End
