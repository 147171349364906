// Customizable Area Start
import React from "react";
import { Box, Button } from "@material-ui/core";

const ItemBox = ({ image, heading, value }: any) => {
  return (
    <Box className="item">
      <div className="heading" style={{ gap:"10px" }}>
        <img src={image} />
        <h4 className="bold-text" style={{ margin:"0px" }}>{heading}</h4>
      </div>
      {value > 0 && <Button className="color-btn">{value}</Button>}
    </Box>
  );
};

export default ItemBox;
// Customizable Area End
