// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";

export interface Props {
  id?: string;
  navigation?: any;
  classes?: any;
}

interface S {}

interface SS {
  id: any;
}

export default class CEPollSurveyWrapperReportsController extends CommonApiCallForBlockComponent<Props, S, SS> {
  LoadDataCallID: any;

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {};
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
}

// Customizable Area End
