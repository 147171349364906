import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  buildingList: any[];
  actionList: any[];
  categoryList: any[];

  filterBuilding: string;
  filterSort: string;
  filterCategory: string;

  filter: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DashboardActionsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetAllBuildingListCallId: any;
  GetAllActionListCallId: any;
  GetAllCategoryListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      buildingList: [],
      actionList: [],
      categoryList: [],

      filterBuilding: "",
      filterSort: "",
      filterCategory: "",

      filter: {
        building: "",
        sort: "",
        category: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetAllBuildingListCallId:
          this.handleBuildingListResponse(responseJson);
          break;
        case this.GetAllActionListCallId:
          this.handleActionListResponse(responseJson);
          break;
        case this.GetAllCategoryListCallId:
          this.handleCategoryListResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getAllActionList();
    await this.getAllBuildingList();
    await this.getAllCategoryList();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.filterBuilding !== this.state.filterBuilding ||
      prevState.filterSort !== this.state.filterSort ||
      prevState.filterCategory !== this.state.filterCategory
    ) {
      await this.getAllActionList();
    }
  }

  // Building List
  getAllBuildingList = async () => {
    this.GetAllBuildingListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/building_managements`,
    });
  };

  // Action List
  getAllActionList = async () => {
    const { filterBuilding, filterSort, filterCategory } = this.state;
    const society_id = localStorage.getItem("society_id");

    this.GetAllActionListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_actions?society_management_id=${society_id}&category=${filterCategory}&building_management_id=${filterBuilding}&sort=${filterSort}`,
    });
  };

  // Category List
  getAllCategoryList = async () => {
    this.GetAllCategoryListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_actions/list_categories`,
    });
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ buildingList: responseJson.data });
    }
  };

  handleActionListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ actionList: responseJson.data });
    }
  };

  handleCategoryListResponse = (responseJson: any) => {
    if (responseJson && responseJson.categories) {
      this.setState({ categoryList: responseJson.categories });
    }
  };
  // Customizable Area End
}
