// Customizable Area Start
import React from "react";
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import SuggestionController, { Props } from "./SuggestionController.web";
import { Building1 } from "../../ContentManagement/src/assets";
import { Claender } from "./assets";
import "../assets/style.css";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import moment from "moment";
class Suggestion extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.getSuggtionListing();
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper" style={{ background: "#F8F9FE", overflowY:"hidden" }}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block" style={{ display: "block" }} dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks" style={{gap:"1rem"}}>
                    <ArrowBackIcon onClick={() => this.redirectToDashboard()} />
                    <p className='bold-text' style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                      {t("My Suggestions")}
                    </p>
                  </Box>
                </Box>
                <Box
                  className="content-block-wrapper common-incident-block"
                  style={{
                    background: "#F8F9FE",
                    // display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "space-between",
                  }}
                >
                  <Box className="incident-content-wrapper" style={{ margin: "0", marginBottom:"30px", height:"100%" }}>
                    {this.state.suggestionList.map((item: any) => {
                      return (
                        <Card
                          className="incident-card facility-card card nagivationSuggestion"
                          key={item.id}
                          onClick={() => this.openSuggestion(item)}
                          style={{ padding: "0" }}
                        >
                          <CardContent className="costom-card-content" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Typography className="sub-title h5-title bold-text" component="h4">
                              {item?.attributes?.title}
                            </Typography>
                            <Box className="card-listing-row" style={{ marginBottom: "15px",paddingTop:"0px" }}>
                              <Typography component="span" className="span-subtitle">
                                {item?.attributes?.description}
                              </Typography>
                            </Box>
                            <Typography component="span" className="span-subtitle">
                              <span style={{color:"gray"}}>{t("Building")}:</span>{" "}<span className="bold-text">{item?.attributes.society_management.name}</span>
                            </Typography>
                            <hr />
                            <CardActions className="card-footer">
                              <Typography
                                className="sub-title h5-title"
                                component="h5"
                                style={{ display: "flex", gap: "0.5rem" }}
                              >
                                <img src={Claender} />
                                {moment(item?.attributes?.created_at).format("DD-MM-YYYY")}
                              </Typography>
                              <Box className="customButton" style={{ padding: "0" }}>
                                <Button variant="contained" type="submit" className={item?.attributes?.response ? "contain green-span" : "contain red-span"} >{item?.attributes?.response ? item?.attributes?.response.data.length : '0'} {t("Response")}</Button>
                              </Box>
                            </CardActions>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Box>
                  <Box className="customButton add-incident add-incident-list-btn grayColor" style={{ borderTop: "none" }}>
                    <Button
                      variant="contained"
                      className="createSuggestion"
                      onClick={() => {
                        this.setState({ loading: true }); //@ts-ignore
                        this.props.history.push("/newsuggestion");
                      }}
                    >
                      {t("Add New Suggestion")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(Suggestion));
// Customizable Area End