// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";




export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  location: any;
  match: any;
}

interface S {
  isRejectReportModalOpen: boolean;
  isApproveReportModalOpen: boolean;
  auditReportDetails: any;
  loading: boolean;
  rejectReason:any;
  rejectReasonError:any;
  successMessage:any;
  showSuccess:boolean;
  error:any;
  showError:boolean;
  userType:any;
}

interface SS {
  id: any;
}

export default class AuditReportDetailsController extends CommonApiCallForBlockComponent<Props, S, SS> {
  updateAuditReportId: string = "";
  getAuditReportDetailsId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    this.state = {
      isRejectReportModalOpen: false,
      isApproveReportModalOpen: false,
      auditReportDetails: {},
      loading: false,
      rejectReason:"",
      rejectReasonError:"",
      error:"",
      showError:false,
      showSuccess:false,
      successMessage:"",
      userType:localStorage.getItem("userType")
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getAuditReportDetails()
  }

  updateAuditReportResponse = (responseJson:any) => {
    if(responseJson.message === "Audit Report Successfully verified"){
      if(this.state.rejectReason){
        this.setState({
          isRejectReportModalOpen:false,
          successMessage:"Audit Report Rejected Successfully!",
          showSuccess:true,
        })
      }else{
        this.setState({
          isApproveReportModalOpen:false,
          successMessage:"Audit Report Successfully Verified!",
          showSuccess:true,
        })
      }
      this.getAuditReportDetails()
    }else{
      this.setState({
        isApproveReportModalOpen:false,
        error:"Something Went Wrong!",
        showError:true,
      })
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(this.getAuditReportDetailsId === apiRequestCallId){
        this.setState({
          auditReportDetails:responseJson.data.attributes,
          loading:false
        })
      }
      if(this.updateAuditReportId === apiRequestCallId){
        this.updateAuditReportResponse(responseJson)
      }
    }
  }

  handleRejectReportModal = () => {
    this.setState({
      isRejectReportModalOpen: !this.state.isRejectReportModalOpen,
    });
  };

  handleApproveReportModal = () => {
    this.setState({
      isApproveReportModalOpen: !this.state.isApproveReportModalOpen,
    });
  };

  manageAuditReportApproval = () => {
    const data = {
      status:true
    }
    this.updateAuditReport(data)
  }

  handleRejectAuditReport = () => {
    if(this.state.rejectReason){
      const data = {
        status:false,
        note:this.state.rejectReason
      }
      this.updateAuditReport(data)
    }else{
      this.setState({
        rejectReasonError:"Please enter reason for reject this expense"
      })
    }
  }

  getAuditReportDetails = async () => {
    const societyAuditID = localStorage.getItem("society_id")
    const AuditId =  this.props.match.params.id || ""
    this.getAuditReportDetailsId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${societyAuditID}/bx_block_auditor/auditors/${AuditId}`,
    });
  }
  updateAuditReport = async (data:any) => {
    const societyID = localStorage.getItem("society_id")
    const AuditId =  this.props.match.params.id || ""
    this.updateAuditReportId = await this.apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/${societyID}/bx_block_report/audit_reports/${AuditId}/verify_report`,
      body:JSON.stringify(data)
    });
  }

}

// Customizable Area End