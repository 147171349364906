// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography, Select, InputAdornment, MenuItem } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon, filterIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import MyInvoicesController, { Props } from "./MyInvoicesController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { Building, Unit } from "./assets";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import InvoiceCard from "../../../components/src/InvoiceComponent/InvoiceCard.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends MyInvoicesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols my-invoice-page-content">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "my-invoice-navigation-icon")} onClick={() => window.history.back()} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text my-invoice-header-title">
                  {t("View Invoices")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <Box className={languageCondition(language, "", "my-invoice-sort")}>
                  <Menu menuButton={<img src={shortIcon} alt="" />}>
                    <MenuItemMenu className="my-invoice-asc-sort" onClick={() => this.setState({ selectedSort: "asc" })}>{t("Asc")}</MenuItemMenu>
                    <MenuItemMenu className="my-invoice-desc-sort" onClick={() => this.setState({ selectedSort: "desc" })}>{t("Desc")}</MenuItemMenu>
                  </Menu>
                </Box>
                <Box className={languageCondition(language, "", "my-invoice-filter")}>
                  <Menu menuButton={<img src={filterIcon} alt="" />}>
                    <MenuItemMenu className="all-filter-menu" onClick={() => this.setState({ selectedStatus: "" })}>{t("All")}</MenuItemMenu>
                    <MenuItemMenu className="paid-filter-menu" onClick={() => this.setState({ selectedStatus: "paid" })}>{t("Paid")}</MenuItemMenu>
                    <MenuItemMenu className="due-filter-menu" onClick={() => this.setState({ selectedStatus: "due" })}>{t("Due")}</MenuItemMenu>
                    <MenuItemMenu className="overdue-filter-menu" onClick={() => this.setState({ selectedStatus: "overdue" })}>{t("Overdue")}</MenuItemMenu>
                    <MenuItemMenu className="partial-filter-menu" onClick={() => this.setState({ selectedStatus: "partially_paid" })}>{t("Partially Paid")}</MenuItemMenu>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list my-invoice-content-box-main">
            <Grid container spacing={2} style={{ width: "90%" }} className={languageCondition(language, "arabic-grid", "")}>
              <Grid item xs={12}>
                <Select
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={Building} style={{ marginLeft: languageCondition(language, "0", "25px"), marginRight: languageCondition(language, "25px", "0") }} />
                    </InputAdornment>
                  }
                  className="my-invoice-building-filter-select selectBuildingBox"
                  fullWidth
                  disableUnderline
                  displayEmpty
                  value={this.state.selectedBuilding}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "100px",
                    height: "50px",
                    border: "1px solid #f0f0f0",
                    padding: "28px 0",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    this.setState({ selectedBuilding: value }, () => {
                      this.getUnitList(value);
                    });
                  }}
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "my-invoice-select-building")}>
                    {t("Building Name")}
                  </MenuItem>
                  {this.state.buildingList.map((building: any) => {
                    return (
                      <MenuItem value={building.id} key={building.id} className={languageCondition(language, "select-arabic-menu", "my-invoice-building-menu")}>
                        {building.attributes.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  className="my-invoice-unit-filter-select selectBuildingBox"
                  fullWidth
                  disableUnderline
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={Unit} style={{ marginLeft: languageCondition(language, "0", "25px"), marginRight: languageCondition(language, "25px", "0") }} />
                    </InputAdornment>
                  }
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "28px 0",
                    backgroundColor: "white",
                    borderRadius: "100px",
                    height: "50px",
                    border: "1px solid #f0f0f0",
                  }}
                  value={this.state.selectedUnit}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    this.setState({ selectedUnit: value });
                  }}
                  displayEmpty
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "my-invoice-select-unit")}>{t("Unit Number")}</MenuItem>
                  {this.state.unitList.map((unit: any) => {
                    return (
                      <MenuItem value={unit.id} key={unit.id} className={languageCondition(language, "select-arabic-menu", "my-invoice-unit-menu")}>
                        {unit.apartment_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid container spacing={2} style={{ display: "flex" }}>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="body1"
                      className={this.state.selectedTab === "MyInvoices" ? "tabInvoiceButtonActive" : "tabInvoiceButton"}
                      onClick={() => this.setState({ selectedTab: "MyInvoices" })}
                    >
                      {t("My Invoices")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="body1"
                      className={this.state.selectedTab === "TenantInvoices" ? "tabInvoiceButtonActive" : "tabInvoiceButton"}
                      onClick={() => this.setState({ selectedTab: "TenantInvoices" })}
                    >
                      {t("Tenant Invoices")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.selectedTab === "MyInvoices" ? (
                <>
                  {this.state.invoiceList.map((invoice: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={invoice.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyInvoices/Owner/${invoice.id}/${invoice.attributes.invoice_type}`,
                            state: { selectedInvoice: invoice },
                          })
                        }
                      >
                        <InvoiceCard
                          language={language}
                          date={invoice.attributes.title}
                          status={invoice.attributes.status}
                          tenant={invoice.attributes.owner_details.owner_name}
                          building={invoice.attributes.building_name}
                          unit={invoice.attributes.unit_number}
                          currency={invoice.attributes.currency}
                          amount={invoice.attributes.total_amount}
                          type={invoice.attributes.invoice_type}
                          cardType="Owner"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  {this.state.tenantInvoiceList.map((invoice: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={invoice.data.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyInvoices/Owner/${invoice.data.id}/${invoice.data.attributes.invoice_type}`,
                            state: { selectedInvoice: invoice.data },
                          })
                        }
                      >
                        <InvoiceCard
                          language={language}
                          date={invoice.data.attributes.title}
                          status={invoice.data.attributes.status}
                          tenant={invoice.data.attributes.tenant_details.tenant_name}
                          building={invoice.data.attributes.building_name}
                          unit={invoice.data.attributes.unit_number}
                          currency={invoice.data.attributes.currency}
                          amount={invoice.data.attributes.total_amount}
                          type={invoice.data.attributes.invoice_type}
                          cardType="Tenant"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                  {this.state.tenantRentInvoiceList.map((rent: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={rent.data.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyInvoices/Owner/${rent.data.id}/${rent.data.attributes.invoice_type}`,
                            state: { selectedInvoice: rent.data },
                          })
                        }
                      >
                        <InvoiceCard
                          language={language}
                          date={rent.data.attributes.title}
                          status={rent.data.attributes.status}
                          tenant={rent.data.attributes.tenant_details.tenant_name}
                          building={rent.data.attributes.building_name}
                          unit={rent.data.attributes.apartment_name}
                          currency={rent.data.attributes.currency}
                          amount={rent.data.attributes.rent_amount}
                          type={rent.data.attributes.invoice_type}
                          cardType="Tenant"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));
// Customizable Area End
