//Customizable area start
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation?: any;
  id: string;


}
interface S {
  email: string;
  open4: any;
  field2: any;
  initialValues: any;
  otp: any;
  token: any;
  language5:string;
}
interface SS {
  id: any;
}
export default class CompEmpForgotPasswordController extends CommonApiCallForBlockComponent<Props, S, SS> {
  ForgotPassID: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
email: '',
      otp: '',
      open4: false,
      field2: "",
      initialValues: {
        email: "",
      },
      token: '',
    language5:'ENG'
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }
  async componentDidMount() {
    await super.componentDidMount()
   
  
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId && responseJson) {
      await this.handleForgotPasswordResponse(apiRequestCallId, responseJson);
    }
  }
  
  async handleForgotPasswordResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.ForgotPassID) {
      if (!responseJson.errors) {
        this.setState({ otp: responseJson.email_otp, token: responseJson.meta.token });
        this.navigateToOTPPage();
      } else {
        this.handleError(responseJson);
      }
    }
  }
  
  navigateToOTPPage() {
    this.props.navigation.history.push('/CompanyEmployee/CMOTP', {
      otp: this.state.otp,
      token: this.state.token,
      email: this.state.email
    });
  }
  
  handleError(responseJson: any) {
    if (responseJson.errors[0].otp === 'Account not found') {
      this.errorModal(responseJson.errors[0].otp);
    }
  } 

  ConfirmEmail = async (values: any) => {
    this.setState({ email: values.email })
    this.ForgotPassID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: 'bx_block_forgot_password/employee_passwords/send_otp',
      body: JSON.stringify({
        data: {
          "attributes": {
            "email": values.email

          }
        }
      })
    });
  }

  handleOpen = (e:any) => {
    this.setState({
      open4: true,
    }); 
    this.setState({
            field2: e.currentTarget
          }) 
  }
}
// Customizable Area End

