// Customizable Area Start
import React from "react";
export const CommunityImageComponent = () => {
  return (
    <svg
      width="21.719727px"
      height="16.799805px"
      viewBox="0 0 21.719727 16.799805"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M20.6 16.2C20.3 16.2 20 15.9 20 15.6C20 12.7 18.4 10.1 15.8 8.9L15.7 8.8L15.3 8.6C15.1 8.4 15 8.2 15 8C15 7.8 15.1 7.6 15.3 7.5C16.3 6.9 17 5.8 17 4.6C17 2.7 15.5 1.2 13.6 1.2C13.3 1.2 13 0.9 13 0.6C13 0.3 13.3 0 13.6 0C14.7 0 15.8 0.4 16.7 1.2C18.6 2.9 18.8 5.8 17.1 7.7L16.7 8L17.1 8.3C19.6 9.9 21.2 12.6 21.1 15.6C21.2 15.9 20.9 16.2 20.6 16.2ZM14.6 16.2C14.3 16.2 14 15.9 14 15.6C14 12.1 11.1 9.2 7.6 9.2C4.1 9.2 1.2 12.1 1.2 15.6C1.2 15.9 0.9 16.2 0.6 16.2C0.3 16.2 0 15.9 0 15.6C0 12.6 1.8 9.9 4.5 8.7L5 8.4L4.6 8C3.6 7.2 3 5.9 3 4.6C3 2.1 5.1 0 7.6 0C10.1 0 12.2 2.1 12.2 4.6C12.2 5.9 11.6 7.2 10.6 8L10.1 8.4L10.7 8.7C13.4 9.9 15.2 12.6 15.2 15.6C15.2 15.9 14.9 16.2 14.6 16.2ZM7.59995 1.19995C5.69995 1.19995 4.19995 2.69995 4.19995 4.59995C4.19995 6.49995 5.69995 7.99995 7.59995 7.99995C9.49995 7.99995 11 6.49995 11 4.59995C11 2.69995 9.49995 1.19995 7.59995 1.19995Z"
          id="path_1"
        />
      </defs>
      <g id="users-(1)" transform="translate(0.30004883 0.2998047)">
        <g id="Shape">
          <path
            fill="none"
            strokeWidth="0.6"
            d="M20.6 16.2C20.3 16.2 20 15.9 20 15.6C20 12.7 18.4 10.1 15.8 8.9L15.7 8.8L15.3 8.6C15.1 8.4 15 8.2 15 8C15 7.8 15.1 7.6 15.3 7.5C16.3 6.9 17 5.8 17 4.6C17 2.7 15.5 1.2 13.6 1.2C13.3 1.2 13 0.9 13 0.6C13 0.3 13.3 0 13.6 0C14.7 0 15.8 0.4 16.7 1.2C18.6 2.9 18.8 5.8 17.1 7.7L16.7 8L17.1 8.3C19.6 9.9 21.2 12.6 21.1 15.6C21.2 15.9 20.9 16.2 20.6 16.2ZM14.6 16.2C14.3 16.2 14 15.9 14 15.6C14 12.1 11.1 9.2 7.6 9.2C4.1 9.2 1.2 12.1 1.2 15.6C1.2 15.9 0.9 16.2 0.6 16.2C0.3 16.2 0 15.9 0 15.6C0 12.6 1.8 9.9 4.5 8.7L5 8.4L4.6 8C3.6 7.2 3 5.9 3 4.6C3 2.1 5.1 0 7.6 0C10.1 0 12.2 2.1 12.2 4.6C12.2 5.9 11.6 7.2 10.6 8L10.1 8.4L10.7 8.7C13.4 9.9 15.2 12.6 15.2 15.6C15.2 15.9 14.9 16.2 14.6 16.2ZM7.59995 1.19995C5.69995 1.19995 4.19995 2.69995 4.19995 4.59995C4.19995 6.49995 5.69995 7.99995 7.59995 7.99995C9.49995 7.99995 11 6.49995 11 4.59995C11 2.69995 9.49995 1.19995 7.59995 1.19995Z"
            id="path_1"
          />
          <path
            stroke="none"
            fillRule="evenodd"
            d="M20.6 16.2C20.3 16.2 20 15.9 20 15.6C20 12.7 18.4 10.1 15.8 8.9L15.7 8.8L15.3 8.6C15.1 8.4 15 8.2 15 8C15 7.8 15.1 7.6 15.3 7.5C16.3 6.9 17 5.8 17 4.6C17 2.7 15.5 1.2 13.6 1.2C13.3 1.2 13 0.9 13 0.6C13 0.3 13.3 0 13.6 0C14.7 0 15.8 0.4 16.7 1.2C18.6 2.9 18.8 5.8 17.1 7.7L16.7 8L17.1 8.3C19.6 9.9 21.2 12.6 21.1 15.6C21.2 15.9 20.9 16.2 20.6 16.2ZM14.6 16.2C14.3 16.2 14 15.9 14 15.6C14 12.1 11.1 9.2 7.6 9.2C4.1 9.2 1.2 12.1 1.2 15.6C1.2 15.9 0.9 16.2 0.6 16.2C0.3 16.2 0 15.9 0 15.6C0 12.6 1.8 9.9 4.5 8.7L5 8.4L4.6 8C3.6 7.2 3 5.9 3 4.6C3 2.1 5.1 0 7.6 0C10.1 0 12.2 2.1 12.2 4.6C12.2 5.9 11.6 7.2 10.6 8L10.1 8.4L10.7 8.7C13.4 9.9 15.2 12.6 15.2 15.6C15.2 15.9 14.9 16.2 14.6 16.2ZM7.59995 1.19995C5.69995 1.19995 4.19995 2.69995 4.19995 4.59995C4.19995 6.49995 5.69995 7.99995 7.59995 7.99995C9.49995 7.99995 11 6.49995 11 4.59995C11 2.69995 9.49995 1.19995 7.59995 1.19995Z"
            id="path_1"
          />
        </g>
      </g>
    </svg>
  );
};
// Customizable Area End
