//@ts-ignore
//@ts-nocheck

import * as React from "react";
//Customizable Area Start
// custom components
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import {Building1, Hash, Hyperlink, user, User3} from "./assets";
import {withRouter} from 'react-router';
import '../assets/css/style.scss';
import {ErrorMessage, Field, Form, Formik} from "formik";
import FamilyController from "./FamilyController.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {withTranslation} from "react-i18next";

const errorStyleFamily = {
  color: "#F14E24",
  fontFamily: "Poppins",
  fontWeight: 300,
  fontSize: 14,
  marginTop: 5,
  marginLeft: 10
}
class NewFamily extends FamilyController {
  async componentDidMount() {
    this.getRelation()
    this.getIdType();
  }
  render() {
    //@ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (

      <>
        <Grid container spacing={2} className="auth-container AddFamily" dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols AddFamily" style={{ justifyContent: 'unset' }}>
            <Grid container>
              <Grid xs={12} className="newFamilyAdd AddFamily" style={{ display: 'flex', alignItems: 'center',paddingBottom:'10px',paddingTop:'10px',paddingLeft:"8px" }}>
                <ArrowBackIcon onClick={() => window.history.back()} className='btn newFamilyAdd AddFamily' style={{fontSize:"27px",marginRight:"10px"}}/>
                <p style={{ fontWeight: 600, fontSize: '18px' }} className="bold-text newFamilyAdd AddFamily">
                  {t("Family Members")}
                </p>
              </Grid>
            </Grid>
            <Divider/>
            <div>
              <Grid container className="main-content-block newFamilyAdd AddFamily" style={{marginTop:"15px",width:"95%"}}>
                <Grid xs={12} className={languageCondition(language, "arabic-grid inputPlaceholderRegistration newFamilyAdd", "inputPlaceholderRegistration newFamilyAdd")}>
                  <Formik initialValues={{
                    full_name: "", relation: "",
                    IDoption: "", IDnumber: "",
                    }}
                    id="formik"
                    validationSchema={this.addVehicleSchema()}
                    validateOnMount={true}
                    onSubmit={(values) => { this.createVehicle(values) }}
                  >
                    {({ values,
                      errors,
                      touched,
                      setFieldValue, setFieldError }) => (
                      <Form className="commonForm newFamilyAdd AddFamily" translate="yes" >
                        <Box className='formGroup newFamilyAdd AddFamily' style={{height:'121%'}}>
                          <Box style={{
                            display: 'flex', alignItems: 'center',
                            gap: '1rem',
                            marginTop:'1rem', marginBottom:'1.5rem'
                          }}>
                            <Avatar src={values.bannerUrl} style={{height:"65px",width:"65px"}} className="newFamilyAdd AddFamily"/>
                            <label for="file1"
                              style={{ color: '#FC8434',fontWeight:'bold'}}>
                              {t("Change Profile Picture")}
                            </label>
                            <input
                              id="file1"
                              type="file"
                              className="newFamilyAdd AddFamily"
                              onChange={(e: any) => {
                                this.handleSelectBanner(e, setFieldValue, setFieldError);
                              }}
                              style={{
                                position: "absolute", zIndex: 2,
                                cursor: "pointer", opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                          </Box>
                            <Typography
                              className="newFamilyAdd AddFamily"
                              style={errorStyleFamily}>
                              <ErrorMessage className="text-error" component="Typography" name="banner" />
                            </Typography>
                            <Typography
                              className="newFamilyAdd"
                              style={errorStyleFamily}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="bannerUrl" />
                            </Typography>

                          <Box
                            className="formInputGrp newFamilyAdd"
                            style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",backgroundColor:"#f9f9f9",marginTop:"14px"}}
                          >
                            <Field
                              className={languageCondition(language, "formInput_ar newFamilyAdd", "formInput fullName newFamilyAdd")}
                              name="full_name"
                              placeholder={t("Family Member Name")}

                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons fullName")}>
                              <img src={user} />
                            </span>
                          </Box>
                            <Typography
                              className="newFamilyAdd AddFamily"
                              style={errorStyleFamily}>
                              <ErrorMessage className="text-error" component="Typography" name="full_name" />
                            </Typography>
                          <Box className="formGroup customSelect newFamilyAdd" marginTop="20px">
                            <FormControl variant="outlined" className="newFamilyAdd">
                              <InputLabel id="demo-simple-select-outlined-label" className="newFamilyAdd" style={{ display: 'flex', alignItems: 'center', gap: '1rem',marginLeft:"40px" }}>
                                {t("Relation")}</InputLabel>
                              {/* <InputLabel id="demo-simple-select-outlined-label">Select User Type</InputLabel>  */}
                              <Select
                                  name="userType" className="formInput_select_family familyRelationAdd AddFamily selectRelation"
                                  labelId="demo-simple-select-outlined-label" id="family-select-outlined"
                                  displayEmpty style={{borderRadius:"25px"}} label="Relation"
                                  startAdornment={
                                    <img src={Hyperlink} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"20px"},{marginLeft:"5px",marginRight:"10px"})}/>
                                  }
                                  placeholder="Relation" value={values.relation}
                                  onChange={(e) => {  setFieldValue("relation", e.target.value) }}
                              >
                                <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "Relation")}>
                                  {t("Relation")}
                                </MenuItem>
                                {this.state.allRelation && this.state.allRelation.map((item) =>
                                    <MenuItem  className={languageCondition(language, "select-arabic-menu", "Relation")} key={item.id} value={item.id}>{item.name}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                            <ErrorMessage className="text-error addFamily" component="Typography" name="relation" />
                          </Box>

                          {/* Id type */}
                          <Box className="formGroup customSelect newFamilyAdd" marginTop="20px">
                            <FormControl variant="outlined" className="newFamilyAdd" >
                              <InputLabel id="demo-simple-select-outlined-label" className="newFamilyAdd" style={{ display: 'flex', alignItems: 'center', gap: '1rem',marginLeft:"40px" }}>
                                {t("ID Type")}</InputLabel>
                              {/* <InputLabel id="demo-simple-select-outlined-label">Select User Type</InputLabel>  */}
                              <Select
                                  name="userType"
                                  startAdornment={
                                    <img src={User3} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"20px"},{marginLeft:"5px",marginRight:"10px"})}/>
                                  }
                                  className="formInput_select_family familyIDAdd selectID" labelId="demo-simple-select-outlined-label"
                                  displayEmpty id="family-select-outlined" value={values.IDoption}
                                  style={{borderRadius:"25px"}} label="Relation"
                                  placeholder="ID Type"
                                  onChange={(e) => { setFieldValue("IDoption", e.target.value) }}
                              >
                                <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "IDType")}>
                                   {t("ID Type")}
                                </MenuItem>
                                {this.state.allIdType && this.state.allIdType.map((item) =>
                                    <MenuItem className={languageCondition(language, "select-arabic-menu", "IDType")} key={item.id} value={item.id}>{item.name}</MenuItem>)}
                              </Select>
                            </FormControl>
                            <ErrorMessage className="text-error newFamilyAdd addFamily" component="Typography" name="IDoption" />
                          </Box>
                          {/* car model */}
                          <Box
                            className="formInputGrp newFamilyAdd"
                            style={{border:"0.1px solid rgb(209 209 209 / 100%)",borderRadius:"25px",backgroundColor:"#f9f9f9" ,marginTop:"20px"}}
                          >
                            <Field
                              name="IDnumber"
                              placeholder={t("Enter ID Number")} className={languageCondition(language, "formInput_ar", "formInput IDNumber")}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons fullName")}>
                              <img src={Hash} style={{marginTop:"5px"}} />
                            </span>
                          </Box>
                            <Typography style={errorStyleFamily} className="AddFamily">
                              <ErrorMessage className="text-error addFamily" component="Typography" name="IDnumber" />
                            </Typography>
                          {/* car color */}
                        </Box>
                          <Box className={languageCondition(language,"customButton familyFormButton_ar","customButton familyFormButton")}>
                            <Button
                              variant="contained"
                              type="submit"
                              className="newFamilyAdd familyFormBottomButton addFamily"
                            >
                              {t("Add member to my family")}
                            </Button>
                          </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>

            </div>
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols addFamily">
            <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo addFamily" alt="" />
            </Box>
          </Grid>
        </Grid>
      </>

    )

  }

}
export default withTranslation()(withRouter(NewFamily))
//Customizable Area End
