// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import {array} from "yup";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
}

interface S {
  loading: boolean;
  first: boolean;
  second: boolean;
  third: boolean;

  id: any;
  Document: any;
  Index: number;
  fileIndex: number;
  Heading: string;
  anchorEl: any;
  open: boolean;
  newDoc: boolean;
  docDelete: boolean;
  File: any[];
  documentTitle: string;
  uploadDocument: any;

  teamReactStateList: any[];
  countriesList: any[];
  cityList: any[];
  complexList: any[];
  filterComplexList: any[];
  buildingList: any[];

  companyFilter: any;
  countryFilter: any;
  cityFilter: any;
  complexFilter: any;
  buildingFilter: any;
  isComplex: number | string;

  showError: boolean;
  error: string;

  isShareModalOpen: boolean;
  shareUrl: string;

  documentDetails: any;

  deleteDocId: any;

  addComplexList: any[];
  meetingList:any[];
  documentType:string;
}

interface SS {
  id: any;
}

export default class CompEmpDocumentController extends BlockComponent<Props, S, SS> {
  GetDocumentsCounts: any = "";
  GetDocumentList: any = "";
  UploadDocument: any = "";
  getCountryList: any = "";
  GetCityFilterList: any = "";
  GetComplexFilterList: any = "";
  GetBuildingFilterList: any = "";
  DeleteDocument: any = "";
  GetActionTeamReactEStateCallId: any = "";
  GetMultipleComplexList: any = "";
  getMeetingList:any="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      first: true,
      second: false,
      third: false,

      Index: -1,
      fileIndex: 0,
      Heading: "",
      anchorEl: null,
      open: false,
      newDoc: false,
      docDelete: false,
      id: null,

      Document: [
        {
          Id: 1,
          Doc: "Policy",
          Num: 0,
          responseKey: "policy_count",
          docType: "Policy",
          listApi: "bx_block_my_document/all_docs_society?document_type=policy",
        },
        {
          Id: 2,
          Doc: "Guidelines",
          Num: 0,
          docType: "Guideline",
          responseKey: "guideline_count",
          listApi: "bx_block_my_document/all_docs_society?document_type=guideline",
        },
        {
          Id: 3,
          Doc: "Roles",
          Num: 0,
          docType: "Role",
          responseKey: "role_count",
          listApi: "bx_block_my_document/all_docs_society?document_type=role",
        },
        {
          Id: 4,
          Doc: "Resolutions",
          Num: 0,
          docType: "Resolutions",
          responseKey: "resolution_count",
          listApi: "bx_block_my_document/all_docs_society?document_type=resolution",
        },
        {
          Id: 5,
          Doc: "Building Plans",
          Num: 0,
          docType: "Building_plan",
          responseKey: "building_plan_count",
          listApi: "bx_block_my_document/all_docs_society?document_type=building_plan",
        },
      ],

      File: [],
      documentTitle: "",
      uploadDocument: null,
      teamReactStateList: [],
      countriesList: [],
      cityList: [],
      complexList: [],
      filterComplexList: [],
      buildingList: [],

      companyFilter: "",
      countryFilter: "",
      cityFilter: "",
      complexFilter: "",
      buildingFilter: "",
      isComplex: 1,

      showError: false,
      error: "",

      isShareModalOpen: false,
      shareUrl: "",

      documentDetails: null,

      deleteDocId: "",

      addComplexList: [],
      meetingList:[],
      documentType:"",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleDocument = (key: any,name:any) => {
    this.setState({
      first: false,
      second: true,
      third: false,
      Index: key,
      documentType:name,
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetDocumentsCounts:
          this.handleDocumentsCounts(responseJson);
          break;
        case this.GetDocumentList:
          this.handleDocumentList(responseJson);
          break;
        case this.UploadDocument:
          this.handleDocumentUpload(responseJson);
          break;
        case this.DeleteDocument:
          this.handleDeleteDocumentResponse(responseJson);
          break;
        case this.GetActionTeamReactEStateCallId:
          this.handleActionTeamReactEstateResponse(responseJson);
          break;
        case this.getCountryList:
          this.showAllCountryResponse(responseJson);
          break;
        case this.GetCityFilterList:
          this.cityListResponse(responseJson);
          break;
        case this.GetComplexFilterList:
          this.handleComplexListResponse(responseJson);
          break;
        case this.GetBuildingFilterList:
          this.handleBuildingListResponse(responseJson);
          break;
        case this.GetMultipleComplexList:
          this.handleAddFormComplexListResponse(responseJson);
          break;
        case this.getMeetingList:
          this.handleGetMeetingList(responseJson);
          break;
        default:
          break;
      }
      this.setState({ loading: false });

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  upload: any;

  async componentDidMount(): Promise<void> {
    await this.getDocumentCounts();
    await this.getActionTeamReactEstate();
    await this.getCountryListAPI();
  }


  getActionTeamReactEstate = async () => {
    this.GetActionTeamReactEStateCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  handleActionTeamReactEstateResponse = (responseJson: any) => {
    if (responseJson && responseJson.compneys) {
      this.setState({ teamReactStateList: responseJson.compneys.map((item: any) => ({ label: item.company_name, value: item.id })) });
    }
  };

  handleGetMeetingList = (responseJson: any) => {
    if (responseJson && responseJson.meeting_list) {
      this.setState({ meetingList: responseJson.meeting_list.map((item: any) => ({ label: item.title, value: item.id })) });
    }
  }

  cityListResponse = (responseJson: any) => {
    if (responseJson && responseJson.city_list) {
      this.setState({ cityList: responseJson.city_list.map((item: any) => ({ label: item.city, value: item.city })) });
    }
  };

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.Index !== prevState.Index && this.state.Index !== -1) {
      await this.getDocumentsList(this.state.Index);
    }
  }

  handleAddDocument = (values: any) => {
    const formData = new FormData();
    if(this.state.documentType === "Resolutions"){
      formData.append("resolution[title]", values.title);
      formData.append("resolution[society_management_id]", values.complex);
      formData.append("resolution[meeting_id]", values.meeting);
      formData.append("resolution[attachments][]", values.file);
    }else{
      formData.append("title", values.title);
      formData.append("images", values.file);
      formData.append("society_ids[]", values.complex);
    }
    if (this.state.Index !== -1) {
      formData.append("document_type", this.state.Document[this.state.Index].docType);
    }
    this.uploadDocument(formData);
  };

  uploadDocument = async (data: any) => {
    this.setState({ loading: true });
    this.UploadDocument = await apiCall({
      method: "POST",
      endPoint: this.state.documentType === "Resolutions" ? "bx_block_my_document/resolutions/create_resolution" : "bx_block_my_document/create_document",
      body: data,
    });
  };

  handleDocumentUpload = (responseJson: any) => {
    if (responseJson && (responseJson.data || responseJson.resolution) ) {
      if (this.state.Index !== -1) {
        this.getDocumentsList(this.state.Index);
        this.newDocClose();
      }
    }
  };

  getCountryListAPI = async () => {
    this.setState({ loading: true });
    this.getCountryList = await apiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  getmeetingListAPI = async (id:any) => {
    this.setState({ loading: true });
    this.getMeetingList = await apiCall({
      method: "get",
      endPoint: `bx_block_my_document/resolutions/metting_list?society_management_id=${id}`,
    });
  };

  showAllCountryResponse = (res: any) => {
    if (res && res.data) {
      this.setState({ countriesList: res.data.countries.map((item: any) => ({ label: item, value: item })) });
    }
  };

  getDocumentsList = async (index: number) => {
    const { companyFilter, complexFilter, countryFilter, cityFilter, buildingFilter } = this.state;

    const company = companyFilter === "all" ? "" : companyFilter;
    const complex = complexFilter === "all" ? "" : complexFilter;
    const country = countryFilter === "all" ? "" : countryFilter;
    const city = cityFilter === "all" ? "" : cityFilter;
    const building = buildingFilter === "all" ? "" : buildingFilter;

    this.GetDocumentList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `${this.state.Document[index].listApi}&company=${company}&country=${country}&city=${city}&complex=${complex}&building=${building}`,
    });
  };

  getDocumentCounts = async () => {
    const { companyFilter, complexFilter, countryFilter, cityFilter, buildingFilter } = this.state;

    const company = companyFilter === "all" ? "" : companyFilter;
    const complex = complexFilter === "all" ? "" : complexFilter;
    const country = countryFilter === "all" ? "" : countryFilter;
    const city = cityFilter === "all" ? "" : cityFilter;
    const building = buildingFilter === "all" ? "" : buildingFilter;

    this.GetDocumentsCounts = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_my_document/document_count?company=${company}&country=${country}&city=${city}&complex=${complex}&building=${building}`,
    });
  };

  getCityFilterList = async (country: any) => {
    this.GetCityFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${country}`,
    });
  };

  getComplexFilterList = async (city?: string) => {
    this.GetComplexFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${this.state.countryFilter}`,
    });
  };

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.handleFilterComplexList();
      });
    }
  };

  getAddFormComplexFilterList = async (city?: string, country?: string) => {
    this.GetMultipleComplexList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${country}`,
    });
  };

  handleAddFormComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ addComplexList: responseJson.complexes.data.map((item: any) => ({ label: item.attributes.name, value: item.id })) });
    }
  };

  handleFilterComplexList = () => {
    if (this.state.isComplex === 0) {
      this.setState({ filterComplexList: this.state.complexList.map((item: any) => ({ label: item.attributes.name, value: item.id })) });
    } else if (this.state.isComplex === 1) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => !complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    } else if (this.state.isComplex === 2) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    }
  };

  getBuildingFilterList = async (society_management_id: any) => {
    this.GetBuildingFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${society_management_id}`,
    });
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })) });
    }
  };

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;
    if (stateParam === "companyFilter") {
      this.setState({ [stateParam]: value, countryFilter: "", cityFilter: "", complexFilter: "", buildingFilter: "" });
    }
    if (stateParam === "countryFilter") {
      this.setState({ [stateParam]: value, cityFilter: "", complexFilter: "", buildingFilter: "" }, () => {
        this.getCityFilterList(value);
      });
    }
    if (stateParam === "cityFilter") {
      this.setState({ [stateParam]: value, complexFilter: "", buildingFilter: "" }, () => {
        this.getComplexFilterList(value);
      });
    }
    if (stateParam === "complexFilter") {
      this.setState({ [stateParam]: value, buildingFilter: "" }, () => {
        this.getBuildingFilterList(value);
      });
    }
    if (stateParam === "buildingFilter") {
      this.setState({ [stateParam]: value });
    }
  };

  handleDocumentList = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({ File: responseJson.data });
    } else {
      this.setState({ File: [] });
    }
  };

  handleDocumentsCounts = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const documentsArray = [...this.state.Document];
      documentsArray.forEach((document) => {
        document.Num = responseJson.data[document.responseKey];
      });
      this.setState({ Document: documentsArray });
    }
  };

  handleDeleteDocument = async () => {
    this.setState({ loading: true });
    let endPoint: string = "";
    if (this.state.Document[this.state.Index].docType === "Resolutions") {
      endPoint = `bx_block_my_document/destroy_resolution/?id=${this.state.deleteDocId}`;
    } else {
      endPoint = `/bx_block_my_document/destroy_building_document?id=${this.state.deleteDocId}`;
    }
    this.DeleteDocument = await apiCall({
      method: "DELETE",
      endPoint: endPoint,
    });
  };

  handleDeleteDocumentResponse = (responseJson: any) => {
    const newFileList = this.state.File.filter((file) => file.id !== this.state.deleteDocId);
    this.setState({ File: newFileList }, () => {
      this.docDeleteClose();
    });
  };

  docDeleteClose = () => {
    this.setState({
      docDelete: !this.state.docDelete,
    });
  };

  newDocClose = () => {
    this.setState({
      newDoc: false,
      uploadDocument: {},
      documentTitle: "",
    });
  };

  newDocOpen = () => {
    this.setState({
      newDoc: true,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  readDocument = (item: any, key: any) => {
    this.setState({
      first: false,
      second: false,
      third: true,
      fileIndex: key,
      documentDetails: item,
    });
  };

  backToDoc = () => {
    this.setState({
      first: true,
      second: false,
      third: false,
      Index: -1,
      File: [],
    }, () => {
      this.getDocumentCounts();
    });
  };

  backToPolicy = () => {
    this.setState({
      first: false,
      second: true,
      third: false,
    });
  };

  validationAddForm = Yup.object().shape({
    title: Yup.string().required("Required").max(100, "Maximum length of title should be 100 character").matches(/\S/, "Required"),
    file: Yup.mixed().required("Required"),
    country: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    complex: Yup.array().when("isMeeting",{
      is:false,
      then:array().min(1, "Required"),
    }),
    meetingComplex:Yup.string().when("isMeeting",{
      is:true,
      then:Yup.string().required("Required"),
    }),
    meeting:Yup.string().when("isMeeting",{
      is:true,
      then:Yup.string().required("Required"),
    })
  });

  validateSize = (event: any, input: any) => {
    const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/bmp", "image/gif", "application/vnd.ms-excel", "image/jpg", "text/csv"];
    const fileSize = input.files[0].size / 1024 / 1024;
    return this.ValidationCondition(event, SUPPORTED_FORMATS, input, fileSize);
  };

  handleShareModal = () => {
    this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
  };

  ValidationCondition = (event: any, SUPPORTED_FORMATS: any, input: any, fileSize: any) => {
    if (fileSize > 2) {
      this.setState({ showError: true, error: "File size exceeds 2 MiB" });
      event.target.value = null;
      return true;
    } else if (SUPPORTED_FORMATS.includes(input.files[0].type)) {
      this.setState({ showError: true, error: "Only Pdf, Docs and Text file support" });
      event.target.value = null;
      return true;
    } else {
      return false;
    }
  };
}
// Customizable Area End
