//Customizable area start
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation?: any;
  id: string;


}
interface S {
  otp: any;
  email: string;
  emailopen1: any;
  fieldd: any;
  initialValues: any;
  token: any;
  newemail: any;
  otpSent: boolean;
  resendTimer: any;
  isResendDisabled: boolean;
  newotp: any;
  language2:any;

}interface SS {
  id: any;

}
export default class CompEmpOTPController extends CommonApiCallForBlockComponent<Props, S, SS> {
  verifyOtpApiCallId: any
  resendTimerInterval: any
  ForgotPassID: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
      otp: '',
      email: '',
      emailopen1: false,
      fieldd: "",
      initialValues: {
        email: "",
        password: ''
      },
      token: '',
      newemail: '',
      otpSent: false,
      resendTimer: 10,
      isResendDisabled: false,
      newotp: '',
      language2:'ENG',
   
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentDidMount() {
    await super.componentDidMount()
    this.getotp()
   
  }
  
  handleChange = (otp: any) => {this.setState({ otp })};

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId && responseJson) {
      await this.handleApiResponses(apiRequestCallId, responseJson);
    }
  }

  async handleApiResponses(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.verifyOtpApiCallId) {
      await this.handleVerifyOtpApiResponse(responseJson);
    } else if (apiRequestCallId === this.ForgotPassID) {
      await this.handleForgotPasswordApiResponse(responseJson);
    }
  }

  async handleVerifyOtpApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      console.log(responseJson.messages[0].otp);
      this.props.navigation.history.push('/CompanyEmployee/CMConfirmPass', {
        token: this.state.token
      });
    } else if (responseJson.errors[0].pin === 'OTP has expired, please request a new one.') {
      this.errorModal(responseJson.errors[0].pin);
      console.log(responseJson.errors[0].pin);
    } else if (responseJson.errors[0].otp === 'Invalid OTP code') {
      this.errorModal(responseJson.errors[0].otp);
      console.log(responseJson.errors[0].otp);
    }
  }

  async handleForgotPasswordApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      localStorage.setItem("otp", JSON.stringify(responseJson.email_otp));
      console.log('otp:', responseJson.email_otp);
      this.setState({ newotp: responseJson.email_otp, token: responseJson.meta.token });
      this.errorModal("OTP sent on registered email or mobile. Please enter OTP");
    } else if (responseJson.errors[0].otp === 'Account not found') {
      this.errorModal(responseJson.errors[0].otp);
      console.log(responseJson.errors[0].otp);
    }
  }

  verifyOtp = () => {


    let raw = JSON.stringify({
      "data": {
        "otp_code": this.state.otp
      }
    });
    const header = {
      "token": this.state.token,
      "Content-Type": "application/json",

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      (raw)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  hideMiddlePart = (email: any) => {
    const [username, domain] = email.split('@');
    const hiddenUsername = `${username.slice(0, 2)}${'*'.repeat(username.length - 4)}${username.slice(-2)}`;
    return `${hiddenUsername}@${domain}`;
  };
  startResendTimer = () => {
    this.setState({ otpSent: true, isResendDisabled: true, resendTimer: 60 });

    this.resendTimerInterval = setInterval(() => {
      if (this.state.resendTimer > 0) {
        this.setState((prevState) => ({ resendTimer: prevState.resendTimer - 1 }));
      } else {
        this.setState({ isResendDisabled: false });
        this.setState({ otpSent: false });
        clearInterval(this.resendTimerInterval);

      }
    }, 1000)
  };
  sendOTP = () => {


    this.ConfirmEmail()
    this.setState({ otpSent: true, });
    this.setState({ isResendDisabled: true })
    this.startResendTimer();


  };

  getotp = () => {

    const getotp =
      this.props.navigation.history?.location?.state?.otp;
    console.log(getotp,)

    this.setState({ token: this.props.navigation.history?.location?.state?.token })
    this.setState({ email: this.props.navigation.history?.location?.state?.email })


    const newemail = this.hideMiddlePart(this.state.email)
    this.setState({ newemail: newemail })
    this.startResendTimer()

  }


  ConfirmEmail = async () => {

    this.ForgotPassID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: 'bx_block_forgot_password/employee_passwords/send_otp',
      body: JSON.stringify({
        data: {
          "attributes": {
            "email": this.state.email

          }
        }
      })
    });
  }
  handleOpen = (e:any) => {
    this.setState({
      emailopen1: true,
    }); 
    this.setState({
            fieldd: e.currentTarget
          }) 
  }
  
}
// Customizable Area End

