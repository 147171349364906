// Customizable Area Start
import * as React from "react";
import {
  Grid,
  Box,
  withStyles,
  Container,
  Link,
  Typography,
  IconButton,
  Drawer,
  Divider,
  Avatar,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
} from "@material-ui/core";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import DashboardCard from "../../../components/src/DashboardCard";
import CloseIcon from "@material-ui/icons/Close";
import DashboardController, { Props } from "./DashboardController.web";
import {
  keyhand,
  hamburgerIcon,
  globalIcon,
  chatIcon,
  SidebarProfile,
  SidebarFee,
  SidebarNeighbor,
  SidebarFaq,
  SidebarLogout,
  SidebarIncident,
  SidebarLogoutDialog,
  DashboardIncident,
  DashboardAnnouncement,
  DashboardFacility,
  DashboardFee,
  DashboardPoll,
  DashboardInfo,
  DashboardMeeting,
  DashboardClass,
  DashboardDocument,
  DashboardLease,
  DashboardFamily,
  DashboardVehicle,
  RPaymentIcon,
  RSuggestionIcon,
  RVisitorIcon,
} from "./assets";
import { withTranslation } from "react-i18next";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import moment from "moment";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import NavigateBeforeSharpIcon from '@material-ui/icons/NavigateBeforeSharp';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

const MenuList = [
  {
    name: "Profile",
    url: "profile",
    img: SidebarProfile,
  },
  {
    name: "Fees & Payments",
    url: "/FeesAndPayment",
    img: SidebarFee,
  },
  {
    name: "My Incidents",
    url: "/IncidentListing",
    img: SidebarIncident,
  },
  {
    name: "My Neighbors",
    url: "/NeighboursListing",
    img: SidebarNeighbor,
  },
  {
    name: "FAQ",
    url: "/FaqComplexList",
    img: SidebarFaq,
  },
];

class ResidentDashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n?.language;

    return (
      <>
        <Box className={classes.ownerDashboard} dir={languageCondition(language, "rtl", "ltr")} style={{ background: "#F7F9FE", height: "100vh" }}>
          <Drawer dir={languageCondition(language, "rtl", "ltr")} anchor={languageCondition(language, "right", "left")} open={this.state.isMenuOpen} onClose={() => this.toggleDrawer()}>
            <Box className="dashboard-sidebar">
              <Box className="close-menu">
                <IconButton onClick={() => this.toggleDrawer()}>
                  <CloseIcon />
                </IconButton>{" "}
                <span>{t("Menu")}</span>
              </Box>
              <Divider />
              <div className="user-info">
                <Avatar alt="Remy Sharp" src={this.state.profileData?.attributes?.profile_pic?.url} />
                <h4 className="bold-text">{this.handleValidText(this.state.profileData?.attributes?.full_name?.name)}</h4>
                <p>{this.handleValidText(this.state.profileData?.attributes?.email?.email)}</p>
              </div>
              <Divider />
              <List className="menu-list resident-dashboard-menu-list">
                {MenuList.map((menu, index) => (
                  <ListItem key={index}>
                    <Link className="list-box resident-dashboard-link" href={menu.url}>
                      <div className="list-menu">
                        <div className="image">
                          <img src={menu.img} alt="" />
                        </div>
                        <p className="resident-dashboard-menu-name">{t(menu.name)}</p>
                      </div>
                      {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                    </Link>
                  </ListItem>
                ))}
                <ListItem onClick={() => this.handleLogoutModal()}>
                  <div className="list-box resident-dashboard-logout-box">
                    <div className="list-menu">
                      <div className="image resident-dashboard-logout-img">
                        <img src={SidebarLogout} alt="" />
                      </div>
                      <p className="resident-dashboard-logout-text">{t("Logout")}</p>
                    </div>
                    {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                  </div>
                </ListItem>
              </List>
            </Box>
          </Drawer>
          <Grid container className="resident-dashboard-container">
            <Grid item xs={12} md={7}>
              <Box display={{ xs: "flex", md: "flex" }} className="menu resident-dashboard-head-box">
                <div className="left-icon resident-dashboard-left-box">
                  <IconButton onClick={() => this.toggleDrawer()}>
                    <img src={hamburgerIcon} alt="" />
                  </IconButton>
                  <span className="complex-name">{localStorage.getItem("complexName")}</span>
                </div>
                <div className="right-icon resident-dashboard-lang-menu" style={{ display: "flex", gap: "15px" }}>
                  <Box>
                    <Menu
                      className="chairman-lang-menu"
                      arrow={true}
                      align="center"
                      menuButton={<img src={globalIcon} alt="GlobalIcon" />}
                    >
                      <MenuItem
                        className={this.handleMenuClass("en")}
                        onClick={() => this.handleEngLngChange()}
                      >
                        English
                      </MenuItem>
                      <MenuItem
                        className={this.handleMenuClass("ar")}
                        onClick={() => this.handleAreLngChange()}
                      >
                        Arabic
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box className="resident-dashboard-chat-box">
                    <Link href="/inbox" style={{ margin:"0" }}>
                      <img src={chatIcon} alt="GlobalIcon" />
                    </Link>
                  </Box>
                  <Box className="resident-dashboard-notification-box">
                    <Link href="/Notifications" style={{ margin:"0" }}>
                      {this.handleNotificationIcon()}
                    </Link>
                  </Box>
                </div>
              </Box>
              <Container className="dashboard">
                <Grid container spacing={1} style={{ marginTop: 15 }}>
                  <Grid item xs={12} sm={12} className="title">
                    <Typography variant="h6" className="bold-text">
                      {t("Building Services")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/IncidentListing">
                      <DashboardCard
                        image={DashboardIncident}
                        heading={t("Incidents")}
                        title={t("Open")}
                        value={this.state.buildingCategory && this.state.buildingCategory.incidents.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Announcement">
                      <DashboardCard
                        image={DashboardAnnouncement}
                        heading={t("Announcements")}
                        title={t("Total")}
                        value={this.state.buildingCategory && this.state.buildingCategory.announcements.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/Visitors">
                      <DashboardCard
                        image={RVisitorIcon}
                        heading={t("Visitors")}
                        title={t("Scheduled")}
                        value={this.state.buildingCategory && this.state.buildingCategory.visitors.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/FacilityReservation">
                      <DashboardCard
                        image={DashboardFacility}
                        heading={t("Facility Reservation")}
                        title={t("Scheduled")}
                        value={this.state.buildingCategory && this.state.buildingCategory.facility_reservations.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/FeesAndPayment">
                      <DashboardCard
                        image={DashboardFee}
                        heading={t("Management Fees")}
                        title={t("Paid On")}
                        value={moment(this.state.buildingCategory?.management_fees?.count, "DD-MM-YYYY hh:mm:ss").format("DD/MM/YYYY")}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/pollsSurvey">
                      <DashboardCard
                        image={DashboardPoll}
                        heading={t("Survey")}
                        title={t("Ongoing")}
                        value={this.state.buildingCategory && this.state.buildingCategory.poll_surveys.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/ComplexList">
                      <DashboardCard
                        value={moment(this.state.buildingCategory?.building_info_and_rules?.count).utc().fromNow()}
                        title={t("Last Uploaded")}
                        heading={t("Building Info & Rules")}
                        image={DashboardInfo}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/MyMeetings">
                      <DashboardCard
                        image={DashboardMeeting}
                        heading={t("Meetings")}
                        title={t("Scheduled")}
                        value={this.state.buildingCategory?.meeting?.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/ClassifiedListing">
                      <DashboardCard
                        image={DashboardClass}
                        heading={t("Classifieds")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.buildingCategory?.classifieds?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/BuildingDocuments">
                      <DashboardCard
                        image={DashboardDocument}
                        heading={t("Building Documents")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.buildingCategory?.building_documents?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: 15, marginBottom:"100px"}}>
                  <Grid item xs={12} sm={12} className="title">
                    <Typography variant="h6" className="bold-text">
                      {t("Personal Services")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/MyLeaseList">
                      <DashboardCard
                        image={DashboardLease}
                        heading={t("My Lease")}
                        title={t("Total")}
                        value={this.state.personalCategory && this.state.personalCategory.my_lease.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/veichleList">
                      <DashboardCard
                        image={DashboardVehicle.default}
                        heading={t("My Vehicles")}
                        title={t("Registered")}
                        value={this.state.personalCategory && this.state.personalCategory.my_vehicles.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/familylist">
                      <DashboardCard
                        image={DashboardFamily}
                        heading={t("My Family")}
                        title={t("Registered Members")}
                        value={this.state.personalCategory && this.state.personalCategory.my_family.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/FeesAndPayment">
                      <DashboardCard
                        image={RPaymentIcon}
                        heading={t("Fees & Payment")}
                        title={t("Last Paid")}
                        value={moment(this.state.buildingCategory?.fees_and_payments?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/suggestionListing">
                      <DashboardCard
                        image={RSuggestionIcon}
                        heading={t("My Suggestions")}
                        title={t("Total")}
                        value={this.state.personalCategory && this.state.personalCategory.my_suggesition.count}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/PersonalDocument">
                      <DashboardCard
                        image={DashboardDocument}
                        heading={t("My Documents")}
                        title={t("Last Uploaded")}
                        value={moment(this.state.personalCategory?.my_documents?.count).utc().fromNow()}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Link href="/AddRentPayment">
                      <DashboardCard
                        image={keyhand}
                        heading={t("Register Rent Payment")}
                        title={t("Register Rent")}
                        value={0}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={this.state.isLogoutModalOpen}
          onClose={() => this.handleLogoutModal()}
          fullWidth
          className="delete-document personal"
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <DialogContent className="resident-logout-content-box">
            <Box textAlign="center">
              <img src={SidebarLogoutDialog} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text resident-logout-heading">
                {t("Are you sure you want to logout?")}
              </Typography>
              <Typography variant="body1">{t("You will be returned to the login screen")}</Typography>
              <DialogActions className="dialog-button-group resident-logout-btn-box">
                <Button onClick={() => this.logout()}>{t("Logout")}</Button>
                <Button onClick={() => this.handleLogoutModal()}>{t("Cancel")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default withTranslation()(withStyles(DashboardStyleWeb)(ResidentDashboard));

// Customizable Area End
