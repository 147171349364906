// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  i18n: {
    language: string;
  };
}

interface S {
  selectedTab: any;
  selectedStatus: string;
  selectedBuilding: string;
  selectedSort: string;
  selectedUnit: string;
  buildingList: any[];
  unitList: any[];
  receiptList: any[];
  tenantReceiptList: any[];
  tenantRentReceiptList: any[];
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getBuildingListCallId: any;
  getUnitListCallId: any;
  getTenantReceiptListCallId: any;
  getReceiptListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedTab: "MyInvoices",
      selectedStatus: "",
      selectedSort: "asc",
      selectedBuilding: "",
      selectedUnit: "",
      buildingList: [],
      unitList: [],
      receiptList: [],
      tenantReceiptList: [],
      tenantRentReceiptList: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.getReceiptListCallId:
          this.getReceiptListResponse(responseJson);
          break;
        case this.getTenantReceiptListCallId:
          this.getTenantReceiptListResponse(responseJson);
          break;
        case this.getBuildingListCallId:
          this.getBuildingListResponse(responseJson);
          break;
        case this.getUnitListCallId:
          this.getUnitListResponse(responseJson);
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  getReceiptListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ receiptList: responseJson.data });
    }
  };

  getTenantReceiptListResponse = (responseJson: any) => {
    if (responseJson && responseJson.tenant_management_receipts && responseJson.tenant_rent_receipts) {
      this.setState({
        tenantReceiptList: responseJson.tenant_management_receipts,
        tenantRentReceiptList: responseJson.tenant_rent_receipts,
      });
    }
  };

  getBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ buildingList: responseJson.data });
    }
  };

  getUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.units) {
      this.setState({ unitList: responseJson.units });
    }
  };

  async componentDidMount() {
    this.getBuildingList();
    this.getReceiptList();
    this.getTenantReceiptList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.selectedStatus !== this.state.selectedStatus ||
      prevState.selectedSort !== this.state.selectedSort ||
      prevState.selectedBuilding !== this.state.selectedBuilding ||
      prevState.selectedUnit !== this.state.selectedUnit
    ) {
      await this.getReceiptList();
      await this.getTenantReceiptList();
    }
  }

  getReceiptList = async () => {
    const { selectedStatus, selectedSort, selectedBuilding, selectedUnit } = this.state;
    this.getReceiptListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/receipts/my_receipts_list?sort_by=${selectedSort}&invoice_type=${selectedStatus}&building_management_id=${selectedBuilding}&unit_id=${selectedUnit}`,
    });
  };

  getTenantReceiptList = async () => {
    const { selectedStatus, selectedSort, selectedBuilding, selectedUnit } = this.state;
    this.getTenantReceiptListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/receipts/tenant_receipts_list?sort_by=${selectedSort}&invoice_type=${selectedStatus}&building_management_id=${selectedBuilding}&unit_id=${selectedUnit}`,
    });
  };

  getBuildingList = async () => {
    this.getBuildingListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_rent_payment/buildings`,
    });
  };

  getUnitList = async (building: any) => {
    this.getUnitListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/my_unit_list?building_management_id=${building}`,
    });
  };
}

// Customizable Area End
