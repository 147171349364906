// Customizable Area Start
import React from "react";
export const InvoiceImageComponent = () => {
  return (
    <svg
      width="20.469238px"
      height="20.469727px"
      viewBox="0 0 20.469238 20.469727"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="invoice-(1)" transform="translate(0.25 0.25)">
        <path
          d="M4.37281 6.87281C4.02632 6.87281 3.74561 6.59211 3.74561 6.24561C3.74561 5.89912 4.02632 5.61404 4.37281 5.61404L11.8596 5.61404C12.2061 5.61404 12.4868 5.89474 12.4868 6.24123C12.4868 6.58772 12.2061 6.86842 11.8596 6.86842L4.37281 6.86842L4.37281 6.87281Z"
          id="Path"
          stroke-width="0.5"
        />
        <path
          d="M4.37281 10.614C4.02632 10.614 3.74561 10.3333 3.74561 9.98684C3.74561 9.64035 4.02632 9.35965 4.37281 9.35965L11.8596 9.35965C12.2061 9.35965 12.4868 9.64035 12.4868 9.98684C12.4868 10.3333 12.2061 10.614 11.8596 10.614L4.37281 10.614Z"
          id="Path"
          stroke-width="0.5"
        />
        <path
          d="M4.37281 14.3553C4.02632 14.3553 3.74561 14.0746 3.74561 13.7281C3.74561 13.3816 4.02632 13.1009 4.37281 13.1009L8.11403 13.1009C8.46053 13.1009 8.74123 13.3816 8.74123 13.7281C8.74123 14.0746 8.46053 14.3553 8.11403 14.3553L4.37281 14.3553Z"
          id="Path"
          stroke-width="0.5"
        />
        <path
          d="M2.5 19.9693C1.12281 19.9693 0 18.8465 0 17.4693L0 2.5C0 1.12281 1.12281 0 2.5 0L13.7281 0C15.1053 0 16.2281 1.12281 16.2281 2.5L16.2281 9.35526L19.3421 9.35526C19.6886 9.35526 19.9693 9.63596 19.9693 9.98246L19.9693 17.4693C19.9693 18.8465 18.8465 19.9693 17.4693 19.9693L2.5 19.9693ZM16.2281 18.7149L17.4693 18.7149C18.1535 18.7149 18.7105 18.1579 18.7105 17.4737L18.7105 10.614L16.2281 10.614L16.2281 18.7149ZM2.5 1.25877C1.81579 1.25877 1.25877 1.81579 1.25877 2.5L1.25877 17.4693C1.25877 18.1535 1.81579 18.7105 2.5 18.7105L14.9693 18.7105L14.9693 2.5C14.9693 1.81579 14.4123 1.25877 13.7281 1.25877L2.5 1.25877Z"
          id="Shape"
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
