export const complexList = {
  data: {
    complex_list: [
      {
        id: 28,
        name: "The Urban Haven",
        description: "",
        complex_area: "70000",
        maintenance_per_square_feet: 5000.0,
        measurement_unit: "Sqft",
        ticket_days_configuration: 0,
        contigency: 7.0,
        zone_multiplier: 10.0,
        late_charge_percentage: "12.0",
        value_added_tax: "5.0",
        is_building: true,
        is_building_list: [
          {
            id: 39,
            name: "The Urban Haven",
            society_management_id: 28,
            per_floor_unit: 2,
            generation_methods: "A-101, A-102, A-103",
            number_of_floor: 2,
            building_area: "70000",
          },
        ],
      },
    ],
  },
};

export const singleComplexMockData = {
  id: 28,
  name: "The Urban Haven",
  description: "",
  complex_area: "70000",
  maintenance_per_square_feet: 5000.0,
  measurement_unit: "Sqft",
  ticket_days_configuration: 0,
  contigency: 7.0,
  zone_multiplier: 10.0,
  late_charge_percentage: "12.0",
  value_added_tax: "5.0",
  is_building: false,
  is_building_list: [
    {
      id: 39,
      name: "The Urban Haven",
      society_management_id: 28,
      per_floor_unit: 2,
      generation_methods: "A-101, A-102, A-103",
      number_of_floor: 2,
      building_area: "70000",
    },
  ],
};

export const buildingListDummy = {
  data: {
    buildings: [
      {
        id: 10,
        name: "Nilamber Prime-1",
        society_management_id: 11,
        description:
          "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
        created_at: "2022-09-23T10:22:40.723Z",
        updated_at: "2022-10-07T06:43:36.533Z",
        per_floor_unit: 4,
        generation_methods: "1, 2, 3",
        number_of_floor: 10,
        building_area: "100000",
        account_id: null,
        lat: null,
        long: null,
        city: null,
      },
    ],
  },
};

export const commonAreaListDummy = {
  data: [
    {
      id: "13",
      type: "common_area",
      attributes: {
        name: "swimming pool",
        details: "Swimming Poll",
        total_area: "55000",
        reservation_fee: 500,
        currency: {
          id: 1,
          currency: "USD",
          created_at: "2022-09-20T09:14:07.234Z",
          updated_at: "2023-07-28T11:56:13.843Z",
          currency_ar: "دولار أمريكي",
        },
        society_management_id: 11,
        photos: [
          {
            url: "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0VIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c78092e32e1a10ffb4ba97e504c75a3c1a47446f/imageName.jpg",
            content_type: "image/jpeg",
            file_name: "imageName.jpg",
          },
          {
            url: "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--11f76ed9944eac85770adf26ffbcf566ef78436a/imageName.jpg",
            content_type: "image/jpeg",
            file_name: "imageName.jpg",
          },
        ],
        floor_plan: {
          url: "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBc1FDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a11a9b31d185bf571fe2333e6eeabe0643c5ea19/Testing.pdf",
          content_type: "application/pdf",
          file_name: "Testing.pdf",
        },
      },
    },
  ],
};

export const unitDummyList = {
  "data": {
    "apartment_list": [
      {
        "id": 140,
        "society_management_id": 7,
        "building_management_id": 5,
        "created_at": "2022-09-02T04:36:26.267Z",
        "updated_at": "2023-08-30T04:37:52.920Z",
        "apartment_name": "1902",
        "floor_number": 19,
        "size": "9000",
        "purchase_price": "10000",
        "configuration": "2 BHK",
        "purchase_date": "2023-08-28",
        "unit_type": "Rented",
        "status": "Empty",
        "current_valuation": "100000",
        "monthly_renting_income": "1000",
        "account_id": null
      }
    ]
  }
}

export const facilityDetailsMock = {
  data: {
    id: "214",
    type: "facility_reservation",
    attributes: {
      date: "05-Aug-2023",
      status: "Pending",
      note: null,
      description: null,
      account_id: 294,
      paid_on: null,
      rent: 1500,
      Owner_name: "Ownerkt",
      start_time: "10:29",
      end_time: "13:32",
      unit_number: {
        id: 268,
        society_management_id: 11,
        building_management_id: 10,
        created_at: "2022-09-23T10:22:40.815Z",
        updated_at: "2023-02-07T09:54:41.066Z",
        apartment_name: "7",
        floor_number: 2,
        size: "10000",
        purchase_price: "200000",
        configuration: "3 BHK",
        purchase_date: "2023-01-25",
        unit_type: "Rented",
        status: "Empty",
        current_valuation: "200000",
        monthly_renting_income: "2000",
        account_id: null,
      },
      building: {
        id: 10,
        name: "Nilamber Prime-1",
        society_management_id: 11,
        description:
          "A building should be regarded as residential building when more than half of the floor area is used for dwelling purposes. Other buildings should be regarded as non-residential",
        created_at: "2022-09-23T10:22:40.723Z",
        updated_at: "2022-10-07T06:43:36.533Z",
        per_floor_unit: 4,
        generation_methods: "1, 2, 3",
        number_of_floor: 10,
        building_area: "100000",
        account_id: null,
        lat: null,
        long: null,
        city: null,
      },
      common_area: {
        id: 13,
        name: "swimming pool",
        society_management_id: 11,
        created_at: "2022-09-23T10:22:40.721Z",
        updated_at: "2023-07-06T06:57:43.071Z",
        details: "Swimming Poll",
        total_area: "55000",
        currency_id: 1,
        reservation_fee: 500,
      },
      currency: {
        id: 1,
        currency: "USD",
        created_at: "2022-09-20T09:14:07.234Z",
        updated_at: "2023-07-28T11:56:13.843Z",
        currency_ar: "دولار أمريكي",
      },
    },
  },
};

export const facilityData = {
  buildingName: "Nilamber Prime-1",
  id: 10,
  date: "05-Aug-2023",
  timeFrom: "10:29",
  timeTo: "13:32",
  ComplexId: 11,
  unitId: 268,
  areaReserve: 10,
};
