// Customizable Area Start
import React from "react";
import "../../dashboard/src/Dashboard.web.css";
import { Typography, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";
import { TenantLogo } from "../../dashboard/src/assets";
import AuditorController,{Props} from "./AuditorSidebarController.web";
import {MyTeamImageComponent} from "../../dashboard/src/SidebarImages/MyTeamImage.web";
import {ComplexImageComponent} from "../../dashboard/src/SidebarImages/ComplexImage.web";
import {DocumentImageComponent} from "../../dashboard/src/SidebarImages/DocumentImage.web";
import {DashboardImageComponent} from "../../dashboard/src/SidebarImages/DashboardImage.web";
import {ChatImageComponent} from "../../dashboard/src/SidebarImages/ChatImage.web";
import {HelpImageComponent} from "../../dashboard/src/SidebarImages/HelpImage.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class AuditorSideBar extends AuditorController {
  constructor(props: Props) {
    super(props);
  }

  auditorBarColorHandle = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight";
      }
    }
  };

  visitorBarBlueColorHandle = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight-blue";
      }
    }
  };

  iconColorHandle = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight-blue";
      }
    }
  }


  render() {
    const { t,i18n }: any = this.props;
    const {language} = i18n // for get the current Language
    const pathName = window.location.pathname;
    return (
      <Box style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
        <Box className="AccordinoList">
          {/* Dashboard */}
          <Accordion  id={"dashboard"} expanded={this.state.expanded == `panel1`} onChange={this.handleAccordinoChange(`panel1`)}>
            <AccordionSummary
              expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
              className="AuditorDashboard"
              aria-controls={"panel2bh-content"}
              id={"Dashboards"}
              style={dashBoard.ListItem}
            >
              <Typography id={"dashboard"} className={`SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/AuditorDashboard","/DashboardActions","/AuditReport/"])}`}>
                <DashboardImageComponent/>
              </Typography>
              <Typography className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/AuditorDashboard","/DashboardActions","/AuditReport/"])}`}>{t("My Dashboard")}</Typography>
            </AccordionSummary>

            <AccordionDetails id="GeneralDashboard" className="AuditorDashboardLink" onClick={() => {
              //@ts-ignore
              this.props.history.push("/AuditorDashboard");
            }}>
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.auditorBarColorHandle(pathName,["/AuditorDashboard","/AuditReport/"])}`}>
                {t("General Dashboard")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              className="AuditorDashboardActions"
              id="actionAssinedToMe"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/DashboardActions");
              }}
            >
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.auditorBarColorHandle(pathName,["/DashboardActions"])}`}>
                {t("Action Assigned to me")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* My Team */}
          <Box className="SingleLink">
            <Typography className={`SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/TeamMembers/Core_member","/TeamMember/userDetails"])}`} >
              <MyTeamImageComponent/>
            </Typography>
            <div
              className="TeamMembersNavigation"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/TeamMembers/Core_member");
              }}
            >
              <Typography style={{marginLeft:"0px"}} className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/TeamMembers/Core_member","/TeamMember/userDetails"])}`}>{t("Building's Management")}</Typography>
            </div>
          </Box>
        
          {/* Community Management */}
          <Box className="SingleLink ComplexAudiorBox" style={{marginTop:"40px"}}>
            <Typography className={`SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/Complex","/Building","/UnitDetail"])}`}>
              <ComplexImageComponent/>
            </Typography>
            <div
                className="complexAuditorLink"
                onClick={() => {
                  //@ts-ignore
                  this.props.history.push("/Complex");
                }}
            >
              <Typography style={{marginLeft:"0px"}} className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/Complex","/Building","/UnitDetail"])}`}>{t("Complex Page")}</Typography>
            </div>
          </Box>
          {/* Invoices & Receipts */}
          <Accordion expanded={this.state.expanded == `panel4`} onChange={this.handleAccordinoChange(`panel4`)}>
            <AccordionSummary
              className="AuditorReportsMain"
              expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
              aria-controls={"panel2bh-content"}
              id={"panel2bh-header"}
              style={dashBoard.ListItem}
            >
              <Typography  className={`SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/BudgetReports","/ExpenseReports","/ExpenseReportDetails","/AuditReports","/AuditReportDetails"])}`}>
                <DocumentImageComponent/>
              </Typography>
              <Typography className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/BudgetReports","/ExpenseReports","/ExpenseReportDetails","/AuditReports","/AuditReportDetails"])}`}>{t("Reports")}</Typography>
            </AccordionSummary>
            <AccordionDetails
                className="AuditorBudgetReports"
                id={"BudgetReports"}
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/BudgetReports");
              }}
            >
              <Typography variant="body2"  className={`SingleLinkSize cursor-pointer ${this.auditorBarColorHandle(pathName,["/BudgetReports","/BudgetReports"])}`}>{t("Budget Report")}</Typography>
            </AccordionDetails>
            <AccordionDetails
                className="AuditorExpenseReports"
                id={"ExpenseReports"}
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/ExpenseReports");
              }}
            >
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.auditorBarColorHandle(pathName,["/ExpenseReports","/ExpenseReportDetails"])}`}>{t("Expense Report")}</Typography>
            </AccordionDetails>
            <AccordionDetails
                className="AuditorAuditReports"
                id={"AuditReports"}
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/AuditReports");
              }}
            >
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.auditorBarColorHandle(pathName,["/AuditReports","/AuditReportDetails"])}`}>{t("Audit Reports")}</Typography>
            </AccordionDetails>
          </Accordion>
          {/* Chat */}
          <Box className="SingleLink AuditorChat">
            <Typography className={`SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/AuditorChat","chairmanchat"])}`}>
              <ChatImageComponent/>
            </Typography>
            <div
              className="AuditorChatLink"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/AuditorChat");
              }}
            >
              <Typography style={{marginLeft:"0px"}} className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/AuditorChat","chairmanchat"])}`}>{t("Chat")}</Typography>
            </div>
          </Box>

          {/* Help */}
          <Accordion expanded={this.state.expanded == `panel7`} onChange={this.handleAccordinoChange(`panel7`)}>
            <AccordionSummary
              className="AuditorHelpMain"
              expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
              style={dashBoard.ListItem}
            >
              <Typography className={` SingleLinkSize cursor-pointer ${this.iconColorHandle(pathName,["/SubscriptionDetail","/FaqChairman","/ContactUsChairman"])}`}>
                <HelpImageComponent/>
              </Typography>
              <Typography className={`ListItemText cursor-pointer ${this.visitorBarBlueColorHandle(pathName,["/SubscriptionDetail","/FaqChairman","/ContactUsChairman"])}`}>{t("Help")}</Typography>
            </AccordionSummary>
            <AccordionDetails
              className={"AuditorHelpSub"}
              style={dashBoard.Item}
              onClick={() => this.props.history.push("/SubscriptionDetail")}
            >
              <Typography variant="body2" className={this.auditorBarColorHandle(pathName,["/SubscriptionDetail"])}>
                {t("Subscription")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails className={"AuditorHelpFAQLink"} style={dashBoard.Item} onClick={() => this.props.history.push("/FaqChairman")}>
              <Typography variant="body2"className={this.auditorBarColorHandle(pathName,["/FaqChairman"])}>
                {t("Frequently asked questions")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              className={"AuditorHelpContactUs"}
              style={dashBoard.Item}
              onClick={() => this.props.history.push("/ContactUsChairman")}
            >
              <Typography variant="body2" className={this.auditorBarColorHandle(pathName,["/ContactUsChairman"])}>
                {t("Contact Us")}
              </Typography>
            </AccordionDetails>
          </Accordion>
         
        </Box>

        <Box className="SideBarBottom">
          <Box>
            <Typography style={{ fontSize: 10, fontWeight: 600 }}>
              <Box component="span" style={languageCondition(language,dashBoard.PremimumPlan_Ar,dashBoard.PremimumPlan)}>
                {t("Premimum")}
              </Box>
              {t("Plan")}
            </Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}>Expires in 125 days</Typography>
          </Box>
          <Box>
            <img src={TenantLogo.default} alt="TenantLogo" width={110} />
          </Box>
        </Box>
      </Box>
    );
  }
}
const dashBoard = {
  PremimumPlan: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginRight: 8,
  },
  PremimumPlan_Ar: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginLeft: 8,
  },
  ListItem: {
    // color: "black",
    marginTop: "20px",
    alignItems: "center",
  },
  ListItemText: {
    marginLeft: 15,
    fontSize: 14,
  },
  Item: {
    cursor: "pointer",
  },
};
//@ts-ignore
export default withTranslation()(withStyles(dashBoard)(withRouter(AuditorSideBar)));



//@ts-ignore
// export default withTranslation()(withStyles(dashBoard)(withRouter(ChairmanSidebar)));


// Customizable Area End
