// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  match: any; // from react-router
}
interface S {
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  Year: any;
  searchName:any;
}
interface SS {
  id: any;
}

export default class AuditorController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getDashboardDataAuditorId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      Year: "",
      searchName:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getBudgetBuildingList(this.state.Year,"");
  }

  handleSearch = (e: any) => {
    this.setState({ searchName: e.target.value });
    this.getBudgetBuildingList(this.state.Year,e.target.value)
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson: any = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse: any = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId:any = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId === this.getDashboardDataAuditorId){
        if (responseJson.hasOwnProperty("auditor")) {
          this.setState({ dashboardData: responseJson.auditor });
        } else {
          this.setState({ errorMsg: errorResponse });
        }
      }
    }
  }


  getBudgetBuildingList = async (year:any,search:any) => {
    const societyID = localStorage.getItem("society_id")
    this.getDashboardDataAuditorId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${societyID}/bx_block_auditor/auditors/auditor_dashboard?search_building=${search}&year=${year}`,
    });
  }
}

// Customizable Area End

