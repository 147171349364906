// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  complexId: any;
  buildingBudgetList: any;
  budgetYear: any;
  buildingList: any;
  complexName: any;
  finalBudgetList: any;
  perBudgetList: any;
  buildingBudgetResponse: any;
  currency: any;
  currency_ar: any;
  budgetId: string;
}

interface SS {
  id: any;
}

export default class BuildingBudgetController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getBuildingListBudgetListId: string = "";
  getBudgetListResidentId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      complexId: "",
      buildingBudgetList: [],
      budgetYear: new Date().getFullYear(),
      buildingList: [],
      budgetId: "",
      complexName: "",
      currency: "",
      currency_ar: "",
      buildingBudgetResponse: null,
      finalBudgetList: null,
      perBudgetList: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    this.setState({ complexId: complex_id }, () => {
      this.getBuildingListBudget(this.state.budgetYear);
    });
  }

  getBuildingListBudget = async (year: any) => {
    const { complexId } = this.state;
    this.getBuildingListBudgetListId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${complexId}/bx_block_fees_payment/building_budgets?year=${year || ""}`,
    });
  };

  budgetListResponse = (responseJson: any) => {
    if (responseJson.hasOwnProperty("budget_report")) {
      this.setState({
        budgetId: responseJson?.budget_report?.data?.id,
        buildingBudgetList: responseJson?.budget_report?.data?.attributes?.facilities,
        finalBudgetList: responseJson?.budget_report?.data?.attributes?.final_budget,
        perBudgetList: responseJson?.budget_report?.data?.attributes?.budegt_per_sq_ft,
        currency: responseJson?.budget_report?.data?.attributes?.currency?.currency,
        currency_ar: responseJson?.budget_report?.data?.attributes?.currency?.currency_ar,
      });
    } else {
      this.setState({
        buildingBudgetList: [],
        finalBudgetList: null,
        perBudgetList: null,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.getBuildingListBudgetListId === apiRequestCallId) {
        this.budgetListResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  buildingBudgetDownload = async () => {
    const { complexId, budgetId } = this.state;
    await this.downloadPdf(
      `/society_managements/${complexId}/bx_block_report/budget_reports/${budgetId}/download_report.pdf`,
      `BuildingBudget.pdf`
    );
  };

  handleChange = (e: any) => {
    this.setState(
      {
        budgetYear: e.target.value,
      },
      () => {
        this.getBuildingListBudget(e.target.value);
      }
    );
  };
}

// Customizable Area End
