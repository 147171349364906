// Customizable Area Start
import * as React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { LanguageAttributeHandler } from "../UtilFunction.web";

const ReceiptCard = ({
  language, payment_type, amount, currency, unit_number, building_name, tenant_name, status, month_year, cardType, t,
}: any) => {
  return (
    <Box
      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
      alignItems="center"
      borderRadius="8px"
      bgcolor="white"
      justifyContent="space-between"
      padding="20px"
      display="flex"
    >
      <Box style={{ minWidth: "100%" }}>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant={"subtitle2"}>
            {t("Receipt")} : {month_year}
          </Typography>
          <Typography variant="subtitle2" className={`my-receipt-status ${status}`}>
            {t(status?.split("_").join(" "))}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ marginTop: "15px" }}>
            <Typography variant={"subtitle2"} className="my-receipt-tenant">
              {t(cardType)}
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }} className="bold-text">
              {tenant_name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "15px" }}>
            <Typography variant={"subtitle2"} className="my-receipt-building">
              {t("Building Name")}
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }} className="bold-text">
              {building_name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"} className="my-receipt-unit-number">
              {t("Unit Number")}
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }} className="bold-text">
              {unit_number}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"} className="my-receipt-amount-number">
              {t("Amount")}
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }} className="bold-text">
              {LanguageAttributeHandler(language, currency.currency, currency.currency_ar)} {Number(Number(amount).toFixed(2)).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"} className="my-receipt-invoice-type">
              {t("Receipt Type")}
            </Typography>
            <Typography variant={"body1"} style={{ fontWeight: "bold" }} className="bold-text">
              {payment_type?.split("_").join(" ")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReceiptCard;
// Customizable Area End
