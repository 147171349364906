//Customizable area start

import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation: any;
  id: string;
  t:any;
  i18n:any;
}
interface S {
open3: any;
  field: any;
  language:string;
}interface SS {
  id: any;
}
export default class CompEmpChangePassSucessController extends CommonApiCallForBlockComponent<Props, S, SS> {
  ConfirmPassCallID: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
  open3: false,
      field: "",
      language:"ENG"
      };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }
  async componentDidMount() {
    await super.componentDidMount()
   
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }
  
  changePassSucess=()=>{
    this.props.navigation.navigate("EmailAccount")
  
  }
  handleOpen = (e:any) => {
    this.setState({
      open3: true,
    }); 
    const Element = document.querySelector("#myExpand");
    this.setState({
      field: Element
          }) 
  }

  

  
}
// Customizable Area End

