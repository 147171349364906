//@ts-ignore
//@ts-nocheck
import React from "react";

//components
import {
    Backdrop,
    Box,
    Button,
    Container,
    Divider,
    Fade,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Select from '@material-ui/core/Select';
import {withRouter} from 'react-router-dom';
import {Menu} from "@szhsin/react-menu";
import CharmainInvoicesController, {Props} from "./CharmainInvoicesController";
import {withTranslation} from 'react-i18next';
import {SearchIconImage} from "../../user-profile-basic/src/assets"
import {SuggestionStyleWeb} from "../../user-profile-basic/src/SuggestionStyle.web";
import {confirmIcon} from "./assets"
import SearchIcon from "@material-ui/icons/Search";
import 'web/src/i18n.js';
import {CloseButton, dashBoardActions, PublishButton} from "./chairmanUIStyles"
import '../../dashboard/src/style.css'
import AlertError from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import InvoicesModal from "./GenerateInvoiceModal.web"

// Customizable Area Start

import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

// Customizable Area End


class CharmainInvoices extends CharmainInvoicesController {
constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
        dataSearch: "",
        anchorEl: null
    }
    // Customizable Area End
}

render() {
    const {t,classes,i18n} = this.props
    const { language } = i18n;

    return (
      // Customizable Area Start
      <>
        <GeneralHeader>
                    <Container className={classes.announcements} id="ChairmanInvoices">
                        <Box style={dashBoardActions.navigation} id="ChairmanInvoices">
                            <Box>
                                <Typography variant="body1" id="ChairmanInvoices" >
                                {t("Invoices & Receipts")} / <Box component="span" style={{color: "blue"}} id="ChairmanInvoices">{t("Invoices")}</Box>
                                </Typography>
                                <Typography variant="h5" className="bold-text chairmanInvoices" id="ChairmanInvoices" style={{marginLeft:"0px",marginBottom:"15px",marginTop:"15px",fontSize:"32px"}}>{t("Invoices")}</Typography>
                            </Box>
                        </Box>
                        <Box className={languageCondition(language, "arabic-grid top-bar", "top-bar ChairmanInvoices")} id="ChairmanInvoices">
                            <Box className="filter" id="ChairmanInvoices">
                                <Select displayEmpty value={this.state.filterBuilding || ""} className="select-input selectBuildingForChairmanInvoices" onChange={this.selectBuilding}>
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Building")}
                                    </MenuItem>
                                    {
                                        this.state.buildingList?.length > 0 &&
                                        <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                                    }
                                    {
                                        this.state.buildingList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Select displayEmpty  value={this.state.filterFloor || ""} className="select-input selectFloorChairmanInvoices" onChange={this.selectFloor}>
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Floor")}
                                    </MenuItem>
                                    {
                                        this.state.floorList?.length > 0 &&
                                        <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "selectAllFloor")}>{t("All")}</MenuItem>
                                    }
                                    {
                                        this.state.floorList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item} className={languageCondition(language, "select-arabic-menu", "")}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Select displayEmpty value={this.state.filterUnit || ""} className="select-input selectUnitChairmanInvoices" onChange={(e:any) => this.setState({filterUnit:e.target.value,filterStatus:""})}>
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Unit")}
                                    </MenuItem>
                                    {
                                        this.state.unitList?.length > 0 &&
                                        <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "selectAllUnits")}>{t("All")}</MenuItem>
                                    }
                                    {
                                        this.state.unitList?.map((item:any,key:any)=> {
                                            return(
                                                <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                                    {item.apartment_name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <Select displayEmpty value={this.state.filterStatus|| ""} className="select-input invoiceTypeFromChairmanInvoices" onChange={(e:any)=> this.setState({filterStatus:e.target.value})}>
                                    <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("Select Status")}
                                    </MenuItem>
                                    <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>
                                        {t("All")}
                                    </MenuItem>
                                    <MenuItem value="due" className={languageCondition(language, "select-arabic-menu", "")}>{t("Due")}</MenuItem>
                                    <MenuItem value="overdue" className={languageCondition(language, "select-arabic-menu", "")}>{t("Over Due")}</MenuItem>
                                    <MenuItem value="paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Paid")}</MenuItem>
                                    <MenuItem value="partially_paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Partially Paid")}</MenuItem>
                                </Select>
                                <Button className="handleSearchFilterForInvoices" onClick={this.handleFilterBy} startIcon={<img src={SearchIconImage} />}>{t("Search")}</Button>
                            </Box>
                        </Box>
                        <Box className="meeting-table">
                            <Grid item sm={12} md={12} xs={12}>
                                <Box className="table-top">
                                    <h3 className="bold-text" style={{fontSize:"22px"}}>{t("Invoices")}</h3>
                                    <Box className="filter">
                                        <Box className="search-box">
                                            <SearchIcon />
                                            <InputBase placeholder={t("search by name")} className="search handleSearchByNameForInvoices" onChange={this.handleSearch}/>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider />
                                <Table className="table-box" style={{paddingBottom:"25px"}}  aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>#</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Name")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Building Name")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Unit No.")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Title")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Amount")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Type")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Status")}</TableCell>
                                            <TableCell className="bold-text" style={{color:"#181d25",fontSize:"14px"}} align={languageCondition(language,"right","left")}>{t("Options")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.invoicesList?.length > 0 ?
                                            this.state.invoicesList.map((row:any,key:any) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.name}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.building_name}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.unit_number}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{row.attributes.title}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{languageCondition(language,row.attributes.currency.currency_ar,row.attributes.currency.currency)} {row.attributes.amount.toLocaleString()}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.payment_type.split("_").join(" "))}</TableCell>
                                                <TableCell align={languageCondition(language,"right","left")}>{t(row.attributes.status.split("_").join(" "))}</TableCell>
                                                <TableCell className="invoice-receipts-menu" align={languageCondition(language,"right","left")}>
                                                    <Menu
                                                        id="chairmanInvoicesMenuButton"
                                                        menuButton={
                                                            <IconButton>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }

                                                    >
                                                        <MenuItem id="chairmanInvoicesMenuButton1" onClick={() => {
                                                            this.setState({openModal: true,invoiceType:row?.attributes?.payment_type});
                                                            this.getInvoiceDetails(row.id)
                                                            }}>
                                                            {t("View")}
                                                        </MenuItem>
                                                        <MenuItem id="chairmanInvoicesMenuButton2" onClick={() => this.manageDownload(row.id)}>{t("Download")}</MenuItem>
                                                        <MenuItem id="chairmanInvoicesMenuButton3" onClick={() => this.manageShare(row.id)}>{t("Share")}</MenuItem>
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                            )):
                                            <TableRow>
                                                <TableCell colSpan={6} align={languageCondition(language,"right","left")}>{t("No Invoice Data Available")}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                <Divider />
                                <Box style={{width:"100%",height:"70px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                    <PaginationModule handlePagination={this.handleInvoicesPagination} pagination={this.state.pagination} page={this.state.page ||1}/>
                                </Box>
                            </Grid>
                        </Box>
                            <Modal
                                dir={languageCondition(language,"rtl","ltr")}
                                style={dashBoardActions.modal}
                                open={this.state.openModal}
                                onClose={()=> this.setState({openModal:false,generateReceipt:false,paymentType:""})}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                timeout: 500,
                                }}
                            >
                                <Fade in={this.state.openModal}>
                                   <InvoicesModal
                                       t={t}
                                       invoiceDetails={this.state.invoiceDetails}
                                       handleModalClose={()=> this.setState({openModal:false,generateReceipt:false})}
                                       paymentType={this.state.paymentType}
                                       partialPaymentError={this.state.partialPaymentError}
                                       manageDownload={this.manageDownload}
                                       downloadId={this.state.downloadId}
                                       generateReceipt={this.state.generateReceipt}
                                       generateReceiptFalse={()=> this.setState({generateReceipt:false})}
                                       changePaymentType={(e:any)=> this.setState({paymentType:e.target.value})}
                                       generateReceiptTrue={()=> this.setState({generateReceipt:true})}
                                       registerPaymentConfirmation={this.registerPaymentConfirmation}
                                       partialPaymentAmount={this.state.partialPaymentAmount}
                                       managePartialAmount={(partialAmount:any,actualAmount:any) => this.managePartialAmount(partialAmount,actualAmount)}
                                       invoiceType={this.state.invoiceType}
                                   />
                                </Fade>
                            </Modal>
                    </Container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="modalStyle"
                        // @ts-ignore
                        open={this.state.confirmPaymentModal}
                        onClose={()=> this.setState({confirmPaymentModal:false})}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        {/*@ts-ignore*/}
                        <Fade in={this.state.confirmPaymentModal}>
                            <Box style={{width:"425px",marginTop:'20px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Box style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"15px",marginBottom:"30px"}}>
                                    <Box>
                                        <img src={confirmIcon} />
                                    </Box>
                                    <Typography className="paymentConfirmationChairmanInvoicesHeading" variant="h6" style={{color:"black",fontWeight:"bold",marginTop:"15px",marginBottom:"10px",textAlign:"center"}}>
                                       {t("Payment Confirmation")}?
                                    </Typography>
                                    <Typography variant="body2" style={{textAlign:"center"}}>
                                        {t("Please confirm that you want to register the payment amount")}
                                        <span style={{color:"#FC8434",fontWeight:"bold",marginLeft:"5px"}}> {languageCondition(language,this.state.invoiceDetails?.currency.currency_ar,this.state.invoiceDetails?.currency.currency)}  {this.state.paymentType == "partial" ? this.state.partialPaymentAmount : this.state.invoiceDetails?.total_amount?.toLocaleString()}</span>. {t("This action can’t be undone.")}
                                    </Typography>
                                    <Box style={{marginTop:"20px",width:"90%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                                        {/*@ts-ignore*/}
                                        <PublishButton variant="outlined" style={{marginRight:"10px"}} onClick={()=> this.setState({confirmPaymentModal:false})} >{t("Close")}</PublishButton>
                                        <CloseButton className="confirmPaymentButtonInvoices" onClick={this.paymentRegistration}>{t("Confirm")}</CloseButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
        </GeneralHeader>
          <AlertError show={this.state.showError} handleClose={()=> this.setState({showError:false})} message={this.state.error} />
          <AlertSuccess show={this.state.showSuccess} handleClose={()=> this.setState({showSuccess:false})} message={this.state.successMessage} />
          <ShareDocumentModal
              isOpen={this.state.isShareModalOpen}
              handleClose={this.handleShareModal}
              heading={t("Share")}
              documentURL={this.state.shareUrl}
          />
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withStyles(SuggestionStyleWeb)(withRouter(CharmainInvoices)));



// Customizable Area Start



  // Customizable Area End