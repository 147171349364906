import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  actionAssignTaskList: any;

  meReactStateList: any[];
  meComplexList: any[];
  meBuildingList: any[];
  meAssignList: any[];

  filterValue: {
    reactEstate: string;
    complex: string;
    building: string;
    assign: string;
  };

  reactEstate: string;
  complex: string;
  building: string;
  assign: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEActionsMeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetActionMeReactEStateCallId: any;
  GetActionMeComplexCallId: any;
  GetActionMeBuildingCallId: any;
  GetActionMeAssignCallId: any;
  GetActionAssignTaskCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      actionAssignTaskList: [],

      meReactStateList: [],
      meComplexList: [],
      meBuildingList: [],
      meAssignList: [],

      filterValue: {
        reactEstate: "",
        complex: "",
        building: "",
        assign: "",
      },

      reactEstate: "",
      complex: "",
      building: "",
      assign: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetActionAssignTaskCallId:
          this.handleActionAssignMeTaskResponse(responseJson);
          break;
        case this.GetActionMeReactEStateCallId:
          this.handleActionMeReactEstateResponse(responseJson);
          break;
        case this.GetActionMeComplexCallId:
          this.handleActionMeComplexResponse(responseJson);
          break;
        case this.GetActionMeBuildingCallId:
          this.handleActionMeBuildingResponse(responseJson);
          break;
        case this.GetActionMeAssignCallId:
          this.handleActionMeAssignResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getActionMeReactEstate();
    await this.getActionMeComplex();
    await this.getActionMeAssign();
    await this.getActionAssignMeTask();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.reactEstate !== this.state.reactEstate) {
      await this.getActionMeComplex();
    }
    if (prevState.complex !== this.state.complex) {
      await this.getActionMeBuilding();
    }

    if (
      prevState.filterValue.reactEstate !== this.state.filterValue.reactEstate ||
      prevState.filterValue.complex !== this.state.filterValue.complex ||
      prevState.filterValue.building !== this.state.filterValue.building ||
      prevState.filterValue.assign !== this.state.filterValue.assign
    ) {
      this.getActionAssignMeTask();
    }
  }

  getActionAssignMeTask = async () => {
    const { reactEstate, complex, building, assign } = this.state.filterValue;
    this.GetActionAssignTaskCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/my_assigned_actions?company_id=${reactEstate}&complex_id=${complex}&building_id=${building}&assigned_by_id=${assign}`,
    });
  };

  handleActionAssignMeTaskResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ actionAssignTaskList: responseJson.data });
    }
  };

  getActionMeReactEstate = async () => {
    this.GetActionMeReactEStateCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  handleActionMeReactEstateResponse = (responseJson: any) => {
    if (responseJson && responseJson.compneys) {
      this.setState({ meReactStateList: responseJson.compneys });
    }
  };

  getActionMeComplex = async () => {
    const { reactEstate } = this.state;
    this.GetActionMeComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/complex_list?real_estate_company_id=${reactEstate}`,
    });
  };

  handleActionMeComplexResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ meComplexList: responseJson.complexes });
    }
  };

  getActionMeBuilding = async () => {
    const { complex } = this.state;
    this.GetActionMeBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/building_list?society_management_id=${complex}`,
    });
  };

  handleActionMeBuildingResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ meBuildingList: responseJson.buildings });
    }
  };

  getActionMeAssign = async () => {
    this.GetActionMeAssignCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/assigend_by_list`,
    });
  };

  handleActionMeAssignResponse = (responseJson: any) => {
    if (responseJson && responseJson.assign_by_list) {
      this.setState({ meAssignList: responseJson.assign_by_list });
    }
  };

  handleMeCompanyName = (task: any) => {
    if (task.real_estate_company) {
      return task.real_estate_company.company_name;
    } else {
      return "-";
    }
  };
  // Customizable Area End
}
