import React from "react";
import {
    Tabs,
    Tab,
} from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  interface StyledTabProps {
    label: string;
  }
  
  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: 'grey',
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '22px',
      fontWeight: 400,
      fontSize: '15px',
      width: 'fit-content',
      opacity: 1,
      marginRight: '10px',
      '&:focus': {
        opacity: 1,
      },
      '&.Mui-selected': {
        background: '#2B6FED',
        color: '#fff',
        fontWeight:800
      },
    },
  }))((props: StyledTabProps) => <Tab disableRipple {...props} />);

export const TabsComponent = (props: any) => {
    const {
        tabNo,
        handleChange,
        tabs
    } = props;
    return <StyledTabs 
    aria-label="styled tabs example"
    //@ts-ignore
    value={tabNo} 
    onChange={handleChange}
>
    {
        tabs.map((tab:any, i:number) => {
            if (tab.testId) {
                return <StyledTab label={tab.label} key={i} data-testid={tab.testId} />
            }
            return <StyledTab label={tab.label} key={i} />
        })
    }
</StyledTabs>
}

const webStyle:any = {
  };