// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityReservationController, { Props } from "./FacilityReservationController.web";
import { Building1, upcoming, pending, previous } from "../src/assets";
import { withTranslation } from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class FacilityReservation extends FacilityReservationController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    this.getFacilityReservationCount();
  }
  render() {
    const { t }: any = this.props;
    // @ts-ignore
    const {language} = this.props.i18n
    const { total_upcoming_count, total_pending_count, total_completed_count, total_cancelled_count, total_rejected_count } = this.state?.facilityCount;

    return (
      <>
        <Box className="login-wrapper incident-wrapper">
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block facilityReservation" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks">
                    <ArrowBackIcon onClick={this.redirectToDashboard}/>
                    <h4 className="bold-text">{t("Facility Reservation")}</h4>
                  </Box>
                </Box>
                <Box className="content-block-wrapper facility-block-wrapper" style={{padding:"0 20px"}}>
                  <Box className="incident-content-wrapper">
                    <Card className="card facilityReserve-card" onClick={() => this.getFacilityReservationDetails("Upcoming")}>
                      <CardContent className="costom-card-content">
                        <img src={upcoming} className="frm-icons" alt="House Icon" />
                        <Typography component="h4" className="bold-text">
                          {t("Upcoming Reservations")}
                        </Typography>
                        <Typography component="h5">
                          {t("Total")}
                        </Typography>
                        <CardActions className="card-footer">
                          <Box className="customButton">
                            <Button variant="contained" className="contain warning">{total_upcoming_count}</Button>
                          </Box>
                        </CardActions>
                      </CardContent>
                    </Card>

                    <Card className="card facilityReserve-card" onClick={() => this.getFacilityReservationDetails("Pending")}>
                      <CardContent className="costom-card-content">
                        <img src={pending} className="frm-icons" alt="House Icon" />
                        <Typography component="h4" className="bold-text">
                          {t("Pending Reservations")}
                        </Typography>
                        <Typography component="h5">
                          {t("Total")}
                        </Typography>
                        <CardActions className="card-footer">
                          <Box className="customButton">
                            <Button variant="contained" className="contain warning" >{total_pending_count}</Button>
                          </Box>
                        </CardActions>
                      </CardContent>
                    </Card>
                    <Card className="card facilityReserve-card" onClick={() => this.getFacilityReservationDetails("Previous")}>
                      <CardContent className="costom-card-content">
                        <img src={previous} className="frm-icons" alt="House Icon" />
                        <Typography component="h4" className="bold-text">
                          {t("Previous Reservations")}
                        </Typography>
                        <Typography component="h5">
                          {t("Total")}
                        </Typography>
                        <CardActions className="card-footer">
                          <Box className="customButton">
                            <Button variant="contained" className="contain warning" >{total_completed_count}</Button>
                          </Box>
                        </CardActions>
                      </CardContent>
                    </Card>

                    <Card className="card facilityReserve-card" onClick={() => this.getFacilityReservationDetails("Rejected")}>
                      <CardContent className="costom-card-content">
                        <img src={previous} className="frm-icons" alt="House Icon" />
                        <Typography component="h4" className="bold-text">
                          {t("Reject Reservations")}
                        </Typography>
                        <Typography component="h5">
                          {t("Total")}
                        </Typography>
                        <CardActions className="card-footer">
                          <Box className="customButton">
                            <Button variant="contained" className="contain warning" >{total_rejected_count}</Button>
                          </Box>
                        </CardActions>
                      </CardContent>
                    </Card>

                    <Card className="card facilityReserve-card" onClick={() => this.getFacilityReservationDetails("Cancelled")}>
                      <CardContent className="costom-card-content">
                        <img src={previous} className="frm-icons" alt="House Icon" />
                        <Typography component="h4" className="bold-text">
                          {t("Cancelled Reservations")}
                        </Typography>
                        <Typography component="h5">
                          {t("Total")}
                        </Typography>
                        <CardActions className="card-footer">
                          <Box className="customButton">
                            <Button variant="contained" className="contain warning" >{total_cancelled_count}</Button>
                          </Box>
                        </CardActions>
                      </CardContent>
                    </Card>

                  </Box>
                  <Box className="customButton add-incident" style={{ background: "#f8f9fe", paddingTop: "10px" }}>
                    <Button variant="contained" onClick={() => {
                      this.setState({ loading: true });//@ts-ignore
                      this.props.history.push("/CreateFacilityReservation");
                    }} >{t("Book a facility")}</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* desktop footer block */}
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(FacilityReservation));
// Customizable Area End
