//Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Hidden, InputAdornment, IconButton,
  Grid, Checkbox, FormControl, FormControlLabel, MenuItem, Menu, Link, Typography
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import TextField from '@material-ui/core/TextField';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {withTranslation} from 'react-i18next';

import '../../../../web/src/i18n.js';
import i18next from 'i18next';
import {withRouter} from 'react-router';
import {languageCondition} from "../../../../components/src/ConditionManager.web";

import {
  Tenant_Logo, Building1,
  Email_Icon, Lock_User_Icon, globalIcon, downArrow
} from "../../src/assets";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import EmailAccountController, {
  Props,
} from "./EmailAccountController.web";


 class EmailAccount extends EmailAccountController {
  constructor(props: Props) {
    super(props);
  }
  handleEngLngChange = () => {
    this.setState({language: 'ENG',})
    localStorage.setItem("language", "en");
    i18next.changeLanguage("en");
};
handleAreLngChange = () => {
  this.setState({language: 'ARB',})
  localStorage.setItem("language", "ar");
  i18next.changeLanguage("ar");
};
  render() {
    const {t,i18n} = this.props;
    const language = i18n.language;
    const validationschema = Yup.object().shape({
      email: Yup.string().email(t("Invalid email format")).required(t("Email is required")).max(320,t('email adress should not be longer than 320 characters')),
      password: Yup.string().required(t("Password is required"))
     
     
    
    })
return (
      <Box style={{ overflowY: 'hidden' }} dir={languageCondition(language,"rtl","ltr")}>
        <Grid container spacing={5}>
          <Grid item sm={7} xs={12} style={{ height: '100%' }} className="rightbox">
            <Hidden >
              <Box p={2} style={{ height: '100%' }} className="rightbox" >
                <Box  style={{ display: 'flex', height: '80px', justifyContent: 'space-between', flexDirection: 'row' }}>
                  <img src={Tenant_Logo.default} style={webStyle.login_imge} />
                  <Box style={{ height: '25px' }}  onClick={() => { this.handleOpen() }} data-test-id='box' id='closeLocation'>
                    <p style={{ verticalAlign: 'middle' }}>
                      <img src={globalIcon} alt="GlobalIcon" style={{ position: 'relative', top: '5px' }} className="login-h1 bold-text " />
                      <span style={{ fontWeight: 'bolder', paddingLeft: '3px' }} className="login-h1 bold-text " >{this.state.language} </span>
                      <span  ><img src={downArrow} style={{ position: 'relative', height: '7px', color: 'grey',top:"-2px" }} /></span>
                    </p>
                  </Box>
                  
                </Box>
                <Menu open={this.state.emailopen}
               
                data-test-id='menu'
                  anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'left',
                  }}
                  style={{
                    position: 'absolute',
                    top: '30px'
                  }}
                  transformOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'left',
                  }}
                  anchorEl={this.state.emailfield}>
                  <MenuItem  onClick={() => this.handleEngLngChange()} data-test-id="english"  style={localStorage.getItem("language") === "en" ? webStyle.activeEn : webStyle.activeAr} >English</MenuItem>
                  <MenuItem  onClick={() => this.handleAreLngChange()} data-test-id="arabic" style={localStorage.getItem("language") === "ar" ? webStyle.activeEn : webStyle.activeAr}>Arabic</MenuItem>
                </Menu>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px', }}>
                  <Box style={{ width: '360.75px', height: '353.8px' }}>
                    <Formik initialValues={this.state.initialValues} validationSchema={validationschema} onSubmit={(values) => {
                      this.loginSubmit(values)
                    }}>
                      {(props) => (

                        <Form translate={undefined}>
                          <Box>
                            <h1 style={{ fontSize: '32px',marginBottom:"10px"}} className="login-h1 bold-text ">{t("Welcome Back")}</h1>
                            <p style={{ fontSize: '15px'}}>{t("Login with your account credentials")} </p>
                          </Box>
                          <br />
                          <Box style={{ marginBottom: "5px" }} >
                            <Field as={TextField} type="email" variant='outlined'
                              data-test-id="emailInput"
                              className="compempemail"
                              
                              name="email" helperText={<div style={{ color: 'red', fontSize: '15px' }}> <ErrorMessage name="email" component="div" /></div>}
                              id="outlined-basic" placeholder={t("Email ID")} fullWidth
                             
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={Email_Icon}  alt="Email Icon" style={languageCondition(language,{opacity: 0.54,marginRight: 8},{opacity: 0.54})} />
                                  </InputAdornment>
                                ),
                                style: {
                                  width: '360px',
                                  height: '56px',
                                  borderRadius: '12px',
                                  border: '1px solid #d1d1d1',
                                  background: '#f9f9f9',
                                },
                              }}
                            />
                          </Box>
                          
                          <Box style={{ marginBottom: "5px" }}>
                            <Field as={TextField} variant='outlined' name="password" className="compempemail" helperText={<div style={{ color: 'red', fontSize: '15px' }}><ErrorMessage name="password" component="div" /></div>}
                              placeholder={t("Password")} fullWidth
                              data-test-passinp="passwordInput"
                              type={this.state.showPassword ? 'text' : 'password'}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"  >
                                    <img src={Lock_User_Icon} className="frm-icons" alt="Email Icon"  style={languageCondition(language,{width: 24, height: 24,marginRight: -10, marginLeft:10,marginTop: -4,color: "#000000",opacity: 0.54},{width: 24, height: 24,marginRight: 0,marginTop: 4,color: "#000000",opacity: 0.54})}  />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                   <InputAdornment position="end" data-test-passinp="passwordInput">
                                   <IconButton onClick={this.handleClickShowPassword} edge="end">
                                   {this.state.showPassword ? <VisibilityOutlinedIcon style={languageCondition(language,{width: 24, height: 24,marginRight: 0,marginTop: -4,color: "#000000",opacity: 0.54},{width: 24, height: 24,marginRight: 0,marginTop: 4,color: "#000000",opacity: 0.54})} data-test-id="Password"/> : <VisibilityOffOutlinedIcon style={languageCondition(language,{width: 24, height: 24,marginRight: 0,marginTop: -4,color: "#000000",opacity: 0.54},{width: 24, height: 24,marginRight: 0,marginTop: 4,color: "#000000",opacity: 0.54})} data-test-id="Password"/>}
                                   </IconButton>
                                 </InputAdornment>
                                ),
                                style: {
                                  width: '360px',
                                  height: '56px',
                                  borderRadius: '12px',
                                  border: '1px solid #d1d1d1',
                                  background: '#f9f9f9',
                                },
                              }}
                            />
                          </Box>
                          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <FormControl >
                              <FormControlLabel
                                label={t('Stay logged in')}
                                className="checkboxLabel"
                                control={<Checkbox  style={languageCondition(language,{marginRight: -20,color: '#fc8434'},{ color: '#fc8434' })}  name='check' value={this.state.stay_login}
                                  icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />} onChange={() => { this.setState({ stay_login: !this.state.stay_login }) }}
                              />
                            </FormControl>
                            <Link href="/CompanyEmployee/CMForgotPass" className="link bold-text" data-test-forgotpass="forgotPass" style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', verticalAlign: ' middle', fontWeight: 'bold' }}>{t("Forgot Password?")}</Link>
                          </Box>
                          <br />
                          <Button color="primary" variant="contained" data-test-id='LogIn' style={{ height: '56px', backgroundColor: '#2B6FED', borderRadius: '10px', lineHeight: '20px' }} fullWidth size="large"
                            type="submit"> <Typography style={{ fontWeight: 'bolder', color: '#ffffff' }} className="login-h1 bold-text "> {t("LOGIN")}</Typography> </Button>
                        </Form>)}
                    </Formik>
                  </Box>
                </Box>
              </Box>
            </Hidden>
          </Grid>
          <Grid item sm={5} className="leftBox" >
            <Hidden xsDown >
              <Box pt={2} style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100vh' }} visibility={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} style={webStyle.side_img} />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    );

  
  }
}

const webStyle = {

  login_imge: {
    width: '116px',
    height: '45px',
  },
  side_img: {
    height: '95vh',
    width: '39vw',
    borderRadius: '40px'

  },
  activeEn:{
    color:"#fc8434"
  },
  activeAr:{
    color:"black"
  },
  expandIcon:
  {
    fontSize: '25px', color: "grey", position: 'relative'
    ,

    top: '10px',
  }
};
export default withTranslation()(withRouter(EmailAccount));
// Customizable Area End

