// Customizable Area Start
import React from "react";
import {
    Container,
    Typography,
    withStyles,
    Box,
    Grid,
    Divider,
} from "@material-ui/core";
import VisitorDetailsController, { Props } from "./VisitorDetailsController";
import { SuggestionStyleWeb } from "../../user-profile-basic/src/SuggestionStyle.web";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {ROLE} from "../../../framework/src/Enum";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class VisitorDetails extends VisitorDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // @ts-ignore
        const { classes } = this.props;
        //@ts-ignore
        const {t} = this.props
        const userType  = localStorage.getItem("userType");
        let comingWtihCar :any=""
        if( this.state?.visitorDetails?.vehicle_detail?.car_number){
            comingWtihCar = "Yes"
        }
        else {
            comingWtihCar = "No"
        }

        return (
            <>
                <GeneralHeader>
                            <Container className={classes.announcements}>
                                <Box className="navigation">
                                    <Box>
                                        <Typography variant="body1">
                                            {t("My Dashboards")} /{" "}
                                            {
                                                userType !== ROLE.SECURITY &&
                                                <>
                                                    <Box component="span"  className="visotDetailsDashboard auditReportSearchButton" onClick={()=> this.props.history.push("/DashboardGeneral")} style={{cursor:"pointer"}}>{t("General Dashboards")}</Box>
                                                    {" "}/{" "}
                                                </>
                                            }
                                            <Box component="span"  className="VisitorDetails" onClick={()=> this.props.history.push("/VisitorList")} style={{cursor:"pointer"}}>{t("Visitors")}</Box>
                                            {" "}/{" "}
                                            <Box component="span" style={{ color: "blue" }}>
                                                {t("Visitors Details")}
                                            </Box>
                                        </Typography>
                                        <Typography variant="h5" className="sub-heading bold-text">
                                            {t("Visitors Details")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="meeting-table">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <Box className="table-top">
                                            <h5 className="bold-text" style={{fontSize:"22px"}}>{t(this.state.visitorDetails.name)}</h5>
                                        </Box>
                                        <Divider />
                                        <Box width="100%" style={{display:'flex',flexDirection:"column",alignItems:"center"}}>
                                            <Box style={{width:"95%"}}>
                                                {
                                                    userType === "Security" ?
                                                <>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Date")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{moment(this.state?.visitorDetails?.schedule_date).format("DD MMMM YYYY")}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Time")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state?.visitorDetails?.schedule_time}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Resident Name")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails.resident_name}</Typography>
                                                    </Box>
                                                    <Divider/>

                                                         <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Owner Name")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails?.owner_name}</Typography>
                                                    </Box>
                                                    <Divider/>

                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Building Name")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails?.building_management?.name}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Unit Number")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails?.apartment_management?.apartment_name}</Typography>
                                                    </Box>
                                                    <Divider/>

                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Visitor Phone Number")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state?.visitorDetails?.mobile_number?.full_mobile_number}</Typography>
                                                    </Box>
                                                        <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Coming with car")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{comingWtihCar}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Car Plate Number")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state?.visitorDetails?.vehicle_detail?.car_number || "N/A"}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("ID Copy")} : </Typography>
                                                        <img style={{margin: '10px',width: '200px'}} src={this.state?.visitorDetails?.image?.url}/>
                                                    </Box>
                                                </>
                                                    :
                                                <>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Resident Name")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails.resident_name}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Building Name")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails?.building_management?.name}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Unit Number")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state.visitorDetails?.apartment_management?.apartment_name}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Date")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{moment(this.state?.visitorDetails?.schedule_date).format("DD MMMM YYYY")} - {this.state?.visitorDetails?.schedule_time}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={{display:"flex",margin:"10px 0px"}}>
                                                        <Typography style={{marginRight:"5px",fontSize:"15px"}}>{t("Phone Number")} : </Typography>
                                                        <Typography style={{fontWeight:"bold",fontSize:"15px"}}>{this.state?.visitorDetails?.mobile_number?.full_mobile_number}</Typography>
                                                    </Box>
                                                </>
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Container>
                </GeneralHeader>
            </>
        );
    }
}

export default withTranslation()(withStyles(SuggestionStyleWeb)(withRouter(VisitorDetails)));
// Customizable Area End
