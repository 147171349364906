//Customizable Area Start
import React from "react";
import {

    Box,
    Grid,

} from "@material-ui/core";
import MainScreenController, {
    Props,
} from "./MainScreenController.web"
import './global.css';
import DashboardHeader from "./DashboardHeader.web";
import CEAccManSidebar from "./CEAccManSIdebar.web";
import CECOOSidebar from "./CECOOSidebar.web";
import CESalesManSidebar from "./CESalesManSidebar.web";
import {languageCondition} from "../../../../components/src/ConditionManager.web";

export default class MainScreen extends MainScreenController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        const {language} = this.props;

        if (localStorage.loginSuccessToken) {
            return (

                <>
                    <Box maxWidth='xl' className="dashboard_container" dir={languageCondition(language,"rtl","ltr")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DashboardHeader navigation={undefined} id={""} />
                            </Grid>
                        </Grid>

                        <Box className="dashboard_middle">
                            <Grid container className="dashboard_grid">
                                <Grid item xs={3} >
                                    {this.state.userRole === "coo" && <CECOOSidebar />}

                                    {this.state.userRole === "account_manager" && <CEAccManSidebar />}

                                    {this.state.userRole === "sales_manager" && <CESalesManSidebar />}
                                </Grid>
                                <Grid item xs={9} style={{ backgroundColor: "#F4F7FF" }}>
                                    {this.props.children}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};
//Customizable Area End


