// Customizable Area Start
import React from "react";
import { Container, Box, Grid, withStyles, Typography, FormLabel, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import AddUnitsController, { Props } from "./AddUnitsController.web";
import { withTranslation } from "react-i18next";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

class AddUnit extends AddUnitsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes }: any = this.props;

    return (
      <CEHeaderComponentWeb>
        <Box style={{ background: "#F4F7FF" }} className={classes.reportList}>
          <Grid item xs={12} md={12} sm={12} style={{ paddingTop: 35, minHeight: "95vh" }}>
            <Container>
              <Box className="navigation">
                <Box>
                  <Typography variant="body1">
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton unitNavg"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {t("Complex")}
                    </Box>
                    /{" "}
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton unitNavg1"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {t("Buildings")}
                    </Box>
                    /{" "}
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton unitNavg2"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {t("Complex Details")}
                    </Box>
                    /{" "}
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton unitNavg3"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {t("Buildings")}
                    </Box>
                    /{" "}
                    <Box className="reports-BudgetReportmain unitNavg" component="span" style={{ color: "#2B6FED" }}>
                      {t("Add New Unit")}
                    </Box>
                  </Typography>

                  <Typography
                    variant="h5"
                    className="sub-heading bold-text reports-BudgetReportmain unitNavg"
                    style={{ fontSize: "32px" }}
                  >
                    {t("Add new Unit")}
                  </Typography>
                </Box>
              </Box>
              <Grid className="meeting-table unitNavg" style={{ boxShadow: "4px 0px 14px #e9e9e9" }}>
                <Grid>
                  <Box className="table-top unitNavg">
                    <Grid container spacing={2}>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text unitNavg"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Unit Number")}
                        </FormLabel>
                        <TextField
                          label={t("JUnit Number")}
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          name="jname"
                          className="firstname"
                        />
                      </Grid>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text unitNavg"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Floor Number")}
                        </FormLabel>

                        <TextField
                          label={t("Floor Number")}
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          name="jname"
                          className="floor-name firstname unitNavg"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="table-top">
                    <Grid container spacing={2}>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Owner ID")}
                        </FormLabel>
                        <TextField
                          label={t("Owner ID")}
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="ownerid unitNavg"
                        />
                      </Grid>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Owner Name")}
                        </FormLabel>

                        <TextField
                          label={t("Owner Name")}
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="onwerstatus"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="table-top">
                    <Grid container spacing={2}>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Unit Status")}
                        </FormLabel>
                        <TextField
                          label={t("Unit Status")}
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="firstname unitStatus1"
                        />
                      </Grid>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text unitNavg"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Size")}
                        </FormLabel>

                        <TextField
                          label={t("Size")}
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="size floorNo"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="table-top">
                    <Grid container spacing={2}>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text unitNavg"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Configration(optional)")}
                        </FormLabel>
                        <TextField
                          label={t("Configration")}
                          fullWidth
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="optional unitNavg"
                        />
                      </Grid>
                      <Grid item xs={6} spacing={2}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text unitNavg"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Purchase Price(optional)")}
                        </FormLabel>

                        <TextField
                          label={t("Purchase Price")}
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="price unitNavg"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Box>
      </CEHeaderComponentWeb>
    );
  }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(AddUnit)));
// Customizable Area End
