//Customizable area start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation?: any;
  id?: string;
  dataFromComponent1: any;
  handleModalClose: any;
  isBuilding: boolean;
  dataId: string | number;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface ListData {
  id: string;
  name: string;
}

interface S {
  initialValues: any;
  reassignEmpFrmModal: any;
  role: string;
  salesManagerList: ListData[];
  accountManagerList: ListData[];
  companyId: string;
  complexId: string;
}

interface SS {
  id: any;
}

export default class CEReassignBuildController extends CommonApiCallForBlockComponent<Props, S, SS> {
  LoadDataCallID: any;
  PostCallId: any;
  salesManagerListCallId: string = "";
  accountManagerListCallId: string = "";
  buildingComplexDataCallId: string = "";
  reassignBuildingCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      reassignEmpFrmModal: false,
      initialValues: {
        BuildingID: "",
        BuildingName: "",
        salesMan: "",
        AccountMan: "",
        NewSalesMan: "",
        NewAccountMan: "",
        notes: "",
      },
      role: "",
      salesManagerList: [],
      accountManagerList: [],

      companyId: "",
      complexId: ""
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const userRole = localStorage.getItem("role") ?? "";
    this.setState({ reassignEmpFrmModal: this.props.dataFromComponent1, role: userRole }, () => {
      this.getAccountManagerList();
      this.getSalesManagerList();
      this.getBuildingComplexData();
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJsonData && !responseJsonData.errors) {
        this.apiHandler(apiRequestCallId, responseJsonData);
      }
    }
  }

  apiHandler = (apiRequestCallId: string, responseJsonData: any) => {
    if (apiRequestCallId === this.salesManagerListCallId) {
      this.setState({ salesManagerList: responseJsonData.sales_managers.map((item: any) => ({ label: item.name, value: item.id })) });
    }
    if (apiRequestCallId === this.accountManagerListCallId) {
      this.setState({ accountManagerList: responseJsonData.account_managers.map((item: any) => ({ label: item.name, value: item.id })) });
    }
    if (apiRequestCallId === this.buildingComplexDataCallId) {
      this.handleBuildingComplexData(responseJsonData);
    }
    if (apiRequestCallId === this.reassignBuildingCallId) {
      this.props.handleModalClose();
    }
  };

  handleFormModal = () => {
    this.setState({ reassignEmpFrmModal: false });
  };

  getOpacityFromValue = (value: string) => (value === "" ? 0.5 : 1);

  getBuildingLabel = () => (this.props.isBuilding ? `Building` : `Complex`);

  getBuildingComplexDataEndpoint = () => {
    const { dataId } = this.props;
    return `bx_block_society_management/society_managements/${dataId}`;
  };

  handleBuildingComplexData = (responseJsonData: any) => {
    console.log(responseJsonData);
    
    const { id, attributes } = responseJsonData.data;
    const { name, account_manager, sales_manager, company_name } = attributes;

    this.setState({
      initialValues: {
        ...this.state.initialValues,
        BuildingID: id,
        BuildingName: name,
        CurrentAccountManager: account_manager,
        SalesManager: sales_manager,
      },
      companyId: company_name ? company_name.id : "",
      complexId: id,
    });
  };

  handleSubmit = async (values: any) => {
    const { NewAccountMan, NewSalesMan } = values;
    const formData = new FormData();
    formData.append("account_manager_id", NewAccountMan);
    formData.append("sales_manager_id", NewSalesMan);
    this.reassignBuildingCallId = await apiCallWithToken({
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_objects/reassign_object?id=${this.state.complexId}&employee_account_id=${NewAccountMan}`,
      body: formData,
    });
  };

  getSalesManagerList = async () => {
    this.salesManagerListCallId = await apiCallWithToken({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/sales_manager_list`,
    });
  };

  getBuildingComplexData = async () => {
    this.buildingComplexDataCallId = await apiCallWithToken({
      method: "GET",
      contentType: "application/json",
      endPoint: this.getBuildingComplexDataEndpoint(),
    });
  };

  getAccountManagerList = async () => {
    this.accountManagerListCallId = await apiCallWithToken({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_real_estate_companies/account_manager_list`,
    });
  };
}
// Customizable Area End
