// Customizable Area Start
import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography } from "@material-ui/core";

const DialogHeader = ({ t, title, onClose }: any) => {
  return (
    <MuiDialogTitle disableTypography className="dialog-heading">
      <Typography variant="h6" className="bold-text">
        {t(title)}
      </Typography>
      <IconButton className="admin-invitation-close-icon" onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default DialogHeader;
// Customizable Area End
