import React from "react";

// Customizable Area Start
import { Grid, Box, Typography, Button, ListItem, Avatar, List, Modal, Link } from "@material-ui/core";
import { withRouter } from "react-router";
import moment from "moment";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InboxController, { Props } from "./inboxController.web";
import "../assets/css/style.scss";
import { NoProfile_Img } from "../../user-profile-basic/src/assets";
import { Sendbutton } from "./assets";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class OwnerIncidentChat extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.refs = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  refs: any;
  interval: any;
  messageList: any;
  handleClick1 = () => {
    this.refs.fileUploader.click();
  }

  handleFile2(file: any) {
    this.setState({ selectedMedia: { url: URL.createObjectURL(file), mimetype: file.type }, accept: true, file: file });
  }

  displaytime(time: any) {
    let dateNow: any = new Date(time);
    let hourNow: any = dateNow.getHours();
    let minuteNow: any = dateNow.getMinutes();
    let formattedM: any = minuteNow < 10 ? `0${minuteNow}` : minuteNow;
    return `${hourNow}:${formattedM}`;
  }

  async componentDidMount() {
    this.getAllChat();
    this.interval = setInterval(() => {
      this.getSingleInbox();
    }, 3000);
  }

  scrollToBottom = () => {
    const scrollHeightScreen: any = this.messageList?.scrollHeight;
    if (scrollHeightScreen) {
      const heightBox: any = this.messageList.clientHeight;
      const maxScrollTop: any = scrollHeightScreen - heightBox;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  handleModalContent = (media: any) => {
    if (media?.mimetype !== "application/pdf") {
      return <Avatar src={media?.url} style={{ width: "300px", height: "26rem", borderRadius: 0 }} />;
    } else {
      return <iframe src={media?.url} style={{ width: "300px", height: "26rem" }} />;
    }
  };

  handleDate = (index: any, dateNow: any) => {
    if (index > 1) {
      return moment.utc(dateNow).fromNow();
    } else {
      return moment.utc(dateNow).format("MMM-DD-YYYY");
    }
  };

  handleUserImage = (message: any, currentAccountId: any, item: any) => {
    if (message.message.account_id != currentAccountId) {
      return (
        <img
          src={
            item?.attributes?.chat_with_account?.id != localStorage.getItem("userId") ? item?.attributes?.chat_with_account?.attributes?.profile_pic?.url || NoProfile_Img : item?.attributes?.chatable?.attributes?.profile_pic?.url || NoProfile_Img
          }
          alt="profile-pic"
          width="50"
          height="50"
          style={{ borderRadius: 20, marginRight: 5 }}
        />
      );
    }
  };

  handleAlignment = (left: any, right: any) => {
    return left == right ? "right" : "left";
  };

  handleChatImage = (image: any) => {
    if (image.length != 0) {
      const url = image[0].url;
      if (url.includes(".pdf")) {
        return (
          <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <p>PDF</p>{" "}
            <Link href={url} target="_blank">
              <GetAppIcon />
            </Link>
          </Box>
        );
      } else {
        return (
          <Grid item xs={12}>
            <img
              className="incident-chat-image-dialog"
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ selectedMedia: image[0] })}
              src={image[0].url}
              width="75"
              height="75"
            />
          </Grid>
        );
      }
    }
  };

  handleMessage = (message: any) => {
    if (message.length > 45) {
      return (
        <Typography
          style={{
            color: "#081F32",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            wordBreak: "break-all",
          }}
          align="left"
        >
          {message}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            color: "#081F32",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: 14,
            wordBreak: "break-all",
          }}
        >
          {message}
        </Typography>
      );
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // @ts-ignore
    const item: any = JSON.parse(localStorage.getItem("selectedChat"));
    const currentAccountId: any = localStorage.getItem("userId");

    const { t, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <div
          dir={languageCondition(language, "rtl", "ltr")}
          style={{
            padding: "0.3rem",
            backgroundColor: "#ffff",
            paddingLeft: "0.3rem",
            marginTop: "1rem",
            overflowY: "hidden",
          }}
        >
          <Grid container>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Box className="owner-incident-chat-back-btn" display="flex" alignItems="center" onClick={() => this.redirectToList()}>
                <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                <span style={{ fontWeight: "bold", marginLeft: "1rem" }}>{t("Ticket")}</span>
              </Box>
            </Grid>
            <Box
              marginTop={2}
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              style={{
                height: "60px",
                borderTop: "1px solid #f4f4f4",
                borderBottom: "1px solid #f4f4f4",
                padding: "1rem",
              }}
            >
              <Box>
                <p className={"bold-text"} style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {item?.attributes?.chatable?.attributes?.incident_title}
                </p>
                <p style={{ fontWeight: "bold", fontSize: "12px", color: "rgba(24,29,37,0.5)" }}>
                  {t("Ticket ID")}: {item?.attributes?.chatable?.id}
                </p>
              </Box>
              <Box>
                <Button variant="contained" className="contain danger" style={{ textTransform: "capitalize" }}>
                  {t(item?.attributes?.chatable?.attributes?.incident_status)}
                </Button>
              </Box>
            </Box>
            <Grid xs={12}>
              <List
                style={{ overflowY: "auto", maxHeight: "62vh", minHeight: "62vh", overflowX: "hidden" }}
                ref={(div: any) => {
                  this.messageList = div;
                }}
              >
                {this.state.allInboxKey?.length != 0 &&
                  this.state.allInboxKey?.map((date: any, i: any) => (
                    <>
                      <Box key={i} display="flex" justifyContent="center">
                        <p
                          className="bold-text"
                          style={{ padding: "6px 12px", background: "#f9f9f9", borderRadius: "25px", fontSize: "13px" }}
                        >
                          {this.handleDate(i, date)}
                        </p>
                      </Box>
                      {this.state.singleChatRoom[date]?.map((message: any, i: any) => (
                        <>
                          <ListItem key={i}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={
                                  message.message.account_id == currentAccountId
                                    ? { display: "flex", justifyContent: "end", alignItems: "center" }
                                    : { display: "flex", justifyContent: "start", alignItems: "center" }
                                }
                              >
                                {this.handleUserImage(message, currentAccountId, item)}
                                <Box>
                                  <Box
                                    style={{
                                      background: "#f6f6f6",
                                      borderRadius: "6px",
                                      padding: "0.5rem",
                                      borderTopRightRadius: 0,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        color: "#081F32",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        marginLeft: 5,
                                      }}
                                      align={this.handleAlignment(message.message.account_id, currentAccountId)}
                                    ></Typography>
                                    {this.handleMessage(message.message.message)}
                                    {this.handleChatImage(message?.message?.images)}
                                  </Box>
                                  <p
                                    style={{
                                      textAlign: this.handleAlignment(currentAccountId, message.message.account_id),
                                      fontSize: "12px",
                                    }}
                                  >
                                    {this.displaytime(message.message.created_at)}
                                  </p>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </>
                      ))}
                    </>
                  ))}
              </List>
              <Grid
                container
                className="incident-chat-box-chairman"
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "white",
                }}
              >
                <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className="incident-chat-box-input"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.createMessages();
                      }
                    }}
                    onChange={(e) => this.CreateNewMessage(e)}
                    type=""
                    style={{
                      border: "1px solid #EDEDED",
                      color: "#726363",
                      borderRadius: 15,
                      padding: 10,
                      width: "100%",
                    }}
                    placeholder={t("Type your message")}
                    value={this.state.newMessage}
                  />
                  {
                    // @ts-ignore
                    // @ts-nocheck
                    <AttachFileIcon onClick={this.handleClick1} for="BtnBrowseHidden" style={{ cursor: "pointer" }} />
                  }
                  <input
                    id="BtnBrowseHidden"
                    type="file"
                    onChange={(e: any) => this.handleFile2(e.target.files[0])}
                    className="incident-chat-upload-input"
                    style={{
                      position: "absolute",
                      outline: "none",
                      height: "10px",
                      width: "10px",
                      zIndex: 2,
                      cursor: "pointer",
                      opacity: 0,
                    }}
                    ref="fileUploader"
                    accept="image/png, image/jpeg, image/jpg,.pdf"
                  />
                </Grid>

                <img
                  className={languageCondition(language, "KeyboardForwardIconAR", "incident-chat-send-chat")}
                  src={Sendbutton}
                  style={{ cursor: "pointer", width: "2.5rem" }}
                  onClick={() => {
                    this.createMessages();
                    this.scrollToBottom();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Modal
            dir={languageCondition(language, "rtl", "ltr")}
            open={this.state.selectedMedia}
            style={{ display: "flex", alignItems: "center", flexDirection: "column-reverse", justifyContent: "center" }}
            onClose={() => this.setState({ selectedMedia: null, accept: false })}
            className="incident-chat-box-modal"
          >
            <div>
              {this.handleModalContent(this.state.selectedMedia)}
              {this.state.accept && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() => this.setState({ selectedMedia: null, accept: false })}
                    style={{
                      fontSize: 13,
                      color: " rgb(247, 247, 252)",
                      fontFamily: "Poppins",
                      backgroundColor: "rgb(241, 78, 36)",
                      height: 35,
                      borderRadius: "16px",
                      boxShadow: "none",
                      border: "1px solid rgb(217, 219, 233)",
                      marginTop: 10,
                      marginRight: 10,
                      width: 150,
                    }}
                    className="incident-chat-cancel-btn"
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    style={{
                      backgroundColor: "#ffff",
                      borderRadius: "16px",
                      fontSize: 13,
                      fontFamily: "Poppins",
                      height: 35,
                      boxShadow: "none",
                      color: " red",
                      marginTop: 10,
                      marginRight: 10,
                      width: 150,
                      border: "1px solid red",
                    }}
                    onClick={() =>
                      this.setState({ selectedMedia: null, accept: false }, () =>
                        this.handleSelectFile(this.state.file)
                      )
                    }
                    className="incident-chat-send-btn"
                  >
                    {t("Send")}
                  </button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withRouter(OwnerIncidentChat));
// Customizable Area End
