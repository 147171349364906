import React from "react";
//Customizable Area Start
//components
import {
  Box,
  Button,
  Grid
} from "@material-ui/core";

//resources

import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedController, { Props } from "./ClassifiedController.web";
import { withTranslation } from "react-i18next";
//resorces
import {Tick_Circle_Icon, Building1 } from "../src/assets";

class ClassifiedReportedSuccessfully extends ClassifiedController {
  static findWhere: any;
 
  constructor(props: Props) {
    super(props);
  }

  render() {
  // @ts-ignore
   const { t } = this.props;
    return (
      <>
        <Box className="login-wrapper auth-wrapper" style={{backgroundColor:"white"}}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block">
                {/* <Box className="backIcon" onClick={() => window.history.back()}><KeyboardBackspaceIcon /></Box> */}
                <Box className="main-content-block change-password-mainblock" style={{height:"75vh",display:"flex",alignItems:"center"}}>
                  <Box className="header-block header-block-changepassword">
                    <img src={Tick_Circle_Icon} className="lock-logo" alt="Lock_Icon" />
                    <h1 className="bold-text">{t("Request Sent")}<br></br>{t("Successfully")}</h1>
                    <p>{t("Your classified request has been sent to the building/complex manager successfully. You will get a notification once it is processed.")}</p> </Box>
                </Box>
                <Box className="footer-block desktop-ui">
                  <Box className="row-btn customButton CreateClassifedFormButton">
                    <Button data-test-id="customButton" variant="contained" className="CreateClassifedBottomButton" onClick={() => {
                      localStorage.removeItem("classifiedPreview");
                      localStorage.removeItem("classifiedUserType");
                      //@ts-ignore
                      this.props.history.push("/ClassifiedListing");
                    }}>{t("Okay")}</Button>
                  </Box>
                </Box>
                {/* desktop footer block */}
                {/* <Box className="bottomBlock common-bottom-padding" display={{ xs: 'none', md: 'flex' }}>
                  <h6 className="bottom-text">POWERED BY</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo" alt="" />
                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

export default withTranslation()(withRouter(ClassifiedReportedSuccessfully));
export {ClassifiedReportedSuccessfully}
//Customizable Area End