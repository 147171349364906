// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  role: string;

  isShareModalOpen: boolean;
  shareUrl: string;

  filter: FilterList;

  meetingMinutes: any[];
  pagination: any;
  page: number | string;

  reactStateList: any[];
  countriesList: any[];
  citiesList: any[];
  complexList: any[];
  filterComplexList: any[];
  buildingList: any[];
  addedByList: any[];
}

interface SS {
  id: any;
}

interface FilterList {
  company: string;
  isComplex: number;
  country: string;
  city: string;
  complex: string;
  building: string;
  status: string;
  sortBy: string;
  addedBy: string;
  search: string;
}

export default class CEMeetingMinutesController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetMeetingMinutesCallId: string = "";
  GetMinuteReactEStateCallId: string = "";
  GetMinuteCountryCallId: string = "";
  GetMinuteCitiesCallId: string = "";
  GetMinuteComplexCallId: string = "";
  GetMinuteBuildingCallId: string = "";
  GetMinuteAddedByCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      role: "",

      isShareModalOpen: false,
      shareUrl: "",

      filter: {
        company: "",
        country: "",
        city: "",
        complex: "",
        building: "",
        sortBy: "",
        status: "",
        addedBy: "",
        isComplex: 1,
        search: "",
      },

      meetingMinutes: [],
      pagination: null,
      page: 1,

      reactStateList: [],
      countriesList: [],
      citiesList: [],
      complexList: [],
      filterComplexList: [],
      buildingList: [],
      addedByList: [],
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetMeetingMinutesCallId:
        return this.handleMeetingMinutesSuccess(responseJson);
      case this.GetMinuteReactEStateCallId:
        return this.handleReactEstateResponse(responseJson);
      case this.GetMinuteCountryCallId:
        return this.handleCountryResponse(responseJson);
      case this.GetMinuteCitiesCallId:
        return this.handleCityListResponse(responseJson);
      case this.GetMinuteComplexCallId:
        return this.handleComplexListResponse(responseJson);
      case this.GetMinuteBuildingCallId:
        return this.handleBuildingListResponse(responseJson);
      case this.GetMinuteAddedByCallId:
        return this.handleAddedByListResponse(responseJson);
      default:
        break;
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJson);
    }
    ApiCatchErrorResponse(errorResponse);
  }

  async componentDidMount() {
    const role = localStorage.getItem("role") ?? "";
    this.setState({ role: role }, () => {
      this.getAllMeetingMinutes();

      this.getMinuteReactEstate();
      this.getMinuteCountryList();
      this.getMinuteAddedByList();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      this.getAllMeetingMinutes();
    }
  }

  handleAllEmptyFilter = (value: string) => (value === "all" ? "" : value);

  getAllMeetingMinutes = () => {
    const { country, city, complex, building, addedBy, search, status, sortBy } = this.state.filter;
    this.setState({ loading: true }, async () => {
      this.GetMeetingMinutesCallId = await CEApiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_meeting/compney_employee_meeting_mins?page=${this.state.page
          }&per_page=10&contry=${this.handleAllEmptyFilter(country)}&city=${this.handleAllEmptyFilter(
            city
          )}&society_management_id=${this.handleAllEmptyFilter(
            complex
          )}&building_management_id=${this.handleAllEmptyFilter(
            building
          )}&meeting_mins_status=${this.handleAllEmptyFilter(status)}&title=${this.handleAllEmptyFilter(
            search
          )}&added_by=${this.handleAllEmptyFilter(addedBy)}&sort_by=${sortBy}`,
      });
    });
  };

  handleMeetingMinutesSuccess = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.code === 200) {
        this.setState({ meetingMinutes: responseJson.meeting.data, pagination: responseJson.meta.pagination });
      }
    });
  };

  getMinuteReactEstate = async () => {
    this.GetMinuteReactEStateCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  handleReactEstateResponse = (responseJson: any) => {
    if (responseJson && responseJson.compneys) {
      this.setState({
        reactStateList: responseJson.compneys.map((item: any) => ({ label: item.company_name, value: item.id })),
      });
    }
  };

  getMinuteCountryList = async () => {
    this.GetMinuteCountryCallId = await CEApiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countriesList: responseJson.data.countries.map((item: any) => ({ label: item, value: item })) });
    }
  };

  getMinuteCityList = async (country: any) => {
    this.GetMinuteCitiesCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${this.handleAllEmptyFilter(country)}`,
    });
  };

  handleCityListResponse = (responseJson: any) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        citiesList: responseJson.city_list.map((item: any) => ({ label: item.city, value: item.city })),
      });
    }
  };

  getMinuteComplexList = async (country?: string, city?: string) => {
    this.GetMinuteComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${country}`,
    });
  };

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.handleMinuteFilterComplexList(1);
      });
    }
  };

  handleMinuteFilterComplexList = (value: number) => {
    if (value === 1) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => !complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    } else if (value === 0) {
      this.setState({
        filterComplexList: this.state.complexList.map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    } else if (value === 2) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    }
  };

  getMinuteBuildingList = async (complexId: any) => {
    this.GetMinuteBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getMinuteAddedByList = async () => {
    this.GetMinuteAddedByCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_meeting/compney_employee_meeting_mins/added_by_list`,
    });
  };

  handleAddedByListResponse = (responseJson: any) => {
    if (responseJson && responseJson.added_by) {
      this.setState({
        addedByList: responseJson.added_by.map((item: any) => ({ label: item, value: item })),
      });
    }
  };

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;
    if (stateParam === "company") {
      this.setState({
        filter: {
          ...this.state.filter,
          company: value,
          country: "",
          city: "",
          complex: "",
          building: "",
          sortBy: "",
          status: "",
          addedBy: "",
        },
      });
    } else if (stateParam === "country") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            country: value,
            city: "",
            complex: "",
            building: "",
            sortBy: "",
            status: "",
            addedBy: "",
          },
        },
        () => this.getMinuteCityList(value)
      );
    } else if (stateParam === "city") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            city: value,
            complex: "",
            building: "",
            sortBy: "",
            status: "",
            addedBy: "",
          },
        },
        () => {
          const { country, city } = this.state.filter;
          this.getMinuteComplexList(country, city);
        }
      );
    } else if (stateParam === "complex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            complex: value,
            building: "",
            sortBy: "",
            status: "",
            addedBy: "",
          },
        },
        () => this.getMinuteBuildingList(value)
      );
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building: value,
          sortBy: "",
          status: "",
          addedBy: "",
        },
      });
    } else if (stateParam === "status") {
      this.setState({
        filter: {
          ...this.state.filter,
          status: value,
          sortBy: "",
          addedBy: "",
        },
      });
    } else if (stateParam === "sortBy") {
      this.setState({
        filter: {
          ...this.state.filter,
          sortBy: value,
          addedBy: "",
        },
      });
    } else if (stateParam === "addedBy") {
      this.setState({
        filter: {
          ...this.state.filter,
          addedBy: value,
        },
      });
    } else if (stateParam === "isComplex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            isComplex: value,
          },
        },
        () => this.handleMinuteFilterComplexList(value)
      );
    }
  };

  handlePagination = (e: any, value: any) => {
    this.setState({ page: value });
  };

  handleShareModal = () => {
    this.setState((prevState) => ({ isShareModalOpen: !prevState.isShareModalOpen }));
  }
}
// Customizable Area End
