//Customizable area start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import * as Yup from "yup";

export interface Props {
  navigation?: any;
  id?: string;
  classes: any;
  dataFromComponent1: any;
  handleModalClose: any;
  isEdit: boolean;
  empName?: string;
  empId?: number;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  initialValues: any;
  assignEmpformModal: any;
  assignmentValue: string;
  countryList: any[];
  cityList: any[];
  nameList: any[];
  usersList: any[];
}

interface SS {
  id: any;
}

export default class CEAssignObjectController extends CommonApiCallForBlockComponent<Props, S, SS> {
  countryList: string = "";
  cityList: string = "";
  nameList: string = "";
  usersList: string = "";
  setAssignBuildingCallId = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      initialValues: {
        EmpName: "",
        Assignment: "",
        city: "",
        country: "",
        nameOfAssignment: "",
      },
      assignEmpformModal: false,
      assignmentValue: "",
      countryList: [],
      cityList: [],
      nameList: [],
      usersList: [],
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  dynamicContent: any = {
    Company: {
      country: "Company's Country",
      countryLabel: "Select Country",
      city: "Company's City",
      cityLabel: " Select City",
      name: "Company Name",
      nameLabel: "Select Company Name",
    },
    "Individual Building": {
      country: "Individual Building's Country",
      countryLabel: "Select Country",
      city: "Individual Building's City",
      cityLabel: " Select City",
      name: "Individual Building Name",
      nameLabel: "Select Individual Building Name",
    },
    Complex: {
      country: "Complex's Country",
      countryLabel: "Select Country",
      city: "Complex's City",
      cityLabel: " Select City",
      name: "Complex Name",
      nameLabel: "Select Complex Name",
    },
  };

  async componentDidMount() {
    await super.componentDidMount();
    await this.getAssigneeCountryList();
    await this.getTeamNameListAPI();
    if (this.props.isEdit) {
      this.setState({ initialValues: { ...this.state.initialValues, EmpName: this.props.empName } });
    }
    this.setState({ assignEmpformModal: this.props.dataFromComponent1 });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.countryList) {
          this.handleAssigneeCountryList(responseJson);
        } else if (apiRequestCallId === this.cityList) {
          this.handleAssigneeCityList(responseJson);
        } else if (apiRequestCallId === this.nameList) {
          this.handleAssigneeNameList(responseJson);
        } else if (apiRequestCallId === this.setAssignBuildingCallId) {
          this.handleAssignBuildingData();
        } else if (apiRequestCallId === this.usersList) {
          this.handleUserListData(responseJson);
        }
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  //API response handler

  handleAssigneeCityList = (responseJson: any) => {
    if (!responseJson.errors) {
      const cities = responseJson.data.cities.map((item: any) => ({ label: item, value: item }));
      this.setState({ cityList: cities, initialValues: { ...this.state.initialValues, city: "" } });
    }
  };

  handleAssigneeCountryList = (responseJson: any) => {
    if (!responseJson.errors) {
      const list = responseJson.data.countries.map((item: any) => ({ label: item, value: item }));
      this.setState({ countryList: list });
    }
  };

  handleAssigneeNameList = (responseJson: any) => {
    if (!responseJson.errors) {
      const list = responseJson.status;
      this.setState({ nameList: list, initialValues: { ...this.state.initialValues, nameOfAssignment: "" } });
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    const { EmpName, Assignment } = this.state.initialValues;

    if (name == "EmpName") {
      this.setState({ initialValues: { ...this.state.initialValues, [name]: value } });
    } else if (name == "Assignment") {
      this.setState({ initialValues: { EmpName: EmpName, country: "", city: "", [name]: value } });
    } else if (name == "country") {
      this.setState({ initialValues: { EmpName: EmpName, Assignment: Assignment, city: "", [name]: value } });
    } else {
      this.setState({ initialValues: { ...this.state.initialValues, [name]: value } });
    }
  };

  handleAssignBuildingData = () => {
    this.props.handleModalClose();
  };

  handleUserListData = (response: any) => {
    if (!response.errors) {
      const list = response.status.map((item: any) => ({ label: item.name, value: item.id }));
      this.setState({ usersList: list });
    }
  };

  getAssigneeCountryList = async () => {
    this.countryList = await apiCallWithToken({
      method: "GET",
      endPoint: `bx_block_address/country_list`,
      contentType: "application/json",
    });
  };

  getTeamNameListAPI = async () => {
    this.usersList = await apiCallWithToken({
      contentType: "application/json",
      endPoint: `bx_block_my_team/team_members/member_name_list`,
      method: "GET",
    });
  };

  getAssigneeCityListAPI = async (value: string) => {
    this.cityList = await apiCallWithToken({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_address/city_list?country=${value}`,
    });
  };

  getAssigneeNameList = async (assignment: string, country: string, city: string) => {
    if (assignment) {
      let baseUrl = "/bx_block_my_team/company_employee/employee_team_objects/get_type_of_assignment";
      if (assignment === "Company") {
        baseUrl += "?employee_team_object[employee_team_objectable_type]=BxBlockRealEstateCompanies::RealEstateCompany";
      } else if (assignment === "Individual Building") {
        baseUrl += "?employee_team_object[employee_team_objectable_type]=BxBlockSettings::BuildingManagement";
      } else if (assignment === "Complex") {
        baseUrl += "?employee_team_object[employee_team_objectable_type]=BxBlockSocietyManagement::SocietyManagement";
      }
      baseUrl += `&country=${country}`;
      baseUrl += `&city=${city}`;
      this.nameList = await apiCallWithToken({
        method: "GET",
        contentType: "application/json",
        endPoint: baseUrl,
      });
    }
  };

  getColorFromValue = (value: string | number) => (Number.isNaN(value) || value === "" ? 0.5 : 1);

  getAssignMentValue = (values: any) => {
    if (values.Assignment === "Company") {
      return "BxBlockRealEstateCompanies::RealEstateCompany";
    } else if (values.Assignment === "Individual Building") {
      return "BxBlockSocietyManagement::SocietyManagement";
    } else if (values.Assignment === "Complex") {
      return "BxBlockSocietyManagement::SocietyManagement";
    }
  };

  postAssigneeAPI = async (values: any) => {
    const assignment = this.getAssignMentValue(values) ?? "";
    const initialFormData = new FormData();

    let employeeName = this.props.isEdit ? String(this.props.empName) : "";
    const employeeId = this.props.isEdit ? String(this.props.empId) : values.EmpName;

    if (values.EmpName && !this.props.isEdit) {
      const employeeData: any = this.state.usersList.find((names) => names.id === values.EmpName);
      if (employeeData) {
        employeeName = employeeData.name;
      }
    }

    initialFormData.append("employee_team_object[name]", employeeName);
    initialFormData.append("employee_team_object[employee_team_objectable_type]", assignment);
    initialFormData.append("employee_team_object[country]", values.country);
    initialFormData.append("employee_team_object[city]", values.city);
    initialFormData.append("employee_team_object[employee_team_objectable_id]", values.nameOfAssignment);
    initialFormData.append("employee_team_object[employee_team_member_id]", employeeId);

    this.setAssignBuildingCallId = await apiCallWithToken({
      method: "POST",
      endPoint: `bx_block_my_team/company_employee/employee_team_objects`,
      body: initialFormData,
    });
  };

  assignedValidationSchema = Yup.object().shape({
    EmpName: this.props.isEdit ? Yup.string().notRequired() : Yup.string().required("Employee name is required"),
    country: Yup.string().required("Country is required"),
    Assignment: Yup.string().required("Type of assignment is required"),
    nameOfAssignment: Yup.string().required("Company name is required"),
    city: Yup.string().required("City is required"),
  });
}
// Customizable Area End
