import React from "react";
// Customizable Area Start
import {
  Typography,
  Container,
  Select,
  Button,
  withStyles,
  Box,
  Grid,
  MenuItem,
  TextField,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  TableBody,
  Paper,
 Input

} from "@material-ui/core";
import moment from "moment"
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { withTranslation } from "react-i18next";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import SearchIcon from "@material-ui/icons/Search";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { withRouter,Link } from "react-router-dom";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";

import { languageCondition } from "../../../components/src/ConditionManager.web";

import ReceivedClientTicketController,{Props} from "./ReceivedClientTicketController.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import ReceivedClientTicketController, { Props } from "./ReceivedClientTicketController.web";
import {Clock,Ticket,Cat} from "./assets"
// Customizable Area End

class ReceivedClientTicket extends ReceivedClientTicketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t, classes,i18n }: any = this.props;
    const language = i18n.language;
    return (
      <MainScreen>
        <Box style={{ background: "#F4F7FF" }} className={classes.reportList} dir={languageCondition(language, "rtl", "ltr")}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            style={{ paddingTop: 35, minHeight: "95vh" }}
          >
            <Container>
              <Box className="navigation">
                <Box>
                  <Typography variant="body1">
                    <Box
                      className="reports-BudgetReportmain budgetReportBackButton"
                      component="span"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("Client Tickets")}
                    </Box>
                  </Typography>
                  <Typography
                    variant="h5"
                    className="sub-heading bold-text reports-BudgetReportmain"
                    style={webStyle.subHeadLine}
                  >
                    {t("Client Tickets")}
                  </Typography>
                </Box>
              </Box>
              <Box className="top-bar ChairmanInvoices" style={{ display: "flex" }}>
                <Box className="filter" style={{ display: "flex", flexWrap: "wrap" }}>
                  
                  <Select displayEmpty
                    value={this.state.countryFilter}
                    id="selectCountry"

                    onChange={(e) => this.handleCountryFilterChange(e)}
                    className="select-input selectBuildingForChairmanInvoices" >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Select Country")}
                    </MenuItem>
                    <MenuItem
                      value="all"
                      id="selectStatusBudgetReportAll"
                    >
                      {t("All")}
                    </MenuItem>
                    {this.state.countryList.length &&
                              this.state.countryList.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                  </Select>

                  <Select displayEmpty
                    className="select-input selectFloorChairmanInvoices"
                    value={this.state.cityFilter}
                    id="select-city1"
                    onChange={(e:any)=>{this.setState({cityFilter:e.target.value},()=>{this.getComplexList()}) }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Select City")}
                    </MenuItem>
                    <MenuItem
                      value="all"
                      id="selectStatusBudgetReportAll"

                    >
                      {t("All")}
                    </MenuItem>
                    {this.state.cityList?.length &&
                              this.state.cityList?.map((item: any) => {
                                return (
                                  <MenuItem key={item} value={item.city}>
                                    {item.city}
                                  </MenuItem>
                                );
                              })}
                  </Select>
                  <Select displayEmpty

                    value={this.state.companyFilter}
                    onChange={(e:any)=>{this.setState({companyFilter:e.target.value},()=>{this.getBuildingList()})}}
                    className="select-input selectUnitChairmanInvoices complexS"
                  >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Complex")}
                    </MenuItem>
                    <MenuItem
                        value=" "
                        id="selectStatusBudgetReport"
                    >
                      {t("All")}
                    </MenuItem>

                    {this.state.complexList?.length &&
                              this.state.complexList?.map((item: any) => {
                                return (
                                  <MenuItem key={item.attributes.name} value={item.id}>
                                    {item.attributes.name}
                                  </MenuItem>
                                );
                              })}

                  </Select>
                  <Select displayEmpty
                    className="select-input invoiceTypeFromChairmanInvoices"
                    value={this.state.buildingFilter}
                    onChange={(e:any)=>{this.setState({buildingFilter:e.target.value})}}
                  >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Select Building")}
                    </MenuItem>
                    <MenuItem
                        value=" "
                        id="selectStatusBudgetReport"
                    >
                      {t("All")}
                    </MenuItem>
                    {this.state.buildingList?.length &&
                              this.state.buildingList.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                  </Select>
                  <Select displayEmpty
                    className="select-input invoiceTypeFromChairmanInvoices unitSelect"
                    value={this.state.statusFilter}
                    onChange={(e:any)=>{this.setState({statusFilter:e.target.value})}}
                  >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Select Status")}
                    </MenuItem>
                    <MenuItem
                        value=" "
                        id="selectStatusBudgetReport"
                    >
                      {t("All")}
                    </MenuItem>
                    <MenuItem
                      value="responded"

                      id="selectStatusBudgetReport"
                    >
                      Responded
                    </MenuItem>
                    <MenuItem
                      value="pending_response"

                      id="selectStatusBudgetReport"
                    >
                      Pending Response
                    </MenuItem>
                  </Select>
                  <Select displayEmpty
                    className="select-input schedule-meeting-filter-status"
                    value={this.state.categoryFilter}
                    onChange={(e:any)=>{this.setState({categoryFilter:e.target.value})}}
                    id="categoryId"
                  >
                    <MenuItem
                      value=""
                      disabled
                   
                    >
                      {t("Select Category")}
                    </MenuItem>
                    <MenuItem
                      value="all"
                      id="selectStatusBudgetReportAll"
                    >
                      All
                    </MenuItem>
                    {this.state.categoryList?.length &&
                              this.state.categoryList.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                );
                              })}
                  </Select>
                  <div className="date-time">
                   <Input
                      value={this.state.dateFilter}
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          dateFilter: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={t("Select Date")}
                      className="input date schedule-meeting-filter-date"
                      onFocus={(e: any) => (e.target.type = "date")}
                    />
                    </div>
                  <Select displayEmpty
                    className="select-input schedule-meeting-filter-status"
                    value={this.state.ticketFilter}
                    onChange={(e:any)=>{this.setState({ticketOwnedFilter:e.target.value})}}
                    id="ticket-filter"
                  >
                    <MenuItem
                      value=""
                      disabled
                     
                    >
                      {t("Ticket Opened By")}
                    </MenuItem>
                    <MenuItem
                        value=" "
                        id="selectStatusBudgetReport"
                    >
                      {t("All")}
                    </MenuItem>
                   
                    {this.state.ticketList?.length &&
                              this.state.ticketList.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.attributes.full_name}
                                  </MenuItem>
                                );
                              })}

                  </Select>
                  <Select displayEmpty
                    className="select-input schedule-meeting-filter-status"
                    value={this.state.sortFilter}
                    onChange={(e:any)=>{this.setState({sortFilter:e.target.value})}}
                    id="sort"
                  >
                    <MenuItem
                      value=""
                      disabled
                      id="selectStatusBudgetReport"
                    >
                      {t("Sort By")}
                    </MenuItem>
                    <MenuItem
                      value="all"
                      id="selectStatusBudgetReportAll"
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      value="ascending"
                      id="selectStatusBudgetReportAll"
                    >
                      Ascending
                    </MenuItem>
                    <MenuItem
                      value="descending"
                      id="selectStatusBudgetReportAll"
                    >
                   Descending
                    </MenuItem>

                  </Select>
                  <Button className="handleSearchFilterForInvoices"
                    startIcon={<SearchIcon />}
                    style={{ marginLeft: "10px" }}
                    onClick={()=>this.handleSearch()}
                    id="search-id"
                  >
                    {t("Search")}</Button>
                </Box>
              </Box>

              <Grid container spacing={3}>


    <Grid item xs={4}>
        <Paper style={webStyle.cardAlign}>
            <div style={webStyle.cardSpace}>
            <img src={Ticket} style={{ width: 22, height: 22,marginRight:"15px", cursor :"pointer",paddingTop:"10px",paddingBottom:"20px",paddingLeft:"20px"}}/>
                <Typography className="bold-text" style={{padding:"5px"}}>{t("Open v/s Closed")}</Typography>
                <Typography  style={{padding:"5px"}}><span style={{color:"#FC8434"}} className="bold-text">{this.state.cardValues?.open_tickets}</span> / <span className="bold-text" style={{color:"grey"}}>{this.state.cardValues?.closed_tickets}</span></Typography>
            </div>
           
        </Paper>
    </Grid>
    <Grid item xs={4}>
        <Paper style={webStyle.cardAlign}>
            <div style={webStyle.cardSpace}>
            <img src={Cat} style={{ width: 22, height: 22,marginRight:"15px", cursor :"pointer",paddingTop:"10px",paddingBottom:"20px",paddingLeft:"20px"}}/>
            <Typography className="bold-text" style={{padding:"5px"}}>{t("Total by category")}</Typography>
            <Typography  className="bold-text" style={{padding:"5px",color:"#FC8434"}}>{this.state.cardValues?.total_by_category||"-"}</Typography>
            </div>
           
        </Paper>
    </Grid>
    <Grid item xs={4}>
        <Paper style={webStyle.cardAlign}>
            <div style={webStyle.cardSpace}>
            <img src={Clock} style={{ width: 22, height: 22,marginRight:"15px", cursor :"pointer",paddingTop:"10px",paddingBottom:"20px",paddingLeft:"20px"}}/>
            <Typography className="bold-text" style={{padding:"5px"}}>{t("Total Time to resolve")}</Typography>
            <Typography  className="bold-text" style={{padding:"5px",color:"#FC8434"}} >{this.state.cardValues?.average_resolution_time ||"-"}</Typography>
            </div>
           
        </Paper>
    </Grid>



</Grid>
              <Grid
                className="meeting-table"
                style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
              >
                <Grid item sm={12} md={12} xs={12}>
                  <Box className="table-top">
                    <h3 className="bold-text reports-BudgetReportmain" style={{ fontSize: "22px" }}>
                      {t("All tickets receieved from clients")}
                    </h3>
                    <div>
                      {
                        this.state.accountType==="coo" &&
                    <TextField
                       
                        label="Search"
                        variant="outlined"
                        className="table_search"
                        style={webStyle.searchInput}
                        placeholder={`${t("Search by ticket ID")}`}
                        onKeyUp={this.handleFilterDataKeyUp}
                        InputProps={{
                            startAdornment: (
                                <IconButton 
                                style={webStyle.searchIconButton}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                            style: webStyle.innerInput
                        }}
                    />
                      }
                    </div>
                  </Box>
                  <Divider />
                  <Table className="table-box">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="bold-text reports-BudgetReportmain1"
                          style={{ color: "#181d25", fontSize: "14px" }}
                        >
                          {t("Ticket Id")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmain1"
                          style={{ color: "#181d25", fontSize: "14px" }}
                        >
                          {t("Name")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainYear"
                          style={{ color: "#181d25", fontSize: "14px" }}
                        >
                          {t("Date")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainGenerated"
                          style={{ color: "#181d25", fontSize: "14px" }}
                          id="comp"
                        >
                          {t("Complex")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount"
                          style={{ color: "#181d25", fontSize: "14px" }}
                          id="build"
                        >
                          {t("Building")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount"
                          style={{ color: "#181d25", fontSize: "14px" }}
                          id="category"
                        >
                          {t("Category")}
                        </TableCell>
                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount"
                          style={{ color: "#181d25", fontSize: "14px" }}
                          id="status"
                        >
                          {t("Status")}
                        </TableCell>

                        <TableCell
                          className="bold-text reports-BudgetReportmainAmount"
                          style={{ color: "#181d25", fontSize: "14px" }}
                        ></TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.ticketData.length > 0 ? (
                          this.state.ticketData.map((item: any, key: any) => {
                            return (
                             
                                <TableRow>
                                  <TableCell >
                                    {item.id}
                                  </TableCell>
                                  <TableCell >
                                    {item.attributes.reported_by}
                                  </TableCell>
                                  <TableCell>
                                  {moment(item.attributes.created_at).format("DD/MM/YYYY hh:mm A")}
                                  </TableCell>
                                  <TableCell >
                                    {item.attributes.complex_name || "-"}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      item.attributes.building_name||"-"
                                    }
                                  </TableCell>
                                  <TableCell >
                                   {item.attributes.category}
                                  </TableCell>
                                  <TableCell className="bold-text" >
                                   
                                      <span  style={webStyle[item.attributes.status]} className="bold-text" >
                                      {item.attributes.status=="pending_response"?"Pending Response":item.attributes.status}
                                      </span>
                                   
                                  </TableCell>

                                  <TableCell  className="view-page-menu textid">
                                <Menu
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon/>
                                    </IconButton>
                                  }
                                  className="textid"
                                  style={webStyle.btnMenu}
                                >
                                  <MenuItem className="textid">
                                    <Link to={`/CompanyEmployee/ClientTicketsDetails/${item.id}`}>{t("View Details")}</Link>
                                  </MenuItem>
                                 
                                 
                                </Menu>
                              </TableCell>    
                                </TableRow>
                              
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>
                              {t("No Tickets Available")}
                            </TableCell>
                          </TableRow>
                        )}
                       
                      </TableBody>
                  </Table>
                  <Divider />
                  <Box className="table-bottom">
                    <PaginationModule
                      pagination={this.state.pagination}
                      handlePagination={this.handlePage}
                      page={this.state.page}
                    // count={this.state.incidentList?.length}

                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Loader loading={this.state.loading} />

        </Box>
        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() =>
            this.setState({ showError: false, error: null, })
          }
          message={this.state.error}
        />

      </MainScreen>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// export default ReceivedClientTicket;
const webStyle: any = {

  cardAlign: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px"
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  searchIconButton: {
    padding: "0px",
  },
  subHeadLine: {
    fontSize: "32px",
    fontWeight: "0px"
  },
  pending_response:{
    background: "#FFEDE0",
    color: "#FC8434",
   
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
    whiteSpace:"nowrap"
  },
  responded:{
    background: "#eefbf1",
    color: "#20a258",
   
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  }


};
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ReceivedClientTicket))
);
// Customizable Area End