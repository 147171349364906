// Customizable Area Start
export const CommunityStyle: any = {
  communityDashboard: {
    background: "#F4F7FF",
    "& .navigation-chairman": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& .sub-heading-box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        "& h5": {
          fontWeight: "600",
          fontSize: "22px",
        },
      },
    },
    "& .dashboard-card-box": {
      padding: "25px",
      background: "#fff",
      borderRadius: 8,
      minHeight: "150px",
      boxShadow: "4px 0px 14px #e9e9e9",
      "& .card-image": {
        width: "40px",
        height: "40px",
        padding: "10px",
        border: "1px solid #d9d4d3",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        "& img": {
          width: "28px",
          height: "28px",
        },
      },
      "& .dashboard-card-heading": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& img": {
          paddingLeft: "10px",
        },
      },
      "& .active-register-member-tooltip": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
      },
      "& h4": {
        fontWeight: "600",
        fontSize: "18px",
        margin: "12px 0",
      },
      "& .card-bottom-info": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
      },
      "& .info-box": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        "& span": {
          fontWeight: "600",
          color: "#FC8434",
        },
      },
    },
    "& a": {
      textDecoration: "none !important",
      color: "black",
    },
    "& .budget-table-content-box": {
      background: "#fff",
      borderRadius: 8,
      boxShadow: "4px 0px 14px #e9e9e9",
      "& .header": {
        fontWeight: "600",
        padding: "20px 15px",
        fontSize: "24px",
      },
      "& .footer": {
        padding: "20px 15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
          fontSize: "18px",
          fontWeight: "600",
        },
        "& h4": {
          fontSize: "18px",
          color: "#FC8434",
          fontWeight: "600",
        },
      },
      "& .body": {
        padding: "20px 15px",
        "& .table-header": {
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& span": {
            color: "#999",
          },
        },
        "& .table-content": {
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid #F4F6FB",
          "& span": {
            fontWeight: "600",
          },
        },
      },
      "& hr": {
        margin: "0",
        color: "#F4F6FB",
      },
    },
    "& .upcoming-events-box": {
      marginBottom: "30px",
    },
    "& .ticket-number": {
      cursor: "pointer",
    },
    "& .print-report-box": {
      textAlign: "right",
      "& button": {
        backgroundColor: "#2b6fec",
        color: "white",
        padding: "12px 30px",
        borderRadius: "8px",
        fontWeight: "600",
      },
    },
    "& .user-details": {
      boxShadow: "4px 0px 14px #e9e9e9",
      padding: "20px 15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: "8px",
      position: "relative",
    },
    "& .user-details.await-accept-user": {
      padding: "30px 15px",
      gap: "8px",
    },
    "& .user-details .building-unit-box": {
      margin: "10px 0",
      textAlign: "center",
    },
    "& .user-details p": {
      marginBottom: "8px",
      textAlign: "center",
    },
    "& .user-details .role": {
      padding: "5px 12px",
      borderRadius: "25px",
      background: "#e4edff",
      color: "#2B6FED",
      fontWeight: 600,
    },
    "& .user-details .icons": {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      gap: "12px",
    },
    "& .user-details .icons img": {
      cursor: "pointer",
    },
    "& .user-details .user-menu": {
      position: "absolute",
      top: 10,
      right: 10,
    },
    "& .user-details .arabic-menu": {
      position: "absolute",
      top: 10,
      right: 10,
    },
    "& .user-details .user-menu ul": {
      left: "-150px !important",
      top: "0px !important",
      cursor: "pointer",
    },
    "& .user-details.await-accept-user .user-menu ul": {
      left: "-175px !important",
      top: "0px !important",
    },
    "& .user-details.await-accept-user .user-menu ul li": {
      padding: "8px 15px !important",
      fontSize: "14px",
    },
    "& .user-details.await-accept-user .user-menu svg": {
      color: "darkgrey",
    },
    "& .user-details.await-accept-user .arabic-menu svg": {
      color: "darkgrey",
    },
    "& .select-year": {
      background: "#fff",
      border: "1px solid lightgrey",
      borderRadius: 5,
      padding: "10px 12px",
      fontSize: "14px",
      color: "grey",
    },
    "& .suggestion-table": {
      borderRadius: "8px",
      background: "white",
      marginTop: "30px",
      marginBottom: "30px",
      boxShadow: "4px 0px 14px #e9e9e9",
    },
    "& .suggestion-table .top-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    "& .suggestion-table .top-content .heading": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      "& span": {
        color: "#FC8434",
        fontWeight: "600",
      },
    },
    "& .suggestion-table .top-content .right-content": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    "& .suggestion-table .top-content .right-content span": {
      color: "#FC8434",
      fontWeight: 600,
    },
    "& .suggestion-table .top-content .heading h2": {
      fontWeight: 600,
      fontSize: "22px",
    },
    "& .suggestion-table .unit-pagination": {
      padding: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .suggestion-table .unit-pagination p": {
      fontWeight: 600,
    },
    "& .suggestion-table .unit-pagination p span": {
      fontWeight: 600,
      color: "#FC8434",
    },
    "& .suggestion-table .unit-table th": {
      fontWeight: 600,
      color: "darkgrey",
    },
    "& .suggestion-table .unit-table svg": {
      color: "darkgrey",
    },
    "& .suggestion-table .unit-select": {
      fontWeight: 600,
      padding: "12px 40px 12px 10px",
      borderRadius: "8px",
      border: "1px solid lightgray",
    },
    "& .suggestion-table .search-unit .MuiInput-root": {
      borderRadius: "8px",
      border: "1px solid lightgray",
      padding: "5px 10px",
    },
    "& .suggestion-table .search-unit svg": {
      fill: "darkgrey",
    },
    "& .suggestion-table .search-unit .MuiInput-root::after": {
      content: "",
      border: 0,
      outline: "none",
      position: "unset",
    },
    "& .suggestion-table .search-unit .MuiInput-root::before": {
      content: "",
      border: 0,
      outline: "none",
      position: "unset",
    },
    "& .suggestion-table .MuiTableCell-head": {
      color: "#9198a3",
    },
    "& .suggestion-table .MuiPaginationItem-page.Mui-selected": {
      color: "white",
      background: "#FC8434",
      border: "0",
    },
    "& .chairman-filter": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      margin: "10px 0 15px 0",
      "& .action-filter-box": {
        margin: "0",
      },
    },
    "& .roles-box": {
      margin: "0 0 15px 0",
    },
    "& .invitation-table .szh-menu-container": {
      position: "absolute",
    },
    "& .invitation-table .szh-menu-container ul": {
      left: "-150px !important",
      top: "0 !important",
    },
    "& .invitation-table .arabic-sent-menu .szh-menu-container ul": {
      left: "-50px !important",
      top: "0 !important",
    },
    "& .invitation-sent-on": {
      fontSize: "14px",
      color: "#9198a3",
    },
    "& .action-filter-box": {
      display: "flex",
      alignItems: "center",
      gap: "18px",
      margin: "15px 0",
      "& button": {
        background: "#2B6FED",
        color: "white",
        borderRadius: "8px",
        fontWeight: "600",
        padding: "7px 18px",
      },
    },
  },
};

// Customizable Area End