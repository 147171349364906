// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  IconButton,
  Link,
  withStyles,
  Box,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { ContractsStyleWeb } from "./ContractsStyle.web";
import TemplateDetailController, { Props } from "./TemplateDetailController.web";
import { DownloadIcon, ShareIcon, ExclamationIcon } from "./assets";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class TemplateDetail extends TemplateDetailController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.detailPage}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <Link href="/Contracts">
                      <IconButton>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")}/>
                      </IconButton>
                    </Link>
                    <span className="bold-text">{this.state.templateData.templateName}</span>
                  </div>
                  <div className="right-icon">
                    <Link href={this.state.templateData.templateUrl} target="_blank">
                      <img src={DownloadIcon} alt="SortIcon" />
                    </Link>
                  </div>
                </Box>
                <Container>
                  <Box className="content-box">
                    <div className="contracts-list">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.templateData.templateText,
                        }}
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.templateData.conditionText,
                        }}
                      />
                      <br />
                    </div>
                    <Box className="upload-button" style={{ background: "white" }}>
                      <Box className="upload-button-group">
                        <Box className="top">
                          <Button className="template-details-edit-doc" onClick={() => this.handleEditTemplate()}>
                            {t("Edit Document")}
                          </Button>
                          <Button
                            className="template-details-delete-temp"
                            onClick={() => this.handleDeleteTemplateModal()}
                          >
                            {t("Delete Template")}
                          </Button>
                        </Box>
                        <Box className="bottom">
                          <Button
                            className="template-details-generate-lease"
                            onClick={() => {
                              this.setState({ loading: true }, () => {
                                this.handleCreateContract();
                              });
                            }}
                          >
                            {t("Generate a Lease")}
                          </Button>
                          <Box className="image template-details-share-temp" onClick={() => this.handleShareModal()}>
                            <img src={ShareIcon} alt="" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        <Dialog
          className="delete-document personal template-details-delete-dialog"
          dir={languageCondition(language, "rtl", "ltr")}
          fullWidth
          open={this.state.isTerminateContractModalOpen}
          onClose={() => this.handleDeleteTemplateModal()}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={ExclamationIcon} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                {t("Delete lease Template?")}
              </Typography>
              <Typography variant="body1">
                {t("Are you sure want to delete lease template?")} {t("Once deleted you won't be able to retrieve.")}
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button className="template-details-delete-dialog-temp" onClick={() => this.deleteTemplateDetails()}>
                  {t("Yes, Delete")}
                </Button>
                <Button
                  className="template-details-delete-close-dialog"
                  onClick={() => this.handleDeleteTemplateModal()}
                >
                  {t("No, Don't Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <ShareDocumentModal
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
          language={language}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(ContractsStyleWeb)(TemplateDetail));
// Customizable Area End
