import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import * as Yup from "yup";
import moment from "moment/moment";
import {CEApiCall} from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  location: any;
  match: any;
  i18n: any;
  t: any;
  handleClose:any;
  isEdit?:boolean;
  nominationDetails?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  building: any;
  countryList: any;
  cityList: any;
  selectedCity: any;
  buildingList: any;
  startDateType: any;
  endDateType: any;
  nominationStartDate: any;
  nominationEndDate: any;
  description: any;
  isEdit:boolean;
  confirmModal:boolean;
  nominationDetails:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CECreateNominationController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  CountryListNomination: any;
  CityListNomination: any;
  ComplexListNomination: any;
  createNominationId: any;
  editNominationId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        cityList: [],
        countryList: [],
        buildingList: [],
        startDateType: "text",
        endDateType: "text",
        nominationStartDate: "",
        nominationEndDate: "",
        description: "",
        selectedCity: "",
        building: "",
        isEdit:false,
        confirmModal:false,
        nominationDetails: new FormData(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.CountryListNomination){
        this.setState({
          countryList: responseJson.data.countries,
        });
      }
      if(apiRequestCallId === this.CityListNomination){
        this.setState({
          cityList: responseJson.city_list,
        });
      }
      if(apiRequestCallId === this.ComplexListNomination){
        this.setState({
          buildingList: responseJson.complexes.data,
        });
      }
      if(apiRequestCallId === this.createNominationId){
        this.nominationCreateResponse(responseJson)
      }
      if(apiRequestCallId === this.editNominationId){
        this.nominationEditResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  nominationCreateResponse = (responseJson:any) => {
    if(responseJson.meta.message === "Nomination created."){
      this.successModal("Nomination Created Successfully")
      this.sentMessage("NOMINATION_CREATED")
    }else{
      this.errorModal("Nomination Creation Failed")
    }
  }


  nominationEditResponse = (responseJson:any) => {
    if(responseJson.data){
      this.successModal("Nomination Updated Successfully")
      this.sentMessage("NOMINATION_UPDATED")
    }else{
      this.errorModal("Nomination Update Failed")
    }
  }
  manageChangeCountry = (e:any,setValues:any) => {
    setValues("country",e.target.value)
    setValues("city","")
    setValues("complex","")
    this.getCityListCreate(e.target.value)
  }

  handleChangeCity = (e:any,setValues:any,country:any) => {
    setValues("city",e.target.value)
    setValues("complex","")
    this.getComplexListCreate(country, e.target.value)
  }


  async componentDidMount(): Promise<void> {
    this.getCountryListCreate()
    console.log("CHECK FOR THE EDIT",this.props.nominationDetails)
    this.setState({
      isEdit:this.props.isEdit || false
    })
  }

  initialValuesNomination = () => {
    return {
      country: "",
      city: "",
      complex:"",
      title: "",
      startDate: "",
      endDate: "",
      description: "",
    }
  }

  initialValuesNominationEdit = () => {
    return{
      title: this.props.nominationDetails.title,
      country: this.props.nominationDetails.country || "-",
      city: this.props.nominationDetails.city,
      complex:this.props.nominationDetails.society_management_id,
      startDate: moment(this.props.nominationDetails.start_date).format("YYYY-MM-DD"),
      endDate: moment(this.props.nominationDetails.end_date).format("YYYY-MM-DD"),
      description: this.props.nominationDetails.description,
    }
  }


  nominationSchema() {
    const {t} = this.props
    const validations = Yup.object().shape({
      country: Yup.string().required(t("Please Select Country")),
      city: Yup.string().required(t("Please Select City")),
      complex: Yup.string().required(t("Please Select Complex")),
      // @ts-ignore
      title: Yup.string().required(t(`Nomination title is required`)),
      startDate: Yup.date()
          .required(t("Required")),
      endDate: Yup.date()
          .required(t("Required")).min(Yup.ref('startDate'), t("End date should be greater than start date")),
      description:Yup.string().required(t("Please enter nomination Description")),
    });
    return validations
  }

  createNomination = async (values:any) => {
    localStorage.setItem("complex_id",values.complex)
    const formData = new FormData
    formData.append("company_chairman_nomination[country]",values.country)
    formData.append("company_chairman_nomination[city]",values.city)
    formData.append("company_chairman_nomination[society_management_id]",values.complex)
    formData.append("company_chairman_nomination[title]",values.title)
    formData.append("company_chairman_nomination[start_date]",values.startDate)
    formData.append("company_chairman_nomination[end_date]",values.endDate)
    formData.append("company_chairman_nomination[description]",values.description)
    if(this.state.isEdit){
      this.editNominationAPICall(formData)
    }else {
      this.setState({
        nominationDetails:formData,
        confirmModal:true
      })
    }
  }

  getCountryListCreate = async () => {
    this.CountryListNomination = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_address/country_list`
    })
  }

  getCityListCreate = async (country:any) => {
    this.CityListNomination = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_custom_form/incident_managements/city_list?country=${country}`
    })
  }

  getComplexListCreate = async (country:any,city:any) => {
    this.ComplexListNomination = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_interactive_faqs/complex_list?country=${country}&city=${city}`
    })
  }

  createNominationAPICall = async (formData:any) => {
    this.createNominationId = await this.apiCallForFileUpload({
      contentType: "application/json",
      method:"POST",
      endPoint:`bx_block_my_team/company_employee_nominations`,
      body:formData,
    })
  }

  editNominationAPICall = async (formData:any) => {
    const nominateId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.editNominationId = await this.apiCallForFileUpload({
      contentType: "application/json",
      method:"PUT",
      endPoint:`/bx_block_my_team/company_employee_nominations/update_nomination?id=${nominateId}`,
      body:formData,
    })
  }
  // Customizable Area End
}
