// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { IDIcon, Share, TenantIcon } from "./assets";
import InvoiceDetailsController, { Props } from "./InvoiceDetailsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { CloseButton } from "./CloseButton";
import moment from "moment";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";

class Visitors extends InvoiceDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    
    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="invoice-details-page auth-cols">
          <Grid container className="invoice-details-navigation fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "history-back-icon")} onClick={() => window.history.back()} />
                <Box className="ellipse-nav-bar">
                  <p style={{ fontSize: "1.2rem" }} className="bold-text invoice-details-header">
                    {t(this.state.type?.split("_").join(" "))} {t("Invoice")} :{" "}
                    {moment(this.state.date, "DD-MM-YYYY").format("MMMM YYYY")}
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="invoice-details-content bold-text">
                    {t("Invoice Details")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={`invoice-details-status ${this.state.status}`}
                  >
                    {t(this.state.status?.split("_").join(" "))}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="8px"
                  bgcolor="white"
                  padding="15px"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                >
                  <Box style={{ minWidth: "100%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Building")}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.building}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Unit")}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.unit}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Due Amount")}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Due Date")}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(this.state.dueDate).format("MMMM DD, YYYY")}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Invoice Type")}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {t(this.state.type?.split("_").join(" "))}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text">
                    {t("Tenant Details")}
                  </Typography>
                </Box>
                <Box borderRadius="8px" bgcolor="white" padding="15px" style={{ boxShadow: "4px 0px 14px #e9e9e9" }}>
                  <Box style={{ marginBottom: "10px" }}>
                    <Box style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
                      <img src={TenantIcon} alt="" style={{ width: "18px", height: "18px" }} />
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary" style={{ lineHeight: "15px" }}>
                          {t("Tenant")}:
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                          {this.state.tenant || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <Box style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
                      <img src={IDIcon} alt="" style={{ width: "20px", height: "15px" }} />
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary" style={{ lineHeight: "15px" }}>
                          {t("ID Number")}:
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                          {this.state.tenantId || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="my-invoice-details-download-share-btn download-share-bar">
                <CloseButton
                  fullWidth
                  className="my-invoice-details-download-btn download-button-invoice"
                  onClick={() => this.invoiceDetailsDownload(this.state.invoiceId)}
                >
                  {t("Download Invoice")}
                </CloseButton>
                <Box
                  className="my-invoice-details-share-btn shareIcon"
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                  onClick={this.handleInvoiceShareModal}
                >
                  <img src={Share} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleInvoiceShareModal}
          heading={t("Share")}
          documentURL={`${baseURL}/bx_block_fees_payment/invoices/${this.state.invoiceId}/download_invoice.pdf`}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
