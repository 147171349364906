import React from "react";

// Customizable Area Start
import { withTranslation } from "react-i18next";
import ChairmansNotificationController, { Props } from "./ChairmansNotificationController.web";
import { Box, Button, Container, Grid, IconButton, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import { NotificationStyle } from "./NotificationStyle.web";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
// Customizable Area End

class ChairmansNotification extends ChairmansNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container className={classes.chairmanNotification}>
            <Box className="navigation chairman-notification-navigation">
              <Box className="chairman-notification-heading-box">
                <Typography variant="body1">
                  {t("Home")} /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Notifications")}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text chairman-notification-heading">
                  {t("Notifications")}
                </Typography>
              </Box>
            </Box>
            <Box className="content-boxes chairman-notification-content">
              <Tabs value={this.state.currentTab} onChange={this.handleTabChange}>
                <Tab label={t("Tasks")} onClick={() => this.getAllTaskList()} />
                <Tab label={t("Messages")} onClick={() => this.getAllChairmanNotification()} />
              </Tabs>
              <Box className="tab-content chairman-notification-tabs-box">
                <TabPanel value={this.state.currentTab} index={0}>
                  <>
                    <Box className="tab-content-box chairman-notification-tab-content">
                      <Grid container spacing={2}>
                        {this.state.taskList.length == 0 && (
                          <Grid item xs={12}>
                            <Box className="notification-box">{t("No task found")}</Box>
                          </Grid>
                        )}
                        {this.state.taskList.map((notification: any) => {
                          return (
                            <Grid item xs={12} key={notification.id}>
                              <Box className="notification-box chairman-notification-card-box">
                                <Box className="tag-box">
                                  {notification.attributes?.building_management?.name && (
                                    <span className="building chairman-notification-tag">{notification.attributes?.building_management?.name}</span>
                                  )}
                                  {notification.attributes?.apartment_management?.name && (
                                    <span className="unit chairman-notification-tag">{notification.attributes?.apartment_management?.name}</span>
                                  )}
                                  {notification.attributes.assign_actions_type && (
                                    <span className="category chairman-notification-tag">{notification.attributes.assign_actions_type}</span>
                                  )}
                                </Box>
                                <h4 className="bold-text">{notification.attributes.title}</h4>
                                <p>{notification.attributes.description}</p>
                                <p className="time">{moment(notification.attributes.created_at).fromNow()}</p>
                                {notification.attributes.assign_actions_type !== "Manual Task" &&
                                  <Button
                                    className="view-button chairman-notification-view-btn"
                                    onClick={() => {
                                      window.open(notification.attributes.app_url, "_self", "noopener");
                                    }}
                                  >
                                    {t("View Document")}
                                  </Button>
                                }
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </>
                </TabPanel>
                <TabPanel value={this.state.currentTab} index={1}>
                  <>
                    <Box className="tab-content-box chairman-notification-tab-box">
                      <Grid container spacing={2}>
                        {this.state.notificationList.length == 0 && (
                          <Grid item xs={12}>
                            <Box className="notification-box chairman-notification-empty">{t("No message found")}</Box>
                          </Grid>
                        )}
                        {this.state.notificationList.map((notification: any) => {
                          return (
                            <Grid item xs={12} key={notification.id}>
                              <Box className={`notification-box ${notification.attributes.is_read === false && "unRead"}`}>
                                <Box className="header chairman-notification-tag-header">
                                  <Box className="tag-box">
                                    {notification.attributes.building_name && (
                                      <span className="building chairman-notification-tag">{notification.attributes.building_name}</span>
                                    )}
                                    {notification.attributes.notification_type && (
                                      <span className="category chairman-notification-tag">{notification.attributes.notification_type}</span>
                                    )}
                                  </Box>
                                  <Box className="chairman-notification-menu">
                                    <Menu
                                      menuButton={
                                        <IconButton>
                                          <MoreVertIcon />
                                        </IconButton>
                                      }
                                    >
                                      <MenuItem className="notification-read-menu" onClick={() => this.updateReadNotificationStatus(notification.id, true)}>
                                        {t("Mark as read")}
                                      </MenuItem>
                                      <MenuItem className="notification-unread-menu" onClick={() => this.updateReadNotificationStatus(notification.id, false)}>
                                        {t("Mark as unread")}
                                      </MenuItem>
                                      <MenuItem className="notification-delete-menu" onClick={() => this.deleteSingleNotification(notification.id)}>
                                        {t("Delete")}
                                      </MenuItem>
                                    </Menu>
                                  </Box>
                                </Box>
                                <h4 className="bold-text">{languageCondition(language, notification.attributes.title_ar, notification.attributes.title)}</h4>
                                <p>{languageCondition(language, notification.attributes.description_ar, notification.attributes.description)}</p>
                                <p className="time chairman-notification-time">{moment(notification.attributes.created_at).fromNow()}</p>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </>
                </TabPanel>
              </Box>
            </Box>
          </Container>
        </GeneralHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

export default withTranslation()(withStyles(NotificationStyle)(ChairmansNotification));
