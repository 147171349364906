import React from 'react';
import SessionManagerController from "./SessionManagerController"
import {withRouter} from "react-router-dom"
import AlertErrorWeb from "./AlertError.web";
import AlertSuccessWeb from "./AlertSuccess.web";
class SessionManager extends SessionManagerController {
  render() {
    return (
      <>
        <AlertErrorWeb show={this.state.showError} handleClose={()=>this.setState({showError:false})} message={this.state.error} />
        <AlertSuccessWeb show={this.state.showSuccess} handleClose={()=>this.setState({showSuccess:false})} message={this.state.successMessage} />
      </>
    );
  }
}

// @ts-ignore
export default withRouter(SessionManager);
