// Customizable Area Start
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button, Container, Link, withStyles, Box, Grid, IconButton } from "@material-ui/core";
import FaqResidentController, { Props } from "./FaqResidentController.web";
import { FaqChairmanStyleWeb } from "./FaqChairmanStyle.web";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import NavigateBeforeSharpIcon from "@material-ui/icons/NavigateBeforeSharp";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";
// @ts-ignore
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
// @ts-ignore
import { baseURL } from "../../../framework/src/config";
import { settings } from "./FaqOwner.web";

class FaqResident extends FaqResidentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n }: any = this.props;
    const language = i18n?.language;

    return (
      <>
        <Box
          style={{ background: "#F7F9FE", height: "100vh" }}
          className={classes.faqOwner}
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                {this.state.faqStep === 1 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon">
                      <Link href="/FaqComplexList">
                        <IconButton>
                          <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                        </IconButton>
                      </Link>
                      <span className="bold-text resident-heading">{t("FAQs")}</span>
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        {this.state.catagoriesList.map((category: any) => {
                          return (
                            <div
                              key={category.id}
                              className="faq-item"
                              onClick={() => {
                                this.setState(
                                  {
                                    ...this.state,
                                    faqList: category.attributes.FAQ.data,
                                    faq: category.attributes.name,
                                  },
                                  () => {
                                    this.changeFaqState(2);
                                  }
                                );
                              }}
                            >
                              <p>{category.attributes.name}</p>
                              {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                            </div>
                          );
                        })}
                      </Box>
                    </Container>
                  </>
                )}
                {this.state.faqStep === 2 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon">
                      <IconButton onClick={() => this.changeFaqState(1)}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      {this.state.faq}
                      {t("'s FAQs")}
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        {this.state.faqList.map((faq: any) => {
                          return (
                            <div
                              key={faq.id}
                              className="faq-item resident-faq-box"
                              onClick={() => {
                                this.setState(
                                  {
                                    ...this.state,
                                    images: faq.attributes.images,
                                    answer: faq.attributes.content,
                                    question: faq.attributes.title,
                                  },
                                  () => {
                                    this.changeFaqState(3);
                                  }
                                );
                              }}
                            >
                              <p className="bold-text">{faq.attributes.title}</p>
                              {languageCondition(language, <NavigateBeforeSharpIcon />, <NavigateNextIcon />)}
                            </div>
                          );
                        })}
                      </Box>
                    </Container>
                  </>
                )}
                {this.state.faqStep === 3 && (
                  <>
                    <Box display={{ xs: "flex", md: "flex" }} className="backIcon">
                      <IconButton onClick={() => this.changeFaqState(2)}>
                        <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                      </IconButton>
                      {this.state.faq}
                      {t("'s FAQs")}
                    </Box>
                    <Container>
                      <Box className="faq-list">
                        <div className="faq-ans">
                          <span>{this.state.question}</span>
                          <p>{this.state.answer}</p>
                          <Box className="slicker-images-box">
                            <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                              {this.state.images.map((image: any, index: number) => {
                                return (
                                  <div className="testId-image" onClick={() => this.setState({ photoIndex: index, imageBox: true })}>
                                    <img src={`${baseURL}${image.url}`} alt="" />
                                  </div>
                                );
                              })}
                            </Slider>
                          </Box>
                        </div>
                        <Button fullWidth variant="contained" disableElevation onClick={() => this.changeFaqState(2)}>
                          {t("Close")}
                        </Button>
                      </Box>
                    </Container>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>

        {this.state.imageBox && this.state.images.length > 0 && (
          <Lightbox
            prevSrc={`${baseURL}${
              this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length].url
            }`}
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
              })
            }
            mainSrc={`${baseURL}${this.state.images[this.state.photoIndex].url}`}
            nextSrc={`${baseURL}${this.state.images[(this.state.photoIndex + 1) % this.state.images.length].url}`}
            imagePadding={120}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
              })
            }
            onCloseRequest={() => this.setState({ imageBox: false })}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(FaqChairmanStyleWeb)(FaqResident));
// Customizable Area End
