// Customizable Area Start
import React from "react";
import "../../dashboard/src/Dashboard.web.css";
import { Typography, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { TenantLogo } from "../../dashboard/src/assets";
import AuditorController, { Props } from "../../customform/src/AuditorSidebarController.web";
import { MyTeamImageComponent } from "../../dashboard/src/SidebarImages/MyTeamImage.web";
import { ComplexImageComponent } from "../../dashboard/src/SidebarImages/ComplexImage.web";
import { DocumentImageComponent } from "../../dashboard/src/SidebarImages/DocumentImage.web";
import { DashboardImageComponent } from "../../dashboard/src/SidebarImages/DashboardImage.web";
import { ChatImageComponent } from "../../dashboard/src/SidebarImages/ChatImage.web";
import { HelpImageComponent } from "../../dashboard/src/SidebarImages/HelpImage.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class ServiceProviderSideBar extends AuditorController {
  constructor(props: Props) {
    super(props);
  }

  providerTextHighlight = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight";
      }
    }
  };

  providerColorHide = (pathName: any, paths: any) => {
    for (const path of paths) {
      if (pathName.toString().includes(path.toString())) {
        return "highlight-blue";
      }
    }
  }

    // test case try code change

  render() {
    const {t,i18n}: any = this.props; // Get the t and i18n props from props object
    const {language} = i18n // Get the language from i18n for Service Provider
    const pathName = window.location.pathname;
    return (
      <Box style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Box className="AccordinoList">
          <Accordion id={"dashboard"} expanded={this.state.expanded == `panel1`} onChange={this.handleAccordinoChange(`panel1`)}>
            <AccordionSummary
              expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
              className="AuditorDashboard"
              aria-controls={"panel2bh-content"}
              id={"Dashboards"}
              style={providerDashboard.ListItem}
            >
              <Typography className={`SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/ServiceProviderDashboard","/DashboardTicket","/DashboardActions","/AvgResolutionTicket","ServiceProviderManagementDetail"])}`}>
                <DashboardImageComponent />
              </Typography>
              <Typography className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/ServiceProviderDashboard","/DashboardTicket","/DashboardActions","/AvgResolutionTicket","ServiceProviderManagementDetail"])}`}>{t("My Dashboards")}</Typography>
            </AccordionSummary>
            <AccordionDetails id="GeneralDashboard" className="AuditorDashboardLink" onClick={() => {
              //@ts-ignore
              this.props.history.push("/ServiceProviderDashboard");
            }}>
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.providerTextHighlight(pathName, ["/ServiceProviderDashboard","ServiceProviderManagementDetail"])}`}>
                {t("General Dashboard")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails className="DashboardTicketAccordion" onClick={() => this.props.history.push("/DashboardTicket")}>
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.providerTextHighlight(pathName, ["/DashboardTicket","/AvgResolutionTicket"])}`}>
                {t("Ticket Dashboard")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              className="AuditorDashboardActions"
              id="actionAssinedToMe"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/DashboardActions");
              }}
            >
              <Typography variant="body2" className={`SingleLinkSize cursor-pointer ${this.providerTextHighlight(pathName, ["/DashboardActions"])}`}>
                {t("Actions Assigned to me")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Box className="SingleLink" style={{ marginTop: "20px" }}>
            <Typography className={`SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/TeamMembers/Core_member", "/TeamMember/userDetails"])}`} >
              <MyTeamImageComponent />
            </Typography>
            <div
              id="BuildingManagement"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/TeamMembers/Core_member");
              }}
            >
              <Typography style={{ marginLeft: "0px" }} className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/TeamMembers/Core_member", "/TeamMember/userDetails"])}`}>
                {t("Building's Management")}</Typography>
            </div>
          </Box>
          <Box className="SingleLink ComplexAudiorBox" style={{ marginTop: "20px" }}>
            <Typography className={`SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/Complex", "/Building", "/UnitDetail"])}`}>
              <ComplexImageComponent />
            </Typography>
            <div
              className="complexAuditorLink"
              id="Complex"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/Complex");
              }}
            >
              <Typography style={{ marginLeft: "0px" }} className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/Complex", "/Building", "/UnitDetail"])}`}>{t("Complex Page")}</Typography>
            </div>
          </Box>
          <Box className="SingleLink AuditorChat">
            <Typography className={`SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/DocumentChairman"])}`}>
              <DocumentImageComponent />
            </Typography>
            <div
              className="AuditorChatLink"
              id="documentsService"
              onClick={() => this.props.history.push("/DocumentChairman")}
            >
              <Typography style={{ marginLeft: "0px" }} className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/DocumentChairman"])}`}>{t("Documents")}</Typography>
            </div>
          </Box>
          <Box className="SingleLink AuditorChat">
            <Typography className={`SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/AuditorChat"])}`}>
              <ChatImageComponent />
            </Typography>
            <div
              className="AuditorChatLink"
              id="serviceChat"
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/AuditorChat");
              }}
            >
              <Typography style={{ marginLeft: "0px" }} className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/AuditorChat"])}`}>{t("Chat")}</Typography>
            </div>
          </Box>
          <Accordion expanded={this.state.expanded == `panel7`} onChange={this.handleAccordinoChange(`panel7`)}>
            <AccordionSummary className="AuditorHelpMain"
              expandIcon={languageCondition(language,<ArrowBackIosIcon className="arabicSideBarIcon" style={{ width: 16, height: 16 }} />,<ArrowForwardIosIcon style={{ width: 16, height: 16 }} />)}
              style={providerDashboard.ListItem}
            >
              <Typography className={` SingleLinkSize cursor-pointer ${this.providerColorHide(pathName, ["/FaqChairman", "/ContactUsChairman"])}`}>
                <HelpImageComponent />
              </Typography>
              <Typography className={`ListItemText cursor-pointer ${this.providerColorHide(pathName, ["/FaqChairman", "/ContactUsChairman"])}`}>{t("Help")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={"AuditorHelpFAQLink"} style={providerDashboard.Item} id="faq" onClick={() => this.props.history.push("/FaqChairman")}>
              <Typography variant="body2" className={this.providerTextHighlight(pathName, ["/FaqChairman"])}>
                {t("Frequently asked questions")}
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              className={"AuditorHelpContactUs"}
              style={providerDashboard.Item}
              id="contactChairman"
              onClick={() => this.props.history.push("/ContactUsChairman")}
            >
              <Typography variant="body2" className={this.providerTextHighlight(pathName, ["/ContactUsChairman"])}>
                {t("Contact Us")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="SideBarBottom">
          <Box>
            <Typography style={{ fontSize: 10, fontWeight: 600 }}>
              <Box component="span" style={providerDashboard.PremimumPlan}>
                {t("Premimum")}
              </Box>
              {t("Plan")}
            </Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}>{t("Expires in 125 days")}</Typography>
          </Box>
          <Box>
            <img src={TenantLogo.default} alt="TenantLogo" width={110} />
          </Box>
        </Box>
      </Box>
    );
  }
}
const providerDashboard = {
  PremimumPlan: {
    background: "#ff8100",
    padding: 8,
    borderRadius: "5px",
    marginRight: 8,
  },
  ListItem: {
    // color: "black",
    marginTop: "0px",
    alignItems: "center",
  },
  ListItemText: {
    marginLeft: 15,
    fontSize: 14,
  },
  Item: {
    cursor: "pointer",
  },
};
//@ts-ignore
export default withTranslation()(withStyles(providerDashboard)(withRouter(ServiceProviderSideBar)));

// Customizable Area End
