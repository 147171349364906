// Customizable Area Start
import React from "react";
import { Box, Button, Card, Container, Grid, Link, TextareaAutosize, Typography, withStyles } from "@material-ui/core";
import SuggestionsController, { Props } from "./SuggestionsController.web";
import { SuggestionStyleWeb } from "./SuggestionStyle.web";
import { avatarIcon, BuildingDetail, calenderIcon, InfoGrey } from "./assets";
import { withRouter } from 'react-router';
import { withTranslation } from "react-i18next";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import moment from "moment";

class SuggestionDetails extends SuggestionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes }: any = this.props;
    const item: any = JSON.parse(localStorage.getItem('selectSuggestion')!);

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.suggestionDetails}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Community Management")} / <Link href="/Suggestions">{t("Suggestion")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Suggestion Details")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading bold-text">
                    <h3 className="bold-text">{t("Suggestion Details")}</h3>
                  </Box>
                </Box>
                <Box className="content-box">
                  <Box className="suggestion-detail">
                    <Card>
                      <Box className="heading">
                        <p>
                          {t("Suggestion is related to")}: <span className="bold-text">{item?.attributes?.suggestion_related?.related_to}</span>
                        </p>
                        <span className={item?.attributes?.response ? "green-span" : "red-span"}>{item?.attributes?.response ? item?.attributes?.response?.data.length : 0} {t("Response")}</span>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <p>{t("Description")}</p>
                        <img src={InfoGrey} alt="" />
                      </Box>
                      <p style={{ fontSize: "14px", marginTop: "8px" }}>{item?.attributes?.description}</p>
                      <Box className="suggestion-info">
                        <Box className="info">
                          <img src={BuildingDetail} />
                          <Box>
                            <p className="heading" style={{ fontSize: "14px" }}>{t("Society")}</p>
                            <p className="bold-text">{item?.attributes?.society_management?.name}</p>
                          </Box>
                        </Box>
                        <Box className="info">
                          <img src={avatarIcon} />
                          <Box>
                            <p className="heading" style={{ fontSize: "14px" }}>{t("Sent By")}</p>
                            <p className="bold-text">{item?.attributes?.sent_by?.name || 'N/A'}</p>
                          </Box>
                        </Box>
                        <Box className="info">
                          <img src={calenderIcon} />
                          <Box>
                            <p className="heading" style={{ fontSize: "14px" }}>{t("Sent On")}</p>
                            <p className="bold-text">{moment(item?.attributes?.created_at).format("DD/MM/YYYY")}</p>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                  <Box className="response-input">
                    <Card>
                      <TextareaAutosize minRows={3} placeholder={t("Your Response")} value={this.state.reposne} onChange={(e) => this.setState({ reposne: e.target.value })} style={{ border: "1px solid #f0f0f0", background: "#f9f9f9", height: "150px" }} />
                      <Button onClick={() => this.addResponse(item)}>{t("Submit")}</Button>
                    </Card>
                  </Box>
                  <Box className="responses-box">
                    {item?.attributes?.response != null &&
                      item?.attributes?.response.data.map((data: any) => <>
                        <Card>
                          <p>
                            {t("Response By")}: <span className="bold-text">{data?.attributes?.account?.full_name || 'N/A'}</span>
                          </p>
                          <pre>{data?.attributes?.description}</pre>
                        </Card>
                      </>)}
                  </Box>
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}
// @ts-ignore
// @ts-nocheck
export default withTranslation()(withStyles(SuggestionStyleWeb)(withRouter(SuggestionDetails)));
// Customizable Area End
