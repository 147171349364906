export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BuildingImage = require("../assets/building.png");
export const FilterIcon = require("../assets/filter.png");
export const EarthIcon = require("../assets/earth.png");
export const BuildingIcon = require("../assets/select-building.png");
export const CubeIcon = require("../assets/cube.png");
export const CalenderIcon = require("../assets/calender.png");
export const RentAmountIcon = require("../assets/rent-amount.png");
export const RegionIcon = require("../assets/region.png");
export const CityIcon = require("../assets/city.png");
export const ComplexIcon = require("../assets/complex.png");
export const FloorIcon = require("../assets/floor.png");
export const SizeIcon = require("../assets/size.png");
export const ConfigIcon = require("../assets/config.png");
export const PriceIcon = require("../assets/price.png");
export const ValuationIcon = require("../assets/valuation.png");
export const RentAmountHistoryIcon = require("../assets/amount.png");
export const UnitRegisterCompleteIcon = require("../assets/unit.png");
export const ReceivedIcon = require("../assets/rAmount.png");
export const TenantIcon = require("../assets/tenant.png");
export const DeleteRentIcon = require("../assets/delete-rent.png");
export const BlueAssetsIcon = require("../assets/blue-assets.png");
export const BlueCalenderIcon = require("../assets/blue-calender.png");
export const BlueCityIcon = require("../assets/blue-city.png");
export const BlueComplexIcon = require("../assets/blue-complex.png");
export const BlueConfigIcon = require("../assets/blue-config.png");
export const BlueCountryIcon = require("../assets/blue-country.png");
export const BlueFloorIcon = require("../assets/blue-floor.png");
export const BluePriceIcon = require("../assets/blue-price.png");
export const BlueRegionIcon = require("../assets/blue-region.png");
export const BlueRentIcon = require("../assets/blue-rent.png");
export const BlueSizeIcon = require("../assets/blue-size.png");
export const BlueStatusIcon = require("../assets/blue-status.png");
export const BlueTenantIcon = require("../assets/blue-tenant.png");
export const BlueUnitIcon = require("../assets/blue-unit.png");
export const BlueValuationIcon = require("../assets/blue-valuation.png");
export const EditIcon = require("../assets/edit.png");
export const UnderProcessIcon = require("../assets/under-process.png");
export const ChatIcon = require("../assets/chat.png");
export const DarkChatIcon = require("../assets/dark-chat.png");
export const FBIcon = require("../assets/fb.png");
export const DarkFBIcon = require("../assets/dark-fb.png");
export const TwitterIcon = require("../assets/twitter.png");
export const DarkTwitterIcon = require("../assets/dark-twitter.png");
export const PhoneIcon = require("../assets/phone.png");
export const EmailIcon = require("../assets/email.png");
export const DeleteUnitIcon = require("../assets/delete.png");
export const UploadIcon = require("../assets/upload.png");

export const CERealEstateIcon = require("../assets/real-estate.png");
export const CEBuildingIcon = require("../assets/ce-building.png");
export const CECalendarIcon = require("../assets/ce-calendar.png");
export const CEUserIcon = require("../assets/ce-user.png");
