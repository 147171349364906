// Customizable Area Start
import React from "react";
import { Container, withStyles, Card, IconButton } from "@material-ui/core";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import OwnerComplexListController, { Props } from "./OwnerComplexListController.web";
import { withTranslation } from "react-i18next";
import "./style.css";
//@ts-ignore
import { BuildingLogo } from "./assets";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { BuildingApartmentStyle } from "./BuildingApartmentStyle.web";
import { withRouter } from "react-router";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class OwnerComplexList extends OwnerComplexListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.complexDetails}>
          <Grid container className="owner-complex-main-container">
            <Grid item xs={12} md={7}>
              <Box className="owner-complex-content-box">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon owner-complex-back-icon">
                    <IconButton className="owner-complex-list-back" onClick={() => this.handleGotoDashboard()}>
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text owner-complex-heading">{t("Building Info & Rules")}</span>
                  </div>
                </Box>
                <Container className="page-container owner-complex-page-container">
                  <Box className="details-box">
                    <Box className="document-boxes owner-complex-complex-box" style={{ marginTop: "8px", marginBottom: "30px" }}>
                      <Grid container spacing={2} className="owner-complex-container">
                        {this.state.complexList.map((complex: any) => {
                          return (
                            <Grid
                              item xs={12} className="owner-complex-grid" key={complex.id}
                              onClick={() => {
                                if (complex.is_building) {
                                  this.props.navigation.navigate("OwnerBuildings", {
                                    cid: complex.id,
                                    id: complex.is_building_list[0].id,
                                  });
                                } else {
                                  this.props.navigation.navigate("OwnerComplex", { id: complex.id });
                                }
                              }}
                            >
                              <Card className="document owner-complex-box">
                                <h6 className="bold-text">{complex.name}</h6>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className="right-block right-image owner-complex-right-building-image" display={{ xs: "none", md: "flex" }}>
                <img src={BuildingLogo.default} className="building-logo" alt="owner-complex-building" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(BuildingApartmentStyle)(withRouter(OwnerComplexList)));
// Customizable Area End
