//Customizable Area Start
import * as React from "react";
// custom components
import { Box, Button, Dialog, DialogActions, Divider, Grid, IconButton,Avatar } from "@material-ui/core";
import { Building1 } from "./assets";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";

import "../assets/css/style.scss";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import FamilyController from "./FamilyController.web";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class FamilyList extends FamilyController {
  async componentDidMount() {
    this.getVehicle();
  }

  render() {
    //@ts-ignore
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid container className="auth-container FamilyList" style={{background: "#F7F9FE",minHeight:"100vh",display:"block",padding:"0px"}} dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} className="auth-cols FamilyList" style={{ justifyContent: "unset", overflowY: "auto", overflowX: "hidden" }}>
            <Grid container style={{background:"white"}} className="FamilyList">
              <Grid xs={12} style={{
                  display: "flex",alignItems: "center",
                  paddingBottom: "13px",paddingTop: "14px",paddingLeft: "12px"}}>
                <ArrowBackIcon onClick={this.redirectToDashboard} className="backtesticon FamilyList" style={{ fontSize: "27px", marginRight: "10px" }}/>
                <p style={{ fontWeight: 600, fontSize: "18px" }} className="bold-text FamilyList">{t("My Family")}</p>
              </Grid>
            </Grid>
            <Divider />
            {this.state.allVehcile.length > 0 ? (
              <>
                <Box className="vehicle-list-owner FamilyList" style={{display:"flex",justifyContent:"center"}}>
                  <Box style={{ minHeight: "80vh",width:"95%" }} className="FamilyList">
                    <Grid container style={{ overflowX: "auto", marginTop: "10px"}} className="FamilyList">
                      {this.state.allVehcile.map((item) => (
                        <>
                          <Grid item xs={12} key={item.id} className="card fam FamilyList" style={{ margin: "0.50rem", height: "175px",background:"white" }}>
                            <div className="flex FamilyList">
                              <div className="FamilyList" style={{ display: "flex", alignItems: "center", gap: "0.5rem",marginBottom:"10px" }}>
                                <Avatar className="FamilyList" src={item?.attributes?.member_pic?.url}/>
                                <p className="bold-text FamilyList" style={{ fontSize: "16px" }} >
                                  {item.attributes.name}
                                </p>
                              </div>
                              <div className={languageCondition(language,"right-icon family-menu_ar","right-icon family-menu ")}>
                                <Menu
                                  className="menu-button-family"
                                  menuButton={<IconButton><MoreVertIcon /></IconButton>}
                                >
                                  <MenuItem onClick={() => this.handleClose(item)} className="FamilyListMenu1">{t("Edit")}</MenuItem>
                                  <MenuItem
                                      className="FamilyListMenu2"
                                      onClick={() => { this.setState({ showDialogDelete: true }); localStorage.setItem('selectFamily', JSON.stringify(item)) }}
                                  >
                                    {t("Delete")}
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                            <div>
                              <p className="fam-label FamilyList" style={{ marginTop: "0" }} >
                                {t("Relation")}:
                              </p>
                              <p className="fam-value FamilyList">{item.attributes.relation.name}</p>
                            </div>
                            <div>
                              <p className="fam-label FamilyList">{t("ID")}:</p>
                              <p className="fam-value FamilyList">{item.attributes.id_number}</p>
                            </div>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Box>
                  <Box className="register-another-vehicle-button FamilyList" style={{background: "#F7F9FE"}}>
                    <Grid container className="FamilyList">
                      <Grid item xs={12} className="FamilyList" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          fullWidth={true} className={"btn addAnotherFamilyButton"}
                          variant="contained" type="submit"
                          onClick={() => {
                            //@ts-ignore
                            this.props.history.push("/NewFamily");
                          }}
                          style={{
                            backgroundColor: "#2B6FEC", borderRadius: 25,
                            height: 50, marginBottom: 10,
                            boxShadow: "none", color: "#F7F7FC",
                            fontWeight: 600, fontSize: 16,
                            marginTop: 10, width: "90%",
                          }}
                        >
                          {t("Add another family member")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            ) : (
              <div style={{display:'flex',justifyContent:"center"}} className="FamilyList">
                <NoVehicle props={this.props} />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols FamilyList">
            <Box className="right-block FamilyList" display={{ xs: "none", md: "flex" }}>
              <img src={Building1.default} className="building-logo FamilyList" alt="" />
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.showDialogDelete}
          onClose={() => this.setState({ showDialogDelete: false, showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="diloag-wrapper FamilyList deleteFamilyModal"
          PaperProps={{
            style: {borderRadius: "15px", padding: "2rem"},
          }}
        >
          <Grid container className="FamilyList">
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }} className="FamilyList">
              {/* <img src={deleteI} /> */}
            </Grid>
          </Grid>
          <Grid container className="FamilyList">
            <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }} className="FamilyList">
              <p className="bold-text FamilyList" style={{ fontWeight: 600, fontSize: "1.25rem", textAlign: "center" }}>
                {t("Delete registered")}
                <br />
                {t("Family Member")}
              </p>
            </Grid>
          </Grid>
          <Grid container className="FamilyList">
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
              <p style={{ fontWeight: 400, fontSize: "0.8rem", textAlign: "center" }} className="FamilyList">
                {t("Are you should want to delete this registered family member from this App?")}
              </p>
            </Grid>
          </Grid>
          <Box className="dialog-footer desktop-ui FamilyList">
            <DialogActions className="customButton FamilyList">
              <Button variant="contained" className="FamilyList yesDeleteButton" onClick={() => this.deleteRequest()} style={{marginBottom:"10px"}}>
                {t("yes, delete")}
              </Button>
              <Button className="FamilyList noDeleteButton" onClick={() => this.setState({ showDialogDelete: false, showDialog: false })} variant="text" style={{width:"200px",marginLeft:"0px"}}>
                {t("No, don’t delete")}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}
//@ts-ignore
//@ts-nocheck
export default withTranslation()(withRouter(FamilyList));

// @ts-ignore
function NoVehicle({ props }) {
  return (
    <>
      <div style={{ height: "81vh", display: "flex", flexDirection: "column", justifyContent: "center",width:"90%" }} className="FamilyList">
        <Grid container className="FamilyList">
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }} className="FamilyList">
            {/* <img src={NoVehicles} /> */}
          </Grid>
        </Grid>
        <Grid container className="FamilyList">
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }} className="FamilyList">
            <p style={{ fontWeight: 600, fontSize: "1.25rem", textAlign: "center" }} className="FamilyList">
              {props.t("No Family Member")}
              <br />
              {props.t("Registered")}
            </p>
          </Grid>
        </Grid>
        <Grid container className="FamilyList">
          <Grid xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }} className="FamilyList">
            <p style={{ fontWeight: 400, fontSize: "14px", textAlign: "center" }} className="FamilyList">
              {props.t("Looks like you have’t registered any family Members! You can register a new family member by tapping the below button.")}
            </p>
          </Grid>
        </Grid>
      </div>
      <Grid container className="register-new-vehicle-btn FamilyList" >
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} className="FamilyList">
          <Button
            fullWidth={true} className={"btn addFamilyButton"}
            variant="contained" type="submit"
            onClick={() => {
              //@ts-ignore
              //@ts-nocheck
              props.history.push("/NewFamily");
            }}
            style={{
              backgroundColor: "#2B6FEC", borderRadius: 25,
              height: 50, boxShadow: "none",
              position: "fixed", bottom: "0",
              color: "#F7F7FC", fontWeight: 600,
              fontSize: 16, maxWidth: 350, margin: "10px",
            }}
          >
            {props.t("Add member to my family")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
//Customizable Area End
