// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  FormControl,
  withStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  TextareaAutosize,
  Card,
  Link as NavLink,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";
import MeetingMinutesController, { Props } from "./MeetingMinutesController.web";
import { MeetingsStyleWeb } from "./MeetingsStyle.web";
import { DownloadIcon, PdfIcon, CheckIcon } from "./assets";
import { Link } from "react-router-dom";
import { ROLE } from "../../../framework/src/Enum";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class MeetingMinuteDetails extends MeetingMinutesController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const meeting_id = this.props.navigation.getParam("id");
    this.setState({ meetingMinuteId: meeting_id }, () => {
      this.MinuteMeetingDetail();
    });
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />
        <GeneralHeaderComponentWeb>
              <Container className={classes.scheduledMeetingDetails}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Meetings")} / <Link to="/MeetingMinutes">{t("Meeting Minutes")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {this.state.meetingMinuteDetails && this.state.meetingMinuteDetails.attributes.title}
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="sub-heading bold-text">
                      {t("Meeting Minutes Details")}
                    </Typography>
                  </Box>
                </Box>
                {this.state.meetingMinuteDetails && this.state.meetingMinuteDetails.attributes.meeting_mins_pdf ? (
                  <>
                    <Box className="meeting-detail-box">
                      <Box className="meeting-top">
                        <h4 className="bold-text">
                          {t("Meeting Minutes")}{" "}
                          {moment(
                            this.state.meetingMinuteDetails.attributes.meeting_date_time,
                            "DD-MM-YYYY HH:mm",
                            true
                          ).format("MMMM DD, YYYY HH:mm")}
                        </h4>
                        <span className={this.state.meetingMinuteStatus}>
                          {this.state.meetingMinuteStatus == "pending"
                            ? t("Pending Approval")
                            : t(this.state.meetingMinuteStatus)}
                        </span>
                      </Box>
                      <Divider />
                      <Box className="meeting-minute-details">
                        <Box className="resolution">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.meetingMinuteDetails.attributes.meeting_mins_notes,
                            }}
                          />
                        </Box>
                        <Box className="pdf-detail">
                          <div className="heading">
                            <img src={PdfIcon} alt="pdf" />
                            <h6 className="bold-text">
                              {t("Meeting Minutes")}{" "}
                              {moment(
                                this.state.meetingMinuteDetails.attributes.meeting_date_time,
                                "DD-MM-YYYY HH:mm",
                                true
                              ).format("MMMM DD, YYYY HH:mm")}
                            </h6>
                          </div>
                          <NavLink
                            href={this.state.meetingMinuteDetails.attributes.meeting_mins_pdf.url}
                            target="_blank"
                          >
                            <img src={DownloadIcon} alt="download" />
                          </NavLink>
                        </Box>
                      </Box>
                    </Box>
                    {this.state.meetingMinuteDetails.attributes.meeting_mins_status === "rejected" && (
                      <Box className="rejection-box">
                        <Card>
                          <h4 className="bold-text">{t("Rejection Reason")}</h4>
                          <p>{this.state.meetingMinuteDetails.attributes.meeting_reject_note.note}</p>
                        </Card>
                      </Box>
                    )}
                    {localStorage.getItem("userType") === ROLE.MANAGER && (
                      <Box className="button-box">
                        <Link to={`/MeetingMinute/${this.state.meetingMinuteId}/Note`}>
                          <Button className="edit">{t("Edit")}</Button>
                        </Link>
                      </Box>
                    )}
                    {this.state.meetingMinuteStatus === "pending" &&
                      localStorage.getItem("userType") === ROLE.CHAIRMAN && (
                        <Box className="button-box">
                          <Button className="cancel" onClick={() => this.handleRejectMeetingModal()}>
                            {t("Reject")}
                          </Button>
                          <Button className="edit" onClick={() => this.handleApproveMeetingModal()}>
                            {t("Approve")}
                          </Button>
                        </Box>
                      )}
                  </>
                ) : (
                  <Box className="no-available">
                    <Card>{t("No Meeting Minute Available!!")}</Card>
                    {localStorage.getItem("userType") === ROLE.MANAGER && (
                      <Box className="button-box">
                        <Link to={`/MeetingMinute/${this.state.meetingMinuteId}/Note`}>
                          <Button className="edit">{t("Add")}</Button>
                        </Link>
                      </Box>
                    )}
                  </Box>
                )}
              </Container>
        </GeneralHeaderComponentWeb>

        <Dialog dir={languageCondition(language, "rtl", "ltr")} fullWidth className="add-meeting meeting-minute-reject-modal" open={this.state.isRejectMeetingModalOpen}>
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Reject Meeting Minutes")}{" "}
              {moment(
                this.state.meetingMinuteDetails && this.state.meetingMinuteDetails.attributes.meeting_date_time,
                "DD-MM-YYYY HH:mm",
                true
              ).format("MMMM DD, YYYY HH:mm")}
            </Typography>
            <IconButton onClick={() => this.handleRejectMeetingModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <TextareaAutosize
                className="reject-note"
                placeholder={t("Add Notes")}
                value={this.state.rejectNote}
                onChange={(e: any) => {
                  this.setState({
                    rejectNote: e.target.value,
                  });
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
            <Button className="cancel-button" onClick={() => this.handleRejectMeetingModal()}>
              {t("Cancel")}
            </Button>
            <Button
              className="add-button"
              disabled={this.state.rejectNote.length === 0 || this.isInputOnlyWhiteSpace(this.state.rejectNote)}
              style={{ margin:"0" }}
              onClick={() => {
                this.setState({ loading: true }, () => {
                  this.handleRejectMeetingModal();
                  this.updateMinuteMeeting("rejected");
                });
              }}
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          onClose={() => this.handleApproveMeetingModal()}
          open={this.state.isApproveMeetingModalOpen}
          dir={languageCondition(language, "rtl", "ltr")}
          className="cancel-meeting-dialog meeting-minute-approve-modal"
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={CheckIcon} alt="check" />
              <Typography variant="h6" className="bold-text">
                {t("Approve meeting minutes")}{" "}
                {moment(
                  this.state.meetingMinuteDetails && this.state.meetingMinuteDetails.attributes.meeting_date_time,
                  "DD-MM-YYYY HH:mm",
                  true
                ).format("MMMM DD, YYYY HH:mm")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0px" }}>
                {t("Are you sure you want to approve meeting minutes")}{" "}
                {moment(
                  this.state.meetingMinuteDetails && this.state.meetingMinuteDetails.attributes.meeting_date_time,
                  "DD-MM-YYYY HH:mm",
                  true
                ).format("MMMM DD, YYYY HH:mm")}
                ?
              </Typography>
              <br />
              <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
                <Button
                  className="cancel-button"
                  style={{ width: "200px" }}
                  onClick={() => this.handleApproveMeetingModal()}
                >
                  {t("Close")}
                </Button>
                <Button
                  style={{ width: "200px", margin:"0" }}
                  className="add-button"
                  onClick={() => {
                    this.setState({ loading: true }, () => {
                      this.handleApproveMeetingModal();
                      this.updateMinuteMeeting("approved");
                    });
                  }}
                >
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(MeetingMinuteDetails)));
// Customizable Area End
