// Customizable Area Start
//@ts-ignore
//@ts-nocheck

import React from "react";
import "./Polling.web.css"
import {
    Button,
    Container,
    Divider,
    InputBase,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
// @ts-ignore
import DOMPurify from 'dompurify'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import SurveyReportController, {Props,} from "./SurveyReportController";
import {withRouter} from "react-router-dom";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import SurveryResponse from "./SurveyResponseModal.web"
import {withTranslation} from 'react-i18next';
import '../../../web/src/i18n.js';
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import InfoIcon from "@material-ui/icons/Info";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class PollReport extends SurveyReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {t,i18n} = this.props
    const {language} = i18n
    const surveyId =  window.location.search ? window.location.search.split("=")[1] : null;
    return ( 
      <>
    <GeneralHeader>
                <Container>
                    <Grid item sm={12} md={12} xs={12}>
                        <Box className="navigation">
                            <Box>
                                <Typography variant="body1" >
                                    <Box component="span" className="surveyReportCreate surveyReportBreadcrum3" onClick={()=> this.props.history.push("/Polling")} style={{cursor:"pointer"}}>{t("Poll and survey")}
                                    </Box>
                                    {" "}/{" "}
                                    <Box component="span" className="surveyReportCreate surveyReportBreadcrum1" onClick={()=> this.props.history.push("/SurveyAllData")} style={{cursor:"pointer"}}>
                                        {t("Created Surveys")}
                                    </Box>{" "}/ {" "} <Box component="span"  className="surveyReportCreate surveyReportBreadcrum2" onClick={()=> this.props.history.push(`/SurveyDetails?id=${surveyId}`)} style={{cursor:"pointer"}}> {t("Survey Details")} </Box> /{" "}
                                    <Box component="span" style={{color: "blue"}}>{t("Survey Report")}</Box>
                                </Typography>
                                <Typography variant="h5" className="subHeading bold-text surveyReport">{t("Survey Report")}</Typography>
                            </Box>  
                            <Box className="downloadReport">
                                <button onClick={this.handleDownload} className="reportbtn surveyReport"  style={{backgroundColor:"2B6FED",border:"0px"}}>
                                    {t("DOWNLOAD REPORT")}
                                </button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container spacing={4} style={{marginTop: 0}}>
                        <Grid item sm={12} md={12} xs={12}>
                            <Box className="createPSCards PollResponseMain SurveyReport">
                                <Box className="PreviewName SurveyReport">
                                    <Box className="PollName SurveyReport">
                                        <Typography className="subHeading SurveyReport">{t("Survey Name")}: </Typography>
                                        <Typography className="PollNameText textwrap bold-text SurveyReport">
                                            {this.state.SurveyPreviewAnswer.title}
                                        </Typography>
                                    </Box>
                                    <Box className="surveyReportMain">
                                        {
                                            this.state.SurveyPreviewAnswer.status == "upcoming" &&
                                            <Typography variant="body1" className={"statusOngoingBlue SurveyReport"}>{this.state.SurveyPreviewAnswer.status}</Typography>
                                        }
                                        {
                                            this.state.SurveyPreviewAnswer.status == "ongoing" &&
                                            <Typography variant="body1" className={"statusOngoingRed SurveyReportRed"}>{this.state.SurveyPreviewAnswer.status}</Typography>
                                        }
                                        {
                                            this.state.SurveyPreviewAnswer.status == "completed" &&
                                            <Typography variant="body1" className={"statusOngoingGreen SurveyReportGreen"}>{this.state.SurveyPreviewAnswer.status}</Typography>
                                        }
                                        {
                                            this.state.SurveyPreviewAnswer.status == "Terminated" &&
                                            <Typography variant="body2" className={"statusTerminatedRed SurveyReportRed"} >{t('Terminated')}</Typography>
                                        }
                                    </Box>
                                </Box>

                                <Box className="DateSectionPreviewpoll SurveyReport">
                                    <Box className="datebox SurveyReport" style={languageCondition(language,{marginLeft:"50px",marginRight:"0px"},{marginLeft:"0px",marginRight:"50px"})}>
                                        <CalendarTodayOutlinedIcon ClassName="SurveyReport" style={{color:"grey", fontSize:22}}/>
                                        <Box>
                                            <Typography className="PollNamedate SurveyReport">{t("Start Date")}</Typography>
                                            <Typography className="PollNameText bold-text SurveyReportStartDate" style={{fontSize:"13px"}}>
                                                {/* June 7, 2022 */}
                                                {this.state.SurveyPreviewAnswer?.start_date}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className="datebox surveyReportMain">
                                        <CalendarTodayOutlinedIcon style={{color:"grey", fontSize:22}}/>
                                        <Box display='flex'>
                                            <Box className="surveyReportMain">
                                                <Typography className="PollNamedate SurveyReport">{t("End Date")}</Typography>
                                                <Typography className="PollNameText bold-text SurveyReportEndDate" style={{fontSize:"13px"}}>
                                                    {/* June 7, 2022 */}
                                                    {this.state.SurveyPreviewAnswer?.end_date}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{marginTop:15}} className="SurveyReport">
                                    <Box display="flex" alignItems="center" justifyContent="space-between" className="SurveyReport">
                                        <Box className="infoIcon SurveyReport">
                                            <Typography variant="subtitle1" className="SurveyReport">{t("Description")}</Typography>
                                            <InfoIcon style={{color:"grey", fontSize:18}}/>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop:5, overflowWrap:"break-word"}} className="SurveyReport">
                                        <Typography variant="body2"
                                                    className="SurveyReport"
                                                    dangerouslySetInnerHTML={
                                                        { __html: DOMPurify.sanitize(this.state.SurveyPreviewAnswer?.description) }
                                                    }
                                        />
                                    </Box>
                                </Box>
                                <Box className="PollName SurveyReport" style={{marginTop:15}}>
                                    <Typography className="subHeading SurveyReport">{t("Target Audience")}: </Typography>
                                    <Typography className="PollNameText bold-text SurveyReport" style={{color:"#2B6FED"}}>
                                        {this.state.SurveyPreviewAnswer.survey_audience_name.join(",")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid style={{marginTop: "2rem", marginBottom:"5rem"}} className="PollResponseMain surveyReportMain">
                        <Grid item sm={12} md={12} xs={12}>
                            <Box class="tableTopSearch surveyReportMain" style={{marginTop:"0px"}}>
                                <h4 className="bold-text SurveyReport">{this.state.surveyName}</h4>
                                <div className="searchBox" style={{width:"250px"}}>
                                    <div className="searchIcon">
                                    <SearchIcon />
                                    </div>
                                    <InputBase
                                    placeholder="Search"
                                    style={languageCondition(language,{marginRight:"2rem"},{marginLeft:"2.5rem"})}
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={this.state.reportSearch}
                                    onChange={this.handleReportSearch}
                                    />
                                </div>
                            </Box>

                            <Divider />

                            <TableContainer >
                                <Table  aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{fontWeight:"600"}}>#</TableCell>
                                            <TableCell style={{fontWeight:"600"}} align="start">{t("Name")}</TableCell>
                                            <TableCell style={{fontWeight:"600"}} align="start">{t("Unit Number")}</TableCell>
                                            <TableCell style={{fontWeight:"600"}} align="start">{t("Response")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        this.state.surveyReport?.length > 0 &&
                                        <TableBody>
                                            {this.state.surveyReport?.map((row, index) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">{index + 1}</TableCell>
                                                    <TableCell align="start">{row.name}</TableCell>
                                                    <TableCell align="start">
                                                        {row.unit_number.join(",")}
                                                    </TableCell>
                                                    <TableCell align="start"><Button variant="contained" color="inherit" onClick={()=>this.handleOpenAudienceModal(row.response_details)}>{t("View")}</Button></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>

                            <Divider />
                            <Box className="TableHeader">
                                <h5>{t("Showing")} {this.state?.reportPagination?.total_count > 10 ? (this.state.reportPagination?.total_count * this.state.reportPagination?.page) || 0 : this.state.reportPagination?.total_count || 0} {t("of")} {this.state.reportPagination?.total_count || 0} {t("results")}</h5>
                                <Pagination count={Math.round(this.state.reportPagination?.total_count/10 || 0)} onChange={this.handleReportPagination} variant="outlined" shape="rounded" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Modal
                        aria-labelledby="filterModal"
                        aria-describedby="filtermodal"
                        className="modalStyle"
                        open={this.state.audienceModal}
                        onClose={this.handleCloseAudienceModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        {/*@ts-ignore*/}
                        <Fade in={this.state.audienceModal}>
                            <div>
                                <SurveryResponse handleClose={this.handleCloseAudienceModal} response={this.state.responseModalData} />
                            </div>
                        </Fade>
                    </Modal>
                </Container>
    </GeneralHeader>
     </>
      );
  }
}

export default withTranslation()(withRouter(PollReport));

const dashBoard = {
    SideBar: {
        background: "#f9f6f6",
        position:"relative",
        paddingBottom: 150,
    },
}
// Customizable Area End
