// Customizable Area Start
import React from "react";
import { Input } from "@material-ui/core";

const FilterInput = ({ t, language, label, value, ...props }: any) => {
  return (
    <Input
      className="select-year input__filter_input"
      fullWidth
      value={value}
      placeholder={t(label)}
      {...props}
    />
  );
};

export default FilterInput;
// Customizable Area End
