// Customizable Area Start
import React from "react";
import { Card, Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./style.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ComplexListController, { Props } from "./ComplexListController.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class ComplexList extends ComplexListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols invoice-complex-content-box">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} className="invoice-top-bar-grid">
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon
                  onClick={() => {
                    if (sessionStorage.getItem("isBudget") === "true") {
                      sessionStorage.removeItem("isBudget")
                      this.props.history.push("/BudgetAndExpenseDetails");
                    } else {
                      sessionStorage.removeItem("isBudget")
                      this.props.history.push("/FeesAndPayment");
                    }
                  }}
                  className={languageCondition(language, "KeyboardForwardIconAR", "")}
                />
                <p style={{ fontSize: "1.2rem" }} className="bold-text invoice-complex-heading">
                  {t("Complex / Building List")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list invoice-complex-main-box">
            <Grid container spacing={2} style={{ width: "90%" }} className="invoice-complex-container">
              {this.state.invoiceComplexList.map((complex: any) => {
                return (
                  <Grid item xs={12} className="invoice-complex-grid" key={complex.id}>
                    <Card
                      className="invoice-complex-box invoice-complex-box-card"
                      onClick={() => this.handleChangePage(complex)}
                    >
                      <h6 className="bold-text">{complex.name}</h6>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(withRouter(ComplexList));
// Customizable Area End
