Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Name";
exports.emailPlaceHolder = "Email";
exports.numberPlaceHolder = "Phone number";
exports.commentsPlaceHolder = "Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.ApiContentType = "application/json";

exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.apiMethodTypePut = "PUT";

exports.FaqCategoryAPIEndPoint = "bx_block_interactive_faqs/interactive_faq_categories";
exports.CreateFaqCategoryAPIEndPoint = "bx_block_interactive_faqs/interactive_faq_categories";
exports.DeleteFaqCategoryAPIEndPoint = "bx_block_interactive_faqs/interactive_faq_categories";
exports.EditFaqAPIEndPoint = "bx_block_interactive_faqs/interactive_faqs";
exports.DeleteFaqAPIEndPoint = "bx_block_interactive_faqs/interactive_faqs";
exports.CreateFaqAPIEndPoint = "bx_block_interactive_faqs/interactive_faqs";
exports.CategoryByIdAPIEndPoint = "bx_block_interactive_faqs/interactive_faq_categories";
exports.showAllQuestionsEndPoint = "bx_block_interactive_faqs/faq_by_category";
exports.showAllCountryEndPoint = "bx_block_address/country_list";
exports.showAllComplexEndPoint = "/bx_block_society_management/company_employee/complexes/complex_list";
exports.showAllComplexEndPointFilter = "/bx_block_interactive_faqs/complex_list";
exports.showAllComplexSearchEndPointFilter = "bx_block_address/housing_complex_list";
exports.showAllUserEndPoint = "bx_block_interactive_faqs/list_faq_cat_dashbord";
exports.showAllCategoryEndPoint = "bx_block_interactive_faqs/list_with_filters";
exports.imageUrl = "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc.builder.cafe/";
// Customizable Area End