// Customizable Area Start
import { MenuItem } from "@material-ui/core";
import React from "react";

export const handleFilterValue = (state: any) => {
  if (state.selectedFilter === "year") {
    return state.selectedYear;
  } else if (state.selectedFilter === "quarter") {
    return state.selectedQuarter;
  } else {
    return state.selectedMonth;
  }
};

export const handleSelectFilterList = (state: any) => {
  if (state.selectedFilter === "year") {
    return state.yearList.map((year: number) => {
      return (
        <MenuItem value={year} key={year}>
          {year}
        </MenuItem>
      );
    });
  } else if (state.selectedFilter === "quarter") {
    return state.quarterList.map((quarter: any) => {
      return (
        <MenuItem value={quarter.value} key={quarter.value}>
          {quarter.key}
        </MenuItem>
      );
    });
  } else {
    return state.monthList.map((month: number) => {
      return (
        <MenuItem value={month} key={month}>
          {month}
        </MenuItem>
      );
    });
  }
};
// Customizable Area End
