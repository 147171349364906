// Customizable Area Start

import React from "react";

//components
import {Button, Card, CardContent, CardMedia, Container, IconButton, Paper, Typography} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//resources
import {withRouter} from 'react-router-dom';
import VisitorsListController, {Props} from "./VisitorsListController";
import 'web/src/i18n';
import {call_org, email_org, facebook, instagram, snap, twitter_org} from "../../user-profile-basic/src/assets";
import {NoProfile_Img} from "../../search/src/assets"
import {useTranslation, withTranslation} from 'react-i18next';
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";


class UnitGeneralDetails extends VisitorsListController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        // @ts-ignore
        const id = this.props.history.location?.id;
        const ownerId = this.props.history.location?.ownerId;
        if (id) {
            this.getUnitGeneralDetails(id, ownerId)
        } else {
            window.history.back()
        }

    }

    render() {
        const { t, i18n }: any = this.props;
        const {language} = i18n
        let profileDetails = this.state?.getUnitGeneralDetails?.resident?.data?.attributes;
        return (
            <>
                <GeneralHeader>
                            <Container className="incident-Listing-wrapper desktop-ui">
                                <Box style={dashBoard.navigation}>
                                    <Box>
                                        <Typography variant="body1">
                                            <Box component="span" style={{cursor:"pointer"}} onClick={()=> this.props.history.push("Unit")}>{t("Unit")}</Box> / <Box component="span"
                                                               style={{color: "blue"}}> {t("Resident Profile")}</Box>
                                        </Typography>
                                        <Typography variant="h5" className="bold-text"
                                                    style={dashBoard.subHeading}>{t("Resident Profile")}</Typography>
                                    </Box>
                                </Box>

                                {/* GA MEMBERS -- */}
                                <Box>
                                    <Grid container style={dashBoard.gaMemberMain}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" className="bold-text"
                                                        style={dashBoard.Headings}>{t("General Details")}</Typography>
                                        </Grid>
                                        <Grid style={{display: 'flex', alignItems: 'center'}}>
                                            <Typography variant="subtitle1"
                                                        style={{marginRight: '5px'}}>{t("Owner Name")}:</Typography>
                                            <h5>{this.state?.getUnitGeneralDetails?.owner}</h5>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={{marginTop: "10px"}}>
                                    <Paper>
                                        <Grid container spacing={3}>
                                            <CardDeatils profileDetails={profileDetails} t={t} language={language}/>
                                            <Grid item xs={12} sm={8} style={{padding: "35px 25px 25px 35px"}}>
                                                <Box>
                                                    {
                                                        profileDetails?.bio?.publilc_access ?
                                                            <>
                                                                <Typography variant="subtitle1"
                                                                            style={dashBoard.subtitleClr}>{t("About")}</Typography>
                                                                <Typography variant="subtitle1">{
                                                                    profileDetails?.bio?.bio || "N/A"
                                                                } </Typography>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                    <Grid container spacing={3} style={{marginTop: "5px"}}>
                                                        <Grid item xs={2} sm={3}>
                                                            <Typography variant="subtitle1"
                                                                        style={dashBoard.subtitleClr}>{t("Gender")}</Typography>
                                                            <Typography
                                                                variant="subtitle1">{profileDetails?.gender?.gender || 'N/A'}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sm={3}>
                                                            <Typography variant="subtitle1"
                                                                        style={dashBoard.subtitleClr}>{t("DOB")}</Typography>
                                                            <Typography
                                                                variant="subtitle1">{profileDetails?.date_of_birth?.date_of_birth || 'N/A'}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sm={6}>
                                                            <HobbiesDeatils profileDetails={profileDetails}/>
                                                        </Grid>
                                                        <WebsiteDeatils profileDetails={profileDetails} t={t}/>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                                <br></br><br></br>
                                <FamilyDeatils profileDetails={profileDetails} t={t} language={language}/>
                            </Container>
                </GeneralHeader>
                {/* <Loader loading={this.state.loading} /> */}
            </>
        )
    }
}

//@ts-ignore
export default withTranslation()(withRouter(UnitGeneralDetails));

const CardDeatils = (props: any) => {
    const profileDetails = props?.profileDetails;
    return (
        <>
            <Grid item xs={12} sm={4} style={{borderRight: "1px solid #979797"}}>
                <Card style={dashBoard.cardStyle}>
                    <CardMedia
                        component="img"
                        height="140"
                        image={profileDetails?.profile_pic?.url || NoProfile_Img}
                        alt="green iguana"
                        style={languageCondition(props.language,dashBoard.profileImage_ar,dashBoard.profileImage)}
                    />
                    <CardContent style={{padding: "0px 16px 16px 16px"}}>
                        <Typography variant="h6"
                            //@ts-ignore
                                    style={languageCondition(props.language,dashBoard.unitno_ar,dashBoard.unitno)}> {profileDetails?.full_name?.name || 'N/A'}</Typography>
                        <Typography variant="h6"
                                    style={{marginTop: "5px"}}> {profileDetails?.apartment_number?.apartment_number || 'N/A'} </Typography>
                        <Grid container spacing={4} style={{marginTop: "5px"}}>
                            <Grid item xs={3} sm={3}>
                                <img src={call_org} style={{width: "40px"}}
                                     onClick={() => window.location.href = `tel:${profileDetails?.full_phone_number?.full_phone_number}`}/>
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <img src={email_org} style={{width: "40px"}}
                                     onClick={() => window.location.href = `mailto:${profileDetails?.email?.email}`}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

const HobbiesDeatils = (props: any) => {
    const profileDetails = props?.profileDetails;
    const {t} = useTranslation()
    return (
        <>
            {
                profileDetails?.hobbies?.publilc_access ?
                    <Box className="bio-row">
                        <Typography variant="subtitle1" style={dashBoard.subtitleClr}>{t("Hobbies")}</Typography>
                        <Grid container spacing={1}>
                            {
                                profileDetails?.hobbies?.hobbies === null ?
                                    'N/A'
                                    :
                                    profileDetails?.hobbies?.hobbies?.map((val: any, index: any) => (
                                        <Grid item>
                                            <Box className="customButton">
                                                <Button variant="contained" className="contain warning"
                                                        key={index}>{val}</Button>
                                            </Box>
                                        </Grid>
                                    ))
                            }
                        </Grid>
                    </Box>
                    :
                    null
            }
        </>
    )
}

const WebsiteDeatils = (props: any) => {
    const profileDetails = props?.profileDetails;
    return (
        <>
            <Grid item xs={12}>
                <Box style={{margin:"5px 0px"}}>
                    <Typography variant="subtitle1" color="textSecondary" style={dashBoard.subtitleClr}>{props.t("Social Media")}</Typography>
                    <Box style={{display:'flex'}}>
                        {
                            profileDetails?.website[0]?.twitter_link &&
                            <IconButton style={{backgroundColor:"rgba(252,52,52,.1)",marginRight:"8px",padding:"2px"}} onClick={()=> window.open(profileDetails?.website[0]?.twitter_link,"_blank")}>
                                <img src={twitter_org} />
                            </IconButton>
                        }
                        {
                            profileDetails?.website[1]?.instagram_link &&
                            <IconButton style={{backgroundColor:"rgba(252,52,52,.1)",marginRight:"8px",padding:"2px"}} onClick={()=> window.open(profileDetails?.website[1]?.instagram_link,"_blank")}>
                                <img src={instagram} />
                            </IconButton>
                        }
                        {
                            profileDetails?.website[2]?.fb_link &&
                            <IconButton style={{backgroundColor:"rgba(252,52,52,.1)",padding:"2px",marginRight:"8px"}} onClick={()=> window.open(profileDetails?.website[2]?.fb_link,"_blank")}>
                                <img src={facebook} />
                            </IconButton>
                        }
                        {
                            profileDetails?.website[3]?.snapchat_link &&
                            <IconButton style={{backgroundColor:"rgba(252,52,52,.1)",padding:"2px"}} href={profileDetails?.website[3]?.snapchat_link} target="_blank">
                                <img src={snap} />
                            </IconButton>
                        }
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

const FamilyDeatils = (props: any) => {
    const profileDetails = props?.profileDetails;
    const {t} = useTranslation()
    return (
        <>
            <Grid item xs={6}>
                <Typography variant="h6" className="bold-text" style={dashBoard.Headings}>{t("Family Details")}</Typography>
            </Grid>
            <Box className="famliy-container" style={{marginTop: "10px"}}>
                {
                    profileDetails?.families?.families?.length > 0 ?
                        <Paper>
                            <Grid container>
                                <Box style={{padding: "35px 25px 25px 35px", width: '100%'}}>
                                    <Grid container>
                                        {
                                            profileDetails?.families?.families?.map((val: any, index: any) => (
                                                <Grid xs={12} md={6} key={index}>
                                                    <Box className="famliy-card">
                                                        <Box className="famliy-row" style={{alignItems:"center"}}>
                                                            <Typography component="h4">
                                                                {val?.attributes?.name}
                                                            </Typography>
                                                            <Typography variant="subtitle1"
                                                                        style={dashBoard.subtitleClr}>{'DI:'}{val?.attributes?.id_number}</Typography>
                                                        </Box>
                                                        <Typography variant="subtitle1"
                                                                    style={dashBoard.subtitleClr}>{languageCondition(props.language,val?.attributes?.relation?.name_ar,val?.attributes?.relation?.name)}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Paper>
                        : <Typography component="h4">
                            {t("No Family Details Available!")}
                        </Typography>
                }
            </Box>
        </>
    )
}
const dashBoard = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHeading: {
        fontWeight: 600,
        marginTop: 15,
        fontSize: "28px"
    },
    Headings: {
        fontWeight: 600,
        margin: "0px 0px 10px 0px",
    },
    cardBottom: {
        display: "flex",
        gap: 20,
        marginTop: 10
    },
    facility: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    SideBar: {
        background: "#f9f6f6",
        position: "relative",
        paddingBottom: 150,
    },
    gaMemberMain: {
        display: "flex",
        alignItems: "baseline",
        marginTop: 5,
        justifyContent: "space-between"
    },
    viewMore: {
        marginTop: 15,
        textDecoration: "underline",
        color: "#E5B08D",
        fontWeight: 600,
    },
    gaMemberCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr 3fr",
        gap: 20
    },
    relatedMemberCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr 3fr",
        gap: 20
    },
    gaActiveMemberCard: {
        display: "grid",
        gridTemplateColumns: "3fr 3fr",
        gap: 20
    },
    profileImage: {
        borderRadius: "100%",
        width: 90,
        height: 90,
        margin: "35px auto auto 20px"
    },
    profileImage_ar: {
        borderRadius: "100%",
        width: 90,
        height: 90,
        margin: "35px auto auto 200px"
    },
    relatedprofileImage: {
        borderRadius: "100%",
        width: 70,
        height: 70,
        margin: "35px auto"
    },
    userType: {
        backgroundColor: "aliceblue",
        borderRadius: 30,
        display: "inline-block",
        padding: "0px 8px",
        color: "#2D6EED",
        fontWeight: 600,
        marginTop: 5
    },
    unitno: {
        marginTop: 15,
        fontWeight: 600,
        textAlign: "left"
    },
    unitno_ar: {
        marginTop: 15,
        fontWeight: 600,
        textAlign: "right"
    },
    relatedunitno: {
        marginTop: 15,
        fontWeight: 600,
        textAlign: "center"
    },
    contactIcon: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "left",
        marginTop: 15
    },
    cardStyle: {
        borderRadius: 10,
        maxWidth: 345,
        boxShadow: "none",
        padding: "0px 20px 20px 20px",
    },
    activeMembercardStyle: {
        borderRadius: 10,
        maxWidth: 600,
        boxShadow: "none",
        padding: "0px 20px 0px 20px",
    },
    cursorPointer: {
        cursor: "pointer"
    },
    subtitleClr: {
        color: "#D3D3D3"
    }
};

// Customizable Area End
