import React from "react";
import {
    Box,
    Typography,
    Button,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { CSSProperties } from '@material-ui/styles';

export const ModalPopup = (props: any) => {
    const {
        title,
        description, 
        open, 
        closeFn,  
        primaryAction,
        secondaryAction,
        primaryLabel,
        secondaryLabel,
        icon
    } = props;
     // @ts-ignore
    return  <Modal
      
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="modalStyle"
    // @ts-ignore
    open={open}
    onClose={closeFn}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{timeout: 500}}
>
    {/*@ts-ignore*/}
    <Fade in={open}>
        <Box style={{width:'600px',marginTop:'15px',backgroundColor:"white",height:'380px',borderRadius:"20px", display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Box style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"15px"}}>
                {
                    icon && <Box>
                    <img src={icon} />
                </Box>
                }
                {
                    title && <Typography className="bold-text" variant="h6" style={{color:"black",fontWeight:"bold",marginTop:"15px",marginBottom:"10px",textAlign:"center",fontSize:"20px"}}>
                    {title}
                </Typography>
                }
                {
                    description && <Typography variant="body2" style={{textAlign:"center",fontSize:"14px",width:"90%"}}>
                    {description}
                    </Typography>
                }
                <Box style={
                    secondaryLabel ? 
                    {marginTop:"30px",width:"90%",display:"flex",alignItems:"center",justifyContent:'space-between'}
                    : {marginTop:"30px",width:"90%",display:"flex",alignItems:"center",justifyContent:'center'}
                }
                    >
                    {/*@ts-ignore*/}
                    {
                        secondaryLabel ? <><Button variant="outlined" color="primary"
                        data-testid="secondary_btn"
                        className="circle_border"
                        style={webStyle.closeDeleteBtn} 
                        onClick={secondaryAction}
                        ><span className="bold-text">{secondaryLabel}</span></Button><Button variant="contained" color="primary"
                        className="circle_border"
                        data-testid="primary_btn"
                        style={webStyle.confirmDeleteBtn} 
                        onClick={primaryAction}  
                        ><span className="bold-text">{primaryLabel}</span></Button></> : <Button variant="outlined" color="primary"
                        data-testid="primary_btn"
                        className="circle_border"
                        style={webStyle.primaryNewBtn} 
                        onClick={primaryAction}  
                        ><span className="bold-text">{primaryLabel}</span></Button>
                    }
                </Box>
            </Box>
        </Box>
    </Fade>
</Modal>
}

const webStyle:any = {
    closeDeleteBtn:{
        borderColor: 'rgb(43, 111, 237) none repeat scroll 0% 0%',
        color: 'rgb(43, 111, 237)',
        width:'47.5%',
        height: '56px',
    borderRdaius: '12px !important',
    fontWeight: '700' as CSSProperties['fontWeight']
    }, 
    primaryNewBtn:{
        borderColor: 'rgb(43, 111, 237) none repeat scroll 0% 0%',
        color: 'rgb(43, 111, 237)',
        width:'200px',
        height: '56px',
    borderRdaius: '12px !important',
    fontWeight: '700' as CSSProperties['fontWeight']
    },
    confirmDeleteBtn:{
        background: 'rgb(43, 111, 237)',
        borderColor:'rgb(43, 111, 237)',
        color:'#fff',
        boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
        width:'47.5%',
        height: '56px',
    borderRdaius: '12px !important',
    fontWeight: '700' as CSSProperties['fontWeight']
    },
  };