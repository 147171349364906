// Customizable Area Start
import { Button } from "@material-ui/core";
import React from "react";

const CommonButton = ({ t, label, color, isVariant, ...props }: any) => {
  return (
    <Button
      className={`common_btn ${color === "orange" ? "orange_btn" : "blue_btn"} ${isVariant ? "variant" : ""}`}
      {...props}
    >
      {t(label)}
    </Button>
  );
};

export default CommonButton;
// Customizable Area End
