import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  userId: any;
  userName: any;

  userActionProgressList: any[];

  meComplexList: any[];
  meBuildingList: any[];

  filterValue: {
    complex: string;
    status: string;
    building: string;
  };

  complex: string;
  status: string;
  building: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEActionsUserController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetActionProgressListCallId: any;
  GetActionUserComplexCallId: any;
  GetActionUserBuildingCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      userId: "",
      userName: "",

      userActionProgressList: [],

      meComplexList: [],
      meBuildingList: [],

      filterValue: {
        complex: "",
        status: "",
        building: "",
      },

      complex: "",
      status: "",
      building: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetActionProgressListCallId:
          this.handleGetActionProgressListResponse(responseJson);
          break;
        case this.GetActionUserComplexCallId:
          this.handleActionUserComplexResponse(responseJson);
          break;
        case this.GetActionUserBuildingCallId:
          this.handleActionUserBuildingResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const user_id = this.props.navigation.getParam("id");
    this.setState({ userId: user_id }, () => {
      this.getUserActionProgress();
      this.getActionUserComplex();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.complex !== this.state.complex) {
      await this.getActionUserBuilding();
    }

    if (
      prevState.filterValue.complex !== this.state.filterValue.complex ||
      prevState.filterValue.building !== this.state.filterValue.building ||
      prevState.filterValue.status !== this.state.filterValue.status
    ) {
      this.getUserActionProgress();
    }
  }

  getUserActionProgress = async () => {
    const { complex, building, status } = this.state.filterValue;
    this.GetActionProgressListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/user_task_list?user_id=${
        this.state.userId
      }&complex_id=${complex}&building_id=${building}&status=${status}`,
    });
  };

  getActionUserComplex = async () => {
    this.GetActionUserComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee_nominations/complex_list`,
    });
  };

  handleActionUserComplexResponse = (responseJson: any) => {
    if (responseJson && responseJson.societies) {
      this.setState({ meComplexList: responseJson.societies });
    }
  };

  getActionUserBuilding = async () => {
    const { complex } = this.state;
    this.GetActionUserBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/building_list?society_management_id=${complex}`,
    });
  };

  handleActionUserBuildingResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ meBuildingList: responseJson.buildings });
    }
  };

  handleGetActionProgressListResponse = (responseJson: any) => {
    if (responseJson && responseJson.name) {
      this.setState({ userName: responseJson.name });
    }
    if (responseJson && responseJson.task_list) {
      this.setState({ userActionProgressList: responseJson.task_list });
    }
  };

  handleUserCompanyName = (task: any) => {
    if (task.company_name) {
      return task.company_name;
    } else {
      return "-";
    }
  };
  // Customizable Area End
}
