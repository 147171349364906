export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const user = require("../assets/user.svg");
export const email = require("../assets/email.svg");
export const password = require("../assets/password.svg");
export const company_logo = require("../assets/company_logo.svg");
export const owner = require("../assets/Group 7.png");
export const resident_owner = require("../assets/Group 8.png");
export const tenet = require("../assets/tenant.png");
export const country = require("../assets/country.png");
export const city = require("../assets/city.png");
export const building = require("../assets/building.png");
export const unit = require("../assets/unit.png");
export const Linkage = require("../assets/linkage.png");
export const manual = require("../assets/Manual.png");
export const search = require("../assets/search.png");
export const modalbuilding = require("../assets/modal-building.png");
export const manager = require("../assets/manager.png");
export const request = require("../assets/request.png");
export const Map = require("../assets/Map.png");
export const company_logo2 = require("../assets/company_logo.svg");
export const Building1 = require("../assets/building1.png");
export const numpad = require("../assets/numpad.png");
export const compnayName = require("../assets/companyIcon.png");
export const Tenant_Logo = require("../assets/TenantLogo.png");
export const Back_btn = require("../assets/backbtn.png");
export const LinkageSelected = require("../assets/LinkageSelected.png");
export const ManualSelected = require("../assets/ManualSelected.png");
export const PropertyManagerSelected = require("../assets/PropertyManagerSelected.png");
export const OwnerResidetSelected = require("../assets/ResidetOwnerSelected.png");
export const OwnerSelected = require("../assets/OwnerSelected.png");
export const TenantSelected = require("../assets/TenantSelected.png");
export const ReqHome = require("../assets/reqHome.png");
export const Complex = require("../assets/complex.png");

export const searchIcon = require("../assets/searchIcon.png");













