//Customizable Area Start
import React from "react";
import CEPollSurveyWrapperController, { Props } from "./CEPollSurveyWrapperController.web";
import CEPollSurvey from './CEPollSurvey.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";

export default class CEPollWrappepr extends CEPollSurveyWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      return (
        <MainScreen>
          <CEPollSurvey navigation={this.props.navigation} />
        </MainScreen>
      );
   
  }
}
//Customizable Area End
