// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import ScheduledMeetingController, { Props } from "./ScheduledMeetingController.web";
import { MeetingsStyleWeb } from "./MeetingsStyle.web";
import { CommentIcon, RejectIcon, AcceptIcon, AwaitIcon } from "./assets";
import moment from "moment";
//@ts-ignore
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import { ROLE } from "../../../framework/src/Enum";
import "@szhsin/react-menu/dist/core.css";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import Loader from "../../../components/src/Loader.web";

class ScheduledMeetingDetails extends ScheduledMeetingController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const meeting_id = this.props.navigation.getParam("id");
    this.setState({ scheduleMeetingId: meeting_id }, () => {
      this.getScheduleMeetingDetail();
      this.getMeetingResponseList();
      this.getBuildingsList();
      this.getUserList();
      this.getGroupList();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.filter.page !== this.state.filter.page) {
      await this.getMeetingResponseList();
    }

    if (
      prevState.userFilter.buildingName !== this.state.userFilter.buildingName ||
      prevState.userFilter.floorId !== this.state.userFilter.floorId ||
      prevState.userFilter.userType !== this.state.userFilter.userType
    ) {
      await this.getUserList();
    }
  }

  handleAddMeetingMinuteButton = (t: any) => {
    if (localStorage.getItem("userType") === ROLE.MANAGER && this.state.scheduleMeetingDetails &&
      this.state.scheduleMeetingStatus === "completed" && !this.state.scheduleMeetingDetails.attributes.meeting_mins_pdf) {
      return <Link to={`/ScheduledMeeting/${this.state.scheduleMeetingId}/Note`}>
        <Button>{t("Add Meeting Minutes")}</Button>
      </Link>;
    } else if (localStorage.getItem("userType") === ROLE.MANAGER) {
      return <Link to={`/MeetingMinute/${this.state.scheduleMeetingId}`}>
        <Button className="view-button">{t("View Meeting Minutes")}</Button>
      </Link>;
    }
  };

  handleMeetingCancelButton = (t: any) => {
    if(this.state.scheduleMeetingDetails && this.state.scheduleMeetingStatus === "scheduled") {
      return (
        <Button className="cancel" onClick={() => this.handleCancelMeetingModal()}>
          {t("Cancel Meeting")}
        </Button>
      )
    }
    
  }

  handleMeetingEditButton = (t: any) => {
    if(this.state.scheduleMeetingDetails && this.state.scheduleMeetingStatus !== "completed") {
      return (
        <Button className="edit" onClick={() => this.openEditMeetingModal(this.state.scheduleMeetingDetails)}>
          {t("Edit Meeting")}
        </Button>
      )
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />

        <GeneralHeaderComponentWeb>
              <Container className={classes.scheduledMeetingDetails}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Meetings")} / <Link to="/ScheduledMeetings">{t("Scheduled Meetings")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Meeting Details")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading">
                    <h3 className="bold-text">{t("Meeting Details")}</h3>
                    {this.handleAddMeetingMinuteButton(t)}
                  </Box>
                </Box>
                <Box className="meeting-detail-box">
                  <Box className="meeting-top">
                    <Box className="heading">
                      <h4 className="bold-text">
                        {this.state.scheduleMeetingDetails?.attributes?.title}
                      </h4>
                      {this.state.scheduleMeetingDetails?.attributes?.meeting_type === "ga_meeting" && (
                        <span className="ga-meeting">{t("GA Meeting")}</span>
                      )}
                    </Box>
                    <span className={this.state.scheduleMeetingStatus}>{t(this.state.scheduleMeetingStatus)}</span>
                  </Box>
                  <Divider />
                  <Box className="meeting-details">
                    <h4 className="bold-text">{t("Meeting Details")}</h4>
                    <Box className="items">
                      <span>{t("Date & Time")}: </span>
                      <p className="bold-text">
                        {moment(
                          this.state.scheduleMeetingDetails?.attributes?.meeting_date_time,
                          "DD-MM-YYYY HH:mm",
                          true
                        ).format("MMMM DD, YYYY HH:mm")}
                      </p>
                    </Box>
                    <Box className="items">
                      <span>{t("Place")}: </span>
                      <p className="bold-text">
                        {this.state.scheduleMeetingDetails?.attributes?.place}
                      </p>
                    </Box>
                    <Box className="items">
                      <span>{t("Building")}: </span>
                      <p className="bold-text">
                        {this.state.scheduleMeetingDetails?.attributes?.building?.name || "All"}
                      </p>
                    </Box>
                    <Box className="items">
                      <span>{t("Agenda")}: </span>
                      <p className="bold-text">
                        {this.state.scheduleMeetingDetails?.attributes?.agenda}
                      </p>
                    </Box>
                  </Box>
                  <Box className="meeting-details">
                    <h4 className="bold-text">{t("Scheduling Details")}</h4>
                    <Box className="items">
                      <span>{t("Scheduled By")}: </span>
                      <p className="bold-text">
                        {this.state.scheduleMeetingDetails?.attributes?.meeting_schedule_detail?.scheduled_by}
                      </p>
                    </Box>
                    <Box className="items">
                      <span>{t("Scheduled On")}: </span>
                      {this.state.scheduleMeetingDetails && (
                        <p className="bold-text">
                          {moment(
                            this.state.scheduleMeetingDetails?.attributes?.meeting_schedule_detail?.scheduled_on,
                            "DD-MM-YYYY HH:mm",
                            true
                          ).format("MMMM DD, YYYY HH:mm")}
                        </p>
                      )}
                    </Box>
                  </Box>
                  {this.state.scheduleMeetingStatus === "cancelled" &&
                    this.state.scheduleMeetingDetails.attributes.meeting_cancel_detail && (
                      <Box className="meeting-details">
                        <h4 className="bold-text">{t("Cancelled Details")}</h4>
                        <Box className="items">
                          <span>{t("Cancelled By")}: </span>
                          <p className="bold-text">
                            {this.state.scheduleMeetingDetails.attributes.meeting_cancel_detail.cancelled_by}
                          </p>
                        </Box>
                        <Box className="items">
                          <span>{t("Cancelled On")}: </span>
                          <p className="bold-text">
                            {moment(
                              this.state.scheduleMeetingDetails.attributes.meeting_cancel_detail.cancelled_on,
                              "DD-MM-YYYY HH:mm",
                              true
                            ).format("MMMM DD, YYYY HH:mm")}
                          </p>
                        </Box>
                      </Box>
                    )}
                </Box>
                <Box className="response-box">
                  {this.state.scheduleMeetingDetails && this.state.scheduleMeetingStatus === "scheduled" ? (
                    <Box className="heading">
                      <h3 className="bold-text">{t("Meeting Attendees")}</h3>
                      <Box className="status">
                        <div className="item">
                          <img src={AwaitIcon} />
                          <p>
                            {t("Awaiting")}{" "}
                            <span>{this.state.scheduleMeetingDetails.attributes.meeting_responses.awaited}</span>
                          </p>
                        </div>
                        <div className="item">
                          <img src={AcceptIcon} />
                          <p>
                            {t("Accepted")}{" "}
                            <span>{this.state.scheduleMeetingDetails.attributes.meeting_responses.accepted}</span>
                          </p>
                        </div>
                        <div className="item">
                          <img src={RejectIcon} />
                          <p>
                            {t("Rejected")}{" "}
                            <span>{this.state.scheduleMeetingDetails.attributes.meeting_responses.rejected}</span>
                          </p>
                        </div>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="heading">
                      <h3 className="bold-text">{t("Meeting Attendees")}</h3>
                    </Box>
                  )}
                  <Table className="table-box">
                    <TableHead>
                      <TableRow>
                        <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Name")}</TableCell>
                        <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Building")}</TableCell>
                        <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Unit No.")}</TableCell>
                        <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Floor Number")}</TableCell>
                        {this.state.scheduleMeetingDetails && this.state.scheduleMeetingStatus !== "cancelled" && (
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Response")}</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.responseList.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={5}>{t("No User Available!!")}</TableCell>
                        </TableRow>
                      )}
                      {this.state.responseList.map((user: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align={languageCondition(language,"right","left")}>{user.attributes.name}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")}>{user.attributes.building_name}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")}>{user.attributes.unit_number}</TableCell>
                            <TableCell align={languageCondition(language,"right","left")}>{user.attributes.floor_number}</TableCell>
                            {this.state.scheduleMeetingDetails && this.state.scheduleMeetingStatus !== "cancelled" && (
                              <TableCell align={languageCondition(language,"right","left")}>
                                {this.state.scheduleMeetingStatus === "completed" &&
                                  user.attributes.response === "awaited" ? (
                                  <span className="no-response">No Response</span>
                                ) : (
                                  <span className={user.attributes.response}>{user.attributes.response}</span>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Box className="table-bottom">
                    <p>
                      {t("Showing")} <span className="current-page">{this.state.responseList.length}</span> {t("of")}{" "}
                      <span className="total-page">
                        {this.state.pagination ? this.state.pagination.total_count : 0}
                      </span>{" "}
                      {t("results")}
                    </p>
                    {this.state.pagination && (
                      <Pagination
                        onChange={(event: any, value: any) => {
                          this.setState({
                            ...this.state,
                            filter: {
                              ...this.state.filter,
                              page: Number(value),
                            },
                          });
                        }}
                        count={this.state.pagination.total_pages}
                        page={this.state.pagination.current_page}
                        siblingCount={2}
                        variant="outlined"
                        shape="rounded"
                      />
                    )}
                  </Box>
                </Box>
                <Box className="button-box">
                  {this.handleMeetingCancelButton(t)}
                  {this.handleMeetingEditButton(t)}
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>

        {this.editMeetingModal(language, t)}

        {this.createMeetingGroupModal(language, t)}

        {this.cancelMeetingModal(language, t)}

        <Dialog
          fullWidth
          onClose={() => this.handleCompleteMeetingModal()}
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.isCompleteMeetingModalOpen}
          className="cancel-meeting-dialog meeting-detail-complete-modal"
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={CommentIcon} alt="comment" />
              <Typography variant="h6" className="bold-text">
                {t("Complete Meeting Confirmation")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0px" }}>
                {t("Are you sure want to complete the meeting scheduled on")}{" "}
                {moment(
                  this.state.scheduleMeetingDetails?.attributes?.meeting_schedule_detail?.scheduled_on,
                  "DD-MM-YYYY HH:mm",
                  true
                ).format("MMMM DD, YYYY HH:mm")}{" "}
                {t("at")} {this.state.scheduleMeetingDetails?.attributes?.place}? {t("Once completed, attendees will receive a meeting completion notification")}.
              </Typography>
              <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
                <Button
                  className="cancel-button"
                  style={{ width: "200px" }}
                  onClick={() => this.handleCompleteMeetingModal()}
                >
                  {t("No, Don't Complete")}
                </Button>
                <Button
                  style={{ width: "200px", margin:"0" }}
                  className="add-button"
                  onClick={() => this.updateStatusMeeting("completed")}
                >
                  {t("Yes, Complete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(ScheduledMeetingDetails)));
// Customizable Area End
