// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  anchorEl: any;
  anchorEl_1: any;
  loading: boolean;
  sortBy: any;
  status: any;
  pollListing: any;
  myPreviousList: any;
  isOpen: boolean;
  toggleAscDesc: string;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getPreviousPaymentCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      anchorEl: null,
      anchorEl_1: null,
      loading: false,
      sortBy: "",
      status: "",
      pollListing: [],
      myPreviousList: [],
      toggleAscDesc: "asc",
      isOpen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getPreviousPayment();
  }

  getPreviousPayment = async () => {
    this.getPreviousPaymentCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/invoices/my_previous_payments?select_status=${
        this.state.anchorEl?.toLowerCase() || ""
      }&sort_by=${this.state.toggleAscDesc?.toLowerCase() || ""}`,
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getPreviousPaymentCallId) {
        this.getPreviousPaymentResponse(responseJson);
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  getPreviousPaymentResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        myPreviousList: responseJson.data,
      });
    }
  };
}

// Customizable Area End
