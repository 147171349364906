// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  anchorEl: any;
  anchorEl_1: any;
  loading: boolean;
  sortBy: any;
  status: any;
  isOpen: boolean;
  isOpenFilter: any;
  pollListing: any;
  toggleAscDesc: string;
  myReceiptsList: any;
  myCurrentReceipts: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getMyRecepitsCallId: any;
  getVisitorListId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      anchorEl: null,
      anchorEl_1: null,
      loading: false,
      sortBy: "",
      status: "",
      toggleAscDesc: "asc",
      isOpen: false,
      pollListing: [],
      myReceiptsList: [],
      isOpenFilter: null,
      myCurrentReceipts: null,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getMyRecepits();
  }

  getMyRecepits = async () => {
    this.getMyRecepitsCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_fees_payment/receipts/my_receipts_list?invoice_type=${
        this.state.anchorEl?.toLowerCase() || ""
      }&sort_by=${this.state.toggleAscDesc?.toLowerCase() || ""}`,
    });
    return true;
  };

  getMyReceiptsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        myReceiptsList: responseJson.data,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getMyRecepitsCallId) {
        this.getMyReceiptsResponse(responseJson);
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }
}
