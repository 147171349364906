//Customizable Area Start
import * as React from "react";
// custom components
import {Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography} from "@material-ui/core";
import {Building1, Chat_Disable_Icon, Chat_Icon, Contact_Icon, Email_Msg_Icon, NoProfile_Img, Pencil} from "./assets";
import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import {FacebookIcon, InstagramIcon, SnapchatIcon, TwitterIcon} from "../../Settings5/src/assets"
import '../assets/css/style.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ProfileController from "./ProfileController.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import {Menu, MenuItem} from "@szhsin/react-menu";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class Profile extends ProfileController {

  async componentDidMount() {
    this.getProfile();
  }

  editProfile = () => {
    localStorage.setItem('profileData',JSON.stringify(this.state.profiledata));
    // @ts-ignore
    this.props.history.push('/editprofile');
  }

  managePublishDetails = () => {
    localStorage.setItem('profileData', JSON.stringify(this.state.profiledata));
    // @ts-ignore
    this.props.history.push('/publicview');
  }

  render() {
    let profileData = this.state.profiledata;
    const { t,i18n }: any = this.props;
    const language = i18n.language;



    return (
      <>
        <Grid container className="auth-container profile" dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols profile" style={{ justifyContent: 'unset', overflowY: 'auto', overflowX: 'hidden' }}>
            <Grid container className="profile">
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center',borderBottom:'2px solid #F2F2F2' }}>
                <div className="flex profile" style={{width:'100%', alignItems: 'center'}} >
                  <div style={{ display: "flex", alignItems: 'center', gap: '0.5rem' }} className="profile">
                    <ArrowBackIcon onClick={this.redirectToDashboard} style={{fontSize:"27px"}} />
                    <p style={{ fontSize: '18px' }} className="bold-text profile">
                      {t("My Profile")}
                    </p>
                  </div>
                  <div className={languageCondition(language,"right-icon profile-menu_ar","right-icon profile-menu")}>
                    <Menu className="ownerProfileMenu" menuButton={<IconButton><MoreVertIcon /></IconButton>}>
                      <MenuItem className="profile" onClick={this.editProfile}
                      >{t("Edit profile")}</MenuItem>
                      <MenuItem className="profile" onClick={this.managePublishDetails}>{t("Publish details for others")}</MenuItem>
                      <MenuItem className="profile" onClick={() => this.disablechat()}>{
                        t(profileData?.attributes?.disable_chat ? 'Enable Chat' : 'Disable Chat')
                      }</MenuItem>
                    </Menu>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{marginTop:'1.5rem'}} className="profile">
              <Grid item xs={12} className="profile" >
              </Grid>
            </Grid>
            <Grid container className="profile">
              <SectionOne profileData={profileData} props={this.props} t={t} language={language}/>
            </Grid>
            <Grid container className="profile">
              <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginTop:'1rem',gap:'1rem'}} className="profile">
              </Grid>
            </Grid>

            <Grid container className="profile">
              <Grid item xs={12} className="profile">
                <p className="bold-text profileBio" style={{ padding:"0 20px", fontWeight: 'bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.25rem' }} >
                  {t("Bio")}:
                </p>
              </Grid>
            </Grid>
            <Grid container className="profileBioDetails">
              <Grid item xs={12} className="profileBioDetails">
                <p style={{ padding:"0 20px", fontSize: '14px', marginBottom: '0.5rem' }} className="profileBioDetails">
                  {profileData?.attributes?.bio?.bio || t("No Bio added yet!")}
                </p>
              </Grid>
            </Grid>
              <Grid container className="profileHobbies">
                <Grid item xs={12} className="profileHobbies">
                  <p className="bold-text profileHobbies" style={{ padding:"0 20px", fontWeight: 'bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.25rem' }}>
                    {t("Hobbies")}:
                  </p>
                </Grid>
              </Grid>
            {
              profileData?.attributes?.hobbies?.hobbies && <>
                <Grid container className="profileHobbiesDetails">
                  <Grid item xs={12} style={{ display:"flex", flexWrap:"wrap",paddingLeft:"10px"}} className="profileHobbiesDetails">
                    {
                      profileData?.attributes?.hobbies?.hobbies.map((item:any) => <>
                        <span className="hobbies profileHobbiesDetails" style={{marginTop:'0', marginBottom:'0.5rem'}}>
                          {item}
                        </span>
                      </>)
                    }
                  </Grid>
                </Grid>
              </>
            }
            <Grid container className="profileFollowMe">
              <Grid item xs={12} className="profileFollowMe">
                <p className="bold-text profileFollowMe" style={{ padding:"0 20px", fontWeight: 'bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.25rem' }}>
                  {t("Follow me on")}:
                </p>
              </Grid>
            </Grid>
            <Grid container className="profileFollowMe">
              <Grid item xs={12} style={{paddingLeft:"10px"}} className="profileFollowMe">
                <Box display='flex' justifyContent='start' className="icons profileFollowMe" style={{marginLeft:"8px"}}>
                  {
                      profileData?.attributes?.website[0].twitter_link &&
                      <Button className="profileFollowMe twitter" onClick={() => window.open(profileData.attributes?.website[0].twitter_link,"_blank")} style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                        <img src={TwitterIcon} alt="phone"/>
                      </Button>
                  }
                  {
                      profileData?.attributes?.website[1].instagram_link &&
                      <Button className="profileFollowMe insta" onClick={() => window.open(profileData.attributes?.website[1].instagram_link,"_blank")} style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                        <img src={InstagramIcon} alt="chat"/>
                      </Button>
                  }
                  {
                      profileData?.attributes?.website[2].fb_link &&
                      <Button className="profileFollowMe fb" onClick={() => window.open(profileData.attributes?.website[2].fb_link,"_blank")} style={{minWidth:"0px",padding:"6px 0px",marginRight:"5px"}}>
                        <img src={FacebookIcon} alt="chat"/>
                      </Button>
                  }
                  {
                      profileData?.attributes?.website[3].snapchat_link &&
                      <Button className="profileFollowMe snapchat" onClick={() => window.open(profileData.attributes?.website[3].snapchat_link,"_blank")} style={{minWidth:"0px",padding:"6px 0px"}}>
                        <img src={SnapchatIcon} alt="email"/>
                      </Button>
                  }
                </Box>
              </Grid>
            </Grid>

            {
              localStorage.getItem('userType') !== 'Owner' &&
              <>
                <Grid container style={{ padding:"0 20px",}} className="myFamily">
                  <Grid item xs={12} style={{ display:'flex',justifyContent:'space-between',alignItems:'center'}} className="myFamily">
                    <p className="bold-text myFamilyName" style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.25rem' }}>
                      {t("My Family")}
                    </p>
                    {
                      profileData?.attributes?.families?.families?.length > 0 && 
                      <img src={Pencil} width='20' height='20' onClick={()=>this.props.history.push('/familylist')} className="myFamilyEdit"/>
                    }
                  </Grid>
                </Grid>
                <Grid container className="myFamilyListing">
                  <Grid item xs={12} style={{ padding:"0 10px", margin: '0.25rem' }} className="myFamilyListing">
                    {
                      profileData?.attributes?.families.families && profileData?.attributes?.families.families.map((item: any) =>
                        <>
                          <Grid xs={12} className="card fam myFamilyListingItem" spacing={4} style={{marginTop:10,paddingTop:"5px"}}>
                            <div className="flex myFamilyListingItem">
                              <div style={{ display: "flex", alignItems: 'center', gap: '0.5rem' }} className="myFamilyListingItem">
                                {/* <Avatar src={item?.attributes?.member_pic} /> */}
                                <p className="bold-text myFamilyListingItem" style={{fontSize:"16px"}}>
                                  {item.attributes.name}
                                </p>
                              </div>
                              <div className={languageCondition(language,"right-icon family-menu_ar","right-icon family-menu")} >
                                <Menu
                                  className="myFamilyMenu"
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                >
                                  <MenuItem className="myFamilyMenu1" onClick={() => this.handleClose(item)}>{t("Edit")}</MenuItem>
                                  <MenuItem className="myFamilyMenu2" onClick={() => { this.setState({ showDialogDelete: true }); localStorage.setItem('selectFamily', JSON.stringify(item)) }}>{t("Delete")}</MenuItem>
                                </Menu>
                              </div>
                            </div>
                            <div>
                              <p className="fam-label myFamilyMenu" >
                                {t("Relation")}:
                              </p>
                              <p className="fam-value myFamilyMenu">
                                {item.attributes.relation.name}
                              </p>
                            </div>
                            <div className="myFamilyMenu">
                              <p className="fam-label myFamilyMenu">
                                {t("ID")}:
                              </p>
                              <p className="fam-value">
                                {item.attributes.id_number}
                              </p>
                            </div>
                          </Grid>
                        </>
                      )
                    }
                  </Grid>
                </Grid>
                {
                  profileData?.attributes?.families?.families == null && 
                  <Grid container className="myFamilyMenu">
                    <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }} className="myFamilyMenu">
                      <Button
                        fullWidth={true} className={'btn myFamilyMenu myFamilyCreate'}
                        variant="contained" type="submit"
                        onClick={() => {
                          // @ts-ignore
                          this.props.history.push("/NewFamily")
                        }}
                        style={{
                          border: "1px solid #2B6FEC", background: 'white',
                          borderRadius: 25, height: 50,
                          boxShadow: "none", color: "#2B6FEC",
                          fontWeight: 600, fontSize: 16,
                          maxWidth: 350
                        }}

                      >
                        {t("Add Family Details")}
                      </Button>
                    </Grid>
                  </Grid>
                }
              </>
            }
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols myFamilyMenu">
            <Box className="right-block myFamilyMenu" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo myFamilyMenu" alt="" />
            </Box>
          </Grid>
        </Grid>

        <Dialog
          className="delete-document personal myFamilyMenu deleteFamilyModal"
          fullWidth
          onClose={() => this.setState({ showDialogDelete: false, showDialog: false })}
          open={this.state.showDialogDelete}
        >
          <DialogContent>
            <Box textAlign="center" className="myFamilyMenu1">
              {/* <img src={DeleteUnitIcon} alt="ExclamationIcon" /> */}
              <Typography variant="h6" className="bold-text myFamilyMenu" >
                {t("Delete registered")} <br /> {t("Family Member")}
              </Typography>
              <Typography variant="body1" className="myFamilyMenu1">
                {t("Are you should want to delete this registered family member from this App?")}
              </Typography>
              <DialogActions className="dialog-button-group myFamilyMenu">
                <Button onClick={() => this.deleteRequest()} className="deleteFaliyYesButton">
                  {t("Yes, Delete")}
                </Button>
                <Button className="deleteFaliyNoButton" onClick={() => this.setState({ showDialogDelete: false, showDialog: false })}>{t("No, Don’t Delete")}</Button>
              </DialogActions> </Box>
          </DialogContent> </Dialog>

        <Loader loading={this.state.loading} />

        <AlertErrorWeb
            show={this.state.showError}
            handleClose={()=> this.setState({showError:false,error:null})}
            message={this.state.error}
        />
      </>
    )
  }
}

// @ts-ignore
// @ts-nocheck
export default withTranslation()(withRouter(Profile));


function SectionOne(props:any){
  console.log('props',props)
  const {profileData,t,language} =props
  return <>
  <Grid item xs={12}>
    <Box className="card-top-block OwnerProfile">
      <img style={{width:50,height:50,borderRadius:'50%'}} src={profileData?.attributes?.profile_pic?.url || NoProfile_Img} className="info-icon OwnerProfile" alt="info-icon" />
      <Typography component="h4" className="title bold-text OwnerProfile" style={{ fontSize:'18px', paddingTop:"0"}}>
        {profileData?.attributes?.full_name?.name}
      </Typography>
      <Box className="social-raw OwnerProfile">
      {
        profileData?.attributes?.disable_chat ?
        <Box className="blocks" style={languageCondition(language,{border:0},{})}>
          <img src={Chat_Disable_Icon}  className="icons OwnerProfile" alt="info-icon" width='15' />
        </Box> :
        <Box className="blocks" style={languageCondition(language,{border:0},{})}>
          <img src={Chat_Icon} onClick={()=>props?.props?.history.push('/inbox')} className="icons chatButton" alt="info-icon" />
        </Box>
      }
          <Box className="blocks">
            <a href={`tel:${profileData?.attributes.full_phone_number?.full_phone_number}`}>
              <img src={Contact_Icon} className="icons" alt="info-icon" />
            </a>
          </Box>
          <Box className="blocks" style={languageCondition(language,{},{border:0})}>
            <a href={`mailto:${profileData?.attributes.email?.email}`}>
              <img src={Email_Msg_Icon} className="icons" alt="info-icon" />
            </a>
          </Box>
      </Box>
      <Box className="relation-row">
        <Box className="blocks" style={{ display: 'flex',gap:'1rem', marginTop:"15px" }}>
          <div>
          {
            profileData?.attributes?.gender?.publilc_access ?
              <Box style={{display:"flex", alignItems:"center", gap:"5px"}}>
                <p className="bold-text" style={{fontSize:"14px"}}>{t("Gender")}:</p>
                <span style={{fontWeight:400, fontSize:"14px"}}>{t(profileData.attributes?.gender.gender)}</span>
              </Box>
              : null
          }
          </div>
          <div>
          {
            profileData?.attributes?.date_of_birth?.publilc_access ?
              <Box style={{display:"flex", alignItems:"center", gap:"5px"}}>
                <p className="bold-text" style={{fontSize:"14px"}}>{t("DOB")}:</p>
                <span style={{fontWeight:400, fontSize:"14px"}}>{profileData.attributes?.date_of_birth.date_of_birth && moment(profileData.attributes?.date_of_birth.date_of_birth,"YYYY-MM-DD").format("DD MMM YYYY")}</span>
              </Box>
              :
              null
          }
          </div>
        </Box>
      </Box>
    </Box>
  </Grid>
  </>
}
//Customizable Area End