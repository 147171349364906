// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history:any;
  location:any;
  match:any;
}

interface S {
  reportData:any;
  startDate:any;
  endDate:any;
}

interface SS {
  id: any;
}

export default class IssueAReportController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getIssueReportId:string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    this.state = {
      reportData:{},
      startDate:"",
      endDate:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getIssueReportData()
  }

  manageSearchByDate = () => {
   if(this.state.startDate !== "" && this.state.endDate !== ""){
     this.getIssueReportData()
   }
  }

  getIssueReportData = async () => {
    const societyID = localStorage.getItem("society_id")
    this.getIssueReportId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/issue_reports?society_id=${societyID}&from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }


  issueAReportDownload = async () => {
    const societyID = localStorage.getItem("society_id")
    await this.downloadPdf(`society_managements/${societyID}/bx_block_dashboard/download_report?from=${this.state.startDate}&to=${this.state.endDate}`, "IssueReport.pdf")
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getIssueReportId){
        if(responseJson.hasOwnProperty("data")){
          this.setState({
            reportData:responseJson?.data?.attributes
          })
        }else{
          console.log("NO DATA")
        }
      }
    }
  }

}
// Customizable Area End
