//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Container,
  Typography,
  Link,
  Card,
  CardContent,
  Button,
  withStyles
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import Box from '@material-ui/core/Box';
import Select from "@material-ui/core/Select";
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentManagementController, { Props } from "./IncidentManagementController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebar from "../../dashboard/src/ChairmanSidebar.web";
import { withTranslation } from 'react-i18next';
import '../../../web/src/i18n.js';
import { SearchIconImage } from "../../StoreCredits/src/assets";
//resorces
import { incedentBuilding, incedentUnit, incedentUser } from "../src/assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
class IncidentManagement extends IncidentManagementController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getIncidentListing();
    this.getBuildingName();
  }

  

  render() {
    const { t,i18n }: any = this.props;
    const language = i18n.language;
    const statusArray = ["Ongoing", "Unresolved", "Closed", "Pending Confirmation"];

    return (
      <>
        <GeneralHeader>
              <Container className="incident-Listing-wrapper desktop-ui">
                <Box style={dashBoard.navigation}>
                  <Box>
                    <Typography variant="body1" >
                      <Link href="DashboardGeneral" color="inherit"> {t("My Dashboard")}</Link> /
                      <Link href="DashboardGeneral" color="inherit"> {t("General Dashboard")}</Link> /
                      <Box component="span" style={{ color: "#2c6fed" }}>
                        <Link href="IncidentManagement" color="inherit">{t("Incidents")}</Link>
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>{t("Incidents")}</Typography>
                  </Box>
                </Box>
                <Box className="sorting-header ">
                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="buildingName"
                        style={{width:"170px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        displayEmpty
                        className="buildingNameClass-testcase"
                        onChange={(e) => { this.onChange(e); }}
                        value={this.state.buildingName}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t("Select Building")}
                        </MenuItem>
                        {
                          this.state.buildingNameData.length > 1 && <MenuItem value=" ">
                            {t("All")}
                            </MenuItem>
                        }
                        {
                          this.state.buildingNameData?.map((val, index) => (
                            <MenuItem
                              key={index}
                              value={`${val?.id},${val?.attributes?.name}`}
                            >
                              {val.attributes.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="unitName"
                        displayEmpty
                        className="unitName-testcases"
                        style={{width:"170px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => { this.onChange(e); }}
                        value={this.state.unitName}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t("Select Unit")}
                        </MenuItem>
                        {
                            this.state.unitNameData.length > 1 && <MenuItem value=" ">
                              {t("All")}
                            </MenuItem>
                        }
                        {
                          this.state.unitNameData?.map((val, index) => (
                            <MenuItem
                              key={index}
                              value={val.id}
                              disabled={this.state.buildingName ? false : true}
                              data-test-id="unitNameData"
                            >
                              {val.apartment_name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>

                  <Box className="formGroup customSelect" >
                    <FormControl variant="outlined" >
                      <Select
                        name="status"
                        displayEmpty
                        style={{width:"170px"}}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        className="status-testcases"
                        onChange={(e) => { this.onChange(e); }}
                        value={this.state.status}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t("Select Status")}
                        </MenuItem>
                        {
                            statusArray.length > 1 && <MenuItem value=" ">
                              {t("All")}
                            </MenuItem>
                        }
                        {
                          statusArray.map((val, index) => (
                            <MenuItem
                              key={index}
                              value={val}
                            >
                              {val}
                            </MenuItem>
                          ))
                        }
                      </Select>

                    </FormControl>
                  </Box>
                  <Box >
                    <Button variant="contained" className="searchIncident" color="primary" style={{ backgroundColor: "#2b6fed", borderRadius: "8px" }} startIcon={<img src={SearchIconImage} style={{marginLeft:"10px"}} />} onClick={() => this.serachHandle()}>
                      {t("Search")}
                    </Button>
                  </Box>
                </Box>
                <Grid container spacing={2} style={{ marginTop: 15, marginBottom: 15 }}>
                 {
                   this.state.incidentListing?.map((val, index) => (
                     <Grid className="getIncidentDetails-testcase" item sm={4} key={index} onClick={() => this.getIncidentDetails(val.id)}>
                       <Card className="management-card card" key={index}>
                         <CardContent className="costom-card-content">
                           <ButtonStatus val={val} language={t}></ButtonStatus>
                           <Typography component="h4" className="bold-text" style={{ fontSize: "20px" }}>
                             {val.attributes.incident_related.name}
                           </Typography>
                           <Box className="card-rows">
                             <img src={incedentBuilding} alt="Bank Icon" />
                             <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>{val.attributes.apartment_management.building_name}</p>
                           </Box>
                           <Box className="card-rows">
                             <img src={incedentUnit} alt="Bank Icon" />
                             <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>{val.attributes.apartment_management.apartment_name}</p>
                           </Box>
                           <Box className="card-rows">
                             <img src={incedentUser} alt="Bank Icon" />
                             <p style={languageCondition(language,{ fontSize: "16px", marginRight: "10px" },{ fontSize: "16px", marginLeft: "10px" })}>{val.attributes.reported_by.full_name}</p>
                           </Box>
                         </CardContent>
                       </Card>
                     </Grid>
                   ))
                 }
               </Grid>

              </Container>
        </GeneralHeader>
        <AlertErrorWeb data-test-id="showError" show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};

export default withTranslation()(withStyles(dashBoard)(withRouter(IncidentManagement)));

const ButtonStatus = (props: any) => {
  const val = props.val;
  const language = props.language;
  const checkCl = () => {
    if (val.attributes.incident_status === 'Unresolved') {
      return "contain danger";
    } else if (val.attributes.incident_status === 'Resolved') {
      return 'contain warning';
    } else if (val.attributes.incident_status === 'Ongoing') {
      return 'contain incident-status-blue';
    } else {
      return 'contain success';
    }
  };
  return (
    <>
      <Box className="customButton" >
        <Button variant="contained" className={checkCl()} type="submit" style={{ borderRadius: "25px" }}>
          {language(val?.attributes?.incident_status)}</Button>
      </Box>
    </>
  );
 };



// Customizable Area End
