// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Menu,
  MenuItem
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityReservationController, { Props } from "./FacilityReservationController.web";
import { Building1, Grid_Icon } from "../src/assets";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class FacilityReservationListing extends FacilityReservationController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    //@ts-ignore
    const reservation = localStorage.getItem("idOrName");
    if (reservation)
      this.getFacilityReservationListing(this.state?.sortBy);
    else
      this.props.history.push("/FacilityReservation");
  }

  render() {
    const { t }: any = this.props;
    // @ts-ignore
    const {language} = this.props.i18n
    const reservation = localStorage.getItem("idOrName");
    let facilityReservationListing: any;

    if (reservation === "Previous") {
      facilityReservationListing = this.state?.facilityReservationListing?.filter((val: any) => val?.attributes?.status === 'Completed');
    } else {
      facilityReservationListing = this.state?.facilityReservationListing?.filter((val: any) => val?.attributes?.status === reservation);
    }

    return (
      <>
        <Box className="login-wrapper incident-wrapper" style={{ background: "#f8f9fe" }}>
          <Grid container spacing={2} className="auth-container" style={{ background: "#f8f9fe" }}>
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block FaciltiyReservationListing" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks">
                    <ArrowBackIcon className="backIcons" onClick={() => this.props.history.push("/FacilityReservation")} />
                    <h4 className="bold-text">{t(`${reservation} Reservations`)}</h4>
                  </Box>
                  <Box className="incident-right-block blocks">
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e: any) => this.handleClick(e)}>
                        <img src={Grid_Icon} className="grid-icon icons" alt="" />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleClose("", "")}
                      >
                        <MenuItem onClick={(e) => this.handleClose(e, "asc")}>{t("Ascending")}</MenuItem>
                        <MenuItem onClick={(e) => this.handleClose(e, "desc")}>{t("Descending")}</MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
                <Box className="content-block-wrapper facility-block-wrapper filtered-facility-list" style={{ padding: "0 20px 75px 20px" }}>
                  <Box className="incident-content-wrapper" style={{ marginTop: 0 }}>
                    {facilityReservationListing?.length === 0 &&
                      <Box style={{ background: "white", padding: "10px", marginTop: "10px" }}>
                        <Typography variant="body1">
                          There are no {reservation} Reservations for you...
                        </Typography>
                      </Box>
                    }
                    {facilityReservationListing?.map((val: any, index: any) => (
                      <Card className="incident-card facility-card card" key={index} onClick={() => this.getFacilityReservationDetails(val.id)} style={{ border: 0, padding: "16px" }}>
                        <CardContent className="costom-card-content" style={{ border: 0, padding: "0" }}>
                          <Typography className="sub-title h5-title bold-text" component="h4">
                            {moment(val?.attributes?.date, "DD-MMM-YYYY").format("MMM DD, YYYY")}
                          </Typography>
                          <Box className="card-listing-row">
                            <Typography component="span" className="span-subtitle">
                              {t("Facility Reserved")}:
                            </Typography>
                            <Typography component="span" className="span-subtitle">
                              {t("Building Name")}:
                            </Typography>
                          </Box>
                          <Box className="card-listing-row" style={{ paddingBottom: "8px", paddingTop: "4px" }}>
                            <p className="facility-subtitle" >
                              {val?.attributes?.common_area?.name}
                            </p>
                            <p className="facility-subtitle">
                              {val?.attributes?.building?.name}
                            </p>
                          </Box>
                          <hr />
                          <CardActions className="card-footer">
                            <Typography className="sub-title h5-title" component="h5" style={{ padding: 0 }}>
                              {t("Rent")}
                            </Typography>
                            <Box className="customButton" style={{ padding: 0 }}>
                              <Button variant="contained" className="contain blue" type="submit" >{languageCondition(language,val.attributes.currency?.currency_ar,val.attributes.currency?.currency)}{" "}{Number(val.attributes.rent).toLocaleString()}</Button>
                            </Box>
                          </CardActions>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(FacilityReservationListing));
// Customizable Area End