// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openmanageFAQ: any;
  }
export const HelpImageComponent:React.FC<MyComponentProps>  = ({openmanageFAQ}) => {
    const strokeColor = openmanageFAQ 
  return (
    <svg className={strokeColor}
      width="24.399902px"
      height="24.40039px"
      viewBox="0 0 24.399902 24.40039"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="question" transform="translate(0.19995117 0.20019531)">
        <path
          d="M12 0C5.36761 0 0 5.367 0 12C0 18.6323 5.367 24 12 24C18.6324 24 24 18.633 24 12C24 5.36761 18.633 0 12 0L12 0ZM11.9999 22.3256C6.30636 22.3256 1.67432 17.6936 1.67432 12C1.67432 6.30644 6.30636 1.67444 11.9999 1.67444C17.6935 1.67444 22.3255 6.30644 22.3255 12C22.3255 17.6936 17.6935 22.3256 11.9999 22.3256L11.9999 22.3256Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.4"
        />
        <path
          d="M11.6448 15.184C10.9814 15.184 10.4443 15.7368 10.4443 16.4002C10.4443 17.0478 10.9656 17.6165 11.6448 17.6165C12.324 17.6165 12.861 17.0478 12.861 16.4002C12.861 15.7368 12.3082 15.184 11.6448 15.184L11.6448 15.184Z"
          id="Shape"
          fill="#181D25"
          fill-rule="evenodd"
          stroke-width="0.4"
        />
        <path
          d="M11.8503 5.9751C9.71784 5.9751 8.73853 7.2388 8.73853 8.09174C8.73853 8.70777 9.25978 8.99211 9.68624 8.99211C10.5392 8.99211 10.1917 7.7758 11.8029 7.7758C12.5926 7.7758 13.2245 8.12333 13.2245 8.84994C13.2245 9.70288 12.3399 10.1925 11.8187 10.6348C11.3606 11.0296 10.7604 11.6773 10.7604 13.0357C10.7604 13.8571 10.9815 14.094 11.6291 14.094C12.4031 14.094 12.561 13.7466 12.561 13.4464C12.561 12.625 12.5768 12.1512 13.4456 11.4719C13.8721 11.1403 15.2147 10.0661 15.2147 8.58135C15.2147 7.09658 13.8721 5.9751 11.8503 5.9751L11.8503 5.9751Z"
          id="Shape"
          fill="#181D25"
          fill-rule="evenodd"
          stroke-width="0.4"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
