import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import toast from "react-hot-toast";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface TenantDetails {
  tenantName: string;
  tenantType: string;
  complexName: string;
  buildingName: string;
  unitNumber: string;
  city: string;
  phoneNumber: string;
  email: string;
  isLeaseIssued: string;
  IdType: string;
  IdNumber: string;
  IdExpDate: string;
  documentList: any[];
}

interface S {
  loading: boolean;

  tenantList: any[];

  tenantId: string;
  tenantData: TenantDetails;

  showError: boolean;
  error: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantListController extends BlockComponent<Props, S, SS> {
  GetTenantListCallId: any;
  GetRegisteredTenantDetailsCallId: any;
  DeleteTenantCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      showError: false,
      error: "",

      loading: false,

      tenantList: [],

      tenantId: "",
      tenantData: {
        tenantName: "",
        tenantType: "",
        complexName: "",
        buildingName: "",
        unitNumber: "",
        city: "",
        phoneNumber: "",
        email: "",
        isLeaseIssued: "",
        IdType: "",
        IdNumber: "",
        IdExpDate: "",
        documentList: [],
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetTenantListCallId:
          this.handleGetTenantListResponse(responseJson);
          break;
        case this.GetRegisteredTenantDetailsCallId:
          this.handleGetTenantDetailsResponse(responseJson);
          break;
        case this.DeleteTenantCallId:
          this.handleDeleteTenantResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  handleGetTenantListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ tenantList: responseJson.data });
    }
  };

  handleGetTenantDetailsResponse = (responseJson: any) => {
    if (responseJson.code === 200) {
      const tenant = responseJson.tenant.data;
      let document: any = [];
      if (tenant.attributes.tenant_id_copy) {
        document = [...document, tenant.attributes.tenant_id_copy];
      }
      if (tenant.attributes.tenant_documents && tenant.attributes.tenant_documents.length > 0) {
        document = [...document, ...tenant.attributes.tenant_documents];
      }
      if (tenant.attributes.contract) {
        document = [...document, tenant.attributes.contract];
      }
      this.setState({
        tenantData: {
          tenantName: tenant.attributes.name,
          tenantType: tenant.attributes.tenant_type,
          complexName: tenant.attributes.society_management?.name,
          buildingName: tenant.attributes.building_management?.name,
          unitNumber: tenant.attributes.apartment_management.apartment_name,
          city: tenant.attributes.city,
          phoneNumber: tenant.attributes.phone_number,
          email: tenant.attributes.email,
          isLeaseIssued: tenant.attributes.lease_issued,
          IdType: tenant.attributes.id_proof?.name,
          IdNumber: tenant.attributes.id_number,
          IdExpDate: tenant.attributes.id_expectation_date,
          documentList: document,
        },
      });
    }
  };

  handleDeleteTenantResponse = (responseJson: any) => {
    if (responseJson.code === 200) {
      this.setState({ loading: false });
      this.getTenantList();
      toast.success("Delete tenant successfully");
      this.props.navigation.navigate("TenantList");
    } else if (responseJson.code === 422) {
      this.setState({ loading: false, showError: true, error: responseJson.message });
    }
  };

  // Get All Tenant List - API
  getTenantList = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetTenantListCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_contract/tenant_resquests`);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Tenant Details - API
  getRegisteredTenantDetails = () => {
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    this.GetRegisteredTenantDetailsCallId = apiRequest.messageId;

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/tenant_resquests/${this.state.tenantId}`
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Delete Tenant - API
  handleDeleteTenant = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteTenantCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/tenant_resquests/${this.state.tenantId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };
  // Customizable Area End
}
