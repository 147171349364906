// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import toast from "react-hot-toast";
import { apiCall } from "../../../components/src/APICallComponent/index.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  templatesList: any[];
  complexList: any[];
  buildingList: any[];
  unitList: any[];

  complexId: string;
  buildingId: string;
  buildingName: string;
  unitId: string;
  unitName: string;

  tenant: any;
  contract: any;

  isBuilding: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class IssueContractController extends BlockComponent<Props, S, SS> {
  GetTemplatesListCallId: any;
  GetBuildingListCallId: any;
  GetUnitListCallId: any;
  IsContractExistCallId: any;
  GetComplexListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      templatesList: [],
      complexList: [],
      buildingList: [],
      unitList: [],

      complexId: "",
      buildingId: "",
      buildingName: "",
      unitId: "",
      unitName: "",

      tenant: null,
      contract: null,

      isBuilding: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetComplexListCallId:
          this.handleGetComplexListResponse(responseJson);
          break;
        case this.GetTemplatesListCallId:
          this.handleGetTemplateListResponse(responseJson);
          break;
        case this.GetBuildingListCallId:
          this.handleGetBuildingListResponse(responseJson);
          break;
        case this.GetUnitListCallId:
          this.handleGetUnitListResponse(responseJson);
          break;
        case this.IsContractExistCallId:
          this.handleCheckContractResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getTemplateListFromAdmin();
    this.getComplexList();
  }

  getComplexList = async () => {
    const user_type = localStorage.getItem("userType");

    this.GetComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_type}`,
    });
  };

  handleGetComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complex) {
      this.setState({ complexList: responseJson.complex });
    }
  };

  handleIssueContractComplex = (complex: any) => {
    const complexId = complex.id;
    this.setState({ complexId: complexId }, () => {
      if (complex.is_building) {
        this.setState({ isBuilding: false }, () => {
          const buildingId = complex.is_building_list[0].id;
          this.setState({ buildingId: buildingId }, () => {
            this.getUnits();
          });
        });
      } else {
        this.setState({ isBuilding: true }, () => {
          this.setState({ buildingId: "" }, () => {
            this.getBuilding();
          });
        });
      }
    });
  };

  // Get All Template List from Admin - API
  getTemplateListFromAdmin = async () => {
    this.GetTemplatesListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contract/lease_templates`,
    });
  };

  handleGetTemplateListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ templatesList: responseJson.data });
    }
  };

  // Get Building - API
  getBuilding = async () => {
    const { complexId } = this.state;
    this.GetBuildingListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_meeting/find_building`,
    });
  };

  handleGetBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingList: responseJson.buildings });
    }
  };

  // Get Units - API
  getUnits = async () => {
    const { buildingId, complexId } = this.state;
    this.GetUnitListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_contract/tenant_resquests/find_unit?building_management_id=${buildingId}`,
    });
  };

  handleGetUnitListResponse = (responseJson: any) => {
    if (responseJson && responseJson.apartments) {
      this.setState({ unitList: responseJson.apartments });
    }
  };

  // Find Unit Contract Exist - API
  handleCheckContractExist = async () => {
    const { complexId, unitId } = this.state;
    this.IsContractExistCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${complexId}/bx_block_contract/contracts/find_tenant?apartment_management_id=${unitId}`,
    });
  };

  handleCheckContractResponse = (responseJson: any) => {
    this.setState({ tenant: responseJson.account, contract: responseJson.contract.data });
  };

  // Handle State
  handleGotoTemplate = (templateId: any) => {
    if (!this.state.buildingId) {
      toast.error("Please select building");
    } else if (!this.state.unitId) {
      toast.error("Please select unit");
    } else if (!this.state.tenant) {
      toast.error("Please register tenant");
    } else if (this.state.buildingId && this.state.unitId && this.state.tenant) {
      window.sessionStorage.setItem("page", "IssueContract");
      window.sessionStorage.setItem("buildingId", this.state.buildingId);
      window.sessionStorage.setItem("unitId", this.state.unitId);
      window.sessionStorage.setItem("complexId", this.state.complexId);
      window.sessionStorage.setItem("isBuildingAvailable", `${this.state.isBuilding}`);
      this.props.navigation.navigate("SelectedTemplateTwo", { templateId: templateId });
    }
  };

  handleGotoTemplateLease = (templateId: any) => {
    window.sessionStorage.setItem("page", "IssueLease");
    this.props.navigation.navigate("SelectedTemplateTwo", { templateId: templateId });
  };
  // Customizable Area End
}
// Customizable Area End
