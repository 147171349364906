// Customizable Area Start

import React from "react";
import "./Polling.web.css"
import 'draft-js/dist/Draft.css';

import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    InputAdornment,
    Divider,
    CircularProgress,
    IconButton
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import {audienceCheck,audienceChecked} from "./assets";
import {DeleteImage} from "../../Notes/src/assets"
import 'date-fns';
// Icons
import InfoIcon from '@material-ui/icons/Info';
// Icons

import CreateSurveyController, {
  Props,
} from "./CreateSurveyController";
import TextEditor from "./TextEditorSurvey.web";
import Backdrop from "@material-ui/core/Backdrop";
import AudienceModal from "./AudienceModal.web";
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import '../../../web/src/i18n.js';
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import AlertError from "../../../components/src/AlertError.web";
import BackspaceIcon from '@material-ui/icons/Backspace';
import {languageCondition, pathRedirectManager} from "../../../components/src/ConditionManager.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import Loader from "../../../components/src/Loader.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import moment from "moment/moment";
class CreateSurvey extends CreateSurveyController {
  constructor(props: Props) {
    super(props);

  }
  render() {
    // @ts-ignore
    const {t} = this.props
    // @ts-ignore
    const language = this.props.i18n.language
    return ( 
      <>
    <GeneralHeader>
        <SurveyComponent t={t} language={language} survey={this}/>
    </GeneralHeader>
     </>
      );
  }
}

export const CreateSurveys = withTranslation()(withRouter(CreateSurvey));

class CECreateSurveys extends CreateSurveyController {
    constructor(props: Props) {
        super(props);

    }
    render() {
        // @ts-ignore
        const {t} = this.props
        // @ts-ignore
        const language = this.props.i18n.language
        return (
            <>
                <MainScreen>
                    <div style={{marginTop:"30px",minHeight:"90vh",marginBottom:"50px"}}>
                        <SurveyComponent t={t} language={language} survey={this}/>
                    </div>
                </MainScreen>
            </>
        );
    }
}

export const CECreateSurvey = withTranslation()(withRouter(CECreateSurveys));

const dashBoard = {
    SideBar: {
        background: "#f9f6f6",
        position:"relative",
        paddingBottom: 150,
    },
}

const SurveyComponent = (props:any) => {
    const {survey,t,language} = props
    return(
        <Container>
            <Box className="navigation createSurveys">
                <Box>
                    <Typography variant="body1" >
                        <Box component="span" className="manageSurveyBreadcrums" onClick={() => pathRedirectManager(survey.props.history,"/Polling","/CompanyEmployee/CEPollSurvey")} style={{cursor:"pointer"}}>
                            {t("Poll and survey")}
                        </Box>{" "}/{" "}
                        <Box component="span" style={{color: "blue"}}>{t("Create a Survey")}</Box>
                    </Typography>
                    <Typography variant="h4" className="subHeading bold-text">{t("Create a Survey")}</Typography>
                </Box>
            </Box>
            <Box style={{marginTop:"10px"}}>

            </Box>
            <form>
                <Grid container spacing={4}>
                    <Grid item sm={12} md={12} xs={12}>
                        <Box className="createPSCards">
                            <TextField label={t("Name of the Survey")} variant="outlined"
                                       className={languageCondition(language,"placeHolderAlignment","")}
                                       name="title"
                                       id="SurveyQuestion"
                                       value={survey.state.SurveyData.title}
                                       onChange={survey.handlePollDataChange}
                                       inputProps={{
                                           maxLength: 100
                                       }}
                                       required fullWidth
                            />
                            <p style={{color:"red"}}>{t(survey.state.pollTitleError)}</p>
                            <Box className="DateSection">
                                <Box style={{width:"100%"}}>
                                    <TextField
                                        label="Start Date" variant="outlined"
                                        style={{width:"100%"}}
                                        className="dateInputBox startDateSurvey"
                                        name="startDate"  fullWidth
                                        placeholder={t("Start Date")}
                                        id="SurveyQuestion"
                                        format='DD/MM/YYYY'
                                        value={survey.state.SurveyData.startDate}
                                        onChange={survey.handlePollDataChange}
                                        inputProps={{
                                            min: moment().format("YYYY-MM-DD"),
                                            //@ts-ignore
                                            max: "5000-05-31",
                                        }}
                                        InputProps={{
                                            // min: "2019-01-24",
                                            //@ts-ignore
                                            max: "5000-05-31",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DateRangeOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        type={survey.state.inputType1}
                                        onFocus={()=> survey.setState({inputType1:"date"})}
                                    />
                                    <p style={{color:"red"}}>{t(survey.state.pollDateError)}</p>
                                </Box>
                                <Box style={{width:"100%"}}>
                                    <TextField label={t("End Date")} variant="outlined"
                                               name="endDate"  fullWidth
                                               style={{width:"100%"}}
                                               placeholder={t("End Date")}
                                               className="dateInputBox endDateSurvey"
                                               id="SurveyQuestion"
                                               value={survey.state.SurveyData.endDate}
                                               onChange={survey.handlePollDataChange}
                                               InputProps={{
                                                   // min: "2019-01-24",
                                                   //@ts-ignore
                                                   max: "5000-05-31",
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <DateRangeOutlinedIcon />
                                                       </InputAdornment>
                                                   )
                                               }}
                                               inputProps={{
                                                   min: survey.state.SurveyData.startDate
                                               }}
                                               type={survey.state.inputType2}
                                               onFocus={()=> survey.setState({inputType2:"date"})}
                                    />
                                    <p style={{color:"red"}}>{t(survey.state.pollEndDateError)}</p>
                                </Box>
                            </Box>
                            <Box className="infoIcon">
                                <Typography variant="subtitle1">{t("Description")}</Typography>
                                <InfoIcon style={{color:"grey", fontSize:18}}/>
                            </Box>
                            <Box className="descriptionEditor" style={{maxHeight:"200px",overflow:"hidden"}}>
                                <TextEditor
                                    // @ts-ignore
                                    markup={survey.state.textEditor}
                                    onChange={survey.onChangeTextEditor} />
                            </Box>
                            <p style={{color:"red"}}>{survey.state.pollDescriptionError}</p>
                            <Box className="targetaudience">
                                <Box className="infoIcon">
                                    <Typography variant="subtitle1">{t("Select your target audience")}</Typography>
                                    <InfoIcon style={{color:"grey", fontSize:18}}/>
                                </Box>
                                <Box className="targetOne">
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <AudienceSelectBox
                                                name={t("OWNERS")}
                                                selected={!!survey.state.selectedAudience.find((check: any) => check === "Owner")}
                                                isMenu={false}
                                                selectAudience={(id:any,name:any)=> survey.selectAudience(id,name)}
                                                audienceId={"Owner"}
                                                manageEdit={(id:any) => survey.handleOpenAudienceModalEditMode(id)}
                                                manageDelete={(id:any) => survey.handleDeleteModal(id)}
                                                t={t}
                                                language={language}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AudienceSelectBox
                                                name={t("RESIDENTS")}
                                                selected={!!survey.state.selectedAudience.find((check: any) => check === "Resident")}
                                                isMenu={false}
                                                selectAudience={(id:any,name:any)=> survey.selectAudience(id,name)}
                                                audienceId={"Resident"}
                                                t={t}
                                                language={language}
                                            />
                                        </Grid>
                                        {
                                            survey.state.audienceList.length > 0 &&
                                            survey.state.audienceList.map((item:any,key:any)=> {
                                                return(
                                                    <Grid item key={key}>
                                                        <AudienceSelectBox
                                                            name={item.attributes.audience_name}
                                                            selected={!!survey.state.selectedAudienceId.find((check: any) => check === item.id)}
                                                            isMenu={true}
                                                            manageEdit={(id:any) => survey.handleOpenAudienceModalEditMode(id)}
                                                            manageDelete={(id:any) => survey.handleDeleteModal(id)}
                                                            selectAudience={(id:any,name:any)=> survey.selectAudience(id,name)}
                                                            audienceId={item.id}
                                                            t={t}
                                                            language={language}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Grid item style={{display:'flex',alignItems:'center'}}>
                                            <Typography variant="subtitle1">{t("Or")}, </Typography>
                                        </Grid>
                                        <Grid item>
                                            <AudienceButton variant="contained" color="primary" onClick={survey.handleOpenAudienceModal}>{t("CREATE AUDIENCE")}</AudienceButton>
                                        </Grid>

                                    </Grid>
                                </Box>
                                <p style={{color:"red"}}>{t(survey.state.audienceValidationError)}</p>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:"40px"}}>

                    </Grid>
                    {
                        survey.state.surveyQuestions.map((item:any,key:any)=>{
                            return(
                                <Grid item sm={12} md={12} xs={12} key={key}>
                                    <Box className="createPSCards">
                                        <FormControl variant="outlined" fullWidth dir={languageCondition(language,"rtl","ltr")}>
                                            <InputLabel id="question-type">{t("Select Type of Question")}</InputLabel>
                                            <Select
                                                labelId="question-type"
                                                id="question-type-select"
                                                className="questionForSurvey"
                                                value={item.question_type}
                                                label="Age"
                                                // @ts-ignore
                                                style={{width:"100%",border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                                                onChange={(e)=> survey.handleQuestionType(key,e)}
                                            >
                                                <MenuItem value="short_answers">{t("Short Answer")}</MenuItem>
                                                <MenuItem value="checkbox">{t("Multiple Choice Questions")}</MenuItem>
                                                <MenuItem value="options">{t("Options")}</MenuItem>
                                            </Select>
                                            <p style={{color:"red"}}>{t(item.question_typeError)}</p>
                                        </FormControl>
                                        <TextField  label={t("enter question")} variant="outlined"
                                                    name="question"
                                                    className={languageCondition(language,"placeHolderAlignment questionText","questionText")}
                                                    inputProps={{
                                                        maxLength: 500
                                                    }}
                                                    id="SurveyQuestion"
                                                    value={item.title}
                                                    onChange={(e)=>survey.handleQuestion(key,e)}
                                                    required fullWidth style={{marginTop:20,borderRadius:"10px"}}
                                        />
                                        <p style={{color:"red"}}>{t(item.questionError)}</p>
                                        {
                                            item.question_type !== "short_answers" && item.survey_options_attributes.map((inputfield:any , index:any) => {
                                                return(
                                                    <Box display='flex' alignItems="center">
                                                        <Box style={{width:"95%"}}>
                                                            <TextField
                                                                className={languageCondition(language,"placeHolderAlignment options1","options1")}
                                                                key={index}
                                                                label={t("Option") + " - " + (index + 1)} variant="outlined"
                                                                name="text"
                                                                id="SurveyQuestionOptions"
                                                                value={inputfield.text}
                                                                onChange={(event) => survey.handleOptionsChange(key,index, event)}
                                                                required fullWidth style={{marginTop:20}}
                                                            />
                                                            <p style={{color:"red"}}>{t(inputfield.error)}</p>
                                                        </Box>
                                                        <Box style={{display:'flex',alignItems:"center",justifyContent:'center'}}>
                                                            <IconButton style={{marginTop:"15px",marginLeft:"10px"}} className="deleteOption" onClick={()=>survey.deleteOption(key,index)}>
                                                                <BackspaceIcon fontSize="large" style={{color:"red"}} className={languageCondition(language,"removeButtonTransformAr","")} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Box display="flex" justifyContent="space-between">
                                            {
                                                item.question_type !== "short_answers" &&
                                                <Button variant="outlined" color="primary"
                                                        onClick={() => survey.addOptionsFields(key)}
                                                        className="addOptions"
                                                >
                                                    {t("ADD OPTION")}
                                                </Button>
                                            }
                                            <Button variant="outlined" color="secondary" className="removeOptions" style={{marginTop:"20px"}} onClick={()=> survey.deleteQuestion(key)}>
                                                {t("Remove Question")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                    <Grid  item sm={12} md={12} xs={12}>
                        {/*@ts-ignore*/}
                        <Button onClick={survey.addQuestionFields} fullWidth size="large" colo="primary" variant="outlined" style={{borderRadius:"8px",border:" 1px dashed #2b6fed",color:"#2b6fed",fontWeight:"bold"}}>+ {t("Add Another Question")}</Button>
                    </Grid>
                    <Grid  item sm={12} md={12} xs={12}>
                        <Box className="BottomButtonSurvey">
                            <Box className="Previewbtn">
                                <AudienceButton onClick={survey.handlePriviewData} variant="contained" color="primary">{t("PREVIEW")}</AudienceButton>
                            </Box>
                            <Box className="Publishbtn">
                                <PublishButton onClick={survey.handleSurveyDataSubmit} disabled={survey.state.loading} type="submit" variant="outlined" color="primary">{survey.state.loading && <CircularProgress color="inherit" size={20}/> } {" "}{t("PUBLISH")}</PublishButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Modal
                aria-labelledby="filterModal"
                aria-describedby="filtermodal"
                className="modalStyle"
                open={survey.state.audienceModal}
                onClose={survey.handleCloseAudienceModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {/*@ts-ignore*/}
                <Fade in={survey.state.audienceModal}>
                    <div>
                        {/*@ts-ignore*/}
                        <AudienceModal handleClose={survey.handleCloseAudienceModal} isEdit={survey.state.isAudienceEdit} />
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modalStyle"
                // @ts-ignore
                open={survey.state.deleteModal}
                onClose={survey.closeDeleteModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {/*@ts-ignore*/}
                <Fade in={survey.state.deleteModal}>
                    <Box style={{width:"30vw",marginTop:'15px',backgroundColor:"white",padding:'40px',borderRadius:"20px",display:"flex",alignItems:"center",flexDirection:"column"}}>
                        <img src={DeleteImage} alt="delete" style={{height:"50px"}} />
                        <Typography variant="h6" className="bold-text" style={{fontSize:"22px",marginTop:"10px"}}>
                            {t("Delete Audience")}
                        </Typography>
                        <Typography variant="h6" style={{color:"black",marginBottom:"10px",fontSize:"16px",marginTop:"10px"}}>
                            Are you sure you want to delete this audience?
                        </Typography>
                        <Box style={{display:'flex',justifyContent:'flex-end',marginTop:"15px"}}>
                            {/*@ts-ignore*/}
                            <AudienceButton variant="outlined" style={{marginRight:"20px",width:"150px"}} onClick={survey.closeDeleteModal}>{t("Cancel")}</AudienceButton>
                            <PublishButton variant="contained" style={{width:"150px"}} onClick={survey.deleteAudience} >{t("Ok")}</PublishButton>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <AlertError show={survey.state.showError} handleClose={()=> survey.setState({showError:false})} message={survey.state.error}/>
            <Loader loading={survey.state.loading}/>
        </Container>
    )
}

const AudienceSelectBox = (props:any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = props
    const {language} = props
    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setAnchorEl(null);
        props.manageEdit(props.audienceId)
    }

    const handleDelete = () => {
        setAnchorEl(null);
        props.manageDelete(props.audienceId)
    }

    return(

        <>
            <Box
                style={
                    props.selected ?
                        {border:"1px solid #2b6fed",borderRadius:"8px",cursor:'pointer'}
                        :
                        {border:"1px solid #f0f0f0",borderRadius:"8px",cursor:'pointer',backgroundColor:"#f9f9f9",minHeight:"24px"}
                }

            >
                <Box style={{padding:"10px 13px",display:"flex",alignItems:"center"}}>
                    <Box style={{display:'flex',alignItems:'center'}} className="selectAudienceBox" onClick={()=>props.selectAudience(props.audienceId,props.name)}>
                        <img
                            src={props.selected ? audienceChecked : audienceCheck}
                            height="10px"
                        />
                        <Typography
                            variant="body2"
                            style={
                                props.selected ?
                                    {minHeight:"24px",fontWeight:"bold",color:"#2b6fed",marginLeft:"5px",marginRight:"5px",fontFamily:"Century Gothic",textTransform:"uppercase"}
                                    :
                                    {minHeight:"24px",fontWeight:"bold",color:"#9a9fa5",marginLeft:"5px",marginRight:"5px",fontFamily:"Century Gothic",textTransform:"uppercase"}
                            }
                        >
                            {props.name}
                        </Typography>
                    </Box>
                    {
                        props.isMenu &&
                        <Box className="openMenu" onClick={handleClick} style={{marginLeft:"8px"}}>
                            <MoreVertIcon style={{color:"#c0c0c0"}} />
                        </Box>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        className="moreMenu"
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem className="editAudience" onClick={handleEdit}>{t("Edit")}</MenuItem>
                        <Divider/>
                        <MenuItem className="deleteAudience" onClick={handleDelete}>{t("Delete")}</MenuItem>
                    </Menu>
                </Box>
            </Box>
        </>
    )
}

const AudienceButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        height:"45px",
        width: "170px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const PublishButton = withStyles((theme) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        fontWeight:"bold",
        height:"45px",
        width: "140px",
        '&:hover': {
            color: "#2b6fef",
        },
    },
}))(Button);
// Customizable Area End
