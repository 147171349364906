// Customizable Area Start
import * as React from "react";
import { Grid, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import MyInvoicesAndReceiptsController, { Props } from "./MyInvoicesAndReceiptsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import ListingComponent from "./LisitingComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class MyInvoicesAndReceipts extends MyInvoicesAndReceiptsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols InvoiceReceipts">
          <Grid container className="invoiceMainGrid fee-payment-top-bar">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}
              className="invoiceSubGrid"
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }} className="invoiceSubGridMainBox">
                <ArrowBackIcon onClick={() => this.props.history.push("/")} className={languageCondition(language, "KeyboardForwardIconAR", "invoicesArrow")}/>
                <p style={{ fontSize: "1.2rem" }} className="invoicesReceiptsHeading bold-text">
                  {t("My Invoices / Receipts")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }} className="invoicesReceiptsSubGridMain">
              <ListingComponent language={language} name="View Invoices" link="/MyInvoices/Owner" label="" />
              <ListingComponent language={language} name="View Receipts" link="/MyReceipts/Owner" label="" />
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(MyInvoicesAndReceipts));

// Customizable Area End
