// Customizable Area Start
import React from "react";
import { Box, Button, Typography, Grid, TextareaAutosize } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SuggestionController, { Props } from "./SuggestionController.web";
import { Book, Clipboard, Layout } from "./assets";
import { Building1 } from "../../ContentManagement/src/assets";
import "./suggestion.css";
import {withTranslation} from "react-i18next";
import {GreyBuildingName} from "../../RequestManagement/src/assets";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class Suggestion extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.getRealtedToData();
    this.getComplexList()
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper owner-suggestion">
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className={languageCondition(language, "arabic-grid auth-cols", "auth-cols")}>
              <Box className="common_content_block content-block" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks" style={{gap:"1rem"}}>
                    <ArrowBackIcon  onClick={() => window.history.back()} />
                    <h4 className="bold-text" style={{ fontSize: "15px" }}>
                      {t("Add New Suggestion")}
                    </h4>
                  </Box>
                </Box>
                <Box
                  className="content-block-wrapper common-incident-block desktop-ui create-reservation-wrapper"
                  style={{ paddingLeft: "20px", paddingRight: "15px" }}
                >
                  <Formik
                    initialValues={{
                      title: "",
                      relatedTo: " ",
                      description: "",
                      selectedComplex:"",
                    }}
                    enableReinitialize
                    validationSchema={this.createSuggtionSchema()}
                    validateOnMount={true}
                    onSubmit={(values: any) => {
                      this.createSuggestion(values);
                    }}
                  >
                    {({ values, touched, errors, isValid, setFieldError, setFieldValue, handleChange }) => (
                      <Form translate="yes" className="commonForm CreateClassifiedFrm">
                        <Box className="formGroup customSelect formInputGrp" style={{marginTop:"20px"}}>
                          <FormControl variant="outlined">
                            <Select
                                name="selectComplex"
                                className="formInput_select_s selectComplexSuggestion"
                                displayEmpty
                                placeholder="Suggestion is related to"
                                startAdornment={
                                  <img src={GreyBuildingName} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                                }
                                onChange={(e) => {
                                  setFieldValue("selectedComplex", e.target.value);
                                }}
                                value={values.selectedComplex}
                            >
                              <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu", "selectComplexSuggestion")}>
                                {t("Select Complex/Building")}
                              </MenuItem>
                              {this.state.complexList.map((item: any) => (
                                  <MenuItem key={item.id} value={item.id} className={languageCondition(language, "select-arabic-menu", "selectComplexSuggestion")}>{item.name}</MenuItem>
                              ))}
                            </Select>
                            {/*@ts-ignore*/}
                            <ErrorMessage className="text-error" component="Typography" name="selectedComplex" style={{marginLeft:"25px"}} />
                          </FormControl>
                        </Box>
                        <Box className="formGroup">
                          <Box className="formInputGrp">
                            <Field className={languageCondition(language, "formInput_ar", "formInput SuggestionTitle")} name="title" placeholder={t("Suggestion Title")} />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons SuggestionTitle")} style={{ top: "20px" }}>
                              <img src={Book} />
                            </span>
                          </Box>
                          {errors.title && touched.title ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10,
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="title" />
                            </Typography>
                          ) : null}

                          <Box className="formGroup customSelect formInputGrp">
                            <FormControl variant="outlined">
                              <Select
                                name="relatedTo"
                                className="formInput_select_s relatedSuggestionSelect"
                                placeholder="Suggestion is related to"
                                onChange={(e) => {
                                  setFieldValue("relatedTo", e.target.value);
                                }}
                                startAdornment={
                                  <img src={Layout} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                                }
                                value={values.relatedTo}
                              >
                                <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu", "relatedSuggestionSelect")}>
                                  {t("Suggestion is related to")}
                                </MenuItem>
                                {this.state.realtedData.map((item: any) => (
                                  <MenuItem key={item.attributes.id} value={item.attributes.id} className={languageCondition(language, "select-arabic-menu", "relatedSuggestionSelect")}>{item.attributes.related_to}</MenuItem>
                                ))}
                              </Select>
                              {/*@ts-ignore*/}
                              <ErrorMessage className="text-error" component="Typography" name="relatedTo" style={{marginLeft:"25px"}} />
                            </FormControl>
                          </Box>

                          <Box className="formInputGrp">
                            <TextareaAutosize
                              onChange={(e: any) => {
                                setFieldValue("description", e.target.value);
                              }}
                              value={values.description}
                              className={languageCondition(language, "formInput_ar incident-text-area suggestionText", "formInput incident-text-area suggestionText")}
                              name="description"
                              placeholder={t("Add Description")}
                              style={{ fontWeight: "normal", fontSize: "16px", borderRadius: "10px" }}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons SuggestionTitle")}>
                              <img src={Clipboard} style={{ top: "1rem" }} />
                            </span>
                          </Box>
                          {errors.description && touched.description ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10,
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="description" />
                            </Typography>
                          ) : null}
                          <Box className="customButton new-suggestion-create-btn" style={{ marginTop: "29rem" }}>
                            <Button variant="contained" type="submit" style={{ fontSize: "16px" }}>
                              {t("Submit")}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(Suggestion));
// Customizable Area End
