// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Button,
  IconButton,
  Select,
  MenuItem,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  InputBase,
  Box,
  Grid,
} from "@material-ui/core";
import ManagementFeeReportController, { Props } from "./ManagementFeeReportController.web";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebarWeb from "../../dashboard/src/ChairmanSidebar.web";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//@ts-ignore
import { withTranslation } from "react-i18next";
import { ROLE } from "../../../framework/src/Enum";
import { ReportsStyleWeb } from "./ReportsStyle.web";
import { SearchIconImage } from "./assets";
import PaginationModule from "./PaginationModule.web";
import { withRouter } from "react-router-dom";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class ManagementFeeReport extends ManagementFeeReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container className={classes.reportList}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Documents & Reports")} /
                      <Box
                        className="report-ManagementFeeMain"
                        component="span"
                        id="BacktoManagementfeeRouting"
                        onClick={() => this.props.history.push("/Reports")}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {t("Reports")}
                      </Box>{" "}
                      /{" "}
                      <Box className="report-ManagementFeeMain" component="span" style={{ color: "blue" }}>
                        {t("Management Fee Reports")}
                      </Box>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="sub-heading bold-text report-ManagementFeeMain"
                      style={{ fontSize: "28px" }}
                    >
                      {t("Management Fee Reports")}
                    </Typography>
                  </Box>
                </Box>
                <Box className={languageCondition(language, "arabic-grid top-bar", "top-bar ManagementFees")}>
                  <Box className="filter">
                    {this.state.userType === ROLE.MANAGER && (
                      <Select
                        displayEmpty
                        value={this.state.buildingId}
                        className="select-input"
                        id="ManagementFeeBulidngSelect"
                        onChange={(e: any) => this.setState({ buildingId: e.target.value,filterYear:"",filterMonth:"",status:"" })}
                      >
                        <MenuItem value="" disabled id="selectbuildingManagementFeesReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Building")}</MenuItem>
                        <MenuItem value=" " id="selectbuildingManagementFeesReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                        {this.state.buildingList.length > 0 &&
                          this.state.buildingList.map((item: any, key: any) => {
                            return (
                              <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                    <Select
                      displayEmpty
                      className="select-input"
                      value={this.state.filterYear}
                      id={"ManagementReportYearListSelect"}
                      onChange={(e: any) => this.setState({ filterYear: e.target.value ,filterMonth:"",status:""})}
                    >
                      <MenuItem value="" disabled id="selectYearManagementFeesReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Year")}</MenuItem>
                      <MenuItem value=" " id="selectYearManagementFeesReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 3} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 3}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 2} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 2}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 1} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 1}</MenuItem>
                      <MenuItem value={new Date().getFullYear()} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear()}</MenuItem>
                    </Select>
                    <Select
                      displayEmpty
                      className="select-input"
                      value={this.state.filterMonth}
                      id="ManagementReportMonthListSelect"
                      onChange={(e: any) => this.setState({ filterMonth: e.target.value,status:"" })}
                    >
                      <MenuItem value="" disabled id="selectMonthManagementFeesReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Month")}</MenuItem>
                      <MenuItem value=" " id="selectMonthManagementFeesReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      {month.map((item, key) => {
                        return (
                          <MenuItem key={key} value={key + 1} className={languageCondition(language, "select-arabic-menu", "")}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      displayEmpty
                      className="select-input"
                      value={this.state.status}
                      id="ManagementReportStatusListSelect"
                      onChange={(e: any) => this.setState({ status: e.target.value })}
                    >
                      <MenuItem value="" disabled id="selectStatusManagementFeesReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Status")}</MenuItem>
                      <MenuItem value=" " id="selectStatusManagementFeesReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      <MenuItem value="due" className={languageCondition(language, "select-arabic-menu", "")}>{t("Due")}</MenuItem>
                      <MenuItem value="over_due" className={languageCondition(language, "select-arabic-menu", "")}>{t("Over Due")}</MenuItem>
                      <MenuItem value="paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Paid")}</MenuItem>
                      <MenuItem value="partially_paid" className={languageCondition(language, "select-arabic-menu", "")}>{t("Partially Paid")}</MenuItem>
                    </Select>
                    <Button id="managementFeeSearchButton" startIcon={<img src={SearchIconImage} />} onClick={this.searchButtonManage}>
                      {t("Search")}
                    </Button>
                  </Box>
                </Box>
                <Grid className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className="bold-text report-ExpenseReport">{t("Management Fee Reports")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search by unit number")}
                          className="search"
                          id="SearchManagementFeeReport"
                          value={this.state.searchUnit}
                          onChange={this.manageUnitSearch}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeeMain1"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("#")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeeMainUnit"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Unit Number")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeePaid"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Paid On")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeePaidBy"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Paid By")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeeMonth"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Month & Year")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ManagementFeeAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Amount")}
                          </TableCell>
                          <TableCell className="bold-text report-ManagementStatus" style={{ color: "#181d25", fontSize: "14px" }} align={languageCondition(language,"right","left")}>
                            {t("Status")}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.feeListing?.length > 0 ? (
                          this.state.feeListing.map((item: any, key: any) => {
                            return (
                              <TableRow key={key}>
                                <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item.attributes.unit_number}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item.attributes.paid_on}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item.attributes.paid_by}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item.attributes.Month_year}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{languageCondition(language,item.attributes?.currency?.currency_ar,item.attributes?.currency?.currency)} {item.attributes.amount.toLocaleString()}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>
                                  {
                                    item.attributes.status === "due" &&
                                      <Typography className="pending" id="managementFeeDue" style={{width:"120px",fontSize:"12px",textAlign:"center",padding:"5px",borderRadius:"50px"}}>{t(item.attributes.status)}</Typography>
                                  }
                                  {
                                      item.attributes.status === "overdue" &&
                                      <Typography className="cancelled" id="managementFeeOverDue" style={{width:"120px",fontSize:"12px",textAlign:"center",padding:"5px",borderRadius:"50px"}}>{t(item.attributes.status)}</Typography>
                                  }
                                  {
                                      item.attributes.status === "paid" &&
                                      <Typography className="approved" id="managementFeePaid" style={{width:"120px",fontSize:"12px",textAlign:"center",padding:"5px",borderRadius:"50px"}}>{t(item.attributes.status)}</Typography>
                                  }
                                  {
                                      item.attributes.status === "partially_paid" &&
                                      <Typography className="approved" id="managementFeePartiallYPaid" style={{width:"120px",fontSize:"12px",textAlign:"center",padding:"5px",borderRadius:"50px"}}>{t("Partially Paid")}</Typography>
                                  }
                                </TableCell>
                                <TableCell className="invoice-receipts-menu" align={languageCondition(language,"right","left")}>
                                  <Menu
                                    menuButton={
                                      <IconButton>
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  >
                                    <MenuItem onClick={() => this.manageDownload(item.id)}>{t("Download")}</MenuItem>
                                    <MenuItem onClick={() => this.manageShare(item.id)}>{t("Share")}</MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8}>{t("No Management Fee Reports Available")}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <PaginationModule
                        handlePagination={this.handleManagementFeePagination}
                        pagination={this.state.pagination}
                        page={this.state.page}
                      />
                    </Box>
                  </Grid>
                </Grid>
            </Container>
            <ShareDocumentModal
                isOpen={this.state.isShareModalOpen}
                handleClose={this.handleShareModalManagementFees}
                heading={t("Share")}
                documentURL={this.state.shareUrl}
            />
        </GeneralHeader>
      </>
    );
  }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ManagementFeeReport)));
// Customizable Area End
