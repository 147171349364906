// Customizable Area Start
import React from "react";
import { Box, Container, withStyles, Typography, Button, Divider, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEScheduleMeetingController, { Props } from "./CEScheduleMeetingController.web";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import moment from "moment";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { AcceptIcon, AwaitIcon, CommentIcon, RejectIcon } from "../assets";
import CEScheduleMeetingDialogWeb from "./CEScheduleMeetingDialog.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

class CEScheduleMeetingDetails extends CEScheduleMeetingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const meeting_id = this.props.navigation.getParam("id");
    this.setState({ scheduleMeetingId: meeting_id }, () => {
      this.getMeetingDetailsById();
      this.getMeetingDetailResponseList();
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.responsePage !== this.state.responsePage) {
      await this.getMeetingDetailResponseList();
    }
  }

  handleDateDetail = (date: any) => {
    if (date) {
      return moment(date, "DD-MM-YYYY HH:mm", true).format("MMMM DD, YYYY HH:mm");
    }
  };

  handleButtonLabel = (t: any) => {
    return this.state.meetingNote.note ? `${t("Edit Meeting Minutes")}` : `${t("Add Meeting Minutes")}`;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />

        <Container className={classes.scheduledMeetingDetails}>
          <Box className="navigation">
            <Box>
              <Typography variant="body1">
                <Link to={"/CompanyEmployee/CEScheduleMeeting"}>
                  {t("Activities")} / {t("Scheduled Meetings")} /{" "}
                </Link>
                <Box component="span" style={{ color: "blue" }}>
                  {t("Scheduled Meetings")}
                </Box>
              </Typography>
            </Box>
            <Box className="sub-heading" style={{ margin: "10px 0 0" }}>
              <Typography variant="h5" className="sub-heading bold-text" style={{ margin: "0" }}>
                {t("Meeting Details")}
              </Typography>
              {this.state.meetingDetails.status === "completed" && (
                <CommonButton
                  onClick={() => this.handleAddMeetingMinute()}
                  t={t}
                  label={this.handleButtonLabel(t)}
                  color="blue"
                  style={{ padding: "10px", minWidth: "200px" }}
                />
              )}
            </Box>
          </Box>

          <Box className="meeting-detail-box">
            <Box className="meeting-top">
              <Box className="heading">
                <h4 className="bold-text">{this.state.meetingDetails.title}</h4>
                {this.state.meetingDetails.type === "ga_meeting" && <span className="ga-meeting">{t("GA Meeting")}</span>}
              </Box>
              <span className={this.state.meetingDetails.status}>{t(this.state.meetingDetails.status)}</span>
            </Box>
            <Divider />
            <Box className="meeting-details">
              <h4 className="bold-text">{t("Meeting Details")}</h4>
              <Box className="items">
                <span>{t("Date & Time")}: </span>
                <p className="bold-text">{this.handleDateDetail(this.state.meetingDetails.dateTime)}</p>
              </Box>
              <Box className="items">
                <span>{t("Place")}: </span>
                <p className="bold-text">{this.state.meetingDetails.place}</p>
              </Box>
              <Box className="items">
                <span>{t("Complex")}: </span>
                <p className="bold-text">{this.state.meetingDetails.complex}</p>
              </Box>
              <Box className="items">
                <span>{t("Building")}: </span>
                <p className="bold-text">{this.state.meetingDetails.building || "All"}</p>
              </Box>
              <Box className="items">
                <span>{t("Agenda")}: </span>
                <p className="bold-text">{this.state.meetingDetails.agenda}</p>
              </Box>
            </Box>
            <Box className="meeting-details">
              <h4 className="bold-text">{t("Scheduling Details")}</h4>
              <Box className="items">
                <span>{t("Scheduled By")}: </span>
                <p className="bold-text">{this.state.meetingDetails.scheduleBy}</p>
              </Box>
              <Box className="items">
                <span>{t("Scheduled On")}: </span>
                <p className="bold-text">{this.handleDateDetail(this.state.meetingDetails.scheduleOn)}</p>
              </Box>
            </Box>
            {this.state.meetingDetails.status === "cancelled" && (
              <Box className="meeting-details">
                <h4 className="bold-text">{t("Cancelled Details")}</h4>
                <Box className="items">
                  <span>{t("Cancelled By")}: </span>
                  <p className="bold-text">{this.state.meetingDetails.cancelBy}</p>
                </Box>
                <Box className="items">
                  <span>{t("Cancelled On")}: </span>
                  <p className="bold-text">{this.handleDateDetail(this.state.meetingDetails.cancelOn)}</p>
                </Box>
              </Box>
            )}
          </Box>

          <Box className="response-box" style={{ marginBottom: "50px" }}>
            {this.state.meetingDetails.status === "scheduled" ? (
              <Box className="heading">
                <h3 className="bold-text">{t("Meeting Attendees")}</h3>
                <Box className="status">
                  <div className="item">
                    <img src={AwaitIcon} />
                    <p>
                      {t("Awaiting")} <span>{this.state.meetingDetails.count.await}</span>
                    </p>
                  </div>
                  <div className="item">
                    <img src={AcceptIcon} />
                    <p>
                      {t("Accepted")} <span>{this.state.meetingDetails.count.accept}</span>
                    </p>
                  </div>
                  <div className="item">
                    <img src={RejectIcon} />
                    <p>
                      {t("Rejected")} <span>{this.state.meetingDetails.count.reject}</span>
                    </p>
                  </div>
                </Box>
              </Box>
            ) : (
              <Box className="heading" style={{ margin: 0 }}>
                <h3 className="bold-text">{t("Meeting Attendees")}</h3>
              </Box>
            )}
            <Table className="table-box">
              <TableHead>
                <TableRow>
                  <TableCell align={languageCondition(language, "right", "left")} className="ce-detail-response bold-text">
                    {t("Name")}
                  </TableCell>
                  <TableCell className="ce-detail-response bold-text" align={languageCondition(language, "right", "left")}>
                    {t("Building")}
                  </TableCell>
                  <TableCell className="ce-detail-response bold-text" align={languageCondition(language, "right", "left")}>
                    {t("Unit No.")}
                  </TableCell>
                  <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                    {t("Floor Number")}
                  </TableCell>
                  {this.state.meetingDetails.status !== "cancelled" && (
                    <TableCell className="ce-detail-response bold-text" align={languageCondition(language, "right", "left")}>
                      {t("Response")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.meetingResponseList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>{t("No User Available!!")}</TableCell>
                  </TableRow>
                )}
                {this.state.meetingResponseList.map((resp: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align={languageCondition(language, "right", "left")}>{resp.attributes.name}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{resp.attributes.building_name}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{resp.attributes.unit_number}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{resp.attributes.floor_number}</TableCell>
                      {this.state.meetingDetails.status !== "cancelled" && (
                        <TableCell align={languageCondition(language, "right", "left")}>
                          {this.state.meetingDetails.status === "completed" && resp.attributes.response === "awaited" ? (
                            <span className="no-response">No Response</span>
                          ) : (
                            <span className={resp.attributes.response}>{resp.attributes.response}</span>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box className="table-bottom">
              <p>
                {t("Showing")} <span className="current-page">{this.state.meetingResponseList.length}</span> {t("of")}{" "}
                <span className="total-page">{this.state.meetingResponsePagination ? this.state.meetingResponsePagination.total_count : 0}</span>{" "}
                {t("results")}
              </p>
              {this.state.meetingResponsePagination && (
                <Pagination
                  onChange={this.handleResponsePagination}
                  count={this.state.meetingResponsePagination.total_pages}
                  page={this.state.meetingResponsePagination.current_page}
                  siblingCount={2}
                  variant="outlined"
                  shape="rounded"
                />
              )}
            </Box>
          </Box>

          <Box className="button-box">
            {this.state.meetingDetails.status === "scheduled" && (
              <Button className="cancel" onClick={this.handleCancelMeetingDialog}>
                {t("Cancel Meeting")}
              </Button>
            )}
            {this.state.meetingDetails.status !== "completed" && (
              <Button className="edit" onClick={() => this.handleOpenEditForm(this.state.meeting)}>
                {t("Edit Meeting")}
              </Button>
            )}
          </Box>
        </Container>

        <AlertDialog
          IsOpen={this.state.isCancelMeetingDialogOpen}
          Image={CommentIcon}
          CloseDialog={this.handleCancelMeetingDialog}
          Header={t("Complete Meeting Confirmation")}
          Content={`<p>${t("Are you sure want to complete the meeting scheduled on")} <br/> ${t(
            moment(this.state.meetingDetails.scheduleOn, "DD-MM-YYYY HH:mm", true).format("MMMM DD, YYYY HH:mm")
          )} ${t("at")} ${t(this.state.meetingDetails.place)}${t(
            "? Once cancelled, attendees will receive a meeting cancelation notification."
          )}</p>`}
          DeclineText={t("No, Don't Cancel")}
          AcceptText={t("Yes, Cancel")}
          DeclineFun={this.handleCancelMeetingDialog}
          AcceptFun={this.handleCancelMeeting}
        />

        <CEScheduleMeetingDialogWeb
          {...this.state}
          {...this.props}
          onSubmitScheduleList={this.getScheduleMeetingList}
          onSubmitScheduleDetails={this.getMeetingDetailsById}
          page={this.state.page}
          // Dialog
          onFormClose={this.handleMeetingDialog}
          isOpenForm={this.state.isFormDialogOpen}
          // Add-Edit
          isEditFormOpen={this.state.isEditFormOpen}
          // Form value
          formValue={this.state.formValue}
          // Id
          meetingId={this.state.scheduleMeetingId}
        />
      </CEHeaderComponentWeb>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEScheduleMeetingDetails)));
// Customizable Area End
