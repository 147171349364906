// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Card,
  CardMedia,
  Button,
  withStyles,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Grid
} from "@material-ui/core";
import "../../dashboard/src/Dashboard.web.css";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import CommunityUserProfileController, { Props } from "./communityManagementController.web";
import Loader from "../../../components/src/Loader.web";
import { CommunityStyle } from "./CommunityStyle.web";
import { true_mark, x_mark } from "./assets";
import { NoUserIcon } from "../../LeadManagement/src/assets";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class PendingRequest extends CommunityUserProfileController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getRequest();
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.communityDashboard}>
                <Box className="navigation-chairman">
                  <Box className="admin-pending-head-box">
                    <Typography variant="body1">
                      {t("Community Management")} /{" "}
                      <Link href="/CommunityRequestManagement">{t("Requests Management")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Pending Join Requests")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading-box admin-pending-heading-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Pending Join Requests")}
                    </Typography>
                  </Box>
                </Box>

                <Grid container spacing={2} style={{ marginBottom: 30 }}>
                  {this.state.allInvitation.map((item: any, index: any) => {
                    return (
                      <Grid item sm={6} md={4} lg={3} key={item.id}>
                        <Card className="user-details">
                          <CardMedia
                            component="img"
                            height="140"
                            image={NoUserIcon}
                            alt="image"
                            style={dashBoard.profileImage}
                          />
                          <Box className="building-unit-box">
                            <h4 className="bold-text">{item?.attributes?.building_management?.name || 'N/A'}</h4>
                            <h4 className="bold-text">{item?.attributes?.unit_number || 'N/A'}</h4>
                          </Box>
                          <p>{item.attributes.name}</p>
                          <Box className="roles-box">
                            <span className="role">{item.attributes.role}</span>
                          </Box>
                          <p>{item.attributes?.phone}</p>
                          <p>{item.attributes?.email}</p>
                          <Grid container spacing={3} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={6}>
                              <Button
                                variant="outlined"
                                style={{
                                  width: "100%",
                                  color: "#2B6FED",
                                  border: "1px solid #2B6FED",
                                  fontWeight: 600,
                                }}
                                onClick={() => this.handleRejectOpen(item)}
                              >
                                {t("Decline")}
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ width: "100%", backgroundColor: "#2B6FED", fontWeight: 600 }}
                                onClick={() => this.handleAcceptOpen(item)}
                              >
                                {t("Accept")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
        </GeneralHeaderComponentWeb>

        <Dialog
          className="delete-document delete-document-dialog"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="sm"
          onClose={() => this.handleRejectClose()}
          open={this.state.setRejectOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={x_mark} alt="delete" />
              <Typography variant="h6" className="bold-text">
                {t("Reject Join Request")}
              </Typography>
              <Typography variant="body1">{t("Are you sure want to reject invitation request received from")} <b>{this.state?.selectInvitation?.attributes?.name || 'N/A'}</b> {t("for Unit")} <b>{this.state.selectInvitation?.attributes?.unit_number}</b>.</Typography>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button className="cancel-button" onClick={() => this.handleRejectClose()} style={{ height: "50px" }}>
                  {t("Close")}
                </Button>
                <Button
                  style={{ height: "50px", margin: "0" }}
                  className="add-button"
                  onClick={() => {
                    this.rejectInvitation(this.state.selectInvitation?.id);
                  }}
                >
                  {t("Yes, Reject")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          className="delete-document delete-document-dialog"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="sm"
          onClose={() => this.handleAcceptClose()}
          open={this.state.setAcceptOpen}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={true_mark} alt="delete" />
              <Typography variant="h6" className="bold-text">
                {t("Accept Join Request")}
              </Typography>
              <Typography variant="body1">{t("Are you sure want to accept the join request received from")}  <b>{this.state.selectInvitation?.attributes?.name || "N/a"}</b> {t("for Unit")} <b>{this.state?.selectInvitation?.attributes?.unit_number}</b></Typography>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button className="cancel-button" onClick={() => this.handleAcceptClose()} style={{ height: "50px" }}>
                  {t("Close")}
                </Button>
                <Button
                  style={{ height: "50px", margin: "0" }}
                  className="add-button"
                  onClick={() => {
                    this.acceptInvitation(this.state.selectInvitation?.id);
                  }}
                >
                  {t("Yes, Accept")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

//@ts-ignore
export default withTranslation()(withStyles(CommunityStyle)(withRouter(PendingRequest)));

const dashBoard = {
  profileImage: {
    borderRadius: "100%",
    width: 70,
    height: 70,
    border:"1px solid #f0f0f0"
  },
};

// Customizable Area End
