export const dailCode = [{
    code: "CA",
    dial_code: "+1",
    flag: "🇨🇦",
    name: "Canada"
  }, {
    code: "US",
    dial_code: "+1",
    flag: "🇺🇸",
    name: "United States"
  }, {
    code: "BS",
    dial_code: "+1242",
    flag: "🇧🇸",
    name: "Bahamas"
  }, {
    code: "BB",
    dial_code: "+1246",
    flag: "🇧🇧",
    name: "Barbados"
  }, {
    code: "AI",
    dial_code: "+1264",
    flag: "🇦🇮",
    name: "Anguilla"
  }, {
    code: "AG",
    dial_code: "+1268",
    flag: "🇦🇬",
    name: "Antigua and Barbuda"
  }, {
    code: "VG",
    dial_code: "+1284",
    flag: "🇻🇬",
    name: "Virgin Islands, British"
  }, {
    code: "VI",
    dial_code: "+1340",
    flag: "🇻🇮",
    name: "Virgin Islands, U.S."
  }, {
    code: "BM",
    dial_code: "+1441",
    flag: "🇧🇲",
    name: "Bermuda"
  }, {
    code: "GD",
    dial_code: "+1473",
    flag: "🇬🇩",
    name: "Grenada"
  }, {
    code: "TC",
    dial_code: "+1649",
    flag: "🇹🇨",
    name: "Turks and Caicos Islands"
  }, {
    code: "MS",
    dial_code: "+1664",
    flag: "🇲🇸",
    name: "Montserrat"
  }, {
    code: "MP",
    dial_code: "+1670",
    flag: "🇲🇵",
    name: "Northern Mariana Islands"
  }, {
    code: "GU",
    dial_code: "+1671",
    flag: "🇬🇺",
    name: "Guam"
  }, {
    code: "AS",
    dial_code: "+1684",
    flag: "🇦🇸",
    name: "American Samoa"
  }, {
    code: "LC",
    dial_code: "+1758",
    flag: "🇱🇨",
    name: "Saint Lucia"
  }, {
    code: "DM",
    dial_code: "+1767",
    flag: "🇩🇲",
    name: "Dominica"
  }, {
    code: "VC",
    dial_code: "+1784",
    flag: "🇻🇨",
    name: "Saint Vincent and the Grenadines"
  }, {
    code: "DO",
    dial_code: "+1849",
    flag: "🇩🇴",
    name: "Dominican Republic"
  }, {
    code: "TT",
    dial_code: "+1868",
    flag: "🇹🇹",
    name: "Trinidad and Tobago"
  }, {
    code: "KN",
    dial_code: "+1869",
    flag: "🇰🇳",
    name: "Saint Kitts and Nevis"
  }, {
    code: "JM",
    dial_code: "+1876",
    flag: "🇯🇲",
    name: "Jamaica"
  }, {
    code: "PR",
    dial_code: "+1939",
    flag: "🇵🇷",
    name: "Puerto Rico"
  }, {
    code: "EG",
    dial_code: "+20",
    flag: "🇪🇬",
    name: "Egypt"
  }, {
    code: "SS",
    dial_code: "+211",
    flag: "🇸🇸",
    name: "South Sudan"
  }, {
    code: "MA",
    dial_code: "+212",
    flag: "🇲🇦",
    name: "Morocco"
  }, {
    code: "DZ",
    dial_code: "+213",
    flag: "🇩🇿",
    name: "Algeria"
  }, {
    code: "TN",
    dial_code: "+216",
    flag: "🇹🇳",
    name: "Tunisia"
  }, {
    code: "LY",
    dial_code: "+218",
    flag: "🇱🇾",
    name: "Libyan Arab Jamahiriya"
  }, {
    code: "GM",
    dial_code: "+220",
    flag: "🇬🇲",
    name: "Gambia"
  }, {
    code: "SN",
    dial_code: "+221",
    flag: "🇸🇳",
    name: "Senegal"
  }, {
    code: "MR",
    dial_code: "+222",
    flag: "🇲🇷",
    name: "Mauritania"
  }, {
    code: "ML",
    dial_code: "+223",
    flag: "🇲🇱",
    name: "Mali"
  }, {
    code: "GN",
    dial_code: "+224",
    flag: "🇬🇳",
    name: "Guinea"
  }, {
    code: "CI",
    dial_code: "+225",
    flag: "🇨🇮",
    name: "Cote d'Ivoire"
  }, {
    code: "BF",
    dial_code: "+226",
    flag: "🇧🇫",
    name: "Burkina Faso"
  }, {
    code: "NE",
    dial_code: "+227",
    flag: "🇳🇪",
    name: "Niger"
  }, {
    code: "TG",
    dial_code: "+228",
    flag: "🇹🇬",
    name: "Togo"
  }, {
    code: "BJ",
    dial_code: "+229",
    flag: "🇧🇯",
    name: "Benin"
  }, {
    code: "MU",
    dial_code: "+230",
    flag: "🇲🇺",
    name: "Mauritius"
  }, {
    code: "LR",
    dial_code: "+231",
    flag: "🇱🇷",
    name: "Liberia"
  }, {
    code: "SL",
    dial_code: "+232",
    flag: "🇸🇱",
    name: "Sierra Leone"
  }, {
    code: "GH",
    dial_code: "+233",
    flag: "🇬🇭",
    name: "Ghana"
  }, {
    code: "NG",
    dial_code: "+234",
    flag: "🇳🇬",
    name: "Nigeria"
  }, {
    code: "TD",
    dial_code: "+235",
    flag: "🇹🇩",
    name: "Chad"
  }, {
    code: "CF",
    dial_code: "+236",
    flag: "🇨🇫",
    name: "Central African Republic"
  }, {
    code: "CM",
    dial_code: "+237",
    flag: "🇨🇲",
    name: "Cameroon"
  }, {
    code: "CV",
    dial_code: "+238",
    flag: "🇨🇻",
    name: "Cape Verde"
  }, {
    code: "ST",
    dial_code: "+239",
    flag: "🇸🇹",
    name: "Sao Tome and Principe"
  }, {
    code: "GQ",
    dial_code: "+240",
    flag: "🇬🇶",
    name: "Equatorial Guinea"
  }, {
    code: "GA",
    dial_code: "+241",
    flag: "🇬🇦",
    name: "Gabon"
  }, {
    code: "CG",
    dial_code: "+242",
    flag: "🇨🇬",
    name: "Congo"
  }, {
    code: "CD",
    dial_code: "+243",
    flag: "🇨🇩",
    name: "Congo, The Democratic Republic of the Congo"
  }, {
    code: "AO",
    dial_code: "+244",
    flag: "🇦🇴",
    name: "Angola"
  }, {
    code: "GW",
    dial_code: "+245",
    flag: "🇬🇼",
    name: "Guinea-Bissau"
  }, {
    code: "IO",
    dial_code: "+246",
    flag: "🇮🇴",
    name: "British Indian Ocean Territory"
  }, {
    code: "SC",
    dial_code: "+248",
    flag: "🇸🇨",
    name: "Seychelles"
  }, {
    code: "SD",
    dial_code: "+249",
    flag: "🇸🇩",
    name: "Sudan"
  }, {
    code: "RW",
    dial_code: "+250",
    flag: "🇷🇼",
    name: "Rwanda"
  }, {
    code: "ET",
    dial_code: "+251",
    flag: "🇪🇹",
    name: "Ethiopia"
  }, {
    code: "SO",
    dial_code: "+252",
    flag: "🇸🇴",
    name: "Somalia"
  }, {
    code: "DJ",
    dial_code: "+253",
    flag: "🇩🇯",
    name: "Djibouti"
  }, {
    code: "KE",
    dial_code: "+254",
    flag: "🇰🇪",
    name: "Kenya"
  }, {
    code: "TZ",
    dial_code: "+255",
    flag: "🇹🇿",
    name: "Tanzania, United Republic of Tanzania"
  }, {
    code: "UG",
    dial_code: "+256",
    flag: "🇺🇬",
    name: "Uganda"
  }, {
    code: "BI",
    dial_code: "+257",
    flag: "🇧🇮",
    name: "Burundi"
  }, {
    code: "MZ",
    dial_code: "+258",
    flag: "🇲🇿",
    name: "Mozambique"
  }, {
    code: "ZM",
    dial_code: "+260",
    flag: "🇿🇲",
    name: "Zambia"
  }, {
    code: "MG",
    dial_code: "+261",
    flag: "🇲🇬",
    name: "Madagascar"
  }, {
    code: "TF",
    dial_code: "+262",
    flag: "🇹🇫",
    name: "French Southern Territories"
  }, {
    code: "YT",
    dial_code: "+262",
    flag: "🇾🇹",
    name: "Mayotte"
  }, {
    code: "RE",
    dial_code: "+262",
    flag: "🇷🇪",
    name: "Reunion"
  }, {
    code: "ZW",
    dial_code: "+263",
    flag: "🇿🇼",
    name: "Zimbabwe"
  }, {
    code: "NA",
    dial_code: "+264",
    flag: "🇳🇦",
    name: "Namibia"
  }, {
    code: "MW",
    dial_code: "+265",
    flag: "🇲🇼",
    name: "Malawi"
  }, {
    code: "LS",
    dial_code: "+266",
    flag: "🇱🇸",
    name: "Lesotho"
  }, {
    code: "BW",
    dial_code: "+267",
    flag: "🇧🇼",
    name: "Botswana"
  }, {
    code: "SZ",
    dial_code: "+268",
    flag: "🇸🇿",
    name: "Eswatini"
  }, {
    code: "KM",
    dial_code: "+269",
    flag: "🇰🇲",
    name: "Comoros"
  }, {
    code: "ZA",
    dial_code: "+27",
    flag: "🇿🇦",
    name: "South Africa"
  }, {
    code: "SH",
    dial_code: "+290",
    flag: "🇸🇭",
    name: "Saint Helena, Ascension and Tristan Da Cunha"
  }, {
    code: "ER",
    dial_code: "+291",
    flag: "🇪🇷",
    name: "Eritrea"
  }, {
    code: "AW",
    dial_code: "+297",
    flag: "🇦🇼",
    name: "Aruba"
  }, {
    code: "FO",
    dial_code: "+298",
    flag: "🇫🇴",
    name: "Faroe Islands"
  }, {
    code: "GL",
    dial_code: "+299",
    flag: "🇬🇱",
    name: "Greenland"
  }, {
    code: "GR",
    dial_code: "+30",
    flag: "🇬🇷",
    name: "Greece"
  }, {
    code: "NL",
    dial_code: "+31",
    flag: "🇳🇱",
    name: "Netherlands"
  }, {
    code: "BE",
    dial_code: "+32",
    flag: "🇧🇪",
    name: "Belgium"
  }, {
    code: "FR",
    dial_code: "+33",
    flag: "🇫🇷",
    name: "France"
  }, {
    code: "ES",
    dial_code: "+34",
    flag: "🇪🇸",
    name: "Spain"
  }, {
    code: "KY",
    dial_code: "+345",
    flag: "🇰🇾",
    name: "Cayman Islands"
  }, {
    code: "GI",
    dial_code: "+350",
    flag: "🇬🇮",
    name: "Gibraltar"
  }, {
    code: "PT",
    dial_code: "+351",
    flag: "🇵🇹",
    name: "Portugal"
  }, {
    code: "LU",
    dial_code: "+352",
    flag: "🇱🇺",
    name: "Luxembourg"
  }, {
    code: "IE",
    dial_code: "+353",
    flag: "🇮🇪",
    name: "Ireland"
  }, {
    code: "IS",
    dial_code: "+354",
    flag: "🇮🇸",
    name: "Iceland"
  }, {
    code: "AL",
    dial_code: "+355",
    flag: "🇦🇱",
    name: "Albania"
  }, {
    code: "MT",
    dial_code: "+356",
    flag: "🇲🇹",
    name: "Malta"
  }, {
    code: "CY",
    dial_code: "+357",
    flag: "🇨🇾",
    name: "Cyprus"
  }, {
    code: "AX",
    dial_code: "+358",
    flag: "🇦🇽",
    name: "Åland Islands"
  }, {
    code: "FI",
    dial_code: "+358",
    flag: "🇫🇮",
    name: "Finland"
  }, {
    code: "BG",
    dial_code: "+359",
    flag: "🇧🇬",
    name: "Bulgaria"
  }, {
    code: "HU",
    dial_code: "+36",
    flag: "🇭🇺",
    name: "Hungary"
  }, {
    code: "LT",
    dial_code: "+370",
    flag: "🇱🇹",
    name: "Lithuania"
  }, {
    code: "LV",
    dial_code: "+371",
    flag: "🇱🇻",
    name: "Latvia"
  }, {
    code: "EE",
    dial_code: "+372",
    flag: "🇪🇪",
    name: "Estonia"
  }, {
    code: "MD",
    dial_code: "+373",
    flag: "🇲🇩",
    name: "Moldova"
  }, {
    code: "AM",
    dial_code: "+374",
    flag: "🇦🇲",
    name: "Armenia"
  }, {
    code: "BY",
    dial_code: "+375",
    flag: "🇧🇾",
    name: "Belarus"
  }, {
    code: "AD",
    dial_code: "+376",
    flag: "🇦🇩",
    name: "Andorra"
  }, {
    code: "MC",
    dial_code: "+377",
    flag: "🇲🇨",
    name: "Monaco"
  }, {
    code: "SM",
    dial_code: "+378",
    flag: "🇸🇲",
    name: "San Marino"
  }, {
    code: "VA",
    dial_code: "+379",
    flag: "🇻🇦",
    name: "Holy See (Vatican City State)"
  }, {
    code: "UA",
    dial_code: "+380",
    flag: "🇺🇦",
    name: "Ukraine"
  }, {
    code: "RS",
    dial_code: "+381",
    flag: "🇷🇸",
    name: "Serbia"
  }, {
    code: "ME",
    dial_code: "+382",
    flag: "🇲🇪",
    name: "Montenegro"
  }, {
    code: "XK",
    dial_code: "+383",
    flag: "🇽🇰",
    name: "Kosovo"
  }, {
    code: "HR",
    dial_code: "+385",
    flag: "🇭🇷",
    name: "Croatia"
  }, {
    code: "SI",
    dial_code: "+386",
    flag: "🇸🇮",
    name: "Slovenia"
  }, {
    code: "BA",
    dial_code: "+387",
    flag: "🇧🇦",
    name: "Bosnia and Herzegovina"
  }, {
    code: "MK",
    dial_code: "+389",
    flag: "🇲🇰",
    name: "Macedonia"
  }, {
    code: "IT",
    dial_code: "+39",
    flag: "🇮🇹",
    name: "Italy"
  }, {
    code: "RO",
    dial_code: "+40",
    flag: "🇷🇴",
    name: "Romania"
  }, {
    code: "CH",
    dial_code: "+41",
    flag: "🇨🇭",
    name: "Switzerland"
  }, {
    code: "CZ",
    dial_code: "+420",
    flag: "🇨🇿",
    name: "Czech Republic"
  }, {
    code: "SK",
    dial_code: "+421",
    flag: "🇸🇰",
    name: "Slovakia"
  }, {
    code: "LI",
    dial_code: "+423",
    flag: "🇱🇮",
    name: "Liechtenstein"
  }, {
    code: "AT",
    dial_code: "+43",
    flag: "🇦🇹",
    name: "Austria"
  }, {
    code: "GG",
    dial_code: "+44",
    flag: "🇬🇬",
    name: "Guernsey"
  }, {
    code: "IM",
    dial_code: "+44",
    flag: "🇮🇲",
    name: "Isle of Man"
  }, {
    code: "JE",
    dial_code: "+44",
    flag: "🇯🇪",
    name: "Jersey"
  }, {
    code: "GB",
    dial_code: "+44",
    flag: "🇬🇧",
    name: "United Kingdom"
  }, {
    code: "DK",
    dial_code: "+45",
    flag: "🇩🇰",
    name: "Denmark"
  }, {
    code: "SE",
    dial_code: "+46",
    flag: "🇸🇪",
    name: "Sweden"
  }, {
    code: "BV",
    dial_code: "+47",
    flag: "🇧🇻",
    name: "Bouvet Island"
  }, {
    code: "NO",
    dial_code: "+47",
    flag: "🇳🇴",
    name: "Norway"
  }, {
    code: "SJ",
    dial_code: "+47",
    flag: "🇸🇯",
    name: "Svalbard and Jan Mayen"
  }, {
    code: "PL",
    dial_code: "+48",
    flag: "🇵🇱",
    name: "Poland"
  }, {
    code: "DE",
    dial_code: "+49",
    flag: "🇩🇪",
    name: "Germany"
  }, {
    code: "FK",
    dial_code: "+500",
    flag: "🇫🇰",
    name: "Falkland Islands (Malvinas)"
  }, {
    code: "GS",
    dial_code: "+500",
    flag: "🇬🇸",
    name: "South Georgia and the South Sandwich Islands"
  }, {
    code: "BZ",
    dial_code: "+501",
    flag: "🇧🇿",
    name: "Belize"
  }, {
    code: "GT",
    dial_code: "+502",
    flag: "🇬🇹",
    name: "Guatemala"
  }, {
    code: "SV",
    dial_code: "+503",
    flag: "🇸🇻",
    name: "El Salvador"
  }, {
    code: "HN",
    dial_code: "+504",
    flag: "🇭🇳",
    name: "Honduras"
  }, {
    code: "NI",
    dial_code: "+505",
    flag: "🇳🇮",
    name: "Nicaragua"
  }, {
    code: "CR",
    dial_code: "+506",
    flag: "🇨🇷",
    name: "Costa Rica"
  }, {
    code: "PA",
    dial_code: "+507",
    flag: "🇵🇦",
    name: "Panama"
  }, {
    code: "PM",
    dial_code: "+508",
    flag: "🇵🇲",
    name: "Saint Pierre and Miquelon"
  }, {
    code: "HT",
    dial_code: "+509",
    flag: "🇭🇹",
    name: "Haiti"
  }, {
    code: "PE",
    dial_code: "+51",
    flag: "🇵🇪",
    name: "Peru"
  }, {
    code: "MX",
    dial_code: "+52",
    flag: "🇲🇽",
    name: "Mexico"
  }, {
    code: "CU",
    dial_code: "+53",
    flag: "🇨🇺",
    name: "Cuba"
  }, {
    code: "AR",
    dial_code: "+54",
    flag: "🇦🇷",
    name: "Argentina"
  }, {
    code: "BR",
    dial_code: "+55",
    flag: "🇧🇷",
    name: "Brazil"
  }, {
    code: "CL",
    dial_code: "+56",
    flag: "🇨🇱",
    name: "Chile"
  }, {
    code: "CO",
    dial_code: "+57",
    flag: "🇨🇴",
    name: "Colombia"
  }, {
    code: "VE",
    dial_code: "+58",
    flag: "🇻🇪",
    name: "Venezuela, Bolivarian Republic of Venezuela"
  }, {
    code: "GP",
    dial_code: "+590",
    flag: "🇬🇵",
    name: "Guadeloupe"
  }, {
    code: "BL",
    dial_code: "+590",
    flag: "🇧🇱",
    name: "Saint Barthelemy"
  }, {
    code: "MF",
    dial_code: "+590",
    flag: "🇲🇫",
    name: "Saint Martin"
  }, {
    code: "BO",
    dial_code: "+591",
    flag: "🇧🇴",
    name: "Bolivia, Plurinational State of bolivia"
  }, {
    code: "GY",
    dial_code: "+592",
    flag: "🇬🇾",
    name: "Guyana"
  }, {
    code: "EC",
    dial_code: "+593",
    flag: "🇪🇨",
    name: "Ecuador"
  }, {
    code: "GF",
    dial_code: "+594",
    flag: "🇬🇫",
    name: "French Guiana"
  }, {
    code: "PY",
    dial_code: "+595",
    flag: "🇵🇾",
    name: "Paraguay"
  }, {
    code: "MQ",
    dial_code: "+596",
    flag: "🇲🇶",
    name: "Martinique"
  }, {
    code: "SR",
    dial_code: "+597",
    flag: "🇸🇷",
    name: "Suriname"
  }, {
    code: "UY",
    dial_code: "+598",
    flag: "🇺🇾",
    name: "Uruguay"
  }, {
    code: "AN",
    dial_code: "+599",
    flag: "",
    name: "Netherlands Antilles"
  }, {
    code: "MY",
    dial_code: "+60",
    flag: "🇲🇾",
    name: "Malaysia"
  }, {
    code: "AU",
    dial_code: "+61",
    flag: "🇦🇺",
    name: "Australia"
  }, {
    code: "CX",
    dial_code: "+61",
    flag: "🇨🇽",
    name: "Christmas Island"
  }, {
    code: "CC",
    dial_code: "+61",
    flag: "🇨🇨",
    name: "Cocos (Keeling) Islands"
  }, {
    code: "ID",
    dial_code: "+62",
    flag: "🇮🇩",
    name: "Indonesia"
  }, {
    code: "PH",
    dial_code: "+63",
    flag: "🇵🇭",
    name: "Philippines"
  }, {
    code: "NZ",
    dial_code: "+64",
    flag: "🇳🇿",
    name: "New Zealand"
  }, {
    code: "PN",
    dial_code: "+64",
    flag: "🇵🇳",
    name: "Pitcairn"
  }, {
    code: "SG",
    dial_code: "+65",
    flag: "🇸🇬",
    name: "Singapore"
  }, {
    code: "TH",
    dial_code: "+66",
    flag: "🇹🇭",
    name: "Thailand"
  }, {
    code: "TL",
    dial_code: "+670",
    flag: "🇹🇱",
    name: "Timor-Leste"
  }, {
    code: "AQ",
    dial_code: "+672",
    flag: "🇦🇶",
    name: "Antarctica"
  }, {
    code: "HM",
    dial_code: "+672",
    flag: "🇭🇲",
    name: "Heard Island and Mcdonald Islands"
  }, {
    code: "NF",
    dial_code: "+672",
    flag: "🇳🇫",
    name: "Norfolk Island"
  }, {
    code: "BN",
    dial_code: "+673",
    flag: "🇧🇳",
    name: "Brunei Darussalam"
  }, {
    code: "NR",
    dial_code: "+674",
    flag: "🇳🇷",
    name: "Nauru"
  }, {
    code: "PG",
    dial_code: "+675",
    flag: "🇵🇬",
    name: "Papua New Guinea"
  }, {
    code: "TO",
    dial_code: "+676",
    flag: "🇹🇴",
    name: "Tonga"
  }, {
    code: "SB",
    dial_code: "+677",
    flag: "🇸🇧",
    name: "Solomon Islands"
  }, {
    code: "VU",
    dial_code: "+678",
    flag: "🇻🇺",
    name: "Vanuatu"
  }, {
    code: "FJ",
    dial_code: "+679",
    flag: "🇫🇯",
    name: "Fiji"
  }, {
    code: "PW",
    dial_code: "+680",
    flag: "🇵🇼",
    name: "Palau"
  }, {
    code: "WF",
    dial_code: "+681",
    flag: "🇼🇫",
    name: "Wallis and Futuna"
  }, {
    code: "CK",
    dial_code: "+682",
    flag: "🇨🇰",
    name: "Cook Islands"
  }, {
    code: "NU",
    dial_code: "+683",
    flag: "🇳🇺",
    name: "Niue"
  }, {
    code: "WS",
    dial_code: "+685",
    flag: "🇼🇸",
    name: "Samoa"
  }, {
    code: "KI",
    dial_code: "+686",
    flag: "🇰🇮",
    name: "Kiribati"
  }, {
    code: "NC",
    dial_code: "+687",
    flag: "🇳🇨",
    name: "New Caledonia"
  }, {
    code: "TV",
    dial_code: "+688",
    flag: "🇹🇻",
    name: "Tuvalu"
  }, {
    code: "PF",
    dial_code: "+689",
    flag: "🇵🇫",
    name: "French Polynesia"
  }, {
    code: "TK",
    dial_code: "+690",
    flag: "🇹🇰",
    name: "Tokelau"
  }, {
    code: "FM",
    dial_code: "+691",
    flag: "🇫🇲",
    name: "Micronesia, Federated States of Micronesia"
  }, {
    code: "MH",
    dial_code: "+692",
    flag: "🇲🇭",
    name: "Marshall Islands"
  }, {
    code: "KZ",
    dial_code: "+7",
    flag: "🇰🇿",
    name: "Kazakhstan"
  }, {
    code: "RU",
    dial_code: "+7",
    flag: "🇷🇺",
    name: "Russia"
  }, {
    code: "JP",
    dial_code: "+81",
    flag: "🇯🇵",
    name: "Japan"
  }, {
    code: "KR",
    dial_code: "+82",
    flag: "🇰🇷",
    name: "Korea, Republic of South Korea"
  }, {
    code: "VN",
    dial_code: "+84",
    flag: "🇻🇳",
    name: "Vietnam"
  }, {
    code: "KP",
    dial_code: "+850",
    flag: "🇰🇵",
    name: "Korea, Democratic People's Republic of Korea"
  }, {
    code: "HK",
    dial_code: "+852",
    flag: "🇭🇰",
    name: "Hong Kong"
  }, {
    code: "MO",
    dial_code: "+853",
    flag: "🇲🇴",
    name: "Macao"
  }, {
    code: "KH",
    dial_code: "+855",
    flag: "🇰🇭",
    name: "Cambodia"
  }, {
    code: "LA",
    dial_code: "+856",
    flag: "🇱🇦",
    name: "Laos"
  }, {
    code: "CN",
    dial_code: "+86",
    flag: "🇨🇳",
    name: "China"
  }, {
    code: "BD",
    dial_code: "+880",
    flag: "🇧🇩",
    name: "Bangladesh"
  }, {
    code: "TW",
    dial_code: "+886",
    flag: "🇹🇼",
    name: "Taiwan"
  }, {
    code: "TR",
    dial_code: "+90",
    flag: "🇹🇷",
    name: "Turkey"
  }, {
    code: "IN",
    dial_code: "+91",
    flag: "🇮🇳",
    name: "India"
  }, {
    code: "PK",
    dial_code: "+92",
    flag: "🇵🇰",
    name: "Pakistan"
  }, {
    code: "AF",
    dial_code: "+93",
    flag: "🇦🇫",
    name: "Afghanistan"
  }, {
    code: "LK",
    dial_code: "+94",
    flag: "🇱🇰",
    name: "Sri Lanka"
  }, {
    code: "MM",
    dial_code: "+95",
    flag: "🇲🇲",
    name: "Myanmar"
  }, {
    code: "MV",
    dial_code: "+960",
    flag: "🇲🇻",
    name: "Maldives"
  }, {
    code: "LB",
    dial_code: "+961",
    flag: "🇱🇧",
    name: "Lebanon"
  }, {
    code: "JO",
    dial_code: "+962",
    flag: "🇯🇴",
    name: "Jordan"
  }, {
    code: "SY",
    dial_code: "+963",
    flag: "🇸🇾",
    name: "Syrian Arab Republic"
  }, {
    code: "IQ",
    dial_code: "+964",
    flag: "🇮🇶",
    name: "Iraq"
  }, {
    code: "KW",
    dial_code: "+965",
    flag: "🇰🇼",
    name: "Kuwait"
  }, {
    code: "SA",
    dial_code: "+966",
    flag: "🇸🇦",
    name: "Saudi Arabia"
  }, {
    code: "YE",
    dial_code: "+967",
    flag: "🇾🇪",
    name: "Yemen"
  }, {
    code: "OM",
    dial_code: "+968",
    flag: "🇴🇲",
    name: "Oman"
  }, {
    code: "PS",
    dial_code: "+970",
    flag: "🇵🇸",
    name: "Palestinian Territory, Occupied"
  }, {
    code: "AE",
    dial_code: "+971",
    flag: "🇦🇪",
    name: "United Arab Emirates"
  }, {
    code: "IL",
    dial_code: "+972",
    flag: "🇮🇱",
    name: "Israel"
  }, {
    code: "BH",
    dial_code: "+973",
    flag: "🇧🇭",
    name: "Bahrain"
  }, {
    code: "QA",
    dial_code: "+974",
    flag: "🇶🇦",
    name: "Qatar"
  }, {
    code: "BT",
    dial_code: "+975",
    flag: "🇧🇹",
    name: "Bhutan"
  }, {
    code: "MN",
    dial_code: "+976",
    flag: "🇲🇳",
    name: "Mongolia"
  }, {
    code: "NP",
    dial_code: "+977",
    flag: "🇳🇵",
    name: "Nepal"
  }, {
    code: "IR",
    dial_code: "+98",
    flag: "🇮🇷",
    name: "Iran, Islamic Republic of Persian Gulf"
  }, {
    code: "TJ",
    dial_code: "+992",
    flag: "🇹🇯",
    name: "Tajikistan"
  }, {
    code: "TM",
    dial_code: "+993",
    flag: "🇹🇲",
    name: "Turkmenistan"
  }, {
    code: "AZ",
    dial_code: "+994",
    flag: "🇦🇿",
    name: "Azerbaijan"
  }, {
    code: "GE",
    dial_code: "+995",
    flag: "🇬🇪",
    name: "Georgia"
  }, {
    code: "KG",
    dial_code: "+996",
    flag: "🇰🇬",
    name: "Kyrgyzstan"
  }, {
    code: "UZ",
    dial_code: "+998",
    flag: "🇺🇿",
    name: "Uzbekistan"
  }]
  