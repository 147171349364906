//Customizable Area Start
import React from "react";
import {
    Box,
    Button,
    Hidden, InputAdornment,
    Grid, MenuItem, Menu, Link, Typography
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import {
    Tenant_Logo, Building1,
    Email_Icon, globalIcon, downArrow
} from "../../src/assets";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import CompEmpForgotPasswordController, {
    Props,
} from "./CompEmpForgotPasswordController.web";

const validationschema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Email is required").max(320, 'email address should not be longer than 320 characters'),

})

export default class CompEmpForgotPassword extends CompEmpForgotPasswordController {

    render() {

        return (
            <Box>
                <Grid container spacing={5}>
                    <Grid item sm={7} xs={12} style={{height: '100%'}} className="rightbox t">
                        <Hidden>
                            <Box p={2} style={{height: '100%'}} className="rightbox u">
                                <Box style={{
                                    display: 'flex',
                                    height: '80px',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row'
                                }}>

                                    <img src={Tenant_Logo.default} style={webStyle.login_img}/>
                                    <Box style={{height: '25px'}} onClick={(e) => {
                                        this.handleOpen(e)
                                    }} data-test-id='menu'>
                                        <p style={{verticalAlign: 'middle'}}>
                                            <img src={globalIcon} alt="GlobalIcon"
                                                 style={{position: 'relative', top: '5px'}}
                                                 className="login-h1 bold-text "/>
                                            <span style={{fontWeight: 'bolder', paddingLeft: '3px'}}
                                                  className="login-h1 bold-text ">{this.state.language5} </span>
                                            <img src={downArrow}
                                                 style={{position: 'relative', height: '7px', color: 'grey',top:"-2px"}}/>
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                            <Menu open={this.state.open4}

                                  anchorOrigin={{
                                      vertical: 'bottom', // Change this to 'bottom'
                                      horizontal: 'left',
                                  }}
                                  style={{
                                      position: 'absolute',
                                      top: '30px'
                                  }}
                                  transformOrigin={{
                                      vertical: 'bottom', // Change this to 'top'
                                      horizontal: 'left',
                                  }}
                                  anchorEl={this.state.field2}>
                                <MenuItem onClick={() => {
                                    this.setState({language5: 'ENG', open4: false})
                                }} data-test-id="english">English</MenuItem>
                                <MenuItem onClick={() => {
                                    this.setState({language5: 'ARB', open4: false})
                                }} data-test-id="arabic">Arabic</MenuItem>
                            </Menu>
                            <Box className="middle_box w">
                                <Box className="middleinner_box x">
                                    <Formik initialValues={this.state.initialValues} validationSchema={validationschema}
                                            onSubmit={(values) => {
                                                this.ConfirmEmail(values)
                                            }}>
                                        {(props) => (
                                            <Form translate={undefined}>
                                                <Box>
                                                    <h1 style={{fontSize: '32px',marginBottom:"10px"}}
                                                        className="login-h1 bold-text">Forgot Password</h1>
                                                    <p style={{fontSize: '15px'}}>One time Password
                                                        (OTP) code will be sent to the registered email </p>
                                                </Box>
                                                <br/>
                                                <Box style={{marginBottom: "5px"}}>
                                                    <Field as={TextField} type="email" variant='outlined'
                                                           className="compempemail"
                                                           data-test-id="emailInput"
                                                           label="Email"
                                                           name="email" helperText={<span
                                                        style={{color: 'red', fontSize: '15px'}}> <ErrorMessage
                                                        name="email" component="span"/></span>}
                                                           id="outlined-basic" placeholder="Email ID"
                                                           fullWidth
                                                           InputProps={{
                                                               startAdornment: (
                                                                   <InputAdornment position="start">
                                                                       <img src={Email_Icon}/>
                                                                   </InputAdornment>
                                                               ),
                                                               style: {
                                                                   width: '360px',
                                                                   height: '56px',
                                                                   borderRadius: '12px',
                                                                   border: '1px solid #d1d1d1',
                                                                   background: '#f9f9f9',
                                                               }
                                                           }}
                                                    />
                                                </Box>


                                                <br/>

                                                <Button color="primary" variant="contained" data-test-id='LogIn'
                                                        style={{
                                                            height: '56px',
                                                            backgroundColor: '#2B6FED',
                                                            color: '#ffffff',
                                                            borderRadius: '10px',
                                                            fontSize: '16px',
                                                            lineHeight: '20px'
                                                        }} fullWidth size="large"
                                                        type="submit"><Typography
                                                    style={{fontWeight: 'bolder', color: '#ffffff'}}
                                                    className="login-h1 bold-text "> NEXT</Typography></Button>


                                            </Form>)}
                                    </Formik>
                                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <Typography style={{fontSize: '15px', fontWeight: 'bold'}}> Back to
                                        </Typography>
                                        <Link href="/CompanyEmployee/Login" className="link bold-text y"
                                              data-test-forgotpass="forgotPass" style={{
                                            cursor: 'pointer',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            verticalAlign: ' middle',
                                            color: '#FC8434'
                                        }}><p className="login-h1 bold-text "
                                              style={{color: '#FC8434', fontSize: '15px'}}>Login</p></Link>

                                    </Box>
                                </Box>
                            </Box>
                        </Hidden>
                    </Grid>
                    <Grid item sm={5} className="leftBox z">
                        <Hidden smDown>
                            <Box pt={2} className="right_image a" visibility={{xs: 'none', md: 'flex'}}>
                                <img src={Building1.default} style={webStyle.side_img}/>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const webStyle = {

    login_img: {
        width: '116px',
        height: '45px',
    },
    side_img: {
        height: '95vh',
        width: '39vw',
        borderRadius: '40px'

    },
    image: {
        fontSize: '25px'
    }
};
// Customizable Area End

