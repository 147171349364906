// Customizable Area Start
import React from "react";
import {Button, Card, Container, Link, Typography, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ReportDashboardController, {Props} from "./ReportDashboardController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebarWeb from "../../dashboard/src/ChairmanSidebar.web";
import {withTranslation} from "react-i18next";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import {AuditReportIcon, BudgetReportIcon, ExpenseReportIcon, FeeReportIcon, InvitationReportIcon} from "./assets";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class ReportDashboard extends ReportDashboardController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {t, classes}: any = this.props;

        return (
            <GeneralHeader>
                <Container className={classes.reportChairman}>
                    <Box className="navigation">
                        <Box>
                            <Typography variant="body1">
                                {t("Documents & Reports")} /{" "}
                                <Box component="span" style={{color: "blue"}}>
                                    {t("Reports")}
                                </Box>
                            </Typography>
                            <Typography variant="h5" className="sub-heading bold-text" style={{fontSize: "28px"}}>
                                {t("Reports")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <Link href="/BudgetReports ">
                                    <Card className="report-items">
                                        <Box className="img-box">
                                            <img src={BudgetReportIcon}/>
                                        </Box>
                                        <h4 className="bold-text reportsMain">{t("Budget Reports")}</h4>
                                        <Button className="color-btn">{this.state.budgetReportCount}</Button>
                                    </Card>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Link href="/ExpenseReports">
                                    <Card className="report-items">
                                        <Box className="img-box">
                                            <img src={ExpenseReportIcon}/>
                                        </Box>
                                        <h4 className="bold-text reportsMain">{t("Expense Reports")}</h4>
                                        <Button className="color-btn">{this.state.expenseReportCount}</Button>
                                    </Card>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Link href="/ManagementFeeReports">
                                    <Card className="report-items">
                                        <Box className="img-box">
                                            <img src={FeeReportIcon}/>
                                        </Box>
                                        <h4 className="bold-text reportsMain">{t("Management Fee Reports")}</h4>
                                        <Button className="color-btn">{this.state.managementFeeCount}</Button>
                                    </Card>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Link href="/AuditReports">
                                    <Card className="report-items">
                                        <Box className="img-box">
                                            <img src={AuditReportIcon}/>
                                        </Box>
                                        <h4 className="bold-text reportsMain">{t("Audit Reports")}</h4>
                                        <Button className="color-btn">{this.state.auditReportCount}</Button>
                                    </Card>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Link href="/InvitationReport">
                                    <Card className="report-items">
                                        <Box className="img-box">
                                            <img src={InvitationReportIcon}/>
                                        </Box>
                                        <h4 className="bold-text reportsMain">{t("Invitations Reports")}</h4>
                                        <Button className="color-btn">{this.state.invitationReportCount}</Button>
                                    </Card>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </GeneralHeader>
        );
    }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(ReportDashboard));
// Customizable Area End
