// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Container,
  Typography,
  FormControl,
  Tab,
  withStyles,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import FaqChairmanController, { Props } from "./FaqChairmanController.web";
import { FaqChairmanStyleWeb } from "./FaqChairmanStyle.web";
import "./DialogStyle.web.css";
import { withTranslation } from "react-i18next";
import { CommentImage, QuestionImage } from "./assets";
import { withRouter } from "react-router";
// @ts-ignore
import { baseURL } from "../../../framework/src/config";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import ComplexDropdownWeb from "../../../components/src/ComplexDropdown.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class FaqChairman extends FaqChairmanController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const userType = localStorage.getItem("userType");

    return (
      <>
        <GeneralHeaderComponentWeb>
          <Container className={classes.faqChairman}>
            <Box className="navigation">
              <Box style={{ width: "100%" }}>
                <Typography variant="body1">
                  {t("Help")} /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Frequently asked questions")}
                  </Box>
                </Typography>
                <Box className="space-between">
                  <Typography variant="h5" className="sub-heading bold-text">
                    {t("Frequently asked questions")}
                  </Typography>
                  <ComplexDropdownWeb />
                </Box>
              </Box>
            </Box>

            <Box className="category-box">
              <Box className="category">
                {this.state.catagoriesList.map((category: any) => {
                  return (
                    <Tab
                      key={category.id}
                      onClick={() => {
                        this.setState(
                          {
                            faqList: category.attributes.FAQ.data,
                            selectedCategoryId: category.id,
                            createCategoryId: category.id,
                            selectedCategoryName: category.attributes.name,
                          },
                          () => {
                            this.getCategoryByCategoryId();
                          }
                        );
                      }}
                      label={category.attributes.name}
                      className={category.id === this.state.selectedCategoryId ? "active faq-chairman-tab" : "faq-chairman-tab"}
                    />
                  );
                })}
              </Box>
              {userType !== "Security" && userType !== "Auditor" && userType !== "ServiceProvider" && (
                <Button startIcon={<AddIcon />} variant="contained" onClick={() => this.handleAddCategoryModal()}>
                  {t("Add New Category")}
                </Button>
              )}
            </Box>

            {this.state.faqList.length === 0 && (
              <Box className="empty-box">
                <img src={QuestionImage} alt="no questions" />
                <Typography variant="h6" className="no-question-text bold-text">
                  {t("No question added")}
                </Typography>
              </Box>
            )}
            <Box className="faq-box">
              {this.state.faqList.map((faq: any) => {
                return (
                  <Accordion square key={faq.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className="bold-text"
                        expanded={this.state.expanded === faq.title}
                        onClick={() => this.handleChange(faq.title)}
                      >
                        {faq.attributes.title}
                      </Typography>
                      {userType === "Security" ? null : (
                        <Box className="icons">
                          <DeleteOutlineIcon
                            onClick={() => {
                              this.selectDeleteFaq(faq);
                            }}
                          />
                          <EditIcon
                            onClick={() => {
                              this.selectEditFaq(faq);
                            }}
                          />
                        </Box>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <p>{faq.attributes.content}</p>
                        <Box display="flex">
                          {faq.attributes.images.map((image: any, i: number) => {
                            return (
                              <div className="faq_image_pad_accordion" key={i}>
                                <img src={`${baseURL}${image.url}`} className="faq_preview_image" />
                              </div>
                            );
                          })}
                        </Box>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
            <Box className="bottom-buttons">
              {userType !== "Security" &&
                userType !== "Auditor" &&
                userType !== "ServiceProvider" &&
                this.state.selectedCategoryName && (
                  <Button
                    className="remove-cat-button"
                    variant="outlined"
                    onClick={() => this.handleDeleteAllCategoryModal()}
                  >
                    {t("Remove")} {this.state.selectedCategoryName} {t("Faq")}
                  </Button>
                )}
              {userType === "Security" || userType === "Auditor" || userType === "ServiceProvider" ? null : (
                <Button
                  disabled={this.state.catagoriesList.length === 0}
                  variant="contained"
                  onClick={() => this.handleAddQuestionModal()}
                >
                  {t("Add Questions")}
                </Button>
              )}
            </Box>
          </Container>

          <Dialog
            className="add-faq-dialog"
            onClose={() => this.handleAddQuestionModal()}
            open={this.state.isAddQuestionModalOpen}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            <MuiDialogTitle disableTypography className="dialog-heading">
              <Typography variant="h6" className="bold-text">
                {t("Add Questions")}
              </Typography>
              <IconButton onClick={() => this.handleAddQuestionModal()}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
              <FormControl fullWidth>
                <select
                  className="dialog-select-input"
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      createCategoryId: e.target.value,
                    });
                  }}
                  value={this.state.createCategoryId}
                >
                  <option aria-label="None" value="">
                    {t("Select Category")}
                  </option>
                  {this.state.catagoriesList.map((category: any) => {
                    return (
                      <option value={category.id} key={category.id}>
                        {category.attributes.name}
                      </option>
                    );
                  })}
                </select>
              </FormControl>
              <FormControl fullWidth>
                <input
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      createQuestion: e.target.value,
                    });
                  }}
                  value={this.state.createQuestion}
                  placeholder={t("Title Questions")}
                  className="dialog-input"
                />
                {this.state.createQuestion.length > 500 && (
                  <span className="error">{t("Maximum length of title should be 500 character")}</span>
                )}
              </FormControl>
              <FormControl fullWidth>
                <textarea
                  className="dialog-textarea-input"
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      createAnswer: e.target.value,
                    });
                  }}
                  value={this.state.createAnswer}
                  placeholder={t("Answer")}
                />
              </FormControl>
            </DialogContent>
            <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
              <Button className="cancel-button" onClick={() => this.handleAddQuestionModal()}>
                {t("Cancel")}
              </Button>
              <Button
                style={{ margin: "0px" }}
                className="add-button"
                onClick={() => this.createFaq()}
                disabled={
                  this.state.createAnswer.length === 0 ||
                  this.state.createQuestion.length === 0 ||
                  this.state.createQuestion.length > 500 ||
                  this.state.createCategoryId.length === 0
                }
              >
                {t("Add")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            className="add-faq-dialog"
            onClose={() => this.handleEditQuestionModal()}
            open={this.state.isEditQuestionModalOpen}
            dir={languageCondition(language, "rtl", "ltr")}
          >
            <MuiDialogTitle className="dialog-heading" disableTypography>
              <Typography variant="h6" className="bold-text">
                {t("Edit Questions")}
              </Typography>
              <IconButton onClick={() => this.handleEditQuestionModal()}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
              <FormControl fullWidth>
                <select
                  className="dialog-select-input"
                  value={this.state.editCategoryId}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      editCategoryId: e.target.value,
                    });
                  }}
                >
                  <option aria-label="None" value="">
                    {t("Select Category")}
                  </option>
                  {this.state.catagoriesList.map((category: any) => {
                    return (
                      <option value={category.id} key={category.id}>
                        {category.attributes.name}
                      </option>
                    );
                  })}
                </select>
              </FormControl>
              <FormControl fullWidth>
                <input
                  className="dialog-input"
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      editQuestion: e.target.value,
                    });
                  }}
                  value={this.state.editQuestion}
                  placeholder={t("Title Questions")}
                />
                {this.state.editQuestion.length > 500 && (
                  <span className="error">{t("Maximum length of title should be 500 character")}</span>
                )}
              </FormControl>
              <FormControl fullWidth>
                <textarea
                  className="dialog-textarea-input"
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      editAnswer: e.target.value,
                    });
                  }}
                  value={this.state.editAnswer}
                  placeholder={t("Answer")}
                />
              </FormControl>
            </DialogContent>
            <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
              <Button onClick={() => this.handleEditQuestionModal()} className="cancel-button">
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => this.editFaq()}
                disabled={
                  this.state.editAnswer.length === 0 ||
                  this.state.editQuestion.length === 0 ||
                  this.state.editQuestion.length > 500 ||
                  this.state.editCategoryId.length === 0
                }
                style={{ margin: "0px" }}
                className="add-button"
              >
                {t("Edit")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            dir={languageCondition(language, "rtl", "ltr")}
            fullWidth
            onClose={() => this.handleAddCategoryModal()}
            open={this.state.isAddCategoryModalOpen}
            className="add-faq-category-modal"
          >
            <MuiDialogTitle className="dialog-heading" disableTypography>
              <Typography variant="h6" className="bold-text">
                {t("Add Category")}
              </Typography>
              <IconButton onClick={() => this.handleAddCategoryModal()}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers>
              <FormControl fullWidth>
                <select
                  className="category dialog-select-input"
                  onChange={(e: any) => this.setState({ dashboardType: e.target.value })}
                  value={this.state.dashboardType}
                >
                  <option aria-label="None" value="">
                    {t("Select Dashboard")}
                  </option>
                  {this.state.dashboardTypeList.map((type: any) => {
                    return (
                      <option value={type.attributes.title} key={type.id}>
                        {LanguageAttributeHandler(language, type.attributes.title, type.attributes.title_ar)}
                      </option>
                    );
                  })}
                </select>
              </FormControl>
              <FormControl fullWidth>
                <input
                  value={this.state.categoryName}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      categoryName: e.target.value,
                    });
                  }}
                  placeholder={t("Category Title")}
                  className="dialog-input"
                />
              </FormControl>
            </DialogContent>
            <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
              <Button onClick={() => this.handleAddCategoryModal()} className="cancel-button">
                {t("Cancel")}
              </Button>
              <Button
                disabled={this.state.categoryName.length === 0 || this.state.dashboardType.length === 0}
                onClick={() => {
                  this.createCategory();
                }}
                className="add-button"
              >
                {t("Confirm")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            className="delete-dialog"
            fullWidth
            dir={languageCondition(language, "rtl", "ltr")}
            onClose={() => this.handleDeleteAllCategoryModal()}
            open={this.state.isDeleteAllCategoryModalOpen}
          >
            <DialogContent style={{ margin: "15px 0" }}>
              <Box textAlign="center">
                <img className="comment-image" src={CommentImage} alt="comment" />
                <Typography variant="h6" className="bold-text">
                  {t("Do you want to delete the category?")}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: "0px" }}>
                  {t("Are you sure want to delete the category")} "{this.state.selectedCategoryName}"?
                </Typography>
                <Typography variant="body1" style={{ marginBottom: "15px" }}>
                  {t("All FAQ related this category will be deleted permanently.")}
                </Typography>
                <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                  <Button
                    onClick={() => this.handleDeleteAllCategoryModal()}
                    className="cancel-button"
                    style={{ width: "200px" }}
                  >
                    {t("No, Don't Delete")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.deleteCategory();
                    }}
                    style={{ width: "200px" }}
                    className="add-button"
                  >
                    {t("Yes Delete")}
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog
            dir={languageCondition(language, "rtl", "ltr")}
            className="delete-dialog"
            fullWidth
            onClose={() => this.handleDeleteQuestionModal()}
            open={this.state.isDeleteQuestionModalOpen}
          >
            <DialogContent style={{ margin: "15px 0" }}>
              <Box textAlign="center">
                <img className="comment-image" src={CommentImage} alt="comment" />
                <Typography variant="h6" className="bold-text">
                  {t("Do you want to delete the question?")}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: "15px" }}>
                  {t("Are you sure want to delete the question?")}
                </Typography>
                <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                  <Button
                    onClick={() => this.handleDeleteQuestionModal()}
                    className="cancel-button"
                    style={{ width: "200px" }}
                  >
                    {t("No, Don't Delete")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.deleteFaq();
                    }}
                    className="add-button"
                    style={{ width: "200px" }}
                  >
                    {t("Yes Delete")}
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(FaqChairmanStyleWeb)(withRouter(FaqChairman)));
// Customizable Area End
