// Customizable Area Start

import * as React from "react";
// custom components
import {Box, Button, Grid, Typography,} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import RentUnitListController, {Props} from "./RentUnitListController";
import './style.css'
import {withTranslation} from "react-i18next";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Loader from "../../../components/src/Loader.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
class RentUnitLists extends RentUnitListController{
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
   const {i18n} = this.props
   const {language} = i18n
    return (
        <>
            <Grid item xs={12} md={12} className="auth-cols RentUnitList" dir={languageCondition(language,"rtl","ltr")}>
                <Grid container style={{ margin: '1rem', width: '90%' }} >
                  <Grid item xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}} >
                      <Box style={{ display:"flex", alignItems:"center", gap:"1rem"}}>
                          <ArrowBackIcon className="rentUnitBackBtn" onClick={() => window.history.back( )} />
                          <p className="bold-text RentPaymentUniList" style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                              {this.state.buildingName}
                          </p>
                      </Box>
                  </Grid>
                </Grid>
                <Box style={{background: "#F7F9FE",minHeight:"95%",display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"space-between"}} >
                    <Grid container spacing={2} style={{width:"90%"}}>
                        {
                            this.state.UnitListing.map((item:any,key:any)=> {
                                return(
                                    <Grid item xs={12} key={key}>
                                    <Box
                                        display="flex"
                                        className="rentUnitListBox"
                                        justifyContent='space-between'
                                        alignItems="center"
                                        borderRadius="15px"
                                        bgcolor="white"
                                        marginTop='1.5rem'
                                        padding='1.5rem'
                                        style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                                        onClick={()=> {
                                            this.props.history.push(`/UnitRentList/${item.id}`);
                                            localStorage.setItem("unitName",item.attributes.apartment_management.apartment_name)
                                        }}
                                    >
                                        <Box style={{minWidth:"100%"}}>
                                            <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                                <Box>
                                                    <Typography variant={"body1"} className="bold-text RentPaymentUnitName" style={{fontWeight:"bold",marginTop:"5px"}}>
                                                        {item.attributes.apartment_management.apartment_name}
                                                    </Typography>
                                                </Box>
                                                {
                                                    languageCondition(language, <ArrowBackIosIcon fontSize="small" style={{color:"#BFBFBF",fontSize:"25px"}}/>, <ArrowForwardIosIcon fontSize="small" style={{color:"#BFBFBF",fontSize:"25px"}}/>)
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Grid>
            <Loader loading={this.state.loading} />
        </>
    );
  }
}
export default withTranslation()(withRouter(RentUnitLists))

// Customizable Area End
