// Allow only (uppercase or lowercase)letters, numbers, backspace, tab, or space
export const alphaNumericOnly=(e:any) => {
    const keyCode = e.keyCode || e.which;

    if (e.ctrlKey && keyCode === 86) {
      return;
    }  
    
    if (
      !(
        ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode === 9 || keyCode === 32) &&
        !(e.shiftKey && keyCode >= 48 && keyCode <= 57)
      )
    ){
      e.preventDefault();
    }
  }


  export const alphaNumericOnlyTextArea=(e:any) => {
    const keyCode = e.keyCode || e.which;

    if (e.ctrlKey && keyCode === 86) {
      return;
    }  
    
    if (
      !(
        ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || (keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode === 9 || keyCode === 32 || keyCode === 190) &&
        !(e.shiftKey && keyCode >= 48 && keyCode <= 57)
      )
    ){
      e.preventDefault();
    }
  }

  // Allow only alphabets (capital or lowercase) letters, backspace, tab or space
  export const alphabetsOnly = (e:any) => {
    const keyCode = e.keyCode || e.which;

    if (e.ctrlKey && keyCode === 86) {
      return;
    }  

    if (!((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122) || keyCode === 8 || keyCode === 9 || keyCode === 13 || keyCode === 32)) {
      e.preventDefault();
    }
  }

  // Allow only alphabets (capital or lowercase) letters, backspace, tab
  export const numbersOnly = (e:any) => {
    const keyCode = e.keyCode || e.which;

    if (e.ctrlKey && keyCode === 86) {
      return;
    }  

    // Allow only numeric keycodes (0-9)
    if (
      ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46 && keyCode !== 9 && keyCode !== 190) ||
      (e.shiftKey && keyCode >= 48 && keyCode <= 57)
    ){
      e.preventDefault();
    }
  }