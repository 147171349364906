
import React from "react";
// Customizable Area Start
import {
  Backdrop,
  Button, Checkbox,
  Container, Dialog, DialogActions, DialogContent, Fade, FormControlLabel, IconButton, MenuItem, Modal,
  Paper, Select,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow, TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import CENominationDetailsController, { Props } from "./CENominationDetailsController.web";
import "@szhsin/react-menu/dist/core.css";
import {useTranslation, withTranslation} from "react-i18next";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {profileExp} from "../assets";
import moment from "moment/moment";
import ReactToPrint from "react-to-print";
import {languageCondition} from "../../../../components/src/ConditionManager.web";
import {cancle, CheckIcon} from "../../../user-profile-basic/src/assets";
import Divider from "@material-ui/core/Divider";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Loader from "../../../../components/src/Loader.web";
import CECreateNomination from "./CECreateNomination.web";
// Customizable Area End

class CENominationDetails extends CENominationDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {t,i18n} = this.props
    const language = i18n.language
    // @ts-ignore
    return (
        <>
          <div>
            <Container className="link-decoration CENominationDetails" style={{marginTop:"30px",minHeight:"90vh"}}>
              <Box className="navigation CENominationDetails">
                <Box style={{width: "100%"}}>
                  <Typography variant="body1" >
                    <Box id="nominationNavigateBack" className="CENominationDetails" component="span" onClick={()=> this.props.history.push("/CompanyEmployee/CENomination")} style={{cursor:"pointer"}}>{t("Nominations")} / </Box>{" "}
                    <Box component="span" className="CENominationDetails" style={{color: "blue"}}>{t("Chairman and Vice Chairman Nomination")}</Box>
                  </Typography>
                  <NominationHeader
                      title={this.state.CEnominationData.title}
                      voting_flag={this.state.CEnominationData.voting_flag}
                      endVotingCall={()=>this.endVotingCallCE()}
                      startVoting={()=>this.setState({CEstartVotingModal:true})}
                      nomination_flag={this.state.CEnominationData.nomination_flag}
                      nominatedSelf={this.state.CEnominatedSelf}
                      handleOpenMySelfModal={this.handleOpenMySelfModalCE}
                      status={this.state.CEnominationData.status}
                  />
                </Box>
              </Box>
              <Grid container className="CENominationDetails" spacing={3} style={{marginTop: 10, marginBottom:30}}>
                <Grid item xs={12} className="CENominationDetails">
                  <Paper elevation={6} style={{backgroundColor:"white",padding:"20px 30px",borderRadius:"15px"}} className="CENominationDetails">
                    <Grid container spacing={1} className="CENominationDetails">
                      <Grid item xs={9} style={{display:'flex'}} className="CENominationDetails">
                        <Typography className="bold-text CENominationDetails" style={{fontWeight:"bold",fontSize:"17px",marginTop:"5px"}}>{this.state.CEnominationData.title}</Typography>
                        <NominationEditButton CEnominationData={this.state.CEnominationData} t={t} handleEditDetails={this.handleEditDetails}/>
                      </Grid>
                      <Grid item xs={3} className="CENominationDetails" style={{display:'flex',alignItems:"center",justifyContent:"flex-end"}}>
                        <NominationStatus status={this.state.CEnominationData.status} t={t}/>
                      </Grid>
                      <Grid item xs={12} sm={3} className="CENominationDetails">
                        <Box>
                          <Typography variant="subtitle1" className="CENominationDetails" color="textSecondary" style={{fontSize:"14px",color:"rgba(24,29,37,0.5)"}}>{t("Complex Name")}:</Typography>
                          <Typography variant="subtitle1" className="CENominationDetails" color="textPrimary" style={{fontSize:"16px"}}>{this.state.CEnominationData.complex_name}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} className="CENominationDetails">
                        <Box className="CENominationDetails">
                          <Typography variant="subtitle1" className="CENominationDetails" color="textSecondary" style={{fontSize:"14px",color:"rgba(24,29,37,0.5)"}}>{t("Duration")}:</Typography>
                          <Typography variant="subtitle1" className="CENominationDetails" color="textPrimary" style={{fontSize:"16px"}}>{moment(this.state.CEnominationData.start_date).format("DD-MMM-YYYY")} to {moment(this.state.CEnominationData.end_date).format("DD-MMM-YYYY")}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} className="CENominationDetails">
                        <Box className="CENominationDetails">
                          <Typography variant="subtitle1" className="CENominationDetails" color="textSecondary" style={{fontSize:"14px",color:"rgba(24,29,37,0.5)"}}>{t("Total Nomination")}: </Typography>
                          <Typography variant="subtitle1" className="CENominationDetails" color="textPrimary" style={{fontSize:"16px"}}>{this.state.CEnominationData.total_nomination || 0} {t("Members")}</Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} className="CENominationDetails">
                        <Box>
                          <Typography variant="subtitle1" color="textSecondary" className="CENominationDetails" style={{fontSize:"14px",color:"rgba(24,29,37,0.5)"}}>{t("Description")}:</Typography>
                          <Typography variant="subtitle1" color="textPrimary" className="CENominationDetails" style={{fontSize:"16px"}}>{this.state.CEnominationData.description}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              {
                  this.state.CEnominationData.status !== "closed" &&
                  <Grid container spacing={3} className="CENominationDetails" style={{marginTop: 10, marginBottom:30}}>
                    {
                        this.state.CEnomineeList.length > 0 &&
                        <Grid xs={12}>
                          <Typography className="bold-text CENominationDetails" variant="h5" style={{fontWeight:"bold",marginLeft:"15px"}}>{t("Nominated Members")}</Typography>
                        </Grid>
                    }
                    {
                      this.state.CEnomineeList.length > 0 ?
                          this.state.CEnomineeList.map((item:any,key:any)=> {
                            return(
                                <NominatedMemberCard
                                    item={item}
                                    key={key}
                                    handleOpenDetailsModal={(item:any)=> this.handleOpenDetailsModalCE(item)}
                                    voting_flag={this.state.CEnominationData.voting_flag}
                                    votedViceChairmanId={this.state.CEvotedViceChairmanId}
                                    votedChairmanId={this.state.CEvotedChairmanId}
                                />
                            )
                          }):
                          <Box className="CENominationDetails" style={{height:"200px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Typography className="CENominationDetails" color="textSecondary" variant="h5">{t("No Nominated Members Found!")}</Typography>
                          </Box>
                    }
                  </Grid>
              }
              {
                  this.state.CEnominationData.status === "closed" &&
                  <>
                    <Typography variant="h5" className="bold-text CENominationDetails" style={{fontWeight:"bold"}}>{t("Nominated Members")}</Typography>
                    <Box className="CENominationDetails" style={{display:'flex',marginTop:"20px",marginBottom:"20px"}}>
                      <Typography
                          variant="body1"
                          id="chairmanTab"
                          className={this.state.CEselectedTab === "Chairman" ? "tabButtonActive" : "tabButton"}
                          style={{marginRight:"15px",minWidth:"150px",textAlign:"center"}}
                          onClick={()=> this.setState({CEselectedTab:"Chairman"})}
                      >
                        {t("Chairman")}
                      </Typography>
                      <Typography
                          variant="body1"
                          id="VchairmanTab"
                          style={{minWidth:"150px",textAlign:"center"}}
                          className={this.state.CEselectedTab === "ViceChairman" ? "tabButtonActive" : "tabButton"}
                          onClick={()=> this.setState({CEselectedTab:"ViceChairman"})}
                      >
                        {t("Vice Chairman")}
                      </Typography>
                    </Box>
                    <Grid container style={{marginTop:"15px"}}>
                      {
                        this.state.CEselectedTab === "Chairman" ?
                            <Grid item xs={12} className="CENominationDetails">
                              <Paper elevation={2} style={{backgroundColor:"white",padding:"20px 30px",borderRadius:"15px",cursor:"pointer"}} className="CENominationDetails">
                                <Table className="table-box CENominationDetails">
                                  <TableHead className="CENominationDetails">
                                    <TableRow className="CENominationDetails">
                                      <TableCell className="CENominationDetails" style={{color:"#181d25",width:"30%"}}>{t("Name")}</TableCell>
                                      <TableCell className="CENominationDetails" style={{color:"#181d25",width:"30%"}}>{t("Unit Number")}</TableCell>
                                      <TableCell className="CENominationDetails" style={{color:"#181d25"}}>{t("Total Vote")}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className="CENominationDetails">
                                    {
                                      this.state.CEchairmanVoteCount.length > 0 ?
                                          this.state.CEchairmanVoteCount.map((item:any,key:any)=>{
                                            return(
                                                <ChairmanVoteCount item={item} key={key} count={key} t={t}/>
                                            )
                                          })
                                          :
                                          <TableRow className="CENominationDetails">
                                            <TableCell className="CENominationDetails" colSpan={3}>{t("No Records Found!")}</TableCell>
                                          </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>
                            :
                            <Grid item xs={12} className="CENominationDetails">
                              <Paper elevation={2} className="CENominationDetails" style={{backgroundColor:"white",padding:"20px 30px",borderRadius:"15px",cursor:"pointer"}}>
                                <Table className="table-box CENominationDetails">
                                  <TableHead className="CENominationDetails">
                                    <TableRow className="CENominationDetails">
                                      <TableCell className="CENominationDetails" style={{color:"#181d25",width:"30%"}}>{t("Name")}</TableCell>
                                      <TableCell className="CENominationDetails" style={{color:"#181d25",width:"30%"}}>{t("Unit Number")}</TableCell>
                                      <TableCell className="CENominationDetails" style={{color:"#181d25"}}>{t("Total Vote")}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      this.state.CEviceChairmanVoteCount.length > 0 ?
                                          this.state.CEviceChairmanVoteCount.map((item:any,key:any)=>{
                                            return(
                                                <ViceChairmanVoteCount  item={item} key={key} count={key} t={t}/>
                                            )
                                          }):
                                          <TableRow className="CENominationDetails">
                                            <TableCell className="CENominationDetails" colSpan={3}>{t("No Records Found!")}</TableCell>
                                          </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>
                      }
                      <Grid xs={12} className="CENominationDetails" style={{display:'flex',alignItems:"center",justifyContent:"flex-end",marginTop:"40px",marginBottom:"50px"}}>
                        <ReactToPrint
                            trigger={() => {
                              return <DeclineButton className="CENominationDetails" style={{width:"175px",marginRight:"20px"}} >{t("Print")}</DeclineButton>;
                            }}
                            content={() => this.CEprintRef}
                        />
                        <ChairmanButton style={{width:"175px"}} onClick={this.manageShareNominationCE}>{t("Share Result")}</ChairmanButton>
                      </Grid>
                    </Grid>
                  </>
              }
            </Container>
            <Modal
                style={dashBoard.modal}
                open={Boolean(this.state.CEsetOpen)}
                onClose={this.handleCloseCE}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}>
              <Fade in={Boolean(this.state.CEsetOpen)}>
                <>
                  {/*@ts-ignore*/}
                  <CECreateNomination handleClose={()=> this.setState({CEsetOpen:false})} isEdit={true} nominationDetails={this.state.CEnominationDetails} />
                </>
              </Fade>
            </Modal>
            <Modal
                style={dashBoard.modal}
                open={Boolean(this.state.CEdetailsModal)}
                onClose={this.handleCloseDetailsModalCE}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}>
              <Fade in={Boolean(this.state.CEdetailsModal)}>
                <div style={dashBoard.paper} dir={languageCondition(language,"rtl","ltr")} className="detailsModal CENominationDetails">
                  <Box className="CENominationDetails" style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"10px"}}>
                    <Typography variant="h5" className="bold-text CENominationDetails" style={{fontSize:"22px"}}>
                      {t("Nominated Member Details")}
                    </Typography>
                    <IconButton className="CENominationDetails" onClick={this.handleCloseDetailsModalCE}>
                      <img src={cancle}
                          //@ts-ignore
                           style={dashBoard.modalCacle}/>
                    </IconButton>
                  </Box>
                  <Divider/>
                  <Grid className="CENominationDetails" container spacing={2} style={{marginTop:"10px"}}>
                    <Grid item xs={12} className="CENominationDetails">
                      <Box className="CENominationDetails" style={{display:'flex',justifyContent:'space-between'}}>
                        <Box className="CENominationDetails" display="flex" alignItems="center">
                          <img src={this.state.CEdetailsForModal?.image?.url || profileExp} width="50px" height="50px" style={{borderRadius:"100px"}} className="CENominationDetails" />
                          <Box style={{marginLeft:"20px"}} className="CENominationDetails">
                            <Typography className="bold-text CENominationDetails" style={{fontWeight:"bold"}}>{this.state.CEdetailsForModal.name}</Typography>
                            <Typography className="CENominationDetails">{this.state.CEdetailsForModal.unit_number.join(",")}</Typography>
                          </Box>
                        </Box>
                        <Box className="CENominationDetails" style={{marginTop:"10px"}}>
                          <Typography  variant="subtitle2" className={"statusOngoingBlue CENominationDetails"}>{this.state.CEdetailsForModal.role}</Typography>
                        </Box>
                      </Box>
                      <Box style={{width:"100%",marginTop:"20px "}}>
                        <Typography>
                          {this.state.CEdetailsForModal.description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
            <Modal
                style={dashBoard.modal}
                open={Boolean(this.state.CEnominateMySelf)}
                onClose={this.handleCloseMySelfModalCE}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}>
              <Fade in={Boolean(this.state.CEnominateMySelf)}>
                <div style={dashBoard.paper} dir={languageCondition(language,"rtl","ltr")} className="nominateMySelfModal CENominationDetails" >
                  <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"10px"}} className="CENominationDetails">
                    <Typography variant="h5" className="bold-text CENominationDetails" style={{fontSize:"22px"}} >
                      {t("Nominate Owner")}
                    </Typography>
                    <IconButton className="CENominationDetails" onClick={this.handleCloseMySelfModalCE}>
                      <img src={cancle}
                          //@ts-ignore
                           style={dashBoard.modalCacle}/>
                    </IconButton>
                  </Box>
                  <Divider/>
                  <Grid container className="CENominationDetails" spacing={2} style={{marginTop:"10px"}}>
                    <Grid item xs={12}>
                          <Typography style={{marginBottom:"5px"}}>{t("Select Owner")}</Typography>
                          <Select
                              variant="outlined"
                              displayEmpty
                              fullWidth
                              className="select-input selectOwnerNomination"
                              value={this.state.ownerId}
                              onChange={(e)=> this.setState({ownerId:e.target.value,ownerIdError:""})}
                              name="complex"
                              style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                          >
                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                              {t("Select Owner")}
                            </MenuItem>
                            {
                              this.state.ownerList.map((item:any)=> {
                                return(
                                    <MenuItem value={item.id} key={item.id} className={languageCondition(language, "select-arabic-menu", "")}>{item.full_name}</MenuItem>
                                )
                              })
                            }
                          </Select>
                      <Box style={{width:"100%",marginTop:"20px "}}>
                        <TextField
                            className="CENominationDetails"
                            id="outlined-multiline-static"
                            label={t("Why I should be elected")}
                            value={this.state.CEmyNominationDescription}
                            onChange={(e) => this.setState({CEmyNominationDescription:e.target.value,CEmyNominationDescriptionError:""})}
                            multiline
                            fullWidth
                            style={{border:"1px solid #ECECEC",borderRadius:"10px",backgroundColor:"#f9f9f9",marginRight:"10px"}}
                            minRows={8}
                            variant="outlined"
                        />
                        <Typography color="error" variant="subtitle1">{t(this.state.CEmyNominationDescriptionError)}</Typography>
                      </Box>
                      <Box style={{width:"100%",marginTop:"20px "}}>
                        <Typography className="bold-text CENominationDetails" style={{fontWeight:"bold"}}>{t("Nominate As a")}</Typography>
                        <FormControlLabel
                            className="CENominationDetails"
                            onChange={this.manageSelectRoleCE}
                            control={<Checkbox checkedIcon={<CheckBoxIcon style={{color:"#fc8434"}}/>} name="checkedA" value={0} checked={this.state.CEmyNominationAs.find((check:any)=> check === '0') ? true : false}/>}
                            label={t("Chairman")}
                        />
                        <FormControlLabel
                            className="CENominationDetails"
                            onChange={this.manageSelectRoleCE}
                            control={<Checkbox checkedIcon={<CheckBoxIcon style={{color:"#fc8434"}}/>} name="checkedB"  value={1} checked={this.state.CEmyNominationAs.find((check:any)=> check === '1') ? true : false} />}
                            label={t("Vice Chairman")}
                        />
                        <Typography color="error" className="CENominationDetails" variant="subtitle1">{t(this.state.CEmyNominationAsError)}</Typography>
                      </Box>
                    </Grid>
                    <Grid className="CENominationDetails" item xs={12} style={{display:'flex',justifyContent:"flex-end",marginTop:"1px"}}>
                      <Box>
                        <CancelButton className="CENominationDetails" variant="contained" style={{marginRight:"15px",width:"150px",height:"45px",boxShadow:"0px"}} onClick={this.handleCloseMySelfModalCE}>{t("Cancel")}</CancelButton>
                        <ChairmanButton className="CENominationDetails" variant="contained" style={{width:"150px",height:"45px"}} onClick={this.manageNominateCE}>{t("Submit")}</ChairmanButton>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
            <Dialog
                fullWidth
                onClose={() => this.setState({CEstartVotingModal:false})}
                open={this.state.CEstartVotingModal}
                className="cancel-meeting-dialog"
            >
              <DialogContent style={{ margin: "15px 0" }} className="CENominationDetails">
                <Box textAlign="center" dir={languageCondition(language,"rtl","ltr")} className="startVoting CENominationDetails">
                  <img className="comment-image CENominationDetails" src={CheckIcon} alt="check" />
                  <Typography variant="h6" className="bold-text CENominationDetails">{t("Start Voting")}</Typography>
                  <Typography variant="body1" className="CENominationDetails" style={{ marginBottom: "0px" }}>
                    {t("Are you sure you want to start the voting process? by confirming nomination process will be closed.")}
                  </Typography>
                  <DialogActions className="dialog-button-group CENominationDetails">
                    <Button className="cancel-button cancelVotingButton CENominationDetails" style={{ width: "200px",height:"55px",marginRight:"15px",marginLeft:"15px" }} onClick={() => this.setState({CEstartVotingModal:false})}>
                      {t("Close")}
                    </Button>
                    <Button style={{ width: "200px",height:"55px" }} className="add-button CENominationDetails" onClick={this.startVotingCE}>
                      {t("Confirm")}
                    </Button>
                  </DialogActions>
                </Box>
              </DialogContent>
            </Dialog>
          </div>
          <Loader loading={this.state.CEloading} />
        </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const NominationEditButton = (props:any) => {
  const {CEnominationData,t,handleEditDetails} = props
  return(
      <>
        {
          CEnominationData.status !== "closed" &&
          <Button variant="text" color="primary" className="CENominationDetails" name="ButtonEdit" id="editNominationButton" style={{color:"2b6fed",fontWeight:"bold",textTransform:"capitalize"}} onClick={handleEditDetails}>{t("Edit Details")}</Button>
        }
      </>
  )
}

const NominationHeader = (props:any) => {
  const {t} = useTranslation()
  return(
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" className="CENominationDetails">
        <Typography variant="h4" className="subHeading bold-text CENominationDetails" style={{fontSize:"28px",width:"65%"}}>{t("Chairman and Vice Chairman Nomination")}</Typography>
        {
            props.status !== "closed" &&
            <Box className="CENominationDetails" style={{display:"flex",justifyContent:"flex-end"}}>
              {
                props?.voting_flag ?
                    <DeclineButton id="nominationCloseVotingButton" style={{marginTop:"20px",height:"45px",width:"190px"}}  onClick={()=>props.endVotingCall()}>{t("Close Voting")}</DeclineButton> :
                    <>
                      {
                          props?.nomination_flag && !props.voting_flag &&
                          <AcceptButton id="nominationStartVotingButton" style={{marginTop:"20px",marginRight:"10px",width:"190px",height:"45px"}} onClick={props.startVoting}>{t("Start Voting")}</AcceptButton>
                      }
                      {
                          props?.nomination_flag && !props?.voting_flag && !props.nominatedSelf &&
                          <DeclineButton style={{marginTop:"20px",width:"190px",height:"45px"}} onClick={props.handleOpenMySelfModal}>{t("Nominate Owner")}</DeclineButton>
                      }
                    </>
              }
            </Box>
        }
      </Box>
  )
}

const NominationStatus = (props:any) => {
  const {t} = props;
  return(
      <>
        {
            props.status === "closed" &&
            <Typography variant="subtitle2" className={"statusOngoingRed bold-text CENominationDetails"}>{t("Closed")}</Typography>
        }
        {
            props.status === "active" &&
            <Typography variant="subtitle2" className={"statusOngoingGreen bold-text CENominationDetails"}>{t("Active")}</Typography>
        }
        {
            props.status === "upcoming" &&
            <Typography variant="subtitle2" className={"statusOngoingBlue bold-text CENominationDetails"}>{t("Upcoming")}</Typography>
        }
      </>
  )
}

const ChairmanVoteCount = (props:any) => {
  const {item,count,t} = props
  if(count === 0){
    return(
        <TableRow  className="CENominationDetails" key={count} style={{cursor:"pointer"}}>
          <TableCell className="ellipse CENominationDetails" style={item.chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.name} {item.chairman_count > 0 && <Typography variant="subtitle2" className="chairmanSelected">{t("Chairman")}</Typography>}</TableCell>
          <TableCell  className="CENominationDetails"  style={item.chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.unit_no?.join(",")}</TableCell>
          <TableCell  className="CENominationDetails"  style={item.chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.chairman_count}</TableCell>
        </TableRow>
    )
  }else{
    return(
        <TableRow  className="CENominationDetails"  key={count} style={{cursor:"pointer"}}>
          <TableCell className="ellipse CENominationDetails">{item.name}</TableCell>
          <TableCell  className="CENominationDetails" >{item.unit_no.join(",")}</TableCell>
          <TableCell  className="CENominationDetails" >{item.chairman_count}</TableCell>
        </TableRow>
    )
  }
}


const ViceChairmanVoteCount = (props:any) => {
  const {item,count,t} = props
  console.log("KEY",count)
  if(count === 0){
    return(
        <TableRow  className="CENominationDetails"  key={count} style={{cursor:"pointer"}}>
          <TableCell className="ellipse CENominationDetails" style={item.vice_chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.name} {item.vice_chairman_count > 0 && <Typography variant="subtitle2" className="chairmanSelected">{t("Vice Chairman")}</Typography> }</TableCell>
          <TableCell  className="CENominationDetails" style={item.vice_chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.unit_no?.join(",")}</TableCell>
          <TableCell  className="CENominationDetails" style={item.vice_chairman_count > 0? {fontWeight:"bold"} : {fontWeight:"normal"}}>{item.vice_chairman_count}</TableCell>
        </TableRow>
    )
  }else{
    return(
        <TableRow  className="CENominationDetails" key={count} style={{cursor:"pointer"}}>
          <TableCell className="ellipse CENominationDetails">{item.name}</TableCell>
          <TableCell className="CENominationDetails" >{item.unit_no.join(",")}</TableCell>
          <TableCell className="CENominationDetails" >{item.vice_chairman_count}</TableCell>
        </TableRow>
    )
  }
}

const NominatedMemberCard = (props:any) => {
  const {item,key,handleOpenDetailsModal} = props
  return(
      <Grid key={key} item xs={12} sm={6} className="CENominationDetails" >
        <Paper elevation={3} className="CENominationDetails"  style={{backgroundColor:"white",padding:"20px 30px",borderRadius:"15px",cursor:"pointer"}} >
          <Box  className="CENominationDetails"  id="NominatedMembers" onClick={()=> handleOpenDetailsModal(item.attributes)}>
            <Box  className="CENominationDetails" style={{display:'flex',justifyContent:'space-between'}}>
              <Box className="CENominationDetails"  display="flex" alignItems="center">
                <img className="CENominationDetails"  src={ item.attributes?.image?.url || profileExp} width="50px" height="50px" style={{borderRadius:"100px"}}/>
                <Box className="CENominationDetails"  style={{marginLeft:"10px"}}>
                  <Typography className="bold-text CENominationDetails" style={{fontWeight:"bold"}}>{item.attributes.name}</Typography>
                  <Typography  className="CENominationDetails"  style={{width:"150px",overflow:"hidden",height:"20px",textOverflow:"ellipsis"}}>{item.attributes.unit_number.join(",")}</Typography>
                </Box>
              </Box>
              <Box  className="CENominationDetails" style={{marginTop:"10px"}}>
                <Typography variant="subtitle2" className={"statusOngoingBlue CENominationDetails"}>{item.attributes.role}</Typography>
              </Box>
            </Box>
            <Box  className="CENominationDetails" style={{width:"100%",marginTop:"20px",height:"100px"}}>
              <Typography className="nominationDescriptionChairman CENominationDetails">
                {item.attributes.description}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
  )
}

const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
  },
  modalCacle:{
    top:15,
    cursor:"pointer",
    right:15,
    float:"right"
  },
  modal:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    padding: "16px 32px 24px",
    backgroundColor: "#fff",
    borderRadius: '10px',
    width:"700px"
  }
};

const AcceptButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#fc8434",
    fontWeight:"bold",
    borderRadius:"10px",
    padding:"10px 20px",
    fontSize:"15px",
    marginLeft:"10px",
    '&:hover': {
      backgroundColor: "#fc8439",
    },
  },
}))(Button);

const DeclineButton = withStyles((theme) => ({
  root: {
    color: "#2b6fed",
    backgroundColor: "#F7F9FE",
    border:"1px solid #2b6fed",
    fontWeight:"bold",
    borderRadius:"10px",
    padding:"10px 20px",
    fontSize:"15px",
    marginLeft:"10px",
    '&:hover': {
      backgroundColor: "#F7F9FE",
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    color: "#2b6fed",
    backgroundColor: "white",
    border:"1px solid #2b6fed",
    fontWeight:"bold",
    borderRadius:"10px",
    padding:"10px 20px",
    fontSize:"15px",
    marginLeft:"15px",
    '&:hover': {
      backgroundColor: "white",
    },
  },
}))(Button);

const ChairmanButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight:"bold",
    borderRadius:"10px",
    padding:"10px 20px",
    fontSize:"15px",
    '&:hover': {
      backgroundColor: "#2b6fed",
    },
  },
}))(Button);
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(CENominationDetails)));
// Customizable Area End
