// Customizable Area Start
import React, { PureComponent } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
interface OwnProps {
  t: any;
  history: any;
  location: any;
  match: any;
  name: any;
  link: any;
  label: any;
  language: any;
}

class ListingComponent extends PureComponent<OwnProps> {
  render() {
    const { t, name, link, label, language } = this.props;
    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="8px"
          bgcolor="white"
          padding="20px"
          className="listing-container-box"
          onClick={() => {
            sessionStorage.setItem("isBudget", "true");
            sessionStorage.setItem("invoicePage", label);
            this.props.history.push(link);
          }}
        >
          <Box style={{ minWidth: "100%" }}>
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant={"body1"} className="bold-text">
                {t(name)}
              </Typography>
              <ArrowForwardIosIcon fontSize="small" style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "18px" }} className={languageCondition(language, "KeyboardForwardIconAR", "")}/>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }
}

export default withTranslation()(withRouter(ListingComponent));
// Customizable Area End