// Customizable Area Start
import React from "react";
import CEPollSurveyListController, { Props } from "./CEPollSurveyListController.web";
import { withTranslation } from "react-i18next";
import { Box, Button, Typography, withStyles, Divider } from "@material-ui/core";
import { Cardcalendar, CheckMark, allUsers, awated, building, xmark } from "../assets";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";

class CEPollSurveyList extends CEPollSurveyListController {
  constructor(props: Props) {
    super(props);
  }

  renderIconData = (icon: string, data: number) => (
    <Box style={webStyle.rowContent}>
      <img src={icon} alt="" />
      <Typography className="bold-text" style={{ fontSize: "16px" }}>
        {data}
      </Typography>
    </Box>
  );

  getRecentSection = () => {
    const { type } = this.state;

    return (
      <Box style={webStyle.cardSection}>
        {this.state.surveyPollList.map((cardDetail: any) => (
          <Box
            className="dashboard_card"
            data-test-id="poll-survey-detail"
            onClick={() => this.navigateToPollSurveyReport(cardDetail.id)}
            p={2}
            style={{ cursor: "pointer" }}
          >
            <Box display="flex" flexDirection="column" paddingBottom="15px" style={{ gap: "10px" }}>
              <Box
                style={webStyle.statusContainer}
                className="bold-text"
                sx={{ bgcolor: this.getBackground(cardDetail.status) + "20", color: this.getBackground(cardDetail.status) }}
              >
                {type === "Polls" ? cardDetail.status : cardDetail.attributes.status}
              </Box>
              <Typography className="bold-text textwrap" style={{ fontSize: "18px" }}>
                {type === "Polls" ? cardDetail.title : cardDetail.attributes.title}
              </Typography>
              <Typography
                className="textwrap"
                style={{ fontSize: "18px" }}
                dangerouslySetInnerHTML={{ __html: type === "Polls" ? cardDetail.description : cardDetail.attributes.description }}
              />
              <Box style={webStyle.rowContent}>
                <img src={Cardcalendar} alt="card-calendar" />
                <Typography style={{ fontSize: "14px" }} className="textwrap">
                  {type === "Polls" ? cardDetail.start_date : cardDetail.attributes.start_date}
                  {"  "}-{"  "}
                  {type === "Polls" ? cardDetail.end_date : cardDetail.attributes.end_date}
                </Typography>
              </Box>
              <Box style={webStyle.rowContent}>
                <img src={building} alt="building" />
                <Typography style={{ fontSize: "14px" }} className="textwrap">
                  {type === "Polls" ? cardDetail.building_name.toString() : cardDetail.attributes.building_name}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box display="flex" style={{ gap: "25px" }} marginTop="15px">
              {type === "Polls" ? (
                <>
                  {this.renderIconData(allUsers, cardDetail.awaited + cardDetail.completed_answers)}
                  {cardDetail.status !== "upcoming" && this.renderIconData(CheckMark, cardDetail.completed_answers)}
                  {cardDetail.status !== "upcoming" && this.renderIconData(cardDetail.status === "ongoing" ? awated : xmark, cardDetail.awaited)}
                </>
              ) : (
                <>
                  {this.renderIconData(allUsers, cardDetail.attributes.total_audience)}
                  {cardDetail.status !== "upcoming" && this.renderIconData(CheckMark, cardDetail.attributes.total_response)}
                  {cardDetail.status !== "upcoming" &&
                    this.renderIconData(
                      cardDetail.status === "ongoing" ? awated : xmark,
                      cardDetail.attributes.total_audience - cardDetail.attributes.total_response
                    )}
                </>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <Box style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <Typography style={{ fontSize: "16px" }}>
          <Typography data-test-id="poll-survey" component="span" style={{ cursor: "pointer" }} onClick={this.navigateToPollSurvey}>
            Activities / Poll and survey
          </Typography>
          <Typography component="span">/</Typography>
          <Typography component="span" style={{ color: "rgb(43, 111, 237)" }}>
            {this.state.type}
          </Typography>
        </Typography>
        <Box className="Box12" style={webStyle.pageAction}>
          <Box>
            <Typography className="login-h1 bold-text" style={{ fontSize: "30px" }}>
              {this.state.type}
            </Typography>
          </Box>
        </Box>
        <Box className="filter-head-box">
          <FilterSelect
            t={t}
            language={language}
            label="Select Year"
            value={this.state.year}
            option={[{ label: "All", value: "all" }, ...this.state.yearList]}
            onChange={(e: any) => this.setState({ year: e.target.value })}
          />
          <FilterSelect
            t={t}
            language={language}
            value={this.state.sortBy}
            label="Select Sort By"
            option={[
              { label: "Asc", value: "asc" },
              { label: "Desc", value: "desc" },
            ]}
            onChange={(e: any) => this.setState({ sortBy: e.target.value })}
          />
          <Button className="action-search-btn" startIcon={<SearchIcon />} onClick={this.getPollSurveyList}>
            {t("Search")}
          </Button>
        </Box>
        {this.getRecentSection()}
      </Box>
    );
  }
}

const webStyle: any = {
  pageAction: {
    marginTop: "10px",
  },
  rowContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  cardSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "20px",
    marginBottom: "30px",
  },
  statusContainer: {
    fontSize: "12px",
    borderRadius: "18px",
    fontWeight: 700,
    width: "fit-content",
    padding: "4px 10px",
    textTransform: "Capitalize",
  },
};

const styles: any = {
  menuTeamPaper: {
    maxHeight: "300px",
  },
  menuTeamList: {
    paddingRight: "0px !important",
  },
};

export default withTranslation()(withStyles(styles)(withRouter(CEPollSurveyList)));
// Customizable Area End
