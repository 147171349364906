// Customizable Area Start
import React from "react";
export const ComplexImageComponent = () => {
  return (
    <svg
      width="30.71997px"
      height="30.719727px"
      viewBox="0 0 30.71997 30.719727"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="buildings">
        <path d="M0 0L30.72 0L30.72 30.72L0 30.72L0 0Z" id="Rectangle" fill="none" fillRule="evenodd" stroke="none" />
        <path
          d="M1.91992 25.918L28.7999 25.918"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2794 25.918L17.2794 4.79801C17.2794 4.26782 16.8495 3.83801 16.3194 3.83801L4.79936 3.83801C4.26916 3.83801 3.83936 4.26782 3.83936 4.79801L3.83936 25.918M26.8793 25.918L26.8793 12.478C26.8793 11.9478 26.4495 11.518 25.9193 11.518L17.2793 11.518"
          id="Shape"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.6792 8.638L11.5192 8.638"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.59937 16.3179L13.4394 16.3179"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.6792 21.118L11.5192 21.118"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.1194 21.118L23.0394 21.118"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.1194 16.3179L23.0394 16.3179"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
