export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BuildingLogo = require("../assets/building.png");
export const SortIcon = require("../assets/sort.png");
export const FilterIcon = require("../assets/filter.png");
export const TemplateIcon = require("../assets/template.png");
export const DownloadIcon = require("../assets/download.png");
export const ShareIcon = require("../assets/share.png");
export const ExclamationIcon = require("../assets/exclamation.png");
export const SuccessIcon = require("../assets/successIcon.png");
export const EarthIcon = require("../assets/earth.png");
export const BuildingIcon = require("../assets/select-building.png");
export const CubeIcon = require("../assets/cube.png");
export const EditIcon = require("../assets/edit.png");
export const TenantName = require("../assets/tenant-name.png");
export const BuildingName = require("../assets/building-name.png");
export const DurationIcon = require("../assets/duration.png");
export const CalenderIcon = require("../assets/calender.png");
export const RentAmountIcon = require("../assets/rent-amount.png");
export const CurrencyIcon = require("../assets/currency.png");
export const CardIcon = require("../assets/card.png");
export const PenaltyAmountIcon = require("../assets/penalty-amount.png");
export const LeaseGeneratorIcon = require("../assets/lease-generate.png");
export const SaveTemplateIcon = require("../assets/save-template.png");
export const ComplexIcon = require("../assets/complex.png");
export const PCardIcon = require("../assets/panelty-card.png");
export const PAmountIcon = require("../assets/panelty-amount.png");
