// Customizable Area Start
import React from "react";
import "../../Polling/src/Polling.web.css";
// @ts-ignore
import DOMPurify from "dompurify";
import "draft-js/dist/Draft.css";
import {
  Button,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import GenerateBudgetReportController, { Props } from "./GenerateBudgetReportController";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "web/src/i18n.js";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import AlertError from "../../../components/src/AlertError.web";
import ConfirmModalPopupWeb from "./ConfirmModalPopup.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web"
class SurveyPreview extends GenerateBudgetReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const { t,i18n } = this.props;
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("Documents & Reports")} /{" "}
                      <Box component="span" onClick={() => this.props.history.push("/Reports")} style={{ cursor: "pointer" }}>
                        {" "}
                        {t("Budget Report")}
                      </Box>{" "}
                      /{" "}
                      <Box component="span" onClick={this.handlePreviewForm} style={{ cursor: "pointer" }}>
                        {" "}
                        {t("Generate Report")}
                      </Box>{" "}
                      /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Preview Report")}
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="subHeading bold-text" style={{ fontSize: "28px" }}>
                      {t("Preview Report")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="top-bar" style={{ marginTop: "20px" }} />
                <Grid
                  style={{ marginBottom: "5rem", boxShadow: "4px 0px 14px #e9e9e9", backgroundColor: "white" }}
                  className="meeting-table"
                >
                  <Grid className="meeting-table">
                    <Grid item sm={12} md={12} xs={12}>
                      <Box className="table-top" style={{ padding: "20px 30px" }}>
                        <h4 className="bold-text">
                          {t("Budget")} {this.state.budgetYear}
                        </h4>
                      </Box>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Table className="table-box" style={{ width: "95%", marginBottom: "20px", marginTop: "10px" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align={languageCondition(language,"right","left")}
                              style={{ color: "#181D25", fontSize: "14px", opacity: "0.5", padding: "10px 10px" }}
                            >
                              {t("Name")}
                            </TableCell>
                            <TableCell
                              align={languageCondition(language,"left","right")}
                              style={{
                                paddingRight: "10px",
                                color: "#181D25",
                                fontSize: "14px",
                                opacity: "0.5",
                                padding: "10px 10px",
                              }}
                            >
                              {t("Amount")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.budgetItems.map((item: any, key: any) => {
                            return (
                              <TableRow key={key}>
                                <TableCell
                                  align="left"
                                  style={{ display: "flex", alignItems: "center", fontSize: "15px", padding: "10px 10px" }}
                                >
                                  {item.budget_category}
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Box style={{ margin: "10px" }}>
                                          <Typography
                                            variant="h6"
                                            className="bold-text"
                                            style={{ fontWeight: "bold", marginBottom: "10px" }}
                                          >
                                            {item.budget_category}
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }}
                                          />
                                        </Box>
                                      </React.Fragment>
                                    }
                                  >
                                    <IconButton style={{ padding: "2px" }}>
                                      <InfoOutlinedIcon style={{ color: "#9a9fa5", fontSize: "20px" }} />
                                    </IconButton>
                                  </HtmlTooltip>
                                </TableCell>
                                <TableCell align={languageCondition(language,"left","right")} style={{ paddingRight: "10px", padding: "10px 10px" }}>
                                  {languageCondition(language,this.state.currency_ar,this.state.currency)} {item.allocate_budget}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Divider />
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 30px" }}
                    >
                      <>
                        <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>{t("Requested Budget Amount")}</Typography>
                        <Typography
                          className="bold-text"
                          style={{ fontWeight: "bold", color: "#FC8434", paddingRight: "0px", fontSize: "20px" }}
                        >
                          {" "}
                          {languageCondition(language,this.state.currency_ar,this.state.currency)} {this.state.totalBudget?.toLocaleString() || 0}{" "}
                        </Typography>
                      </>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="BottomButtonSurvey" style={{marginBottom:"50px"}}>
                      <Box className="Previewbtn">
                        <AudienceButton
                          onClick={this.handlePreviewForm}
                          variant="contained"
                          color="primary"
                          style={{ width: "100px" }}
                        >
                          {t("Edit")}
                        </AudienceButton>
                      </Box>
                      <Box className="Publishbtn">
                        <PublishButton
                          onClick={() => this.setState({ confirmSubmit: true })}
                          type="submit"
                          variant="outlined"
                          color="primary"
                          style={{ width: "100px" }}
                        >
                          {t("Submit")}
                        </PublishButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <ConfirmModalPopupWeb
                  t={t}
                  handelClose={() => this.setState({ confirmSubmit: false })}
                  confirmModal={this.state.confirmSubmit}
                  handleGenerateReport={this.handleGenerateReport}
                />
              </Container>
          <AlertError
            show={this.state.showError}
            handleClose={() => this.setState({ showError: false })}
            message={this.state.error}
          />
        </GeneralHeader>
      </>
    );
  }
}

export default withTranslation()(withRouter(SurveyPreview));

const dashBoard = {
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AudienceButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    height: "45px",
    "&:hover": {
      backgroundColor: "#2b6fef",
    },
  },
}))(Button);

const PublishButton = withStyles((theme) => ({
  root: {
    color: "#2b6fed",
    backgroundColor: "white",
    fontWeight: "bold",
    height: "45px",
    "&:hover": {
      color: "#2b6fef",
    },
  },
}))(Button);
// Customizable Area End
