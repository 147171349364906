// Customizable Area Start
import * as React from "react";
// custom components
import {
    Grid, Box, Divider,IconButton, Typography,Button
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {
    editIcon,
    deleteIcon,
    userIcon,
    calendar,
    phone,
    building,
    clock,
    exampleImg
} from "./assets";
import VisitorDetailsController, {
  Props
} from "./VisitorDetailsController";
import './style.css'
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class VisitorDetails extends VisitorDetailsController{
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
      const {t,i18n} = this.props
      const language = i18n.language;
      return (
        <>
            <Grid item xs={12} md={12} className="auth-cols visitorDetails" dir={languageCondition(language,"rtl","ltr")}>
                <Grid container style={{ margin: '1rem', width: '95%' }} className="visitorDetails">
                  <Grid xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}} className="visitorDetails">
                      <Box style={{ display:"flex", alignItems:"center", gap:"5px"}} className="visitorDetails">
                          <ArrowBackIcon onClick={() => window.history.back()} />
                          <p style={{ fontSize: '1rem', fontWeight: 600 }} className="visitorDetails">
                              {
                                  this.props.match.params?.type === "past" ? t("Past Visitor Details") : t("Visitor Details")
                              }
                          </p>
                      </Box>
                          {
                              this.props.match.params?.type !== "past" &&
                              <Box>
                                  <IconButton style={{padding:"8px"}} onClick={()=>this.props.history.push(`/UpdateVisitor/${this.state.visitorId}`)} className="visitorDetails">
                                      <img src={editIcon} />
                                  </IconButton>
                                  <IconButton style={{padding:"8px"}} onClick={this.handleOpenDeleteModal} className="visitorDetails">
                                      <img src={deleteIcon} />
                                  </IconButton>
                              </Box>
                          }
                  </Grid>
                </Grid>
                <Divider/>
                <Box style={{background: "#F7F9FE",minHeight:"100vh",display:'flex',flexDirection:"column",alignItems:'center'}} className="visitorDetails">
                    <Grid container spacing={2} style={{width:"90%",marginTop:"10px"}} className="visitorDetails">
                        <Grid item xs={12} className="visitorDetails">
                            <Box
                                display="flex" justifyContent='space-between'
                                alignItems="center" borderRadius="15px"
                                bgcolor="white" marginTop='1rem'
                                padding='1rem' style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                            >
                                <Box style={{minWidth:"100%"}} className="visitorDetails">
                                    <Box style={{display:"flex",alignItems:"center"}} className="visitorDetails">
                                        <Box style={{marginRight:"10px"}} className="visitorDetails">
                                            <img src={this.state.visitorDetails.profilePic || exampleImg} height="55px" width="55px" style={{borderRadius:"100px"}} className="visitorDetails"/>
                                        </Box>
                                        <Box style={{display:'flex',flexDirection:"column",justifyContent:"center"}} className="visitorDetails">
                                            <Typography variant={"body1"} style={{fontWeight:"bold"}} className="visitorDetails">
                                                {this.state.visitorDetails.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop:"15px",display:"flex",alignItems:"center"}} className="visitorDetails">
                                        <Box style={{marginRight:"15px",alignSelf:"flex-Start"}} className="visitorDetails">
                                            <img src={calendar.default} height="30px" width="30px" className="visitorDetails"/>
                                        </Box>
                                        <Box style={{display:'flex',flexDirection:"column",justifyContent:"center"}} className="visitorDetails">
                                            <Typography variant={"body2"} className="visitorDetails" >
                                                {t("Scheduled Date")}:
                                            </Typography>
                                            <Typography variant="subtitle2" style={{fontWeight:"bold"}} className="visitorDetails">
                                                {moment(this.state?.visitorDetails?.schedule_date).format("DD MMMM YYYY")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop:"15px",display:"flex",alignItems:"center"}} className="visitorDetails">
                                        <Box style={{marginRight:"15px",alignSelf:"flex-Start"}} className="visitorDetails">
                                            <img src={building} height="30px" width="30px" className="visitorDetails"/>
                                        </Box>
                                        <Box style={{display:'flex',flexDirection:"column",justifyContent:"center"}} className="visitorDetails">
                                            <Typography variant={"body2"} className="visitorDetails">
                                                {t("Building Name")}:
                                            </Typography>
                                            <Typography variant="subtitle2" style={{fontWeight:"bold"}} className="visitorDetails">
                                                {this.state.visitorDetails?.building_management?.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop:"15px",display:"flex",alignItems:"center"}} className="visitorDetails">
                                        <Box style={{marginRight:"15px",alignSelf:"flex-Start"}} className="visitorDetails">
                                            <img src={phone} height="30px" width="30px" className="visitorDetails"/>
                                        </Box>
                                        <Box style={{display:'flex',flexDirection:"column",justifyContent:"center"}} className="visitorDetails">
                                            <Typography variant={"body2"} className="visitorDetails">
                                                {t("Phone Number")}:
                                            </Typography>
                                            <Typography variant="subtitle2" style={{fontWeight:"bold"}} className="visitorDetails">
                                                +{this.state?.visitorDetails?.mobile_number?.country_code}-{this.state?.visitorDetails?.mobile_number?.mobile_number}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop:"15px",display:"flex",alignItems:"center"}} className="visitorDetails">
                                        <Box style={{marginRight:"15px",alignSelf:"flex-Start"}} className="visitorDetails">
                                            <img src={clock} height="30px" width="30px" className="visitorDetails"/>
                                        </Box>
                                        <Box style={{display:'flex',flexDirection:"column",justifyContent:"center"}} className="visitorDetails">
                                            <Typography variant={"body2"} className="visitorDetails">
                                                {t("Scheduled Time")}:
                                            </Typography>
                                            <Typography variant="subtitle2" style={{fontWeight:"bold"}} className="visitorDetails">
                                                {this.state.visitorDetails.schedule_time}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                this.state.visitorDetails?.image?.url &&
                                <>
                                    <Typography variant="h6" style={{marginTop:"15px",fontWeight:"bold"}} className="visitorDetails"> {t("ID")} </Typography>
                                    <Box
                                        display="flex"
                                        justifyContent='space-between'
                                        alignItems="center"
                                        borderRadius="15px"
                                        bgcolor="white"
                                        padding='1rem'
                                        style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                                    >
                                        <img src={this.state.visitorDetails.image.url} width="100%" className="visitorDetails" />
                                    </Box>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Box>
                {/*@ts-ignore*/}
                <Modal
                    aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.deleteConfirmModal}
                    onClose={this.handleCloseDeleteModal}
                    closeAfterTransition BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.deleteConfirmModal}>
                        <Box style={{width:"80%",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}} dir={languageCondition(language,"rtl","ltr")}>
                            <Box style={{display:"flex",flexDirection:"column",alignItems:"center",marginTop:"15px"}} className="visitorDetails">
                                <Box className="visitorDetails">
                                    <img src={userIcon} className="visitorDetails" />
                                </Box>
                                <Typography className="visitorDetailsSceduled visitorDetails" variant="h6" style={{color:"black",fontWeight:"bold",marginTop:"15px",textAlign:"center"}}>
                                    {t("Scheduled")}
                                </Typography>
                                <Typography className="visitorDetailsVisitor visitorDetails" variant="h6" style={{color:"black",fontWeight:"bold",marginBottom:"10px",textAlign:"center"}}>
                                    {t("Visitors")}
                                </Typography>
                                <Typography className="visitorDetailsConfirmation visitorDetails" variant="body2" style={{textAlign:"center"}}>
                                    {t("Are you sure that you want to cancel this scheduled visit?")}
                                </Typography>
                                <Box style={{marginTop:"15px",width:"90%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    {/*@ts-ignore*/}
                                    <CancelButton variant="outlined" fullWidth style={{marginRight:"10px",marginBottom:"15px"}} onClick={this.deleteVisitor} className="visitorDetails">{t("Yes, Cancel")}</CancelButton>
                                    <DontCancelButton fullWidth onClick={this.handleCloseDeleteModal} className="visitorDetails" >{t("No, Don't Cancel")}</DontCancelButton>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Grid>
        </>
    );
  }
}
export default withTranslation()(withRouter(VisitorDetails))

const CancelButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"100px", height:"55px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const DontCancelButton = withStyles((theme) => ({
    root: {
        color: "#8d8d8d",
        backgroundColor: "white", fontWeight:"bold",
        height:"45px",
        '&:hover': {
            color: "#8d8d8d",
        },
    },
}))(Button);

// Customizable Area End
