// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import * as Yup from 'yup';
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";


export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  userType: string | null;
  error: string | null;
  loading: boolean;
  userTypeData:any;
  anchorEl :any ;
  anchorEl_1 :any ;
  getFacilityReservationDetails : any;
  sortBy : any ;
  status : any;
  myApartmentList:any;
  upload:any;
  notImageOrVideoError:any,
  sizeError:any,
  file : any,
  commonAreaData:any,
  incidentRelatedData:any,
  facilityReservationListing:any,
  showDialog:any;
  deleteShowDialog:any;
  facilityCount:any;
  areaReserve : any,
  areaReserveName:any,
  areaReserveDetail:any,
  reservationFees:any,
  currency:any;
  facilityComplexList:any;
  unitList:any;
}

export interface SS {
  id: any;
}

export default class FacilityReservationController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  apiCancelUpcomingFacilityReservationCallId:any;
  CreateFacilityReservationapiCallId: any;
  updateFacilityReservationapiCallId:any;
  validationApiCallId: any;
  getFacilityReservationListingApiCallId: any;
  getFacilityReservationCountApiCallId:any;
  getFacilityReservationDetailsByIdApiCallId : any ;
  getCommonAreaApiCallId : any ;
  getIncidentRelatedApiCallId:any;
  getMyApartmentListApiCallId:any;
  createChatRoomAPIId:any;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  deleteFacilityReservationsApiCallId:any;
  getComplexList:any ="";
  getUnitList:any ="";

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  //@ts-ignore
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      error: null,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType:'',
      loading: false,
      commonAreaData:null,
      incidentRelatedData:null,
      facilityReservationListing: null,
      anchorEl:null,
      anchorEl_1:null,
      getFacilityReservationDetails:null,
      sortBy : "" ,
      status : "",
      myApartmentList:[],
      upload:false,
      notImageOrVideoError:false,
      sizeError:false,
      file :{},
      showDialog:false,
      deleteShowDialog:false,
      facilityCount:{},
      areaReserve : " ",
      areaReserveName:"",
      areaReserveDetail:"",
      reservationFees:"",
      currency:"",
      facilityComplexList:[],
      unitList:[],
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.sortBy !== this.state.sortBy
    ) {
     this.getFacilityReservationListing(this.state.sortBy)
    }
  }

  handleValidationResponse = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleCreateFacilityResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      const id = responseJson?.facility_reservation?.data?.id;
      const commonArea = responseJson?.facility_reservation?.data?.attributes?.common_area?.name;
      //@ts-ignore
      this.props.history.push({pathname: "/FacilityReservationReportedSuccessfully",  state:{ id, commonArea}})
      this.setState({loading: false})
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0]
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }

    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({loading: false , error:null})
  }

  handleUpdateFacilityResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
         //@ts-ignore
      this.props.history.push("/FacilityReservationListing")
      this.setState({loading: false})
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }

    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({loading: false , error:null})
  }

  handleCancelUpcomingFacilityReservationResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({loading: false})
         //@ts-ignore
        this.props.history.push("/FacilityReservation")
      
    } else if (responseJson?.errors) {
      let error = Object.values(responseJson.errors[0])[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }

    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({loading: false , error:null})
  }

  handleGetFacilityReservationCountResponse = (responseJson: any) => {
    if (responseJson || responseJson?.data ) {
      this.setState({facilityCount : responseJson})
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  handleGetFacilityReservationListingResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data ) {
      this.setState({facilityReservationListing :responseJson?.data})
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  handleGetFacilityReservationDetailsByIdResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data ) {
      this.setState({getFacilityReservationDetails :responseJson?.data})
      const attributes = this.state?.getFacilityReservationDetails?.attributes;
      this.setState({ areaReserveName : attributes?.common_area?.name , areaReserveDetail:attributes?.common_area?.details , reservationFees:attributes?.common_area?.reservation_fee,currency:attributes?.common_area?.currency?.currency})
      this.setState({loading: false})
      this.getCommonArea(responseJson?.data?.attributes?.building?.society_management_id);
      this.getMyApartmentList(responseJson?.data?.attributes?.building?.society_management_id);
      this.getUnitListFacility(responseJson?.data?.attributes?.building.id);
      } else if (responseJson?.errors) {
        let error = responseJson.errors[0] as string;
                 //@ts-ignore
                //@ts-nocheck
          this.props.history.push("/FacilityReservation")
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  handleGetCommonAreaResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data ) {
      this.setState({commonAreaData :responseJson?.data})

      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  handleDeleteFacilityReservationsResponse = (responseJson: any) => {
    if (responseJson || responseJson?.data ) {
      this.props.history.push('/FacilityReservationListing')

    this.setState({loading: false})
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({loading: false , error:null})
  }

  handleCreateChatRoomResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data) {
      localStorage.setItem('selectedChat', JSON.stringify(responseJson.data))
      this.props.history.push('/incidentchat')
      this.setState({ loading: false })
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.parseApiCatchErrorResponse(this.state.error);
    this.setState({ loading: false, error: null })
  }

  handleGetMyApartmentListResponse = (responseJson: any) => {
    if (responseJson && responseJson?.data ) {
      this.setState({myApartmentList :responseJson?.data?.buildings})
      this.setState({loading: false})
      } else if (responseJson?.errors) {
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complex ) {
      this.setState({facilityComplexList: responseJson.complex})
      this.setState({loading: false})
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.validationApiCallId:
          this.handleValidationResponse(responseJson);
          break;
        case this.CreateFacilityReservationapiCallId:
          this.handleCreateFacilityResponse(responseJson);
          break;
        case this.updateFacilityReservationapiCallId:
          this.handleUpdateFacilityResponse(responseJson);
          break;
        case this.apiCancelUpcomingFacilityReservationCallId:
          this.handleCancelUpcomingFacilityReservationResponse(responseJson);
          break;
        case this.getFacilityReservationCountApiCallId:
          this.handleGetFacilityReservationCountResponse(responseJson);
          break;
        case this.getFacilityReservationListingApiCallId:
          this.handleGetFacilityReservationListingResponse(responseJson);
          break;
        case this.getFacilityReservationDetailsByIdApiCallId:
          this.handleGetFacilityReservationDetailsByIdResponse(responseJson);
          break;
        case this.getCommonAreaApiCallId:
          this.handleGetCommonAreaResponse(responseJson);
          break;
        case this.deleteFacilityReservationsApiCallId:
          this.handleDeleteFacilityReservationsResponse(responseJson);
          break;
        case this.createChatRoomAPIId:
          this.handleCreateChatRoomResponse(responseJson);
          break;
        case this.getMyApartmentListApiCallId:
          this.handleGetMyApartmentListResponse(responseJson);
          break;
        case this.getComplexList:
          this.handleComplexListResponse(responseJson);
          break;
        case this.getUnitList:
          this.handleUnitListResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  }

  handleUnitListResponse = (responseJson:any) => {
    if (responseJson && responseJson?.data) {
      this.setState({unitList: responseJson?.data?.apartment_list})
      this.setState({loading: false})
    }
  }
  getComplexListFacility = async () => {
    const userType = localStorage.getItem("userType");
    this.getComplexList = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,
    });
  }

  getUnitListFacility = async (id: string) => {
    this.getUnitList = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/facility_reservations/user_unit_list?building_management_id=${id}`,
    });
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
      //@ts-ignore
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


clear= () => {
  localStorage.clear()
  //@ts-ignore
  this.props.history.push("/");
}

onChange = (e :any)=>{
  const data = this.state.commonAreaData?.filter((area: any) => area.id === e);
  if (data?.length > 0) {
    const reserveArea = data[0];
    this.setState({ areaReserveName : reserveArea.attributes.name , areaReserveDetail:reserveArea.attributes.details , reservationFees:reserveArea.attributes.reservation_fee ,currency:reserveArea.attributes?.currency?.currency})
  }
}

getFacilityReservationDetails= (idOrName :any) => {
  if(idOrName){
    if(idOrName ==="Upcoming" || idOrName ==="Pending" || idOrName ==="Previous" || idOrName ==="Rejected" ||idOrName ==="Cancelled"){
      localStorage.setItem("idOrName", idOrName);
      this.props.history.push({pathname: "/FacilityReservationListing"})
    } else{
      localStorage.setItem("facilityReservationId",idOrName);
      this.props.history.push({pathname: "/FacilityReservationDetails"});
    }
  }
}

cancelUpcomingFacilityReservation =(id : any,val : any)=>{
  const header = {
    token :localStorage.getItem("userToken")
  };
  
  const formData = new FormData();
  if(val === "Cancel"){
    console.log("if ke andaer he val ==================>", val)

     //@ts-ignore
    formData.append('facility_reservation[status]','Cancelled');
  }
 const httpBody = formData;
 console.log("httpBody httpBody==================>",httpBody.get('facility_reservation'), val);
 this.setState({ showDialog: false ,loading: true })

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCancelUpcomingFacilityReservationCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_society_management/facility_reservations/${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    httpBody
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;

}
 
CreateFacilityReservation = async(val :any) => {
  try
   {
     const header = {
      token :localStorage.getItem("userToken")
    };
    const society_id = localStorage.getItem("society_id")
    const id = val?.areaReserve
    const formData = new FormData();
   formData.append('facility_reservation[building_management_id]',val?.buildingName);
   formData.append('facility_reservation[common_area_id]',id);
   formData.append('facility_reservation[date]', val?.date);
   formData.append('facility_reservation[time_from]', val?.timeFrom);
   formData.append('facility_reservation[time_to]', val?.timeTo);
   formData.append('facility_reservation[society_management_id]', val.ComplexId || "");
   formData.append('facility_reservation[apartment_management_id]', val.unitId || "");

      const httpBody = formData;
    this.setState({loading: true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CreateFacilityReservationapiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_society_management/facility_reservations`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    }
    catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  };

  updateFacilityReservation = async(val :any) => {
    try
     {
       const header = {
        token :localStorage.getItem("userToken")
      };
      const id = val?.areaReserve

      const formData = new FormData();
      
     formData.append('facility_reservation[building_management_id]',val?.buildingName);
     formData.append('facility_reservation[common_area_id]',id);
     formData.append('facility_reservation[date]', val?.date);
     formData.append('facility_reservation[time_from]', val?.timeFrom);
     formData.append('facility_reservation[time_to]', val?.timeTo);
     formData.append('facility_reservation[society_management_id]', val.ComplexId || "");
     formData.append('facility_reservation[apartment_management_id]', val.unitId || "");

      const httpBody = formData;
      this.setState({loading: true})
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateFacilityReservationapiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_society_management/facility_reservations/${val?.id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PATCH`
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
      }
      catch (error) {
        this.setState({loading: false})
        console.log(error);
      }
    };
  
  getFacilityReservationListing = (sortBy : any)  => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const society_id = localStorage.getItem("society_id")
      const userType = localStorage.getItem("userType");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFacilityReservationListingApiCallId = requestMessage.messageId;
      this.setState({ loading: true });
      const  getSortByOrStatus = `bx_block_society_management/facility_reservations?society_management_id=${society_id}&user_role=${userType}&sort_type=${sortBy}`

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getSortByOrStatus
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  getMyApartmentList = (id:any) => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMyApartmentListApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_address/building_list?society_management_id=${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  getCommonArea = (id :any) => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCommonAreaApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_society_management/facility_reservations/common_area_list?society_management_id=${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  getIncidentRelated = () => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getIncidentRelatedApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.incidentRelated
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  getFacilityReservationDetailsById= (id : any) => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFacilityReservationDetailsByIdApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_society_management/facility_reservations/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };


  handleClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget })
  };
  handleClose = (e:any, v:any) => {
    let sortBy : any ;
    console.log("v=========>",v)
    if(v === undefined || v === null){
      sortBy =this.state.sortBy
    }
    else {
      sortBy =v;
    }
    this.setState({anchorEl:null,sortBy : sortBy})
  };

  handleClick_1 = (event :any) => {
    this.setState({anchorEl_1:event.currentTarget})
  };
   
  handleClose_1 = (e:any, v:any) => {
   let status : any ;
    if(v === undefined || v === null){
      console.log("v=========>",v)
      status =this.state.status;
    }
    else {
      status =v;
    }
    this.setState({anchorEl_1:null ,status :status})
  };
  
  

  deleteFacility =(id:any)=>{
    const header = {
      token :localStorage.getItem("userToken")
    };
    
    this.setState({loading: true}) 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteFacilityReservationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_society_management/facility_reservations/${id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  
  }
  getFacilityReservationCount = ()  => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const society_id = localStorage.getItem("society_id")
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFacilityReservationCountApiCallId = requestMessage.messageId;
      this.setState({ loading: true });
      const  getSortByOrStatus = `/bx_block_society_management/facility_reservations/reservation_list_count?society_management_id=${society_id}`

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getSortByOrStatus
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  redirectToDashboard = () => {
    let userType = localStorage.getItem('userType')
    if (userType == 'Owner') {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/OwnerDashboard')
    } else {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/residentDashboard')
    }

  }


  CreateFacilityReservationSchema() {
    const validations = Yup.object().shape({
      areaReserve: Yup.string().required(`This field is required`).trim(),
      ComplexId:Yup.string().required(`This field is required`).trim(),
      unitId:Yup.string().required(`This field is required`).trim(),
      buildingName:Yup.string().required(`This field is required`).trim(),
      date: Yup.date()
      .required()
      .typeError("please enter a valid date")
      .min(new Date(Date.now() -86400000), "Date cannot be in the past")
      ,
      timeFrom:Yup.string().required("Start time is required"),
      timeTo:Yup.string().required("End time is required")
    .test("is-greater", "End time should be greater than Start time", function(value) {
      //@ts-ignore
      const { timeFrom } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(timeFrom, "HH:mm"));
    })
       });

    return validations ;
  }
}
// Customizable Area End
