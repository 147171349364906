// Customizable Area Start
import React from "react";
import { Button, Container, Typography, withStyles, Box, Grid, Link } from "@material-ui/core";
import DocumentViewChairmanController, { Props } from "./DocumentViewChairmanController.web";
import { DocumentReportStyleWeb } from "./DocumentReportStyle.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class DocumentViewChairman extends DocumentViewChairmanController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes }: any = this.props;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.documentChairman}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      <Link href="/DocumentChairman">{t("Documents")}</Link> /{" "}
                      <Link href={`/DocumentChairman/${this.state.documentType}`}>{t(this.state.documentType)}</Link> /{" "}
                      <Box
                        component="span"
                        style={{
                          color: "blue",
                          wordBreak: "break-all",
                        }}
                      >
                        {this.state.document && this.state.document.attributes.title}
                      </Box>
                    </Typography>
                    <Box className="top-heading">
                      <Typography variant="h5" className="sub-heading bold-text">
                        {this.state.document && this.state.document.attributes.title}
                      </Typography>
                      <Link
                        download={this.state.document && this.state.document.attributes.title}
                        href={this.state.document && this.state.document.attributes.images[0].download_url}
                        target="_blank"
                      >
                        <Button variant="contained">{t("Download")}</Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
                <Box className="document-box document-card">
                  {this.state.document && this.state.document.attributes.images[0].file_name.includes(".pdf") ? (
                    <iframe
                      src={`${this.state.document &&
                        this.state.document.attributes.images[0].url}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}
                    />
                  ) : (
                    <div>{t("To view the document, Please download the document.")}</div>
                  )}
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(withRouter(DocumentViewChairman)));
// Customizable Area End
