// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import FeesAndPaymentController, { Props } from "./FeesAndPaymentController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const feePayment = [
  { title: "View Building Budget", link: "/BuildingBudget/ComplexList", label: "BuildingBudget" },
  { title: "View My Invoices", link: "/MyInvoices", label: "MyInvoices" },
  { title: "View My Management Fee", link: "/MyManagementFee/ComplexList", label: "MyManagementFee" },
  { title: "View My Receipts", link: "/MyReceipts", label: "MyReceipts" },
  { title: "Previous Payments", link: "/PreviousPayments", label: "PreviousPayments" },
  { title: "View Budget Spending", link: "/BudgetSpending/ComplexList", label: "BudgetSpending" },
];

class Visitors extends FeesAndPaymentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid item xs={12} md={12} className="auth-cols" dir={languageCondition(language, "rtl", "ltr")}>
          <Grid container className="fee-payment-top-bar">
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}
            >
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "back-button-fee")} onClick={() => this.props.history.push("/")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {t("Fees & Payments")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              {feePayment.map((item: any) => {
                return (
                  <Grid item xs={12} key={item.title}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderRadius="8px"
                      className="fee-payment-box fee-payment-card-box"
                      bgcolor="white"
                      padding="20px"
                      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                      onClick={() => {
                        sessionStorage.setItem("invoicePage", item.label);
                        this.props.history.push(item.link);
                      }}
                    >
                      <Box style={{ minWidth: "100%" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant="body1" className="bold-text">
                            {t(item.title)}
                          </Typography>
                          <ArrowForwardIosIcon fontSize="small" className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));
// Customizable Area End
