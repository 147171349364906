import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { ROLE } from "../../../framework/src/Enum";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  faqComplexList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FaqComplexListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GeFaqComplexListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      // Customizable Area Start
      faqComplexList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GeFaqComplexListCallId !== null &&
      this.GeFaqComplexListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GeFaqComplexListCallId = null;

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (responseJson && responseJson.complex) {
        this.setState({ faqComplexList: responseJson.complex });
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getFaqComplexList();
  }

  getFaqComplexList = async () => {
    const user_role = localStorage.getItem("userType");

    this.GeFaqComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_role}`,
    });
  };

  // Handle State
  handleBack = () => {
    const userType = localStorage.getItem("userType");
    if (userType === ROLE.OWNER || userType === ROLE.PROPERTY_MANAGER || userType === ROLE.OWNER_RESIDENT) {
      this.props.navigation.navigate("OwnerDashboard");
    } else {
      this.props.navigation.navigate("ResidentDashboard");
    }
  };

  handleGotoFaq = (id: any) => {
    const userType = localStorage.getItem("userType");
    if (userType === ROLE.OWNER || userType === ROLE.PROPERTY_MANAGER) {
      this.props.navigation.navigate("FaqOwner", { id: id });
    } else {
      this.props.navigation.navigate("FaqResident", { id: id });
    }
  };
  // Customizable Area End
}
