// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon, filterIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import PreviousPaymentsController, { Props } from "./PreviousPaymentsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { Menu, MenuItem } from "@szhsin/react-menu";
import moment from "moment";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class PreviousPayments extends PreviousPaymentsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="auth-cols payment-page-previous">
          <Grid container className="fee-payment-top-bar top-bar-navigation">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "payment-back-icon")} onClick={() => this.props.history.push("/FeesAndPayment")} />
                <p className="bold-text navigation-bar-payment" style={{ fontSize: "1.2rem" }}>
                  {t("Previous Payment")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                <Box className="previous-payment-sort-menu">
                  <Menu menuButton={<img src={shortIcon} alt="" />} className="chairman-lang-menu">
                    <MenuItem className="previous-asc-menu" onClick={() => this.setState({ toggleAscDesc: "asc" }, () => this.getPreviousPayment())}>
                      {t("Asc")}
                    </MenuItem>
                    <MenuItem className="previous-desc-menu" onClick={() => this.setState({ toggleAscDesc: "desc" }, () => this.getPreviousPayment())}>
                      {t("Desc")}
                    </MenuItem>
                  </Menu>
                </Box>
                <Box className={languageCondition(language, "", "expense-report-menu")}>
                  <Menu menuButton={<img src={filterIcon} alt="" />}>
                    <MenuItem className="all-filter-menu" onClick={() => this.setState({ anchorEl: "" }, () => this.getPreviousPayment())}>
                      {t("All")}
                    </MenuItem>
                    <MenuItem className="paid-filter-menu" onClick={() => this.setState({ anchorEl: "Paid" }, () => this.getPreviousPayment())}>
                      {t("Paid")}
                    </MenuItem>
                    <MenuItem className="due-filter-menu" onClick={() => this.setState({ anchorEl: "Due" }, () => this.getPreviousPayment())}>
                      {t("Due")}
                    </MenuItem>
                    <MenuItem className="partial-filter-menu" onClick={() => this.setState({ anchorEl: "partially_paid" }, () => this.getPreviousPayment())}>
                      {t("Partially Paid")}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list">
            <Grid container spacing={2} style={{ width: "90%" }}>
              {this.state.myPreviousList.length === 0 && (
                <Grid item xs={12} className="ViewMyInvoice">
                  <Box
                    alignItems="center"
                    display="flex"
                    borderRadius="8px"
                    style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                    bgcolor="white"
                    justifyContent="space-between"
                    padding="15px"
                  >
                    {t("No data available")}
                  </Box>
                </Grid>
              )}
              {this.state.myPreviousList.map((item: any, index: number) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderRadius="8px"
                      className="previous-payment-list-box payment-card-box"
                      bgcolor="white"
                      padding="15px"
                      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                      onClick={() => {
                        this.props.history.push({ pathname: `/Payment/${item.id}`, state: { selectedPrePayment: item } });
                      }}
                    >
                      <Box style={{ minWidth: "100%" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography variant={"subtitle2"}>
                            {t("Payments")} : {moment(item.attributes.title, "DD-MM-YYYY").format("MMMM YYYY")}
                          </Typography>
                          <Typography variant="subtitle2" className={item.attributes.status}>
                            {t(item.attributes.status)}
                          </Typography>
                        </Box>
                        <Grid container spacing={1}>
                          <Grid item xs={6} style={{ marginTop: "15px" }}>
                            <Typography variant={"subtitle2"}>{t("Owner")}</Typography>
                            <Typography variant={"body1"} className="bold-text payment-value-bold" style={{ marginTop: "5px" }}>
                              {item.attributes.owner_details.owner_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "15px" }}>
                            <Typography variant={"subtitle2"}>{t("Building Name")}</Typography>
                            <Typography variant={"body1"} className="bold-text payment-value-bold" style={{ marginTop: "5px" }}>
                              {item.attributes.building_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Unit Number")}</Typography>
                            <Typography variant={"body1"} className="bold-text payment-value-bold" style={{ marginTop: "5px" }}>
                              {item.attributes.unit_number}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: "8px" }}>
                            <Typography variant={"subtitle2"}>{t("Amount")}</Typography>
                            <Typography variant={"body1"} className="bold-text payment-value-bold" style={{ marginTop: "5px" }}>
                              {LanguageAttributeHandler(language, item.attributes?.currency?.currency, item.attributes?.currency?.currency_ar)} {Number(Number(item.attributes.paid_amount).toFixed(2)).toLocaleString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(PreviousPayments));
// Customizable Area End
