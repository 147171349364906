import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    language?: string;
}

interface S {
    activeSection:string;
    userRole:any;
}

interface SS {
    id: any;
}

export default class MainScreenController extends CommonApiCallForBlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];
        this.state = {
           activeSection: 'home',  
           userRole:"",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        
    }
    async componentDidMount() {
        await super.componentDidMount()
        
        const storedRole = localStorage.getItem('role');
        this.setState({userRole:storedRole})
      }
  
     
}
 // Customizable Area End

