// Customizable Area Start
import React from "react";
interface MyComponentProps {
    opennominations: any;
  }
export const NominationsImageComponent:React.FC<MyComponentProps>  = ({opennominations}) => {
    const strokeColor = opennominations 
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="26.000000pt" height="22.000000pt" viewBox="0 0 26.000000 22.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,22.000000) scale(0.100000,-0.100000)"
   fill={strokeColor} stroke="none">
   <path d="M81 194 c-11 -14 -23 -34 -26 -45 -4 -10 -13 -19 -21 -19 -10 0 -14
   -14 -14 -54 0 -34 -5 -56 -12 -59 -7 -3 32 -5 87 -5 55 0 94 2 88 5 -7 2 -13
   19 -13 37 0 25 6 35 25 44 14 7 35 12 45 12 17 0 20 7 20 55 l0 55 -79 0 c-74
   0 -81 -2 -100 -26z m129 -34 c0 -35 -4 -42 -27 -51 -16 -6 -38 -20 -50 -31
   -18 -17 -23 -18 -23 -6 0 9 6 21 14 27 8 6 16 20 19 31 7 25 -21 35 -44 14
   -29 -26 -41 -12 -19 24 18 30 23 32 75 32 l55 0 0 -40z m30 5 c0 -19 -4 -35
   -10 -35 -5 0 -10 16 -10 35 0 19 5 35 10 35 6 0 10 -16 10 -35z m-140 -75
   c-20 -37 9 -53 42 -23 17 15 18 14 18 -15 l0 -32 -65 0 -65 0 0 45 0 45 40 0
   c38 0 40 -1 30 -20z"/>
   </g>
   </svg>
  );
};
// Customizable Area End
