//Customizable area start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
  navigation?: any;
  id?: string;
  dataFromComponent1: any;
  handleModalClose: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  initialValues: any;
  empformModal: any;
}

interface SS {
  id: any;
}

export default class CENotDeleteController extends CommonApiCallForBlockComponent<Props, S, SS> {
  NotDeleteLoadDataCallID: any;
  NotDeletePostCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      initialValues: {
        EmpName: "",
        NRP: "",
        CRP: "",
      },
      empformModal: false,
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();

    this.setState({ empformModal: this.props.dataFromComponent1 });
  }
}
// Customizable Area End
