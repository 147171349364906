// Customizable Area Start
import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
// @ts-ignore
import { baseURL }  from "../../../framework/src/config";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import { Share } from "./assets";
import ReceiptDetailsController, { Props } from "./ReceiptDetailsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { CloseButton } from "./CloseButton";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

class ReceiptDetails extends ReceiptDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    const { getMyReceiptList } = this.state;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="my-receipt-details-page auth-cols">
          <Grid container className="fee-payment-top-bar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "back-button")} onClick={() => this.props.history.push("/MyReceipts")} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text">
                  {t(this.state.payment_type?.split("_").join(" "))}{" "}{t("Receipt")}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list" style={{ paddingBottom: "120px" }}>
            <Grid container spacing={2} style={{ width: "90%" }}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text receipt-details-heading">
                    {t("Receipt Details")}
                  </Typography>
                  <Typography variant="subtitle2" className={this.state.status}>
                    {t(this.state.status?.split("_").join(" "))}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
                  borderRadius="8px"
                  bgcolor="white"
                  alignItems="center"
                  padding="15px"
                >
                  <Box style={{ minWidth: "90%" }}>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary" className="receipt-building-name">
                        {t("Building Name")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.building_name}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Unit")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {this.state.unit_number}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Month")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(this.state.paid_on).format("MMMM - YYYY")}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Status")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {t(this.state.status?.split("_").join(" "))}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t(this.state.payment_type?.split("_").join(" "))} {t("Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.rent_amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Late Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.late_charge).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Amount")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {LanguageAttributeHandler(language, this.state.currency, this.state.currency_ar)}{" "}
                        {Number(Number(this.state.paid_amount).toFixed(2)).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: "10px" }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t("Paid Date")}:
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(getMyReceiptList?.attributes?.paid_on).format("MMM DD, YYYY hh:mm A")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px " }}>
                  <Typography variant={"body1"} className="bold-text">
                    {t("Landlord Details")}
                  </Typography>
                </Box>
                <Box borderRadius="8px" bgcolor="white" padding="15px" style={{ boxShadow: "4px 0px 14px #e9e9e9" }}>
                  <Box style={{ marginBottom: "10px" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t("Name")}:
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.owner_name}
                    </Typography>
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t("ID Number")}:
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {this.state.owner_id}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} className="receipt-download-share-btn download-share-bar">
                <CloseButton
                  onClick={() => this.ReciptsDownload(this.state.receiptId)}
                  fullWidth
                  className="download-receipt-btn invoice-details-download-btn receipt-detail-download download-buttons"
                >
                  {t("Download Receipt")}
                </CloseButton>
                <Box
                  onClick={this.handleReceiptShareModal}
                  className="receipt-details-share-btn shareIcon"
                  style={{ marginRight: "5px", padding: "0 16px", width: "unset" }}
                >
                  <img src={Share} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <ShareDocumentModal
          isOpen={this.state.isShareModalOpen}
          heading={t("Share")}
          handleClose={this.handleReceiptShareModal}
          language={language}
          documentURL={`${baseURL}/bx_block_fees_payment/receipts/${this.state.receiptId}/download_receipt.pdf`}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(ReceiptDetails));
// Customizable Area End
