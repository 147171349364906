// Customizable Area Start
import * as React from "react";
// custom components
import {Box, Button, Checkbox, Divider, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import { Building1, Car, ListCopy, palette, upload, user } from "./assets";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import VeichleListController, { Props } from "./VeichleListController.web";
import "../assets/css/style.scss";
import {Field, Form, Formik} from "formik";
import { withTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FormControl from "@material-ui/core/FormControl";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class NewVeichleList extends VeichleListController {
    constructor(props: Props) {
        super(props);
    }

    uploadDocument: any;
    test:number = 1;

    async componentDidMount() {
        this.getVehicle();
        this.getCarManufactureList();
        this.getCarColorList();
        this.getComplexList();
    }

    manageClick=()=>{
       if(this.test === 0){
           this.uploadDocument.click();
           this.test = 1;
       }
    }

    render() {
        const { t, i18n }: any = this.props;
        // @ts-ignore
        const language = i18n?.language;


        return (
            <>
                <Grid container spacing={2} className="auth-container vehicleRegistration" dir={languageCondition(language,"rtl","ltr")}>
                    <Grid item xs={12} md={7} className="auth-cols inputPlaceholderRegistration vehicleRegistration" style={{ justifyContent: "unset" }}>
                        <Grid container style={{ padding: "15px 5px" }} className="vehicleRegistration">
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center" }} className="vehicleRegistration">
                                <ArrowBackIcon onClick={() => window.history.back()} className="vehicleRegistrationBackButton"/>
                                <p className="bold-text vehicleRegistration" style={{ fontWeight: 600, fontSize: "18px", marginLeft: "15px" }}>
                                    {this.state.allVehcile.length === 0 ? `${t("Register vehicle")}` : `${t("Register another vehicle")}`}
                                </p>
                            </Grid>
                        </Grid>
                        <Divider />
                        <div style={{ margin: "10px 0", overflow: "auto", padding: " 0 10px", height: "calc(100% - 128px)" }} className="vehicleRegistration">
                            <Grid container className={languageCondition(language, "arabic-grid main-content-block vehicleRegistration", "main-content-block vehicleRegistration")}>
                                <Grid item xs={12}>
                                    <Formik
                                        initialValues={this.initialValues()}
                                        validationSchema={this.addVehicleSchema()}
                                        validateOnMount={true}
                                        onSubmit={(values) => {
                                            this.vehicleCreate(values);
                                        }}
                                    >
                                        {({ values, errors, touched, isValid, handleChange, setFieldValue, setFieldError }) => {
                                            return (
                                                <Form className="commonForm vehicleRegistrion" translate="yes">
                                                    <Box className="formGroup vehicleRegistrion">
                                                        <Box
                                                            className="formInputGrp vehicleRegistrion"
                                                            style={{marginTop: "5px", borderRadius: "25px"}}
                                                        >
                                                            <Field
                                                                style={{ width: "100%", border: "1px solid rgb(209,209,209,0.3)" }}
                                                                className={languageCondition(language, "formInput_ar", "formInput vehicleRegistrionOwnerName")}
                                                                name="full_name" placeholder={t("Owner Name")}
                                                            />
                                                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons vehicleRegistrionOwnerName")}>
                                                                <img className="VehicleRegistration" src={user} />
                                                              </span>
                                                        </Box>
                                                        {this.handleFormError(errors.full_name, touched.full_name, "full_name")}
                                                        <Box
                                                            className="formInputGrp VRplateNumber"
                                                            style={{borderRadius: "25px"}}
                                                        >
                                                            <Field
                                                                style={{ width: "100%", border: "1px solid rgb(209,209,209,0.3)" }}
                                                                name="plateNumber"
                                                                placeholder={t("Plate Number")}
                                                                className={languageCondition(language, "formInput_ar", "formInput VRplateNumber")}
                                                            />
                                                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons VRplateNumber")}>
                                                                <img src={ListCopy} className="VRplateNumber"/>
                                                            </span>
                                                        </Box>
                                                        {this.handleFormError(errors.plateNumber, touched.plateNumber, "plateNumber")}
                                                        <Box className="formInputGrp VRCarManufecturer create-vehicle-select-box" style={{ border: "none" }}>
                                                            <Select
                                                                displayEmpty
                                                                style={{ border: "1px solid rgb(209,209,209,0.3)" }}
                                                                fullWidth
                                                                disableUnderline
                                                                name="carManufacturer"
                                                                className="formInput_select_v selectCarManufacturer"
                                                                value={values.carManufacturer}
                                                                startAdornment={
                                                                    <img src={Car} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"10px",marginRight:"5px"},{marginLeft:"10px",marginRight:"8px"})}/>
                                                                }
                                                                onChange={(e: any) => {
                                                                    setFieldValue("carManufacturer", e.target.value);
                                                                    this.getCarModalList(e.target.value)
                                                                }}
                                                            >
                                                                <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "carManufacturer")}>
                                                                    {t("Car Manufacturer")}
                                                                </MenuItem>
                                                                {this.state.manufactureList.map((car: any) => {
                                                                    return (
                                                                        <MenuItem value={car.id} key={car.id} className={languageCondition(language, "select-arabic-menu", "carManufacturer")}>
                                                                            {LanguageAttributeHandler(language, car.attributes.title, car.attributes.title_ar)}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Box>
                                                        {this.handleFormError(errors.carManufacturer, touched.carManufacturer, "carManufacturer")}
                                                        {/* car model */}
                                                        <Box className="formInputGrp create-vehicle-select-box" style={{ border: "none" }}>
                                                            <Select
                                                                displayEmpty fullWidth name="carModle"
                                                                style={{ border: "1px solid rgb(209,209,209,0.3)" }}
                                                                className="formInput_select_v selectCarModel"
                                                                value={values.carModle}
                                                                disableUnderline
                                                                onChange={(e: any) => setFieldValue("carModle", e.target.value)}
                                                                startAdornment={
                                                                    <img src={Car} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"10px",marginRight:"5px"},{marginLeft:"10px",marginRight:"8px"})}/>
                                                                }
                                                            >
                                                                <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "carModel")}>
                                                                    {t("Car Model")}
                                                                </MenuItem>
                                                                {this.state.modalList.map((model: any) => {
                                                                    return (
                                                                        <MenuItem value={model.id} key={model.id} className={languageCondition(language, "select-arabic-menu", "carModel")}>{LanguageAttributeHandler(language, model.title, model.title_ar)}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Box>
                                                        {this.handleFormError(errors.carModle, touched.carModle, "carModle")}
                                                        {/* car color */}
                                                        <Box className="formInputGrp create-vehicle-select-box VRcarColor" style={{ border: "none" }}>
                                                            <Select
                                                                displayEmpty fullWidth
                                                                name="carColor" style={{ border: "1px solid rgb(209,209,209,0.3)" }}
                                                                disableUnderline
                                                                startAdornment={
                                                                    <img src={palette} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"10px",marginRight:"5px"},{marginLeft:"10px",marginRight:"8px"})}/>
                                                                }
                                                                className="formInput_select_v selectCarColor" value={values.carColor}
                                                                onChange={(e: any) => setFieldValue("carColor", e.target.value)}
                                                            >
                                                                <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "carColor")}>{t("Car Color")}</MenuItem>
                                                                {this.state.colorList.map((color: any) => {
                                                                    return (
                                                                        <MenuItem value={color.id} key={color.id} className={languageCondition(language, "select-arabic-menu", "carColor")}>{LanguageAttributeHandler(language, color.title, color.title_ar)}</MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Box>
                                                        {this.handleFormError(errors.carColor, touched.carColor, "carColor")}
                                                        <Box className="formInputGrp" style={{ border: "none" }}>
                                                            <Typography variant="body2" className="bold-text" style={{ fontSize: "16px", fontWeight: "bold",marginBottom:"10px" }}>{t("Select Complex / Building")}</Typography>
                                                            <FormControl component="fieldset" style={{width:"99%"}}>
                                                                {
                                                                    this.state.complexList.map((complex: any) => {
                                                                        return(
                                                                            <FormControlLabel
                                                                                value={complex.id}
                                                                                key={complex.id}
                                                                                control={
                                                                                    <Checkbox
                                                                                        className="check-box selectComplex"
                                                                                        onChange={()=>this.handleChecked(complex.id,setFieldValue,values.selectedComplex)}
                                                                                        checked={!!values.selectedComplex.find((check: any) => check === complex.id)}
                                                                                        name="checkedB" color="primary"
                                                                                        icon={<RadioButtonUncheckedIcon style={{color:"#E2E2E2"}} />}
                                                                                        checkedIcon={<CheckCircleIcon style={{color:"#FC8434"}} />} style={{padding:"4px",paddingRight:"9px"}}
                                                                                    />
                                                                                }
                                                                                label={<Typography variant="body2" style={{fontSize:"16px"}}>{complex.name}</Typography>}
                                                                                labelPlacement="start"
                                                                                style={{width:"100%",display:"flex",justifyContent:'space-between',margin:"5px",fontWeight:"normal"}}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            onClick={() => this.manageClick()}
                                                            className="imageUploadClick VRImageUpload"
                                                            style={{
                                                                display: "flex", justifyContent: "center",
                                                                flexDirection: "column", alignItems: "center",
                                                                padding: "3.5rem", marginTop: "15px",
                                                                border: "2px dashed #f0f0f0", backgroundSize: "cover",
                                                                position: "relative", backgroundColor: "#f9f9f9",
                                                                borderRadius: 15,
                                                                backgroundImage: values.bannerUrl ? `url(${values.bannerUrl})` : "",
                                                            }}
                                                        >
                                                            <img src={upload} width="25" height="25" className="VRImageUpload"/>
                                                            <label htmlFor="file1" className="VRImageUpload" style={{ color: "#7a7878", textAlign: "center", marginTop: "10px" }}>
                                                                {t("Upload car registration image")}
                                                            </label>
                                                            <input
                                                                ref={(ref: any) => (this.uploadDocument = ref)}
                                                                id="file1" type="file"
                                                                className="VRImageUpload"
                                                                onChange={(e: any) => {
                                                                    this.handleSelectBanner(e, setFieldValue, setFieldError);
                                                                }}
                                                                style={{display: "none"}}
                                                                accept="image/png, image/jpeg, image/jpg"
                                                            />
                                                        </Box>
                                                        {this.handleFormError(errors.banner, touched.banner, "banner")}
                                                        {this.handleFormError(errors.bannerUrl, touched.bannerUrl, "bannerUrl")}
                                                        <Box className="customButton new-vehicle-submit-btn VRSubmit">
                                                            <Button variant="contained" type="submit" className="VRSubmit">
                                                                {t("Submit")}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} className="auth-cols VR">
                        <Box className="right-block VR" display={{ xs: "none", md: "flex" }}>
                            <img src={Building1.default} className="building-logo VR" alt="" />
                        </Box>
                    </Grid>
                </Grid>
                <Loader loading={this.state.loading} />
            </>
        );
    }
}
// @ts-ignore
export default withTranslation()(withRouter(NewVeichleList));
// Customizable Area End
