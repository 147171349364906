// Customizable Area Start
import React from "react";
import {
    Backdrop,
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Fade,
    FormControl,
    Grid,
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import BudgetReportDetailsController, {Props} from "./BudgetReportDetailsController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import {withTranslation} from "react-i18next";
import "web/src/i18n";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import InfoOutlinedIcon from "@material-ui/icons/Info";
import {cancle, CheckIcon} from "../../user-profile-basic/src/assets";
import {buildingLogo, GroupLogo, manageLogo} from "./assets";
import {withRouter} from "react-router";
// @ts-ignore
import DOMPurify from "dompurify";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import {ROLE} from "../../../framework/src/Enum";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class BudgetReportDetails extends BudgetReportDetailsController {
    constructor(props: Props) {
        super(props);
    }

    handleText = (t: any, status: any) => {
        if (status == "Pending") {
            return <p className="RquestestedAmount"
                      style={{fontSize: "18px", color: "#181D25"}}>{t("Requested Budget Amount")}</p>;
        } else if (status == "Rejected") {
            return <p className="RquestestedAmount"
                      style={{fontSize: "18px", color: "#181D25"}}>{t("Estimated Amount")}</p>;
        } else {
            return <p style={{fontSize: "18px", color: "#181D25"}}>{t("Approved Amount")}</p>;
        }
    };

    render() {
        const {t, classes, i18n}: any = this.props;
        const userTypes = this.state.userType;
        const {language} = i18n
        return (
            <>
                <GeneralHeader>
                    <Container className={classes.reportDetails}>
                        <Box className="navigation">
                            {userTypes === ROLE.AUDITOR ? (
                                <Typography variant="body1" className="reportAuditor">
                                    {t("Reports")} /{" "}
                                    <Box
                                        component="span"
                                        className="budgetReportAuditor"
                                        onClick={() => this.props.history.push("/BudgetReports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {t("Budget Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" className="reportDetailsAuditor" style={{color: "blue"}}>
                                        {t("Report Details")}
                                    </Box>
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    {t("Documents & Reports")} /
                                    <Box
                                        component="span"
                                        className="budgetReportMain"
                                        onClick={() => this.props.history.push("/Reports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {" "}
                                        {t("Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box
                                        component="span"
                                        className="budgetReportDetailsMain onClickBudgetReportBreadcrums"
                                        onClick={() => this.props.history.push("/BudgetReports")}
                                        style={{cursor: "pointer"}}
                                    >
                                        {" "}
                                        {t("Budget Reports")}
                                    </Box>{" "}
                                    /{" "}
                                    <Box component="span" className="ReportDetailsMain" style={{color: "blue"}}>
                                        {t("Report Details")}
                                    </Box>
                                </Typography>
                            )}
                            <Box className="sub-heading-box" style={{marginBottom: "30px"}}>
                                <Typography variant="h5" className="sub-heading bold-text" style={{fontSize: "28px"}}>
                                    {t("Budget Report Details")}
                                </Typography>
                                {this.state.budgetDetails.status == "Pending" && (
                                    <Typography variant="body1" className={"statusOngoingRed"}>
                                        {t("Pending Approval")}
                                    </Typography>
                                )}
                                {this.state.budgetDetails.status == "Rejected" && (
                                    <Typography variant="body1" className={"statusRejected"}>
                                        {t(this.state.budgetDetails.status)}
                                    </Typography>
                                )}
                                {this.state.budgetDetails.status == "Approved" && (
                                    <Typography variant="body1" className={"statusOngoingGreen"}>
                                        {t(this.state.budgetDetails.status)}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {this.state.budgetDetails.status === "check" && (
                            <Box style={{backgroundColor: "white", marginBottom: "30px"}}>
                                <Grid container spacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={7}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around"
                                        }}
                                    >
                                        <Box style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                            <img src={GroupLogo} style={{marginRight: "15px"}}/>
                                            <Typography variant="h6" style={{fontWeight: "bold"}}>
                                                {t("Building Name")}
                                            </Typography>
                                        </Box>
                                        <Box style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                            <Typography variant="body1">{t("Managed By")}:</Typography>
                                            <img src={manageLogo} style={{marginLeft: "10px"}}/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <img src={buildingLogo.default} width="95%" height="130px"/>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <Box className="top-bar"/>
                        <Grid
                            className="meeting-table"
                            style={{
                                backgroundColor: "white",
                                marginBottom: "20px",
                                boxShadow: "4px 0px 14px #e9e9e9",
                                borderRadius: "8px",
                            }}
                        >
                            <Grid item sm={12} md={12} xs={12}>
                                <Box className="table-top">
                                    <h4 className="bold-text" style={{padding: " 20px 30px"}}>
                                        {t("Budget")} {this.state?.budgetDetails?.year}
                                    </h4>
                                </Box>
                                <Divider/>
                                <Grid item xs={12}
                                      style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Table className="table-box"
                                           style={{width: "95%", marginBottom: "20px", marginTop: "10px"}}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={languageCondition(language, "right", "left")}
                                                           style={{color: "gray", padding: "10px 10px"}}>
                                                    <Typography
                                                        style={{color: "#181d25", fontSize: "14px", opacity: "0.5"}}
                                                        variant="subtitle1">
                                                        {t("Name")}{" "}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align={languageCondition(language, "left", "right")} style={{
                                                    padding: "10px 10px",
                                                    paddingRight: "10px",
                                                    color: "gray"
                                                }}>
                                                    <Typography
                                                        style={{color: "#181d25", fontSize: "14px", opacity: "0.5"}}
                                                        variant="subtitle1">
                                                        {t("Amount")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.budgetDetails?.facilities?.length > 0 ? (
                                                this.state.budgetDetails?.facilities?.map((item: any, key: any) => {
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    color: "#181d25",
                                                                    padding: "10px 10px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle1">{item.budget_category}</Typography>
                                                                <HtmlTooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <Box style={{margin: "10px"}}>
                                                                                <Typography
                                                                                    variant="h6"
                                                                                    className="bold-text"
                                                                                    style={{
                                                                                        fontWeight: "bold",
                                                                                        marginBottom: "10px"
                                                                                    }}
                                                                                >
                                                                                    {item.budget_category}
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="subtitle2"
                                                                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.description)}}
                                                                                />
                                                                            </Box>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <IconButton style={{padding: "2px"}}>
                                                                        <InfoOutlinedIcon style={{
                                                                            color: "#9a9fa5",
                                                                            fontSize: "20px"
                                                                        }}/>
                                                                    </IconButton>
                                                                </HtmlTooltip>
                                                            </TableCell>
                                                            <TableCell
                                                                align={languageCondition(language, "left", "right")}
                                                                style={{padding: "10px 10px", paddingRight: "10px"}}>
                                                                <Typography className="bold-text" variant="subtitle1"
                                                                            style={{fontWeight: "bold"}}>
                                                                    {languageCondition(language, this.state?.budgetDetails?.currency?.currency_ar, this.state?.budgetDetails?.currency?.currency)} {item.allocate_budget?.toLocaleString()}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell style={{color: "gray", padding: "5px 10px"}} colSpan={6}>
                                                        {t("No Budget Details are Available")}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Divider/>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        padding: "15px 35px",
                                    }}
                                >
                                    {this.state.budgetDetails?.facilities?.length > 0 && (
                                        <>
                                            {this.handleText(t, this.state?.budgetDetails?.status)}
                                            <Typography
                                                className="bold-text"
                                                style={{
                                                    fontWeight: "bold",
                                                    color: "#FC8434",
                                                    paddingRight: "0px",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {languageCondition(language, this.state?.budgetDetails?.currency?.currency_ar, this.state?.budgetDetails?.currency?.currency)}{" "}
                                                {this.state?.budgetDetails?.approved_amount?.toLocaleString() || 0}{" "}
                                            </Typography>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.state?.budgetDetails?.status == "Rejected" && (
                            <Grid className="rejection-box">
                                <Card>
                                    <h4>{t("Rejection Reason")}</h4>
                                    <p>{this.state?.budgetDetails?.note}</p>
                                </Card>
                            </Grid>
                        )}
                        {this.state?.budgetDetails?.status === "Pending" && userTypes === "Chairman" && (
                            <Box style={{display: "flex", justifyContent: "flex-end", marginBottom: "50px"}}>
                                <ApproveButton className="RejectBudgetReportButton"
                                               onClick={() => this.setState({setOpen: true})}>{t("Reject")}</ApproveButton>
                                <RejectButton className="budgetReportApproveConfirmation"
                                              onClick={() => this.setState({ApproveModal: true})}>{t("Approve")}</RejectButton>
                            </Box>
                        )}
                        {this.state?.budgetDetails?.status !== "Pending" && this.state?.budgetDetails?.status !== "Rejected" && (
                            <Box style={{display: "flex", justifyContent: "flex-end", marginBottom: "50px"}}>
                                <RejectButton onClick={this.manageDownloadFile}>{t("Download Report")}</RejectButton>
                            </Box>
                        )}
                    </Container>
                    <Dialog
                        fullWidth
                        onClose={() => this.setState({ApproveModal: false})}
                        open={this.state.ApproveModal}
                        className="cancel-meeting-dialog"
                    >
                        <DialogContent style={{margin: "15px 0"}}>
                            <Box textAlign="center">
                                <img className="comment-image" src={CheckIcon} alt="check"/>
                                <Typography className="bold-text" variant="h6" style={{fontSize: "22px"}}>
                                    {t("Approve Budget Report")}
                                </Typography>
                                <Typography variant="body1" style={{marginBottom: "15px", fontSize: "16px"}}>
                                    {t("Are you sure you want to approve budget report")}?
                                </Typography>
                                <DialogActions className="dialog-button-group">
                                    <Button
                                        className="cancel-button budgetReportApproveCancelButton"
                                        style={{width: "200px", marginRight: "15px", height: "55px"}}
                                        onClick={() => this.setState({ApproveModal: false})}
                                    >
                                        {t("Close")}
                                    </Button>
                                    <Button style={{width: "200px", height: "55px"}}
                                            className="add-button budgetReportApproveSubmitButton"
                                            onClick={this.manageBudgetApproval}>
                                        {t("Approve")}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Modal
                        style={dashBoard.modal}
                        open={Boolean(this.state.setOpen)}
                        onClose={() => this.setState({setOpen: false})}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={Boolean(this.state.setOpen)}>
                            <div style={dashBoard.paper}>
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Typography variant="h5" className="bold-text"
                                                style={{fontWeight: "bold", fontSize: "22px"}}>
                                        {t("Reject Budget Report")}
                                    </Typography>
                                    <IconButton onClick={() => this.setState({setOpen: false})}>
                                        <img
                                            src={cancle}
                                            //@ts-ignore
                                            style={dashBoard.modalCacle}
                                        />
                                    </IconButton>
                                </Box>
                                <Box style={{display: "flex", alignItems: "center", marginLeft: "-50px"}}>
                                    <Divider style={{width: "120%"}}/>
                                </Box>
                                <Grid container spacing={2} style={{marginTop: "20px"}}>
                                    <Grid xs={12} style={{marginTop: "10px", padding: "0px 7px"}}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label={t("Add Notes")}
                                            className="RejectBudgetReportNote"
                                            multiline
                                            value={this.state.rejectReason}
                                            onChange={(e: any) => {
                                                this.setState({rejectReason: e.target.value, RejectReasonError: ""});
                                            }}
                                            fullWidth
                                            style={{
                                                border: "1px solid #ECECEC",
                                                borderRadius: "10px",
                                                backgroundColor: "#f9f9f9",
                                                marginRight: "10px",
                                            }}
                                            rows={7}
                                            variant="outlined"
                                        />
                                        <p style={{color: "red"}}>{this.state.RejectReasonError}</p>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: "40px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <Box>
                                        <ApproveButton
                                            className="budgetReportRejectCancelButton"
                                            variant="contained"
                                            style={{marginRight: "15px", height: "55px"}}
                                            onClick={() => this.setState({setOpen: false})}
                                        >
                                            {t("Cancel")}
                                        </ApproveButton>
                                        <RejectButton className="budgetReportRejectSubmitButton" variant="contained"
                                                      style={{height: "55px"}} onClick={this.handleRejectBudget}>
                                            {t("Submit")}
                                        </RejectButton>
                                    </Box>
                                </Grid>
                            </div>
                        </Fade>
                    </Modal>
                </GeneralHeader>

                <AlertSuccess
                    show={this.state.showSuccess}
                    handleClose={() => {
                        this.setState({showSuccess: false});
                    }}
                    message={this.state.successMessage}
                />
            </>
        );
    }
}

const dashBoard = {
    navigation: {
        display: "flex",
        justifyContent: "space-between",
    },
    subHeading: {
        fontWeight: 600,
        // marginTop: 15,
    },
    invitationCont: {
        fontWeight: 600,
        margin: "10px 0px 10px 0px",
    },
    inviteTitle: {
        margin: "10px 0px 10px 0px",
    },
    SideBar: {
        background: "#f9f6f6",
        position: "relative",
        paddingBottom: 150,
    },
    gaMemberCard: {
        display: "grid",
        gridTemplateColumns: "4fr 4fr 4fr",
        gap: 20,
    },
    managementPaper: {
        padding: 20,
    },
    imgRound: {
        border: "2px solid #F7F9FE",
        borderRadius: "100%",
        height: 50,
        width: 50,
    },
    mailIcon: {
        padding: 8,
    },
    invitemember: {
        border: "2px solid #F7F9FE",
        borderRadius: "100%",
        height: 50,
        width: 50,
        backgroundColor: "#FC8434",
    },
    inviteIcon: {
        padding: 13,
    },
    cancleIcon: {
        position: "absolute",
        top: 15,
        right: 15,
    },
    modalCacle: {
        top: 15,
        right: 15,
        float: "right",
        cursor: "pointer",
    },
    invitationReq: {
        marginTop: 30,
    },
    facility: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #f8f8f8",
        cursor: "pointer",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        // boxShadow: theme.shadows[5],
        padding: "16px 32px 24px",
        width: "700px",
        overflow: "hidden",
    },
    formLabels: {
        paddingLeft: 35,
    },
    labelsStyle: {
        color: "#212121",
        margin: "10px 0px 10px 0px",
    },
    formLeftIcn: {
        position: "absolute",
        left: 20,
        top: 44,
        color: "#b9b9b9",
    },
    inviteInput: {
        padding: "18px 18px 18px 50px",
        color: "#b5b5b5",
        borderRadius: "10px",
        border: "1px solid #e9dede",
        backgroundColor: "#f9f9f9",
        fontSize: "16px",
        outline: 0,
        width: "100%",
    },
};

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "white",
        color: "black",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const RejectButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight: "bold",
        height: "55px",
        width: "200px",
        "&:hover": {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const ApproveButton = withStyles((theme) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        fontWeight: "bold",
        height: "55px",
        width: "200px",
        border: "#2B6FED 1px solid",
        marginRight: "20px",
    },
}))(Button);
// @ts-ignore
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(BudgetReportDetails)));
// Customizable Area End
