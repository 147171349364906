// Customizable Area Start
import * as React from "react";
// custom components
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { Building1, CarBlue, deleteI, deleteIcon, edit, List, paletteBlue, userBlue } from "./assets";
import { withRouter } from 'react-router-dom';
import VeichleListController, { Props } from "./VeichleListController.web";
import '../assets/css/style.scss';
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class ViewVeichle extends VeichleListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    // @ts-ignore
    const language = i18n?.language;
    // @ts-ignore
    let item = JSON.parse(localStorage.getItem('selectCar'));
    return (
      <>
        <Grid container spacing={2} className="auth-container VD vehicle-grid" style={{ padding: "0" }} dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols VD" style={{ justifyContent: 'unset' }}>
            <Grid container className="main-content-block VD vehicle-header" style={{ background: 'white', minHeight: "60px", padding: "10px" }}>
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: "12px" }} className="VD">
                  <ArrowBackIcon onClick={() => window.history.back()} className="VD vehicleDetailsBackButton" />
                  <p className="bold-text VD" style={{ fontWeight: 600, fontSize: '18px' }}>{t("My Vehicles")}</p>
                </div>
                <div>
                  <img src={edit} style={languageCondition(language,{ marginLeft: 10, cursor: 'pointer' },{ marginRight: 10, cursor: 'pointer' })} className="VD editVehicleClick" onClick={() => this.props.history.push('/editVehicle')} />
                  <img src={deleteIcon} style={{ cursor: 'pointer' }} className="VD deleteVehicleClick" onClick={() => this.setState({ showDialog: true })} />
                </div>
              </Grid>
            </Grid>
            <div style={{ background: '#f6f7fc' }} className="VD vehicle-content-box">
              <Grid container style={{ marginTop: "10px" }} className="VD vehicle-gird-container">
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} className="VD">
                  <div className="card VD" style={{ width: "80%", padding: '1rem', boxShadow: 'none', margin: 8, border: '1px solid #f0f0f0', background: "white", marginTop: '10px' }}>
                    <div className="card-content VD" style={{ paddingLeft: "0", paddingRight: "0" }}>
                      <img className="VD" src={item?.attributes?.car_manufacturer.attributes?.image?.url} style={languageCondition(language,{ marginLeft: 10, width: 60, height: 40, padding: "10px", border: "1px solid #f2f2f2", },{ marginRight: 10, width: 60, height: 40, padding: "10px", border: "1px solid #f2f2f2", })} />
                      <div className="content VD" style={{ padding: '0px 0px 0px 5px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <p className="title bold-text VD" style={{ padding: 0, marginBottom: 0 }}>
                          {LanguageAttributeHandler(language, item?.attributes.car_manufacturer.attributes.title, item?.attributes.car_manufacturer.attributes.title_ar)}
                        </p>
                        <p className="sub-title VD">
                          {LanguageAttributeHandler(language, item?.attributes.car_model.title, item?.attributes.car_model.title_ar)}
                        </p>
                      </div>
                    </div>

                    <div className="VD vehicle-content-container">
                      <div className="VD vehicle-card-box">
                        <div style={{ display: 'flex', fontWeight: 500 }} className="VD">
                          <img src={userBlue.default} width='25' height='25' style={languageCondition(language,{ marginLeft: 10 },{ marginRight: 10 })} className="VD" />
                          <p className="VD">{t("Owner Name")}:</p>
                        </div>
                        <div className="bold-text VDName" style={languageCondition(language,{ marginRight: 35, marginBottom: 20, fontWeight: 'bold' },{ marginLeft: 35, marginBottom: 20, fontWeight: 'bold' })}>
                          {item?.attributes.owner_name}
                        </div>
                      </div>
                      <div>
                        <div style={{ display: 'flex', fontWeight: 500 }} className="VD">
                          <img src={userBlue.default} width='25' height='25' style={languageCondition(language,{ marginLeft: 10 },{ marginRight: 10 })} className="VDAP" />
                          <p className="VD">{t("Complex / Building Name")}:</p>
                        </div>
                        <div className="bold-text VDCComplex" style={languageCondition(language,{ marginRight: 35, marginBottom: 20, fontWeight: 'bold' },{ marginLeft: 35, marginBottom: 20, fontWeight: 'bold' })}>
                          {item?.attributes.complex_lists.map((item:any)=> {
                            return(
                                <>
                                  {item?.name} ({t(item?.vehicle_approval_status)}) <br/>
                                </>
                            )
                          })}
                        </div>
                      </div>
                      <div className="VD">
                        <div style={{ display: 'flex', fontWeight: 500 }} className="VD">
                          <img src={CarBlue} width='25' height='25' style={languageCondition(language,{ marginLeft: 10 },{ marginRight: 10 })} className="VDMAN" />
                          <p className="VD">{t("Car Manufacturer")}:</p>
                        </div>
                        <div className="bold-text VDManufacturer" style={languageCondition(language,{ marginRight: 35, marginBottom: 20, fontWeight: 'bold' },{ marginLeft: 35, marginBottom: 20, fontWeight: 'bold' })}>
                          {LanguageAttributeHandler(language, item?.attributes.car_manufacturer.attributes.title, item?.attributes.car_manufacturer.attributes.title_ar)}
                        </div>
                      </div>
                      <div className="VD">
                        <div className="VD" style={{ display: 'flex', fontWeight: 500 }}>
                          <img src={List} width='25' height='25' style={languageCondition(language,{ marginLeft: 10 },{ marginRight: 10 })} className="plateNumber" />
                          <p className="VD">{t("Plate Number")}:</p>
                        </div>
                        <div className="VD bold-text PlateNUmber" style={languageCondition(language,{ marginRight: 35, marginBottom: 20, fontWeight: 'bold' },{ marginLeft: 35, marginBottom: 20, fontWeight: 'bold' })}>
                          {item?.attributes.plate_number}
                        </div>
                      </div>
                      <div>
                        <div className="VD" style={{ display: 'flex', fontWeight: 500 }}>
                          <img className="VDCOlor" src={paletteBlue} width='25' height='25' style={languageCondition(language,{ marginLeft: 10 },{ marginRight: 10 })}  />
                          <p className="VD">{t("Car Color")}:</p>
                        </div>
                        <div className="bold-text VDColor" style={languageCondition(language,{ marginRight: 35, marginBottom: 20, fontWeight: 'bold' },{ marginLeft: 35, marginBottom: 20, fontWeight: 'bold' })}>
                          {LanguageAttributeHandler(language, item?.attributes.car_color.title, item?.attributes.car_color.title_ar)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid >
                {
                  item?.attributes.status === "Rejected" &&
                  <Grid item xs={12} className="VD" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="card" style={{
                      width: "80%", padding: '1rem', boxShadow: 'none',
                      margin: 8, border: '1px solid #f0f0f0',
                      background: "white", marginTop: '10px'
                    }}>
                      <Typography className={"bold-text VD"} color={"error"}>
                        {t("Reject Reason")}:
                      </Typography>
                      <Typography className="VD">
                        {item?.attributes.reject_note[item.attributes.reject_note?.length - 1]?.reject_note}
                      </Typography>
                    </div>
                  </Grid>
                }
                <Grid item xs={12} className="VD" style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                  <div className="card VD" style={{
                    width: "80%", padding: '1rem',boxShadow: 'none',
                    margin: 8,border: '1px solid #f0f0f0',
                    background: "white",marginTop: '10px'
                  }}>
                    <p className="bold-text VD" style={{ fontWeight: 800, fontSize: '1rem', marginBottom: '0.5rem' }}>
                      {t("ID")}
                    </p>
                    <img className="VD" src={item?.attributes?.registration_card_copy?.url} width='100%' style={{ borderRadius: "8px" }} alt="Vehicle id" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid  item xs={12} md={5} className="auth-cols VD">
            <Box className="VD right-block" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo" alt="" />
            </Box>
          </Grid>
        </Grid>

        <Dialog
          className="delete-document personal VD DeleteDailog" fullWidth
          onClose={() => this.setState({ showDialog: false })} open={this.state.showDialog}
        >
          <DialogContent className="VD">
            <Box textAlign="center" className="VD">
              <img src={deleteI} alt="ExclamationIcon" className="VD" />
              <Typography variant="h6" className="bold-text VD">
                {t("Delete registered")} <br /> {t("vehicle confirmation")}
              </Typography>
              <Typography variant="body1" className="VD">
                {t("You would like to unregister the vehicle? Upon doing that you may not be able to enter the building with this vehicle")}
              </Typography>
              <DialogActions className="dialog-button-group VD">
                <Button
                  className="VD deleteConfirmButton"
                  onClick={() => this.deleteRequest()}
                >
                  {t("Yes, Delete")}
                </Button>
                <Button className="VD deleteCancelButton" onClick={() => this.setState({ showDialog: false })}>{t("No, Don’t Delete")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default withTranslation()(withRouter(ViewVeichle));

// Customizable Area End
