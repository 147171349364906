//Customizable Area Start
import React from "react";
import CEPollSurveyListWrapperController, { Props } from "./CEPollSurveyListWrapperController.web";
import CEPollSurveyList from "./CEPollSurveyList.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";

export default class CEPollWrappepr extends CEPollSurveyListWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <CEHeaderComponentWeb>
        <CEPollSurveyList navigation={this.props.navigation} />
      </CEHeaderComponentWeb>
    );
  }
}
//Customizable Area End
