import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {RouteComponentProps} from 'react-router';
import * as Yup from 'yup';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { valueContainerCSS } from "react-select/src/components/containers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps{
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  assignProvider: any,
  enablePasswordField: boolean;
  email: string;
  lastName: string;
  passwordHelperText: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  password: string;
  countryCodeSelected: string;
  phone: string;
  userType: string | null;
  error: string | null;
  loading: boolean;
  enableReTypePasswordField: boolean;
  userTypeData:any;
  anchorEl :any ;
  anchorEl_1 :any ;
  getIncidentDetails : any;
  sortBy : any ;
  statusShowDialog:any;
  status : any;
  buildingName:any;
  statusDetail : any;
  imageShowDialog:any;
  providerWork:any;
  incidentRelatedData:any;
  providerName:any;
  provider_id : any ;
  file:any;
  commonAreaData:any;
  classifiedsListing:any[];
  unitName : any;
  serachBuildingName:any;
  SearchIncident:any;
  showDialog:any;
  unitNameData:any;
  providerNameListing:any;
  providerListing:any;
  buildingNameData:any[];
  classifiedType:any;
  addNote:any;
  getClassifiedDetails:any;
  ignoreShowDialog:any;
  UnpublishedShowDialog:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export default class ClassifiedManagerContorller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  createAccountApiCallId: any;


  validationApiCallId: any;
  getClassifiedsListingApiCallId: any;
  getClassifiedDetailsByIdApiCallId : any ;
  getBuildingNameApiCallId : any ;
  getUnitRelatedApiCallId:any ;
  rejectedOrPublishedAPICallId:any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
     //@ts-ignore
    this.state = {
      // Customizable Area Start
      firstName: "",
      email: "",
      password: "",
      lastName: "",
      enablePasswordField: true,
      data: [],
      passwordHelperText: "",
      otpAuthToken: "",
      error: null,
      countryCodeSelected: "",
      reTypePassword: "",
      phone: "",
      userType:'',
      loading: false,
      enableReTypePasswordField: true,
      incidentRelatedData:null,
      anchorEl:null,
      commonAreaData:null,
      anchorEl_1:null,
      getIncidentDetails:null,
      buildingName : "",
      unitName : "",
      status :"",
      serachBuildingName:" ",
      imageShowDialog:false,
      file:{},
      buildingNameData:[],
      statusShowDialog:false,
      SearchIncident:null,
      getClassifiedDetails:null,
      ignoreShowDialog:false,
      UnpublishedShowDialog:false,
      showDialog:false,
      unitNameData:null,
      classifiedType : "",
      classifiedsListing:[],
      addNote:"",
    
      // Customizable Area End
    };

    // Customizable Area Start
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.arrayholder = [];
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;

    // Customizable Area End
  }

  handleRejectOrPublish = (responseJson:any) => {
      if (responseJson.data) {
          const id = localStorage.getItem("classifiedManagerDetailId")
          this.getClassifiedDetailsById(id);
          this.setState({loading: false})
      } else if (responseJson?.errors) {
          let error = responseJson.errors[0] as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  getClassifiedListingResponse = (responseJson:any) => {
      if (responseJson.data) {
          this.setState({classifiedsListing :responseJson?.data})
          this.setState({loading: false})
      } else if (responseJson?.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
          this.setState({classifiedsListing:[]})
      } else {
          this.setState({ error: responseJson?.error || "Something went wrong!" });
          this.setState({classifiedsListing:[]})
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  getClassifiedDetailsByID = (responseJson:any) => {
      if (responseJson?.data ) {
          const statusDetail = responseJson?.data?.attributes?.classified_status;
          this.setState({loading: false,statusDetail ,getClassifiedDetails :responseJson?.data})
      } else if (responseJson?.errors) {
          let error = responseJson.errors[0] as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }

  getBuildingNameResponse = (responseJson:any) => {
      if (responseJson?.buildings ) {
          this.setState({buildingNameData :responseJson?.buildings})
          this.setState({loading: false})
      } else if (responseJson?.errors) {
          let error = responseJson.errors[0] as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || "Something went wrong!" });
      }
      this.parseApiCatchErrorResponse(this.state.error);
      this.setState({loading: false , error:null})
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId && responseJson) {
       if (apiRequestCallId === this.rejectedOrPublishedAPICallId) {
          this.handleRejectOrPublish(responseJson);
        }
        else if (apiRequestCallId === this.getClassifiedsListingApiCallId) {
           this.getClassifiedListingResponse(responseJson);
        }
        else if (apiRequestCallId === this.getClassifiedDetailsByIdApiCallId) {
            this.getClassifiedDetailsByID(responseJson);
        }
        else if (apiRequestCallId === this.getBuildingNameApiCallId) {
            this.getBuildingNameResponse(responseJson);
        }
      }
    }
  }
  
  
  // Define similar handler functions for other API request call IDs

  // Customizable Area Start

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

getClassifiedDetails= (id :any) => {
 localStorage.setItem("classifiedManagerDetailId",id)
 //@ts-ignore
  this.props.history.push({
    pathname: "/ClassifiedManagerDetail",
});
}

  getClassifiedsListing= ()  => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getClassifiedsListingApiCallId = requestMessage.messageId;
      this.setState({ loading: true });
      const societyID = localStorage.getItem("society_id")
     //const  url = `bx_block_custom_form/incidents`
     const  getSortByOrStatus = `bx_block_posts/classifieds/classified_list?search_building=${this.state.serachBuildingName}&filter_by=${this.state.classifiedType}&classified_status=${this.state?.status}&search_apartment=${this.state.unitName}&society_management_id=${societyID}`
    // /bx_block_posts/classifieds/classified_list?search_building=&filter_by=&classified_status= 
     requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getSortByOrStatus
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {

    }
  };

   checkClassName = (val: any) => {
    let check = ""
    if (val.attributes?.classified_status === 'Pending Approval') {
      check = "contain warning"
    } else {
      if (val.attributes?.classified_status === 'Published') {
        check = 'contain success'
      } else {
        check = 'contain danger'
      }
    }
    return check
  }
  
  getBuildingName = () => {
    try {
      const societyID = localStorage.getItem("society_id")
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getBuildingNameApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_fees_payment/invoices/buiding_list?society_management_id=${societyID}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      
    }
  };

  getClassifiedDetailsById= (id : any ,) => {
    try 
    {
      const header = 
      {
        "Content-Type": configJSON.validationApiContentType,
        token 
        :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getClassifiedDetailsByIdApiCallId = 
      requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`/bx_block_posts/classifieds/${id}`
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, 
        requestMessage);
      return true;
    } catch (error) { }
  };
    serachHandle=()=>{
        this.getClassifiedsListing()
    }

    rejectedOrPublished= (val :any)  => {
        const header = {token :localStorage.getItem("userToken")};
        const idCheck : any= localStorage.getItem("classifiedManagerDetailId")
        const formData = new FormData();
        if(val ==="Published"){
            this.setState({statusShowDialog :false});
            formData.append('classified[classified_status]',"Published");
        }
        else if(val ==="Ignore"){
            this.setState({ignoreShowDialog :false });
            formData.append('classified[classified_status]',"Ignore");
        }
        else if(val ==="Unpublished"){
            this.setState({UnpublishedShowDialog :false });
            formData.append('classified[classified_status]',"Unpublished");
        }
        else {
            formData.append('classified[classified_status]',"Rejected");
            formData.append('classified[notes]',this.state?.addNote);
            this.setState({showDialog :false})
        }
        const httpBody = formData;

        this.setState({loading: true ,showDialog :false ,})
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.rejectedOrPublishedAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_posts/classifieds/${idCheck}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            httpBody
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PatchAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

  onChange = (e: any) => {
    if (e.target.name === 'buildingName') {
        this.setState({buildingName: e.target.value})
        this.setState({serachBuildingName: e.target.value})
    } else if (e.target.name === "statusDetail") {
        //@ts-ignore
        this.setState({[e.target.name]: e.target.value})
        this.setState({statusShowDialog: true})
    }
    else {
        //@ts-ignore
        this.setState({[e.target.name]: e.target.value})
    }
  }



  // Customizable Area End
}
