// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;
  isShareModalOpen: boolean;
  receiptId: any;
  getMyReceiptList: any;

  payment_type: any;
  paid_on: any;
  status: any;
  building_name: any;
  unit_number: any;
  currency: any;
  currency_ar: any;
  paid_amount: any;
  late_charge: any;
  rent_amount: any;
  tenant_name: any;
  tenant_id: any;
  owner_name: any;
  owner_id: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getMyReceiptCallId: any;
  downloadIDPdf: any;
  getMyRentReceiptCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      isShareModalOpen: false,

      getMyReceiptList: null,

      receiptId: "",
      payment_type: "",
      paid_on: "",
      status: "",
      building_name: "",
      unit_number: "",
      currency: "",
      currency_ar: "",
      paid_amount: "",
      late_charge: "",
      rent_amount: "",
      tenant_name: "",
      tenant_id: "",
      owner_name: "",
      owner_id: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const receipt_id = this.props.navigation.getParam("id");
    const type = this.props.navigation.getParam("type");

    this.setState({ receiptId: receipt_id, payment_type: type }, () => {
      if (type === "rent") {
        this.getMyShowRentReceipt();
      } else if (type === "management fee" || type === "facility rental") {
        this.getMyShowReceipt();
      }
    });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.getMyReceiptCallId:
          this.getMyReceiptResponse(responseJson);
          break;
        case this.getMyRentReceiptCallId:
          this.getMyRentReceiptResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  getMyShowReceipt = async () => {
    this.getMyReceiptCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/receipts/tenant_receipt_details?id=${
        this.state.receiptId
      }&invoice_type=management fee`,
    });
  };

  getMyShowRentReceipt = async () => {
    this.getMyRentReceiptCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_fees_payment/receipts/tenant_receipt_details?id=${this.state.receiptId}&invoice_type=rent`,
    });
  };

  ReciptsDownload = async (selectedId: any) => {
    if (this.state.payment_type === "rent") {
      await this.downloadPdf(
        `/bx_block_fees_payment/receipts/${selectedId}/download_receipt.pdf`, `rent_receipt.pdf`
      );
    } else {
      await this.downloadPdf(
        `/bx_block_fees_payment/receipts/${selectedId}/download_receipt.pdf`, `receipt.pdf`
      );
    }
  };

  getMyReceiptResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        paid_on: responseJson.data.attributes.paid_on,
        status: responseJson.data.attributes.status,
        building_name: responseJson.data.attributes.building_name,
        unit_number: responseJson.data.attributes.unit_number,
        currency: responseJson.data.attributes.currency.currency,
        currency_ar: responseJson.data.attributes.currency.currency_ar,
        paid_amount: responseJson.data.attributes.paid_amount,
        late_charge: responseJson.data.attributes.late_charge,
        rent_amount: responseJson.data.attributes.amount,
        tenant_name: responseJson.data.attributes.tenant_details.tenant_name,
        tenant_id: responseJson.data.attributes.tenant_details.tenant_id,
        owner_name: responseJson.data.attributes.owner_details.owner_name,
        owner_id: responseJson.data.attributes.owner_details.owner_id,
      });
    }
  };

  getMyRentReceiptResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        paid_on: responseJson.data.attributes.paid_date,
        status: responseJson.data.attributes.status,
        building_name: responseJson.data.attributes.building_name,
        unit_number: responseJson.data.attributes.apartment_name,
        currency: responseJson.data.attributes.currency.currency,
        currency_ar: responseJson.data.attributes.currency.currency_ar,
        paid_amount: responseJson.data.attributes.received_amount,
        late_charge: responseJson.data.attributes.late_charge,
        rent_amount: responseJson.data.attributes.rent_amount,
        tenant_name: responseJson.data.attributes.tenant_details.tenant_name,
        tenant_id: responseJson.data.attributes.tenant_details.tenant_id,
        owner_name: responseJson.data.attributes.owner_details.owner_name,
        owner_id: responseJson.data.attributes.owner_details.owner_id,
      });
    }
  };

  handleReceiptShareModal = () => {
    this.setState({
      ...this.state,
      isShareModalOpen: !this.state.isShareModalOpen,
    });
  };
}

// Customizable Area End
