// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  withStyles,
  Divider,
  Button,
  Drawer,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CEPollSurveyDetailsController, { Props } from "./CEPollSurveyDetailsController.web";
import { withTranslation } from "react-i18next";
import { CheckMark, awated, buildingGrey, calendar, infoIcon } from "../assets";
import { withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

class CEPollSurveyDetails extends CEPollSurveyDetailsController {
  constructor(props: Props) {
    super(props);
  }

  getIconText = (label: string, value: string, icon: string) => (
    <Box display="flex" alignItems="center" style={{ gap: "18px" }}>
      <img height="20" width="20" src={icon} alt={label} />
      <Box>
        <Typography style={{ color: "#6F767E", fontSize: "12px" }}>{label}</Typography>
        <Typography className="bold-text" style={{ color: "#1A1D1F", fontSize: "15px" }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );

  getIconsData = (icon: string, value: number, text: string) => (
    <Box style={{ display: "flex", gap: "12px", height: "48px", alignItems: "center" }}>
      <img height={18} width={18} src={icon} alt={text} />
      <Typography className="bold-text" style={{ color: "#181D25", fontSize: "16px" }}>
        {value + ""} {text}
      </Typography>
    </Box>
  );

  getPollCard = () => (
    <Box>
      <Typography className="bold-text" style={{ fontSize: "20px" }}>
        {this.state.poll.question}
      </Typography>
      <Box style={{ display: "flex", flexDirection: "column", gap: "20px", margin: "30px 0 20px" }}>
        {this.state.poll.option.map((option: any) => (
          <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <Box style={webStyle.pollSection}>
              <Typography className="bold-text" style={{ fontSize: "18px", color: this.getPollCardColor() }}>
                {option.text}
              </Typography>
              <Typography className="bold-text" style={{ color: "#272B30", fontSize: "20px" }}>
                {option.answer_percentage}%
              </Typography>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "inherit",
                  width: `${option.answer_percentage}%`,
                  background: "rgba(27, 205, 71, 0.12)",
                  borderRadius: "8px",
                }}
              ></Box>
            </Box>
            {!(this.state.type === "Polls" && this.state.isPollAnonyms) && (
              <Button
                data-test-id="voted-button"
                className="bold-text vote-response-button"
                onClick={() => {
                  this.setState(
                    {
                      isDrawerOpen: true,
                      drawerItem: { ...this.state.drawerItem, people: option.answer_count, vote: option.text },
                      optionId: option.id,
                    },
                    () => {
                      this.getResponseOptionUser("");
                    }
                  );
                }}
                style={webStyle.peopleVoted}
              >
                {option.answer_count + ""} People Voted
              </Button>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );

  render() {
    const { t }: any = this.props;

    return (
      <Box style={{ padding: "0 40px" }}>
        {/* Drawer */}
        <Drawer anchor="right" open={this.state.isDrawerOpen} onClose={this.handleDrawerClose}>
          <Box minWidth="600px">
            <Box style={webStyle.drawerTopSection}>
              <Typography className="bold-text" style={{ fontSize: "18px" }}>
                People voted for " {this.state.drawerItem.vote.toUpperCase()} "
              </Typography>
              <CloseIcon style={{ cursor: "pointer" }} onClick={this.handleDrawerClose} />
            </Box>
            <Divider />
            <Box style={{ border: "1px solid #e0e0e0", margin: "15px", borderRadius: "8px" }}>
              <Box style={webStyle.drawerTopSection}>
                <Typography className="bold-text" style={{ fontSize: "18px" }}>
                  {this.state.drawerItem.people} people
                </Typography>
                <TextField
                  type="name"
                  className="compempemail MyteamTextField"
                  name="name"
                  id="outlined-basic"
                  placeholder="Search"
                  variant="outlined"
                  data-test-id="search-name-input"
                  inputProps={{
                    placeholder: "Search",
                  }}
                  onChange={(e: any) => this.getResponseOptionUser(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "grey" }} />
                      </InputAdornment>
                    ),
                    style: {
                      height: "42px",
                      borderRadius: "8px",
                      border: "1px solid #e0e0e0",
                    },
                  }}
                />
              </Box>
              <Divider />
              <Box></Box>
              <TableContainer>
                <Table className="table-box">
                  <TableHead>
                    <TableRow className="login-h1 bold-text">
                      <TableCell className="login-h1 bold-text">#</TableCell>
                      <TableCell className="login-h1 bold-text">Name</TableCell>
                      <TableCell className="login-h1 bold-text">Unit Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.drawerItem.data.length > 0 ? (
                      this.state.drawerItem.data.map((item: any, index: any) => {
                        const { attributes } = item;
                        return (
                          <TableRow key={index}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{attributes.full_name}</TableCell>
                            <TableCell>{attributes.unit_number.toString()}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>No record found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Drawer>

        {/* Main Page */}
        <Typography style={{ fontSize: "16px", marginBottom: "10px" }}>
          <Typography data-test-id="poll-survey-button" component="span" onClick={this.navigateToPollSurvey} style={{ cursor: "pointer" }}>
            Activities / Poll and survey
          </Typography>
          <Typography component="span">/</Typography>
          <Typography component="span" style={{ color: "rgb(43, 111, 237)" }}>
            {this.state.type} Details
          </Typography>
        </Typography>
        <Box className="Box12" style={webStyle.pageAction}>
          <Box>
            <Typography className="login-h1 bold-text" style={{ fontSize: "30px" }}>
              {this.state.type} Details
            </Typography>
          </Box>
        </Box>
        <br />
        <Box className="dashboard_card" p={3}>
          <Box display="flex" justifyContent="space-between">
            <Typography style={{ fontSize: "16px" }}>
              {this.state.type} Name: <span className="bold-text">{this.state.name}</span>
            </Typography>
            <Box display="flex" style={{ gap: "10px" }}>
              {this.state.type === "Polls" && this.state.isPollAnonyms && (
                <Box className="bold-text" style={webStyle.anonymousContainer}>
                  Anonymous Poll
                </Box>
              )}
              <Box
                className="bold-text"
                style={webStyle.statusContainer}
                sx={{ bgcolor: this.getBackgroundColors(this.state.status) + "20", color: this.getBackgroundColors(this.state.status) }}
              >
                {this.state.status}
              </Box>
            </Box>
          </Box>
          <Box display="flex" style={{ gap: "25px", margin: "15px 0", justifyContent: "space-between" }}>
            {this.getIconText("Complex", this.state.complex, buildingGrey)}
            {this.getIconText("Building", this.state.building, buildingGrey)}
            {this.state.startDate && this.getIconText("Start Date", moment(this.state.startDate, "DD-MM-YYYY").format("MMMM DD, YYYY"), calendar)}
            {this.state.endDate && this.getIconText("End Date", moment(this.state.endDate, "DD-MM-YYYY").format("MMMM DD, YYYY"), calendar)}
          </Box>
          <Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "16px" }}>Description </Typography>
              <img src={infoIcon} height={13} width={13} alt="info-icon" />
            </Box>
            <Typography
              style={{ color: "#1A1D1F", fontSize: "14px", marginTop: "10px" }}
              dangerouslySetInnerHTML={{ __html: this.state.description }}
            />
            {this.state.type === "Surveys" && (
              <Typography style={{ fontSize: "16px", marginTop: "20px" }}>
                Target Audience:{" "}
                <Typography style={{ color: "#2B6FED" }} className="bold-text" component="span">
                  {this.state.targetAudience}
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>
        <br />
        <Box
          position="relative"
          className="dashboard_card"
          minHeight="50px"
          p={2}
          style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}
        >
          <Box>
            {this.state.type === "Polls" && this.getPollCard()}
            <Box style={{ display: "flex", gap: "40px", alignItems: "center" }}>
              {this.getIconsData(awated, this.state.awaited, "Awaited")}
              {this.getIconsData(CheckMark, this.state.received, "Response received")}
            </Box>
          </Box>
          {!(this.state.type === "Surveys" && this.state.role === "sales_manager") && (
            <CommonButton t={t} label="Generate Report" color="blue" onClick={this.navigateToPollSurveyReports} />
          )}
        </Box>
        <br />
        {this.state.type === "Surveys" && this.state.surveys.length > 0 && (
          <Box className="dashboard_card" p={3}>
            <Typography style={{ color: "#272B30", fontSize: "16px", marginBottom: "20px" }}>{this.state.surveys.length} Questions</Typography>
            {this.state.surveys.map((queAns: any, index: number) => (
              <Box key={index}>
                <Typography className="bold-text" style={{ color: "#272B30", fontSize: "20px", marginBottom: "12px" }}>
                  Q{index + 1}. {queAns.title}
                </Typography>
                <Box style={{ textTransform: "capitalize" }}>
                  <Typography component="span" style={{ fontSize: "16px", padding: 0 }}>
                    {queAns.question_type}{" "}
                    {queAns.survey_options && queAns.survey_options.length > 0 && <span style={{ color: "#D8D8D8" }}> | </span>}
                  </Typography>
                  {queAns.survey_options &&
                    queAns.survey_options.length > 0 &&
                    queAns.survey_options.map((options: any, index: number) => (
                      <Typography style={{ color: "#272B30", fontSize: "16px" }} component="span">
                        {alphabet[index]}.{options.text}{" "}
                      </Typography>
                    ))}
                </Box>
                {index !== this.state.surveys.length - 1 && <Divider style={{ margin: "20px 0" }} />}
              </Box>
            ))}
          </Box>
        )}
        <br />
        {this.state.type === "Polls" && this.state.status === "ongoing" && (
          <Box style={{ textAlign: "end" }}>
            <CommonButton t={t} label="End Poll" color="blue" onClick={this.endPollStatus} />
          </Box>
        )}
        {
          console.log("this.state.type", this.state.type)
        }
        {
          this.state.type === "Surveys" && this.state.status === "ongoing" && (
                <Box style={{ textAlign: "end" }}>
                  <CommonButton t={t} label="Suspend Survey" color="blue" onClick={this.endSurveyStatus} />
                </Box>
          )
        }
      </Box>
    );
  }
}
const webStyle: any = {
  drawerTopSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
    alignItems: "center",
  },
  peopleVoted: {
    border: "1px solid #2B6FED",
    borderRadius: "8px",
    width: "190px",
    height: "50px",
    color: "#2B6FED",
    fontWeight: "bold",
    fontSize: "16px",
  },
  pollSection: {
    padding: "0 20px",
    boxSizing: "borderBox",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    background: "#FFF",
    width: "450px",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  cardSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "20px",
  },
  anonymousContainer: {
    borderRadius: "18px",
    border: "1px solid #2B6FED",
    width: "164px",
    height: "36px",
    color: "#2B6FED",
    fontFamily: "CenturyGothic",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "36px",
  },
  statusContainer: {
    padding: "0 24px",
    width: "fit-content",
    textTransform: "Capitalize",
    borderRadius: "18px",
    fontSize: "16px",
    textAlign: "center",
    height: "36px",
    lineHeight: "36px",
  },
};

export default withTranslation()(withStyles({})(withRouter(CEPollSurveyDetails)));
// Customizable Area End
