// Customizable Area Start
import React from "react";
import { Button, Container, IconButton, withStyles, Box, Grid } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SelectedTemplateController, { Props } from "./SelectedTemplateController.web";
import { ContractsStyleWeb } from "./ContractsStyle.web";
import { withTranslation } from "react-i18next";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SidebarImageComponent from "../../../components/src/OwnerSidebarImage.web";

class SelectedTemplate extends SelectedTemplateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Box dir={languageCondition(language, "rtl", "ltr")} style={{ background: "white", height: "100vh", overflowY: "hidden" }} className={classes.commonPage}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box className="faq-step">
                <Box display={{ xs: "flex", md: "flex" }} className="top-bar">
                  <div className="left-icon">
                    <IconButton className="select-template-back-btn" onClick={() => this.goBackPage()}>
                      <KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} />
                    </IconButton>
                    <span className="bold-text">{t("Issue a Lease")}</span>
                  </div>
                </Box>
                <Container className="page-container">
                  <div className="template-box">
                    <div className="template-view">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: LanguageAttributeHandler(language, this.state.template, this.state.template_ar),
                        }}
                      />
                    </div>
                    <div className="upload-button">
                      <Button onClick={() => this.gotoContractFrom()}>{t("Use This Template")}</Button>
                    </div>
                  </div>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <SidebarImageComponent />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withTranslation()(withStyles(ContractsStyleWeb)(SelectedTemplate));
// Customizable Area End
