export const BuildingLogo = require("../assets/building.png");
export const CommentImage = require("../assets/comment.png");
export const QuestionImage = require("../assets/question.png");
export const Close = require("../assets/image_close.png");
export const Down = require("../assets/image_dropdown.png");
export const Upload = require("../assets/image_upload.png");
export const Bin = require("../assets/bin.png");
export const Edit = require("../assets/edit.png");
export const Msg = require("../assets/msg.png");
export const SearchIcon = require("../assets/search.png");
export const Clock=require("../assets/image_clock.png")
export const Cat=require("../assets/image_cat.png")
export const Ticket=require("../assets/image_opentickets.png")
