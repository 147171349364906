// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {}

interface SS {
  id: any;
}

export default class AddUnitsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {};

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
// Customizable Area End
