// Customizable Area Start
import React from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { languageCondition } from "../ConditionManager.web";

const CategoryBox = ({ language, image, heading, value }: any) => {
  return (
    <Box className="item">
      <div className="heading" style={{ gap:"10px" }}>
        <img src={image} />
        <h4 className="bold-text" style={{ margin:"0px" }}>{heading}</h4>
      </div>
      <div className="document-btn-box">
        {value > 0 && <Button className="color-btn">{value}</Button>}
        <IconButton>
          <ChevronRightIcon className={languageCondition(language, "KeyboardForwardIconAR", "")}/>
        </IconButton>
      </div>
    </Box>
  );
};

export default CategoryBox;
// Customizable Area End
