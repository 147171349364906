import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isActionAssignOpen: boolean;

  actionAssignTeamTaskList: any;

  teamReactStateList: any[];
  complexList: any[];
  teamComplexList: any[];
  teamFilterComplexList: any[];
  teamBuildingList: any[];
  teamAssignList: any[];
  teamAssignToList: any[];

  reactEstate: string;
  complex: string;
  building: string;
  assign: string;

  filterValue: {
    reactEstate: string;
    complex: string;
    building: string;
    assign: string;
  };

  actionForm: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEActionsTeamController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetActionAssignTeamTaskCallId: any;
  GetActionTeamReactEStateCallId: any;
  GetActionTeamComplexCallId: any;
  GetActionTeamBuildingCallId: any;
  GetActionTeamAssignCallId: any;
  GetActionTeamAssignToCallId: any;
  PostActionAssignCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isActionAssignOpen: false,

      actionAssignTeamTaskList: [],

      teamReactStateList: [],
      complexList: [],
      teamComplexList: [],
      teamFilterComplexList: [],
      teamBuildingList: [],
      teamAssignList: [],
      teamAssignToList: [],

      reactEstate: "",
      complex: "",
      building: "",
      assign: "",

      filterValue: {
        reactEstate: "",
        complex: "",
        building: "",
        assign: "",
      },

      actionForm: {
        title: "",
        description: "",
        realStateCompany: "",
        isComplex: "complex",
        complex: "",
        building: "",
        assignTo: "",
        dueOn: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetActionAssignTeamTaskCallId:
          this.handleActionAssignTeamTaskResponse(responseJson);
          break;
        case this.GetActionTeamReactEStateCallId:
          this.handleActionTeamReactEstateResponse(responseJson);
          break;
        case this.GetActionTeamComplexCallId:
          this.handleActionTeamComplexResponse(responseJson);
          break;
        case this.GetActionTeamBuildingCallId:
          this.handleActionTeamBuildingResponse(responseJson);
          break;
        case this.GetActionTeamAssignCallId:
          this.handleActionTeamAssignResponse(responseJson);
          break;
        case this.GetActionTeamAssignToCallId:
          this.handleActionTeamAssignToResponse(responseJson);
          break;
        case this.PostActionAssignCallId:
          this.setState({ loading: false }, () => {
            this.getActionAssignTeamTask();
          });
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getActionTeamReactEstate();
    await this.getActionTeamComplex("");
    await this.getActionAssignTeamTask();
    await this.getActionTeamAssign();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.reactEstate !== this.state.reactEstate) {
      await this.getActionTeamComplex("");
    }
    if (prevState.complex !== this.state.complex) {
      await this.getActionTeamBuilding("");
    }

    if (
      prevState.filterValue.reactEstate !== this.state.filterValue.reactEstate ||
      prevState.filterValue.complex !== this.state.filterValue.complex ||
      prevState.filterValue.building !== this.state.filterValue.building ||
      prevState.filterValue.assign !== this.state.filterValue.assign
    ) {
      this.getActionAssignTeamTask();
    }
  }

  getActionAssignTeamTask = async () => {
    const { reactEstate, complex, building, assign } = this.state.filterValue;
    this.GetActionAssignTeamTaskCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/assigned_action_team?company_id=${reactEstate}&complex_id=${complex}&building_id=${building}&assigned_by_id=${assign}`,
    });
  };

  handleActionAssignTeamTaskResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ actionAssignTeamTaskList: responseJson.data });
    }
  };

  getActionTeamReactEstate = async () => {
    this.GetActionTeamReactEStateCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  handleActionTeamReactEstateResponse = (responseJson: any) => {
    if (responseJson && responseJson.compneys) {
      this.setState({ teamReactStateList: responseJson.compneys });
    }
  };

  getActionTeamComplex = async (realEstateId: any) => {
    const { reactEstate } = this.state;
    this.GetActionTeamComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/complex_list?real_estate_company_id=${reactEstate || realEstateId}`,
    });
  };

  handleActionTeamComplexResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({
        complexList: responseJson.complexes,
        teamComplexList: responseJson.complexes,
        teamFilterComplexList: responseJson.complexes,
      });
    }
  };

  getActionTeamBuilding = async (complexId: any) => {
    const { complex } = this.state;
    this.GetActionTeamBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/building_list?society_management_id=${complex || complexId}`,
    });
  };

  handleActionTeamBuildingResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ teamBuildingList: responseJson.buildings });
    }
  };

  getActionTeamAssign = async () => {
    this.GetActionTeamAssignCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/assigend_by_list`,
    });
  };

  handleActionTeamAssignResponse = (responseJson: any) => {
    if (responseJson && responseJson.assign_by_list) {
      this.setState({ teamAssignList: responseJson.assign_by_list });
    }
  };

  getActionTeamAssignTo = async (complex: any) => {
    this.GetActionTeamAssignToCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/assigend_to_list?complex_id=${complex}`,
    });
  };

  handleActionTeamAssignToResponse = (responseJson: any) => {
    if (responseJson && responseJson.assigned_to_list) {
      this.setState({ teamAssignToList: responseJson.assigned_to_list });
    }
  };

  handleActionAssignModal = () => {
    this.setState({ isActionAssignOpen: !this.state.isActionAssignOpen });
  };

  handleSubmitAssignForm = async (values: any) => {
    let formData = new FormData();
    formData.append("assign_action_team[title]", values.title);
    formData.append("assign_action_team[description]", values.description);
    formData.append("assign_action_team[real_estate_company_id]", values.realStateCompany);
    formData.append("assign_action_team[society_management_id]", values.complex);
    formData.append("assign_action_team[building_management_id]", values.building);
    formData.append("assign_action_team[assignee_id]", values.assignTo);
    formData.append("assign_action_team[due_date]", values.dueOn);

    this.PostActionAssignCallId = await CEApiCall({
      method: "POST",
      endPoint: `bx_block_dashboard/assign_action_team`,
      body: formData,
    });
  };

  handleTeamCompanyName = (task: any) => {
    if (task.real_estate_company) {
      return task.real_estate_company.company_name;
    } else {
      return "-";
    }
  };

  actionAssignValidation: any = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    isComplex: Yup.string().required("Required"),
    complex: Yup.string().required("Required"),
    building: Yup.string().when("isComplex", {
      is: "complex",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    assignTo: Yup.string().required("Required"),
    dueOn: Yup.string().required("Required"),
  });
  // Customizable Area End
}
