export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Building1 = require("../assets/building1.png");
export const Building_Logo = require("../assets/BuildingLogo.png");
export const pollandsurvey = require("../assets/pollandsurvey.png");
export const pollcreate = require("../assets/pollcreate-2.png");
export const surveycreate = require("../assets/surveycreate.png");
export const xmark = require("../assets/x-mark.png");
export const CheckMark = require("../assets/Check_Mark.png");
export const awated = require("../assets/awated.png");
export const Cardcalendar = require("../assets/Card-calendar.png");
export const Filter_Icon = require("../assets/filter-icon.png");
export const Tenant_Logo = require("../assets/TenantLogo.png");
export const filterIcon = require("../assets/filter-icon.png");
export const clipBoard = require("../assets/Clipboard.png")
export const allUsers = require("../assets/allUsers.png")
export const audienceCheck= require("../assets/AudianceCheck.png")
export const audienceChecked= require("../assets/SelectedAudiance.png")
export const success = require("../assets/suceess.png");
export const building = require("../assets/building.svg");
export const calendar = require("../assets/calendar.svg");
export const buildingGrey = require("../assets/building_grey.svg");
export const infoIcon = require("../assets/info_icon.svg");

