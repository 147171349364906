// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openmanageSub: any;
  }
export const ManageSubImageComponent:React.FC<MyComponentProps>  = ({openmanageSub}) => {
    const strokeColor = openmanageSub 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="body_1" width="32" height="38">

    <g transform="matrix(0.76190484 0 0 0.74509805 0 0)">
        <g transform="matrix(0.875 0 0 0.875 -0 0.24999905)">
            <g transform="matrix(0.1 0 -0 -0.1 0 58)">
                <path d="M275 551C 272 546 274 539 280 535C 303 521 287 510 241 510C 134 510 34 421 23 315C 17 263 36 184 56 176C 78 168 80 183 64 236C 26 362 122 480 264 480C 290 480 299 466 280 455C 263 445 269 430 289 430C 300 430 320 444 335 461L335 461L361 493L329 526C 294 562 285 566 275 551z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
                <path d="M404 396C 401 388 404 372 410 359C 428 327 425 249 405 209C 380 160 335 124 285 111C 207 91 172 98 202 128C 211 137 209 141 196 146C 185 150 169 143 149 124C 133 109 120 94 120 91C 120 88 134 70 151 52C 170 30 187 20 197 24C 211 30 211 33 195 50C 177 70 178 70 231 70C 357 70 460 170 460 291C 460 356 419 434 404 396z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
                <path d="M208 371C 158 321 178 270 245 270C 289 270 268 246 219 240C 173 235 159 217 198 212C 210 210 220 202 220 194C 220 172 239 177 271 207C 323 256 304 310 235 310C 191 310 212 335 258 338C 287 340 300 346 300 356C 300 364 291 370 281 370C 271 370 260 377 256 385C 248 406 239 404 208 371z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
            </g>
        </g>
    </g>
    </svg>
  );
};
// Customizable Area End
