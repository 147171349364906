import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
interface CreatedData {
    total: number
    lastCreated: string;
}

interface RecentData {
    status: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    address: string;
    participants: number;
    checked?: number;
    process?: number;
    unchecked?: number;
    id: string;
}
// Customizable Area End


export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    classes?: any;
    history: any;
    location: any;
    match: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    id: any;
    role: any;
    pollsCreated: CreatedData;
    surveysCreated: CreatedData;
    recentPolls: any[];
    recentSurveys: any[];
    city: string;
    building: string;
    year: string;
    company: string;
    country: string;
    complex: string;
    salesManager: string;
    sortBy: string;
    companyList: string[];
    buildingList: string[];
    yearList: string[];
    salesManagerList: string[];
    countryList: string[];
    cityList: string[];
    complexList: string[];
    sortList: string[];
    showDialog:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CEPollSurveyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallId: string = "";
    classes: any;
    GetRecentPollsList:any;
    GetRecentSurveysList:any;
    getCountPolls:any;
    getCountSurveys:any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            id: '',
            pollsCreated: {
                total: 0,
                lastCreated: '-'
            },
            surveysCreated: {
                total: 0,
                lastCreated: '-'
            },
            recentPolls: [],
            recentSurveys: [],
            role: '',
            city: '',
            building: '',
            year: '',
            company: '',
            country: '',
            complex: '',
            salesManager: '',
            sortBy: '',
            companyList: ['Arrow realtors', 'Liven Realty'],
            buildingList: ['Crystal Luxuria', 'Raghuveer Towers'],
            yearList: ['2023', '2022'],
            countryList: ['UK', 'India'],
            cityList: ['Indore', 'Mumbai'],
            complexList: ['Emirates Crown', 'Lake Shore Towers'],
            salesManagerList: ['Rahul', 'Rohit'],
            sortList: ['Date', 'Time'],
            showDialog:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getDataId();
        const userRole = localStorage.getItem('role');
        this.setState({ role: userRole })
        // Customizable Area End
    }

    // Customizable Area Start

    getDataId = () => {
        const societyId = localStorage.getItem('society_id');
        this.setState({ id: societyId })
        this.getRecentPolls()
        this.getRecentSurveys()
        this.getCountPollsAPI()
        this.getCountSurveysAPI()
    }

    getRecentPolls=async()=>{
        const societyId = localStorage.getItem('society_id');
        this.GetRecentPollsList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/society_managements/${societyId}/bx_block_polling/polls/list_recent_polls`
          })
    }

    getRecentSurveys=async()=>{
        const societyId = localStorage.getItem('society_id');
        this.GetRecentSurveysList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/society_managements/${societyId}/bx_block_survey/surveys/list_recent_surveys`
          })
    }

    getCountPollsAPI=async()=>{
        const societyId = localStorage.getItem('society_id');
        this.getCountPolls = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyId}/bx_block_polling/polls/count_of_polls`
        })
    }

    getCountSurveysAPI = async()=>{
        const societyId = localStorage.getItem('society_id');
        this.getCountSurveys = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyId}/bx_block_survey/surveys/total_surveys_count`
        })
    }


    surveyReportResponse = (responseJson:any) => {
        if(responseJson.hasOwnProperty("surveys")){
            this.setState({
                recentSurveys: responseJson.surveys.data
            })
        }else{
            this.setState({
                recentSurveys: [],
            })
        }

    }
    // Add function which you required
    checkAPICallResponse(apiRequestCallId:string,responseJson:any){
        if(apiRequestCallId === this.GetRecentPollsList){
            if(!responseJson.errors){
                this.setState({
                    recentPolls: responseJson
                })
            }else{
               this.setState({
                    recentPolls: []
               })
            }
        }
        if(apiRequestCallId === this.GetRecentSurveysList){
            this.surveyReportResponse(responseJson)
        }
        if(apiRequestCallId === this.getCountPolls){
            if(responseJson.hasOwnProperty("polls_count")){
                this.setState({
                    pollsCreated: {
                        total: responseJson.polls_count,
                        lastCreated: responseJson.last_poll_created_at
                    }
                })
            }else{
                this.setState({
                    pollsCreated: {
                        total: 0,
                        lastCreated: "-"
                    }
                })
            }
        }
        if(apiRequestCallId === this.getCountSurveys){
            if(responseJson.hasOwnProperty("survey_count")){
                this.setState({
                    surveysCreated: {
                        total: responseJson.survey_count,
                        lastCreated: responseJson.last_survey_created_at
                    }
                })
            }else{
                this.setState({
                    surveysCreated: {
                        total: 0,
                        lastCreated: "-"
                    }
                })
            }
        }
    }

    getBackground = (status: string) => {
        if(status === "ongoing"){
            return '#FC8434'
        }else if(status === "upcoming"){
            return '#2B6FED' 
        }else{
            return '#1EC65B'
        }
    }

    handleViewAll = (type: string) => {
        this.props.history.push('/CompanyEmployee/CEPollSurveyList', { type })
    }

    navigateToPollSurvey = (id: string, type: string) => {
        this.props.history.push('/CompanyEmployee/CEPollSurveyDetails', { id, type: type })
    }





    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                this.checkAPICallResponse(apiRequestCallId,responseJson) //API response check function for solve code smell issue in sonar
                // Add other response function here if required
            }
        }
        // Customizable Area End
    }
}