export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_visible.png");
export const SettingIcon = require("../assets/setting.png");
export const AvatarIcon = require("../assets/avatar.png");
export const ChatIcon = require("../assets/chat.png");
export const CallIcon = require("../assets/call.png");
export const EmailIcon = require("../assets/email.png");
export const FacebookIcon = require("../assets/facebook.png");
export const InstagramIcon = require("../assets/instagram.png");
export const TwitterIcon = require("../assets/twitter.png");
export const SnapchatIcon = require("../assets/snapchat.png");
export const TimeIcon = require("../assets/time.png");
