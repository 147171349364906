// Customizable Area Start
import React from "react";
export const PollImageComponent = () => {
  return (
    <svg width="24.000244px" height="24px" viewBox="0 0 24.000244 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="poll">
        <path
          d="M6.23649 8.57141L0.857143 8.57141C0.383756 8.57141 0 8.95517 0 9.42855L0 23.1428C0 23.6162 0.383756 24 0.857143 24L6.23649 24C6.70987 24 7.09363 23.6162 7.09363 23.1428L7.09363 9.42855C7.09363 8.95517 6.70987 8.57141 6.23649 8.57141ZM5.37941 22.2857L1.71436 22.2857L1.71436 10.2857L5.37941 10.2857L5.37941 22.2857Z"
          id="Shape"
          fill-rule="evenodd"
          stroke="none"
        />
        <path
          d="M14.6894 0L9.31051 0C8.83712 0 8.45337 0.383756 8.45337 0.857143L8.45337 23.1429C8.45337 23.6162 8.83712 24 9.31051 24L14.6894 24C15.1628 24 15.5466 23.6162 15.5466 23.1429L15.5466 0.857143C15.5466 0.383756 15.1628 0 14.6894 0ZM13.8324 22.2857L10.1677 22.2857L10.1677 1.71429L13.8324 1.71429L13.8324 22.2857Z"
          id="Shape"
          fill-rule="evenodd"
          stroke="none"
        />
        <path
          d="M23.143 3.42859L17.7636 3.42859C17.2903 3.42859 16.9065 3.81234 16.9065 4.28573L16.9065 23.1429C16.9065 23.6163 17.2903 24 17.7636 24L23.143 24C23.6164 24 24.0001 23.6163 24.0001 23.1429L24.0001 4.28573C24.0001 3.81234 23.6164 3.42859 23.143 3.42859ZM22.2859 22.2857L18.6208 22.2857L18.6208 5.14288L22.2859 5.14288L22.2859 22.2857Z"
          id="Shape"
          fill-rule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
