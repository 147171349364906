// Customizable Area Start
import React from "react";
import {
    Container,
    Typography,
    withStyles,
    Box,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputBase,
    Divider,
    Button,
    Select,
    MenuItem,
} from "@material-ui/core";
import VisitorsListController, { Props } from "./VisitorsListController";
import { SuggestionStyleWeb } from "../../user-profile-basic/src/SuggestionStyle.web";
import SearchIcon from "@material-ui/icons/Search";
// @ts-ignore
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from "react-router-dom";
import { SearchIconImage} from "../../user-profile-basic/src/assets";
import {withTranslation} from "react-i18next";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

class Unit extends VisitorsListController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        // @ts-ignore
        const { t, classes,i18n }: any = this.props;
        const {language} = i18n
        return (
            <>
                <GeneralHeader>
                            <Container className={classes.announcements}>
                                <Box className="navigation">
                                    <Box>
                                        <Typography variant="body1" style={{color:"#2b6fed"}}>
                                         {"Units"}
                                            <Box component="span" style={{ color: "blue" }}>
                                                {t("")}
                                            </Box>
                                        </Typography>
                                        <Typography variant="h5" className="sub-heading bold-text">
                                            {t("Units")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={languageCondition(language, "arabic-grid top-bar", "top-bar")}>
                                    <Box className="filter">
                                        <Select displayEmpty value={this.state.buildingID} className="select-input" placeholder="Select Building" onChange={(e)=> this.setState({buildingID:e.target.value})}>
                                            <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                                                {t("Select Building")}
                                            </MenuItem>
                                            {
                                                this.state?.securityBuildingList?.length > 0 &&
                                                    this.state?.securityBuildingList?.map((item:any,key:any) => {
                                                        return(
                                                            <MenuItem value={item.id} key={key} className={languageCondition(language, "select-arabic-menu", "")}>{item.name}</MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>
                                        <Button className="serachUnitButton" onClick={()=> this.getSecurityUnitList(this.state.buildingID,1,this.state.searchQuery)} startIcon={<img src={SearchIconImage} style={{marginLeft:"10px"}} />}>{t("Search")}</Button>
                                    </Box>
                                </Box>
                                <Box className="meeting-table">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <Box className="table-top">
                                            <h3 className="bold-text">Units</h3>
                                            <Box className="filter">
                                                <Box className="search-box">
                                                    <SearchIcon />
                                                    <InputBase id={"nominateMySelf"} placeholder={t("Search")} className="search unitSearch" onChange={this.unitSearch} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Table className="table-box">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align={languageCondition(language,"right","left")}>#</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Building")}</TableCell>
                                                     <TableCell align={languageCondition(language,"right","left")}>{t("Unit Number")}</TableCell>
                                                     <TableCell align={languageCondition(language,"right","left")}>{t("Resident Name")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Owner Name")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Phone Number")}</TableCell>
                                                    <TableCell align={languageCondition(language,"right","left")}>{t("Family Members")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state?.getUnitListing?.length > 0 ?
                                                        this.state?.getUnitListing?.map((item:any,key:any)=>{
                                                            return(
                                                                <TableRow key={key} className="unitGeneralDetails" onClick={() => //@ts-ignore
                                                                this.props.history.push({pathname: "/UnitGeneralDetails",id:item.id , ownerId : item.attributes.owner?.id})} style={{cursor:"pointer"}}>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                                                    <TableCell className="ellipse" align={languageCondition(language,"right","left")}>{item.attributes.building_management.name || "-"}</TableCell>
                                                                     <TableCell align={languageCondition(language,"right","left")}>{item.attributes.apartment_name || "-"}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.resident?.full_name || "-"} </TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.owner?.full_name || "-"}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")} dir={"ltr"}>{item.attributes.resident?.full_phone_number || "-"}</TableCell>
                                                                    <TableCell align={languageCondition(language,"right","left")}>{item.attributes.family_members || 0}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                       :
                                                        <TableRow style={{cursor:"pointer"}}>
                                                            <TableCell colSpan={5} align={languageCondition(language,"right","left")}>{t("No data found..!!")}</TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                        <Divider />

                                        <Box style={{width:"100%",height:"70px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                         {
                                              this.state?.getUnitListing?.length > 0  &&
                                              <Box style={{display:"flex",marginLeft:"15px"}}>
                                                <Typography style={{marginRight:"5px"}}>{t("Showing")} </Typography>
                                                <Typography style={{marginRight:"5px",fontWeight:"bold",color:"#FC8434"}}>{this.state.unitPagination?.total_count < this.state?.count ? this.state?.unitPagination.total_count : (this.state?.count * this.state?.page)} </Typography>
                                                <Typography style={{marginRight:"5px"}}> {t("of")} </Typography>
                                                <Typography style={{fontWeight:"bold"}}>{this.state.unitPagination?.total_count} </Typography>
                                            </Box>
                                         }

                                            {
                                               this.state?.getUnitListing?.length <= 0 &&
                                                <Box style={{display:"flex",marginLeft:"15px"}} />
                                            }
                                            <Box style={{marginRight:"10px"}}>
                                                <Pagination count={this.state.unitPagination?.total_pages} onChange={this.handleUnitPagination} variant="outlined" shape="rounded" />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Container>
                </GeneralHeader>
            </>
        );
    }
}

export default withStyles(SuggestionStyleWeb)(withTranslation()(withRouter(Unit)));
// Customizable Area End
