import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import moment from "moment/moment";
// @ts-ignore
import {baseURL} from "../../../../framework/src/config.js";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  match: any;
  classes: any;
  t:any;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  CEshowDialog:boolean;
  CEloading:boolean;
  CEdeleteModal:boolean
  CEsetOpen:boolean;
  CEteamAddData:any;
  CEonGoingNomination:boolean;
  CEsetVoting:boolean;
  CEvoted:boolean;
  CEstartVotingModal:boolean;
  CEvoteConfirmModal:boolean;
  CEvotingStatus:any;
  CEselectedTab:any;
  CEdetailsModal:any;
  CEnominateMySelf:boolean;
  CEnominationId:any;
  CEnominationData:any;
  CEnomineeList:any[];
  CEdetailsForModal:any;
  CEmyProfile:any;
  CEupdateName:any;
  CEupdateStartDate:any;
  CEupdateEndDate:any;
  CEupdateDescription:any;
  CEnominationTitleError:any;
  CEnominationStartDateError:any;
  CEnominationEndDateError:any;
  CEnominationDescriptionError:any;
  CEmyNominationDescription:any;
  CEmyNominationDescriptionError:any;
  CEmyNominationAs:any;
  CEmyNominationAsError:any;
  CEnominatedSelf:boolean,
  CEvote:{voteId:any,role:any,name:any};
  CEchairmanVoteCount:any[];
  CEviceChairmanVoteCount:any[];
  CEvotedViceChairmanId:any;
  CEvotedChairmanId:any;
  CEnominationDetails:any;
  ownerId:any;
  ownerIdError:any;
  ownerList:any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CENominationDetailsController extends CommonApiCallForBlockComponent<Props, S, SS> {
  // Customizable Area Start
  CEgetNominationDetailsId:string = "";
  CEnominatedMemberListId:string = "";
  CEmyProfileId:string = "";
  CEupdateNominationId:string = "";
  CEnominateMySelfId:string = "";
  CEstartVotingCallId:string = "";
  CEendVotingCallId:string = "";
  CEnominateId:string = "";
  CEgetVotingCountDetailsId:string = "";
  CEprintRef:any;
  getOwnerListId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage),getName(MessageEnum.PostDetailDataMessage)];
    this.state = {
      // Customizable Area Start
      CEshowDialog:false,
      CEloading:false,
      CEdeleteModal:false,
      CEsetOpen:false,
      CEteamAddData:{},
      CEonGoingNomination:true,
      CEsetVoting:false,
      CEvoted:false,
      CEstartVotingModal:false,
      CEvoteConfirmModal:false,
      CEvotingStatus:"open",
      CEselectedTab:"Chairman",
      CEdetailsModal:false,
      CEnominateMySelf:false,
      CEnominationId:"",
      CEnominationData:{
        title:"",
        start_date:"",
        end_date:"",
        description:"",
        status:"",
        voting_flag:false,
        nomination_flag:false,
        complex_name:"",
      },
      CEnomineeList:[],
      CEdetailsForModal:{
        description: "",
        nominate_as: "",
        name: "",
        role: "",
        unit_number: []
      },
      CEupdateName:"",
      CEnominationTitleError:"",
      CEupdateStartDate:"",
      CEnominationStartDateError:"",
      CEupdateEndDate:"",
      CEnominationEndDateError:"",
      CEupdateDescription:"",
      CEnominationDescriptionError:"",
      CEmyProfile:{},
      CEmyNominationDescription:"",
      CEmyNominationDescriptionError:"",
      CEmyNominationAs:[],
      CEmyNominationAsError:"",
      CEnominatedSelf:false,
      CEvote:{
        voteId:"",
        role:"",
        name:"",
      },
      CEchairmanVoteCount:[],
      CEviceChairmanVoteCount:[],
      CEvotedViceChairmanId:"",
      CEvotedChairmanId:"",
      CEnominationDetails:{},
      ownerId:"",
      ownerIdError:"",
      ownerList:[],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  manageMessageDetails = (message:any) => {
    if(message === "NOMINATION_UPDATED"){
      this.setState({
        CEsetOpen:false
      })
      this.getNominationDetailsCE();
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.PostDetailDataMessage)=== message.id){
      if(message.properties.hasOwnProperty("message")){
        this.manageMessageDetails(message.properties.message)
      }
    }
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.afterReceiveBlock(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  manageShareNominationCE = async () => {
    const sCEId = localStorage.getItem("society_id")
    const nCEId =  window.location.search ? window.location.search.split("=")[1] : null;
    const uCEId = localStorage.getItem("userId")
    window.open(`whatsapp://send?text=Here is your Chairman Nomination Details : ${baseURL}/society_managements/${sCEId}/bx_block_my_team/chairman_nominations/${nCEId}/download_report.pdf?c_id=${uCEId}`,'_blank')
  }
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.nominatedMemberListCE();
    this.getNominationDetailsCE();
    this.getVotingCountDetailsCE();
    this.getOwnerList();
  }

  manageNominateCE = () => {
    if(this.state.CEmyNominationDescription !== null){
      if(this.state.CEmyNominationDescription.trim().length > 0 && this.state.CEmyNominationAs.length > 0 && this.state.ownerId !== ""){
        this.nominateOwner()
      }else{
        if(this.state.CEmyNominationDescription.trim().length <= 0){
          this.setState({
            CEmyNominationDescriptionError:"Please enter the details."
          })
        }
        if(this.state.CEmyNominationAs.length <= 0){
          this.setState({
            CEmyNominationAsError:"Please select nomination type."
          })
        }
        if(this.state.ownerId === ""){
          this.setState({
            ownerIdError:"Please select the owner."
          })
        }
      }
    }
  }

  startVotingCE = () => {
    this.setState({
      CEsetVoting:true,
      CEstartVotingModal:false
    })
    this.startVotingCallCE()
  }

  removeItemOnceCE(arr:any, value:any) {
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  manageSelectRoleCE = (e:any) => {
    let selectedRole = this.state.CEmyNominationAs
    if(selectedRole.find((item:any)=> item === e.target.value)){
      let updatedArray = this.removeItemOnceCE(selectedRole,e.target.value)
      this.setState({
        CEmyNominationAs:updatedArray,
        CEmyNominationAsError:""
      })
    }else{
      this.setState({CEmyNominationAs:[
          ...selectedRole,
          e.target.value,
        ],
        CEmyNominationAsError:""
      })
    }
  }

  handleCloseCE = () => {
    this.setState({CEsetOpen:false});
  };
  handleOpenDetailsModalCE = (data:any) => {
    this.setState({CEdetailsModal:true,CEdetailsForModal:data});
  }

  handleCloseDetailsModalCE = () => {
    this.setState({CEdetailsModal:false});
  }

  handleOpenMySelfModalCE = () => {
    this.setState({CEnominateMySelf:true});
  }

  handleCloseMySelfModalCE = () => {
    this.setState({CEnominateMySelf:false,CEmyNominationAsError:"",CEmyNominationDescriptionError:"",CEmyNominationAs:[],CEmyNominationDescription:"",ownerId:""});
  }

  getNominationDetailsCE = async () => {
    const societyID = localStorage.getItem("complex_id")
    const CEnominationId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.setState({
      CEloading:true,
      CEnominationId
    })
    this.CEgetNominationDetailsId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${societyID}/bx_block_my_team/chairman_nominations/${CEnominationId}`,
    });
  }

  nominatedMemberListCE = async () => {
    const CEnominationId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.CEnominatedMemberListId = await this.apiCall({
      contentType:"application/json",
      method:"GET",
      endPoint: `bx_block_my_team/chairman_nominations/${CEnominationId}/nominated_member_list`,
    });
  }

  getVotingCountDetailsCE = async () => {
    const societyID = localStorage.getItem("complex_id")
    const nominationIdCE =  window.location.search ? window.location.search.split("=")[1] : null;
    this.CEgetVotingCountDetailsId = await this.apiCall({
      contentType:"application/json",
      method:"GET",
      endPoint: `/society_managements/${societyID}/bx_block_my_team/chairman_nominations/${nominationIdCE}/voting_count`,
    });
  }

  startVotingCallCE = async () => {
    const societyID = localStorage.getItem("complex_id")
    const id =  window.location.search ? window.location.search.split("=")[1] : null;
    this.CEstartVotingCallId = await this.apiCall({
      contentType:"application/json",
      method:"PUT",
      endPoint: `/society_managements/${societyID}/bx_block_my_team/chairman_nominations/${id}/start_voting?start_voting=true`,
    });
  }

  endVotingCallCE = async () => {
    const societyID = localStorage.getItem("complex_id")
    const startVotingId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.CEendVotingCallId = await this.apiCall({
      contentType:"application/json",
      method:"PUT",
      endPoint: `/society_managements/${societyID}/bx_block_my_team/chairman_nominations/${startVotingId}/close_voting?end_voting=true`,
    });
  }

  nominateOwner = async () => {
    const data = {
      "nominate_myself": {
            "description":this.state.CEmyNominationDescription,
            "nominate_as":this.state.CEmyNominationAs.length === 2 ? "2" : this.state.CEmyNominationAs[0],
            "account_id": this.state.ownerId
          }
    }
    const societyID = localStorage.getItem("complex_id")
    const nominateId =  window.location.search ? window.location.search.split("=")[1] : null;
    this.CEnominateMySelfId = await this.apiCall({
      contentType:"application/json",
      method:"POST",
      endPoint: `/bx_block_my_team/company_employee_nominations/nominate_owner?society_management_id=${societyID}&nomination_id=${nominateId}`,
      body:JSON.stringify(data)
    });
  }

  getOwnerList = async () => {
    const societyID = localStorage.getItem("complex_id")
    this.getOwnerListId = await this.apiCall({
      contentType:"application/json",
      method:"GET",
      endPoint: `/bx_block_my_team/company_employee_nominations/nomination_owner_list?society_management_id=${societyID}`,
    });
  }

  nominationDetailsResponseCE = (responseJson:any) => {
    if(responseJson.hasOwnProperty("chairman_nominations")){
      this.setState({
        CEloading:false,
        CEnominationData:responseJson.chairman_nominations.data.attributes,
        CEupdateName:responseJson.chairman_nominations.data.attributes.title,
        CEupdateDescription:responseJson.chairman_nominations.data.attributes.description,
        CEupdateEndDate:moment(responseJson.chairman_nominations.data.attributes.end_date).format("YYYY-MM-DD"),
        CEupdateStartDate:moment(responseJson.chairman_nominations.data.attributes.start_date).format("YYYY-MM-DD"),
        CEnominationDetails:responseJson.chairman_nominations.data.attributes,
      })
    }
  }

  nominatedMembersListResponseCE = (responseJson:any) => {
    if(responseJson?.hasOwnProperty("nominated_members")){
      this.setState({
        CEloading:false,
        CEnomineeList:responseJson.nominated_members.data
      })
    }
  }

  handleEditDetails = () => {
    this.setState({
      CEsetOpen:true
    })
  }
  nominateMySelfResponseCE = (responseJson:any) => {
    if(responseJson.message === "Member Nominated successfully"){
      this.handleCloseMySelfModalCE()
      this.getNominationDetailsCE()
      this.nominatedMemberListCE()
    }
  }

  startVotingResponseCE = (responseJson:any) => {
    if(responseJson.code === 200){
      this.nominatedMemberListCE()
      this.getNominationDetailsCE()
    }
  }

  endVotingResponseCE = (responseJson:any) => {
    if(responseJson.code === 200){
      this.nominatedMemberListCE()
      this.getNominationDetailsCE()
      this.getVotingCountDetailsCE()
    }
  }

  votingCountResponseCE = (responseJson:any) => {
    if(responseJson.hasOwnProperty("vote_count")){
      this.setState({
        CEchairmanVoteCount:responseJson.vote_count?.data?.attributes?.chairman,
        CEviceChairmanVoteCount:responseJson.vote_count?.data?.attributes?.vice_chairman,
      })
    }
  }

  afterReceiveBlock = (apiRequestCallId:any,responseJson:any) => {
    if(apiRequestCallId === this.CEgetNominationDetailsId){
      this.nominationDetailsResponseCE(responseJson)
    }
    if(apiRequestCallId === this.CEupdateNominationId){
      this.getNominationDetailsCE()
      this.setState({
        CEsetOpen:false
      })
    }
    if(apiRequestCallId === this.CEnominatedMemberListId){
      this.nominatedMembersListResponseCE(responseJson)
    }
    if(this.CEnominateMySelfId === apiRequestCallId){
      this.nominateMySelfResponseCE(responseJson)
    }
    if(apiRequestCallId === this.CEstartVotingCallId){
      this.startVotingResponseCE(responseJson)
    }
    if(apiRequestCallId === this.CEendVotingCallId){
      this.endVotingResponseCE(responseJson)
    }
    if(apiRequestCallId === this.CEgetVotingCountDetailsId){
      this.votingCountResponseCE(responseJson)
    }
    if(apiRequestCallId === this.getOwnerListId){
      this.setState({
        ownerList:responseJson.owner_account_list
      })
    }
  }


  // Customizable Area End
}
