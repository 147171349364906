// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Link,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MeetingMinutesController, { Props } from "./MeetingMinutesController.web";
import { MeetingsStyleWeb } from "./MeetingsStyle.web";
import RichTextEditor from "react-rte";
import { CheckIcon, DetailsIcon } from "./assets";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

const toolbarConfig: any = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

class MeetingMinuteNote extends MeetingMinutesController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const meeting_id = this.props.navigation.getParam("id");

    this.setState({ meetingMinuteId: meeting_id }, () => {
      this.MinuteMeetingDetail();
    });
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.scheduledMeeting}>
                <Box className="navigation">
                  {this.state.isNotePreviewOpen ? (
                    <Box>
                      <Typography variant="body1">
                        {t("Meetings")} / <Link href="/ScheduledMeetings">{t("Scheduled Meetings")}</Link> /{" "}
                        <Link href={`/ScheduledMeeting/${this.state.meetingMinuteId}`}>{t("Meeting Details")}</Link> /{" "}
                        <Box component="span" style={{ color: "blue" }}>
                          {t("Preview Meeting Minutes")}
                        </Box>
                      </Typography>
                      <Typography variant="h5" className="sub-heading bold-text">
                        {t("Preview Meeting Minutes")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="body1">
                        {t("Meetings")} / <Link href="/ScheduledMeetings">{t("Scheduled Meetings")}</Link> /{" "}
                        <Link href={`/ScheduledMeeting/${this.state.meetingMinuteId}`}>{t("Meeting Details")}</Link> /{" "}
                        <Box component="span" style={{ color: "blue" }}>
                          {this.state.meetingMinuteDetails &&
                          this.state.meetingMinuteDetails.attributes.meeting_mins_notes
                            ? `${t("Edit Meeting Minutes")}`
                            : `${t("Add Meeting Minutes")}`}
                        </Box>
                      </Typography>
                      <Typography variant="h5" className="sub-heading bold-text">
                        {this.state.meetingMinuteDetails &&
                        this.state.meetingMinuteDetails.attributes.meeting_mins_notes
                          ? `${t("Edit Meeting Minutes")}`
                          : `${t("Add Meeting Minutes")}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box className="meeting-minute-note">
                  {this.state.isNotePreviewOpen ? (
                    <Card className="meeting-preview-card">
                      <h4 style={{ marginBottom: "20px" }} className="bold-text">
                        {t("Meeting Minutes")}{" "}
                        {moment(
                          this.state.meetingMinuteDetails &&
                            this.state.meetingMinuteDetails.attributes.meeting_date_time,
                          "DD-MM-YYYY HH:mm",
                          true
                        ).format("MMMM DD, YYYY HH:mm")}
                      </h4>
                      <Divider />
                      <div
                        style={{ marginTop: "20px" }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.meetingNote._cache.html,
                        }}
                      />
                    </Card>
                  ) : (
                    <Card>
                      <Box className="note-details-box">
                        <p>{t("Details")} </p>
                        <img src={DetailsIcon} alt="" />
                      </Box>
                      <RichTextEditor
                        toolbarConfig={toolbarConfig}
                        className={languageCondition(language, "editor arabic-editor", "editor")}
                        value={this.state.meetingNote}
                        onChange={this.onChange}
                      />
                    </Card>
                  )}
                  {this.state.isNotePreviewOpen ? (
                    <Box className="note-button">
                      <Button className="submit" onClick={() => this.handleNotePreview()}>
                        {t("Edit")}
                      </Button>
                      <Button
                        className="preview"
                        disabled={!this.state.meetingNote._cache.html}
                        onClick={() => this.handleSubmitNoteModal()}
                      >
                        {t("Submit")}
                      </Button>
                    </Box>
                  ) : (
                    <Box className="note-button">
                      <Button
                        className="submit"
                        disabled={!this.state.meetingNote._cache.html}
                        onClick={() => this.handleSubmitNoteModal()}
                      >
                        {t("Submit")}
                      </Button>
                      <Button className="preview" onClick={() => this.handleNotePreview()}>
                        {t("Preview")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Container>
        </GeneralHeaderComponentWeb>

        <Dialog
          fullWidth
          onClose={() => this.handleSubmitNoteModal()}
          open={this.state.isSubmitNoteModalOpen}
          className="cancel-meeting-dialog submit-meeting-minute-dialog"
          dir={languageCondition(language, "rtl", "ltr")}
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={CheckIcon} alt="check" />
              <Typography variant="h6" className="bold-text">
                {t("Submit Meeting Minutes")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {t("Are you sure you want to submit meeting minutes?")}
              </Typography>
              <DialogActions className="dialog-button-group" style={{ gap:"10px" }}>
                <Button
                  className="cancel-button"
                  style={{ width: "200px" }}
                  onClick={() => this.handleSubmitNoteModal()}
                >
                  {t("Close")}
                </Button>
                <Button style={{ width: "200px", margin:"0" }} className="add-button" onClick={() => this.meetingMinuteNote()}>
                  {t("Confirm")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(MeetingMinuteNote)));
// Customizable Area End
