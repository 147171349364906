// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import SuggestionController, { Props } from "./SuggestionController.web";
import { Building1 } from "../../ContentManagement/src/assets";
import { Claender, User } from "./assets";
import moment from "moment";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
import {withTranslation} from "react-i18next";

class SuggestionDetails extends SuggestionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const data: any = JSON.parse(localStorage.getItem("selectSuggestion")!);
    // @ts-ignore
    const {t,i18n} = this.props;
    const {language} = i18n;
    return (
      <>
        <Box className="login-wrapper incident-wrapper suggestionDetails" style={{ background: "#F8F9FE", overflowY: "hidden" }}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block suggestionDetails" style={{ height: "100%", display: "block" }} dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header suggestionDetails">
                  <Box className="left-block blocks suggestionDetails">
                    <ArrowBackIcon  onClick={() => window.history.back()} />
                    <h4 className="bold-text" style={{ fontSize: "18px" }}>{data.attributes.title}</h4>
                  </Box>
                  <Box className="incident-right-block blocks" />
                </Box>
                <Box
                  className="content-block-wrapper common-incident-block"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    // height: "95%",
                    background: "#F8F9FE",
                  }}
                >
                  <Box style={{ paddingTop: "12px" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                      <Typography className="sub-title h5-title bold-text" component="h5" style={{ fontWeight: 600 }}>
                        {t("Suggestion Details")}
                      </Typography>
                      <Box className="customButton" style={{ padding: "0" }}>
                        <Button variant="contained" className={data?.attributes?.response ? "contain green-span" : "contain red-span"} type="submit" >{data.attributes.response ? data.attributes.response.data.length : '0'} {t("Response")}</Button>
                      </Box>
                    </Box>
                    <Box style={{ border: "1px solid #F3F3F4", borderRadius: 8, padding: "1rem", background: "white" }}>
                      <Box>
                        <Typography variant="caption" style={{ fontSize: "12px", color: "#9C9C9C" }}>{t("Suggestion is related to")}:</Typography>
                        <Typography className="sub-title h5-title" component="h5" style={{ fontSize: "14px" }}>
                          {data?.attributes?.suggestion_related?.related_to}
                        </Typography>
                      </Box>
                      <br />
                      <Box>
                        <Typography variant="caption" style={{ fontSize: "12px", color: "#9C9C9C" }}>{t("Description")}:</Typography>
                        <Typography className="sub-title h5-title" component="h5" style={{ fontSize: "14px" }}>
                          {data?.attributes?.description}
                        </Typography>
                      </Box>
                      <br />
                      <Box>
                        <Typography variant="caption" style={{ fontSize: "12px", color: "#9C9C9C" }}>{t("Status")}:</Typography>
                        <Typography className="sub-title h5-title" component="h5" style={{ fontSize: "14px" }}>
                          {t(data.attributes.status)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography
                        className="sub-title h5-title bold-text"
                        component="h5"
                        style={{ fontWeight: 600, marginTop: "20px", marginBottom: "8px" }}
                      >
                        {t("Sent Details")}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: "1px solid #F3F3F4",
                        borderRadius: 8,
                        background: "white",
                        padding: "1rem",
                      }}
                    >
                      <Box style={{ display: "flex", gap: "5px", alignItems: "flex-start" }}>
                        <img src={User} />
                        <Box style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                          <span style={{ fontSize: "12px", color: "#9C9C9C" }}>{t("Sent To")}:</span>
                          <p style={{ fontSize: "14px" }}>{data?.attributes?.sent_by.name || 'N/A'}</p>
                        </Box>
                      </Box>
                      <Box style={{ display: "flex", gap: "5px", alignItems: "flex-start" }}>
                        <img src={Claender} />
                        <Box style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                          <span style={{ fontSize: "12px", color: "#9C9C9C" }}>{t("Sent On")}:</span>
                          <p style={{ fontSize: "14px" }}> {moment(data?.attributes?.created_at).format("DD/MM/YYYY hh:mm a")}</p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="customButton add-incident-list-btn">
                    {data.attributes.response ?
                      <Button variant="contained" onClick={() => {
                        this.setState({ loading: true });//@ts-ignore
                        this.props.history.push("/ResponseSuggestion");
                      }} >{t("VIEW RESPONSE")}</Button>
                      :
                      <Button variant="contained" onClick={() => {
                        this.setState({ loading: true });//@ts-ignore
                        window.history.back();
                      }} >{t("Close")}</Button>

                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(SuggestionDetails));
// Customizable Area End