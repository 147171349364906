import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { ROLE } from "../../../framework/src/Enum";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  complexList: any[];
  complexId: any;

  policy: number;
  guidelines: number;
  roles: number;
  resolution: number;
  buildingPlans: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BuildingDocumentController extends BlockComponent<Props, S, SS> {
  GetDocumentCountCallId: any;
  GetComplexesCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      complexList: [],
      complexId: "",

      policy: 0,
      guidelines: 0,
      roles: 0,
      resolution: 0,
      buildingPlans: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetComplexesCallId:
          if (responseJson && responseJson.complex) {
            const complexId = responseJson.complex[0].id;
            sessionStorage.setItem("complexId", complexId);
            this.setState({ complexList: responseJson.complex, complexId: complexId }, () => {
              this.getDocumentCount();
            });
          }
          break;
        case this.GetDocumentCountCallId:
          if (responseJson && responseJson.data) {
            this.setState({
              ...this.state,
              policy: responseJson.data.policy_count,
              guidelines: responseJson.data.guideline_count,
              roles: responseJson.data.role_count,
              resolution: responseJson.data.resolution_count,
              buildingPlans: responseJson.data.building_plan_count,
            });
          }
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getUserComplexList();
  }

  // Get Document Count API
  getDocumentCount = async () => {
    const society_id = sessionStorage.getItem("complexId");

    this.GetDocumentCountCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_my_document/document_count`,
    });
  };

  getUserComplexList = async () => {
    const user_role = localStorage.getItem("userType");

    this.GetComplexesCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_role}`,
    });
  };

  handleGotoDashboard = () => {
    const userType = localStorage.getItem("userType");
    if (userType === ROLE.OWNER || userType === ROLE.PROPERTY_MANAGER) {
      this.props.navigation.navigate("OwnerDashboard");
    } else {
      this.props.navigation.navigate("ResidentDashboard");
    }
  };
  // Customizable Area End
}
