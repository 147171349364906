import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import moment from "moment";
import {RouteComponentProps} from 'react-router';
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  chatDrawer: any;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  userType: string | null;
  error: any;
  loading: boolean;
  userTypeData:any;
  anchorEl :any ;
  anchorEl_1 :any ;
  anchorEl_2:any;
  getClassifiedDetails : any;
  sortBy : any ;
  status : any;
  getCurrencyList:any;
  upload:any;
  notImageOrVideoError:any,
  sizeError:any,
  file : any,
  commonAreaData:any,
  incidentRelatedData:any,
  classifiedListing:[],
  showDialog:any;
  deleteShowDialog:any;
  classifiedId:any;
  myOrAllClassified:boolean;
  classifiedType:any;
  selectCode: string;
  selectCode2: string;
  showError:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ClassifiedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  deleteClassifiedApiCallId:any;
  createClassifiedApiCallId: any;
  // validationApiCallId: any;
  getClassifiedListingApiCallId: any;
  getClassifiedDetailsByIdApiCallId : any ;
  getMyClassifiedListApiCallId : any ;
  updateClassifiedApiCallId:any;
  getCurrencyListApiCallId:any;
  handleGetClassifiedListingResponse: any;
  handleGetClassifiedDetailsResponse: any;
  handleGetCurrencyListResponse: any;
  createChatRoomAPIId: any;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    //@ts-ignore
    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      chatDrawer: false,
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      error: null,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType:'',
      loading: false,
      commonAreaData:null,
      incidentRelatedData:null,
      classifiedListing: [],
      anchorEl:null,
      anchorEl_1:null,
      anchorEl_2:null,
      getClassifiedDetails:null,
      sortBy : "" ,
      status : "",
      getCurrencyList:[],
      upload:false,
      notImageOrVideoError:false,
      sizeError:false,
      file :{},
      showDialog:false,
      deleteShowDialog:false,
      classifiedId:null,
      myOrAllClassified:true,
      classifiedType:'',
      selectCode: '+966',
      selectCode2: '+966',
      showError:false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.status !== this.state.status
    ) {
      this.getClassifiedListing(this.state.status)
    }
  }

  initialValuesClassified = (attributes:any,id:any) => {
    let values = {
      phone: attributes?.phone_number?.phone_number || "",
      // selectCode: attributes?.phone_number?.country_code,
      email: attributes?.email || "",
      classifiedTitle: attributes?.title || "",
      description: attributes?.description || "",
      media: attributes?.attachments || [],
      price: attributes?.price || "",
      currency: attributes?.currency?.id || ' ',
      startDate:attributes?.duration_from ? moment(attributes?.duration_from,'DD-MM-YYYY' ).format('YYYY-MM-DD') : "",
      endDate:attributes?.duration_to ? moment(attributes?.duration_to,'DD-MM-YYYY' ).format('YYYY-MM-DD') : "",
      priceFrom: attributes?.price_from || "",
      priceTo: attributes?.price_to || "",
      timeFrom: attributes?.time_from || "",
      timeTo: attributes?.time_to || "",
      paymentDetail: attributes?.payment_detail || "",
      id: id || "",
    }
    return values
  }

  manageSubmit = (values:any,classifiedUserType:any) => {
    if(!this.state.sizeError && !this.state.notImageOrVideoError){
      classifiedUserType ?
        this.onSubmit(values)
        : this.updateClassified(values)
    }
  }

  showError = () => {
    if(this.state.error){
      this.setState({
        showError:true
      })
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      await this.handleRestAPIResponse(message);
    }
  }
  createChatRoomResponse =
    (responseJson: any) =>
   {
      if (responseJson && responseJson.data)
     {
        localStorage.setItem('selectedChat',
          JSON.stringify(responseJson.data))
        this.setState({loading: false, chatDrawer: true})
      } else if (responseJson.errors) 
    {
        let error = responseJson.errors[0] as string;
        this.setState({error});
      } else {
        this.setState({error: responseJson.error
            || "Something went wrong!"});
      }
      this.showError()
      this.setState(
        {loading: false, error: null}
      )
    }

  async handleRestAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.createClassifiedApiCallId:
          this.handleCreateClassifiedResponse(responseJson);
          break;
        case this.deleteClassifiedApiCallId:
          this.handleDeleteClassifiedResponse(responseJson);
          break;
        case this.getClassifiedListingApiCallId:
          this.setState({classifiedListing:responseJson.data,loading:false})
          break;
        case this.getClassifiedDetailsByIdApiCallId:
          this.setState({getClassifiedDetails:responseJson.data,loading:false})
          break;
        case this.getMyClassifiedListApiCallId:
          this.handleGetMyClassifiedListResponse(responseJson);
          break;
        case this.updateClassifiedApiCallId:
          this.handleUpdateClassifiedResponse(responseJson);
          break;
        case this.getCurrencyListApiCallId:
          this.setState({getCurrencyList: responseJson.data,loading:false})
          break;
        case this.createChatRoomAPIId:
          this.createChatRoomResponse(responseJson)
          break;

      }
    }
  }

  createClassified = async(classifiedFromData: any ,classifiedUserType : any) => {
    try
    {
      const header = {
        token :localStorage.getItem("userToken")
      };
      const full_phone_number =`${classifiedFromData?.selectCode}${classifiedFromData?.phone}`
      const formData = new FormData();
      formData.append('classified[classified_type]', classifiedUserType);
      formData.append('classified[full_phone_number]',full_phone_number);
      formData.append('classified[email]', classifiedFromData.email);
      formData.append('classified[title]', classifiedFromData.classifiedTitle);
      formData.append('classified[description]', classifiedFromData.description);
      formData.append('classified[currency_id]', classifiedFromData.currency);
      formData.append('classified[duration_from]', classifiedFromData.startDate);
      formData.append('classified[duration_to]', classifiedFromData.endDate);

      for (let j = 0; j < classifiedFromData?.media?.length; j += 1) {
        let blob = await fetch(classifiedFromData?.media[j]?.url).then(r => r.blob());
        //@ts-ignore
        formData.append(
          "classified[attachments][]",
          blob
        );
      }
      if(classifiedUserType==='generic'){
        formData.append('classified[payment_detail]', classifiedFromData.paymentDetail);
        formData.append('classified[time_from]', classifiedFromData.timeFrom);
        formData.append('classified[time_to]', classifiedFromData.timeTo);
      }
      else if(classifiedUserType==='buyer'){
        formData.append('classified[price_from]', classifiedFromData.priceFrom);
        formData.append('classified[price_to]', classifiedFromData.priceTo);
      }
      else {
        formData.append('classified[price]', classifiedFromData.price);
      }
      const httpBody = formData;
      this.setState({loading: true})
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.createClassifiedApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_posts/classifieds`);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    catch (error) {
      this.setState({loading: false})
    }
  };

  handleUpdateClassifiedResponse = (responseJson:any) => {
    if (responseJson && responseJson?.data ) {
      this.setState({loading: false})
      this.props.history.push("/ClassifiedEditSuccessfully")
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.showError()
    this.setState({loading: false })
  }
  handleDeleteClassifiedResponse = (responseJson:any) => {
    if (responseJson?.message === 'Successfully deleted' && responseJson?.code === 200) {
      this.setState({loading: false, deleteShowDialog: false,classifiedId:null})
      //@ts-ignore
      this.getMyClassifiedList()
    } else if (responseJson?.errors) {
      let error =responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.showError()
    this.setState({loading: false })
  }

  handleGetMyClassifiedListResponse(responseJson: any) {
    if (responseJson?.data ) {
      this.setState({classifiedListing :responseJson?.data,loading: false})
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
    }
    this.showError()
    this.setState({loading: false })
  }


  handleCreateClassifiedResponse(responseJson: { data: any; errors: any[]; error: any; }) {
    if (responseJson.data) {
      //@ts-ignore
      this.props.history.push("/ClassifiedReportedSuccessfully");
      this.setState({ loading: false });
    } else if (responseJson.errors) {
      let error = responseJson.errors[0];
      this.setState({ error });
    } else {
      this.setState({ error: responseJson.error || "Something went wrong!" });
    }
    this.showError();
    this.setState({ loading: false });
  }

  // handleDefaultResponse(responseJson: { errors: string[]; error: any; }) {
  //   if (responseJson.errors) {
  //     let error = responseJson.errors[0] as string;
  //     this.setState({ error });
  //   } else {
  //     this.setState({ error: responseJson.error || "Something went wrong!" });
  //   }
  //   this.showError();
  //   this.setState({ loading: false });
  // }


  // Customizable Area Start

  onSubmit = (values: any) => {
    if (this.checkPhoneClassified(values.phone)) {
      localStorage.setItem("classifiedPreview", JSON.stringify({ ...values, selectCode: this.state.selectCode }))
      this.setState({ loading: true })
      //@ts-ignore
      this.props.history.push("/ClassifiedPreview")
    }
  }

  checkPhoneClassified=(value:any)=>{
    let pattern = /^5\d+$/
    if(value.includes('+'))
    {
      this.setState({
        error:'Please enter valid mobile number',
        showError:true
      })
      return false
    }else if(this.state.selectCode == '+966' ||this.state.selectCode == '+971' ){
      if(!(pattern.test(value)))
      {
        this.setState({
          error:'Please enter valid mobile number',
          showError:true
        })
        return false
      }
      else{
        if(value.length==9){
          return true
        }else{
          this.setState({error:'Please enter valid mobile number',showError:true})
          return false
        }
      }
    }else {
      if(value.length==10){
        return true
      }else{
        this.setState({error:'Please enter valid mobile number',showError:true})
        return false
      }
    }
  }

  getClassifiedDetails= (e:any,id :any) => {
    //@ts-ignore
    this.props.history.push({
      pathname: "/ClassifiedDetails",
      //@ts-ignore
      id,
    });
    this.getClassifiedDetailsById(id)
  }

  deleteClassified =()=>{
    const header = {
      token :localStorage.getItem("userToken")
    };
    const id =this.state.classifiedId
    this.setState({loading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteClassifiedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/classifieds/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getClassifiedListing= (status : any)  => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const societyID = localStorage.getItem("society_id")
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getClassifiedListingApiCallId = requestMessage.messageId;
      this.setState({ loading: true , myOrAllClassified:true});

      const  getSortByOrStatus =`bx_block_posts/classifieds?filter_by=${status}&society_management_id=${societyID}`

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        getSortByOrStatus
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {

    }
  };

  getCurrencyList = () => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCurrencyListApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/classifieds/currency_list`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {

    }
  };

  getMyClassifiedList = (filter?:any) => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMyClassifiedListApiCallId = requestMessage.messageId;
      this.setState({ loading: true ,myOrAllClassified:false });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/classifieds/my_classified_list?filter_by=${filter|| ""}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {

    }
  };

  updateClassified =async (classifiedFromData:any,) => {
    try {
      const header = {
        token :localStorage.getItem("userToken")
      };
      if(this.checkPhoneClassified(classifiedFromData?.phone)){
        const full_phone_number =`${this.state.selectCode}${classifiedFromData?.phone}`
        const formData = new FormData();
        const classifiedUserType = this.state?.getClassifiedDetails?.attributes?.classified_type
        formData.append('classified[full_phone_number]', full_phone_number);
        formData.append('classified[email]', classifiedFromData.email);
        formData.append('classified[title]', classifiedFromData.classifiedTitle);
        formData.append('classified[description]', classifiedFromData.description);
        formData.append('classified[currency_id]', classifiedFromData.currency);
        formData.append('classified[duration_from]', classifiedFromData.startDate);
        formData.append('classified[duration_to]', classifiedFromData.endDate);

        if(classifiedUserType !=='generic'){
          for (let j = 0; j < classifiedFromData.media?.length; j += 1) {
            let blob = await fetch(classifiedFromData?.media[j]?.url).then(r => r.blob());
            //@ts-ignore

            formData.append(
              "classified[attachments][]",
              blob
            );
          }
        }

        if(classifiedUserType ==='generic'){
          formData.append('classified[payment_detail]', classifiedFromData.paymentDetail);
          formData.append('classified[time_from]', classifiedFromData.timeFrom);
          formData.append('classified[time_to]', classifiedFromData.timeTo);
        }
        else if(classifiedUserType==='buyer'){
          formData.append('classified[price_from]', classifiedFromData.priceFrom);
          formData.append('classified[price_to]', classifiedFromData.priceTo);
        }
        else {
          formData.append('classified[price]', classifiedFromData.price);
        }

        const httpBody = formData;

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateClassifiedApiCallId = requestMessage.messageId;
        this.setState({ loading: true });

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_posts/classifieds/${classifiedFromData?.id}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          httpBody
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.PatchAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  getClassifiedDetailsById= (id : any) => {
    try {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token :localStorage.getItem("userToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getClassifiedDetailsByIdApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/classifieds/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {

    }
  };


  handleClick = (event:any,id:any,classifiedType:any) => {
    event.stopPropagation();
    this.setState({anchorEl:event.currentTarget ,classifiedId:id , classifiedType})
  };
  handleClose = (e:any, v:any) => {
    let anchorEl :any ;
    if(v === undefined || v === null){
      anchorEl=null
    }
    else if (v === "edit"){
      e.stopPropagation();
      localStorage.removeItem("classifiedUserType");
      const id = this.state.classifiedId
      this.props.history.push({
        pathname: "/CreateClassified",
        state: {
          //@ts-ignore
          //@ts-nocheck
          id: id,
        },
      });
    }
    else if (v === "delete"){
      {
        e.stopPropagation();
        this.setState({deleteShowDialog: true}) }
    }

    this.setState({anchorEl})
  };

  handleClick_1 = (event :any) => {
    this.setState({anchorEl_1:event.currentTarget})
  };

  handleClick_2 = (event :any) => {
    this.setState({anchorEl_2:event.currentTarget})
  };

  handleClose_1 = (e:any, v:any) => {
    let status : any ;
    if(v === undefined || v === null){
      status =this.state.status;
    }
    else {
      status =v;
    }
    this.setState({anchorEl_1:null ,status :status})
  };

  handleClose_2 = (e:any, v:any) => {
    let status : any ;
    if(v === undefined || v === null){
      status =this.state.status;
    }
    else {
      status =v;
    }
    this.getMyClassifiedList(status)
    this.setState({anchorEl_2:null})
  };

  handleSelectMedia  =   (
    e: any,
    existingMedia: any[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    let media = [];
    let files = e.target.files;


    if(files.length !== 0){
      for (let i = 0; i < files.length; i += 1) {
        if(files[i] && !["image/jpg", "image/jpeg", "image/gif", "image/png"].includes(files[i].type))
    {
          this.setState({upload: false,sizeError : false,notImageOrVideoError:true});
          return ;
        }
        else if(files[i] && files[i].size >= 10e6)
    {
          this.setState({upload: false , sizeError : true ,notImageOrVideoError:false});
          return ;
        }
        media.push({
          file: {
            lastModified: files[i].lastModified,
            lastModifiedDate: files[i].lastModifiedDate,
            name: files[i].name,
            size: files[i].size,
            type: files[i].type
          },
          url: URL.createObjectURL(files[i])
        });
      }
      e.target.value = "";
      this.setState({upload: true ,sizeError : false,notImageOrVideoError:false});
      setFieldValue("media", media);
    }
    else {
      this.setState({upload: false,sizeError : false,notImageOrVideoError:false});
    }

  };

  createClassifiedSchemaGerenic() 
{
    // @ts-ignore
    const {t} = this.props
    const validations =
      Yup.object().shape({
        phone:
          Yup.number()
            .typeError(t("Only numbers are allowed."))
            .positive(t("Negative numbers are not allowed."))
            .integer(t("Number can't contain a decimal."))
            .required(t("Mobile number is required")),
        email:
          Yup.string()
            .email(t("Please enter a valid email address."))
            .required(t("Email is required")),
        classifiedTitle:
          Yup.string().max(50, t("Too Long!")).required(t("Title is required")),
        description: Yup.string().max(200, t("Too Long!")).required(t("Description is required")),
        currency:Yup.string().trim().required(t("Currency is required")),
        startDate: Yup.date().typeError(t("Invalid date!")).required(t("Start Date is required")),
        endDate:
          Yup.date().typeError(t("Invalid date!")).required(t("End Date is required"))
            .test("is-greater", t("End date should be greater than Start date"), function(value) {
              const { startDate } = this.parent;
              return moment(value, "DD/MM/YYYY").isSameOrAfter(moment(startDate, "DD/MM/YYYY"));
            }
            )
        ,
        timeFrom:Yup.string().required(t("Start time is required")),
        timeTo:Yup.string().required(t("End time is required"))
          .test("is-greater", t("End time should be greater than Start time"), function(value) {
            //@ts-ignore
            const { timeFrom }
              = this.parent;
            return moment(value, "HH:mm").isSameOrAfter(moment(timeFrom, "HH:mm"));
          })
        ,
        paymentDetail:
          Yup.string().required(t("Payment Detail is required")),
      });

    return validations ;
  }
  createClassifiedSchemaBuy() 
  {
    // @ts-ignore
    const {t} = this.props
    const validations =
      Yup.object().shape({
        phone:
          Yup.number()
            .typeError(t("Only numbers are allowed."))
            .positive(t("Negative numbers are not allowed."))
            .integer(t("Number can't contain a decimal."))
            .required(t("Mobile number is required")),
        email:
          Yup.string()
            .email(t("Please enter a valid email address."))
            .required(t("Email is required")),
        classifiedTitle: Yup.string().max(50, t("Too Long!")).required(t("Title is required")),
        description:
          Yup.string().max(200, t("Too Long!")).required(t("Description is required")),
        currency:Yup.string().trim().required(t("Currency is required")),
        startDate:
          Yup.date().typeError(t("Invalid date!")).required(t("Start Date is required")),
        endDate:
          Yup.date().typeError(t("Invalid date!")).required(t("End Date is required"))
            .test("is-greater", t("End date should be greater than Start date"), function(value) {
              const { startDate } = this.parent;
              return moment(value, "DD/MM/YYYY").isSameOrAfter(moment(startDate, "DD/MM/YYYY"));
            })
        ,
        priceFrom:
          Yup.number()
            .typeError(t("Only numbers are allowed."))
            .positive(t("Negative numbers are not allowed."))
            .integer(t("Number can't contain a decimal."))
            .required(t("Price From is required")),
        priceTo:
          Yup.number()
            .typeError(t("Only numbers are allowed."))
            .required(t("Price To is required"))
            .positive(t("Negative numbers are not allowed."))
            .integer(t("Number can't contain a decimal."))
            .test("priceFrom ", t("Value should be greater than From price"), function(value : number) {
              const { priceFrom } = this.parent;
              return value > priceFrom ;
            }),
      });

    return validations ;
  }
  createClassifiedSchemaSell()
   {
    // @ts-ignore
    const {t} = this.props
    const validations = Yup.object().shape({
      phone:
        Yup.number()
          .typeError(t("Only numbers are allowed."))
          .positive(t("Negative numbers are not allowed."))
          .integer(t("Number can't contain a decimal."))
          .required(t("Mobile number is required")),
      email:
        Yup.string()
          .email(t("Please enter a valid email address."))
          .required(t("Email is required")),
      classifiedTitle:
        Yup.string().max(50, t("Too Long!")).required(t("Title is required")),
      description:
        Yup.string().max(200, t("Too Long!")).required(t("Description is required")),
      currency:
        Yup.string().trim().required(t("Currency is required")),
      startDate:
        Yup.date().typeError(t("Invalid date!")).required(t("Start Date is required")),
      endDate:
        Yup.date().typeError(t("Invalid date!")).required(t("End Date is required"))
          .test("is-greater", t("End date should be greater than Start date"), function(value) {
            const { startDate } = this.parent;
            return moment(value, "DD/MM/YYYY").isSameOrAfter(moment(startDate, "DD/MM/YYYY"));
          })
      ,
      price:
        Yup.number()
          .required(t("Price is required"))
          .typeError(t("Only numbers are allowed."))
          .positive(t("Negative numbers are not allowed."))
          .integer(t("Number can't contain a decimal.")),
    });
    return validations ;
  }
  changeType = (value: any) => {
    this.setState({userType: value})
  }
  nextBtn = (val:any)=>{
    localStorage.setItem("classifiedUserType",val)
    this.setState({ loading: true })
    this.props.history.push("/CreateClassified")
  }

  redirectToDashboard = () => {
    let userType = localStorage.getItem('userType')
    if (userType == 'Owner') {
      //@ts-ignore
      this.props.history.push('/OwnerDashboard')
    } else {
      //@ts-ignore
      this.props.history.push('/residentDashboard')
    }

  }
  createChatRoom =
    async (id: any) => 
  {
      try 
      {
        const requestMessage =
          new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
        this.createChatRoomAPIId
          =
          requestMessage.messageId;


        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_chat/chats`
        );


        const header
          =
        {
          token: localStorage.getItem("userToken"),
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        const formData
          =
          new FormData();
        formData.append("chat[chatable_type]", 'BxBlockCustomForm::Incident');
        // @ts-ignore
        formData.append("chat[chatable_id]", id);
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage)
          ,
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      } catch
      (error) 
      {
      }


    }

  // Customizable Area End
}
