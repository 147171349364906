// Customizable Area Start
import React from "react";
interface MyComponentProps2 {
    opendocuments: any;
  }
export const DocumentImageComponent: React.FC<MyComponentProps2>  = ({opendocuments}) => {
    const strokeColor = opendocuments
  return (
    <svg className={strokeColor}
      width="20.558105px"
      height="23.97168px"
      viewBox="0 0 20.558105 23.97168"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="report-(1)" className="b">
        <path className="b"
          d="M14.8869 5.98943L17.322 5.98943L14.3765 3.14221L14.3765 5.54455C14.3765 5.7787 14.6153 5.98943 14.8869 5.98943Z"
          id="Path"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <path className="b"
          d="M14.8871 7.86263C13.5712 7.86263 12.5035 6.82302 12.5035 5.5399L12.5035 1.90127L2.36032 1.87317C2.10275 1.87317 1.87329 2.09327 1.87329 2.34146L1.87329 21.6258C1.87329 21.8693 2.12149 22.1034 2.38373 22.1034L18.2589 22.1034C18.5258 22.1034 18.685 21.8599 18.685 21.6258L18.685 7.86263L14.8871 7.86263ZM13.2059 11.5621C13.721 11.5621 14.1425 11.9836 14.1425 12.4987C14.1425 13.0138 13.721 13.4353 13.2059 13.4353L5.0108 13.4353C4.49568 13.4353 4.07422 13.0138 4.07422 12.4987C4.07422 11.9836 4.49568 11.5621 5.0108 11.5621L13.2059 11.5621ZM4.96393 7.8158L10.3961 7.8158C10.9112 7.8158 11.3327 8.23726 11.3327 8.75238C11.3327 9.2675 10.9112 9.68897 10.3961 9.68897L4.96393 9.68897C4.44881 9.68897 4.02734 9.2675 4.02734 8.75238C4.02734 8.23726 4.44881 7.8158 4.96393 7.8158ZM15.5474 17.1816L5.0108 17.1816C4.49568 17.1816 4.07422 16.7602 4.07422 16.2451C4.07422 15.7299 4.49568 15.3085 5.0108 15.3085L15.5474 15.3085C16.0625 15.3085 16.484 15.7299 16.484 16.2451C16.484 16.7602 16.0625 17.1816 15.5474 17.1816Z"
          id="Shape"
          fill="none"
          fill-rule="evenodd"
          stroke="none"
        />
        <path className="b"
          d="M20.0991 6.07376L14.4 0.557268C14.0535 0.220098 13.5805 0.0280976 13.0935 0.0280976L2.3602 0C1.05834 0 0 1.04898 0 2.34146L0 21.6258C0 22.8948 1.09112 23.9719 2.38361 23.9719L18.2587 23.9719C19.5278 23.9719 20.5581 22.9182 20.5581 21.6211L20.5581 7.19298C20.5581 6.75746 20.3941 6.35942 20.0991 6.07376ZM14.3765 3.14227L17.322 5.98949L14.8869 5.98949C14.6153 5.98949 14.3765 5.77876 14.3765 5.53993L14.3765 3.14227ZM18.2589 22.0987L2.38373 22.0987C2.12149 22.0987 1.87329 21.8693 1.87329 21.6211L1.87329 2.34146C1.87329 2.09327 2.10275 1.87317 2.36032 1.87317L12.5035 1.90127L12.5035 5.5399C12.5035 6.81834 13.5712 7.86263 14.8871 7.86263L18.685 7.86263L18.685 21.6211C18.685 21.8552 18.5258 22.0987 18.2589 22.0987Z"
          id="Shape"
          fill-rule="evenodd"
          stroke="none"
        />
        <path className="b"
          d="M4.07422 12.4987C4.07422 13.0138 4.49568 13.4353 5.0108 13.4353L13.2059 13.4353C13.721 13.4353 14.1425 13.0138 14.1425 12.4987C14.1425 11.9836 13.721 11.5621 13.2059 11.5621L5.0108 11.5621C4.49568 11.5621 4.07422 11.9836 4.07422 12.4987ZM4.96393 9.68897L10.3961 9.68897C10.9112 9.68897 11.3327 9.2675 11.3327 8.75238C11.3327 8.23726 10.9112 7.8158 10.3961 7.8158L4.96393 7.8158C4.44881 7.8158 4.02734 8.23726 4.02734 8.75238C4.02734 9.2675 4.44881 9.68897 4.96393 9.68897ZM15.5474 15.3085L5.0108 15.3085C4.49568 15.3085 4.07422 15.7299 4.07422 16.2451C4.07422 16.7602 4.49568 17.1816 5.0108 17.1816L15.5474 17.1816C16.0625 17.1816 16.484 16.7602 16.484 16.2451C16.484 15.7299 16.0625 15.3085 15.5474 15.3085Z"
          id="Shape"
          fill-rule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
