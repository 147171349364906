//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Button,
    Hidden,
    Grid, MenuItem, Menu, Typography,
} from "@material-ui/core";
import OtpInput from 'react-otp-input';
import {
    Tenant_Logo, Building1,
    globalIcon, downArrow
} from "../../src/assets";
import CompEmpOTPController, {
    Props,
} from "./CompEmpOTPController.web";


export default class CompEmpOTP extends CompEmpOTPController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        const focusStyle = {
            borderBottom: "2px solid #000000",
            outline: "none",
            width: '60px'
        }
        return (
            <Box>
                <Grid container spacing={5}>
                    <Grid item sm={7} xs={12} style={{height: '100%'}} className="rightbox f">
                        <Hidden>
                            <Box p={2} style={{height: '100%'}} className="rightbox g">
                                <Box style={{
                                    display: 'flex',
                                    height: '80px',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row'
                                }}>
                                    <img src={Tenant_Logo.default} style={webStyle.login_img}/>
                                    <Box style={{height: '25px'}} onClick={(e) => {
                                        this.handleOpen(e)
                                    }} data-test-id='box'>
                                        <p style={{verticalAlign: 'middle'}}>
                                            <img src={globalIcon} alt="GlobalIcon"
                                                 style={{position: 'relative', top: '5px'}}
                                                 className="login-h1 bold-text "/>
                                            <span style={{fontWeight: 'bolder', paddingLeft: '3px'}}
                                                  className="login-h1 bold-text ">{this.state.language2} </span>
                                            <img src={downArrow} style={{
                                                position: 'relative',
                                                height: '7px',
                                                color: 'grey',
                                                top: "-2"
                                            }}/>
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                            <Menu open={this.state.emailopen1}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                  }}
                                  style={{
                                      position: 'absolute',
                                      top: '30px'
                                  }}
                                  transformOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                  }}
                                  anchorEl={this.state.fieldd}>
                                <MenuItem onClick={() => {
                                    this.setState({language2: 'ENG', emailopen1: false})
                                }} data-test-id="english">English</MenuItem>
                                <MenuItem onClick={() => {
                                    this.setState({language2: 'ARB', emailopen1: false})
                                }} data-test-id="arabic">Arabic</MenuItem>
                            </Menu>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '600px',
                            }}>
                                <Box style={{width: '360.75px', height: '353.8px'}} className="commonForm">

                                    <Box>
                                        <h1 style={{fontSize: '32px', marginBottom: "10px"}}
                                            className="login-h1 bold-text "> Enter OTP</h1>
                                        <p style={{fontSize: '15px'}}>Please enter the code sent to the email
                                            address <span style={{
                                                color: '#FC8434',
                                                fontSize: '14px'
                                            }}> {this.state.newemail}</span></p>
                                    </Box>
                                    <br/>
                                    <Box style={{marginBottom: "5px"}} className="login-h1 bold-text otpBlock">
                                        <OtpInput className="formOutlineInput"
                                                  data-test-id='otpInput'
                                                  inputStyle="customInputStyle"
                                                  focusStyle={focusStyle}
                                                  value={this.state.otp}
                                                  onChange={(e: any) => {
                                                      this.handleChange(e)
                                                  }}
                                                  numInputs={4}
                                        />

                                    </Box>
                                    <br/>
                                    <Button color="primary" variant="contained" data-test-id='LogIn' style={{
                                        height: '56px',
                                        backgroundColor: '#2B6FED',
                                        color: '#ffffff',
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        lineHeight: '20px'
                                    }} fullWidth size="large"
                                            type="submit" onClick={this.verifyOtp}
                                    ><Typography style={{fontWeight: 'bolder', color: '#ffffff'}}
                                                 className="login-h1 bold-text "> NEXT</Typography></Button>

                                    <br/><br/>
                                    <Box>

                                        <div>
                                            {this.state.otpSent ? (
                                                <p style={{fontSize: '15px', textAlign: 'center'}}>Resend OTP in <span
                                                    className="login-h1 bold-text" style={{
                                                    color: '#FC8434',
                                                    fontSize: '15px'
                                                }}>00:{this.state.resendTimer}</span> seconds</p>
                                            ) : (

                                                <Button color="primary" variant="contained" data-test-id='ForgotOTP'
                                                        style={{
                                                            height: '56px',
                                                            backgroundColor: '#2B6FED',
                                                            color: '#ffffff',
                                                            borderRadius: '10px',
                                                            fontSize: '16px',
                                                            lineHeight: '20px'
                                                        }} fullWidth size="large"
                                                        type="submit" onClick={this.sendOTP}
                                                        disabled={this.state.isResendDisabled}
                                                > <Typography style={{fontWeight: 'bolder', color: '#ffffff'}}
                                                              className="login-h1 bold-text ">Resend
                                                    OTP</Typography></Button>
                                            )}
                                        </div>

                                    </Box>


                                </Box>
                            </Box>
                        </Hidden>
                    </Grid>
                    <Grid item sm={5} className="leftBox i">
                        <Hidden smDown>
                            <Box pt={2} style={{overflowY: 'hidden', overflowX: 'hidden', height: '100vh'}}
                                 visibility={{xs: 'none', md: 'flex'}}>
                                <img src={Building1.default} style={webStyle.side_imgg}/>
                            </Box>
                        </Hidden>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const webStyle = {
    container: {},
    login_img: {
        width: '116px',
        height: '45px',
    },
    side_imgg: {
        height: '95vh',
        width: '39vw',
        borderRadius: '40px'

    },
    Expand: {fontSize: '25px'},
}
// Customizable Area End

