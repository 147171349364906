// Customizable Area Start
import * as React from "react";
// custom components
import {
    Grid,
    Box,
    Divider,
    IconButton,
    Typography,
    Button,
} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import NominateMySelfController, {
    Props
} from "./NominateMySelfController";
import './MyTeam.web.css'
import {profileExp, pencil} from "./assets";
import {withTranslation} from "react-i18next";
import LoaderWeb from "../../../components/src/Loader.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class NominateMySelf extends NominateMySelfController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        //@ts-ignore
        const {t,i18n} = this.props
        const language = i18n.language;
        return (
            <>
                <Grid item xs={12} md={12} className="auth-cols myNominationFile" dir={languageCondition(language,"rtl","ltr")}>
                    <Grid container className="myNominationFile" style={{margin: '1rem', width: '90%'}}>
                        <Grid item className="myNominationFile" xs={12} style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            justifyContent: "space-between"
                        }}>
                            <Box className="myNominationFile"
                                 style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                                <ArrowBackIcon className="backButton" onClick={() => window.history.back()}/>
                                <p className="bold-text myNominationFile" style={{fontSize: '18px'}}>
                                    {t("My Nomination")}
                                </p>
                            </Box>
                            <IconButton className="myNominationFile editNomination"
                                        onClick={() => this.props.history.push(`/NominateMySelf?id=${this.state.nominationId}`)}
                                        style={{padding: "0px"}}>
                                <img src={pencil} width="24px" height="24px"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Box style={{
                        background: "white",
                        minHeight: "95%",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center',
                        justifyContent: "space-between"
                    }}>
                        <Grid container spacing={3} style={{width: "90%", marginTop: "20px"}}>
                            <Grid item xs={12}>
                                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box display="flex" alignItems="center">
                                        <img src={this.state.myProfile?.image?.url || profileExp} width="50px"
                                             height="50px" style={{borderRadius: "100px"}}/>
                                        <Box style={{marginLeft: "10px"}}>
                                            <Typography className="bold-text" style={{
                                                fontWeight: "bold",
                                                marginRight: "20px"
                                            }}>{this.state.myDetails.name}</Typography>
                                            <Typography variant="subtitle2" style={{
                                                width: "120px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>{this.state.myDetails?.unit_number?.join(",")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop: "10px"}}>
                                        <Typography variant="subtitle2"
                                                    className={"statusOngoingBlue bold-text"}>{t(this.state.myDetails.role)}</Typography>
                                    </Box>
                                </Box>
                                <Box style={{width: "100%", marginTop: "20px", minHeight: "150px"}}>
                                    <Typography>
                                        {this.state.myDetails.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box style={{width: "90%", marginBottom: "50px", marginTop: "10px"}}>
                            <CloseButton variant="contained" className={"cancelNomination"} fullWidth size="large"
                                         onClick={() => this.cancelMyNomination()}>
                                {t("Cancel Nomination")}
                            </CloseButton>
                        </Box>
                    </Box>
                </Grid>
                <LoaderWeb loading={this.state.loading}/>
            </>
        );
    }
}

// @ts-ignore
export default withTranslation()(withRouter(NominateMySelf))

const CloseButton = withStyles((theme) => ({
    root: {
        color: "#2B6FED",
        backgroundColor: "white",
        border: "1px solid #2B6FED",
        fontWeight: "bold",
        borderRadius: "100px",
        height: "55px",
        '&:hover': {
            backgroundColor: "#2b6fef",
            color: "white"
        },
    },
}))(Button);


// Customizable Area End
