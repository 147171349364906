import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  location: any;
  match: any;
  i18n: any;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusFilter:string;
  cityFilter:string,
  companyFilter:string,
  countryFilter:string,
  buildingFilter:string,
  categoryFilter:string,
  dateFilter:any;
  ticketFilter:any;
  page: any;
  sortFilter:any;
  pagination: any;
  loading:boolean;
  error:any;
  showError:boolean;
  ticketData:any;
  countryList:any;
  accountType:any;
  cardValues:any;
  complexList:any;
  cityList:any;
  buildingList:any;
  categoryList:any;
  ticketList:any;
  ticketOwnedFilter:any;
  search: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReceivedClientTicketController extends CommonApiCallForBlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getList:any;
  getCountryList:any
  getManagerListData:any;
  getComplexListApiCallId:any;
  getCitiesApiCallId:any;
  getBuildingApiCallId:any;
  getCategoryList:any;
  getTicketOwnedListData:any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      statusFilter:"",
      cityFilter:"",
      companyFilter:"",
      countryFilter:"",
      buildingFilter:"",
      categoryFilter:"",
      dateFilter:"",
      ticketFilter:"",
      sortFilter:"",
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      page:1,
      loading:false,
      error:null,
      showError:false,
      ticketData:[],
      countryList:[],
      accountType:"",
      cardValues:null,
      complexList:[],
      cityList:[],
      buildingList:[],
      categoryList:[],
      ticketList:[],
      ticketOwnedFilter:"",
      search: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        
        switch (apiRequestCallId) {
        
          case this.getList:
            return this.handleListSucc(responseJson);
          case this.getCountryList:
            return this.handleCountryList(responseJson);
          case this.getManagerListData:
            return this.handleManagerListSucc(responseJson);
            case this.getComplexListApiCallId:
              return this.handleComplexList(responseJson);
            case this.getCitiesApiCallId:
              return this.handleCityList(responseJson);
            case this.getBuildingApiCallId:
              return this.handleBuildingList(responseJson);
            case this.getCategoryList:
              return this.handleCategoryList(responseJson);
            case this.getTicketOwnedListData:
              return this.handleticketSucc(responseJson)
          
          default:
            break

         
        }
        
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
 
  async componentDidMount(): Promise<void> {
    const accountRole = localStorage.getItem("role")
    
    this.setState({ accountType: accountRole })
    if(accountRole==="account_manager"){
      this.getManagerList();
    }else{
      this.getTicketDetails();
    }
   
    this.getCountryListData()
    this.getCategoryListData();
    this.getTicketOwnedList()

  }
  handleComplexList=(responseJson:any)=>{
    this.setState({complexList:responseJson.data})
  }
  handleBuildingList=(responseJson:any)=>{
    this.setState({buildingList:responseJson.data.buildings})
  }
  handleCityList=(responnseJson:any)=>{
    this.setState({cityList:responnseJson.city_list})
  }
  
  handleSearch = () => {
    if(this.state.accountType==="account_manager"){
      this.getManagerList()
    }else{
      this.getTicketDetails()
    }
  }
  getTicketOwnedList = async () => {
    this.getTicketOwnedListData = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:`bx_block_contact_us/client_tickets/list_of_ticket_accounts`
    });
  };
  getBuildingList = async () => {
    this.getBuildingApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/building_list?society_management_id=${this.state.companyFilter}`,
    });
  };
  handleCategoryList=(responseJson:any)=>{
    this.setState({categoryList:responseJson.client_ticket_category})
  }
  handleticketSucc=(responseJson:any)=>{
    this.setState({ticketList:responseJson.data})
  }
  handleCountryList=(responseJson:any)=>{
    this.setState({countryList:responseJson.country_list})
  }
  handlePage=(e:any,value:any)=>{
    this.setState({
      page:value
    })
  }

  
  handleFilterDataKeyUp=(e: any)=>{
    this.setState({search: e.target.value}, () => {
      if(this.state.accountType==="account_manager"){
        this.getManagerList()
      }else{
        this.getTicketDetails()
      }
    })
  }
  handleListSucc=(responseJson:any)=>{
    this.setState({ticketData:responseJson.data,cardValues:responseJson.meta,
      pagination:{
        current_page:responseJson.meta?.pagination?.current_page,
        total_count:responseJson.meta?.pagination?.total_count,
        total_pages:responseJson.meta?.pagination?.total_pages,
      },})
  }
  handleManagerListSucc=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({ticketData:responseJson.data,cardValues:responseJson.meta,
        pagination:{
          current_page:responseJson.meta?.pagination?.current_page,
          total_count:responseJson.meta?.pagination?.total_count,
          total_pages:responseJson.meta?.pagination?.total_pages,
        },})
    }
  }

  getTicketDetails = async () => {
    const match: any = this.props.match;
    const id = match?.params.id;
    this.getList = await apiCall({
      contentType: "application/json",
      method: "GET",
      
      endPoint: `bx_block_contact_us/client_tickets/list_client_tickets?page=1&per_page=10&search=${this.state.search}&account_manager_ids[]=${id}&category_id=${this.state.categoryFilter}&complex_id=${this.state.companyFilter}&date=${this.state.dateFilter}&country=${this.state.countryFilter}&city=${this.state.cityFilter}&status=${this.state.statusFilter}&sort_by_date=${this.state.sortFilter}&building_id=${this.state.buildingFilter}&ticket_id=${this.state.ticketOwnedFilter}`,
    });
  };
  getManagerList = async () => {
    this.getManagerListData = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_contact_us/client_tickets/list_client_tickets?page=${this.state.page}&search=${this.state.search}&per_page=10&account_manager_ids[]=&category_id=${this.state.categoryFilter}&complex_id=${this.state.companyFilter}&date=${this.state.dateFilter}&country=${this.state.countryFilter}&city=${this.state.cityFilter}&status=${this.state.statusFilter}&sort_by_date=${this.state.sortFilter}&building_id=${this.state.buildingFilter}&ticket_id=${this.state.ticketOwnedFilter}`,
    });
  };
  handleCountryFilterChange = (e:any) => {
    const { value } = e.target;
    this.setState({ countryFilter: value }, () => {
      this.getComplexList();
      this.getCitiesList();
    });
  };
  getCitiesList = async () => {

    this.getCitiesApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint:`/bx_block_custom_form/incident_managements/city_list?country=${this.state.countryFilter}`

    });
  };
  getComplexList = async () => {
    this.getComplexListApiCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/list_of_complex?country=${this.state.countryFilter}&city=${this.state.cityFilter}`,
    });
  };
  getCountryListData = async () => {
   
    this.getCountryList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_custom_form/incident_managements/country_list`,
    });
  };
  getCategoryListData = async () => {

    this.getCategoryList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_contact_us/client_tickets/list_of_ticket_categories`,
    });
  };
  // Customizable Area End
}
