// Customizable Area Start
import React from "react";
export const MeetingImageComponent = () => {
  return (
    <svg
      width="28.605957px"
      height="25.870117px"
      viewBox="0 0 28.605957 25.870117"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="interview" transform="translate(0.35009766 0.34960938)">
        <path
          d="M26.0678 21.8771L24.4733 21.8771C24.2532 21.8771 24.0747 21.6986 24.0747 21.4785C24.0747 21.2583 24.2532 21.0798 24.4733 21.0798L26.0678 21.0798C26.3827 21.0796 26.6806 20.937 26.8782 20.6918C27.0758 20.4466 27.1519 20.1252 27.0851 19.8174L27.0018 19.4351C26.9547 19.22 27.0908 19.0075 27.3059 18.9604C27.521 18.9133 27.7336 19.0494 27.7807 19.2645L27.864 19.6472C27.9821 20.1907 27.848 20.7585 27.4991 21.1916C27.1502 21.6248 26.624 21.8767 26.0678 21.8771L26.0678 21.8771Z"
          id="Path"
          stroke-width="0.7"
        />
        <path
          d="M3.43406 21.877L1.83958 21.877C1.28325 21.877 0.75681 21.6252 0.407595 21.1922C0.0583798 20.7591 -0.0760972 20.1913 0.041799 19.6476L1.73195 11.8788C1.96745 10.8162 2.90766 10.0584 3.99612 10.054L23.9112 10.054C24.9992 10.0589 25.9388 10.8166 26.1742 11.8788L27.7806 19.2645C27.8275 19.4797 27.6911 19.6922 27.4759 19.739C27.2607 19.7859 27.0482 19.6495 27.0013 19.4343L25.3961 12.0483C25.2415 11.352 24.6256 10.8556 23.9124 10.8524L3.99612 10.8524C3.28296 10.8556 2.66699 11.352 2.51245 12.0483L0.822299 19.8174C0.755533 20.1251 0.83158 20.4466 1.02917 20.6918C1.22676 20.937 1.52467 21.0796 1.83958 21.0798L3.43406 21.0798C3.65421 21.0798 3.83268 21.2583 3.83268 21.4784C3.83268 21.6986 3.65421 21.877 3.43406 21.877Z"
          id="Path"
          stroke-width="0.7"
        />
        <path
          d="M16.19 21.8787L11.7175 21.8787C11.4973 21.8787 11.3188 21.7002 11.3188 21.48C11.3188 21.2599 11.4973 21.0814 11.7175 21.0814L16.19 21.0814C16.4101 21.0814 16.5886 21.2599 16.5886 21.48C16.5886 21.7002 16.4101 21.8787 16.19 21.8787Z"
          id="Path"
          stroke-width="0.7"
        />
        <path
          d="M24.9248 25.1705L15.7382 25.1705C15.5181 25.1705 15.3396 24.992 15.3396 24.7719L15.3396 23.0857C15.3418 21.1652 16.8982 19.6088 18.8188 19.6066L21.8463 19.6066C23.7669 19.6088 25.3233 21.1652 25.3255 23.0857L25.3255 24.7719C25.3255 24.878 25.2832 24.9797 25.208 25.0545C25.1328 25.1293 25.0309 25.171 24.9248 25.1705ZM16.137 24.3733L24.5263 24.3733L24.5263 23.0857C24.5248 21.606 23.3262 20.4064 21.8464 20.4038L18.8209 20.4038C17.3403 20.4053 16.1405 21.6052 16.139 23.0857L16.137 24.3733Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
        <path
          d="M20.3315 20.3934C18.669 20.3932 17.3214 19.0453 17.3215 17.3828C17.3217 15.7203 18.6695 14.3726 20.3321 14.3727C21.9946 14.3728 23.3423 15.7205 23.3423 17.3831C23.3405 19.0451 21.9935 20.3919 20.3315 20.3934L20.3315 20.3934ZM20.3316 15.1715C19.1094 15.1717 18.1187 16.1627 18.1189 17.3849C18.119 18.6072 19.1099 19.5979 20.3322 19.5978C21.5544 19.5977 22.5452 18.6069 22.5452 17.3847C22.5445 16.1622 21.5541 15.1712 20.3316 15.1699L20.3316 15.1715Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
        <path
          d="M18.5469 10.7978L9.36029 10.7978C9.14014 10.7978 8.96167 10.6194 8.96167 10.3992L8.96167 8.71305C8.96387 6.79247 10.5203 5.23608 12.4408 5.23389L15.4684 5.23389C17.3889 5.23608 18.9453 6.79247 18.9475 8.71305L18.9475 10.3992C18.9475 10.5053 18.9053 10.607 18.8301 10.6818C18.7549 10.7566 18.653 10.7984 18.5469 10.7978L18.5469 10.7978ZM9.75903 10.0006L18.1484 10.0006L18.1484 8.71305C18.1469 7.23328 16.9482 6.03376 15.4685 6.03113L12.4429 6.03113C10.9624 6.03267 9.76256 7.2325 9.76103 8.71305L9.75903 10.0006Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
        <path
          d="M13.9536 6.02077C12.2911 6.02055 10.9435 4.67266 10.9436 3.01012C10.9438 1.34758 12.2916 -7.33721e-05 13.9541 0C15.6167 7.33721e-05 16.9644 1.34785 16.9644 3.01038C16.9626 4.6724 15.6156 6.01923 13.9536 6.02077ZM13.9537 0.798836C12.7315 0.799056 11.7408 1.79001 11.741 3.01224C11.7411 4.23448 12.732 5.22519 13.9542 5.22512C15.1765 5.22505 16.1673 4.23421 16.1673 3.01198C16.1668 1.78946 15.1762 0.798341 13.9537 0.797241L13.9537 0.798836Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
        <path
          d="M12.169 25.1705L2.98236 25.1705C2.76221 25.1705 2.58374 24.992 2.58374 24.7719L2.58374 23.0857C2.58593 21.1659 4.14111 19.6099 6.06091 19.6066L9.09043 19.6066C11.011 19.6088 12.5674 21.1652 12.5696 23.0857L12.5696 24.7719C12.5696 24.878 12.5273 24.9797 12.4521 25.0545C12.3769 25.1293 12.275 25.171 12.169 25.1705ZM3.3809 24.3733L11.7703 24.3733L11.7703 23.0857C11.7687 21.606 10.5701 20.4064 9.09035 20.4038L6.06083 20.4038C4.58028 20.4053 3.38044 21.6052 3.37891 23.0857L3.3809 24.3733Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
        <path
          d="M7.57566 20.3938C5.91312 20.3936 4.56553 19.0457 4.56567 17.3832C4.56582 15.7206 5.91365 14.373 7.57619 14.373C9.23873 14.3731 10.5864 15.7209 10.5864 17.3834C10.5847 19.0454 9.23768 20.3923 7.57566 20.3938L7.57566 20.3938ZM7.57578 15.1719C6.35355 15.1721 5.36289 16.1631 5.36304 17.3853C5.36318 18.6075 6.35408 19.5982 7.57631 19.5982C8.79855 19.5981 9.78932 18.6073 9.78932 17.385C9.78866 16.1626 8.79821 15.1716 7.57578 15.1703L7.57578 15.1719Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.7"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
