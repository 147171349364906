import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { WithTranslationProps } from "react-i18next";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";
import RichTextEditor from "react-rte";
import moment from "moment";

interface FormValue {
  type: string;
  title: string;
  country: string;
  city: string;
  date: string;
  time: string;
  isComplex: number;
  complex: string;
  building: string;
  place: string;
  scheduledBy: string;
  agenda: string;
  status: string;
  role: any[];
  ownerIds: any[];
  residentIds: any[];
  meetingGroupIds: any[];
}

interface MeetingDetails {
  title: string;
  type: string;
  status: string;
  dateTime: string;
  place: string;
  complex: string;
  building: string;
  agenda: string;
  scheduleBy: string;
  scheduleOn: string;
  cancelBy: string;
  cancelOn: string;

  count: any;
}

interface FilterGroup {
  isComplex: number;

  complex: string;
  building: string;
  userType: string;
}

interface FilterList {
  company: string;
  country: string;
  city: string;
  complex: string;
  building: string;
  place: string;
  status: string;
  scheduleBy: string;

  isComplex: number;

  search: string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props extends WithTranslationProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;

  isOpenForm: boolean;
  onFormClose: () => void;
  isEditFormOpen: boolean;
  formValue: FormValue;
  meetingId: string | number;
  onSubmitScheduleList: () => void;
  onSubmitScheduleDetails: () => void;
  page: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  meetingData: any[];
  pagination: any;
  page: number;

  filter: FilterList;

  filterGroup: FilterGroup;
  selectedUser: any[];
  isSelectAllUser: boolean;
  groupName: string;

  reactStateList: any[];
  countriesList: any[];
  cityList: any[];
  groupComplexList: any[];
  complexList: any[];
  filterComplexList: any[];
  groupFilterComplexList: any[];
  buildingList: any[];
  scheduleByList: any[];
  groupList: any[];
  groupUserList: any[];

  ownerIdsList: any[];
  residentIdsList: any[];

  isCancelMeetingDialogOpen: boolean;
  isFormDialogOpen: boolean;
  isGroupDialogOpen: boolean;

  isEditFormOpen: boolean;
  isEditGroupOpen: boolean;
  meetingEditId: number | string;
  meetingGroupEditId: number | string;

  formValue: FormValue;

  scheduleMeetingId: number | string;
  meeting: any;
  meetingResponseList: any[];
  meetingResponsePagination: any;
  responsePage: number | string;
  meetingDetails: MeetingDetails;

  isNotePreviewOpen: boolean;
  isSubmitMeetingNoteOpen: boolean;
  meetingNote: {
    value: any;
    note: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEScheduleMeetingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetScheduleMeetingList: any;
  GetReactEStateCallId: any;
  GetCountryList: any;
  GetCityFilterList: any;
  GetComplexFilterList: any;
  GetGroupComplexList: any;
  GetBuildingFilterList: any;
  GetScheduleByList: any;
  GetMeetingGroupList: any;
  GetMeetingGroupUserListCallId: any;
  GetMeetingDetailsByIdCallId: any;
  GetMeetingDetailsResponseCallId: any;
  UpdateMeetingNoteCallId: any;
  CreateGroupCallId: any;
  UpdateGroupCallId: any;
  DeleteGroupCallId: any;
  CancelMeetingCallId: any;
  GetOwnerIdsCallId: any;
  GetResidentIdsCallId: any;
  CreateScheduleMeetingCallId: any;
  EditScheduleMeetingCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      meetingData: [],

      pagination: null,
      page: this.props.page ?? 1,

      filter: {
        company: "",
        country: "",
        city: "",
        complex: "",
        building: "",
        place: "",
        status: "",
        scheduleBy: "",

        isComplex: 1,

        search: "",
      },

      filterGroup: {
        isComplex: 1,

        complex: "",
        building: "",
        userType: "",
      },
      selectedUser: [],
      isSelectAllUser: false,
      groupName: "",

      reactStateList: [],
      countriesList: [],
      cityList: [],
      complexList: [],
      groupComplexList: [],
      filterComplexList: [],
      groupFilterComplexList: [],
      buildingList: [],
      scheduleByList: [],
      groupList: [],
      groupUserList: [],

      ownerIdsList: [],
      residentIdsList: [],

      formValue: {
        type: "",
        title: "",
        country: "",
        city: "",
        date: "",
        time: "",
        isComplex: 1,
        complex: "",
        building: "",
        place: "",
        scheduledBy: "",
        agenda: "",
        status: "scheduled",
        role: [],
        ownerIds: [],
        residentIds: [],
        meetingGroupIds: [],
      },

      isCancelMeetingDialogOpen: false,
      isFormDialogOpen: false,
      isGroupDialogOpen: false,

      isEditFormOpen: false,
      isEditGroupOpen: false,
      meetingEditId: "",
      meetingGroupEditId: "",

      scheduleMeetingId: "",
      meeting: null,
      meetingResponseList: [],
      meetingResponsePagination: null,
      responsePage: 1,
      meetingDetails: {
        title: "",
        type: "",
        status: "",
        dateTime: "",
        place: "",
        complex: "",
        building: "",
        agenda: "",
        scheduleBy: "",
        scheduleOn: "",
        cancelBy: "",
        cancelOn: "",
        count: {
          await: 0,
          accept: 0,
          reject: 0,
        },
      },

      isNotePreviewOpen: false,
      isSubmitMeetingNoteOpen: false,
      meetingNote: {
        value: this.value,
        note: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetScheduleMeetingList:
          return this.handleMeetingSuccess(responseJson);
        case this.GetReactEStateCallId:
          return this.handleReactEstateResponse(responseJson);
        case this.GetCountryList:
          return this.handleCountryResponse(responseJson);
        case this.GetCityFilterList:
          return this.handleCityResponse(responseJson);
        case this.GetComplexFilterList:
          return this.handleComplexListResponse(responseJson);
        case this.GetGroupComplexList:
          return this.handleGroupComplexListResponse(responseJson);
        case this.GetBuildingFilterList:
          return this.handleBuildingListResponse(responseJson);
        case this.GetScheduleByList:
          return this.handleScheduleByListResponse(responseJson);
        case this.GetMeetingDetailsByIdCallId:
          return this.handleMeetingDetailsByIdResponse(responseJson);
        case this.GetMeetingDetailsResponseCallId:
          return this.handleMeetingDetailResponseResponse(responseJson);
        case this.UpdateMeetingNoteCallId:
          return this.handleUpdateMeetingNoteResponse(responseJson);
        case this.GetMeetingGroupList:
          return this.handleGetMeetingGroupListResponse(responseJson);
        case this.GetMeetingGroupUserListCallId:
          return this.handleGetMeetingGroupUserListResponse(responseJson);
        case this.CreateGroupCallId:
          return this.handleCreateGroupResponse(responseJson);
        case this.UpdateGroupCallId:
          return this.handleEditGroupResponse(responseJson);
        case this.DeleteGroupCallId:
          return this.handleDeleteGroupResponse(responseJson);
        case this.CancelMeetingCallId:
          return this.handleCancelMeetingResponse(responseJson);
        case this.GetOwnerIdsCallId:
          return this.handleOwnerIdsResponse(responseJson);
        case this.GetResidentIdsCallId:
          return this.handleResidentIdsResponse(responseJson);
        case this.CreateScheduleMeetingCallId:
          return this.handleCreateScheduleMeetingResponse(responseJson);
        case this.EditScheduleMeetingCallId:
          return this.handleEditScheduleMeetingResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  value = RichTextEditor.createValueFromString("", "html");

  handlePagination = (e: any, value: any) => {
    this.setState({ page: value });
  };

  handleResponsePagination = (e: any, value: any) => {
    this.setState({ responsePage: value });
  };

  handleAllFilter = (value: string) => (value === "all" ? "" : value);

  getScheduleMeetingList = () => {
    const { country, city, complex, building, place, status, scheduleBy, search } = this.state.filter;
    this.setState({ loading: true }, async () => {
      this.GetScheduleMeetingList = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_meeting/company_employee_meetings?page=${this.state.page}&per_page=10&contry=${this.handleAllFilter(
          country
        )}&city=${this.handleAllFilter(city)}&society_management_id=${this.handleAllFilter(complex)}&building_management_id=${this.handleAllFilter(
          building
        )}&place=${this.handleAllFilter(place)}&status=${this.handleAllFilter(status)}&title=${this.handleAllFilter(
          search
        )}&scheduled_by=${this.handleAllFilter(scheduleBy)}`,
      });
    });
  };

  handleMeetingSuccess = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.meeting && responseJson.meeting.data) {
        this.setState({ meetingData: responseJson.meeting.data, pagination: responseJson.meta.pagination });
      }
    });
  };

  handleFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;
    if (stateParam === "company") {
      this.setState({
        filter: {
          ...this.state.filter,
          company: value,
          country: "",
          city: "",
          complex: "",
          building: "",
          place: "",
          status: "",
          scheduleBy: "",
        },
      });
    } else if (stateParam === "country") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            country: value,
            city: "",
            complex: "",
            building: "",
            place: "",
            status: "",
            scheduleBy: "",
          },
        },
        () => this.getCityFilterList(value)
      );
    } else if (stateParam === "city") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            city: value,
            complex: "",
            building: "",
            place: "",
            status: "",
            scheduleBy: "",
          },
        },
        () => {
          const { country, city } = this.state.filter;
          this.getComplexFilterList(country, city);
        }
      );
    } else if (stateParam === "complex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            complex: value,
            building: "",
            place: "",
            status: "",
            scheduleBy: "",
          },
        },
        () => this.getBuildingFilterList(value)
      );
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building: value,
          place: "",
          status: "",
          scheduleBy: "",
        },
      });
    } else if (stateParam === "place") {
      this.setState({
        filter: {
          ...this.state.filter,
          place: value,
          status: "",
          scheduleBy: "",
        },
      });
    } else if (stateParam === "status") {
      this.setState({
        filter: {
          ...this.state.filter,
          status: value,
          scheduleBy: "",
        },
      });
    } else if (stateParam === "scheduleBy") {
      this.setState({
        filter: {
          ...this.state.filter,
          scheduleBy: value,
        },
      });
    } else if (stateParam === "isComplex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            isComplex: value,
          },
        },
        () => this.handleFilterComplexList(value)
      );
    }
  };

  getReactEstate = async () => {
    this.GetReactEStateCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`,
    });
  };

  handleReactEstateResponse = (responseJson: any) => {
    if (responseJson && responseJson.compneys) {
      this.setState({
        reactStateList: responseJson.compneys.map((item: any) => ({ label: item.company_name, value: item.id })),
      });
    }
  };

  getCountryListAPI = async () => {
    this.GetCountryList = await apiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countriesList: responseJson.data.countries.map((item: any) => ({ label: item, value: item })) });
    }
  };

  getCityFilterList = async (country: any) => {
    this.GetCityFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${this.handleAllFilter(country)}`,
    });
  };

  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.city_list) {
      this.setState({ cityList: responseJson.city_list.map((item: any) => ({ label: item.city, value: item.city })) });
    }
  };

  handleFilterComplexList = (value: number) => {
    if (value === 0) {
      this.setState({
        filterComplexList: this.state.complexList.map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    } else if (value === 1) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => !complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    } else if (value === 2) {
      this.setState({
        filterComplexList: this.state.complexList
          .filter((complex) => complex.attributes.is_building)
          .map((item: any) => ({ label: item.attributes.name, value: item.id })),
      });
    }
  };

  getComplexFilterList = async (country?: string, city?: string) => {
    this.GetComplexFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${country}`,
    });
  };

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.handleFilterComplexList(1);
      });
    }
  };

  handleGroupFilterComplexList = (value: number) => {
    if (value === 0) {
      this.setState({
        groupFilterComplexList: this.state.groupComplexList.map((item: any) => ({ label: item.name, value: item.id })),
      });
    } else if (value === 1) {
      this.setState({
        groupFilterComplexList: this.state.groupComplexList
          .filter((complex) => !complex.is_building)
          .map((item: any) => ({ label: item.name, value: item.id })),
      });
    } else if (value === 2) {
      this.setState({
        groupFilterComplexList: this.state.groupComplexList
          .filter((complex) => complex.is_building)
          .map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getGroupComplexList = async () => {
    this.GetGroupComplexList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/complex_list`,
    });
  };

  handleGroupComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ groupComplexList: responseJson.complexes }, () => {
        this.handleGroupFilterComplexList(1);
      });
    }
  };

  getBuildingFilterList = async (complexId: any) => {
    this.GetBuildingFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getScheduleByList = async () => {
    this.GetScheduleByList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_meeting/company_employee_meetings/schedule_by_list`,
    });
  };

  handleScheduleByListResponse = (responseJson: any) => {
    if (responseJson && responseJson.scheduled_by) {
      this.setState({
        scheduleByList: responseJson.scheduled_by.map((item: any) => ({ label: item, value: item })),
      });
    }
  };

  handleGroupFilterChange = (name: any, value: any) => {
    if (name === "isComplex") {
      this.setState(
        {
          filterGroup: {
            ...this.state.filterGroup,
            isComplex: value,
          },
        },
        () => this.handleGroupFilterComplexList(value)
      );
    } else if (name === "complex") {
      this.setState(
        {
          filterGroup: {
            ...this.state.filterGroup,
            complex: value,
            building: "",
            userType: "",
          },
        },
        () => this.getBuildingFilterList(value)
      );
    } else if (name === "building") {
      this.setState({
        filterGroup: {
          ...this.state.filterGroup,
          building: value,
          userType: "",
        },
      });
    } else if (name === "userType") {
      this.setState({
        filterGroup: {
          ...this.state.filterGroup,
          userType: value,
        },
      });
    }
  };

  handleGroupFilter = () => {
    this.setState({ loading: true }, () => {
      this.getMeetingGroupUserList();
    });
  };

  handleMeetingDialog = () => {
    this.setState(
      (prevState) => ({ isFormDialogOpen: !prevState.isFormDialogOpen }),
      () => {
        if (!this.state.isFormDialogOpen) {
          this.setState({ isEditFormOpen: false });
        }
      }
    );
  };

  handleMeetingGroupDialog = () => {
    this.setState((prevState) => ({ isGroupDialogOpen: !prevState.isGroupDialogOpen }));
  };

  handleListCancelMeetingDialog = (meeting: any) => {
    this.setState(
      {
        scheduleMeetingId: meeting.id,
        meetingDetails: {
          ...this.state.meetingDetails,
          place: meeting.attributes.place,
          scheduleOn: meeting.attributes?.meeting_schedule_detail?.scheduled_on,
        },
      },
      () => {
        this.handleCancelMeetingDialog();
      }
    );
  };

  handleCancelMeetingDialog = () => {
    this.setState((prevState) => ({ isCancelMeetingDialogOpen: !prevState.isCancelMeetingDialogOpen }));
  };

  handleCancelMeeting = () => {
    this.setState({ loading: true, isCancelMeetingDialogOpen: false }, async () => {
      const formData = new FormData();
      formData.append("meeting[status]", "cancelled");
      this.CancelMeetingCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_meeting/company_employee_meetings/${this.state.scheduleMeetingId}`,
        body: formData,
      });
    });
  };

  handleCancelMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      if (responseJson && responseJson.code === 200) {
        this.setState(
          {
            meetingData: this.state.meetingData.map((meeting: any) =>
              meeting.id === responseJson.meeting.data.id ? responseJson.meeting.data : meeting
            ),
          },
          () => {
            this.handleMeetingDetailsByIdResponse(responseJson);
          }
        );
      }
    });
  };

  getMeetingGroupList = async () => {
    this.GetMeetingGroupList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/1/bx_block_meeting/meeting_groups`,
    });
  };

  handleGetMeetingGroupListResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ groupList: responseJson.data });
    }
  };

  getMeetingGroupUserList = async () => {
    const { complex, building, userType } = this.state.filterGroup;
    this.GetMeetingGroupUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/5/bx_block_meeting/meeting_groups/add_group_member?search_complex=${this.handleAllFilter(
        complex
      )}&search_building=${this.handleAllFilter(building)}&user_type=${this.handleAllFilter(userType)}`,
    });
  };

  handleGetMeetingGroupUserListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({ groupUserList: responseJson.data });
      }
    });
  };

  addUser = (Id: any) => {
    this.setState({ selectedUser: [...this.state.selectedUser, Id] });
  };

  removeUser = (Id: string) => {
    this.setState({ selectedUser: this.state.selectedUser.filter((id: any) => id !== Id) });
  };

  selectAllUser = () => {
    const idList = this.state.groupUserList.map((user: any) => user.id);
    this.setState({
      selectedUser: idList,
      isSelectAllUser: true,
    });
  };

  removeAllUser = () => {
    this.setState({ selectedUser: [], isSelectAllUser: false });
  };

  handleOpenEditGroup = (group: any) => {
    this.setState(
      {
        isEditGroupOpen: true,
        meetingGroupEditId: group.id,
        selectedUser: group.attributes.accounts.map((item: any) => item.id.toString()),
        groupName: group.attributes.group_name,
      },
      () => {
        this.handleMeetingGroupDialog();
      }
    );
  };

  handleCreateGroup = async () => {
    const body = {
      meeting_group: {
        group_name: this.state.groupName,
        account_ids: this.state.selectedUser,
      },
    };
    this.CreateGroupCallId = await apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `society_managements/1/bx_block_meeting/meeting_groups`,
      body: JSON.stringify(body),
    });
  };

  handleCreateGroupResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ isGroupDialogOpen: false, isEditGroupOpen: false, meetingGroupEditId: "", selectedUser: [], groupName: "" }, () => {
        this.getMeetingGroupList();
      });
    }
  };

  handleEditGroup = async () => {
    const body = {
      meeting_group: {
        group_name: this.state.groupName,
        account_ids: this.state.selectedUser,
      },
    };
    this.UpdateGroupCallId = await apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `society_managements/1/bx_block_meeting/meeting_groups/${this.state.meetingGroupEditId}`,
      body: JSON.stringify(body),
    });
  };

  handleEditGroupResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ isGroupDialogOpen: false, isEditGroupOpen: false, meetingGroupEditId: "", selectedUser: [], groupName: "" }, () => {
        this.getMeetingGroupList();
      });
    }
  };

  handleDeleteGroup = async (groupId: any) => {
    this.DeleteGroupCallId = await apiCall({
      contentType: "application/json",
      method: "DELETE",
      endPoint: `society_managements/5/bx_block_meeting/meeting_groups/${groupId}`,
    });
  };

  handleDeleteGroupResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getMeetingGroupList();
    }
  };

  getMeetingDetailsById = () => {
    this.setState({ loading: true }, async () => {
      this.GetMeetingDetailsByIdCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `/bx_block_meeting/company_employee_meetings/${this.state.scheduleMeetingId}`,
      });
    });
  };

  handleMeetingDetailsByIdResponse = (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      if (responseJson && responseJson.meeting) {
        const meeting = responseJson.meeting.data;
        this.setState({
          scheduleMeetingId: responseJson.meeting.data.id,
          meeting: responseJson.meeting.data,
          meetingDetails: {
            title: meeting.attributes.title,
            type: meeting.attributes.meeting_type,
            status: meeting.attributes.status,
            dateTime: meeting.attributes.meeting_date_time,
            place: meeting.attributes.place,
            complex: meeting.attributes.society_management,
            building: meeting.attributes?.building?.name,
            agenda: meeting.attributes.agenda,
            scheduleBy: meeting.attributes?.meeting_schedule_detail?.scheduled_by,
            scheduleOn: meeting.attributes?.meeting_schedule_detail?.scheduled_on,
            cancelBy: meeting.attributes?.meeting_cancel_detail?.cancelled_by,
            cancelOn: meeting.attributes?.meeting_cancel_detail?.cancelled_on,

            count: {
              await: meeting.attributes.meeting_responses.awaited,
              accept: meeting.attributes.meeting_responses.accepted,
              reject: meeting.attributes.meeting_responses.rejected,
            },
          },
          meetingNote: {
            note: meeting.attributes.meeting_mins_notes,
            value: RichTextEditor.createValueFromString(meeting.attributes.meeting_mins_notes, "html"),
          },
        });
      }
    });
  };

  getMeetingDetailResponseList = async () => {
    const { responsePage } = this.state;
    this.GetMeetingDetailsResponseCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_meeting/company_employee_meetings/meeting_responces?meeting_id=${this.state.scheduleMeetingId}&page=${responsePage}`,
    });
  };

  handleMeetingDetailResponseResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ meetingResponseList: responseJson.data.data, meetingResponsePagination: responseJson.meta.pagination });
    }
  };

  handleNotePreview = () => {
    this.setState((prevState) => ({ isNotePreviewOpen: !prevState.isNotePreviewOpen }));
  };

  handleSubmitMeetingNoteDialog = () => {
    this.setState((prevState) => ({ isSubmitMeetingNoteOpen: !prevState.isSubmitMeetingNoteOpen }));
  };

  handleNoteChange = (value: any) => {
    value.toString("html");
    this.setState({ meetingNote: { ...this.state.meetingNote, value: value } });
  };

  updateMeetingNote = () => {
    this.setState({ loading: true, isSubmitMeetingNoteOpen: false }, async () => {
      this.UpdateMeetingNoteCallId = await apiCall({
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_meeting/compney_employee_meeting_mins/meeting_mins_notes?id=${this.state.scheduleMeetingId}`,
        body: JSON.stringify({
          meeting_mins_notes: this.state.meetingNote.value._cache.html,
        }),
      });
    });
  };

  handleUpdateMeetingNoteResponse = async (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      if (responseJson.code === 200) {
        if (sessionStorage.getItem("isMinuteMeeting")) {
          this.props.navigation.navigate("CEMeetingDetail", { id: this.state.scheduleMeetingId });
        } else {
          this.props.navigation.navigate("CEScheduleMeetingDetails", { id: this.state.scheduleMeetingId });
        }
      }
    });
  };

  handleAddMeetingMinute = () => {
    sessionStorage.removeItem("isMinuteMeeting");
    this.props.navigation.navigate("CEAddScheduleMeetingMinute", { id: this.state.scheduleMeetingId })
  }

  meetingValidation: any = Yup.object().shape({
    type: Yup.string().required("Required").matches(/\S/, "Required"),
    title: Yup.string().required("Required").max(100, "Maximum length of title should be 100 character").matches(/\S/, "Required"),
    date: Yup.string().required("Required").matches(/\S/, "Required"),
    status: Yup.string().required("Required").matches(/\S/, "Required"),
    time: Yup.string()
      .required("Required")
      .matches(/\S/, "Required")
      .when(["status", "date"], (status: any, date: any, schema: any) => {
        const newDate = date && moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
        return schema.test({
          test: (time: any) => {
            if (status == "scheduled" && date && time) {
              const test = moment(newDate + ` ${time}`, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
              return moment(test).isAfter(new Date());
            }
            return true;
          },
          message: "You have entered past time",
        });
      }),
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    place: Yup.string().required("Required").max(100, "Maximum length of title should be 100 character").matches(/\S/, "Required"),
    agenda: Yup.string().required("Required").max(100, "Maximum length of title should be 100 character").matches(/\S/, "Required"),
    meetingGroupIds: Yup.array().when("role", {
      is: (role) => role.length === 0,
      then: Yup.array().min(1, "Required"),
    }),
  });

  createScheduleMeeting = (value: any) => {
    this.setState({ loading: true, isFormDialogOpen: false }, async () => {
      const roleIds: any[] = [...new Set([...value.ownerIds, ...value.residentIds])];

      const body = {
        meeting: {
          title: value.title,
          date: value.date,
          time: value.time,
          agenda: value.agenda,
          place: value.place,
          status: value.status,
          building_management_id: this.handleAllFilter(value.building),
          society_management_id: value.complex,
          meeting_type: value.type,
          joinee_ids: roleIds,
          meeting_group_ids: value.meetingGroupIds,
          meeting_attandees_type: value.role,
        },
      };

      this.CreateScheduleMeetingCallId = await apiCall({
        contentType: "application/json",
        method: "POST",
        endPoint: `bx_block_meeting/company_employee_meetings`,
        body: JSON.stringify(body),
      });
    });
  };

  handleCreateScheduleMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.code === 200) {
        this.props.onSubmitScheduleList();
      }
    });
  };

  editScheduleMeeting = async (value: any, meetingId: any) => {
    this.setState({ loading: true, isFormDialogOpen: false, scheduleMeetingId: meetingId, isEditFormOpen: false }, async () => {
      const roleIds: any[] = [...new Set([...value.ownerIds, ...value.residentIds])];

      const body = {
        meeting: {
          title: value.title,
          date: value.date,
          time: value.time,
          agenda: value.agenda,
          place: value.place,
          status: value.status,
          scheduled_by: value.scheduledBy,
          building_management_id: this.handleAllFilter(value.building),
          society_management_id: value.complex,
          meeting_type: value.type,
          joinee_ids: roleIds,
          meeting_group_ids: value.meetingGroupIds,
          meeting_attandees_type: value.role,
        },
      };

      this.EditScheduleMeetingCallId = await apiCall({
        contentType: "application/json",
        method: "PUT",
        endPoint: `bx_block_meeting/company_employee_meetings/${meetingId}`,
        body: JSON.stringify(body),
      });
    });
  };

  handleEditScheduleMeetingResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.code === 200) {
        this.props.onSubmitScheduleList();
        this.props.onSubmitScheduleDetails();
      }
    });
  };

  handleOpenCreateForm = () => {
    this.setState(
      {
        isEditFormOpen: false,
        scheduleMeetingId: "",
        formValue: {
          type: "",
          title: "",
          country: "",
          city: "",
          date: "",
          time: "",
          isComplex: 1,
          complex: "",
          building: "",
          place: "",
          scheduledBy: "",
          agenda: "",
          status: "scheduled",
          role: [],
          ownerIds: [],
          residentIds: [],
          meetingGroupIds: [],
        },
      },
      () => {
        this.handleMeetingDialog();
      }
    );
  };

  handleOpenEditForm = (meeting: any) => {
    const meetingGroupList = meeting.attributes.meeting_groups.meeting_group.map((group: any) => group.id.toString());

    let selectedGroupList: any[] = [...meetingGroupList];
    let meetingRole: any[] = [];
    if (meeting.attributes.meeting_groups.meeting_owner === "owner") {
      meetingRole = [...meetingRole, "owner"];
    }
    if (meeting.attributes.meeting_groups.meeting_resident === "resident") {
      meetingRole = [...meetingRole, "resident"];
    }

    this.setState(
      {
        isEditFormOpen: true,
        scheduleMeetingId: meeting.id,
        formValue: {
          type: meeting.attributes.meeting_type,
          title: meeting.attributes.title,
          country: meeting.attributes.country,
          city: meeting.attributes.city,
          date: meeting.attributes.meeting_date_time.split(" ")[0].split("-").reverse().join("-"),
          time: meeting.attributes.meeting_date_time.split(" ")[1],
          isComplex: meeting.attributes.complex.is_building === false ? 1 : 0,
          complex: meeting.attributes.complex.id,
          building: meeting.attributes.building ? meeting.attributes.building.id : "all",
          place: meeting.attributes.place,
          scheduledBy: meeting.attributes.meeting_schedule_detail.scheduled_by,
          agenda: meeting.attributes.agenda,
          status: meeting.attributes.status,
          role: meetingRole,
          ownerIds: [],
          residentIds: [],
          meetingGroupIds: selectedGroupList,
        },
      },
      () => {
        this.getOwnerIdsList(meeting.attributes.complex.id);
        this.getResidentIdsList(meeting.attributes.complex.id);
        this.handleMeetingDialog();
      }
    );
  };

  getOwnerIdsList = async (society_id: any) => {
    this.GetOwnerIdsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/owner?building_management_id=${society_id}`,
    });
  };

  handleOwnerIdsResponse = (responseJson: any) => {
    if (responseJson && responseJson.owner_account) {
      this.setState({ ownerIdsList: responseJson.owner_account }, () => {
        if (this.state.formValue.role.includes("owner")) {
          this.setState({ formValue: { ...this.state.formValue, ownerIds: responseJson.owner_account } });
        }
      });
    }
  };

  getResidentIdsList = async (society_id: any) => {
    this.GetResidentIdsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${society_id}/bx_block_meeting/meeting_groups/resident?building_management_id=${society_id}`,
    });
  };

  handleResidentIdsResponse = (responseJson: any) => {
    if (responseJson && responseJson.resident_account) {
      this.setState({ residentIdsList: responseJson.resident_account }, () => {
        if (this.state.formValue.role.includes("resident")) {
          this.setState({ formValue: { ...this.state.formValue, residentIds: responseJson.resident_account } });
        }
      });
    }
  };
  // Customizable Area End
}
