import React from "react";

// Customizable Area Start
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router";
import CommunityUserProfileController, { Props } from "./communityManagementController.web";
import { withTranslation } from "react-i18next";
import { call_org, chat, email_org, userIcon } from "./assets";
import { dashBoard } from "./Style.web";
import Loader from "../../../components/src/Loader.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
// Customizable Area End

class AdminGaMembers extends CommunityUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserProfileSearchWithType("", window.location.pathname.substring(1));
    this.getBuilding();
  }

  getData(item: any) {
    switch (item) {
      case "ga_member":
        return "GA Member";
      case "property_manager":
        return "Property Manager";
      case "resident":
        return "Residents";
      default:
        return "Owners";
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t }: any = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container>
            <Box style={dashBoard.navigation}>
              <Box>
                <Typography variant="body1">
                  {t("Community Management")} /{" "}
                  <Box
                    className="communityManagementUserProfilesGAMembers"
                    component="span"
                    onClick={() => this.props.history.push("/CommunityUserProfile")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("User Profiles")}
                  </Box>{" "}
                  /{" "}
                  <Box component="span" className="GAMembersBlueColor" style={{ color: "blue" }}>
                    {" "}
                    {t(this.getData(window.location.pathname.substring(1)))}
                  </Box>
                </Typography>
                {/*@ts-ignore*/}
                <Typography variant="h5" className="bold-text" style={dashBoard.subHeading}>
                  {t(this.getData(window.location.pathname.substring(1)))}
                </Typography>
              </Box>
            </Box>
            <Box style={dashBoard.boxStyling}>
              <Grid container xs={6} md={6} sm={6} spacing={2}>
                <Grid item>
                  <FormControl style={dashBoard.YearMain} className="yearTab">
                    <NativeSelect
                      className="yearSelection"
                      // value={this.state.Year}
                      name="selectedBUilding"
                      onChange={this.handleChange}
                    >
                      <option value={""}>{t("Select Building")}</option>
                      {this.state.allBuilding.map((item: any) => (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={this.getUserProfile} style={dashBoard.backColorGA}>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {t("Search")}
                  </Button>
                </Grid>
              </Grid>
              <Grid container xs={6} md={6} sm={6} spacing={2} style={{ justifyContent: "end" }}>
                <div className="search-box">
                  <TextField
                    style={dashBoard.searchButton}
                    className="input-with-icon-textfield-for-search-userProfile GAMembersSearch"
                    onChange={(e) =>
                      this.getUserProfileSearchWithType(e.target.value, window.location.pathname.substring(1))
                    }
                    id="input-with-icon-textfield"
                    variant="outlined"
                    placeholder={t("Search by name or unit number")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} className="GAMembersSearchIcon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Box>

            {/* GA MEMBERS -- */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                {/* <Grid item xs={6}>
                            <Typography variant="h6" style={dashBoard.subHeading}>{t("GA Members")}</Typography>
                          </Grid> */}
              </Grid>
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <div>
                <>
                  {this.state.allProfileKeys.map((item: any) => (
                    <>
                      {item == window.location.pathname.substring(1) && (
                        <Grid container style={dashBoard.gaMemberMain}>
                          <Grid item xs={6}>
                            {/*@ts-ignore*/}
                            <Typography variant="h6" style={dashBoard.subHeading}>
                              {t(this.getData(window.location.pathname.substring(1)))}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Box style={{ marginTop: "10px" }}>
                        <div style={dashBoard.gaMemberCard}>
                          {
                            //@ts-ignore
                            item == window.location.pathname.substring(1) &&
                              this.state?.allProfile[item]?.data?.map((singleProfile: any, index: any) => (
                                <>
                                  <div key={index}>
                                    <Card
                                      className="admin-profile-card"
                                      style={dashBoard.cardStyle}
                                      onClick={(e: any) => {
                                        localStorage.setItem("selectedPofile", JSON.stringify(singleProfile));
                                        //@ts-ignore
                                        this.props.history.push({ pathname: "/UserDetailedProfile", singleProfile });
                                      }}
                                    >
                                      <CardActionArea>
                                        <CardMedia
                                          component="img"
                                          height="140"
                                          image={singleProfile?.attributes?.profile_pic?.url || userIcon}
                                          alt="user-icon"
                                          className="GAMEMBERS_PROFILE_IMAGE"
                                          style={dashBoard.profileImage}
                                        />
                                        <CardContent style={{ padding: "0px 16px 16px 16px" }}>
                                          <Typography
                                            variant="h6"
                                            //@ts-ignore
                                            style={dashBoard.unitno}
                                          >
                                            {singleProfile?.attributes?.apartment_number?.apartment_number}
                                          </Typography>
                                          <Typography variant="h6" style={{ textAlign: "center", marginTop: "5px" }}>
                                            {singleProfile?.attributes?.full_name?.name}
                                          </Typography>
                                          <div style={{ textAlign: "center", marginTop: "5px" }}>
                                            {
                                              //@ts-ignore
                                              //@ts-nocheck
                                              <Typography variant="h6" style={dashBoard.userType}>
                                                {this.getData(item).slice(0, -1)}
                                              </Typography>
                                            }
                                          </div>
                                          <div style={dashBoard.contactIcon}>
                                            <div style={dashBoard.relatedMemberCard}>
                                              <img
                                                src={chat}
                                                className="GAMembersChat"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.openChat(singleProfile?.id);
                                                }}
                                                style={{ width: "40px" }}
                                              />
                                              <a
                                                className="GAMemebersEmailTo"
                                                onClick={(e) => e.stopPropagation()}
                                                href={`mailto:${singleProfile?.attributes?.email?.email}`}
                                              >
                                                <img
                                                  src={email_org}
                                                  className="GAMemebersEmailToImg"
                                                  style={{ width: "40px", margin: "0 auto" }}
                                                />
                                              </a>
                                              <a
                                                className="GAMemebersPhoneto"
                                                onClick={(e) => e.stopPropagation()}
                                                href={`tel:${singleProfile?.attributes?.full_phone_number?.full_phone_number}`}
                                              >
                                                <img
                                                  src={call_org}
                                                  className="GAMemebersPhonetoImg"
                                                  style={{ width: "40px", margin: "0 auto" }}
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </div>
                                </>
                              ))
                          }
                        </div>
                      </Box>
                    </>
                  ))}
                </>
              </div>
            </Box>
          </Container>
        </GeneralHeaderComponentWeb>

        <Loader loading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withTranslation()(withRouter(AdminGaMembers));
