// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
    page: any,
    pagination: any;
    historyPaymentList: any;
    buildingPaymentList: any;
    floorPaymentList: any;
    unitPaymentList: any;
    searchPaymentKey: any;
    getPaymentInvoiceListtId: any
    filterPaymentType: any;
    getPaymentListId: any;
    paymentDetails: any;
    filterPaymentStatus: any;
    paymentType: any;
    filterPaymentBuilding: any;
    filterPaymentFloor: any;
    filterPaymentUnit: any;
    paymentHistoryList: any;
    paymentSearchBlock: any;
    paymentList: any;
    partialPaymentAmount: any;
    confirmPaymentModal: boolean;
    showError: boolean;
    downloadPaymentId: any;
    openPaymentModal: boolean;
    showPaymentSuccess: boolean;
    successPaymentMessage: any;
    error: any;
    getPaymentId: any;
    payment_type: any;
}

interface SS {
    id: any;
}

export default class PaymentHistoryController extends CommonApiCallForBlockComponent<Props, S, SS> {
    getPaymentUnitListId: any;
    getPaymentHistoryId: any;
    getPaymentBuildingListId: any;
    getPaymentDetailsId: any;
    getPaymentFloorListId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)
        ];
        this.state = {
            page: 1,
            pagination: {
                current_page: 1,
                total_count: 0,
                total_pages: 1,
            },
            getPaymentInvoiceListtId: null,
            historyPaymentList: [],
            filterPaymentType: "",
            paymentDetails: {},
            buildingPaymentList: [],
            floorPaymentList: [],
            payment_type: "fullpayment",
            partialPaymentAmount: "",
            unitPaymentList: [],
            filterPaymentBuilding: "",
            filterPaymentFloor: "",
            filterPaymentUnit: "",
            filterPaymentStatus: "",
            paymentHistoryList: [],
            paymentSearchBlock: null,
            paymentList: [],
            paymentType: "",
            showPaymentSuccess: false,
            successPaymentMessage: "",
            error: "",
            getPaymentListId: null,
            confirmPaymentModal: false,
            showError: false,
            downloadPaymentId: "",
            openPaymentModal: true,
            searchPaymentKey: "",
            getPaymentId: null,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getPaymentBuildingList()
        this.getPaymentHistory({buildingId: "", floorNo: "", unitId: "", status: "", page: ""})
    }

    handleInvoicesPagination = (e: any, value: any) => {
        this.getPaymentHistory({
            buildingId: this.state.filterPaymentBuilding,
            floorNo: this.state.filterPaymentFloor,
            unitId: this.state.filterPaymentUnit,
            status: this.state.filterPaymentStatus,
            page: value,
        })
        this.setState({
            page: value
        })
    }

    handlePaymentFilterBy = () => {
        this.getPaymentHistory({
            buildingId: this.state.filterPaymentBuilding,
            floorNo: this.state.filterPaymentFloor,
            unitId: this.state.filterPaymentUnit,
            paymentType: this.state.filterPaymentType,
            status: this.state.filterPaymentStatus,
            searchPaymentKey: this.state.searchPaymentKey,
            page: this.state.page,
        })
    }

    getPaymentInvoicesListResponse = (responseJson: any) => {
        if (responseJson.hasOwnProperty("invoices")) {
            this.setState({
                paymentHistoryList: responseJson.invoices.data,
                pagination: responseJson.meta?.pagination,
            })
        }else{
            this.setState({
                paymentHistoryList: [],
                pagination: {
                    current_page: 1,
                    total_count: 0,
                    total_pages: 1,
                },
            })
        }
    }

    getBuildingListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("buildings")) {
            this.setState({
                buildingPaymentList: responseJson?.buildings
            })
        }
    }

    getUnitListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("units")) {
            this.setState({
                unitPaymentList: responseJson.units
            })
        } else {
            this.setState({
                unitPaymentList: []
            })
        }
    }

    paymentHistoryDownload = async () => {
        await this.downloadPdf(`/bx_block_fees_payment/invoices/download_payment_report?unit_number=${this.state.filterPaymentUnit || ""}&floor_number=${this.state.filterPaymentFloor || ""}&select_status=${this.state.filterPaymentStatus || ""}&building_management_id=${this.state.filterPaymentBuilding || ""}&society_management_id=${localStorage.getItem("society_id")}`, `PaymentHistory.pdf`)
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.getPaymentHistoryId) {
                this.getPaymentInvoicesListResponse(responseJson)
            }
            if (apiRequestCallId === this.getPaymentBuildingListId) {
                this.getBuildingListResponse(responseJson)
            }
            if (apiRequestCallId === this.getPaymentUnitListId) {
                this.getUnitListResponse(responseJson)
            }
            if (apiRequestCallId === this.getPaymentFloorListId) {
                this.getPaymentHistoryFloorListResponse(responseJson)
            }
        }
    }

    getPaymentHistoryFloorListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("floors")) {
            this.setState({
                floorPaymentList: responseJson.floors
            })
        } else {
            this.setState({
                floorPaymentList: []
            })
        }
    }

    selectPaymentBuilding = (e: any) => {
        this.setState({filterPaymentBuilding: e.target.value,filterPaymentFloor:"",filterPaymentUnit:"",filterPaymentStatus:""})
        this.getPaymentUnitList(e.target.value, "")
        this.getPaymentHistoryFloorList(e.target.value)
    }

    selectFloorPayment = (e: any) => {
        this.setState({filterPaymentFloor: e.target.value,filterPaymentUnit:"",filterPaymentStatus:""})
        this.getPaymentUnitList(this.state.filterPaymentBuilding, e.target.value)
    }


    getPaymentBuildingList = async () => {
        const societyIDReceipt = localStorage.getItem("society_id")
        this.getPaymentBuildingListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/buiding_list?society_management_id=${societyIDReceipt}`,
        });
        return true
    };

    getPaymentHistory = async (data: any) => {
        const {buildingId, floorNo, unitId, status, page} = data
        console.log("API CALL", data)
        this.getPaymentHistoryId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_fees_payment/invoices/payment_history?unit_number=${unitId || ""}&floor_number=${floorNo || ""}&select_status=${status || ""}&building_management_id=${buildingId || ""}&page=${page || 1}&society_management_id=${localStorage.getItem("society_id")}`,
        });
        return true
    };

    getPaymentUnitList = async (buildingId: any, floorId: any) => {
        this.getPaymentUnitListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/unit_list?building_management_id=${buildingId}&floor_number=${floorId}`,
        });
        return true
    };

    getPaymentHistoryFloorList = async (buildingId: any) => {
        this.getPaymentFloorListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/floor_number?building_id=${buildingId}`,
        });
        return true
    };

}
// Customizable Area End