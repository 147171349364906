// Customizable Area Start
import React from "react";
import { Box, Button, Card, Container, Divider, Link, Typography, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CEScheduleMeetingController, { Props } from "./CEScheduleMeetingController.web";
import Loader from "../../../../components/src/Loader.web";
import { MeetingsStyleWeb } from "../MeetingsStyle.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CheckIcon, DetailsIcon } from "../assets";
import RichTextEditor from "react-rte";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import moment from "moment";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";

const toolbarConfig: any = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

// Customizable Area End

class CEAddScheduleMeetingMinute extends CEScheduleMeetingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const meeting_id = this.props.navigation.getParam("id");
    this.setState({ scheduleMeetingId: meeting_id }, () => {
      this.getMeetingDetailsById();
    });
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />

        <Container className={classes.scheduledMeeting}>
          <Box className="navigation">
            {this.state.isNotePreviewOpen ? (
              <Box>
                <Typography variant="body1">
                  {t("Activities")} / <Link href="/CompanyEmployee/CEScheduleMeeting">{t("Scheduled Meetings")}</Link> /{" "}
                  <Link href={`/CompanyEmployee/CEScheduleMeetingDetails/${this.state.scheduleMeetingId}`}>{t("Meeting Details")}</Link> /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {t("Preview Meeting Minutes")}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text">
                  {t("Meeting Minutes Preview")}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="body1">
                  {t("Activities")} / <Link href="/CompanyEmployee/CEScheduleMeeting">{t("Scheduled Meetings")}</Link> /{" "}
                  <Link href={`/CompanyEmployee/CEScheduleMeetingDetails/${this.state.scheduleMeetingId}`}>{t("Meeting Details")}</Link> /{" "}
                  <Box component="span" style={{ color: "blue" }}>
                    {this.state.meetingNote.note ? `${t("Edit Meeting Minutes")}` : `${t("Add Meeting Minutes")}`}
                  </Box>
                </Typography>
                <Typography variant="h5" className="sub-heading bold-text">
                  {this.state.meetingNote.note ? `${t("Edit Meeting Minutes")}` : `${t("Add Meeting Minutes")}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="meeting-minute-note">
            {this.state.isNotePreviewOpen ? (
              <Card className="meeting-preview-card">
                <h4 style={{ marginBottom: "20px" }} className="bold-text">
                  {t("Meeting Minutes")} {moment(this.state.meetingDetails.scheduleOn, "DD-MM-YYYY HH:mm").format("MMMM DD, YYYY HH:mm")}
                </h4>
                <Divider />
                <div
                  style={{ marginTop: "20px" }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.meetingNote.value?._cache?.html,
                  }}
                />
              </Card>
            ) : (
              <Card>
                <Box className="note-details-box">
                  <p>{t("Details")} </p>
                  <img src={DetailsIcon} alt="" />
                </Box>
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  className={languageCondition(language, "editor arabic-editor", "editor")}
                  value={this.state.meetingNote.value}
                  onChange={this.handleNoteChange}
                />
              </Card>
            )}
            {this.state.isNotePreviewOpen ? (
              <Box className="note-button">
                <Button className="submit" onClick={this.handleNotePreview}>
                  {t("Edit")}
                </Button>
                <Button className="preview" disabled={!this.state.meetingNote.value?._cache?.html} onClick={this.handleSubmitMeetingNoteDialog}>
                  {t("Submit")}
                </Button>
              </Box>
            ) : (
              <Box className="note-button">
                <Button className="submit" disabled={!this.state.meetingNote.value?._cache?.html} onClick={this.handleSubmitMeetingNoteDialog}>
                  {t("Submit")}
                </Button>
                <Button className="preview" onClick={this.handleNotePreview}>
                  {t("Preview")}
                </Button>
              </Box>
            )}
          </Box>
        </Container>

        <AlertDialog
          IsOpen={this.state.isSubmitMeetingNoteOpen}
          Image={CheckIcon}
          CloseDialog={this.handleCancelMeetingDialog}
          Header={t("Submit Meeting Minutes")}
          Content={`<p>${t("Are you sure you want to submit meeting minutes?")}</p>`}
          DeclineText={t("Close")}
          AcceptText={t("Confirm")}
          DeclineFun={this.handleSubmitMeetingNoteDialog}
          AcceptFun={this.updateMeetingNote}
        />
      </CEHeaderComponentWeb>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(CEAddScheduleMeetingMinute)));
// Customizable Area End
