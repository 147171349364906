import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  invoiceComplexList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ComplexListController extends BlockComponent<Props, S, SS> {
  GetInvoiceComplexListCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIRequestMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      // Customizable Area Start
      invoiceComplexList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.GetInvoiceComplexListCallId !== null &&
      this.GetInvoiceComplexListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.GetInvoiceComplexListCallId = null;

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson && responseJson.complex) {
        this.setState({ invoiceComplexList: responseJson.complex });
      }

      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getInvoiceComplexList();
  }

  getInvoiceComplexList = async () => {
    const user_role = localStorage.getItem("userType");

    this.GetInvoiceComplexListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/user_complex_list?user_type=${user_role}`,
    });
  };

  // Handle State
  handleChangePage = (complex: any) => {
    const page = sessionStorage.getItem("invoicePage");
    sessionStorage.setItem("complexName", complex.name);
    switch (page) {
      case "BuildingBudget":
        this.props.navigation.navigate("BuildingBudget", { id: complex.id });
        break;
      case "MyManagementFee":
        this.props.navigation.navigate("MyManagementFee", { id: complex.id });
        break;
      case "BudgetSpending":
        this.props.navigation.navigate("BudgetSpending", { id: complex.id });
        break;
    }
  };
  // Customizable Area End
}
