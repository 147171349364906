// Customizable Area Start
import React from "react";
import { Box, Button, Link, Grid } from "@material-ui/core";
import { Building1, CarFront } from "./assets";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import VeichleListController, { Props } from "./VeichleListController.web";
import { withTranslation } from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class NewRequest extends VeichleListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper reg-wrapper newRequest" style={{ background: "white" }} dir={languageCondition(language,"rtl","ltr")}>
          <Box display={{ xs: "flex", md: "none" }} className={languageCondition(language,"backIcon_ar","backIcon")} onClick={() => this.props.history.push("/veichleList")}>
            <ArrowBackIcon />
          </Box>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block newRequest" style={{ justifyContent: "center", height: "calc(100% - 70px)" }}>
                <Box className="logo-block common-top-padding newRequest" display={{ xs: "none", md: "flex" }}>
                  <Link href="/EmailAccountLogin" />
                </Box>
                <Box className="main-content-block newRequest" style={{ justifyContent: "center" }}>
                  <Box className="reg-content-block newRequest">
                    <Box className="header-block chairmanHeaderBlock newRequest" style={{ marginTop: "0", padding: "0" }}>
                      <img src={CarFront} className="bank-logo newRequest" style={{ fontWeight: "bold" }} alt="Tenant Logo" />
                      <h1 className="bold-text newRequest" style={{ fontWeight: "bold" }}>
                        {t("Vehicle Request")} <br /> {t("Added")}
                      </h1>
                      <h6>
                        {t(
                          "Your new vehicle request added successfully. Request has been sent to the building/complex manager for approval. You will be notified when your request is approved"
                        )}
                      </h6>
                    </Box>
                  </Box>
                </Box>
                <Box className="footer-block newRequest">
                  <Box className="row-btn customButton desktop-ui new-vehicle-request-btn newRequest">
                    <Button variant="contained" className="btn newRequest" onClick={() => this.props.history.push("/VeichleList")}>
                      {t("Okay")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols newRequest">
              <Box className="right-block newRequest" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo newRequest" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(withRouter(NewRequest));

// Customizable Area End
