//Customizable area start
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation: any;
  id: string;
  t: any;
  i18n: any;
}
interface S {

 
  showPassword1: any;
  showPassword2: any,
  open1: any;
  field: any;
  initialValues: any;
  language1:string;
 
 
  token:any;
}interface SS {
  id: any;
}
export default class CompEmpConfirmPassController extends CommonApiCallForBlockComponent<Props, S, SS> {
  ConfirmPassCallID: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
  
  

      showPassword1: false,
      showPassword2: false,
      open1: false,
      field: "",
      initialValues: {
        password1: "",
        password2: ""
      },
      
      
      token:'',
      language1:"ENG"
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  
  async componentDidMount() {
    await super.componentDidMount()
    this.getotp()
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }
  
  getotp =()=>{
this.setState({token:this.props.navigation.history?.location?.state?.token})
 
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    console.log("apiRequestCallId", apiRequestCallId);
  
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId && responseJson) {
      await this.handleApiResponse(apiRequestCallId, responseJson);
    }
  }
  
  async handleApiResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.ConfirmPassCallID) {
      if (!responseJson.errors) {
        this.props.navigation.navigate("CompEmpChangePassSucess");
      } else {
        this.errorModal(responseJson.errors[0].failed_login);
        
      }
    }
  }
  
  
  
  confirmPass = async (values: any) => {
   
      this.ConfirmPassCallID = await this.apiCall({
        contentType: "application/json",
        method: "POST",
        endPoint: 'bx_block_forgot_password/employee_passwords',
        body: JSON.stringify({
          data: {
            token:this.state.token,
            new_password: values.password1,
            confirm_password: values.password2,
          }
        })
      });

    
  }
  handleClickShowPassword1 = () => {
    this.setState({
      showPassword1: !this.state.showPassword1
    })
  }
  handleClickShowPassword2 = () => {
    this.setState({
      showPassword2: !this.state.showPassword2
    })
  }
  handleOpen = (e:any) => {
    const fieldvalue= document.querySelector('#value')
    this.setState({
      open1: true,
    }); 
    this.setState({
            field: fieldvalue
          }) 
  }
}
// Customizable Area End

