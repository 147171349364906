export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const SearchIconImage = require("../assets/search.png");
export const CommentIcon = require("../assets/comment.png");
export const AcceptIcon = require("../assets/accept.png");
export const RejectIcon = require("../assets/reject.png");
export const AwaitIcon = require("../assets/await.png");
export const CheckIcon = require("../assets/check.png");
export const Dots = require("../assets/3dots.png");
export const BuildingLogo = require("../assets/building.png");
export const SortIcon = require("../assets/sort.png");
export const FilterIcon = require("../assets/filter.png");
export const TrueIcon = require("../assets/true.png");
export const FalseIcon = require("../assets/false.png");
export const UserIcon = require("../assets/user.png");
export const CalenderBlueIcon = require("../assets/calender-blue.png");
export const DownloadIcon = require("../assets/download.png");
export const PdfIcon = require("../assets/pdf.png");
export const GreyCheckIcon = require("../assets/grey-check.png");
export const BlueCheckIcon = require("../assets/blue-check.png");
export const DateIcon = require("../assets/calender.png");
export const TimeIcon = require("../assets/time.png");
export const BackIcon = require("../assets/back-icon.png");
export const DetailsIcon = require("../assets/details.png");
