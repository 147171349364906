// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import BudgetReportController, { Props } from "./BudgetReportController.web";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { ROLE } from "../../../framework/src/Enum";
import { ReportsStyleWeb } from "./ReportsStyle.web";
import { SearchIconImage } from "./assets";
import moment from "moment";
import PaginationModule from "./PaginationModule.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
// @ts-ignore
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";

const { baseURL } = require("../../../framework/src/config");

class BudgetReport extends BudgetReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const userTypes = localStorage.getItem("userType");
    const societyID = localStorage.getItem("society_id");
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container className={classes.reportList}>
                <Box className="navigation">
                  <Box>
                    {userTypes === ROLE.AUDITOR ? (
                      <Typography variant="body1" className="budgetReportAuditor">
                        {t("Reports")} /{" "}
                        <Box className="reports-BudgetReportmain budgetReportAuditor" component="span" style={{ color: "blue" }}>
                          {t("Budget Reports")}
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {t("Documents & Reports")} /
                        <Box
                          className="reports-BudgetReportmain budgetReportBackButton"
                          component="span"
                          onClick={() => this.props.history.push("/Reports")}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {t("Reports")}
                        </Box>{" "}
                        /{" "}
                        <Box className="reports-BudgetReportmain" component="span" style={{ color: "blue" }}>
                          {t("Budget Reports")}
                        </Box>
                      </Typography>
                    )}
                    <Typography
                      variant="h5"
                      className="sub-heading bold-text reports-BudgetReportmain"
                      style={{ fontSize: "28px" }}
                    >
                      {t("Budget Reports")}
                    </Typography>
                  </Box>
                </Box>
                <Box className={languageCondition(language, "arabic-grid top-bar", "top-bar budgetReport")}>
                  <Box className="filter">
                    <Select displayEmpty
                            className="select-input budgetReportYearListSelect"
                            value={this.state.budgetYear}
                            onChange={this.handleYearChange}>
                      <MenuItem value="" disabled id="selectYearBudgetReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Year")}</MenuItem>
                      <MenuItem value=" " id="selectYearBudgetReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 3} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 3}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 2} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 2}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 1} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 1}</MenuItem>
                      <MenuItem value={new Date().getFullYear()} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear()}</MenuItem>
                      <MenuItem value={new Date().getFullYear() + 1} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() + 1}</MenuItem>
                    </Select>
                    <Select displayEmpty className="select-input budgetReportStatusListSelect"
                            value={this.state.status}
                            onChange={this.handleStatusChange}>
                      <MenuItem value="" disabled id="selectStatusBudgetReport" className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Status")}</MenuItem>
                      <MenuItem value=" " id="selectStatusBudgetReportAll" className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      <MenuItem value="Pending" className={languageCondition(language, "select-arabic-menu", "")}>{t("Pending")}</MenuItem>
                      <MenuItem value="Approved" className={languageCondition(language, "select-arabic-menu", "")}>{t("Approved")}</MenuItem>
                      <MenuItem value="Rejected" className={languageCondition(language, "select-arabic-menu", "")}>{t("Rejected")}</MenuItem>
                    </Select>
                    <Button
                      startIcon={<img src={SearchIconImage} />}
                      className="budgetReportSearchButton" onClick={() => this.getBudgetReport(this.state.status, this.state.budgetYear, this.state.searchName, 1)}
                    >
                      {t("Search")}
                    </Button>
                  </Box>
                  {localStorage.getItem("userType") === ROLE.MANAGER && (
                    <Box className="create-meeting" onClick={() => this.props.history.push("/GenerateBudgetReport")}>
                      <Button onClick={() => {}}>{t("Generate Report")}</Button>
                    </Box>
                  )}
                </Box>
                <Grid className="meeting-table" style={{ boxShadow: "4px 0px 14px #e9e9e9" }}>
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className="bold-text reports-BudgetReportmain">{t("Budget Reports")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search By Year")}
                          className="search searchInputBudget"
                          value={this.state.searchName} onChange={this.manageSearch}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text reports-BudgetReportmain1"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("#")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text reports-BudgetReportmainYear"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Year")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text reports-BudgetReportmainGenerated"
                            style={{ color: "#181d25", fontSize: "14px",width:"150px" }}
                          >
                            {t("Report Generated On")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text reports-BudgetReportmainAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Amount")}
                          </TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text reports-BudgetStatus" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("Status")}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.budgetReportList?.length > 0 ? (
                          this.state.budgetReportList.map((item: any, key: any) => {
                            return (
                              <TableRow key={key}>
                                <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item?.attributes?.year}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>
                                  {moment(item?.attributes?.report_generated_on, "DD-MM-YY").format("DD MMM YYYY")}
                                </TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>
                                  {languageCondition(language,item.attributes.currency.currency_ar,item.attributes.currency.currency)} {item?.attributes?.amount.toLocaleString()}
                                </TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>
                                  {item.attributes.status === "Pending" && <span className="pending">{t("Pending Approval")}</span>}
                                  {item.attributes.status === "Approved" && (
                                    <span className="approved">{t(item.attributes.status)}</span>
                                  )}
                                  {item.attributes.status === "Rejected" && (
                                    <span className="cancelled">{t(item.attributes.status)}</span>
                                  )}
                                </TableCell >
                                <TableCell align={languageCondition(language,"right","left")} className="personal-document-menu invoice-receipts-menu budgetReportMenu">
                                  <Menu
                                    menuButton={
                                      <IconButton>
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  >
                                    <MenuItem onClick={() => this.props.history.push(`/BudgetReports/${item.id}`)}>
                                      {t("View")}
                                    </MenuItem>
                                    <MenuItem onClick={() => this.manageDownload(item.id)}>{t("Download")}</MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        this.setState({
                                          isShareModalOpen: true,
                                          shareUrl: `${baseURL}/society_managements/${societyID}/bx_block_report/budget_reports/${item.id}/download_report.pdf`,
                                        })
                                      }
                                    >
                                      {t("Share")}
                                    </MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6}>{t("No Budget Reports Available")}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <PaginationModule
                        handlePagination={this.handleBudgetReportPagination}
                        pagination={this.state.pagination}
                        page={this.state.page}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
              <ShareDocumentModal
                isOpen={this.state.isShareModalOpen}
                handleClose={this.handleShareModal}
                heading={t("Share")}
                documentURL={this.state.shareUrl}
              />
        </GeneralHeader>
      </>
    );
  }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(BudgetReport)));
// Customizable Area End
