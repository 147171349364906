// Customizable Area Start
import React from "react";
import { Container, Typography, withStyles, Card, Link, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DashboardBudgetController, { Props } from "./DashboardBudgetController.web";
import { DashboardStyleWeb } from "./DashboardStyle.web";
import { withTranslation } from "react-i18next";
import { ROLE } from "../../../framework/src/Enum";
import ReactToPrint from "react-to-print";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";

class BudgetDetails extends DashboardBudgetController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getBudgetDashboardYearList();
    this.getAllBuildingList();
    this.getBudgetDetails();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.filterBuilding !== this.state.filterBuilding || prevState.filterYear !== this.state.filterYear) {
      this.getBudgetDetails();
    }
  }

  render() {
    const { t, classes }: any = this.props;
    const userType = localStorage.getItem("userType");

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.generalDashboard}>
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      {t("My Dashboards")} / <Link href="/DashboardBudget">{t("Budget Dashboard")}</Link> /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Budget")} {this.state.filterYear}
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="sub-heading-box">
                    <Typography variant="h5" className="bold-text">
                      {t("Budget")} {this.state.filterYear}
                    </Typography>
                    <Box className="select-box">
                      <select
                        value={this.state.filterYear}
                        onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                        className="select-year"
                      >
                        {this.state.yearList.map((year: any) => {
                          return (
                            <option value={year} key={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </Box>
                  </Box>
                </Box>
                <Grid container spacing={4} style={{ marginBottom: 30 }}>
                  <Grid item sm={12} ref={(el: any) => (this.componentRef = el)}>
                    <Card
                      className="budget-table-content-box"
                      id="budget-details"
                      style={{ background: "#fff", borderRadius: 8, boxShadow: "4px 0px 14px #e9e9e9" }}
                    >
                      <Box className="header" style={{ padding: "20px 15px", fontSize: "24px" }}>
                        <h4 className="bold-text">
                          {t("Budget")} {this.state.filterYear}
                        </h4>
                      </Box>
                      <hr />
                      <Box className="body" style={{ padding: "10px 15px" }}>
                        <Box
                          className="table-header"
                          style={{ padding: "10px 0", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <span style={{ color: "#999" }}>{t("Name")}</span>
                          <span style={{ color: "#999" }}>{t("Amount")}</span>
                        </Box>
                        {this.state.facility.map((facility: any) => (
                          <Box
                            className="table-content"
                            key={facility.id}
                            style={{
                              padding: "10px 0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderTop: "1px solid #F4F6FB",
                            }}
                          >
                            <p>{facility.budget_category}</p>
                            <span className="bold-text">
                              {this.state.currency} {Number(facility.allocate_budget).toLocaleString()}
                            </span>
                          </Box>
                        ))}
                      </Box>
                      <hr style={{ margin: "0", color: "#F4F6FB" }} />
                      <Box
                        className="footer"
                        style={{ padding: "20px 15px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <p className="bold-text" style={{ fontSize: "18px" }}>
                          {t("Approved Amount")}
                        </p>
                        <h4 className="bold-text" style={{ fontSize: "18px", color: "#FC8434" }}>
                          {this.state.currency} {Number(this.state.approveAmount).toLocaleString()}
                        </h4>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                {userType === ROLE.MANAGER && (
                  <Box className="print-report-box">
                    <ReactToPrint
                      trigger={() => {
                        return <Button>{t("Print Report")}</Button>;
                      }}
                      content={() => this.componentRef}
                    />
                  </Box>
                )}
              </Container>
        </GeneralHeaderComponentWeb>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(DashboardStyleWeb)(BudgetDetails));
// Customizable Area End
