import * as React from "react";
// custom components
import {
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Dialog,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField, IconButton, Input, InputAdornment
} from "@material-ui/core";
import { Building1, calendar, emailedit, fbedit, heart, instaedit,  Lock, mobile, NoProfile_Img, snapedit, twitteredit, user,} from "./assets";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import '../assets/css/style.scss';
import { Formik, Form, Field, ErrorMessage } from "formik";
import ProfileController from "./ProfileController.web";
import { dailCode } from "../../email-account-registration/src/code";
import ChipInput from 'material-ui-chip-input'
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Hyperlink } from "../../customform/src/assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { withTranslation } from "react-i18next";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import {ErrorHandler} from "../../../components/src/UtilFunction.web";
import {withStyles} from "@material-ui/core/styles";
import {MobileIcon} from "../../ReviewAndApproval/src/assets"
import { PropertyManagerStyleWeb } from "../../ReviewAndApproval/src/PropertyManagerStyle.web";

const errorStyle = {
  color: "#F14E24",
  fontWeight: 300,
  fontSize: 14,
  marginTop: 5,
  marginLeft: 10
}

class EditProfile extends ProfileController {
  render() {
    // @ts-ignore
    // @ts-nocheck
    let profileData:any = JSON.parse(localStorage.getItem('profileData'));
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/ig) || false;
    const { t,i18n,classes }: any = this.props;
    const {language} = i18n;
    return (
      <>
        <Grid container spacing={4} className="auth-container editProfile" dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols editProfile" style={{ justifyContent: 'unset' }}>
            <Grid container className="editProfile" style={{borderBottom:'2px solid #f2efef', padding:"0px 0"}}>
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center',gap:'0.25rem',marginBottom:5 }} className="editProfile">
                <IconButton onClick={() => window.history.back()} className="editProfileBackButton">
                  {languageCondition(language,<ArrowForward style={{fontSize: "27px"}} className="editProfileAR"/>,<ArrowBackIcon style={{fontSize: "27px"}} className="editProfile"/>)}
                </IconButton>
                <p style={{ fontWeight: 600, fontSize: '18px',paddingLeft:8 }} className="bold-text editProfile">
                  {t("Edit My Profile")}
                </p>
              </Grid>
            </Grid>
            <div>
              <Grid container className="main-content-block editProfile">
                <Grid xs={12} className='inputPlaceholderRegistration editProfile'>
                  {/*@ts-ignore*/}
                  <Formik initialValues={this.initialValuesFormik(profileData)} id="formik"
                    validationSchema={this.profileSchema()} validateOnMount={true}
                    onSubmit={(values) => { this.updateProfile(values) }}
                  >
                    {({ values, errors, touched, isValid, handleChange, setFieldValue, setFieldError }) => (
                      <Form className="commonForm editProfile" translate="yes" >
                        <Box className='formGroup editProfile' style={{ height: '91%' }}>
                          <Box style={{
                            display: 'flex', alignItems: 'center',
                            gap: '1rem', marginTop: '1rem', marginBottom: '1.5rem'
                          }}>
                            <Avatar src={values.bannerUrl || NoProfile_Img} className="info-icon editProfile" style={{width:50,height:50}} />
                            <label htmlFor="file1" style={{ color: '#FC8434',fontSize:'1rem'}} className='bold-text editProfile'>
                              {t("Change Profile Picture")}
                            </label>
                            <input
                              id="file1"
                              type="file"
                              onChange={(e: any) => {this.handleSelectBanner(e, setFieldValue, setFieldError);}}
                              style={{position: "absolute", zIndex: 2, cursor: "pointer", opacity: 0}}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                          </Box>
                            <Typography style={errorStyle} className="textErrorBanner">
                              <ErrorMessage className="text-error" component="Typography" name="banner" />
                            </Typography>
                            <Typography style={errorStyle} className="textErrorbannerUrl">
                              {t(errors.bannerUrl)}
                            </Typography>
                          <Box className="formInputGrp EditProfile" style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              className={languageCondition(language, "formInput_ar", "formInput profileName")} name="full_name"
                              value={values.full_name} placeholder={t("Enter your name")}
                              disabled={true} style={{color:"black"}}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons profileName")}>
                              <img src={user} />
                            </span>
                          </Box>
                        
                            <Typography style={errorStyle} className="fullNameError">
                              <ErrorMessage className="text-error" component="Typography" name="full_name" />
                            </Typography>
                          {/* mobile */}
                          <Box
                            marginTop='1rem' className='formInputGrp'
                            display="flex" overflow="hidden"
                            alignItems="center" height="56px"
                            style={{border:"1px solid #e9dede",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Box>
                              <FormControl variant="outlined" >
                                <Select
                                  name='selectCode' labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined" onChange={this.handleChange} label="Unit"
                                  disabled value={this.state.selectCode}
                                >
                                  <MenuItem value="">
                                    <em>{t("None")}</em>
                                  </MenuItem>
                                  {dailCode.map((item) =>
                                    <MenuItem key={item.dial_code} value={item.dial_code}> <img src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.code}.svg`} width='15' height='15' style={{ marginRight: '5px' }} />
                                      {item.dial_code}</MenuItem>

                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Field
                              name="phone" id="mobile"
                              disabled value={values.phone}
                              placeholder={t("Mobile")}
                              style={{
                                border: "none", height: "42%",
                                width: "80%", color: "rgba(0, 0, 0, 0.6)",
                                fontWeight: 400, fontSize: 16,
                                marginRight: 10, marginLeft: 21,
                                outline: "none", backgroundColor: '#f9f9f9'
                              }}
                            />
                          </Box>
                          <Typography style={errorStyle} className="phoneError">
                            <ErrorMessage className="text-error" component="Typography" name="phone" />
                          </Typography>
                          <p className="bold-text updateMobileNumberButton" style={{ color:'#FC8434',textAlign:'right',fontWeight:'bold',cursor:'pointer'}} onClick={()=>this.setState({showDialog:true})}>
                            {t("Update Mobile Number")}
                          </p>
                          {/* email */}
                          <Box className="formInputGrp emailGroup" style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}>
                          <Field
                              className={languageCondition(language, "formInput_ar newFamilyAdd", "formInput fullName email")}
                              value={values.email} name="email"
                              placeholder={t("Email ID")} disabled={true}
                              style={{color:"black"}}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons email")}>
                              <img src={emailedit} style={{marginTop:"5px"}}/>
                            </span>
                          </Box>
                            <Typography style={errorStyle} className="emailErorr">
                              <ErrorMessage className="text-error" component="Typography" name="email" />
                            </Typography>
                         
                          {/* Bio */}
                          <Box
                            className="formInputGrp"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              name="bio" value={values.bio}
                              placeholder={t("Enter your bio")}
                              className={languageCondition(language, "formInput_ar Link", "formInput fullName Link")}
                            />
                            <span className={languageCondition(language, "frmRightIcons Link", "frmLeftIcons Link")}>
                              <img src={Hyperlink} style={{marginTop:"5px"}}/>
                            </span>
                          </Box>
                            <Typography style={errorStyle}>
                              <ErrorMessage className="text-error" component="Typography" name="bio" />
                            </Typography>
                          {/* gender */}
                            <Gender values={values} handleChange={handleChange} t={t}/>
                          {/* DOB */}
                          <Box
                            className="formInputGrp EditProfileOwner"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9",marginBottom:"20px"}}
                          >
                            <TextField
                              className={!values.DOB && is_mobile ? "date formInput placeHolderForDate" : "formInput"}
                              name="DOB"
                              value={values.DOB}
                              onChange={handleChange}
                              variant="outlined"
                              id="profileDateOfBox"
                              placeholder={t("Date of Birth")}
                              type="date"
                              style={{padding:"0px",border:"0px",minWidth:"280px",textAlign:"left"}}
                            />
                            <span className="frmLeftIcons" style={{top:"27%"}}>
                              <img src={calendar} />
                            </span>
                            {/*<Input type="date" className="my-date"/>*/}
                          </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="DOB" />
                            </Typography>
                          {/* Hobbies */}
                          <Box
                            className="formInputGrp"
                          >
                            <ChipInput
                              className={languageCondition(language, "formInput_ar profileHobby_ar", "formInput fullName Hobby")}
                              placeholder={t("Hobbies")} style={languageCondition(language,{ padding:'10px 40px 6px 0px',width:'85%'},{ padding:'10px 0px 6px 50px',width:'85%'})}
                              disableUnderline={true} value={values.hobbies}
                              // onChange={(chip) => setFieldValue('hobbies', chip)}
                              onAdd={(chip) => this.handleAddChip(setFieldValue, chip, values.hobbies)}
                              onDelete={(chip, index) => this.handleDeleteChip(setFieldValue, chip, values.hobbies, index)}
                              onBlur={(chip:any) => this.handleAddChip(setFieldValue, chip.target.value, values.hobbies)}
                            />
                            <span className={languageCondition(language, "frmRightIcons Link", "frmLeftIcons Link")} style={{top:'35%'}}><img src={heart} /></span>
                          </Box>
                            <Typography style={errorStyle}>
                              <ErrorMessage className="text-error" component="Typography" name="hobbies" />
                            </Typography>
                          {/* Twitter */}
                          <Box
                            className="formInputGrp"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              className={languageCondition(language, "formInput_ar twitter", "formInput twitter")}
                              name="twitter"
                              type='url'
                              value={values.twitter}
                              placeholder={t("Twitter profile link")}

                            />
                            <span className={languageCondition(language, "frmRightIcons Link", "frmLeftIcons Link")}>
                              <img src={twitteredit} style={{marginTop:"5px"}} />
                            </span>
                          </Box>
                            <Typography style={errorStyle} className="Twitter">
                              <ErrorMessage className="text-error" component="Typography" name="twitter" />
                            </Typography>
                          {/* fb */}
                          <Box
                            className="formInputGrp"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              name="fb"
                              className={languageCondition(language, "formInput_ar FB", "formInput FB")}
                              type='url'
                              value={values.fb}
                              placeholder={t("Facebook profile link")}
                            />
                            <span className={languageCondition(language, "frmRightIcons FB", "frmLeftIcons FB")}>
                              <img src={fbedit} style={{marginTop:"5px"}} />
                            </span>
                          </Box>
                            <Typography style={errorStyle}>
                              <ErrorMessage className="text-error" component="Typography" name="fb" />
                            </Typography>
                          {/* Insta */}
                          <Box
                            className="formInputGrp"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              name="insta"
                              type='url'
                              value={values.insta}
                              placeholder={t("Instagram profile link")}
                              className={languageCondition(language, "formInput_ar insta", "formInput insta")}
                            />
                            <span className={languageCondition(language, "frmRightIcons insta", "frmLeftIcons insta")}>
                              <img src={instaedit} style={{marginTop:"5px"}} />
                            </span>
                          </Box>
                            <Typography style={errorStyle}>
                              <ErrorMessage className="text-error" component="Typography" name="insta" />
                            </Typography>
                        
                          {/* snap */}
                          <Box
                            className="formInputGrp"
                            style={{border:"1px solid #f0f0f0",borderRadius:"50px",backgroundColor:"#f9f9f9"}}
                          >
                            <Field
                              name="snap"
                              type='url'
                              value={values.snap}
                              className={languageCondition(language, "formInput_ar snap", "formInput snap")}
                              placeholder={t("Snapchat profile link")}

                            />
                            <span className={languageCondition(language, "frmRightIcons snap", "frmLeftIcons snap")}>
                              <img src={snapedit} />
                            </span>
                          </Box>
                         
                            <Typography
                              style={errorStyle}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="snap" />
                            </Typography>
                        
                           </Box>
                        <Box className="customButton">
                          <Button variant="contained" type="submit">
                            {t("Save")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>

            </div>
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols editProfile">
            <Box className="right-block editProfile" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo editProfile" alt="" />
            </Box>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          className="diloag-wrapper editProfileDailog"
          PaperProps={{
            style: {
              borderRadius: '15px',
              padding: '10px',
              margin: 0,
              overflow:'visible',
            },
          }}
          dir={languageCondition(language,"rtl","ltr")}
        >
          <Box style={{ fontSize: "1.25rem", fontWeight: 'bold', textAlign: 'right' }} className="editMobileNumberCloseButton" onClick={() => this.setState({ showDialog: false })}>
            x
          </Box>
          <Grid container className="editMobileNumber">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="editMobileNumber">
              <img src={mobile} className="editMobileNumber"/>
            </Grid>
          </Grid>
          <Grid container className="editMobileNumberGrid">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="editMobileNumberGrid">
              <p className="bold-text editMobileNumberGrid" style={{ fontWeight: 600, fontSize: '20px', textAlign: 'center' }}>
                {t("Update Mobile Number")}
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="editMobileNumberUpdate">
              <p style={{ fontWeight: 400, fontSize: '0.8rem', textAlign: 'center' }} className="editMobileNumberUpdate">
                {t("Update mobile number in the below field.")}
              </p>
            </Grid>
          </Grid>
          <Grid container className="editMobileNumberUpdate">
            <Grid item className='inputPlaceholderRegistration editMobileNumberUpdate'>
              <Formik initialValues={{
                phone: '',
              }}
                validationSchema={this.addPhoneSchema()}
                validateOnMount={true}
                // @ts-ignore
                id="formikPhone"
                onSubmit={(values) => { this.updatePhone(values) }}
              >
                {({ values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur}) => (
                  <Form className="commonForm editMobileNumberUpdate" translate="yes" >
                    <Grid item xs={12} style={{marginTop:"-10px"}} className={classes.registerManager}>
                      <FormControl fullWidth style={{marginBottom:"15px"}}>
                        <Box className="mobile-box" style={{border:"0.1px solid rgb(209 209 209 / 100%)"}}>
                          <Box style={languageCondition(language,{marginRight:"25px"},{})}>
                            <FormControl variant="outlined" style={{width:"80px"}}>
                              <PhoneInput
                                  inputProps={{ name: "selectCode" }}
                                  enableSearch={true}
                                  value={this.state.selectCode}
                                  onChange={this.handleChangeCCode}
                                  country={"us"}
                                  // @ts-ignore
                                  className="editProfileBox"
                              />
                            </FormControl>
                          </Box>
                          <Box className="divider" style={{margin:"10px"}}/>
                          <Input
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="phone"
                              className="mobile-input"
                              placeholder={t("Mobile Number")}
                              startAdornment={
                                <InputAdornment position="start">
                                  <img src={MobileIcon} alt="" />
                                </InputAdornment>
                              }
                          />
                        </Box>
                        {ErrorHandler(t, touched.phone, errors.phone)}
                      </FormControl>
                    </Grid>
                    <Box className="dialog-footer desktop-ui editMobileNumberUpdate">
                      <DialogActions className="customButton editMobileNumberUpdate">
                        <Button
                          type="submit" variant="contained" style={{width:"233px",height:"55px"}} className="editMobileNumberUpdate" >
                          {t("Submit")}
                        </Button>
                      </DialogActions>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>

        </Dialog>

        <Dialog
          open={this.state.showDialogDelete}
          onClose={() => this.setState({ showDialogDelete: false })}
          className="diloag-wrapper editProfileDelete"
          PaperProps={{
            style: {
              borderRadius: '15px',
              padding: '10px',
              margin: 0
            },
          }}
          dir={languageCondition(language,"rtl","ltr")}
        >
          <Box className="enterOTPClose" style={{ fontSize: "1.25rem", fontWeight: 'bold', textAlign: 'right' }} onClick={() => this.setState({ showDialogDelete: false })}>
            x
          </Box>
          <Grid container className="enterOTPClose">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="enterOTPClose">
              <img src={Lock} width='50' height='50' className="enterOTPClose"/>
            </Grid>
          </Grid>
          <Grid container className="enterOTPClose">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="enterOTPClose">
              <p className="bold-text enterOTPClose" style={{ fontWeight: 600, fontSize: '20px', textAlign: 'center' }}>
                {t("Verify Updated Mobile Number")}
              </p>
            </Grid>
          </Grid>
          <Grid container className="enterOTPClose">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }} className="enterOTPCloseText">
              <p style={{ fontWeight: 400, fontSize: '0.9rem', textAlign: 'center',marginBottom:'0.5rem' }} className="enterOTPCloseText">
                {t("Enter OTP sent to your mobile number for verification.")}
              </p>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className="enterOTPClose">
            <Grid item className="commonForm enterOTP">
              <Box className="formGroup otpBlock enterOTP">
                <OtpInput className="formOutlineInput enterOTP"
                  value={this.state.otp}
                  onChange={(otp:any)=> this.setState({otp})}
                  numInputs={4}
                />
              </Box>
              <Box className="customButton row-btn enterOTP" style={{display:"flex",justifyContent:"center",marginTop:"30px"}}>
                <Button variant="contained" className="enterOTPConfirm" style={{width:"233px",height:"55px"}} onClick={() => { this.verifyOtp() }}>{t("Submit")}</Button>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
        <Loader loading={this.state.loading} />
        <AlertErrorWeb show={this.state.showError} handleClose={()=> this.setState({showError:false,error:null})} message={this.state.error} />
      </>
    )
  }
}

const Gender =(props:any)=>{
 const  values = props?.values
 const handleChange = props?.handleChange
 const {t} = props
  return(
    <>
      <Box className="formGroup GenderBox" style={{flexDirection:'column',marginTop:'1rem',marginLeft:'1rem',fontWeight:'bold'}}>
                            <p className="bold-text GenderBox" style={{fontSize:"16px"}}>
                              {t("Gender")}
                            </p>
                            <div style={{display:'flex'}} className="GenderBox">
                              <div className="GenderBox">
                                <RadioGroup
                                  name="radio-buttons-group GenderBox"
                                  defaultValue={values.gender}
                                  style={{ display: 'flex',flexDirection:'row',marginTop:'0.25rem' }}
                                >
                                  <FormControlLabel name='gender' onChange={handleChange} value="Female" className="GenderBox"
                                                    control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#525252"}} />}
                                                                    checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}} />} />}
                                                    label={t("Female")} style={{ paddingRight: 30, borderRadius: 25, border: '1px solid #e9dede',width:"120px" }}  />
                                  <FormControlLabel name='gender' onChange={handleChange} value="Male" className="GenderBox"
                                                    control={<Radio icon={<RadioButtonUncheckedIcon style={{color:"#525252"}} />}
                                                                    checkedIcon={<RadioButtonCheckedIcon style={{color:"#FC8434"}} />} />}
                                                    label={t("Male")} style={{ paddingRight: 30, borderRadius: 25, border: '1px solid #e9dede',width:"120px" }} />
                                </RadioGroup>
                              </div>
                            </div>
                          </Box>
    </>
  )
}
// @ts-ignore
export default withTranslation()(withStyles(PropertyManagerStyleWeb)(withRouter(EditProfile)));
