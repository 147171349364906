import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  docName: string;

  isAddDocumentModalOpen: boolean;
  isDeleteDocumentModalOpen: boolean;
  isAddResolutionModalOpen: boolean;
  isSelectMeetingModalOpen: boolean;
  isShareModalOpen: boolean;

  documentList: any[];
  resolutionList: any[];
  meetingsList: any[];

  title: string;
  file: any;

  selectedDocumentId: string;
  selectedMeeting: any;

  shareUrl: string;

  showError: boolean;
  error: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DocumentListChairmanController extends BlockComponent<Props, S, SS> {
  ChairmanDocumentsCallId: any;
  CreateDocumentCallId: any;
  DeleteDocumentCallId: any;
  ResolutionsCallId: any;
  DeleteResolutionCallId: any;
  MeetingsCallId: any;
  CreateResolutionCallId: any;
  ResolutionPDFCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      docName: "",

      isAddDocumentModalOpen: false,
      isDeleteDocumentModalOpen: false,
      isAddResolutionModalOpen: false,
      isSelectMeetingModalOpen: false,
      isShareModalOpen: false,

      documentList: [],
      resolutionList: [],
      meetingsList: [],

      title: "",
      file: null,

      selectedDocumentId: "",
      selectedMeeting: null,

      shareUrl: "",

      showError: false,
      error: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.ChairmanDocumentsCallId:
          this.getChairmanDocumentsResponse(responseJson);
          break;
        case this.CreateDocumentCallId:
          this.createDocumentResponse(responseJson);
          break;
        case this.DeleteDocumentCallId:
          this.deleteDocumentResponse(responseJson);
          break;
        case this.MeetingsCallId:
          this.getMeetingsResponse(responseJson);
          break;
        case this.ResolutionsCallId:
          this.getResolutionsResponse(responseJson);
          break;
        case this.DeleteResolutionCallId:
          this.deleteResolutionResponse(responseJson);
          break;
        case this.CreateResolutionCallId:
          this.createResolutionResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  upload: any;

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const document_name = this.props.navigation.getParam("name");
    this.setState(
      {
        ...this.state,
        docName: document_name,
      },
      () => {
        if (
          document_name.toLowerCase() === "policy" ||
          document_name.toLowerCase() === "guidelines" ||
          document_name.toLowerCase() === "roles" ||
          document_name.toLowerCase() === "building-plans"
        ) {
          this.getDocuments(document_name.toLowerCase());
        } else if (document_name.toLowerCase() === "resolutions") {
          this.getMeetings();
          this.getResolutions();
        }
      }
    );
  }

  // Response
  getChairmanDocumentsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        ...this.state,
        documentList: responseJson.data,
      });
    }
  }

  createDocumentResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        ...this.state,
        documentList: [...this.state.documentList, responseJson.data],
      });
    }
  }

  deleteDocumentResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      toast.success(responseJson.message);
      this.getDocuments(this.props.navigation.getParam("name").toLowerCase())
    }
  }

  getMeetingsResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.setState({
        ...this.state,
        meetingsList: responseJson.meeting.data,
      });
    }
  }

  getResolutionsResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.setState({
        ...this.state,
        resolutionList: responseJson.resolution.data,
      });
    }
  }

  deleteResolutionResponse = (responseJson: any) => {
    this.getResolutions();
    this.handleDeleteDocumentModal();
  }

  createResolutionResponse = (responseJson: any) => {
    if (responseJson && responseJson.code === 200) {
      this.setState(
        {
          ...this.state,
          resolutionList: [...this.state.resolutionList, responseJson.resolution.data],
        },
        () => {
          this.handleAddResolutionsModal();
        }
      );
    }
  }

  // Get Meetings API
  getMeetings = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const society_id = localStorage.getItem("society_id");
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.MeetingsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_meeting/meeting_mins`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Resolutions API
  getResolutions = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const society_id = localStorage.getItem("society_id");
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ResolutionsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_my_document/resolutions`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Delete Resolution API
  deleteResolution = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const society_id = localStorage.getItem("society_id");
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteResolutionCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_my_document/resolutions/${this.state.selectedDocumentId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Resolution API
  createResolution = () => {
    var data = new FormData();
    data.append("resolution[title]", this.state.title.trim());
    data.append("resolution[attachments][]", this.state.file);
    data.append("resolution[meeting_id]", this.state.selectedMeeting.id);

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const society_id = localStorage.getItem("society_id");
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateResolutionCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_my_document/resolutions`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Get Document API
  getDocuments = (documentType: string) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ChairmanDocumentsCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    var APIEndpoint: string = "";
    if (documentType === "policy") {
      APIEndpoint = `society_managements/${society_id}/bx_block_my_document/policy_document`;
    } else if (documentType === "guidelines") {
      APIEndpoint = `society_managements/${society_id}/bx_block_my_document/guideline_document`;
    } else if (documentType === "roles") {
      APIEndpoint = `society_managements/${society_id}/bx_block_my_document/role_document`;
    } else if (documentType === "building-plans") {
      APIEndpoint = `society_managements/${society_id}/bx_block_my_document/building_plan_document`;
    }

    apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), APIEndpoint);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Document API
  createDocument = (values: any) => {
    var data = new FormData();
    data.append("title", values.title.trim());
    data.append("images", values.file);

    if (this.state.docName.toLowerCase() === "policy") {
      data.append("document_type", "Policy");
    } else if (this.state.docName.toLowerCase() === "guidelines") {
      data.append("document_type", "Guideline");
    } else if (this.state.docName.toLowerCase() === "roles") {
      data.append("document_type", "Role");
    } else if (this.state.docName.toLowerCase() === "building-plans") {
      data.append("document_type", "Building_plan");
    }

    const header = {
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateDocumentCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_my_document/building_documents`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Delete Document API
  deleteCategory = () => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("userToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteDocumentCallId = apiRequest.messageId;

    const society_id = localStorage.getItem("society_id");
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `society_managements/${society_id}/bx_block_my_document/building_documents/${this.state.selectedDocumentId}`
    );

    apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    this.handleDeleteDocumentModal();
    return true;
  };

  validateSize = (event: any, input: any) => {
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
      "image/bmp",
      "text/csv",
      "application/vnd.ms-excel",
    ];
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB

    return this.ValidationCondition(event, SUPPORTED_FORMATS, input, fileSize);
  };

  ValidationCondition = (event: any, SUPPORTED_FORMATS: any, input: any, fileSize: any) => {
    if (fileSize > 2) {
      this.setState({ showError: true, error: "File size exceeds 2 MiB" });
      event.target.value = null;
      return true;
    } else if (SUPPORTED_FORMATS.includes(input.files[0].type)) {
      this.setState({ showError: true, error: "Only Pdf, Docs and Text file support" });
      event.target.value = null;
      return true;
    } else {
      return false;
    }
  };

  validateSizeForResolution = (event: any, input: any) => {
    const SUPPORTED_FORMATS = ["image/gif", "image/bmp", "text/csv", "application/vnd.ms-excel"];
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB

    return this.ValidationCondition(event, SUPPORTED_FORMATS, input, fileSize);
  };

  onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.validateSizeForResolution(event, event.target)) {
      let file = event.target.files[0];

      this.setState({
        ...this.state,
        file: file,
      });
    }
  };

  validationAddForm = Yup.object().shape({
    title: Yup.string()
      .required("Required")
      .max(100, "Maximum length of title should be 100 character")
      .matches(/\S/, "Required"),
    file: Yup.mixed().required("Required"),
  });

  isInputOnlyWhiteSpace = (text: string) => {
    const regEx = /\S/;
    if (!regEx.test(text)) {
      return true;
    } else {
      return false;
    }
  };

  // Handle State
  handleAddDocumentModal = () => {
    this.setState({
      ...this.state,
      title: "",
      file: null,
      isAddDocumentModalOpen: !this.state.isAddDocumentModalOpen,
    });
  };

  handleDeleteDocumentModal = () => {
    this.setState({
      ...this.state,
      isDeleteDocumentModalOpen: !this.state.isDeleteDocumentModalOpen,
    });
  };

  handleAddResolutionsModal = () => {
    this.setState({
      ...this.state,
      title: "",
      file: null,
      selectedMeeting: null,
      isAddResolutionModalOpen: !this.state.isAddResolutionModalOpen,
    });
  };

  handleSelectMeetingModal = () => {
    this.setState({
      ...this.state,
      isSelectMeetingModalOpen: !this.state.isSelectMeetingModalOpen,
    });
  };

  handleShareModal = () => {
    this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
  };

  handleOpenShareModal = (url: string) => {
    this.setState({ shareUrl: url }, () => {
      this.handleShareModal();
    });
  };

  // Customizable Area End
}
