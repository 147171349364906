// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FacilityReservationController, { Props } from "./FacilityReservationController.web";
import { Building1, Pencil, Bin, InfoIcon, CROSS, DeleteIcon2 } from "../src/assets";
import { withTranslation } from "react-i18next";
import moment from "moment";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class FacilityReservationDetails extends FacilityReservationController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    //@ts-ignore
    const id = localStorage.getItem("facilityReservationId");
    if (id) {
      this.getFacilityReservationDetailsById(id);
    } else {
      this.props.history.push("/FacilityReservationListing");
    }
  }

  render() {
    const { t }: any = this.props;
    // @ts-ignore
    const {language} = this.props.i18n
    const reservation = localStorage.getItem("idOrName");
    const id = this.state?.getFacilityReservationDetails?.id;
    const attributes = this.state?.getFacilityReservationDetails?.attributes;

    return (
      <>
        <Box className="login-wrapper incident-wrapper" style={{ background: "#f8f9fe" }}>
          <Grid container spacing={2} className="auth-container" style={{ background: "#f8f9fe" }}>
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block FacilityDetails" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks" >
                    <ArrowBackIcon className="backIcons" onClick={() => window.history.back()} />
                    <h4 className="bold-text">{t(`${reservation} Reservations`)}</h4>
                  </Box>
                  {attributes?.status === "Pending" ?
                    <Box className="incident-right-block blocks">
                      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Button onClick={() => {
                          this.props.history.push({
                            pathname: "/CreateFacilityReservation",//@ts-ignore
                            id
                          });
                        }}>
                          <img src={Pencil} className="grid-icon icons" alt="" />
                        </Button>
                      </Box>
                      <Button onClick={() => { this.setState({ deleteShowDialog: true }); }}>
                        <img src={Bin} className="filter-icon icons" alt="" />
                      </Button>
                    </Box> :
                    attributes?.status === "Upcoming" &&
                    <Box className="incident-right-block blocks">
                      <Button onClick={() => { this.setState({ showDialog: true }); }}>
                        <img src={CROSS} className="filter-icon icons" alt="" />
                      </Button>
                    </Box>}
                </Box>
                <Box className="content-block-wrapper facility-block-wrapper" style={{ padding: "0 20px" }}>
                  <Box className="incident-content-wrapper" style={{ marginTop: 0 }}>
                    <Box className="incident-rows mt-15">
                      <h4 className="bold-text">{moment(attributes?.date, "DD-MMM-YYYY").format("DD MMMM YYYY")}</h4>
                    </Box>
                    <Card className="incident-card card" style={{ border: 0 }}>
                      <CardContent style={{ padding: "8px 16px" }}>
                        <Typography className="title-span" component="span">
                          {t("Building Name")}:
                        </Typography>
                        <Typography className="sub-title bold-text" component="h5">
                          {attributes?.building?.name}
                        </Typography>
                        <Typography className="title-span" component="span">
                          {t("Facility Reserved")}:
                        </Typography>
                        <Typography className="sub-title bold-text" component="h5">
                          {attributes?.common_area?.name}
                        </Typography>
                        <Typography className="title-span" component="span">
                          {t("Rent")}:
                        </Typography>
                        <Typography className="sub-title bold-text" component="h5">
                          {languageCondition(language,attributes?.currency?.currency_ar,attributes?.currency?.currency)}{" "}{attributes?.rent}
                        </Typography>
                        <Typography component="span">
                          {t("Hours")}:
                        </Typography>
                        <Typography className="sub-title bold-text" component="h5">
                          {moment(attributes?.start_time, "HH:mm").format("hh:mm A")} - {moment(attributes?.end_time, "HH:mm").format("hh:mm A")}
                        </Typography>
                        {
                          attributes?.status === "Completed" &&
                          <>
                            <Typography component="span">
                              {t("Paid on")}:
                            </Typography>
                            <Typography className="sub-title bold-text" component="h5">
                              {attributes?.paid_on}
                            </Typography>
                          </>
                        }

                      </CardContent>
                    </Card>
                    {((attributes?.status === "Rejected" || attributes?.status === "Cancelled" ) && attributes.note) &&
                      <Card className="incident-card card" style={{ border: 0, padding: 0 }}>
                        <CardContent style={{ padding: "10px 16px" }}>
                          <h4 style={{ color: "red" }} className="bold-text">{attributes?.status === "Rejected" ? "Rejection" : "Cancellation"} {t("Reason")}:</h4>
                          <p>{attributes.note || "N/A"}</p>
                        </CardContent>
                      </Card>
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Loader loading={this.state.loading} />

        <Dialog
          className="delete-document personal"
          fullWidth
          onClose={() => this.setState({ showDialog: false })}
          open={this.state?.showDialog}
          dir={languageCondition(language,"rtl","ltr")}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={InfoIcon} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                <span className="bold-text">{t("Cancel Reservation")}</span>
              </Typography>
              <Typography variant="body1">
                {t("Are you sure you want to cancel this reservation? If you proceed your reservation will be deleted and the manager will be notified")}.
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button onClick={() => { this.cancelUpcomingFacilityReservation(id, "Cancel"); }}>{t("Yes, Cancel")}</Button>
                <Button onClick={() => { this.setState({ showDialog: false }); }}>
                  {t("No, Don’t Cancel")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          className="delete-document personal"
          fullWidth
          onClose={() => this.setState({ deleteShowDialog: false })}
          open={this.state?.deleteShowDialog}
          dir={languageCondition(language,"rtl","ltr")}
        >
          <DialogContent>
            <Box textAlign="center">
              <img src={DeleteIcon2} alt="ExclamationIcon" />
              <Typography variant="h6" className="bold-text">
                <span className="bold-text">{t("Delete Pending Reservation Request")}?</span>
              </Typography>
              <Typography variant="body1">
                {t("Are you sure want to delete pending")} {attributes?.common_area?.name} {t("reservation request? Once deleted you will have to request reserving the facility again")}.
              </Typography>
              <DialogActions className="dialog-button-group">
                <Button onClick={() => { this.deleteFacility(id); }}>{t("Yes, Delete")}</Button>
                <Button
                  onClick={() => { this.setState({ deleteShowDialog: false }); }}
                >
                  {t("No, Don’t Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withRouter(FacilityReservationDetails));
// Customizable Area End