import React from "react";
import {
  Box,
  Button,
  Link,
  Grid
} from "@material-ui/core";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentController, { Props } from "./IncidentController.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { Tenant_Logo, Building_Logo, Tick_Circle_Icon, Building1 } from "../src/assets";

class IncidentReportedSuccessfully extends IncidentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
   const id = localStorage.getItem("createIncidentId")
    if (!id) {
      this.props.history.replace("/CreateIncident");
    }
    return (
      <>
        <Box className="login-wrapper auth-wrapper" style={{ background: "white" }}>
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className="auth-cols">
              <Box className="content-block">
                <Box className="logo-block common-top-padding" display={{ xs: 'none', md: 'flex' }}>
                  <Link href="/EmailAccountLogin">
                    <img src={Building_Logo.default} className="head-logo" alt="" />
                    <h4>Building Name</h4>
                  </Link>
                </Box>
                <Box className="main-content-block change-password-mainblock" style={{height: "75vh"}}>
                  <Box className="header-block header-block-changepassword" style={{padding: "0", justifyContent:"center"}}>
                    <img src={Tick_Circle_Icon} className="lock-logo" alt="Lock_Icon" />
                    <h1 className="bold-text">Incident Reported<br></br>Successfully</h1>
                    <p>Your incident has been reported successfully. Your ticket id for reported incident is<br></br><span className="id-title bold-text">{id}</span></p>
                  </Box>
                </Box>
                <Box className="footer-block desktop-ui view-ticket-btn-incident">
                  <Box className="row-btn customButton" style={{margin:"0"}}>
                    <Button data-test-id="createChatRoom" variant="contained" style={{boxShadow:"none"}} onClick={() => {
                      this.createChatRoom(localStorage?.getItem("createIncidentId"))
                      localStorage.removeItem("createIncidentId");
                      localStorage.removeItem("incidentPreview");
                    }}>view ticket</Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AlertErrorWeb data-test-id="arrowbtn" show={this.state.showError} handleClose={()=> this.setState({showError:false,error:null})} message={this.state.error} />
        <Loader loading={this.state.loading} />
      </>
    )
  }
}

export default withRouter(IncidentReportedSuccessfully)
export {IncidentReportedSuccessfully}
