// Customizable Area Start
import * as React from "react";
// custom components
import { Grid, Box, Typography, MenuItem, Select, InputAdornment } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { shortIcon, filterIcon } from "../../BroadcastMessage/src/assets";
import { withRouter } from "react-router";
import MyReceiptsController, { Props } from "./MyReceiptsController";
import "./style.css";
import { withTranslation } from "react-i18next";
import { Building, Unit } from "./assets";
import { Menu, MenuItem as MenuItemMenu } from "@szhsin/react-menu";
import ReceiptCard from "../../../components/src/InvoiceComponent/ReceiptCard.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class Visitors extends MyReceiptsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid dir={languageCondition(language, "rtl", "ltr")} item xs={12} md={12} className="my-receipt-page-content auth-cols">
          <Grid container className="fee-payment-top-bar my-receipt-navbar">
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <ArrowBackIcon className={languageCondition(language, "KeyboardForwardIconAR", "receipt-back-button")} onClick={() => window.history.back()} />
                <p style={{ fontSize: "1.2rem" }} className="bold-text my-receipt-title">
                  {t("View Receipts")}
                </p>
              </Box>
              <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                <Box className={languageCondition(language, "", "my-receipt-sort-menu")}>
                  <Menu menuButton={<img src={shortIcon} alt="" />}>
                    <MenuItemMenu className="my-receipt-asc-sort" onClick={() => this.setState({ selectedSort: "asc" })}>{t("Asc")}</MenuItemMenu>
                    <MenuItemMenu className="my-receipt-desc-sort" onClick={() => this.setState({ selectedSort: "desc" })}>{t("Desc")}</MenuItemMenu>
                  </Menu>
                </Box>
                <Box className={languageCondition(language, "", "my-receipt-filter-menu")}>
                  <Menu menuButton={<img src={filterIcon} alt="" />}>
                    <MenuItemMenu className="my-receipt-all-filter" onClick={() => this.setState({ selectedStatus: "" })}>{t("All")}</MenuItemMenu>
                    <MenuItemMenu className="my-receipt-fee-filter" onClick={() => this.setState({ selectedStatus: "management fee" })}>
                      {t("Management Fee")}
                    </MenuItemMenu>
                    <MenuItemMenu className="my-receipt-rent-filter" onClick={() => this.setState({ selectedStatus: "rent" })}>{t("Rent")}</MenuItemMenu>
                    <MenuItemMenu className="my-receipt-fac-filter" onClick={() => this.setState({ selectedStatus: "facility rental" })}>{t("Facility Rental")}</MenuItemMenu>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="fee-payment-list my-receipt-content-box">
            <Grid container spacing={2} style={{ width: "90%" }} className={languageCondition(language, "arabic-grid", "")}>
              <Grid item xs={12}>
                <Select
                  value={this.state.selectedBuilding}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    this.setState({ selectedBuilding: value }, async () => {
                      await this.getUnitList(value);
                    });
                  }}
                  className="my-receipt-building-filter selectBuildingBox"
                  disableUnderline
                  displayEmpty
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={Building} style={{ marginLeft: languageCondition(language, "0", "25px"), marginRight: languageCondition(language, "25px", "0") }} />
                    </InputAdornment>
                  }
                  style={{
                    padding: "28px 0",
                    backgroundColor: "white",
                    borderRadius: "100px",
                    height: "50px",
                    border: "1px solid #f0f0f0",
                  }}
                  fullWidth
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                    {t("Building Name")}
                  </MenuItem>
                  {this.state.buildingList.map((building: any) => {
                    return (
                      <MenuItem value={building.id} key={building.id} className={languageCondition(language, "select-arabic-menu", "")}>
                        {building.attributes.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  className="my-receipt-unit-filter selectBuildingBox"
                  disableUnderline
                  value={this.state.selectedUnit}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    this.setState({ selectedUnit: value });
                  }}
                  style={{
                    padding: "28px 0",
                    backgroundColor: "white",
                    borderRadius: "100px",
                    height: "50px",
                    border: "1px solid #f0f0f0",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={Unit} style={{ marginLeft: languageCondition(language, "0", "25px"), marginRight: languageCondition(language, "25px", "0") }} />
                    </InputAdornment>
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Unit Number")}</MenuItem>
                  {this.state.unitList.map((unit: any) => {
                    return (
                      <MenuItem value={unit.id} key={unit.id} className={languageCondition(language, "select-arabic-menu", "")}>
                        {unit.apartment_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid container spacing={1} style={{ display: "flex" }}>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                    <Typography
                      variant="body1"
                      className={this.state.selectedTab === "MyInvoices" ? "tabInvoiceButtonActive" : "tabInvoiceButton"}
                      onClick={() => this.setState({ selectedTab: "MyInvoices" })}
                    >
                      {t("My Receipts")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                    <Typography
                      variant="body1"
                      className={this.state.selectedTab === "TenantInvoices" ? "tabInvoiceButtonActive" : "tabInvoiceButton"}
                      onClick={() => this.setState({ selectedTab: "TenantInvoices" })}
                    >
                      {t("Tenant Receipts")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.selectedTab === "MyInvoices" ? (
                <>
                  {this.state.receiptList.map((receipt: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={receipt.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyReceipts/Owner/${receipt.id}/${receipt.attributes.invoice_type}`,
                            state: { selectedInvoice: receipt },
                          })
                        }
                      >
                        <ReceiptCard
                          language={language}
                          payment_type={receipt.attributes.invoice_type}
                          amount={receipt.attributes.amount}
                          currency={receipt.attributes.currency}
                          unit_number={receipt.attributes.unit_number}
                          building_name={receipt.attributes.building_name}
                          tenant_name={receipt.attributes.owner_details.owner_name}
                          status={receipt.attributes.status}
                          month_year={receipt.attributes.month_year}
                          cardType="Owner"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <>
                  {this.state.tenantReceiptList.map((receipt: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={receipt.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyReceipts/Owner/${receipt.data.id}/${receipt.data.attributes.invoice_type}`,
                            state: { selectedInvoice: receipt.data },
                          })
                        }
                      >
                        <ReceiptCard
                          language={language}
                          payment_type={receipt.data.attributes.invoice_type}
                          amount={receipt.data.attributes.amount}
                          currency={receipt.data.attributes.currency}
                          unit_number={receipt.data.attributes.unit_number}
                          building_name={receipt.data.attributes.building_name}
                          tenant_name={receipt.data.attributes.tenant_details.tenant_name}
                          status={receipt.data.attributes.status}
                          month_year={receipt.data.attributes.month_year}
                          cardType="Tenant"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                  {this.state.tenantRentReceiptList.map((receipt: any) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={receipt.id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/MyReceipts/Owner/${receipt.data.id}/${receipt.data.attributes.invoice_type}`,
                            state: { selectedInvoice: receipt.data },
                          })
                        }
                      >
                        <ReceiptCard
                          language={language}
                          payment_type={receipt.data.attributes.invoice_type}
                          amount={receipt.data.attributes.received_amount}
                          currency={receipt.data.attributes.currency}
                          unit_number={receipt.data.attributes.apartment_name}
                          building_name={receipt.data.attributes.building_name}
                          tenant_name={receipt.data.attributes.tenant_details.tenant_name}
                          status={receipt.data.attributes.status}
                          month_year={receipt.data.attributes.month_year}
                          cardType="Tenant"
                          t={t}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(withRouter(Visitors));

// Customizable Area End
