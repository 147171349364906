// Customizable Area Start
import React from "react";
export const ChatImageComponent = () => {
  return (
    <svg
      width="24.399902px"
      height="24.40039px"
      viewBox="0 0 24.399902 24.40039"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chat-(4)" transform="translate(0.19995117 0.20019531)">
        <path
          d="M12.0081 0C13.9494 0 15.8833 0.478879 17.6016 1.38484C23.4519 4.47166 25.7017 11.7426 22.617 17.5949C20.513 21.5855 16.3704 24 11.9944 24C11.1394 24 10.2761 23.908 9.4169 23.7177C8.9329 23.6099 8.62749 23.13 8.73422 22.6458C8.84096 22.1617 9.31968 21.8583 9.80685 21.9629C14.2759 22.9566 18.8898 20.8117 21.0276 16.7566C23.6505 11.7817 21.7378 5.59856 16.7636 2.97476C15.3031 2.20411 13.6588 1.79712 12.007 1.79712L12.007 1.79712C6.37234 1.79712 1.79652 6.37343 1.79652 11.9984C1.79441 13.637 2.19387 15.2681 2.95157 16.7174L2.95157 16.7174L3.18195 17.1678C3.46411 17.6963 3.52646 18.3528 3.34364 18.9469C3.09107 19.6129 2.88077 20.2609 2.70746 20.9058C3.38168 20.7028 4.2049 20.4036 4.79987 20.1869L4.79987 20.1869L5.04398 20.0992C5.50579 19.9269 6.02466 20.1711 6.19375 20.6383C6.36283 21.1045 6.12083 21.6204 5.65479 21.7885L5.65479 21.7885L5.41385 21.8762C4.52405 22.1987 3.30665 22.6416 2.48448 22.8255C2.41474 22.8403 2.3397 22.8498 2.27101 22.8477C1.71938 22.8477 1.38333 22.6247 1.19839 22.4365C0.914121 22.1479 0.778854 21.743 0.794706 21.2335C0.796819 21.1754 0.804217 21.1151 0.817955 21.057C1.03142 20.1478 1.30935 19.2408 1.64541 18.3623C1.66971 18.2767 1.65492 18.1213 1.58623 17.9913L1.58623 17.9913L1.35479 17.542C0.468164 15.8442 -0.0020994 13.9234 7.04562e-06 11.9974C7.04562e-06 5.38184 5.38109 0 11.9944 0L11.9944 0L12.0081 0ZM11.9948 10.5714C12.7821 10.5714 13.4214 11.212 13.4214 11.9985C13.4214 12.785 12.7821 13.4257 11.9948 13.4257C11.2075 13.4257 10.5681 12.785 10.5681 11.9985C10.5681 11.212 11.2075 10.5714 11.9948 10.5714ZM17.2787 10.5714C18.066 10.5714 18.7053 11.212 18.7053 11.9985C18.7053 12.785 18.066 13.4257 17.2787 13.4257C16.4914 13.4257 15.8521 12.785 15.8521 11.9985C15.8521 11.212 16.4914 10.5714 17.2787 10.5714ZM6.71082 10.5718C7.49811 10.5718 8.13746 11.2114 8.13746 11.999C8.13746 12.7855 7.49811 13.425 6.71082 13.425C5.92353 13.425 5.28418 12.7855 5.28418 11.999C5.28418 11.2114 5.92353 10.5718 6.71082 10.5718Z"
          id="Shape"
          fill-rule="evenodd"
          stroke-width="0.4"
        />
      </g>
    </svg>
  );
};
// Customizable Area End
