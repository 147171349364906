// Customizable Area Start
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import { Box, Button, Link, Grid } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ChairmanForgotPasswordController, { Props } from "./ChairmanForgotPasswordController.web";
import Loader from "../../../components/src/Loader.web";
import { Tenant_Logo, Building_Logo, Lock_User_Icon, Building1 } from "../src/assets";
import "@szhsin/react-menu/dist/core.css";
import AlertErrorWeb from "../../../components/src/AlertError.web"
import {withTranslation} from "react-i18next";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ChangeLanguageMenu from "../../../components/src/HeaderComponent/ChangeLanguageMenu.web";

class ChairmanChangePassword extends ChairmanForgotPasswordController {
  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props;
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper changePasswordChairman" style={{backgroundColor:"white"}} dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className="auth-container changePasswordChairman">
            <Grid item xs={12} md={7} className="auth-cols changePasswordChairman">
              <Box className="content-block changePasswordChairman">
                <Box className="logo-block common-top-padding common-logo-block changePasswordChairman" display={{ xs: "none", md: "flex" }}>
                  <Link href="/ManagementLogin">
                    <img src={Building_Logo.default} className="head-logo changePasswordChairman" alt="" />
                    <h4 className="changePasswordChairman">{t("Building Name")}</h4>
                  </Link>
                  <Box>
                    <ChangeLanguageMenu/>
                  </Box>
                </Box>
                <Box className="main-content-block desktop-ui changePasswordChairman" style={{marginTop: '50px'}}>
                  <Box className="header-block changePasswordChairman">
                    <h1 className="bold-text changePasswordChairman">{t("Change Password")}</h1>
                    <h6 className="changePasswordChairman">
                      {t("You need to change your password.")}
                      <br />
                      {t("Please enter a new password")}
                    </h6>
                  </Box>
                  <Formik
                    initialValues={{
                      confirmPassword: "",
                      confirmShowPassword: false,
                      newShowPassword: false,
                      newPassword: "",
                    }}
                    validationSchema={this.changePasswordValidations()}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      this.changePassword(values);
                    }}
                  >
                    {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                      <Form translate="yes" className="commonForm changePasswordChairman">
                        <Box className="formGroup changePasswordChairman">
                          <div className="formInputGrp changePasswordChairman">
                            <Field
                              type={values.newShowPassword ? "text" : "password"}
                              name="newPassword"
                              placeholder={t("New Password")}
                              className={languageCondition(language, "formInput_ar", "formInput ForgotPasswordFeild changePasswordChairman")}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons forgotPasswordMain1")}>
                              <img src={Lock_User_Icon} className="frm-icons" alt="Email Icon" style={languageCondition(language,{marginTop: "4px"},{marginTop: "-2px"})} />
                            </span>
                            {values.newShowPassword ? (
                              <span className={languageCondition(language, "frmLeftIcons", "frmRightIcons ForgotPassword1")}>
                                <Visibility className="hidePassword1" onClick={() => setFieldValue("newShowPassword", false)} />
                              </span>
                            ) : (
                              <span className={languageCondition(language, "frmLeftIcons", "frmRightIcons ForgotPasswordOff1")}>
                                <VisibilityOffIcon className="showPassword1" onClick={() => setFieldValue("newShowPassword", true)} />
                              </span>
                            )}
                          </div>
                          <ErrorMessage className="text-error" component="Typography" name="newPassword" />
                        </Box>
                        <Box className="formGroup changePasswordChairman">
                          <div className="formInputGrp changePasswordChairman">
                            <Field
                              type={values.confirmShowPassword ? "text" : "password"}
                              name="confirmPassword"
                              placeholder={t("Confirm Password")}
                              className={languageCondition(language, "formInput_ar", "formInput ForgotPasswordFeild2")}
                            />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons forgotPasswordMain2")}>
                              <img src={Lock_User_Icon} className="frm-icons" alt="Email Icon" style={languageCondition(language,{marginTop: "4px"},{marginTop: "-2px"})}  />
                            </span>
                            {values.confirmShowPassword ? (
                              <span  className={languageCondition(language, "frmLeftIcons", "frmRightIcons ForgotPassword2")}>
                                <Visibility className="hidePassword2" onClick={() => setFieldValue("confirmShowPassword", false)} />
                              </span>
                            ) : (
                              <span  className={languageCondition(language, "frmLeftIcons", "frmRightIcons ForgotPasswordOff2")}>
                                <VisibilityOffIcon className="showPassword2" onClick={() => setFieldValue("confirmShowPassword", true)} />
                              </span>
                            )}
                          </div>
                          <ErrorMessage className="text-error" component="Typography" name="confirmPassword" />
                        </Box>
                        <Box className="customButton row-btn changePasswordChairman">
                          <Button variant="contained" type="submit" className="changePasswordChairman">
                            {t("Change Password")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
                <Box className="bottomBlock common-bottom-padding changePasswordChairman" display={{ xs: "none", md: "flex"}} style={{marginTop:"60px"}}>
                  <h6 className="bottom-text changePasswordChairman">{t("POWERED BY")}</h6>
                  <img src={Tenant_Logo.default} className="tenant-logo changePasswordChairman" alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block changePasswordChairman" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AlertErrorWeb show={this.state.showError}
                       handleClose={()=> this.setState({showError:false,error:null})}
                       message={this.state.error}
        />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}
//@ts-ignore
export default withTranslation()(withRouter(ChairmanChangePassword));

// Customizable Area End