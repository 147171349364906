//Customizable Area Start
import React from "react";
export const image_info = require("../../assets/info.png");
import CENotDeleteController, { Props } from "./CENotDeleteController.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { withTranslation } from "react-i18next";

class CENotDelete extends CENotDeleteController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, dataFromComponent1, handleModalClose }: any = this.props;

    return (
      <>
        <AlertDialog
          IsOpen={dataFromComponent1}
          Image={image_info}
          CloseDialog={handleModalClose}
          Header={t("Delete Profile")}
          Content={`<p>${t(
            "Deletion can't be made because there are Object's assigned to this Employee. Make sure to move all the complexes, buildings, owner's ets to another employee before you can delete this profile."
          )}</p>`}
          DeclineText={t("OKAY")}
          AcceptText=""
          DeclineFun={handleModalClose}
          AcceptFun={() => {}}
        />
      </>
    );
  }
}

export default withTranslation()(CENotDelete);
// Customizable Area End
