// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Button,
  IconButton,
  Select,
  MenuItem,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  InputBase,
  Link as NavLink,
  Input,
  Box,
  Grid
} from "@material-ui/core";
import MeetingMinutesController, { Props } from "./MeetingMinutesController.web";
import { Link } from "react-router-dom";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { MeetingsStyleWeb } from "./MeetingsStyle.web";
import { SearchIconImage } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//@ts-ignore
import Pagination from "@material-ui/lab/Pagination";
import toast from "react-hot-toast";
import { ROLE } from "../../../framework/src/Enum";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import { withRouter } from "react-router";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { LanguageAttributeHandler } from "../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

class MeetingMinutes extends MeetingMinutesController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    this.getAllMeetings();
    this.getBuildingsList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (
      prevState.filter.title !== this.state.filter.title ||
      prevState.filter.status !== this.state.filter.status ||
      prevState.filter.date !== this.state.filter.date ||
      prevState.filter.page !== this.state.filter.page ||
      prevState.filter.building !== this.state.filter.building
    ) {
      await this.getAllMeetings();
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <GeneralHeaderComponentWeb>
              <Container className={classes.scheduledMeeting}>
                <Box className="navigation meeting-minute-navigation-box">
                  <Box className="meeting-minute-header-box">
                    <Typography variant="body1">
                      {t("Meetings")} /{" "}
                      <Box component="span" style={{ color: "blue" }}>
                        {t("Meeting Minutes")}
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="sub-heading bold-text meeting-minute-heading">
                      {t("Meeting Minutes")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="top-bar">
                  <Box className="filter meeting-minute-filter-box">
                    {localStorage.getItem("userType") === ROLE.MANAGER && (
                      <Select
                        displayEmpty
                        value={this.state.building}
                        onChange={(e: any) => {
                          this.setState({
                            ...this.state,
                            building: e.target.value,
                          }, () => {
                            this.removeBuildingAllFilter();
                          });
                        }}
                        className="select-input meeting-minute-filter-building"
                      >
                        <MenuItem value="select building">{t("Select Building")}</MenuItem>
                        <MenuItem value="">{t("All")}</MenuItem>
                        {this.state.buildingsList.map((building: any) => {
                          return <MenuItem value={building.name}>{building.name}</MenuItem>;
                        })}
                      </Select>
                    )}
                    <Select
                      displayEmpty
                      value={this.state.status}
                      className="select-input meeting-minute-filter-status"
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          status: e.target.value,
                        }, () => {
                          this.removeStatusAllFilter();
                        });
                      }}
                    >
                      <MenuItem value="select status">{t("Select Status")}</MenuItem>
                      <MenuItem value="">{t("All")}</MenuItem>
                      <MenuItem value="pending">{t("Pending")}</MenuItem>
                      <MenuItem value="approved">{t("Approved")}</MenuItem>
                      <MenuItem value="rejected">{t("Rejected")}</MenuItem>
                    </Select>
                    <Input
                      value={this.state.date}
                      onChange={(e: any) => {
                        this.setState({
                          ...this.state,
                          date: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={t("Date")}
                      onFocus={(e: any) => (e.target.type = "date")}
                      className="input date meeting-minute-filter-date"
                      onBlur={(e: any) => (e.target.type = "text")}
                    />
                    <Button
                      startIcon={<img src={SearchIconImage} />}
                      onClick={() => {
                        this.setState({
                          filter: {
                            ...this.state.filter,
                            status: this.state.status,
                            date: this.state.date,
                            building: this.state.building,
                            title: "",
                          },
                        });
                      }}
                    >
                      {t("Search")}
                    </Button>
                  </Box>
                </Box>
                <Grid className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className="bold-text">{t("Meeting Minutes")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search by title")}
                          className="search meeting-minute-search-title"
                          value={this.state.filter.title}
                          onChange={(e: any) => {
                            this.setState({
                              ...this.state,
                              filter: {
                                ...this.state.filter,
                                title: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")}>#</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Title")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Agenda")}</TableCell>
                          {localStorage.getItem("userType") === ROLE.MANAGER && <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Building")}</TableCell>}
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Date & Time")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text">{t("Status")}</TableCell>
                          <TableCell align={languageCondition(language,"right","left")} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.meetingMinuteList.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={5}>{t("No Meeting Minutes Available!!")}</TableCell>
                          </TableRow>
                        )}
                        {this.state.meetingMinuteList.map((meeting: any, index: number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align={languageCondition(language,"right","left")}>{index + 1}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ellipse-one" title={meeting.attributes.title}>{meeting.attributes.title}</TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className="ellipse-one">{meeting.attributes.agenda}</TableCell>
                              {localStorage.getItem("userType") === ROLE.MANAGER && (
                                <TableCell align={languageCondition(language,"right","left")}>{meeting.attributes?.building?.name || "All"}</TableCell>
                              )}
                              <TableCell align={languageCondition(language,"right","left")}>
                                {moment(meeting.attributes.meeting_date_time, "DD-MM-YYYY HH:mm", true).format(
                                  "MMMM DD, YYYY HH:mm"
                                )}
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")}>
                                <span className={meeting.attributes.meeting_mins_status}>
                                  {t(meeting.attributes.meeting_mins_status)}
                                </span>
                              </TableCell>
                              <TableCell align={languageCondition(language,"right","left")} className={LanguageAttributeHandler(language, "meeting-menu", "")}>
                                <Menu
                                  menuButton={
                                    <IconButton>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                >
                                  <MenuItem>
                                    <Link to={`/MeetingMinute/${meeting.id}`}>{t("View")}</Link>
                                  </MenuItem>
                                  {!meeting.attributes.meeting_mins_pdf ? (
                                    <>
                                      <MenuItem className="meeting-minute-download-menu" onClick={() => toast.error(`${t("No meeting document available!!")}`)}>
                                        {t("Download")}
                                      </MenuItem>
                                      <MenuItem className="meeting-minute-share-menu" onClick={() => toast.error(`${t("No meeting document available!!")}`)}>
                                        {t("Share")}
                                      </MenuItem>
                                    </>
                                  ) : (
                                    <>
                                      <MenuItem>
                                        <NavLink 
                                          href={meeting.attributes.meeting_mins_pdf.url} 
                                          target="_blank">
                                          {t("Download")}
                                        </NavLink>
                                      </MenuItem>
                                      <MenuItem
                                        className="meeting-minute-share-menu" 
                                        onClick={() => {
                                          this.setState({ shareUrl: meeting.attributes.meeting_mins_pdf.url }, () => {
                                            this.handleShareModal();
                                          });
                                        }}
                                      >
                                        {t("Share")}
                                      </MenuItem>
                                    </>
                                  )}
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <p>
                        {t("Showing")} <span className="current-page">{this.state.meetingMinuteList.length}</span> {t("of")}{" "}
                        <span className="total-page">
                          {this.state.pagination ? this.state.pagination.total_count : 0}
                        </span>{" "}
                        {t("results")}
                      </p>
                      {this.state.pagination && (
                        <Pagination
                          onChange={(event: any, value: any) => {
                            this.setState({
                              ...this.state,
                              filter: {
                                ...this.state.filter,
                                page: Number(value),
                              },
                            });
                          }}
                          count={this.state.pagination.total_pages}
                          page={this.state.pagination.current_page}
                          siblingCount={2}
                          variant="outlined"
                          shape="rounded"
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
        </GeneralHeaderComponentWeb>

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(MeetingsStyleWeb)(withRouter(MeetingMinutes)));
// Customizable Area End
