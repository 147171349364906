import { Button, withStyles } from "@material-ui/core";

export const CloseButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    borderRadius: "100px",
    height: "50px",
    "&:hover": {
      backgroundColor: "#2b6fef",
    },
  },
}))(Button);
