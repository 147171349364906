// Customizable Area Start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import * as Yup from "yup";

export interface Props {
  navigation?: any;
  id?: string;
  classes?: any;
  openModal: boolean;
  closeModal: () => void;
  isEdit: boolean;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  cityList: string[];
  userNameList: any[];
  nationalityList: string[];
  roleList: any[];
  countryList: any[];
  countryForCity: string;
  reportingPersonList: any[];
  userNameValue: number;
  userdata: {
    name: number | string;
    role: string;
    email: string;
    phoneNumber: number | string;
    nationality: string;
    country: string;
    city: string;
    reportingPerson: string;
    reportingPersonId: number | string;
    empID: number | string;
    ResidencyID: string;
  };
  disableState: {
    name: boolean;
    role: boolean;
    email: boolean;
    phoneNumber: boolean;
    nationality: boolean;
    country: boolean;
    city: boolean;
    reportingPerson: boolean;
    empID: boolean;
    ResidencyID: boolean;
  };
  role: string;

  showError: boolean;
  error: string;
}

interface SS {
  id: any;
}

export default class CEAddNewTeamMebmerModalController extends CommonApiCallForBlockComponent<Props, S, SS> {
  cityList: string = "";
  roleList: string = "";
  userNameList: string = "";
  nationalityList: string = "";
  getUserDataById: string = "";
  countryList: string = "";
  reportingPersonList: string = "";
  createTeamMemberCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    this.state = {
      cityList: [],
      userNameList: [],
      nationalityList: [],
      roleList: [],
      countryList: [],
      countryForCity: "",
      reportingPersonList: [],
      userNameValue: NaN,
      userdata: {
        name: "",
        role: "",
        email: "",
        phoneNumber: "",
        nationality: "",
        country: "",
        city: "",
        reportingPersonId: "",
        reportingPerson: "",
        empID: "",
        ResidencyID: "1",
      },
      disableState: {
        name: false,
        role: false,
        email: false,
        phoneNumber: false,
        nationality: false,
        country: false,
        city: false,
        reportingPerson: false,
        empID: false,
        ResidencyID: false,
      },
      role: "",

      showError: false,
      error: ""
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    const role = localStorage.getItem("role") ?? "";
    this.setState({ role: role }, async () => {
      await this.getTeamNationalityListAPI();
      await this.getTeamUserNameListAPI();
      await this.getTeamRoleListAPI();
      this.handleDisabledField(this.props.isEdit);
      if (this.props.isEdit) {
        this.handleGetUserDataById(Number(this.props.id));
      }
    });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        this.checkAPICallResponse(apiRequestCallId, responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  checkAPICallResponse(apiRequestCallId: string, responseJson: any) {
    if (apiRequestCallId === this.roleList) {
      this.handleTeamRoleList(responseJson);
    } else if (apiRequestCallId === this.nationalityList) {
      this.handleTeamNationalityList(responseJson);
    } else if (apiRequestCallId === this.userNameList) {
      this.handleTeamUserNameList(responseJson);
    } else if (apiRequestCallId === this.getUserDataById) {
      this.handleTeamUserDataById(responseJson);
    } else if (apiRequestCallId === this.reportingPersonList) {
      this.handleTeamReportingPersonList(responseJson);
    } else if (apiRequestCallId === this.createTeamMemberCallId) {
      if (responseJson.error) {
        this.setState({ showError: true, error: responseJson.error })
      } else {
        this.props.closeModal();
      }
    }
  }

  getOpacityFromValue = (value: string | number, isDisabled?: boolean) => (Number.isNaN(value) || value === "" || isDisabled ? 0.5 : 1);

  //API response handler
  handleTeamRoleList = (responseJson: any) => {
    let roleList = [];
    if (!responseJson.errors) {
      if (responseJson.status) {
        roleList = responseJson.status;
      }
      if (roleList && roleList.length > 0) {
        this.setState({ roleList: [...roleList] });
      }
    }
  };

  handleTeamUserNameList = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.hasOwnProperty("status")) {
        const list = responseJson.status.map((item: any) => ({ label: item.name, value: item.id }));
        this.setState({ userNameList: list });
      }
    }
  };

  handleTeamNationalityList = (responseJson: any) => {
    if (!responseJson.errors) {
      const nationality = responseJson.data.nationality.map((item: any) => ({ label: item, value: item }));
      this.setState({ nationalityList: nationality });
    }
  };

  handleTeamReportingPersonList = (responseJson: any) => {
    let reportList = [];
    if (!responseJson.errors) {
      if (responseJson && responseJson.data) {
        reportList = responseJson.data.map((item: any) => ({
          label: item.attributes.first_name + " " + item.attributes.last_name,
          value: item.id,
        }));
      }
      this.setState({ reportingPersonList: reportList });
    }
  };

  handleTeamUserDataById = (responseJson: any) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      const user = responseJson.data.attributes;
      this.setState(
        {
          userdata: {
            name: this.state.userNameValue,
            role: user.role,
            email: user.email,
            phoneNumber: user.full_phone_number,
            nationality: user.nationality,
            country: user.country,
            city: user.city,
            reportingPerson: user.reporting_person_name.first_name + " " + user.reporting_person_name.last_name,
            reportingPersonId: user.reporting_person_name.id + "",
            empID: responseJson?.data?.id + "" || "",
            ResidencyID: user.national_id ?? "",
          },
        },
        () => {
          this.getTeamReportingPersonListAPI(user.role);
        }
      );
    }
  };

  handleGetUserDataById = (value: any) => {
    if (value) {
      this.setState({ userNameValue: value }, () => {
        this.getTeamUserDataByIdAPI(value);
      });
    }
  };

  handleDisabledField = (edit: boolean) => {
    this.setState({
      disableState: {
        name: edit,
        role: false,
        email: true,
        phoneNumber: true,
        nationality: true,
        country: true,
        city: true,
        reportingPerson: false,
        empID: true,
        ResidencyID: true,
      },
    });
  };

  addTeamMember = (formData: any) => {
    const name = this.props.isEdit ? this.state.userNameList.find((name) => name.value == this.state.userNameValue).label : formData.name;
    const initialFormData = new FormData();
    initialFormData.append(`team_member[${this.props.isEdit ? "employee_name" : "employee_name_id"}]`, name);
    initialFormData.append("team_member[email]", formData.email);
    initialFormData.append("team_member[role]", formData.role);
    initialFormData.append("team_member[full_phone_number]", formData.phoneNumber);
    initialFormData.append("team_member[country]", formData.country);
    initialFormData.append("team_member[reporting_person_id]", formData.reportingPersonId);
    initialFormData.append("team_member[national_id]", formData.ResidencyID);
    initialFormData.append("team_member[nationality]", formData.nationality);
    initialFormData.append("team_member[employee_account_id]", formData.empID);

    this.createTeamMember(initialFormData);
  };

  getUpdatedList = () => {
    const role = this.state.role;
    const updatedRole = this.state.roleList.filter((item: any) => {
      if (role === "coo") {
        return item.label !== "coo";
      } else if (this.state.role === "sales_manager") {
        return item.label === "account_manager";
      }
    });
    return updatedRole.map((item: any) => ({ label: item.label, value: item.label }));
  };

  //API handler
  getTeamRoleListAPI = async () => {
    this.roleList = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/role_list`,
    });
  };

  getTeamUserNameListAPI = async () => {
    this.userNameList = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/team_members/user_name_list`,
    });
  };

  getTeamNationalityListAPI = async () => {
    this.nationalityList = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/nationality_list`,
    });
  };

  getTeamUserDataByIdAPI = async (id: number) => {
    this.getUserDataById = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/employee_details?employee_id=${id}`,
    });
  };

  getTeamReportingPersonListAPI = async (role: string) => {
    this.reportingPersonList = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee/employee_team_members/reporting_manager_list?role=${role}`,
    });
  };

  createTeamMember = async (formData: any) => {
    this.createTeamMemberCallId = await CEApiCall({
      method: this.props.isEdit ? "PATCH" : "POST",
      endPoint: `bx_block_my_team/company_employee/employee_team_members${this.props.isEdit ? "/" + this.props.id : ""}`,
      body: formData,
    });
  };

  validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    reportingPersonId: Yup.string().required("Reporting Person is required"),
  });
}
// Customizable Area End
