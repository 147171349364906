// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openactivities: any;
  }
export const ActivitiesImageComponent : React.FC<MyComponentProps>  = ({openactivities}) => {
    const strokeColor = openactivities
  return (
<svg xmlns="http://www.w3.org/2000/svg"  id="body_1" width="27" height="29"  >

<g transform="matrix(0.5869565 0 0 0.5869565 -0 0.82608604)">
	<g transform="matrix(0.1 0 -0 -0.1 0 48)">
        <path d="M84 465C 80 457 70 450 61 450C 51 450 34 441 22 430C 1 410 0 402 0 225C 0 -18 -18 0 225 0C 467 0 450 -17 450 222C 450 399 450 401 425 425C 412 439 394 450 387 450C 379 450 370 457 366 465C 363 473 356 480 350 480C 345 480 340 473 340 465C 340 454 328 450 291 450C 257 450 240 454 236 465C 233 473 226 480 220 480C 215 480 210 473 210 465C 210 454 198 450 160 450C 122 450 110 454 110 465C 110 473 105 480 100 480C 94 480 87 473 84 465zM80 405C 80 397 87 390 95 390C 103 390 110 397 110 405C 110 416 122 420 160 420C 198 420 210 416 210 405C 210 397 217 390 225 390C 233 390 240 397 240 405C 240 416 252 420 285 420C 316 420 332 415 336 405C 340 397 348 390 356 390C 364 390 370 397 370 405C 370 424 384 424 404 404C 417 391 420 360 420 221C 420 105 416 50 408 42C 391 25 59 25 42 42C 25 59 25 391 42 408C 59 425 80 423 80 405z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M153 299C 135 281 130 280 112 291C 79 312 73 292 103 262L103 262L130 235L162 268C 184 289 191 303 184 310C 178 316 167 313 153 299z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M230 275C 230 263 244 260 301 260C 353 260 371 263 368 273C 365 280 339 286 297 288C 242 290 230 288 230 275z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M148 153C 134 139 128 139 115 150C 89 172 71 146 95 120C 105 109 118 100 125 100C 137 100 190 151 190 163C 190 176 164 170 148 153z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M230 125C 230 112 242 110 297 112C 339 114 365 120 368 128C 371 137 353 140 301 140C 244 140 230 137 230 125z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
	</g>
</g>
</svg>
  );
};
// Customizable Area End
