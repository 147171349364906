// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  history: any;
  match: any;
  location: any;
}

interface S {
  loading:boolean;
  auditReportList:any;
  year:any;
  status:any;
  searchName:any;
  pagination:any;
  buildingList:any;
  selectedBuilding:any;
  auditReportModal:boolean;
  auditTitle:any;
  auditTitleError:any;
  auditBuilding:any;
  auditBuildingError:any;
  selectedFile:any;
  fileUploadError:any;
  comment:any;
  descriptionError:any;
  error:any;
  showError:boolean;
  successMessage:any;
  showSuccess:boolean;
  page:any;
  userType:any;
}

interface SS {
  id: any;
}

export default class AuditReportController extends CommonApiCallForBlockComponent<Props, S, SS> {
  upload: any;
  createAuditReportAPICallId:string = "";
  getAuditReportListAPICallId:string = "";
  getAuditorBuildingListAPICallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
        loading:false,
        auditReportList:[],
        pagination:{
            current_page:1,
            total_count:0,
            total_pages:1,
        },
        year:"",
        status:"",
        searchName:"",
        buildingList:[],
        selectedBuilding:"",
        auditReportModal:false,
        auditBuilding:"",
        auditBuildingError:"",
        auditTitle:"",
        auditTitleError:"",
        selectedFile:"",
        fileUploadError:"",
        comment:"",
        descriptionError:"",
        error:"",
        showError:false,
        successMessage:"",
        showSuccess:false,
        page:1,
        userType:localStorage.getItem("userType") || "Auditor"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

    async componentDidMount(): Promise<void> {
        const buildingId = this.props?.match?.params?.id
        this.setState({selectedBuilding:buildingId || ""})
        this.getAuditReportList({
            filterBuilding:buildingId || "",
            status:this.state.status,
            page:this.state.page,
            year:this.state.year,
            search:this.state.searchName
        })
        this.getBuildingListDropdownList()
    }

    manageSearch = () => {
        this.getAuditReportList({
            filterBuilding:this.state.selectedBuilding || "",
            status:this.state.status,
            page:this.state.page,
            year:this.state.year || this.state.searchName,
            search:this.state.searchName
        })
    }

    getAuditReportListResponse = (responseJson:any) => {
      if(responseJson.hasOwnProperty("audit_report")){
          this.setState({
              loading:false,
              auditReportList:responseJson?.audit_report?.data,
              pagination:responseJson.meta.pagination
          })
      }else{
          this.setState({
              auditReportList:[]
          })
      }
    }

    async receive(from: string, message: Message) {
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          console.log(apiRequestCallId,responseJson)
            if(this.getAuditReportListAPICallId === apiRequestCallId){
               this.getAuditReportListResponse(responseJson)
            }else if(this.getAuditorBuildingListAPICallId === apiRequestCallId){
                this.setState({
                    buildingList:responseJson.buildings
                })
            }else if(this.createAuditReportAPICallId === apiRequestCallId){
                this.setState({
                    auditReportModal:false,
                    auditTitle:"",
                    auditBuilding:"",
                    selectedFile:"",
                    comment:"",
                    successMessage:"Audit Report Created Successfully",
                    showSuccess:true
                })
                this.getAuditReportList({
                    filterBuilding:this.state.selectedBuilding,
                    status:this.state.status,
                    page:this.state.page,
                    year:this.state.year,
                    search:this.state.searchName
                })
            }
      }
  }

    getAuditReportList = async (data:any) => {
        const{filterBuilding,status,page,year,search} = data
        console.log("SEARCH",search)
        const societyID = localStorage.getItem("society_id")
        this.getAuditReportListAPICallId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyID}/bx_block_auditor/auditors?search_building=${filterBuilding}&status=${status}&page=${page}&year=${year}`,
        });
    }

    titleValidationAuditor = () => {
        if(this.state.auditTitle){
            this.setState({
                auditTitleError:""
            })
            return true
        }else{
            this.setState({
                auditTitleError:"Title Can not be empty!"
            })
            return false
        }
    }

    buildingValidation = () => {
        if(this.state.auditBuilding){
            this.setState({
                auditBuildingError:""
            })
            return true
        }else{
            this.setState({
                auditBuildingError:"Please select building!"
            })
            return false
        }
    }

    fileValidation = () => {
        // file type check accept pdf,doc,docx only
        let fileValidation = false
        if(this.state.selectedFile){
            if(this.state.selectedFile.type === "application/pdf" || this.state.selectedFile.type === "application/msword" || this.state.selectedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
                this.setState({
                    fileUploadError:""
                })
                fileValidation = true
            }else{
                this.setState({
                    fileUploadError:"Please upload pdf,doc,docx file only"
                })
                fileValidation = false
            }
        }else{
            this.setState({
                fileUploadError:"Please upload file"
            })
            fileValidation = false
        }
        return fileValidation
    }

    descriptionValidation = () => {
        let descriptionValidation = false
        if(this.state.comment){
            this.setState({
                descriptionError:""
            })
            descriptionValidation = true
        }else{
            this.setState({
                descriptionError:"Please Enter Comment for Audit report!"
            })
            descriptionValidation = false
        }
        return descriptionValidation
    }

    handleValidationAuditor = () => {
        const titleValidation = this.titleValidationAuditor()
        const buildingValidation = this.buildingValidation()
        const fileValidation = this.fileValidation()
        const descriptionValidation = this.descriptionValidation()
        if(titleValidation &&  buildingValidation && fileValidation && descriptionValidation){
            this.createAuditReport()
        }
    }

    createAuditReport(){
        let formdata = new FormData()
        // @ts-ignore
        formdata.append("audit_report[pdf]", this.state.selectedFile,this.state.selectedFile.name);
        formdata.append("audit_report[year]", this.state.auditTitle);
        formdata.append("audit_report[comments]", this.state.comment);
        formdata.append("audit_report[building_management_id]", this.state.auditBuilding);
        formdata.append("audit_report[status]","Pending");
        this.createAuditReportAPICall(formdata)
    }

    createAuditReportAPICall = async (data:any) => {
        const societyID = localStorage.getItem("society_id")
        this.createAuditReportAPICallId = await this.apiCallForFileUpload({
            method: "POST",
            endPoint: `society_managements/${societyID}/bx_block_auditor/auditors`,
            body:data
        });
    }

    getBuildingListDropdownList = async () => {
        const societyID = localStorage.getItem("society_id")
        this.getAuditorBuildingListAPICallId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `society_managements/${societyID}/bx_block_report/management_fees/building_list`,
        });
    }

    handleAuditReportPagination = (e:any,value:any) => {
        this.setState({
            page:value
        })
        this.getAuditReportList({
            filterBuilding:this.state.selectedBuilding,
            status:this.state.status,
            page:value,
            year:this.state.year,
            search:this.state.searchName
        })
    }
}
// Customizable Area End