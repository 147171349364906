// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  Button,
  IconButton,
  Select,
  MenuItem,
  Divider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  InputBase,
  Box,
  Grid,
  Backdrop,
  Fade,
  TextField,
  Modal,
} from "@material-ui/core";
import ExpenseReportController, { Props } from "./ExpenseReportController.web";
import { Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withTranslation } from "react-i18next";
import { ROLE } from "../../../framework/src/Enum";
import { ReportsStyleWeb } from "./ReportsStyle.web";
import { SearchIconImage, UploadLogo } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import { dashBoardActions, PublishButton, CloseButton } from "../../InvoiceBilling/src/chairmanUIStyles";
import AlertError from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import PaginationModule from "./PaginationModule.web";
import { withRouter } from "react-router-dom";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";

class ExpenseReport extends ExpenseReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes,i18n }: any = this.props;
    const userTypes = localStorage.getItem("userType");
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container className={classes.reportList}>
                <Box className="navigation">
                  <Box>
                    {userTypes === ROLE.AUDITOR ? (
                      <Typography variant="body1">
                        {t("Reports")} /{" "}
                        <Box className="report-ExpenseReport" component="span" style={{ color: "blue" }}>
                          {t("Expense Reports")}
                        </Box>
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {t("Documents & Reports")} /{" "}
                        <Box
                          className="report-ExpenseReport"
                          component="span"
                          onClick={() => this.props.history.push("/Reports")}
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          {t("Reports")}
                        </Box>{" "}
                        /{" "}
                        <Box className="report-ExpenseReport" component="span" style={{ color: "blue" }}>
                          {t("Expense Reports")}
                        </Box>
                      </Typography>
                    )}
                    <Typography variant="h5" className="sub-heading bold-text report-ExpenseReport" style={{ fontSize: "28px" }}>
                      {t("Expense Reports")}
                    </Typography>
                  </Box>
                </Box>
                <Box className={languageCondition(language, "arabic-grid top-bar", "top-bar expenseReport")} style={{alignItems:"flex-start"}}>
                  <Box className="filter">
                    {(this.state.userType === ROLE.MANAGER || this.state.userType=== ROLE.AUDITOR) && (
                      <Select
                        displayEmpty
                        value={this.state.filterBuilding}
                        onChange={(e: any) => this.setState({ filterBuilding: e.target.value,filterCategory:"",filterYear:"" })}
                        className="select-input AnnouncmenentCateogry expenseReportBuildingListSelect"
                      >
                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Building")}</MenuItem>
                        <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                        {this.state.buildingExpenseList?.map((item: any, key: any) => {
                          return (
                            <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    <Select
                      displayEmpty
                      className="select-input expenseReportCategoryListSelect"
                      value={this.state.filterCategory}
                      onChange={(e: any) => this.setState({ filterCategory: e.target.value,filterYear:""})}
                    >
                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Category")}</MenuItem>
                      <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      {this.state.categoryExpenseList?.map((item: any, key: any) => {
                        return (
                          <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                            {item.budget_category}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      displayEmpty
                      className="select-input"
                      value={this.state.filterYear}
                      onChange={(e: any) => this.setState({ filterYear: e.target.value })}
                    >
                      <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>{t("Select Year")}</MenuItem>
                      <MenuItem value=" " className={languageCondition(language, "select-arabic-menu", "")}>{t("All")}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 3} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 3}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 2} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 2}</MenuItem>
                      <MenuItem value={new Date().getFullYear() - 1} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear() - 1}</MenuItem>
                      <MenuItem value={new Date().getFullYear()} className={languageCondition(language, "select-arabic-menu", "")}>{new Date().getFullYear()}</MenuItem>
                    </Select>
                    <Button startIcon={<img src={SearchIconImage} />} onClick={this.handleSearch}>
                      {t("Search")}
                    </Button>
                  </Box>
                  <Box className="expense-right-heading" style={{alignItems:'flex-start'}}>
                    <Box className="filter">
                      <Select
                          displayEmpty
                          style={languageCondition(language,{paddingRight:"0px"},{paddingRight:"24px",width:"100px"})}
                          className="select-input" value={this.state.filterShort} onChange={this.handleShorting}
                      >
                        <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}>{t("Sort By")}</MenuItem>
                        <MenuItem value="asc" className={languageCondition(language, "select-arabic-menu", "")}>{t("Asc")}</MenuItem>
                        <MenuItem value="desc" className={languageCondition(language, "select-arabic-menu", "")}>{t("Desc")}</MenuItem>
                      </Select>
                    </Box>
                    {localStorage.getItem("userType") === ROLE.MANAGER && (
                      <Box className="create-meeting">
                        <Button onClick={() => this.setState({ openModal: true })}>{t("Add New Expense")}</Button>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Grid className="meeting-table">
                  <Grid item sm={12} md={12} xs={12}>
                    <Box className="table-top">
                      <h4 className={"bold-text report-ExpenseReport"}>{t("Expense Reports")}</h4>
                      <div className="search-box">
                        <SearchIcon />
                        <InputBase
                          placeholder={t("Search By Expense Number")}
                          className="search"
                          value={this.state.filterSearch}
                          onChange={this.handleExpenseSearch}
                        />
                      </div>
                    </Box>
                    <Divider />
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell align={languageCondition(language,"right","left")} className="bold-text report-ExpenseReport1" style={{ color: "#181d25", fontSize: "14px" }}>
                            {t("#")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportTitle"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Title")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportExpense"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Expense Number")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportRegisterd"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Expense Registered On")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportAmount"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Amount")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportCateogry"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Category")}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language,"right","left")}
                            className="bold-text report-ExpenseReportCateogry"
                            style={{ color: "#181d25", fontSize: "14px" }}
                          >
                            {t("Status")}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.expenseList.length > 0 ? (
                          this.state.expenseList?.map((item: any, key: any) => {
                            return (
                              <TableRow key={key}>
                                <TableCell align={languageCondition(language,"right","left")}>{key + 1}</TableCell>
                                <TableCell className="ellipse" align={languageCondition(language,"right","left")}>{item?.attributes?.title}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item.attributes.Expence_Number}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item?.attributes?.Expence_Registered_on}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>
                                  {languageCondition(language,item.attributes.currency.currency_ar,item.attributes.currency.currency)} {item?.attributes?.Amount?.toLocaleString()}
                                </TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{item?.attributes?.Category}</TableCell>
                                <TableCell align={languageCondition(language,"right","left")}>{t(item?.attributes?.status)}</TableCell>
                                <TableCell className="invoice-receipts-menu expenseReportMenu" align={languageCondition(language,"right","left")}>
                                  <Menu
                                    menuButton={
                                      <IconButton>
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  >
                                    <MenuItem onClick={() => this.props.history.push(`/ExpenseReportDetails?id=${item.id}`)}>
                                      {t("View")}
                                    </MenuItem>
                                    <MenuItem onClick={() => this.manageExpenseDownload(item.id, item.attributes.Expence_Number)}>
                                      {t("Download")}
                                    </MenuItem>
                                    <MenuItem onClick={()=>this.manageExpenseShare(item.id)}>{t("Share")}</MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell align={languageCondition(language,"right","left")} colSpan={7}>{t("No Expense Reports Available")}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box className="table-bottom">
                      <PaginationModule
                        pagination={this.state.pagination}
                        page={this.state.page}
                        handlePagination={this.handleExpenseReportPagination}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            <Modal
              style={dashBoardActions.modal}
              open={this.state.openModal}
              className="createExpenseModal"
              onClose={() => this.handleCloseModal()}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={this.state.openModal}>
                <div style={dashBoardActions.expensePaper} dir={languageCondition(language,"rtl","ltr")} className={languageCondition(language, "arabic-grid", "expenseReport")}>
                  <div style={dashBoardActions.expenseModalHeader}>
                    <Typography variant="h6" className="bold-text" style={dashBoardActions.subHeadingFont}>
                      {t("Add New Expense")}
                    </Typography>
                    <IconButton className="createExpenseCloseButton" onClick={() => this.handleCloseModal()}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Divider />
                  <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Title")}
                        variant="outlined"
                        className={languageCondition(language,"placeHolderAlignmentSpace","")}
                        name="title"
                        value={this.state.title}
                        onChange={(e) => this.setState({ title: e.target.value, titleError: "" })}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      />
                      <p style={{ color: "red" }}>{this.state.titleError}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        variant="outlined"
                        displayEmpty
                        fullWidth
                        className="select-input"
                        value={this.state.building}
                        onChange={(e) => this.setState({ building: e.target.value, buildingError: "" })}
                        style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      >
                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                          {t("Select Building")}
                        </MenuItem>
                        {this.state.buildingExpenseList?.map((item: any, key: any) => {
                          return (
                            <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <p style={{ color: "red" }}>{this.state.buildingError}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <Select
                        variant="outlined"
                        displayEmpty
                        fullWidth
                        className="select-input"
                        value={this.state.category}
                        onChange={(e) => this.setState({ category: e.target.value, categoryError: "" })}
                        style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      >
                        <MenuItem value="" disabled className={languageCondition(language, "select-arabic-menu", "")}>
                          {t("Select Category")}
                        </MenuItem>
                        {this.state.categoryExpenseList?.map((item: any, key: any) => {
                          return (
                            <MenuItem key={key} value={item.id} className={languageCondition(language, "select-arabic-menu", "")}>
                              {item.budget_category}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <p style={{ color: "red" }}>{this.state.categoryError}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Enter Amount")}
                        className={languageCondition(language,"placeHolderAlignmentSpace","")}
                        variant="outlined"
                        name="amount"
                        type="number"
                        fullWidth
                        value={this.state.amount}
                        onChange={(e) => this.setState({ amount: e.target.value, amountError: "" })}
                        inputProps={{ maxLength: 50 }}
                        style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      />
                      <p style={{ color: "red" }}>{this.state.amountError}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        onClick={() => {
                          if (!this.state.selectedFile?.name) {
                            this.upload.click();
                          }
                        }}
                        style={{
                          backgroundColor: "#F9F9F9",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "55px",
                          borderRadius: "10px",
                        }}
                      >
                        {this.state.selectedFile?.name ? (
                          <div
                            style={{
                              backgroundColor: "white",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                              borderRadius: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1" style={{ marginLeft: "10px" }}>
                              {this.state.selectedFile.name}
                            </Typography>
                            <IconButton onClick={() => this.setState({ selectedFile: {} })}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <Typography variant="body1" color="textSecondary" style={{ marginLeft: "10px" }}>
                            {t("Select Document")}
                          </Typography>
                        )}
                        <img src={UploadLogo} style={{ marginRight: "10px",marginLeft:"10px" }} />
                      </div>
                      <input
                        id="myInput"
                        type="file"
                        ref={(ref: any) => (this.upload = ref)}
                        style={{ display: "none" }}
                        accept=".docx, .pdf, .doc, .xlsx"
                        onChange={(e: any) => {
                          this.setState({ selectedFile: e.currentTarget.files[0], fileUploadError: "" });
                        }}
                        name="document"
                      />
                      <p style={{ color: "red" }}>{this.state.fileUploadError}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Description")}
                        className={languageCondition(language,"placeHolderAlignmentSpace","")}
                        variant="outlined"
                        name="Description"
                        fullWidth
                        multiline
                        value={this.state.description}
                        onChange={(e) => this.setState({ description: e.target.value, descriptionError: "" })}
                        rows={7}
                        inputProps={{ maxLength: 50 }}
                        style={{ backgroundColor: "#F9F9F9", borderRadius: "10px" }}
                      />
                      <p style={{ color: "red" }}>{this.state.descriptionError}</p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px", marginTop: "5px" }}
                    >
                      <PublishButton
                        style={{ marginRight: "10px", height: "40px" }}
                        onClick={() => this.setState({ openModal: false })}
                      >
                        {t("Cancel")}
                      </PublishButton>
                      <CloseButton style={{ height: "40px",marginRight: "10px", }} onClick={this.handleValidation}>
                        {t("Add")}
                    </CloseButton>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
        </GeneralHeader>
        <ShareDocumentModal
            isOpen={this.state.isShareModalOpen}
            handleClose={this.handleShareModalExpense}
            heading={t("Share")}
            documentURL={this.state.shareUrl}
        />
        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: null })}
          message={this.state.error}
        />
        <AlertSuccess
          show={this.state.showSuccess}
          handleClose={() => this.setState({ showSuccess: false, successMessage: "" })}
          message={this.state.successMessage}
        />
      </>
    );
  }
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ExpenseReport)));
// Customizable Area End
