export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BuildingLogo = require("../assets/building.png");
export const SortIcon = require("../assets/sort.png");
export const FilterIcon = require("../assets/filter.png");
export const CompanyIcon = require("../assets/company.png");
export const ManagerIcon = require("../assets/manager.png");
export const EmailIcon = require("../assets/email.png");
export const MobileIcon = require("../assets/mobile.png");
export const IDTypeIcon = require("../assets/idType.png");
export const IDNumberIcon = require("../assets/idNumber.png");
export const IDDateIcon = require("../assets/calender.png");
export const UploadIcon = require("../assets/upload.png");
export const EditIcon = require("../assets/edit.png");
export const DeleteIcon = require("../assets/delete.png");
export const CountryIcon = require("../assets/country.png");
export const CityIcon = require("../assets/city.png");
export const BuildingIcon = require("../assets/buildingName.png");
export const UnitIcon = require("../assets/unit.png");
export const FeeTypeIcon = require("../assets/feeType.png");
export const DownloadIcon = require("../assets/download.png");
export const PdfIcon = require("../assets/pdf.png");
export const SuccessIcon = require("../assets/success.png");
export const ReceivedIcon = require("../assets/received.png");
export const BlueCompanyIcon = require("../assets/blue-company.png");
export const BlueDateIcon = require("../assets/blue-date.png");
export const BlueEmailIcon = require("../assets/blue-email.png");
export const BlueManagerIcon = require("../assets/blue-manager.png");
export const BlueNumberIcon = require("../assets/blue-number.png");
export const BluePhoneIcon = require("../assets/blue-phone.png");
export const BlueTypeIcon = require("../assets/blue-type.png");
export const ComplexIcon = require("../assets/complex.png");
