//Customizable Area Start
import React from "react";
import DeleteRevokeMemberController, { Props } from "./CEDeleteRevokeMemberController.web";
import { withTranslation } from "react-i18next";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
export const tickImg = require("../../assets/tick-circle-icon.png");

class CEDeleteRevokeMember extends DeleteRevokeMemberController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, openModal, modalType, handleModalClose } = this.props;

    return (
      <>
        <AlertDialog
          IsOpen={openModal}
          Image={tickImg}
          CloseDialog={handleModalClose}
          Header={modalType == "delete" ? "Delete Profile" : `Revoke ${this.props.buildingComplexName} Permission`}
          Content={
            modalType == "delete"
              ? `Are you sure you want to delete ${this.props.name}' Profile? You won't be able to retrieve data once deleted`
              : `Are you sure want to revoke ${this.getType()} ${this.props.buildingComplexName}'s permission from ${this.props.name}?`
          }
          DeclineText={t("CLOSE")}
          AcceptText="CONFIRM"
          DeclineFun={handleModalClose}
          AcceptFun={this.handleDelete}
        />
      </>
    );
  }
}

export default withTranslation()(CEDeleteRevokeMember);
// Customizable Area End
