// Customizable Area Start
import React from "react";
//components
import { Box, Button, Link, Grid } from "@material-ui/core";
import { Building1, info } from "./assets";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import VeichleListController, { Props } from "./VeichleListController.web";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class EditRequest extends VeichleListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper reg-wrapper EditRequest" style={{ background: "white" }}>
          <Box
            display={{ xs: "flex", md: "none" }}
            className={languageCondition(language,"backIcon_ar","backIcon")}
            onClick={() => this.props.history.push("/veichleList")}
          >
            <ArrowBackIcon />
          </Box>
          <Grid container spacing={2} className="auth-container EditRequest" >
            <Grid item xs={12} md={7} className="auth-cols EditRequest">
              <Box className="content-block EditRequest">
                <Box className="logo-block common-top-padding EditRequest" display={{ xs: "none", md: "flex" }}>
                  <Link href="/veichleList" />
                </Box>
                <Box className="main-content-block EditRequest" style={{ height: "100%", justifyContent: "center" }}>
                  <Box className="reg-content-block EditRequest">
                    <Box className="header-block chairmanHeaderBlock EditRequest" style={{ marginTop: "0", padding: "0" }}>
                      <img src={info} className="bank-logo EditRequest" alt="Tenant Logo" />
                      <h1 className="bold-text EditRequest" style={{ fontWeight: "bold" }}>
                        {t("Edited Details")} <br /> {t("Request Sent")}
                      </h1>
                      <h6>{t("Your edited details request has been sent to manager for review. Thank You!")}</h6>
                    </Box>
                  </Box>
                </Box>
                <Box className="footer-block EditRequest">
                  <Box className="row-btn customButton desktop-ui new-vehicle-request-btn EditRequest">
                    <Button variant="contained" className="btn EditRequest" onClick={() => this.props.history.push("/VeichleList")}>
                      {t("Okay")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols EditRequest">
              <Box className="right-block EditRequest" display={{ xs: "none", md: "flex" }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}
export default withTranslation()(withRouter(EditRequest));

// Customizable Area End
