// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openleasemanagement: any;
  }
export const LeaseImageComponent : React.FC<MyComponentProps>  = ({openleasemanagement}) => {
    const strokeColor = openleasemanagement
  return (
<svg xmlns="http://www.w3.org/2000/svg"  id="body_1" width="29" height="25" className="{strokeColor}">

<g transform="matrix(0.58000004 0 0 0.58000004 -0 0.6399993)">
	<g transform="matrix(0.1 0 -0 -0.1 0 42)">
        <path d="M120 396C 120 373 116 370 85 370C 60 370 41 362 25 345C 1 322 0 316 0 185C 0 54 1 48 25 25C 49 0 50 0 250 0C 450 0 451 0 475 25C 499 48 500 54 500 185C 500 316 499 322 475 345C 459 362 440 370 415 370C 384 370 380 373 380 396C 380 427 357 424 352 392C 349 371 344 370 250 370C 156 370 151 371 148 392C 143 424 120 427 120 396zM451 330C 468 320 470 308 470 187C 470 97 466 50 458 42C 441 25 59 25 42 42C 34 50 30 97 30 187C 30 302 32 320 48 329C 71 343 425 343 451 330z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M127 245C 92 221 90 216 90 166C 90 104 95 100 175 100C 255 100 260 104 260 166C 260 216 258 221 223 245C 202 259 181 270 175 270C 169 270 148 259 127 245zM230 168L230 130L175 130L120 130L120 168C 120 198 125 209 147 222C 173 237 177 237 202 222C 225 209 230 199 230 168z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M300 215C 300 203 313 200 355 200C 397 200 410 203 410 215C 410 227 397 230 355 230C 313 230 300 227 300 215z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
        <path d="M300 156C 300 144 310 140 336 140C 381 140 378 164 332 168C 308 170 300 167 300 156z" stroke="none" fill={strokeColor} fill-rule="nonzero" />
	</g>
</g>
</svg>
  );
};
// Customizable Area End
