import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {tokenManagerP1P2} from "../ConditionManager.web";

export const apiCall = async (data: any) => {
  const { contentType, method, endPoint, body } = data;

  const header = {
    "Content-Type": contentType,
    token: tokenManagerP1P2(),
  };

  const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

  apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

  apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

  body && apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);

  apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

  runEngine.sendMessage(apiRequest.id, apiRequest);

  return apiRequest.messageId;
};

export const apiCallWithToken = async (data: any) => {
  const { contentType, method, endPoint, body } = data;

  const token: string = String(localStorage.getItem('loginSuccessToken'));

      const header:any = {
        token: token.split('"').join(''),
      }

      if (contentType) {
        header["Content-Type"]=contentType
      }

  const requestMessageNew = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessageNew.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  body && requestMessageNew.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
  );
  runEngine.sendMessage(requestMessageNew.id, requestMessageNew);
  // console.log("Called",requestMessage);
  return requestMessageNew.messageId;
};

export const CEApiCall = async (data: any) => {
  const { contentType, method, endPoint, body } = data;

  const header = {
    "Content-Type": contentType,
    token: localStorage.getItem("loginSuccessToken"),
  };

  const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

  apiRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

  apiRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

  body && apiRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);

  apiRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

  runEngine.sendMessage(apiRequest.id, apiRequest);

  return apiRequest.messageId;
};