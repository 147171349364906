// Customizable Area Start
import React from "react";
import { ArrowBack,ArrowForward } from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {ROLE} from "../../framework/src/Enum";

export const checkIdFromURL = (match:any) => {
    const param1 =  window.location.search ? window.location.search.split("=")[1] : null;
    const param2 = match?.params?.id || null
    const userType = localStorage.getItem("userType")
    if(userType === ROLE.SERVICE_PROVIDER){
        return false
    }else{
        return !param1 && !param2;
    }
}

export const getUserType = () => {
    let userType = localStorage.getItem("userType")
    if(userType === ROLE.SERVICE_PROVIDER){
        return localStorage.getItem("providerUserType")
    }else{
        return (userType)
    }
}

export const setProviderUserType = (check:any,role:any) => {
    if(check === "Service Provider"){
        localStorage.setItem("providerUserType", role)
    }
}

export const languageCondition = (language:any,if_AR:any,if_EN:any) => {
    if(language === "ar"){
        return if_AR
    }else{
        return if_EN
    }
}

export const ArrowBackIcon = ({onClick}:any) => {
    const {i18n} = useTranslation();
    const language = i18n.language;
    return(
        <>
            {languageCondition(language,<ArrowForward onClick={onClick} className="arrowBackIconEN"/>,<ArrowBack onClick={onClick} className="arrowBackIconEN"/>)}
        </>
    )
}

export const manageShowUserType = () => {
    const userType = localStorage.getItem("userType")
    if(userType === ROLE.SERVICE_PROVIDER){
        return localStorage.getItem("providerUserType")
    }else{
        return userType
    }
}

export const tokenManagerP1P2 = () => {
    const P1Token = localStorage.getItem("userToken")
    const P2Token = localStorage.getItem("loginSuccessToken")
    if(P1Token){
        return P1Token
    }else{
        return P2Token
    }
}

export const pathRedirectManager = (history:any,P1Path:any, P2path:any) => {
    const P1Token = localStorage.getItem("userToken")
    const P2Token = localStorage.getItem("loginSuccessToken")
    if(P1Token){
        history.push(P1Path)
    }else if(P2Token){
        history.push(P2path)
    }
}

// Customizable Area End