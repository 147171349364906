import { IBlock } from "../../../framework/src/IBlock";

import { Message } from "../../../framework/src/Message";

import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {

 getName

} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start

import * as Yup from 'yup';

import { RouteComponentProps } from "react-router-dom";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

// Customizable Area End
export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
 }
 
export const configJSON = require("./config");

export interface S {
 // Customizable Area Start
 lastName: string;
 email: string;
 password: string;
 firstName: string;
 otpAuthToken: string;
 reTypePassword: string;
 data: any[];
 passwordHelperText: string;
 enablePasswordField: boolean;
 enableReTypePasswordField: boolean;
 countryCodeSelected: string;
 phone: string;
 userType: string | null;
 error: any;
 loading: boolean;
 userTypeData:any;
 anchorEl :any ;
 anchorEl_1 :any ;
 getIncidentDetails : any;
 sortBy : any ;
 status : any;
 myApartmentList:any;
 upload:any;
 notImageOrVideoError:any,
 sizeError:any,
 file : any,
 commonAreaData:any,
 incidentRelatedData:any,
 incidentListing:any,
 showDialog:any,
 showError:boolean
 // Customizable Area End

}


export interface SS {

 // Customizable Area Start

 id: any;

 // Customizable Area End

}


export default class IncidentController extends BlockComponent<

 Props,

 S,

 SS

> {

 // Customizable Area Start
 createAccountApiCallId: any;
 apiupdateIncidentCallId:any;
 apicreateIncidentCallId: any;
 validationApiCallId: any;
 getIncidentListingApiCallId: any;
 getIncidentDetailsByIdApiCallId : any ;
 getCommonAreaApiCallId : any ;
 getIncidentRelatedApiCallId:any;
 getMyApartmentListApiCallId:any;
 createChatRoomAPIId:any;
 imgPasswordVisible: any;
 imgPasswordInVisible: any;
 labelHeader: any;
 labelFirstName: string;
 lastName: string;
 arrayholder: any[];
 labelEmail: string;
 labelPassword: string;
 labelRePassword: string;
 labelLegalText: string;
 labelLegalTermCondition: string;
 labelLegalPrivacyPolicy: string;
 btnTextSignUp: string;
 currentCountryCode: any;
 // Customizable Area End
 constructor(props: Props) {

   super(props);

   this.subScribedMessages = [

     getName(MessageEnum.RestAPIResponceMessage),

     getName(MessageEnum.NavigationPayLoadMessage),

     getName(MessageEnum.CountryCodeMessage)

   ];

   this.receive = this.receive.bind(this);


   runEngine.attachBuildingBlock(this, this.subScribedMessages);

 //@ts-ignore

   this.state = {

     // Customizable Area Start
     enableReTypePasswordField: true,
     countryCodeSelected: "",
     phone: "",
     userType:'',
     loading: false,
     commonAreaData:null,
     incidentRelatedData:null,
     incidentListing: null,
     anchorEl:null,
     anchorEl_1:null,
     getIncidentDetails:null,
     sortBy : "" ,
     status : "",
     myApartmentList:[],
     upload:false,
     notImageOrVideoError:false,
     sizeError:false,
     firstName: "",
     lastName: "",
     email: "",
     password: "",
     reTypePassword: "",
     otpAuthToken: "",
     data: [],
     passwordHelperText: "",
     enablePasswordField: true,
     error: null,
     file :{},
     showDialog:false,
     showError:false
     // Customizable Area End
   };
   // Customizable Area Start
   
   this.arrayholder = [];
   
   this.labelPassword = configJSON.labelPassword;
   this.labelRePassword = configJSON.labelRePassword;
   this.labelLegalText = configJSON.labelLegalText;
   this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
   this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
   this.btnTextSignUp = configJSON.btnTextSignUp;
   this.imgPasswordVisible = imgPasswordVisible;
   this.imgPasswordInVisible = imgPasswordInVisible;
   this.labelHeader = configJSON.labelHeader;
   this.labelFirstName = configJSON.labelFirstName;
   this.lastName = configJSON.lastName;
   this.labelEmail = configJSON.labelEmail;
   
   // Customizable Area End
 }
 async componentDidUpdate(prevProps: any, prevState: any)
  {
   if (
     prevState.sortBy !== this.state.sortBy ||
     prevState.status !== this.state.status
   ) {
    this.getIncidentListing(this.state.sortBy ,this.state.status)
   }

 }


 showError = () => {
   if(this.state.error){
     this.setState({
       showError:true
     }
     )
   }
 }

 redirectToDashboard = () => {
  let userType = localStorage.getItem('userType')
  if (userType == 'Owner') {
    //@ts-ignore
    //@ts-nocheck
    this.props.history.push('/OwnerDashboard')
  } else {
    //@ts-ignore
    //@ts-nocheck
    this.props.history.push('/residentDashboard')
  }

 }






 async receive(from: string, message: 
  Message) {

   // Customizable Area Start
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     const apiRequestCallId = message.getData(
       getName(MessageEnum.RestAPIResponceDataMessage)
     );
     let responseJson = message.getData(
       getName(MessageEnum.RestAPIResponceSuccessMessage)
     );
   

     if (apiRequestCallId
       && 
       responseJson) 
       {

     if (apiRequestCallId === this.apicreateIncidentCallId) {
        if (responseJson.data) {
          localStorage.setItem("createIncidentId", responseJson.data.id);
          //@ts-ignore
          this.props.history.push("/IncidentReportedSuccessfully");
        } else if (responseJson.errors) {
          let error = responseJson.errors[0];
          this.setState({ error });
        } else {
          this.setState({ error: responseJson.error || "Something went wrong!" });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      

      else if (apiRequestCallId === this.apiupdateIncidentCallId) {
        if (responseJson.data) {
          console.log("apiupdateIncidentCallId===========>", responseJson);
          //@ts-ignore
          this.props.history.push("/IncidentListing");
        } else {
          let error = responseJson.errors ? Object.values(responseJson.errors[0])[0] as string : responseJson.error || "Something went wrong!";
          this.setState({ error });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      
      
      else if (apiRequestCallId === this.getIncidentListingApiCallId) {
        if (responseJson.data) {
          this.setState({ incidentListing: responseJson.data });
        } else {
          let error = responseJson.errors ? Object.values(responseJson.errors[0])[0] as string : responseJson.error || "Something went wrong!";
          this.setState({ error });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      

      else if (apiRequestCallId === this.getIncidentDetailsByIdApiCallId) {
        if (responseJson.data) {
          console.log("getIncidentDetailsByIdApiCallId ========================>", responseJson);
          this.setState({ getIncidentDetails: responseJson.data });
          console.log("responseJson getIncidentDetails========================>", this.state.getIncidentDetails);
        } else {
          let error = responseJson.errors ? responseJson.errors[0] as string : responseJson.error || "Something went wrong!";
          //@ts-ignore
          //@ts-nocheck
          this.props.history.push("/IncidentListing");
          this.setState({ error });
        }
      
        this.showError();
        //this.parseApiCatchErrorResponse(this.state.error);
        this.setState({ loading: false });
      }
      
      else if (apiRequestCallId === this.getCommonAreaApiCallId) {
        if ( responseJson.data) {
          this.setState({ commonAreaData: responseJson.data.common_areas });
        } else {
          let error = responseJson.errors ? Object.values(responseJson.errors[0])[0] as string : responseJson.error || "Something went wrong!";
          this.setState({ error });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      

      else if (apiRequestCallId === this.getIncidentRelatedApiCallId) {
        if (responseJson.data) {
          this.setState({ incidentRelatedData: responseJson.data.incident_relateds });
        } else {
          let error = responseJson.errors ? Object.values(responseJson.errors[0])[0] as string : responseJson.error || "Something went wrong!";
          this.setState({ error });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      
      else if (apiRequestCallId === this.createChatRoomAPIId) {
        if (responseJson.data)
        {
          localStorage.setItem('selectedChat',
           JSON.stringify(responseJson.data));
          this.props.history.push('/incidentchat');
        }
        else
        {
          let error =
          responseJson.errors ? responseJson.errors[0] as string :
          responseJson.error || "Something went wrong!";
          this.setState({ error });
        }

        this.showError();
        this.setState({ loading: false });
      }
      

      else if (apiRequestCallId === this.getMyApartmentListApiCallId) {
        if (responseJson.data) {
          console.log("getMyApartmentListApiCallId========================>", responseJson);
          this.setState({ myApartmentList: responseJson.data });
        } else {
          let error = responseJson.errors ? Object.values(responseJson.errors[0])[0] as string : responseJson.error || "Something went wrong!";
          this.setState({ error });
        }
      
        this.showError();
        this.setState({ loading: false });
      }
      

     }

   }

   // Customizable Area End

 }


 // Customizable Area Start


 imgEnableRePasswordFieldProps = {

   source: imgPasswordVisible

 };


 imgEnablePasswordFieldProps = {

   source: imgPasswordVisible

 };


clear= () => {

 localStorage.clear()

 //@ts-ignore

 this.props.history.push("/");

}

onSubmit =(values:any)=>{
  localStorage.setItem("incidentPreview", JSON.stringify(values))
  console.log("onsbumit=========>", values);
    this.setState({ loading: true })
    //@ts-ignore
    this.props.history.push("/IncidentPreview")
 }
 
getIncidentDetails= (id :any) => {

  //@ts-ignore

 this.props.history.push({pathname: "/IncidentDetails",id});


 //this.getIncidentDetailsById(id)

}


confirmOrRejectIncident =(id : any,val : any)=>{

 const header = {

   token :localStorage.getItem("userToken")

 };

 const formData = new FormData();

 if(val === "confirm"){

    //@ts-ignore

   formData.append('incident[mark_resolved_by_reporter]', true);

   formData.append('incident[incident_status]', 'Closed');

 }else{

    //@ts-ignore

   formData.append('incident[mark_resolved_by_reporter]', false);

   formData.append('incident[incident_status]', 'Unresolved');

 }



const httpBody = formData;


 this.setState({loading: true})

 const requestMessage = new Message(

   getName(MessageEnum.RestAPIRequestMessage)

 );


 this.apiupdateIncidentCallId = requestMessage.messageId;

 requestMessage.addData(

   getName(MessageEnum.RestAPIResponceEndPointMessage),

   `${configJSON.updateIncident}${id}`

 );


 requestMessage.addData(

   getName(MessageEnum.RestAPIRequestHeaderMessage),

   JSON.stringify(header)

 );


 requestMessage.addData(

   getName(MessageEnum.RestAPIRequestBodyMessage),

   httpBody

 );


 requestMessage.addData(

   getName(MessageEnum.RestAPIRequestMethodMessage),

   configJSON.PatchAPiMethod

 );


 runEngine.sendMessage(requestMessage.id, requestMessage);


 return true;


}




 createIncident = async(incidentFromData: any ,incidentRelated : any) => {

 try

  {

    const header = {

     token :localStorage.getItem("userToken")

   };

 

   const formData = new FormData();

  formData.append('incident[common_area_id]', incidentFromData.commonArea.id);

  formData.append('incident[incident_related_id]', incidentRelated[0]);

  formData.append('incident[incident_title]', incidentFromData.incidentTitle);

  formData.append('incident[description]', incidentFromData.description);



  formData.append('incident[apartment_management_id]', incidentFromData.myApartment.id);


  for (const mediaItem of incidentFromData.media) {
    const blob = await fetch(mediaItem.url).then(r => r.blob());
  
    // Append the Blob
    formData.append("incident[attachments][]", blob);
  
    // Append the name separately if needed
    formData.append("incident[attachment_names][]", mediaItem.file.name);
  
    
  }
  
  const httpBody = formData;

   this.setState({loading: true})

   const requestMessage = new Message(

     getName(MessageEnum.RestAPIRequestMessage)

   );


   this.apicreateIncidentCallId = requestMessage.messageId;

   requestMessage.addData(

     getName(MessageEnum.RestAPIResponceEndPointMessage),

     configJSON.createIncident

   );


   requestMessage.addData(

     getName(MessageEnum.RestAPIRequestHeaderMessage),

     JSON.stringify(header)

   );


   requestMessage.addData(

     getName(MessageEnum.RestAPIRequestBodyMessage),

     httpBody

   );


   requestMessage.addData(

     getName(MessageEnum.RestAPIRequestMethodMessage),

     configJSON.exampleAPiMethod

   );


   runEngine.sendMessage(requestMessage.id, requestMessage);


   return true;

   }

   catch (error) {

     this.setState({loading: false})

    

   }
 };



 getIncidentListing= (sortBy : any ,status : any)  => {

   try {

     const header = {

       "Content-Type": configJSON.validationApiContentType,

       token :localStorage.getItem("userToken")

     };


     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)

     );

     this.getIncidentListingApiCallId = requestMessage.messageId;

     this.setState({ loading: true });


    const  getSortByOrStatus = `bx_block_custom_form/incidents/user_incidents?sort_type=${sortBy}&filter_by=${status}`


     requestMessage.addData(

       getName(MessageEnum.RestAPIResponceEndPointMessage),

       getSortByOrStatus

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestHeaderMessage),

       JSON.stringify(header)

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestMethodMessage),

       configJSON.validationApiMethodType

     );


     runEngine.sendMessage(requestMessage.id, requestMessage);

     return true;

   } catch (error) {

    

   }

 };


 getMyApartmentList = () => {
   try {

     const header = {

       "Content-Type": configJSON.validationApiContentType,

       token :localStorage.getItem("userToken")

     };

     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)

     );

     this.getMyApartmentListApiCallId = requestMessage.messageId;

     this.setState({ loading: true });


     requestMessage.addData(

       getName(MessageEnum.RestAPIResponceEndPointMessage),

       `account_block/my_apartments`

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestHeaderMessage),

       JSON.stringify(header)

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestMethodMessage),

       configJSON.validationApiMethodType

     );


     runEngine.sendMessage(requestMessage.id, requestMessage);

     return true;

   } catch (error) {

    

   }

 };


 getCommonArea = (society_id:any) => {

   try {

     const header = {

       "Content-Type": configJSON.validationApiContentType,

       token :localStorage.getItem("userToken")

     };


     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)

     );

     this.getCommonAreaApiCallId = requestMessage.messageId;

     this.setState({ loading: true });


     requestMessage.addData(

       getName(MessageEnum.RestAPIResponceEndPointMessage),

       `bx_block_custom_form/incidents/common_area_list?society_management_id=${society_id}`

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestHeaderMessage),

       JSON.stringify(header)

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestMethodMessage),

       configJSON.validationApiMethodType

     );


     runEngine.sendMessage(requestMessage.id, requestMessage);

     return true;

   } catch (error) {

    

   }

 };


 getIncidentRelated 
 = 
 () => 
 {

   try 
   {

     const header = {
       "Content-Type": 
       configJSON.validationApiContentType,
 token 
 :localStorage.getItem("userToken")
     };

     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)
     );

     this.getIncidentRelatedApiCallId 
     = 
     requestMessage.messageId;
     this.setState(
      { loading: true }
      );
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.incidentRelated
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.validationApiMethodType
     );
     runEngine.sendMessage(requestMessage.id, 
      requestMessage);
     return true;
   } catch (error) {
   }

 };


 getIncidentDetailsById= (id : any) => {

   try {

     const header = {

       "Content-Type": configJSON.validationApiContentType,

       token :localStorage.getItem("userToken")

     };

     //const id = localStorage.getItem("userId");

     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)

     );

     this.getIncidentDetailsByIdApiCallId = requestMessage.messageId;

     this.setState({ loading: true });


     requestMessage.addData(

       getName(MessageEnum.RestAPIResponceEndPointMessage),

       `bx_block_custom_form/incidents/${id}`

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestHeaderMessage),

       JSON.stringify(header)

     );


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestMethodMessage),

       configJSON.validationApiMethodType

     );


     runEngine.sendMessage(requestMessage.id, requestMessage);

     return true;

   } catch (error) {

    

   }

 };



 handleClick = 
 (event:any) => 
 {

   this.setState({anchorEl:event.currentTarget 
  })
 };
 handleClose = 
 (e:any, v:any) => 
 {
   let sortBy : any ;
   console.log("v=========>",v)
   if(v === undefined || v === null){
     sortBy =this.state.sortBy
   }
   else 
   {
     sortBy =v;
   }
   this.setState(
    {
      anchorEl:null,sortBy : sortBy
    }
    )
 };


 handleClick_1 = 
 (event :any) =>
  {
   this.setState(
    {anchorEl_1:event.currentTarget
    })

 };


 handleClose_1 = 
 (e:any, v:any) => 
 {

  let status : any ;

   if(v === undefined 
    ||
     v === null){

     status = this.state.status;
   }
   else {

     status =v;

   }

   this.setState(
    {anchorEl_1:null ,status :status}
    )

 };


 handleSelectMedia  =   (
   e: any,
   existingMedia: 
   any[],
   setFieldValue: (field: string, value: any, 
    shouldValidate?: boolean) => 
    void,


 ) => 
 {
   let media = [];
   let files = e.target.files;
if(files.length !== 0){

 for (let file of files) {
   media.push({

     file: {

       lastModified: file.lastModified,

       lastModifiedDate: file.lastModifiedDate,

       name: file.name,

       size: file.size,

       type: file.type

     },

     url: URL.createObjectURL(file)

   });

 }

 existingMedia = [...existingMedia, ...media];

 e.target.value = "";

 this.setState({upload: true ,sizeError : false,notImageOrVideoError:false});

 setFieldValue("media", existingMedia);

}

else {

 this.setState({upload: false,sizeError : false,notImageOrVideoError:false});

}


 };


createIncidentSchema() {

   const validations = Yup.object().shape({

     commonArea: Yup.string().required(`This field is required`).trim(),

     incidentRelated: Yup.string().required(`This field is required`).trim(),

     incidentTitle: Yup.string().required(`This field is required`).max(50, "Too Long!"),

     description: Yup.string().required(`This field is required`).max(200, "Too Long!"),

     myApartment:Yup.string().required(`This field is required`).trim(),

     //media: Yup.array()

     // .min(1, ("Atleast one image required"))

     // .required(`This field is required.`)

   });


   return validations ;

 }

 createChatRoom = async (id: any) => {

   console.log(id)


   try {

     const requestMessage = new Message(

       getName(MessageEnum.RestAPIRequestMessage)

     );

     this.createChatRoomAPIId = requestMessage.messageId;


     requestMessage.addData(

       getName(MessageEnum.RestAPIResponceEndPointMessage),

       `bx_block_chat/chats`

     );


     const header = {

       token: localStorage.getItem("userToken"),

     };


     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestHeaderMessage),

       JSON.stringify(header)

     );


     const formData = new FormData();

     formData.append("chat[chatable_type]", 'BxBlockCustomForm::Incident');

     // @ts-ignore

     formData.append("chat[chatable_id]", id);




     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestBodyMessage),

       formData

     );



     requestMessage.addData(

       getName(MessageEnum.RestAPIRequestMethodMessage),

       'POST'

     );


     runEngine.sendMessage(requestMessage.id, requestMessage);


     return true;

   } catch (error) {

   }


 }


 // Customizable Area End

}