// Customizable Area Start
import React from "react";
//components
import {Box, Button, Card, CardContent, Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
//resources

import {withRouter} from 'react-router';
import Loader from "../../../components/src/Loader.web";
import NeighboursController from "./NeighboursController.web";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//resorces
import {
    Building1,
    Building_Icon,
    Cancel_Icon,
    Chat_Disable_Icon,
    Chat_Icon,
    Contact_Disable_Icon,
    Contact_Icon,
    Email_Disable_Icon,
    Email_Msg_Icon,
    NoProfile_Img,
    Search_Icon
} from "../src/assets";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class NeighboursListing extends NeighboursController {
  componentDidMount(): any {
    this.getNeighboursListing('','')
    this.getBuilding()
  }

  render() {
    // @ts-ignore
    const {t,i18n} = this.props
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper neighbour-listing-wrapper neighborListing" dir={languageCondition(language,"rtl","ltr")}>
          <Grid container spacing={2} className={languageCondition(language, "arabic-grid auth-container", "auth-container")}>
            <Grid item xs={12} md={7} className="auth-cols inputPlaceholderRegistration neighborListing">
              <Box className="content-block common_content_block common_content_block neighborListing" style={{backgroundColor:"#f8f9fe",height:"102%"}}>
                <Box className="content-header neighborListing">
                  <Box className="left-block blocks neighborListing">
                    <ArrowBackIcon onClick={() => window.history.back()} style={{fontSize:"20px"}} />
                    <h4 style={{display: "flex",fontSize:"18px"}} className={this.state.searchOrCancel === true ? "neighbor-title bold-text neighborListing" : "bold-text neighborListing"} >{t("My Neighbours")}</h4>
                  </Box>
                  {
                     this.state.searchOrCancel === true ? 
                     <>
                     <Box className="searchTexfieldBox neighborListing">
                     <TextField
                       name="serachApartmentName"
                       className="serachApartmentName neighborListing"
                       style={{ width: '100%' }} id="outlined-multiline-static"
                       variant="outlined" placeholder={t("Search")}
                       onChange={(e) => { this.onChange(e) }} value={this.state.serachApartmentName}
                     />
                   </Box>
                   <Button className="neighborListing" onClick={this.onCancel}>
                    <img src={Cancel_Icon} className="Search_Icon neighborListing" alt="Search Icon" style={{width:"20px"}} />
                  </Button>
                   </>
                     :
                     <Button onClick={this.onSearch} className="neighborListing">
                     <img src={Search_Icon} className="Search_Icon neighborListing" alt="Search Icon" style={{width:"20px"}} />
                   </Button>
                  }
                </Box>
                <Box className="content-block-wrapper common-incident-block desktop-ui neighborListing" style={{backgroundColor:"#F7F9FE"}}>
                  <Box className="commonForm neighbour-form neighborListing" style={{paddingTop:"0px",marginTop:"5px"}}>
                      <Box className="formGroup customSelect neighborSelect neighborSelect neighborListing">
                        <FormControl variant="outlined" className="neighborListing">
                          <Select
                            displayEmpty
                            name="myApartment" labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            className="selectBuilding"
                            value={this.state.buildingListing?.length === 1 ? this.state.buildingListing[0]?.id : this.state.myApartment}
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={Building_Icon} alt="" />
                                </InputAdornment>
                            }
                            onChange={(e) => { this.onChange(e) }}
                          >
                             <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectVisitorUnit")}>
                                 {t("Select Building")}
                            </MenuItem>
                            <MenuItem value=" " className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectVisitorUnit")}>
                                {t("All")}
                            </MenuItem>
                            {
                              this.state.buildingListing.map((val:any, index : any) => (
                                <MenuItem
                                  className={languageCondition(language, "select-arabic-menu SelectComplexFacility", "SelectComplexFacility selectVisitorUnit")}
                                  key={index}
                                  value={`${val.id}`}
                                >
                                  {val.attributes.name}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Box>
                  </Box>
                  <Grid container spacing={2} className="neighborListing">
                    {
                      this.state?.neighboursListing?.map((val:any,index: any)=>(
                        <>
                            {
                                val?.attributes?.account?.attributes?.full_name?.publilc_access ?
                                    <Grid item xs={this.state?.neighboursListing.length===1? 12 : 6} md={6}  key={index} className="neighborListing" >
                                        <CardDeatils val={val.attributes} getNeighboursDetails={this.getNeighboursDetails} createChatRoom={this.createChatRoom} language={language}/>
                                    </Grid>
                                    :
                                    <Grid item xs={this.state?.neighboursListing.length===1? 12 : 6} md={6}  key={index} className="neighborListingDetails">
                                        <CardAnonymousDeatils val={val.attributes} getNeighboursDetails={this.getNeighboursDetails} t={t} language={language} />
                                    </Grid>
                            }
                        </>
                      ))
                    }
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols neighborListing">
              <Box className="right-block neighborListing" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo neighborListing" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Loader loading={this.state.loading} />
      </>
    )
  }
}
//@ts-ignore
export default  withTranslation()(withRouter(NeighboursListing))

const CardDeatils = (props:any) => {
  const val =props.val;
  const {language} = props;
  return(
   <>
      <Card className="neighbour-card neighbour-list-card card neighborListing" style={{marginTop:"0px",marginBottom:"0px"}}>
                           <CardContent>
                            <Box className="neighborListing clickCheck" onClick={()=> props.getNeighboursDetails(val?.account?.id)}>
                             <img src={val?.account?.attributes?.profile_pic?.url||NoProfile_Img} className="info-icon neighborListing" alt="No profile" style={{borderRadius:"50px"}}/>
                             <Typography component="h4" className="neighborListing">
                              {val?.account?.attributes?.full_name?.name}
                             </Typography>
                             <Box style={{height:"30px"}} className="neighborListing">
                                 {
                                     val?.account?.attributes?.apartment_number?.publilc_access &&
                                         <Typography component="h5" style={{padding:0}} className="neighborListing">
                                             {val?.apartment_management?.apartment_name}
                                         </Typography>
                                 }
                             </Box>
                             </Box>
                             <Box className="social-raw neighborListing">
                             {
                                 val?.account?.attributes?.disable_chat ?
                                 
                                 <Box className={languageCondition(language,"blocks_ar","blocks chatDisable")}>
                                 <img src={Chat_Disable_Icon} className="icons" alt="info-icon" style={{height:"16px"}}/>
                               </Box>
                                :
                                <Box className={languageCondition(language,"blocks_ar","blocks chatEnable")}>
                                  <img src={Chat_Icon} onClick={() => props.createChatRoom( val?.account?.data?.attributes?.id)} className="icons checkChat" alt="info-icon" />
                                </Box>
                               }
                               {
                                val?.account?.attributes?.full_phone_number?.publilc_access ?
                                <Box className={languageCondition(language,"blocks_ar","blocks phoneNumber")}>
                                <a href={`tel:${val?.account?.attributes?.full_phone_number?.full_phone_number}`}>
                                 <img src={Contact_Icon} className="icons" alt="info-icon" />
                                 </a>
                               </Box>
                                :
                                <Box className={languageCondition(language,"blocks_ar","blocks phoneNumberDisabled")}>
                                <img src={Contact_Disable_Icon} className="icons" alt="info-icon" />
                              </Box>
                              }
                              {
                                val?.account?.attributes?.email?.publilc_access?
                                <Box className="blocks">
                                <a  href={`mailto:${val?.account?.attributes?.email?.email}`}>
                                  <img src={Email_Msg_Icon} className="icons" alt="info-icon" />
                                  </a>
                                </Box>
                                :
                                <Box className="blocks">
                                <img src={Email_Disable_Icon} className="icons" alt="info-icon" style={{height:"16px"}}/>
                              </Box>
                              }
                              
                             </Box>
                           </CardContent>
                         </Card>
   </>
  )
}
const CardAnonymousDeatils = (props:any) => {
  const val =props.val;
  
  return(
   <>
       <Card className="neighbour-card neighbour-list-card card neighborListingAnonymous" style={{marginTop:"0px",marginBottom:"0px"}}>
                          <CardContent>
                           <Box className="neighborListingAnonymous clickCheckForAnonymos" onClick={()=>props.getNeighboursDetails(val?.account?.data?.id)}>
                            <img src={val?.account?.data?.attributes?.profile_pic?.url||NoProfile_Img} className="info-icon neighborListingAnonymous" alt="No profile" />
                            <Typography component="h4" className="neighborListingAnonymous">
                                {props.t("Anonymous")}
                            </Typography>
                            <Box style={{height:"30px"}}/>
                            {/* <Typography component="h5">
                             {val?.apartment_name}
                            </Typography> */}
                            </Box>
                            <Box className="social-raw CardAnonymousDeatils">
                              <Box className={languageCondition(props.language,"blocks_ar","blocks chatDisable")}>
                                <img src={Chat_Disable_Icon} className="icons" alt="info-icon" />
                              </Box>
                              <Box className={languageCondition(props.language,"blocks_ar","blocks contactDisable")}>
                                <img src={Contact_Disable_Icon} className="icons" alt="info-icon" />
                              </Box>
                              <Box className="blocks CardAnonymousDeatils">
                                <img src={Email_Disable_Icon} className="icons" alt="info-icon" />
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
   </>
  )
}

// Customizable Area End