// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ComplexIcon = require("../assets/complex.png");
export const Document = require("../assets/document.png");
export const UploadImage = require("../assets/upload.png");
export const DeleteImage = require("../assets/delete.png");
export const ShareImage = require("../assets/share.png");
export const DownloadImage = require("../assets/download.png");
export const PdfImage = require("../assets/pdf.png");
export const BuildingLogo = require("../assets/building.png");
export const NoPdf = require("../assets/no-pdf.png");
export const BackIcon = require("../assets/back-icon.png");
export const DocIcon = require("../assets/image_docicon.png");
export const Menu = require("../assets/image_menu.png");
export const DeleteIcon = require("../assets/delete.png");
export const BitMapIcon = require("../assets/image_Bitmap.png");
// Customizable Area End
