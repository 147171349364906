// Customizable Area Start
import React from "react";
//components
import {
  Container,
  Typography,
  Link,
  Card,
  CardContent,
  Button,
  Dialog,
  withStyles,
  TextareaAutosize,
  DialogTitle,
} from "@material-ui/core";
import '../../dashboard/src/Dashboard.web.css';
import Box from '@material-ui/core/Box';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ClassifiedManagerController, { Props } from "./ClassifiedManagerContorller.web";
import { Close_Icon, Classified_CorrectIcon } from "./assets";
import { withTranslation } from 'react-i18next';
import CloseIcon from "@material-ui/icons/Close";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
class ClassifiedManagerDetail extends ClassifiedManagerController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount(): any {
    const id = localStorage.getItem("classifiedManagerDetailId");
    if (id)
      this.getClassifiedDetailsById(id);
    else
      this.props.history.push("/ClassifiedManagerListing");
  }
  render() {
    const { t, classes }: any = this.props;
    // @ts-ignore
    const {language} = this.props.i18n;
    const attributes = this.state?.getClassifiedDetails?.attributes;

    return (
      <>
        <GeneralHeader>
              <Container className="incident-Listing-wrapper desktop-ui">
                <Box className={classes.navigation}>
                  <Box>
                    <Typography variant="body1" >
                      <Link href="DashboardGeneral" color="inherit"> {t("My Dashboard")}</Link> /
                      <Link href="DashboardGeneral" color="inherit"> {t("General Dashboard")}</Link> /
                      <Link href="ClassifiedManagerListing" color="inherit"> {t("Classifieds")}</Link> /
                      <Box component="span" style={{ color: "#2c6fed" }}>
                        <Link href="ClassifiedManagerDetail" color="inherit">  {t("Classified Details")}</Link>
                      </Box>
                    </Typography>
                    <Typography variant="h5" className={`bold-text ${classes.subHeading} classiffiedDetails`} style={{ fontSize: "32px" }}>{t("Classified Details")}</Typography>
                  </Box>
                </Box>
                <Box className="content-block-wrapper incident-detail-card-block">
                  <Card className="incident-detail-card card">
                    <Box className="card-header">
                      <Typography component="h4" style={{ fontSize: "22px" }} className="bold-text classifiedDetailsTitle">
                        {attributes?.title}
                      </Typography>
                      <Box className="customButton">
                        <Button variant="contained" className={attributes?.classified_status === 'Pending Approval' ? "contain warning" : attributes?.classified_status === 'Published' ? 'contain success' : 'contain danger'} type="submit">
                          {t(attributes?.classified_status)}</Button>
                      </Box>
                    </Box>
                    <CardContent className="card-content">
                      <Box className="row-block">
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsType" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Type")}:
                          </Typography>
                          <h4 className="bold-text">{t(checkName(attributes))}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsMobile" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Mobile Number")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.full_phone_number}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsMobile" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Requester Name")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.published_by?.full_name}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsMobile" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Unit Number")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.Unit_number}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsEmail" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Email ID")}:
                          </Typography>
                          <h4 className="bold-text" style={{ textTransform: "lowercase" }}>{attributes?.email}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span classifiedDetailsTtitle" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Title")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.title}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("Description")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.description}</h4>
                        </Box>
                        <ClassifiedDetailsConditions attributes={attributes} t={t} language={language}/>
                        <Box className="card-rows">
                          <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("From")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.duration_from}</h4>
                        </Box>
                        <Box className="card-rows">
                          <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
                            {t("To")}:
                          </Typography>
                          <h4 className="bold-text">{attributes?.duration_to}</h4>
                        </Box>
                      </Box>
                      {attributes?.attachments.length !== 0 ?
                        <>
                          <Box className="card-rows">
                            <Typography className="title-span" component="span">
                              {t("Photos")}:
                            </Typography>
                          </Box>
                          <Box className="card-img-row photos-row" style={{ overflowX: "hidden" }}>
                            {
                              attributes?.attachments?.map((val: any, index: any) => (
                                val?.content_type === "video/mp4" || val?.content_type === "video/x-m4v" ?
                                  <Box className="video-img" key={index} data-test-id="imageShowDialogss"
                                  onClick={() => { this.setState({ imageShowDialog: true, file: { url: val.url, type: val?.content_type, name: val?.file_name } }); 
                                  }}
                                  >
                                    <Box className="img-layer"></Box>
                                    <video className="incident-dialog-video"  >
                                      <source src={val?.url} type={val?.file?.type} />
                                    </video>
                                    <PlayCircleOutlineIcon className="play-icon" />
                                  </Box>
                                  :
                                  <Box className="video-img" key={index} 
                                  data-test-id="imageShowDialogs"
                                  onClick={() => { this.setState({ imageShowDialog: true, file: { url: val?.url, type: val?.content_type, name: val?.file_name } }); }}
                                  >
                                    <Box className="img-layer"></Box>
                                    <img src={val?.url} className="card-img" alt="card-img" />
                                    <FullscreenIcon className="play-icon" />
                                  </Box>
                              ))
                            }
                          </Box>
                        </>
                        : null
                      }
                    </CardContent>
                  </Card>
                </Box>
                {
                  attributes?.classified_status === 'Published' ?
                      <Box className="incident-button-row customButton">
                        <Box className="outline-danger">
                          <Button variant="outlined" style={{ width: "190px", height: "55px", borderRadius: "10px" }}
                             data-test-id="UnpublishedShowDialog"     onClick={() => this.setState({ UnpublishedShowDialog: true })}
                          >{t("UnPublish")}</Button>
                        </Box>
                      </Box>
                      :
                      <>
                        {attributes?.classified_status === 'Rejected' ?
                            <Box className="incident-button-row customButton">
                              {/* danger button */}
                              <Box className="outline-danger">
                                <Button variant="outlined" style={{ width: "190px", height: "55px" }}
                                 data-test-id="ignoreShowDialog"       onClick={() => this.setState({ ignoreShowDialog: true })}
                                >{t("Ignore")}</Button>
                              </Box>
                              <Button variant="contained" style={{ width: "190px", height: "55px", borderRadius: "10px" }}
                                 data-test-id="publish"      onClick={() => this.setState({ statusShowDialog: true })}
                              >{t("Publish")}</Button>
                            </Box>
                            :
                            <Box className="incident-button-row customButton">
                              {/* danger button */}
                              <Box className="outline-danger">
                                <Button variant="outlined" style={{ width: "190px", height: "55px" }}
                                       onClick={() => this.setState({ ignoreShowDialog: true })}
                                >{t("Ignore")}</Button>
                              </Box>
                              <Button variant="outlined" style={{ width: "190px", height: "55px" }}
                                      onClick={() => this.setState({ showDialog: true })}
                              >{t("Reject")}</Button>
                              <Button variant="contained" style={{ width: "190px", height: "55px", borderRadius: "10px" }}
                                      onClick={() => this.setState({ statusShowDialog: true })}
                              >{t("Publish")}</Button>
                            </Box>
                        }
                      </>
                }
              </Container>
              {/* view reject dialog */}
              <Dialog
                open={this.state.showDialog}
                data-test-id="showDialog"  
                onClose={() => this.setState({ showDialog: false, addNote: null })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                dir={languageCondition(language,"rtl","ltr")}
                className="diloag-wrapper"
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                  },
                }}
              >
                <Box className="provider-dialouge-body classified-dialouge-body desktop-ui">
                  <Box className="dialouge-header">
                    <DialogTitle className="alert-dialog-title bold-text" id="alert-dialog-title">
                      {t("Reject Classified Request")}
                    </DialogTitle>
                    <Button 
                     data-test-id="ignoreShowDialogs"  
                    onClick={() => { this.setState({ showDialog: false, addNote: null }); }}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                  <Box className="diloag-content">
                    <Box className="commonForm">
                      <Box className="formGroup textarea">
                        <TextareaAutosize
                         data-test-id="addNote"  
                          name="addNote"
                          maxRows={20}
                          aria-label="maximum height"
                          placeholder={t("Add Notes")}
                          onChange={(e: any) => this.onChange(e)}
                          value={this.state.addNote}
                        />
                      </Box>
                    </Box>
                    <Box className="customButton" style={{ paddingTop: "30px" }}>
                      <Button variant="outlined" style={{ width: "160px", height: "43px", marginRight: "10px" }}
                      data-test-id="customButton"    onClick={() => { this.setState({ showDialog: false, addNote: null }); }}
                      >{t("Cancel")}
                      </Button>
                      <Button variant="contained" style={{ width: "160px", height: "43px" }} 
                      onClick={() => this.rejectedOrPublished("Rejected")}
                      >{t("Confirm")}</Button>
                    </Box>
                  </Box>
                </Box>
              </Dialog>
              {/* view large image dialog */}
              <Dialog
              data-test-id="imageShowDialog1"
                open={this.state.imageShowDialog}
                onClose={() => this.setState({ imageShowDialog: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="diloag-wrapper"
                dir={languageCondition(language,"rtl","ltr")}
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                  },
                }}
              >
                <Box className="diloag-body">
                  <Box className="diloag-header">
                    <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                      {this.state?.file?.name}
                    </DialogTitle>
                    <Button 
                   data-test-id="imageShowDialog2" onClick={() => { this.setState({ imageShowDialog: false }); }}
                    >
                      <img src={Close_Icon} className="close-icon" />
                    </Button>
                  </Box>
                  <Box className="diloag-content">
                    {
                      this.state?.file?.type === "video/mp4" || this.state?.file?.type === "video/x-m4v" ?
                        <video className="incident-dialog-video" controls >
                          <source src={this.state?.file?.url} type={this.state?.file?.type} />
                        </video>
                        :
                        <Box>
                          <img src={this.state?.file?.url} className="incident-dialog-photo" alt="card-img" />
                        </Box>
                    }
                  </Box>
                </Box>
              </Dialog>
              {/* view status dialog */}
              <Dialog
                open={this.state?.statusShowDialog}
                data-test-id="statusShowDialog1"
                onClose={() => this.setState({ statusShowDialog: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="diloag-wrapper"
                dir={languageCondition(language,"rtl","ltr")}
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                    width: "500px"
                  },
                }}
              >
                <Box className="diloag-body classified-dialouge-body desktop-ui ">
                  <Box className="diloag-header classified-header">
                  </Box>
                  <Box className="diloag-content classified-content diloag-management-content">
                    <img src={Classified_CorrectIcon} className="lock-logo" alt="Lock_Icon" />
                    <h3 className="bold-text">{t("Approve Classified Request")}</h3>
                    <p className="lead">{t("Are you sure you want to publish this classified")}?</p>
                    <Box className="diloag-btn customButton">
                      <Button variant="outlined" style={{ width: "180px", height: "55px" }} 
                     data-test-id="statusShowDialog3" onClick={() => { this.setState({ statusShowDialog: false }); }}
                      >{t("Close")}</Button>
                      <Button variant="contained" style={{ width: "180px", height: "55px" }}
                       onClick={() => this.rejectedOrPublished("Published")}
                       >{t("Confirm")}</Button>
                    </Box>
                  </Box>
                </Box>
              </Dialog>
              {/* view ignore status dialog */}
              <Dialog
                open={this.state?.ignoreShowDialog}
                data-test-id="diloagWrapper"
                onClose={() => this.setState({ ignoreShowDialog: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="diloag-wrapper"
                dir={languageCondition(language,"rtl","ltr")}
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                    width: "500px"
                  },
                }}
              >
                <Box className="diloag-body classified-dialouge-body desktop-ui ">
                  <Box className="diloag-header classified-header">
                  </Box>
                  <Box className="diloag-content classified-content diloag-management-content">
                    <img src={Classified_CorrectIcon} className="lock-logo" alt="Lock_Icon" />
                    <h3 className="bold-text">{t("Ignore Classified Request")}</h3>
                    <p className="lead">{t("Are you sure you want to ignore this classified")}?</p>
                    <Box className="diloag-btn customButton">
                      <Button variant="outlined" className="ignoreCancel" style={{ width: "180px", height: "55px" }}
                      data-test-id="ignoreCancel" onClick={() => { this.setState({ ignoreShowDialog: false }); }}
                       >{t("Close")}</Button>
                      <Button variant="contained" className="ignoreConfirm" style={{ width: "180px", height: "55px" }}
                       onClick={() => this.rejectedOrPublished("Ignore")}
                       >{t("Confirm")}</Button>
                    </Box>
                  </Box>
                </Box>
              </Dialog>

              {/* view UnpublishedShowDialog status dialog */}
              <Dialog
              data-test-id="UnpublishedShowDialog"
                open={this.state?.UnpublishedShowDialog}
                onClose={() => this.setState({ UnpublishedShowDialog: false })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                dir={languageCondition(language,"rtl","ltr")}
                className="diloag-wrapper"
                PaperProps={{
                  style: {
                    borderRadius: '15px',
                    width: "500px"
                  },
                }}
              >
                <Box className="diloag-body classified-dialouge-body desktop-ui ">
                  <Box className="diloag-header classified-header">
                  </Box>
                  <Box className="diloag-content classified-content diloag-management-content">
                    <img src={Classified_CorrectIcon} className="lock-logo" alt="Lock_Icon" />
                    <h3 className="bold-text">{t("Unpublish Classified Request")}</h3>
                    <p className="lead">{t("Are you sure you want to Unpublish this classified")}?</p>
                    <Box className="diloag-btn customButton">
                      <Button variant="outlined" className="unpublishedCancel" style={{ width: "180px", height: "55px" }} 
                      data-test-id="UnpublishedShowDialog1" onClick={() => { this.setState({ UnpublishedShowDialog: false }); }}
                      >{t("Close")}</Button>
                      <Button variant="contained" className="unpublishedConfirm" style={{ width: "180px", height: "55px" }} 
                      onClick={() => this.rejectedOrPublished("Unpublished")}
                      >{t("Confirm")}</Button>
                    </Box>
                  </Box>
                </Box>
              </Dialog>
        </GeneralHeader>
        {/* <IncidentChatDrawer /> */}
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const checkName = (attributes: any) => {
  let type = "";
  if (attributes?.classified_type === "buyer") {
    type = "Buy";
  } else {
    if (attributes?.classified_type === "seller") {
      type = "Sell";
    } else {
      type = "Generic";
    }
  }
  return type;
};

const ClassifiedDetailsConditions = (props: any) => {
  const { attributes, t ,language} = props;
  return (
    <>
      {
        attributes?.classified_type === "generic" ?
          <Box className="card-rows">
            <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
              {t("Payment Details")}:
            </Typography>
            <h4 className="bold-text">{attributes?.payment_detail} {languageCondition(language,attributes.currency?.currency_ar,attributes.currency?.currency)}</h4>
          </Box>
          : null
      }
      {
        attributes?.classified_type === "seller" ?
          <Box className="card-rows">
            <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
              {t("Price")}:
            </Typography>
            <h4 className="bold-text">{attributes?.price} {languageCondition(language,attributes.currency?.currency_ar,attributes.currency?.currency)}</h4>
          </Box>
          : null
      }
      {
        attributes?.classified_type === "buyer" ?
          <Box className="card-rows">
            <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
              {t("Price")}:
            </Typography>
            <h4 className="bold-text">{attributes.price_from} {languageCondition(language,attributes.currency?.currency_ar,attributes.currency?.currency)} {t("to")} {attributes?.price_to} {languageCondition(language,attributes.currency?.currency_ar,attributes.currency?.currency)}</h4>
          </Box>
          : null
      }

      {
        attributes?.classified_type === "generic" ?
          <Box className="card-rows">
            <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
              {t("From Time")}:
            </Typography>
            <h4 className="bold-text">{attributes?.time_from}</h4>
          </Box>
          :
          null
      }
      {
        attributes?.classified_type === "generic" ?
          <Box className="card-rows">
            <Typography className="title-span" component="span" style={{ fontSize: "16px", color: "#181D25" }}>
              {t("To Time")}:
            </Typography>
            <h4 className="bold-text">{attributes?.time_to}</h4>
          </Box>
          :
          null
      }
    </>
  );
};
const dashBoard: any = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  bottomColor: {
    color: "red"
  },
  bottomTwoSpan: {
    display: "flex",
    gap: 20,
    marginTop: 10
  },
  Cards: {
    paddingTop: 30,
    paddingLeft: 15,
    paddingBottom: 25,
    background: "#fff",
    borderRadius: 10,
  },
  CardsIcons: {
    border: "1px solid #d9d4d3",
    borderRadius: "50%",
    width: 25,
    height: 25,
    padding: 15,
    color: "#054c94",
  },
  EventsHeading: {
    fontWeight: 600,
    marginTop: 50,
  },
  EventsCards: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    background: "#fff",
    borderRadius: 10,
  },
  EventsTitle: {
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
  },
  EventsIconsText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginTop: 15,
    fontSize: 14,
  },
  EventsIconsData: {
    display: "flex",
    alignItems: "center",
    gap: 25,
    marginTop: 15,
  },
  EventsIconsDataBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  PricePaid: {
    marginRight: 70,
    background: "#dcf5f0",
    padding: 6,
    borderRadius: 30,
    color: "green",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
};

// Customizable Area End

export default withTranslation()(withStyles(dashBoard)(withRouter(ClassifiedManagerDetail)));
export {ClassifiedManagerDetail}