import React from "react";

// Customizable Area Start
import { Button, Card, CardContent, CardMedia, Container, IconButton, Paper, Typography } from "@material-ui/core";
import "../../dashboard/src/Dashboard.web.css";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { withRouter } from "react-router";
import UserDetailedProfileController, { Props } from "./UserDetailedProfileController.web";
import { withTranslation } from "react-i18next";
import { call_org, chat, delete_icon, email_org, facebook, instagram, snap, twitter_org, userIcon } from "./assets";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ModalComponent from "../../../components/src/ModalComponent.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

const FullName = (props: any) => {
  const { name } = props;
  return <>{name || "N/A"}</>;
};

const SocialMedia = (props: any) => {
  const { profileDetails } = props;
  return (
    <>
      {profileDetails?.attributes?.website[0].twitter_link && (
        <Button href={profileDetails?.attributes?.website[0].twitter_link} target="_blank">
          <img src={twitter_org} className="icon" alt="FB_Icon" />
        </Button>
      )}
      {profileDetails?.attributes?.website[1].instagram_link && (
        <Button href={profileDetails?.attributes?.website[1].instagram_link} target="_blank">
          <img src={instagram} className="icon" alt="FB_Icon" />
        </Button>
      )}
      {profileDetails?.attributes?.website[2].fb_link && (
        <Button href={profileDetails?.attributes?.website[2].fb_link} target="_blank">
          <img src={facebook} className="icon" alt="FB_Icon" />
        </Button>
      )}
      {profileDetails?.attributes?.website[3].snapchat_link && (
        <Button href={profileDetails?.attributes?.website[3].snapchat_link} target="_blank">
          <img src={snap} className="icon" alt="FB_Icon" />
        </Button>
      )}
    </>
  );
};
// Customizable Area End

class AdminUserDetailedProfile extends UserDetailedProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;
    // @ts-ignore
    let profileDetails = JSON.parse(localStorage.getItem("selectedPofile"));
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <GeneralHeaderComponentWeb>
          <Container>
            <Box style={dashBoard.navigation}>
              <Box>
                <Typography variant="body1">
                  {t("Community Management")} /{" "}
                  <Box
                    className="communityManagementUserProfilesDetailsPage"
                    component="span"
                    onClick={() => this.props.history.push("/CommunityUserProfile")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("User Profiles")}
                  </Box>{" "}
                  /{" "}
                  <Box component="span" className="userNamefortheDetailsPage" style={{ color: "blue" }}>
                    <FullName name={profileDetails?.attributes?.full_name?.name} />
                  </Box>
                </Typography>
                <Typography variant="h5" className="bold-text fullNameForUserDetails" style={dashBoard.subHeading}>
                  <FullName name={profileDetails?.attributes?.full_name?.name} />
                </Typography>
              </Box>
            </Box>

            {/* GA MEMBERS -- */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6" className="bold-text" style={dashBoard.Headings}>
                    {t("General Details")}
                  </Typography>
                  <Button
                    variant="contained"
                    className="bold-text admin-profile-suspend-btn"
                    style={{ background: "rgb(45, 110, 237)", color: "white", fontWeight: "bolder" }}
                    onClick={() => this.setState({ suspendModal: true })}
                  >
                    {t("Suspend User")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <Paper>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    style={{
                      borderRight: languageCondition(language, "0", "1px solid #979797"),
                      borderLeft: languageCondition(language, "1px solid #979797", "0"),
                    }}
                  >
                    <Card style={dashBoard.cardStyle}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={profileDetails?.attributes?.profile_pic?.url || userIcon}
                        alt="green iguana"
                        style={dashBoard.profileImage}
                      />
                      <CardContent style={{ padding: "0px 16px 16px 16px" }}>
                        <Typography
                          variant="h6"
                          className="bold-text"
                          //@ts-ignore
                          style={dashBoard.unitno}
                        >
                          <FullName name={profileDetails?.attributes?.full_name?.name} />
                        </Typography>
                        <Typography variant="h6" style={{ marginTop: "5px" }}>
                          {" "}
                          {profileDetails?.attributes?.apartment_number?.apartment_number || "N/A"}{" "}
                        </Typography>
                        <Grid container spacing={1} style={{ marginTop: "5px" }}>
                          {profileDetails?.attributes?.role.map((item: any) => {
                            return (
                              <Grid item className={"ProfileDetailsForUserList"} key={item}>
                                <Typography
                                  variant="h6"
                                  className="TypeoftheuserforUserDetails bold-text"
                                  style={dashBoard.userType}
                                >
                                  {item.name}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: "5px", gap: "1rem" }}>
                          <Grid item xs={2} sm={2}>
                            <img
                              className="admin-profile-chat"
                              src={chat}
                              style={{ width: "40px" }}
                              onClick={() => this.openChat(profileDetails?.id)}
                            />
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <img
                            className="admin-profile-call"
                              src={call_org}
                              style={{ width: "40px" }}
                              onClick={() =>
                                (window.location.href = `tel:${profileDetails?.attributes?.full_phone_number?.full_phone_number}`)
                              }
                            />
                          </Grid>

                          <Grid item xs={2} sm={2}>
                            <img
                            className="admin-profile-email"
                              src={email_org}
                              style={{ width: "40px" }}
                              onClick={() =>
                                (window.location.href = `mailto:${profileDetails?.attributes?.email?.email}`)
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={8} style={{ padding: "35px 25px 25px 35px" }}>
                    <Box>
                      <Typography variant="subtitle1" style={dashBoard.subtitleClr}>
                        {t("About")}
                      </Typography>
                      <Typography variant="subtitle1">{profileDetails?.attributes?.bio?.bio} </Typography>
                      <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={2} sm={3}>
                          <Typography variant="subtitle1" style={dashBoard.subtitleClr}>
                            {t("Gender")}
                          </Typography>
                          <Typography variant="subtitle1">
                            {profileDetails?.attributes?.gender?.gender || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={3}>
                          <Typography variant="subtitle1" style={dashBoard.subtitleClr}>
                            {t("DOB")}
                          </Typography>
                          <Typography variant="subtitle1">
                            {moment(profileDetails?.attributes?.date_of_birth?.date_of_birth).format("DD MMM YYYY") ||
                              "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={3}>
                          <Typography variant="subtitle1" style={dashBoard.subtitleClr}>
                            {t("Hobbies")}
                          </Typography>

                          <Typography variant="subtitle1">
                            {profileDetails?.attributes?.hobbies?.hobbies !== null &&
                              profileDetails?.attributes?.hobbies?.hobbies.map((item: any) => <>{item} ,</>)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="subtitle1" style={{ ...dashBoard.subtitleClr, marginTop: "10px" }}>
                        {t("Social Media")}
                      </Typography>
                      <Grid container spacing={3} style={{ marginTop: "5px" }}>
                        <SocialMedia profileDetails={profileDetails} />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            {/* Related People -- */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid item xs={6}>
                  <Typography variant="h6" className="bold-text" style={{ ...dashBoard.subHeading, marginTop: "40px" }}>
                    {t("Related People")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <div style={dashBoard.gaMemberCard}>
                {profileDetails?.attributes?.families?.families ? (
                  profileDetails?.attributes?.families?.families.map((item: any, index: any) => (
                    <>
                      <div key={index}>
                        <Card style={dashBoard.cardStyleRelated}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={item?.attributes?.member_pic?.url || userIcon}
                            alt="green iguana"
                            style={dashBoard.relatedprofileImage}
                          />
                          <CardContent style={{ padding: "0px 16px 16px 16px" }}>
                            <span
                              className="admin-profile-more-item"
                              style={{ position: "absolute", right: "10px", top: "10px" }}
                              onClick={(e: any) => this.handleMoreClick(e)}
                            >
                              {item.more}
                            </span>
                            <Typography
                              variant="h6"
                              style={{ textAlign: "center", marginTop: "5px", fontSize: "18px" }}
                            >
                              {item?.attributes?.name}
                            </Typography>
                            <div style={{ textAlign: "center", marginTop: "10px 0px 15px 0px" }}>
                              <Typography variant="h6" style={dashBoard.userType}>
                                {item?.attributes?.relation?.name}
                              </Typography>
                            </div>
                            <div style={dashBoard.contactIcon}>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </>
                  ))
                ) : (
                  <>{t("No related people are added")}</>
                )}
              </div>
            </Box>

            <Box style={{ marginTop: "10px" }}>
              <div style={dashBoard.gaMemberCard}>
              </div>
            </Box>
            
            {/* Related Units -- */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid item xs={6}>
                  <Typography variant="h6" className="bold-text" style={dashBoard.subHeading}>
                    {t("Related Units")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "10px 0px 50px" }} className="admin-user-user-card">
              <div style={dashBoard.gaMemberCard}>
                <>
                  {profileDetails?.attributes?.related_units.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <Card style={dashBoard.cardStyle} className="admin-user-card">
                          <CardContent>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <Box style={{ width: "90%" }}>
                                <Typography
                                  variant="h6"
                                  className={"bold-text"}
                                  //@ts-ignore
                                  style={{ ...dashBoard.relatedUnit, fontSize: "18px" }}
                                >
                                  {item.building_name}
                                  {" Unit "}
                                  {item.unit_number}
                                </Typography>
                              </Box>
                              <Box style={{ width: "10%" }} className="admin-profile-de-link-menu">
                                <Menu
                                  menuButton={
                                    <IconButton style={{ padding: "0" }}>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                >
                                  <MenuItem
                                  className="admin-profile-menu-deLink"
                                    onClick={() => this.setState({ delinkModal: true, delinkUnitId: item.unit_id })}
                                  >
                                    {t("De-link user from unit")}
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Box>
                            <div style={{ marginTop: "5px" }}>
                              <Typography variant="h6" style={dashBoard.userType}>
                                {item.user_role[0] || "NA"}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
                </>
              </div>
            </Box>
            {/* Active Incidents */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid item xs={6}>
                  <Typography variant="h6" className="bold-text" style={dashBoard.subHeading}>
                    {t("Active Incidents")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "10px 0px 50px" }}>
              <div style={dashBoard.gaActiveMemberCard}>
                <>
                  {profileDetails?.attributes?.incidents.length > 0 ? (
                    profileDetails?.attributes?.incidents.map((item: any, index: any) => {
                      return (
                        <div key={index}>
                          <Card style={dashBoard.activeMembercardStyle}>
                            <CardContent>
                              <div style={dashBoard.facility}>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  {item.incident_title}
                                </Typography>
                                <Typography variant="h6" style={dashBoard.userType}>
                                  {item.incident_status}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Affected Area")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item?.affected_area || "N/A"}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Incident is related to")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.incident_related_to}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Reported on")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {moment(item.reported_on).format("MMMM DD,YYYY")}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Building")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.building_name}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6">{t("Unit")}:</Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontWeight: 600 }}>
                                  {" "}
                                  &nbsp; {item.unit_number}
                                </Typography>
                              </div>
                              <Typography
                                variant="h6"
                                className="bold-text"
                                //@ts-ignore
                                style={dashBoard.unitno}
                              >
                                {item.building}
                                {item.unitno}
                              </Typography>
                              <div style={{ marginTop: "5px" }}>
                                {/* <Typography variant="h6" style={dashBoard.userType}>{item.userType}</Typography> */}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <>No incident has been added by this user</>
                  )}
                </>
              </div>
            </Box>

            {/* Vehicle Details */}
            <Box>
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid item xs={6}>
                  <Typography variant="h6" className="bold-text" style={dashBoard.subHeading}>
                    {t("Vehicle Details")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "10px 0px 50px" }} className="admin-user-vehicle-box">
              <div style={dashBoard.gaActiveMemberCard}>
                <>
                  {profileDetails?.attributes?.vehicles !== null ? (
                    profileDetails?.attributes?.vehicles.map((item: any, index: any) => {
                      return (
                        <div key={index} className="admin-user-vehicle-card">
                          <Card style={dashBoard.activeMembercardStyle}>
                            <CardContent>
                              <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                {" "}
                                {item.attributes?.company_name}
                              </Typography>
                              <img
                                src={item.attributes?.car_manufacturer?.attributes?.image?.url}
                                style={{ margin: "5px 0px 5px 0px", height: "50px" }}
                              />
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Owner Name:")}
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.attributes?.owner_name}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Registration Card Number")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.attributes?.plate_number}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Car Details")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.attributes?.car_manufacturer.attributes.title}{" "}
                                  {item.attributes?.car_model?.title} ({item?.attributes?.car_color?.title}){" "}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Building")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.attributes?.building_management?.name}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Unit")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.attributes?.apartment_management?.apartment_name}
                                </Typography>
                              </div>
                              <Typography
                                variant="h6"
                                //@ts-ignore
                                style={{ ...dashBoard.unitno, fontSize: "18px" }}
                              >
                                {item.building}
                                {item.unitno}
                              </Typography>
                              <div style={{ marginTop: "5px" }}>
                                {/* <Typography variant="h6" style={dashBoard.userType}>{item.userType}</Typography> */}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <> No Vehicle has been Added by this user</>
                  )}
                </>
              </div>
            </Box>

            {/* Unanswered Suggestion */}
            <Box className="admin-suggestion-heading-box">
              <Grid container style={dashBoard.gaMemberMain}>
                <Grid item xs={6}>
                  <Typography variant="h6" className="bold-text" style={dashBoard.subHeading}>
                    {t("Unanswered Suggestion")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ margin: "10px 0px 50px" }} className="admin-suggestion-box">
              <div style={dashBoard.gaActiveMemberCard}>
                <>
                  {profileDetails?.attributes?.suggestions.length > 0 ? (
                    profileDetails?.attributes?.suggestions?.map((item: any, index: any) => {
                      return (
                        <div key={index} className="admin-suggestion-card-box">
                          <Card style={dashBoard.activeMembercardStyle}>
                            <CardContent>
                              <div style={dashBoard.facility}>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  {item.title}
                                </Typography>
                                <Typography variant="h6" style={dashBoard.userType}>
                                  {item.status === "unresolved" ? "Unanswered" : item.status}
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Related to")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {item.related_to}
                                </Typography>
                              </div>
                              <div>
                                <Typography variant="h6">
                                  {t("Desc")}: <span style={{ fontSize: "18px" }}> {item.description} </span>
                                </Typography>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Typography variant="h6" style={{ fontSize: "18px" }}>
                                  {t("Sent on")}:
                                </Typography>
                                <Typography variant="h6" className="bold-text" style={{ fontSize: "18px" }}>
                                  {" "}
                                  &nbsp; {moment(item.created_at).format("DD MMM YYYY hh:mm a")}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      );
                    })
                  ) : (
                    <> {t("No Suggestion has been Added by this user")}</>
                  )}
                </>
              </div>
            </Box>
            <ModalComponent
              language={language}
              logo={delete_icon}
              heading={"Suspend User"}
              isOpen={this.state.suspendModal}
              message={"Are you sure you want to Suspend the user?"}
              confirmButton={"Submit"}
              cancelButton={"Cancel"}
              closeFunction={() => this.setState({ suspendModal: false, delinkUnitId: "" })}
              confirmFunction={() => this.suspendUserApiCall()}
            />
            <ModalComponent
              language={language}
              logo={delete_icon}
              heading={"De-Link Unit"}
              isOpen={this.state.delinkModal}
              message={"Are you sure you want to Delink this Unit?"}
              confirmButton={"Submit"}
              cancelButton={"Cancel"}
              closeFunction={() => this.setState({ delinkModal: false })}
              confirmFunction={() => this.delinkUserApiCall()}
            />
          </Container>
        </GeneralHeaderComponentWeb>
        {/* <Loader loading={this.state.loading} /> */}
      </>
      // Customizable Area End
    );
  }
}

//@ts-ignore
export default withTranslation()(withRouter(AdminUserDetailedProfile));

// Customizable Area Start
const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: "22px",
    marginTop: 15,
  },
  Headings: {
    fontWeight: 600,
    margin: "5px 0px 10px 0px",
  },
  cardBottom: {
    display: "flex",
    gap: 20,
    marginTop: 10,
  },
  facility: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
  gaMemberMain: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 0,
    justifyContent: "space-between",
  },
  viewMore: {
    marginTop: 15,
    textDecoration: "underline",
    color: "#E5B08D",
    fontWeight: 600,
  },

  gaMemberCard: {
    display: "grid",
    gridTemplateColumns: "4fr 4fr 4fr",
    gap: 20,
  },

  relatedMemberCard: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr",
    gap: 20,
  },
  gaActiveMemberCard: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr",
    gap: 20,
  },
  profileImage: {
    borderRadius: "100%",
    width: 90,
    height: 90,
    margin: "5px auto auto 20px",
  },
  relatedprofileImage: {
    borderRadius: "100%",
    width: 70,
    height: 70,
    margin: "35px auto",
  },
  userType: {
    backgroundColor: "aliceblue",
    borderRadius: 30,
    fontSize: 14,
    display: "inline-block",
    padding: "5px 10px",
    color: "#2D6EED",
    fontWeight: 600,
    marginTop: 5,
  },
  unitno: {
    marginTop: 15,
    fontWeight: 600,
    textAlign: "left",
  },
  relatedUnit: {
    fontWeight: 600,
    textAlign: "left",
  },
  relatedunitno: {
    marginTop: 15,
    fontWeight: 600,
    textAlign: "center",
  },
  contactIcon: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "left",
    marginTop: 15,
  },
  cardStyle: {
    borderRadius: 10,
    maxWidth: "100%",
    boxShadow: "none",
    padding: "0px 0px 0px 0px",
  },
  cardStyleRelated: {
    borderRadius: 10,
    maxWidth: "300px",
    boxShadow: "none",
    padding: "0px 0px 0px 0px",
  },
  activeMembercardStyle: {
    borderRadius: 10,
    maxWidth: 600,
    boxShadow: "none",
    padding: "0px 20px 0px 0px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  subtitleClr: {
    color: "#181D25",
    fontSize: "15px",
    opacity: "0.5",
  },
};
// Customizable Area End
