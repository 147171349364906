// Customizable Area Start
import * as React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { LanguageAttributeHandler } from "../UtilFunction.web";

const InvoiceCard = ({ language, date, status, tenant, building, unit, currency, amount, type, cardType, t }: any) => {
  return (
    <Box
      className="my-invoice-tenant-list"
      display="flex"
      borderRadius="8px"
      style={{ boxShadow: "4px 0px 14px #e9e9e9" }}
      bgcolor="white"
      alignItems="center"
      padding="20px"
      justifyContent="space-between"
    >
      <Box style={{ minWidth: "100%" }}>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant={"subtitle2"}>
            {t("Invoice")} : {moment(date, "DD-MM-YYYY").format("MMMM YYYY")}
          </Typography>
          <Typography variant="subtitle2" className={status}>
            {t(status?.split("_").join(" "))}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ marginTop: "15px" }}>
            <Typography variant={"subtitle2"}>{t(cardType)}</Typography>
            <Typography variant={"body1"} style={{ marginTop: "5px" }} className="bold-text my-invoice-tenant-value">
              {tenant}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "15px" }}>
            <Typography variant={"subtitle2"}>{t("Building Name")}</Typography>
            <Typography variant={"body1"} style={{ marginTop: "5px" }} className="bold-text my-invoice-building-value">
              {building}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"}>{t("Unit Number")}</Typography>
            <Typography variant={"body1"} style={{ marginTop: "5px" }} className="bold-text my-invoice-unit-value">
              {unit}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"}>{t("Amount")}</Typography>
            <Typography variant={"body1"} style={{ marginTop: "5px" }} className="bold-text my-invoice-amount-value">
              {LanguageAttributeHandler(language, currency.currency, currency.currency_ar)} {Number(Number(amount).toFixed(2)).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <Typography variant={"subtitle2"}>{t("Invoice Type")}</Typography>
            <Typography variant={"body1"} style={{ marginTop: "5px" }} className="bold-text my-invoice-invoice-value">
              {t(type?.split("_").join(" "))}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InvoiceCard;
// Customizable Area End
