// Customizable Area Start
import React from "react";
import "./Polling.web.css"
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import RichTextEditor from "react-rte";
import {
    Container,
    Typography,
    TextField,
    Input,
    InputAdornment,
    Button, IconButton, withStyles,
} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
// import { DateRangePicker, DateRange, Calendar  } from 'react-date-range';
import 'date-fns';
// Icons
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import InfoIcon from '@material-ui/icons/Info';
// Icons

import PollingController, {
    Props,
    configJSON,
} from "./PollingController";

import {withRouter} from "react-router-dom";
import TextEditor from "./TextEditor.web";
import Loader from "../../../components/src/Loader.web";
import {withTranslation} from 'react-i18next';

import '../../../web/src/i18n.js';
import BackspaceIcon from '@material-ui/icons/Backspace';
import AlertError from "../../../components/src/AlertError.web";
import {languageCondition,pathRedirectManager} from "../../../components/src/ConditionManager.web";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import moment from "moment";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";

class CreatePoll extends PollingController {
    constructor(props: Props) {
        super(props);

    }

    // @ts-ignore
    componentDidMount() {
        // @ts-ignore
        const PreviewPollData = JSON.parse(localStorage.getItem('Polls_Data'));
        if (PreviewPollData) {
            this.setState({
                PollData: PreviewPollData.PollFormData,
                options: PreviewPollData.PollOptions,
                checked: PreviewPollData.PollType,
                textEditorVal: PreviewPollData.PollDescription
            })
        }
    }

    render() {
        // @ts-ignore
        const {t} = this.props
        // @ts-ignore
        const language = this.props.i18n.language
        return (
            <>
                <GeneralHeader>
                    <PollMainContainer polls={this} t={t} language={language}/>
                </GeneralHeader>
                <Loader loading={this.state.loading}/>
                <AlertError show={this.state.showError} handleClose={() => this.setState({showError: false})}
                            message={this.state.error}/>
            </>
        );
    }
}

//@ts-ignore
export const CreatePolls = withTranslation()(withRouter(CreatePoll));


class CECreatePolls extends PollingController {
    constructor(props: Props) {
        super(props);
    }

    // @ts-ignore
    componentDidMount() {
        // @ts-ignore
        const CEPreviewPollData = JSON.parse(localStorage.getItem('Polls_Data'));
        if (CEPreviewPollData) {
            this.setState({
                    PollData: CEPreviewPollData.PollFormData,
                    options: CEPreviewPollData.PollOptions,
                    checked: CEPreviewPollData.PollType,
                    textEditorVal: CEPreviewPollData.PollDescription
                }
            )
        }
    }

    render() {
        // @ts-ignore
        const {t} = this.props
        // @ts-ignore
        const language = this.props.i18n.language
        return (
            <>
                <MainScreen>
                    <div style={{marginTop:"30px",minHeight:"90vh"}}>
                        <PollMainContainer polls={this} t={t} language={language}/>
                    </div>
                </MainScreen>
                <Loader loading={this.state.loading}/>
                <AlertError show={this.state.showError} handleClose={() => this.setState({showError: false})}
                            message={this.state.error}/>
            </>
        );
    }
}

//@ts-ignore
export const CECreatePoll = withTranslation()(withRouter(CECreatePolls));


const PollMainContainer = (props: any) => {
    // @ts-ignore
    const {polls, t, language} = props
    return (
        <Container>
            <Box className="navigation createPolls">
                <Box>
                    <Typography variant="body1">
                        <Box component="span" className="NavigationManager"
                             onClick={() => pathRedirectManager(polls.props.history,"/Polling","/CompanyEmployee/CEPollSurvey")} style={{cursor: "pointer"}}>
                            {t("Poll and survey")}
                        </Box>{" "}/ <Box component="span" style={{color: "blue"}}>{t("Create a Poll")}</Box>
                    </Typography>
                    <Typography variant="h5" className="subHeading bold-text"
                                style={{fontSize: "30px"}}>{t("Create a Poll")}</Typography>
                </Box>
            </Box>
            <form>
                <Grid container spacing={4} style={{paddingTop: 0}}>
                    <Grid item sm={12} md={12} xs={12}>
                        <Box className="createPSCards" style={{paddingBottom: "15px"}}>
                            <TextField label={t("Name of the Poll")} variant="outlined"
                                       className={languageCondition(language, "placeHolderAlignment", "")}
                                       name="title"

                                       value={polls.state.PollData.title}
                                       onChange={polls.handlePollDataChange}
                                       fullWidth
                                       inputProps={{maxLength: 100}}
                            />
                            <p style={{color: "red"}}>{t(polls.state.pollTitleError)}</p>
                            <Box className="DateSection">
                                <Box style={{width: "100%"}}>
                                    <TextField
                                        label={t("Start Date")}
                                        placeholder={t("Start Date")}
                                        className="dateInputBox startDateBox"
                                        variant="outlined"
                                        style={{width: "100%"}}
                                        name="startDate" fullWidth
                                        // @ts-ignore
                                        format='DD/MM/YYYY'
                                        value={polls.state.PollData.startDate}
                                        onChange={polls.handlePollDataChange}
                                        inputProps={{
                                            min: moment().format("YYYY-MM-DD"),
                                            //@ts-ignore
                                            max: "5000-05-31",
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DateRangeOutlinedIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        type={polls.state.inputType1}
                                        onFocus={() => polls.setState({inputType1: "date"})}
                                    />
                                    <p style={{color: "red"}}>{t(polls.state.pollDateError)}</p>
                                </Box>
                                <Box style={{width: "100%"}}>
                                    <TextField label={t("End Date")}
                                               placeholder={t("End Date")}
                                               className="dateInputBox endDateBox"
                                               variant="outlined"
                                               name="endDate" fullWidth
                                               style={{width: "100%"}}
                                               value={polls.state.PollData.endDate}
                                               onChange={polls.handlePollDataChange}
                                               inputProps={{
                                                   //@ts-ignore
                                                   min: polls.state.PollData.startDate,
                                                   //@ts-ignore
                                                   max: "5000-05-31",
                                               }}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <DateRangeOutlinedIcon/>
                                                       </InputAdornment>
                                                   ),
                                               }}
                                               type={polls.state.inputType2}
                                               onFocus={() => polls.setState({inputType2: "date"})}
                                    />
                                    <p style={{color: "red"}}>{t(polls.state.pollEndDateError)}</p>
                                </Box>
                            </Box>
                            {/*<p style={{color:"red"}}>{this.state.pollDateError}</p>*/}

                            <Box style={{width: "100%"}}>
                                <Box className="infoIcon">
                                    <Typography variant="subtitle1">{t("Description")}</Typography>
                                    <InfoIcon style={{color: "grey", fontSize: 18}}/>
                                </Box>
                                <Box className="descriptionEditor" style={{maxHeight: "200px", overflow: "hidden"}}>
                                    <TextEditor
                                        // @ts-ignore
                                        markup={polls.state.textEditorVal}
                                        onChange={polls.onChangeTextEditor}/>
                                </Box>
                                <p style={{color: "red"}}>{t(polls.state.pollDescriptionError)}</p>
                            </Box>
                            <Box className="anonymousSwitch">
                                <Box className="infoIcon">
                                    <Typography variant="subtitle1">{t("Make it anonymous poll")}</Typography>
                                    <InfoIcon style={{color: "grey", fontSize: 18}}/>
                                </Box>
                                <Box style={{float: "right"}}>
                                    <Switch
                                        className="anonymousSwitchBtn"
                                        checked={polls.state.checked}
                                        onClick={(event: any) =>
                                            polls.setState({checked: event.target.checked})
                                        }
                                        value="checked"
                                        color="primary"
                                        name="checked"
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item sm={12} md={12} xs={12} style={{marginTop: "70px"}}>
                        <Box className="createPSCards">
                            <TextField label={t("enter question")} variant="outlined"
                                       className={languageCondition(language, "placeHolderAlignment", "")}
                                       name="question"
                                       value={polls.state.PollData.question}
                                // @ts-ignore
                                       onChange={polls.handlePollDataChange}
                                       fullWidth
                                       inputProps={{maxLength: 100}}
                            />
                            <p style={{color: "red"}}>{t(polls.state.pollQuestionError)}</p>
                            {polls.state.options.map((inputfield: any, index: any) => {
                                return (
                                    <Box display='flex' alignItems="center">
                                        <Box style={{width: "95%"}}>
                                            <TextField key={index}
                                                       className={languageCondition(language, "placeHolderAlignment changeTextBoxOption", "changeTextBoxOption")}
                                                       label={t("Option") + " - " + (index + 1)} variant="outlined"
                                                       name="text"
                                                // @ts-ignore
                                                       value={inputfield.text}
                                                       onChange={(event) => polls.handleOptionsChange(index, event)}
                                                       fullWidth style={{marginTop: 20}}
                                            />
                                            <p style={{color: "red"}}>{t(inputfield.error)}</p>
                                        </Box>
                                        <Box style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}
                                             dir={language === "en" ? "ltr" : "rtl"}>
                                            <IconButton style={{marginTop: "15px", marginLeft: "5px"}}
                                                        className="deleteOptionButton"
                                                        onClick={() => polls.deleteOption(index)}>
                                                <BackspaceIcon fontSize="large" style={{color: "red"}}
                                                               className={languageCondition(language, "removeButtonTransformAr", "")}/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )
                            })
                            }
                            <p style={{color: "red"}}>{t(polls.state.pollOptionasError)}</p>

                            <Button variant="outlined" color="primary"
                                    onClick={() => polls.addOptionsFields()}
                                    className="addOptions">{t("ADD OPTION")}</Button>
                        </Box>
                    </Grid>

                </Grid>

                <Box className="BottomButton" style={{marginTop: "45px"}}>
                    <Box className="Previewbtn">
                        <RejectButton variant="contained" color="primary" className="previewPollButton"
                                      onClick={async () => {
                                          await polls.handlePriviewData()
                                      }}
                        >{t("PREVIEW")}</RejectButton>
                    </Box>
                    <Box className="Publishbtn">
                        <ApproveButton variant="outlined" color="primary"
                                       className="onSubmitPoll" onClick={() => polls.handlePollDataSubmit(false)}
                        >{t("PUBLISH")}</ApproveButton>
                    </Box>
                </Box>
            </form>
        </Container>
    )
}
const RejectButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        borderRadius:"8px",
        fontWeight: "bold",
        height: "55px",
        width: "140px",
        "&:hover": {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const ApproveButton = withStyles((theme) => ({
    root: {
        color: "#2b6fed",
        backgroundColor: "white",
        borderRadius:"8px",
        fontWeight: "bold",
        height: "55px",
        width: "140px",
        border: "#2B6FED 1px solid",
    },
}))(Button);

// Customizable Area End
