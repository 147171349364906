import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import { RouteComponentProps } from "react-router-dom";
import * as React from "react";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";
export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)",
};

const options = {
  title: "Select Image",
  storageOptions: {
    skipBackup: true,
    path: "images",
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  postSellerDetailsMessageId: any;
  sellerID: any;
  shopName: string;
  address: string;
  gstin: string;
  selectedServices: number[];
  showSuccessModal: boolean;
  token: string;
  lat: any;
  long: any;
  error: string | null;
  loading: boolean;
  allVehcile: any[];
  showDialog: boolean;
  lodaing: boolean;

  manufactureList: any[],
  modalList: any[],
  colorList: any[];
  complexList:any[];
  selectedComplex:any[];

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VeichleListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createVehicleApiCallId: string = '';
  updateVehicleApiCallId: string = '';
  getVehicleListApiCallId: string = '';
  deleteVehicleAPICallId: string = '';
  GetCarManufactureListCallId: string = '';
  GetCarModalListCallId: string = '';
  GetCarColorListCallId: string = '';
  getComplexListId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      getSellerDetailsMessageId: "",
      postSellerDetailsMessageId: "",
      sellerID: "",
      shopName: "",
      address: "",
      gstin: "",
      selectedServices: [],
      token: "",
      showSuccessModal: false,
      lat: 0,
      long: 0,
      error: null,
      loading: false,
      allVehcile: [],
      showDialog: false,
      lodaing: false,


      manufactureList: [],
      modalList: [],
      colorList: [],
      complexList:[],
      selectedComplex:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.state.postSellerDetailsMessageId:
            this.showModal();
            break;
          case this.createVehicleApiCallId:
            this.handleCreateVehicleResponse(responseJson);
            break;
          case this.getVehicleListApiCallId:
            this.handleGetVehicleListResponse(responseJson);
            break;
          case this.deleteVehicleAPICallId:
            this.handleDeleteVehicleResponse(responseJson);
            break;
          case this.updateVehicleApiCallId:
            this.handleUpdateVehicleResponse(responseJson);
            break;
          case this.GetCarManufactureListCallId:
            this.handleGetManufactureListResponse(responseJson);
            break;
          case this.GetCarModalListCallId:
            this.handleGetCarModelListResponse(responseJson);
            break;
          case this.GetCarColorListCallId:
            this.handleGetCarColorListResponse(responseJson);
            break;
            case this.getComplexListId:
              this.getcomplexListResponse(responseJson)
              break;
          default:
            break;
        }
      }
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetCarColorListResponse = (responseJson: any) => {
    if (responseJson.car_color) {
      this.setState({ colorList: responseJson.car_color });
    }
  };

  initialValues = () => {
    // @ts-ignore
    let item: any = JSON.parse(localStorage.getItem("selectCar"));
    if (item) {
      const arrayComplex = item.attributes.complex_lists.map((item:any) => {
        return (item.id)
      })
      console.log("COMPLEX LIST" ,item.attributes.complex_lists,arrayComplex)
      return {
        full_name: item?.attributes?.owner_name || "",
        plateNumber: item?.attributes?.plate_number || "",
        carManufacturer: item?.attributes?.car_manufacturer?.id || "",
        carModle: item.attributes?.car_model?.id || "",
        carColor: item.attributes?.car_color?.id || "",
        bannerUrl: item.attributes?.registration_card_copy?.url || "",
        banner: "",
        selectedComplex:arrayComplex,
      }
    }else{
     return  {
        full_name:"",
        plateNumber:"",
        carManufacturer:"",
        carModle:"",
        carColor:"",
        bannerUrl:"",
        banner: "",
        selectedComplex:[],
      }
    }
  }

  vehicleCreate = (values:any) => {
    // @ts-ignore
    let item: any = JSON.parse(localStorage.getItem("selectCar"));
    console.log("ITEM",item)
    if (item) {
      this.updateVehicle(values)
    } else {
       this.createVehicle(values)
    }
  }
  getComplexList = async () => {
    const userType = localStorage.getItem("userType")
    this.getComplexListId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,
    });
  }

  getcomplexListResponse = (responseJson: any) => {
    if (responseJson.complex) {
      this.setState({ complexList: responseJson.complex })
    }
  }

  removeItem(arr:any, value:any) {
    let index = arr.indexOf(value);
    if (index > -1) {arr.splice(index, 1)}
    return arr;
  }

  handleChecked (value:any,setFieldValue:any,selectedComplex:any) {
    if(this.state.selectedComplex.find((item:any)=> item === value)){
      let updatedArray = this.removeItem(this.state.selectedComplex,value)
      this.setState({
        selectedComplex:updatedArray
      })
      console.log("CHECK THE STATUS",updatedArray)
      setFieldValue("selectedComplex",updatedArray)
    }else{
      this.setState({selectedComplex:[
          ...this.state.selectedComplex,
          value
        ]})
        console.log("CHECK" ,[...selectedComplex,value])
        setFieldValue("selectedComplex",[...selectedComplex,value])
    }
  }

  handleGetCarModelListResponse = (responseJson: any) => {
    if (responseJson.car_model) {
      this.setState({ modalList: responseJson.car_model });
    }
  };

  handleGetManufactureListResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ manufactureList: responseJson.data }, () => {
        // @ts-ignore
        let carDetails: any = JSON.parse(localStorage.getItem("selectCar"));
        if (carDetails) {
          let car: any = this.state.manufactureList.filter((car) => car.id == carDetails.attributes.car_manufacturer.id);
          this.getCarModalList(car[0].id);
        }
      });
    }
  };

  handleUpdateVehicleResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      console.log(responseJson);
      //@ts-ignore
      //@ts-nocheck
      localStorage.removeItem('selectCar');
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/editRequest');
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleDeleteVehicleResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      console.log(responseJson);
      //@ts-ignore
      //@ts-nocheck
      localStorage.removeItem('selectCar');
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/veichleList');
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  };

  handleGetVehicleListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (!responseJson.errors && !Array.isArray(responseJson.vehicle)) {
        this.setState({ allVehcile: responseJson.vehicle.data });
      }
    });
  };

  handleCreateVehicleResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ lodaing: false });
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/NewRequest');
    } else if (responseJson?.errors) {
      let error = responseJson.errors[0];
      this.setState({ error });
    } else {
      this.setState({ error: responseJson?.error || "Something went wrong!" });
      this.parseApiCatchErrorResponse(this.state.error);
    }
    this.setState({ loading: false });
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  labelShopName: string = "Name of Shop";
  labelAddress: string = "Address";
  labelGstin: string = "GSTIN";
  labelAbout: string = "About Us";
  labelServiceProvided: string = "Services Provided";
  labelDealsIn: string = "Deals in";
  labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
  labelVisitingCard: string = "Upload Visiting Card";
  btnLabel: string = "Submit";
  btnContinueLabel: string = "Continue";

  services = [
    { label: "Wholesaler", value: 1 },
    { label: "Manufacturer", value: 2 },
    { label: "Hallmarker", value: 3 },
    { label: "Retailer", value: 4 },
  ];

  onChangeInput = (name: string, value: any) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  txtInputProps = (key: string) => ({
    // @ts-ignore
    value: this.state[key],
    onChangeText: (text: string) => {
      this.onChangeInput(key, text);
    },
  });

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16,
  };

  showModal = () => {
    this.setState({ showSuccessModal: true });
  };

  hideModal = () => {
    this.setState({ showSuccessModal: false }, () => {
    });
  };

  addVehicleSchema() {
    const validations = Yup.object().shape({
      // @ts-ignore
      full_name: Yup.string().required(`This field is required.`).trim().matches("^[a-zA-Z\u0600-\u06FF,-\s ][\s\a-zA-Z\u0600-\u06FF ,-]*$", "Only alphabets are allowed for this field."),
      plateNumber: Yup.string().required(`This field is required.`).trim(),
      carManufacturer: Yup.string().required(`This field is required.`).trim().matches(/^[\w\s/\-,]+$/, "Only certain characters are allowed"),
      carModle: Yup.string().required(`This field is required.`).trim(),
      carColor: Yup.string().required(`This field is required.`).trim(),
      banner: Yup.mixed(),
      bannerUrl: Yup.string().nullable(true).required(`Please upload registration card copy.`)

    });
    return validations;
  }

  createVehicle = async (values: any) => {
    this.setState({ lodaing: true });

    try {
      const header = {
        token: localStorage.getItem("userToken")
      };
      const formData = new FormData();
      console.log("SELECTED COMPLEX",values.selectedComplex)
      formData.append("vehicle[owner_name]", values.full_name);
      formData.append("vehicle[plate_number]", values.plateNumber);
      formData.append("vehicle[car_manufacturer_id]", values.carManufacturer);
      formData.append("vehicle[car_model_id]", values.carModle);
      formData.append("vehicle[car_color_id]", values.carColor);
      formData.append("vehicle[society_ids]", JSON.stringify(values.selectedComplex));
      let blob = await fetch(values.bannerUrl).then(r => r.blob());
      formData.append(
        "vehicle[registration_card_copy]",
        blob
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createVehicleApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiCreateVehicle
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } catch (error) {
      console.log(error);
    }
  };

  updateVehicle = async (values: any) => {
    this.setState({ loading: true });
    console.log(values);
    //@ts-ignore
    //@ts-nocheck
    let item = JSON.parse(localStorage.getItem('selectCar'));
    try {
      const header = {

        token: localStorage.getItem("userToken")
      };
      const formData = new FormData();
      formData.append("vehicle[owner_name]", values.full_name);
      formData.append("vehicle[plate_number]", values.plateNumber);
      formData.append("vehicle[company_name]", values.carManufacturer);
      formData.append("vehicle[model_number]", values.carModle);
      formData.append("vehicle[color]", values.carColor);
      formData.append("vehicle[society_ids]", values.selectedComplex);
      let blob = await fetch(values.bannerUrl).then(r => r.blob());
      formData.append(
        "vehicle[registration_card_copy]",
        blob
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateVehicleApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiCreateVehicle}/${item.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } catch (error) {
      // this.setState({ loading: false })
      console.log(error);
    }

  };
  handleSelectBanner = (
    e: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    setFieldError: (field: string, message: string) => void,
  ) => {

    let file = e?.target?.files[0];

    if (file && !['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
      return setFieldError('banner', 'Only png and jpeg are supported.');
    }

    setFieldValue("banner", file ? {
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      type: file.type
    } : '');
    console.log('file', URL.createObjectURL(file));
    setFieldValue("bannerUrl", file ? URL.createObjectURL(file) : "");
    if (file) {
      e.target.value = "";

    }

  };
  getVehicle() {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const society_id=localStorage.getItem("society_id")

    this.getVehicleListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_vehicle/vehicles?society_management_id=${society_id}&role=${localStorage.getItem('userType')}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addVehicle(item: any) {
    console.log(item);
    localStorage.setItem('selectCar', JSON.stringify(item));
    // @ts-nocheck
    // @ts-ignore
    this.props.history.push('/viewVehicle');

  }
  getCar() {
    // @ts-nocheck
    // @ts-ignore
    let item = JSON.parse(localStorage.getItem('selectCar'));
    if (item) {

    }
  }
  checkVehicle() {
    console.log(this.state.allVehcile.length);
    if (this.state.allVehcile.length < 5) {
      // @ts-nocheck
      // @ts-ignore
      this.props.history.push("/newVeichleList");
    } else {
      // @ts-nocheck
      // @ts-ignore
      this.setState({ showDialog: true }, () => console.log(this.state));
    }

  }
  deleteRequest() {
    this.setState({ loading: true });
    // @ts-nocheck
    // @ts-ignore
    let item = JSON.parse(localStorage.getItem('selectCar'));
    const header = {

      "token": localStorage.getItem('userToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.deleteVehicleAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_vehicle/vehicles/${item.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  redirectToDashboard = () => {
    let userType = localStorage.getItem('userType');
    if (userType == 'Owner') {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/OwnerDashboard');
    } else {
      //@ts-ignore
      //@ts-nocheck
      this.props.history.push('/residentDashboard');
    }

  };

  getCarManufactureList = async () => {
    this.GetCarManufactureListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/car_manufacturer`,
    });
  };

  getCarModalList = async (id: any) => {
    this.GetCarModalListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/car_model?id=${id}`,
    });
  };

  getCarColorList = async () => {
    this.GetCarColorListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_settings/car_color`,
    });
  };

  handleFormError = (errors: any, touched: any, name: any) => {
    if (errors && touched) {
      return (
        <Typography
          style={{
            color: "#F14E24",
            fontWeight: 300,
            fontSize: 14,
            marginTop: 5,
            marginLeft: 10,
          }}
        >
          <ErrorMessage className="text-error" component="Typography" name={name} />
        </Typography>
      );
    }
  };
  // Customizable Area End
}
