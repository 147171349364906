import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface ComplexData {
  logo: any;
  complexName: string;
  city: string;
  aboutUs: string;
  photos: any[];
  complexArea: string;
  measurement_unit: string;
  totalUnits: string;
  totalBuilding: number;
  buildingList: any[];
  sharedAreaList: any[];
  lat: string;
  long: string;
}
interface S {
  // Customizable Area Start
  complexId: any;
  complexDetails: any;
  complexData: ComplexData;

  managementTeam: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OwnerComplexController extends BlockComponent<Props, S, SS> {
  GetComplexDetailsCallId: any;
  GetManagementTeamCallId: any;
  CreateTeamChatCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      // Customizable Area Start
      complexId: "",
      complexDetails: null,
      complexData: {
        logo: null,
        complexName: "",
        city: "",
        aboutUs: "",
        photos: [],
        complexArea: "",
        measurement_unit: "",
        totalUnits: "",
        totalBuilding: 0,
        buildingList: [],
        sharedAreaList: [],
        lat: "",
        long: "",
      },

      managementTeam: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      switch (apiRequestCallId) {
        case this.GetComplexDetailsCallId:
          this.handleComplexDetailsResponse(responseJson);
          break;
        case this.GetManagementTeamCallId:
          this.handleManagementTeamResponse(responseJson);
          break;
        case this.CreateTeamChatCallId:
          this.handleCreateTeamResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  slider: any;

  async componentDidMount(): Promise<void> {
    const complex_id = this.props.navigation.getParam("id");
    this.setState({ complexId: complex_id }, () => {
      this.getComplexDetails();
      this.getManagementTeam();
    });
  }

  // Get Complex Details API
  getComplexDetails = async () => {
    this.GetComplexDetailsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements/${this.state.complexId}`,
    });
  };

  handleComplexDetailsResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        complexDetails: responseJson.data,
        complexData: {
          ...this.state.complexData,
          logo: responseJson.data.attributes.logo && responseJson.data.attributes.logo.url,
          complexName: responseJson.data.attributes.name,
          city: responseJson.data.attributes.city,
          aboutUs: responseJson.data.attributes.description,
          photos: responseJson.data.attributes.photos,
          complexArea: responseJson.data.attributes.complex_area,
          measurement_unit: responseJson.data.attributes.measurement_unit,
          totalUnits: responseJson.data.attributes.total_units && responseJson.data.attributes.total_units[0],
          totalBuilding: responseJson.data.attributes.total_buildings,
          buildingList: responseJson.data.attributes.building_list,
          sharedAreaList: responseJson.data.attributes.shared_area,
          lat: responseJson.data.attributes.lat,
          long: responseJson.data.attributes.long,
        },
      });
    }
  };

  getManagementTeam = async () => {
    this.GetManagementTeamCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/owner_dashbords/management_user_list?society_management_id=${this.state.complexId}`,
    });
  };

  handleManagementTeamResponse = (responseJson: any) => {
    if (responseJson && responseJson.management_user_list) {
      this.setState({ managementTeam: responseJson.management_user_list });
    }
  };

  createTeamChatRoom = async (id: any) => {
    const formData = new FormData();
    formData.append("chat[chatable_type]", "AccountBlock::Account");
    formData.append("chat[chatable_id]", localStorage.getItem("userId") || "");
    formData.append("chat[chat_with_account]", id);

    this.CreateTeamChatCallId = await apiCall({
      method: "POST",
      endPoint: `bx_block_chat/chats`,
      body: formData,
    });
  };

  handleCreateTeamResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      localStorage.setItem("selectedChat", JSON.stringify(responseJson.data));
      this.props.navigation.navigate("Chatbox");
    }
  };
  // Customizable Area End
}
