// Customizable Area Start
import React from "react";
import {
  Avatar,
  Button,
  Card, Checkbox,
  Container,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { ProfileStyleWeb } from "./ProfileStyle.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import ProfileController, { Props } from "../../user-profile-basic/src/ProfileController.web";
import {
  calendar,
  emailedit,
  fbedit,
  heart,
  instaedit,
  mobile,
  snapedit,
  twitteredit,
  user,
  NoProfile_Img
} from "../../user-profile-basic/src/assets";
import { dailCode } from "../../email-account-registration/src/code";
import ChipInput from "material-ui-chip-input";
import {
  AvatarIcon,
  CallIcon,
  ChatIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  SnapchatIcon,
  TwitterIcon, SettingIcon
} from "./assets";
import OtpInput from "react-otp-input";
import { withRouter } from 'react-router';
import { withTranslation } from "react-i18next";
import { Hyperlink } from "../../customform/src/assets";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import moment from "moment";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
class ChairmanProfile extends ProfileController {
  constructor(props: Props) {
    super(props);
  }


  checkDate = (date:string) =>{
    if(date){
      return moment(date).format('YYYY-MM-DD')
    }else{
      return "-"
    }
  }


  async componentDidMount() {
    this.getProfile();
  }

  render() {
    const { classes, t,i18n }: any = this.props;
    let profileData = this.state.profiledata;
    const {language} = i18n
    return (
      <>
        <GeneralHeader>
              <Container className={classes.ChairmanProfile} >
                <Box className="navigation">
                  <Box>
                    <Typography variant="body1">
                      <Box component="span" style={{ color: "#2B6FED" }}>
                        {t("My Profile")}
                      </Box>
                    </Typography>
                    <Typography variant="h5" className="sub-heading bold-text">
                      {t("My Profile")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="my-profile-box">
                  <Box className="heading">
                    <Typography variant="h6" className="sub-heading bold-text">
                      {t("General Details")}
                    </Typography>
                    <Box className="setting" style={{ cursor: "pointer" }} onClick={() => this.setState({ privacyModal: true })}>
                      <img src={SettingIcon} alt="setting" />
                      <span>{t("Other Can See")}</span>
                    </Box>
                  </Box>
                  <Card className="profile-details-box">
                    <Grid container>
                      <Grid item xs={3} className="left-side" style={{ padding: 20 }}>
                        <SectionOne profileData={profileData} t={t}
                          handleClick={() => this.props.history.push('/chairmanchat')} />
                      </Grid>
                      <Grid item xs={1} className="border" />
                      <Grid item xs={8} className="right-side" style={{ padding: 20 }}>
                        <SectionTwo profile={profileData} t={t} />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
                <Box className="edit-button">
                  <Button onClick={() => this.setState({ showDialog: true })}>
                    {t("Edit Details")}
                  </Button>
                </Box>
              </Container>
          <Dialog
            open={this.state.showDialog}
            onClose={() => this.setState({ showDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            dir={languageCondition(language,"rtl","ltr")}
            PaperProps={{
              style: {
                borderRadius: '15px',
                maxWidth: 700,
                width: '100%'
              },
            }}
          >
            <Grid container >
              <Grid xs={12} style={{ borderBottom: '1px solid #e9dede', padding: '1rem' }}>
                <Box display='flex' justifyContent='space-between'>
                  <p className="bold-text" style={{ fontWeight: 600 }}>
                    {t("Edit My Profile")}
                  </p>
                  <p onClick={() => this.setState({ showDialog: false })} style={{ cursor: 'pointer' }}>
                    X
                  </p>
                </Box>
              </Grid>
            </Grid>

            <Grid container className="main-content-block" style={{ marginTop: '1.5rem', padding: '0rem 1rem 0rem 1rem' }}>
              <Grid xs={12} className='inputPlaceholderRegistration'>
                <Formik initialValues={this.initialValuesFormik(profileData)}
                  validationSchema={this.profileSchema()}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.updateChairmenProfile(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isValid, handleChange,
                    setFieldValue, setFieldError
                  }) => (
                    <Form className="commonForm" translate="yes">
                      <Box className='formGroup' style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <Box style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                          marginTop: '1rem',
                          marginBottom: '1.5rem',
                          flexDirection: 'column',
                        }}>
                          <Avatar src={values.bannerUrl || NoProfile_Img} style={{ width: "50px", height: "50px" }} />
                          <label htmlFor="file1" className="bold-text"
                            style={{ color: '#FC8434', fontWeight: 'bold' }}>
                            {t("Add Profile Picture")}
                          </label>
                          <input
                            id="file1"
                            type="file"
                            onChange={(e: any) => {
                              this.handleSelectBanner(
                                e,
                                setFieldValue,
                                setFieldError
                              );
                            }}
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              cursor: "pointer",
                              opacity: 0
                            }}
                            accept="image/png, image/jpeg, image/jpg"
                          />
                        </Box>

                        <Typography
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: 14,
                            marginTop: 5,
                            marginLeft: 10
                          }}
                        >
                          <ErrorMessage className="text-error" component="Typography" name="banner" />
                        </Typography>
                        {errors.bannerUrl && touched.bannerUrl ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.bannerUrl}
                          </Typography>
                        ) : null}
                        <Grid container spacing={2} style={{ width: "98%" }}>
                          <Grid item xs={6}>
                            <Box
                              className="formInputGrp"
                              style={{
                                border: "0.1px solid rgb(209 209 209 / 100%)",
                                borderRadius: "10px",
                                backgroundColor: "#f9f9f9",
                                height: '59px',
                                width: "100%"
                              }}
                            >
                              <Field
                                className="formInput"
                                name="full_name"
                                value={values.full_name}
                                placeholder={t("Enter your name")}
                                disabled={true}
                                style={{ color: 'gray' }}
                              />
                              <span className="frmLeftIcons" style={{ top: '29%' }}>
                                <img src={user} />
                              </span>
                            </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 0
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="full_name" />
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Box
                                marginTop='1rem'
                                className='formInputGrp'
                                display="flex"
                                overflow="hidden"
                                alignItems="center"
                                height="56px"
                                border="0.1px solid rgb(209 209 209)"
                                borderRadius="10px"
                                bgcolor="#f9f9f9"
                                style={{ width: '100%' }}
                              >
                                <Box>
                                  <FormControl variant="outlined">
                                    <Select
                                      name='selectCode'
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      onChange={this.handleChange}
                                      label="Unit"
                                      disabled
                                      value={this.state.selectCode3}
                                    >
                                      <MenuItem value="">
                                        <em>{t("None")}</em>
                                      </MenuItem>
                                      {dailCode.map((item) =>
                                        <MenuItem key={item.dial_code}
                                          value={item.dial_code}> <img
                                            src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.code}.svg`}
                                            width='15' height='15'
                                            style={{ marginRight: '5px' }} />
                                          {item.dial_code}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Field
                                  name="phone"
                                  id="mobile"
                                  disabled
                                  value={values.phone}
                                  placeholder={t("Mobile")}
                                  style={{
                                    border: "none",
                                    height: "42%",
                                    width: "80%",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontWeight: 400,
                                    fontSize: 16,
                                    marginRight: 10,
                                    marginLeft: 0,
                                    outline: "none",
                                    backgroundColor: '#f9f9f9'
                                  }}
                                />
                              </Box>
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5,
                                  marginLeft: 10
                                }}
                              >
                                <ErrorMessage className="text-error" component="Typography" name="phone" />
                              </Typography>
                              <p style={{
                                color: '#FC8434',
                                textAlign: 'right',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                              }} onClick={() => this.setState({ showDialog1: true })}>
                                {t("Update phone number")}
                              </p>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              className="formInputGrp"
                              style={{
                                width: '100%',
                                border: "0.1px solid rgb(209 209 209 / 100%)",
                                borderRadius: "10px",
                                backgroundColor: "#f9f9f9",
                                marginTop: "0px"
                              }}
                            >
                              <Field
                                className="formInput"
                                value={values.email}
                                name="email"
                                placeholder={t("Email ID")}
                                disabled={true}
                                style={{ color: 'gray' }}
                              />
                              <span className="frmLeftIcons">
                                <img src={emailedit} />
                              </span>
                            </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="email" />
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            {/* Bio */}
                            <Box
                              className="formInputGrp"
                              style={{
                                width: '100%',
                                border: "0.1px solid rgb(209 209 209 / 100%)",
                                borderRadius: "10px",
                                backgroundColor: "#f9f9f9",
                                marginTop: "0px"
                              }}
                            >
                              <Field
                                className="formInput"
                                name="bio"
                                value={values.bio}
                                placeholder={t("Enter your bio")}
                              />
                              <span className="frmLeftIcons">
                                <img src={Hyperlink} />
                              </span>
                            </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="bio" />
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="bold-text" style={{ marginBottom: "10px" }}>
                              {t("Gender")}
                            </Typography>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              defaultValue={values.gender}
                              style={{ width: '100%', marginLeft: "20px" }}>
                              <Grid container spacing={2} >
                                <Grid xs={6}>
                                  <FormControlLabel
                                    className={values.gender == 'Female' ? 'active' : 'unactive'}
                                    name='gender' onChange={handleChange} value={t("Female")}
                                    control={
                                      <Radio icon={<CircleUnchecked style={{ color: "#525252" }} />} checkedIcon={<RadioButtonCheckedIcon style={{ color: "#FC8434" }} />} />} label="Female" style={{
                                        borderRadius: 10,
                                        border: "0.1px solid rgb(209 209 209 / 100%)",
                                        width: '95%',
                                        height: "55px",
                                        background: '#F9F9F9',
                                        marginRight: "0px",
                                        backgroundColor: "white",
                                      }}
                                  />
                                </Grid>
                                <Grid xs={6}>
                                  <FormControlLabel
                                    className={values.gender == 'Male' ? 'active' : 'unactive'}
                                    name='gender' onChange={handleChange} value={t("Male")}
                                    control={
                                      <Radio icon={<CircleUnchecked style={{ color: "#525252" }} />} checkedIcon={<RadioButtonCheckedIcon style={{ color: "#FC8434" }} />} />} label="Male" style={{
                                        borderRadius: 10,
                                        border: "0.1px solid rgb(209 209 209 / 100%)",
                                        width: '95%',
                                        height: "55px",
                                        background: '#F9F9F9',
                                        backgroundColor: "white",
                                      }}
                                  />
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              className="formInputGrp"
                              style={{
                                width: '100%',
                                border: "0.1px solid rgb(209 209 209 / 100%)",
                                borderRadius: "10px",
                                backgroundColor: "#f9f9f9"
                              }}>
                              <Field
                                className="formInput"
                                name="DOB"
                                placeholder={t("Date of Birth")}
                                type={this.state.dateType}
                                onFocus={() => this.setState({ dateType: "date" })}
                              />
                              <span className="frmLeftIcons" style={{ top: '24%' }}>
                                <img src={calendar} />
                              </span>
                            </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="DOB" />
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Box
                              className="formInputGrp"
                              style={{
                                width: '100%',
                                border: "0.1px solid rgb(209 209 209 / 100%)",
                                borderRadius: "10px",
                                backgroundColor: "#f9f9f9"
                              }}
                            >
                              <ChipInput
                                className="formInput"
                                placeholder={t("Hobbies")}
                                style={{ padding: '10px 0px 7px 50px', width: '79%' }}
                                disableUnderline={true}
                                value={values.hobbies}
                                onAdd={(chip: any) => this.handleAddChip(setFieldValue, chip, values.hobbies)}
                                onDelete={(chip: any, index: any) => this.handleDeleteChip(setFieldValue, chip, values.hobbies, index)}
                              />
                              <span className="frmLeftIcons">
                                <img src={heart} style={{ width: "23px" }} />
                              </span>
                            </Box>
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5,
                                marginLeft: 10
                              }}
                            >
                              <ErrorMessage className="text-error" component="Typography" name="hobbies" />
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%'
                            }}>
                              <Box
                                className="formInputGrp"
                                style={{
                                  border: "0.1px solid rgb(209 209 209 / 100%)",
                                  borderRadius: "10px",
                                  backgroundColor: "#f9f9f9",
                                  marginTop: "0px"
                                }}
                              >
                                <Field
                                  className="formInput"
                                  name="twitter"
                                  type='url'
                                  value={values.twitter}
                                  placeholder={t("Twitter profile link")}
                                />
                                <span className="frmLeftIcons">
                                  <img src={twitteredit} />
                                </span>
                              </Box>
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5,
                                  marginLeft: 10
                                }}
                              >
                                <ErrorMessage className="text-error" component="Typography" name="twitter" />
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%'
                            }}>
                              <Box
                                className="formInputGrp"
                                style={{
                                  border: "0.1px solid rgb(209 209 209 / 100%)",
                                  borderRadius: "10px",
                                  backgroundColor: "#f9f9f9",
                                  marginTop: "0px"
                                }}
                              >
                                <Field
                                  className="formInput"
                                  name="fb"
                                  type='url'
                                  value={values.fb}
                                  placeholder={t("Facebook profile link")}
                                />
                                <span className="frmLeftIcons">
                                  <img src={fbedit} />
                                </span>
                              </Box>
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5,
                                  marginLeft: 10
                                }}
                              >
                                <ErrorMessage className="text-error" component="Typography" name="fb" />
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%'
                            }}>
                              <Box
                                className="formInputGrp"
                                style={{
                                  border: "0.1px solid rgb(209 209 209 / 100%)",
                                  borderRadius: "10px",
                                  backgroundColor: "#f9f9f9",
                                  marginTop: "0px"
                                }}
                              >
                                <Field
                                  className="formInput"
                                  name="insta"
                                  type='url'
                                  value={values.insta}
                                  placeholder={t("Instagram profile link")}
                                />
                                <span className="frmLeftIcons">
                                  <img src={instaedit} />
                                </span>
                              </Box>
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5,
                                  marginLeft: 10
                                }}
                              >
                                <ErrorMessage className="text-error" component="Typography" name="insta" />
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%'
                            }}>
                              <Box
                                className="formInputGrp"
                                style={{
                                  border: "0.1px solid rgb(209 209 209 / 100%)",
                                  borderRadius: "10px",
                                  backgroundColor: "#f9f9f9",
                                  marginTop: "0px"
                                }}
                              >
                                <Field
                                  className="formInput"
                                  name="snap"
                                  type='url'
                                  value={values.snap}
                                  placeholder={t("Snapchat profile link")}
                                />
                                <span className="frmLeftIcons">
                                  <img src={snapedit} />
                                </span>
                              </Box>
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5,
                                  marginLeft: 10
                                }}
                              >
                                <ErrorMessage className="text-error" component="Typography" name="snap" />
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box style={{ padding: '1rem 1rem 0rem 1rem', borderTop: '1px solid #1A181D25', display: 'flex', justifyContent: 'end' }}>
                        <DeclineButton variant='contained' onClick={() => { localStorage.setItem('profileData', JSON.stringify(profileData)); this.setState({ showDialog: false }); }} style={{ marginRight: '1rem' }}>
                          {t("Cancel")}
                        </DeclineButton>
                        <Box className="customButton" style={{ width: '10rem' }}>
                          <Button variant="contained" type="submit" style={{ borderRadius: "10px" }}>
                            {t("Save")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
            <Box className="dialog-footer desktop-ui">
              <DialogActions className="customButton">
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            open={this.state.privacyModal}
            onClose={() => this.setState({ privacyModal: false })}
            aria-labelledby="alert-dialog-title"
            dir={languageCondition(language,"rtl","ltr")}
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={{
              style: {
                borderRadius: '15px',
                maxWidth: 700,
                width: '100%'
              },
            }}
          >
            <Grid container className="privecyModal">
              <Grid xs={12} style={{ borderBottom: '1px solid #e9dede', padding: '1rem' }} className="privecyModal">
                <Box display='flex' justifyContent='space-between' className="privecyModal">
                  <p className="bold-text privecyModal" style={{ fontWeight: 600 }}>
                    {t("Publish Details for Others")}
                  </p>
                  <p className="privecyModal" onClick={() => this.setState({ privacyModal: false })} style={{ cursor: 'pointer' }}>
                    <CloseIcon />
                  </p>
                </Box>
              </Grid>
            </Grid>
            <Grid container className="main-content-block privecyModal" style={{ marginTop: '1.5rem', padding: '0rem 1rem 0rem 1rem' }}>
              <Grid xs={12} className='inputPlaceholderRegistration privecyModal'>
                <Formik
                  initialValues={{
                    full_name: this.state.profiledata?.attributes?.full_name?.publilc_access,
                    unit: this.state.profiledata?.attributes?.apartment_number?.publilc_access,
                    phone: this.state.profiledata?.attributes?.full_phone_number?.publilc_access,
                    email: this.state.profiledata?.attributes?.email?.publilc_access,
                    gender: this.state.profiledata?.attributes?.gender?.publilc_access,
                    DOB: this.state.profiledata?.attributes?.date_of_birth?.publilc_access,
                    hobbies: this.state.profiledata?.attributes?.hobbies?.publilc_access,
                    twitter: this.state.profiledata?.attributes?.website[0].publilc_access,
                    fb: this.state.profiledata?.attributes?.website[1].publilc_access,
                    insta: this.state.profiledata?.attributes?.website[2].publilc_access,
                    snap: this.state.profiledata?.attributes?.website[3].publilc_access,
                    bio: this.state.profiledata?.attributes?.bio?.publilc_access,
                    family: this.state.profiledata?.attributes?.families?.publilc_access
                  }}
                  onSubmit={(values) => {
                    this.updatePublicProfile(values);
                  }}
                  enableReinitialize={true}
                >
                  {({
                    values, handleChange
                  }) => (
                    <Form className="commonForm" translate="yes">
                      <Box className='formGroup privacyModal' style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Box className="formGroup" style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxna" className="checkboxLabel bold-text">{t("Name")}</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.full_name.name || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="full_name" onChange={handleChange} checked={values.full_name} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxna"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="formGroup" style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxu" className="checkboxLabel bold-text">{t("Apartment No")}.</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.apartment_number?.apartment_number || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="unit" onChange={handleChange} checked={values.unit} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxu"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="formGroup" style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxp" className="checkboxLabel bold-text">{t("Phone No")}.</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.full_phone_number?.full_phone_number || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="phone" onChange={handleChange} checked={values.phone} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxp"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          {/*t*/}
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxe" className="checkboxLabel bold-text">{t("Email Address")}</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.email?.email || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="email" onChange={handleChange} checked={values.email} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxe"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxg" className="checkboxLabel bold-text">{t("Gender")}</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.gender?.gender || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="gender" onChange={handleChange} checked={values.gender} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxg"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxdb" className="checkboxLabel bold-text">{t("Date of Birth")}</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {
                                      this.checkDate(profileData.attributes.date_of_birth?.date_of_birth)
                                    }
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="DOB" onChange={handleChange} checked={values.DOB} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxdb"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxh" className="checkboxLabel bold-text">{t("Hobbies")}</label>
                                  <p style={{ marginTop: '8px' }}>
                                    {profileData?.attributes?.hobbies?.hobbies?.join(',') || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="hobbies" onChange={handleChange} checked={values.hobbies} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxh"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxt" className="checkboxLabel bold-text">{t("Twitter")}</label>
                                  <p style={{ marginTop: '8px', width: "240px" }} className="textwrapChat">
                                    {profileData?.attributes?.website[0]?.twitter_link || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="twitter" onChange={handleChange} checked={values.twitter} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxt"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxb" className="checkboxLabel bold-text">{t("Facebook")}</label>
                                  <p style={{ marginTop: '8px', width: "240px" }} className="textwrapChat">
                                    {profileData?.attributes?.website[2]?.fb_link || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="fb" onChange={handleChange} checked={values.fb} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxb"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup " style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxi" className="checkboxLabel bold-text">{t("Instagram")}</label>
                                  <p style={{ marginTop: '8px', width: "240px" }} className="textwrapChat">
                                    {profileData?.attributes?.website[1]?.instagram_link || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="insta" onChange={handleChange} checked={values.insta} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxi"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className="formGroup" style={{ display: "block", marginBottom: "0px" }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', borderRadius: '8px', padding: "15px", border: "1px solid #e4e4e4" }}>
                                <div>
                                  <label htmlFor="loginCheckboxs" className="checkboxLabel bold-text">{t("Snapchat")}</label>
                                  <p style={{ marginTop: '8px', width: "240px" }} className="textwrapChat">
                                    {profileData?.attributes?.website[3]?.snapchat_link || "NA"}
                                  </p>
                                </div>
                                <div>
                                  <Checkbox className="radio-toolbar" name="snap" onChange={handleChange} checked={values.snap} icon={<CircleUnchecked style={{ color: "#e2e2e2" }} />}
                                    checkedIcon={<CircleCheckedFilled style={{ color: "#fc8434" }} />} id="loginCheckboxs"
                                  />
                                </div>
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="privacyModal" style={{ padding: '1rem 1rem 0rem 1rem', borderTop: '1px solid #1A181D25', display: 'flex', justifyContent: 'end' }}>
                        <DeclineButton className="privacyModal" variant='contained' onClick={() => { localStorage.setItem('profileData', JSON.stringify(profileData)); this.setState({ privacyModal: false }); }} style={{ marginRight: '1rem' }}>
                          {t("Cancel")}
                        </DeclineButton>
                        <Box className="customButton privacyModal" style={{ width: '10rem' }}>
                          <Button variant="contained" className="privacyModal" type="submit" style={{ borderRadius: "10px" }}>
                            {t("Save")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
            <Box className="dialog-footer desktop-ui">
              <DialogActions className="customButton">
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            open={this.state.showDialog1}
            onClose={() => this.setState({ showDialog1: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={{
              style: {
                borderRadius: '15px',
                padding: '2rem',
                margin: 0,
                overflow: 'visible',
              },
            }}
          >
            <Box style={{ fontSize: "1.25rem", fontWeight: 'bold', textAlign: 'right' }}
              onClick={() => this.setState({ showDialog1: false })}>
              x
            </Box>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10
              }}>
                <img src={mobile} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10
              }}>

                <p style={{ fontWeight: 600, fontSize: '1.25rem', textAlign: 'center' }}>
                  {t("Update Mobile Number")}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10
              }}>
                <p style={{ fontWeight: 400, fontSize: '0.8rem', textAlign: 'center' }}>
                  {t("Update mobile number in the below field")}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Formik initialValues={{
                  phone: '',
                }}
                  validationSchema={this.addPhoneSchema()}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.updatePhone(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isValid, handleChange,
                    setFieldValue, setFieldError
                  }) => (
                    <Form className="commonForm" translate="yes">
                      <Box
                        marginTop='1rem'
                        className='formInputGrp'
                        display="flex"
                        overflow="visible"
                        alignItems="center"
                        height="56px"
                        border="0.1px solid rgb(209 209 209 / 44%)"
                        borderRadius="25px"
                        bgcolor="#f9f9f9"
                      >
                        <Box>
                          <FormControl variant="outlined">
                            <PhoneInput
                              inputProps={{ name: 'selectCode' }}
                              enableSearch={true}
                              value={this.state.selectCode}
                              onChange={this.handleChangeCCode}
                              country={'us'}
                            />
                          </FormControl>
                        </Box>
                        <Field
                          name="phone"
                          id="mobile"
                          placeholder={t("Mobile")}
                          style={{
                            border: "none",
                            height: "42%",
                            width: "80%",
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 400,
                            fontSize: 16,
                            marginRight: 10,
                            marginLeft: 21,
                            outline: "none",
                            backgroundColor: '#f9f9f9'
                          }}
                        />
                      </Box>
                      {this.state.error === 'Invalid or Unrecognized Phone Number' &&
                        <p className="text-error">{this.state.error}</p>
                      }
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5,
                          marginLeft: 10
                        }}
                      >
                        <ErrorMessage className="text-error" component="Typography" name="phone" />
                      </Typography>
                      <Box className="dialog-footer desktop-ui">
                        <DialogActions className="customButton">
                          <Button
                            type="submit" variant="contained" style={{ width: "233px", height: "55px" }}>
                            {t("Submit")}
                          </Button>
                        </DialogActions>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Dialog>

          <Dialog
            open={this.state.showDialogDelete}
            onClose={() => this.setState({ showDialogDelete: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="diloag-wrapper"
            PaperProps={{
              style: {
                borderRadius: '15px',
                padding: '1rem',
                margin: 0,
                minWidth: '400px',
                minHeight: '344px'
              },
            }}
          >
            <Box style={{ fontSize: "1.25rem", fontWeight: 'bold', textAlign: 'right' }}
              onClick={() => this.setState({ showDialogDelete: false })}>
              x
            </Box>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
                marginTop: '2rem'
              }}>
                <img src={mobile} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10
              }}>
                <p style={{ fontWeight: 600, fontSize: '1.25rem', textAlign: 'center' }}>
                  {t("Verify Updated Mobile Number")}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10
              }}>
                <p style={{ fontWeight: 400, fontSize: '0.8rem', textAlign: 'center' }}>
                  {t("Enter OTP sent to your mobile number for verification")}.
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item className="commonForm">
                <Box className="formGroup otpBlock">
                  <OtpInput className="formOutlineInput"
                    value={this.state.otp}
                    onChange={(otp: any) => this.setState({ otp })}
                    numInputs={4}
                  />
                </Box>
                <Box className="customButton row-btn" style={{ marginTop: '2.25rem', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <Button variant="contained" onClick={() => {
                    this.verifyOtp();
                  }} style={{ width: "233px", height: "55px" }}>{t("Send")}</Button>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
          <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        </GeneralHeader>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(ProfileStyleWeb)(withRouter(ChairmanProfile)));


const SectionOne = (props: any) => {
  const userType = localStorage.getItem('userType');
  return <>
    <img
      src={props?.profileData?.attributes?.profile_pic?.url || NoProfile_Img}
      alt="avatar"
      className="profile"
    />
    <Typography variant="h6" className="sub-heading bold-text">
      {props?.profileData?.attributes?.full_name.name || 'N/A'}
    </Typography>
    {
        (userType !== 'ServiceProvider' && userType !== 'Auditor') &&
        <p> {props?.profileData?.attributes?.apartment_number?.apartment_number && 'Unit - '}{props?.profileData?.attributes?.apartment_number?.apartment_number || 'N/A'}</p>
    }
    <Box className="icons">
      <img src={ChatIcon} alt="chat" onClick={props.handleClick} />
      <img src={CallIcon} alt="phone"
        onClick={() => document.location.href = `tel:${props?.profileData?.attributes?.full_phone_number?.full_phone_number}`} />
      <img src={EmailIcon} alt="email"
        onClick={() => document.location.href = `mailto:${props?.profileData?.attributes?.email?.email}`} />
    </Box>
  </>;
};

const DeclineButton = withStyles((theme) => ({
  root: {
    color: "#2b6fed",
    backgroundColor: "white",
    border: "1px solid #2b6fed",
    fontWeight: "bold",
    width: "10rem",
    borderRadius: "10px",
    marginLeft:"10px",
    '&:hover': {
      backgroundColor: "white",
    },
  },
}))(Button);

const SectionTwo = ({ profile, t }: any) => {
  const profileData = {profileData: profile};
  const checkNosocialMedia = (profileData: any) => {
    if (!profileData?.profileData?.attributes?.website[0].twitter_link) {
      if (!profileData?.profileData?.attributes?.website[1].instagram_link) {
        if (!profileData?.profileData?.attributes?.website[2].fb_link) {
          if (!profileData?.profileData?.attributes?.website[3].snapchat_link) {
            return `${t('No social media handle')}`;
          }
        }
      }
    }
  };
  return <>
    <Grid container className="about">
      <Grid item xs={12}>
        <span>{t("About")}</span>
        <p>{profileData?.profileData?.attributes?.bio?.bio || 'N/A'}</p>
      </Grid>
    </Grid>
    <Grid container className="info">
      <Grid item xs={3}>
        <span>{t("Gender")}</span>
        <p>{profileData?.profileData?.attributes?.gender?.gender || 'N/A'}</p>
      </Grid>
      <Grid item xs={3}>
        <span>{t("DOB")}</span>
        <p> {profileData?.profileData?.attributes?.date_of_birth?.date_of_birth ? moment(profileData?.profileData?.attributes?.date_of_birth?.date_of_birth, "YYYY-MM-DD").format("DD MMM YYYY") : ""}</p>
      </Grid>
      <Grid item xs={6}>
        <span>{t("Hobbies")}</span>
        <Box className="hobbies" style={{ margin: "0px", padding: "2px", }}>
          {profileData?.profileData?.attributes?.hobbies?.hobbies && <>
            {profileData?.profileData?.attributes?.hobbies?.hobbies.map((item: any) => <>
              <span style={{ textTransform: "capitalize" }} key={item}>
                {item}
              </span>
            </>
            )}
          </>}
        </Box>
      </Grid>
    </Grid>
    <Grid container className="social">
      <Grid item xs={12}>
        <span>{t("Social Media")}</span>
        <Box className="icons">
          {profileData?.profileData?.attributes?.website[0].twitter_link &&
            <Button onClick={() => window.open(profileData?.profileData?.attributes?.website[0].twitter_link, "_blank")} style={{ minWidth: "0px", padding: "6px 0px" }}>
              <img src={TwitterIcon} alt="phone" />
            </Button>
          }
          {profileData?.profileData?.attributes?.website[1].instagram_link &&
            <Button onClick={() => window.open(profileData?.profileData?.attributes?.website[1].instagram_link, "_blank")} style={{ minWidth: "0px", padding: "6px 0px" }}>
              <img src={InstagramIcon} alt="chat" />
            </Button>
          }
          {profileData?.profileData?.attributes?.website[2].fb_link &&
            <Button onClick={() => window.open(profileData?.profileData?.attributes?.website[2].fb_link, "_blank")} style={{ minWidth: "0px", padding: "6px 0px" }}>
              <img src={FacebookIcon} alt="chat" />
            </Button>
          }
          {profileData?.profileData?.attributes?.website[3].snapchat_link &&
            <Button onClick={() => window.open(profileData?.profileData?.attributes?.website[3].snapchat_link, "_blank")} style={{ minWidth: "0px", padding: "6px 0px" }}>
              <img src={SnapchatIcon} alt="email" />
            </Button>
          }
          {checkNosocialMedia(profileData)}
        </Box>
      </Grid>
    </Grid>
  </>;
};

// Customizable Area End