import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  actionProgressList: any[];

  yearList: any[];

  year: string;
  search: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEActionsProgressController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetActionProgressYearListCallId: any;
  GetActionProgressListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      actionProgressList: [],

      yearList: [],

      year: "",
      search: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetActionProgressYearListCallId:
          this.handleGetActionProgressYearResponse(responseJson);
          break;
        case this.GetActionProgressListCallId:
          this.handleGetActionProgressListResponse(responseJson);
          break;
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getActionProgressYearList()
    await this.getActionProgressUserList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.year !== this.state.year || prevState.search !== this.state.search) {
      this.getActionProgressUserList();
    }
  }

  getActionProgressUserList = async () => {
    const { search, year } = this.state;
    this.GetActionProgressListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/assign_action_progress?search=${search}&year=${year}`,
    });
  };

  handleGetActionProgressListResponse = (responseJson: any) => {
    if (responseJson && responseJson.action_progress_list) {
      this.setState({ actionProgressList: responseJson.action_progress_list });
    }
  };

  getActionProgressYearList = async () => {
    this.GetActionProgressYearListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_expense_report/expense_reports/year_list`,
    });
  };

  handleGetActionProgressYearResponse = (responseJson: any) => {
    if (responseJson && responseJson.year) {
      this.setState({ yearList: responseJson.year });
    }
  };
  // Customizable Area End
}
