import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import {CEApiCall} from "../../../../components/src/APICallComponent/index.web";

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  match: any;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  title: any;
  titleError: any;
  building: any;
  amountError: any;
  description: any;
  countryList: any;
  cityList: any;
  companyList: any;
  complexList: any;
  buildingList: any;
  nominationList: any;
  filterCountry: any;
  filterBuilding: any;
  filterCompany: any;
  filterComplex: any;
  filterCity: any;
  filterSearch: any;
  page: any;
  pagination: any;
  showSuccess: boolean;
  showError: boolean;
  error: any;
  successMessage: any;
  userType: any;
  isShareModalOpen: boolean;
  shareUrl: any;
  startDateType: any;
  endDateType: any;
  nominationStartDate: any;
  nominationEndDate: any;
  confirmModal: boolean;
  societyType: any;
  upcomingNominationListing:boolean;
  noUpcomingNomination:boolean;
  totalNominationListing:boolean;
  totalNominationList:any;
  nominationId:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CENominationController extends CommonApiCallForBlockComponent<Props, S, SS> {
  // Customizable Area Start
  nominationCountryList: any;
  nominationCityList: any;
  nominationComplexList: any;
  nominationListID:any;
  nominationGetCompanyList:any;
  sentReminder:string = "";
  complexWiseNominationId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage),getName(MessageEnum.PostDetailDataMessage)];
    this.state = {
      // Customizable Area Start
      openModal: false,
      title: "",
      titleError: "",
      building: "",
      amountError: "",
      description: "",
      cityList: [],
      companyList: [],
      complexList: [],
      countryList: [],
      filterCompany: "",
      filterComplex: "",
      buildingList: [],
      nominationList: [],
      filterCountry: "",
      filterBuilding: "",
      filterCity: "",
      filterSearch: "",
      page: 1,
      pagination: {
        current_page: 1,
        total_count: 0,
        total_pages: 1,
      },
      error: "",
      showError: false,
      showSuccess: false,
      successMessage: "",
      userType: localStorage.getItem("userType"),
      isShareModalOpen: false,
      shareUrl: "",
      startDateType: "text",
      nominationStartDate: "",
      endDateType: "text",
      nominationEndDate: "",
      confirmModal: false,
      societyType:"complex",
      upcomingNominationListing:true,
      noUpcomingNomination:false,
      totalNominationListing:false,
      nominationId:"",
      totalNominationList:[
        {
          "id": "1",
          "type": "chairman_nomination",
          "attributes": {
            "title": "Chairman Nomination for the year 2024",
            "status": "active",
            "nomination_flag": true,
            "stage": "Nomination Started",
            "account_id": 7,
            "start_date": "2023-12-14T00:00:00.000Z",
            "end_date": "2023-12-16T00:00:00.000Z",
            "description": "test process",
            "voting_status": null,
            "voting_flag": false,
            "building_management_id": 5,
            "society_management_id": 5,
            "complex_name": "Dubai Marina Housing Community",
            "total_nomination":35,
            "building_name": "building2",
            "voted_as": null,
            "city": "Dubai Media City",
            "participent_number": 0,
            "totle_vote": 0,
            "selected_chairman": {
              "name": null,
              "unit_number": null,
              "recived_vote": 0
            },
            "selected_vice_chairman": {
              "name": null,
              "unit_number": null,
              "recived_vote": 0
            },
            "chairman_nomination_results": [],
            "vice_chairman_nomination_results": [],
            "image": null
          }
        }
      ]
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.PostDetailDataMessage)=== message.id){
      if(message.properties.hasOwnProperty("message")){
        this.manageMessage(message.properties.message)
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.manageAPIResponse(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  sentEmailReminder = async () => {
    this.sentReminder = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_my_team/company_employee_nominations/nomination_reminder?id=${this.state.nominationId}`
    })
  }

  manageAPIResponse = (apiRequestCallId:any, responseJson: any) => {
    if (apiRequestCallId === this.nominationListID) {
      this.setState({
        nominationList: responseJson.nominations.data,
        pagination: responseJson.meta.pagination,
      });
    }
    if(apiRequestCallId === this.nominationCountryList){
      this.setState({
        countryList: responseJson.data.countries || [],
      });
    }
    if(apiRequestCallId === this.nominationGetCompanyList){
      this.setState({
        companyList: responseJson.compneys || [],
      });
    }
    if(apiRequestCallId === this.nominationCityList){
      this.setState({
        cityList: responseJson.city_list || [],
      });
    }
    if(apiRequestCallId === this.nominationComplexList){
      this.setState({
        complexList: responseJson.complexes.data || [],
      });
    }
    if(apiRequestCallId === this.complexWiseNominationId){
      this.setState({
        totalNominationList:responseJson.nominations.data || [],
      })
    }
    if(apiRequestCallId === this.sentReminder){
      this.setState({
        showSuccess:true,
        successMessage:"Reminder sent successfully"
      })
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getCountryList();
    this.getNominationList();
    this.getNominationCompanyList()
    this.complexWiseNominationList(localStorage.getItem("complex_id"))
  }

  manageMessage = (message:any) => {
    if(message === "NOMINATION_CREATED"){
      const id = localStorage.getItem("complex_id")
      this.complexWiseNominationList(id)
      this.setState({
        openModal:false,
        totalNominationListing:true,
        upcomingNominationListing:false,
      })
    }
  }

  handleNominationPagination = (e: any, value: any) => {
    this.setState({
      page: value,
    });
  };

  handleCloseModal = () => {
    this.setState({
      openModal: false,
      title: "",
      titleError: "",
      building: "",
      amountError: "",
      description: "",
    });
  };

  handleSearch = () => {
    this.getNominationList();
  };

  getNominationCompanyList=async()=>{
    this.nominationGetCompanyList = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/real_estate_company_list`
    })
  }

  getCountryList = async () => {
    this.nominationCountryList = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_address/country_list`
    })
  }

  getCityList = async () => {
    this.nominationCityList = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_custom_form/incident_managements/city_list?country=${this.state.filterCountry}`
    })
  }

  getComplexList = async () => {
    this.nominationComplexList = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_interactive_faqs/complex_list?country=${this.state.filterCountry}&city=${this.state.filterCity}`
    })
  }

  getNominationList = async () => {
    this.nominationListID = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_my_team/company_employee_nominations?upcoming=true&page=${this.state.page}&company_id=${this.state.filterCompany}&society_management_id=${this.state.filterComplex}&city=${this.state.filterCity}&country=${this.state.filterCountry}&search=${this.state.filterSearch}`
    })
  }

  complexWiseNominationList = async (complex:any) => {
    this.complexWiseNominationId = await CEApiCall({
      contentType: "application/json",
      method:"GET",
      endPoint:`bx_block_my_team/company_employee_nominations?society_management_id=${complex}&per_page=500`
    })
  }

  // Customizable Area End
}
