import React from "react";
// Customizable Area Start
import {
  Typography,
  Container,
 Button,
  withStyles,
  Box,
  Grid,
  Link,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  TextareaAutosize


} from "@material-ui/core";
import moment from "moment";
import { withTranslation } from "react-i18next";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";

import "./DialogStyle.web.css"
import AlertErrorWeb from "../../../components/src/AlertError.web";
import ClientTicketsDetailsController ,{Props} from "./ClientTicketsDetailsController.web"
import { languageCondition } from "../../../components/src/ConditionManager.web";
// Customizable Area End

class ClientTicketsDetails extends ClientTicketsDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t,i18n}: any = this.props;
    const language = i18n.language;
    return (
      <MainScreen>
          <Box className="incident-Listing-wrapper" dir={languageCondition(language, "rtl", "ltr")}>
            <Grid
              xs={12}
              md={12}
              sm={12}
              spacing={4}
              className="incidentManagementDetails"
              style={{ paddingTop: 35, minHeight: "90vh" }}
            >
              <Container>
                <Box style={dashBoard.navigation}>
                  <Box>
                    <Box>
                      <Typography variant="body1" >
                        <Link color="inherit" id="breadCrumbs" style={{cursor:"pointer"}} onClick={()=> this.props.navigation.navigate("ClientTicket")}>
                          {t("Client Tickets")}
                        </Link>{" "}
                        /
                        <Link  color="inherit">
                          <Box component="span" style={{ color: "#2c6fed" }}>
                            {t("Client Ticket Details")}
                          </Box>
                        </Link>
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      className="bold-text"
                      style={dashBoard.subHeading}
                    >
                      {t("Client Ticket Details")}
                    </Typography>
                  </Box>
                </Box>
                {this.state.details!=null &&
                <Box className="content-block-wrapper incident-detail-card-block">
                
                  <Card className="incident-detail-card card">
                    <Box className="card-header">
                      <Typography component="h3" className="bold-text" style={{fontSize:"22px"}}>
                      {this.state.details.title}
                      </Typography>
                      <div
               
                // style={dashBoard[this.state.details.status]}
                // style={{ paddingBottom: "0", borderRadius: "0px" }}
              >
               
                <Button  style={{borderRadius:"20px"}} className={this.state.details.status=="pending_response"?"pendingColor":"resColor" } ><span className="bold-text">{this.state.details.status=="pending_response"?"Pending response" :this.state.details.status} </span></Button>
               
              </div>   
                    </Box>
                    <CardContent className="card-content">
                      <Box className="row-block">
                        {this.state.userRole==="coo" && 
                      <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Ticket Number")} :
                          </Typography>
                          <h4 className="bold-text">
                           {this.state.details.ticket_id}
                          </h4>
                        </Box>
                        }
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Reported By")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.reported_by}
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography  component="span" >
                            {t("User Role")} :
                          </Typography>
                          <h4 className="bold-text">

                            {this.state.details?.user_roles?.join(",")}
                        
                          </h4>
                        </Box>
                        {this.state.userRole==="coo" && 
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Company")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.complex_name}
                          </h4>
                        </Box>
                        }
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Complex")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.complex_name ||"-"}
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Building")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.building_name ||"-"}
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Category")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.category||"-"}
                          </h4>
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Date & Time")} :
                          </Typography>
                          <h4 className="bold-text">
                          {moment(this.state.details.created_at).format('DD/MM/YYYY hh:mm A')}
                          </h4>
                        </Box>
                       
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography className="title-span" component="span">
                            {t("Mobile Number")}:
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.mobile_number}
                          </h4>
                         
                        </Box>
                        <Box
                          className="card-rows"
                          style={{
                            borderBottom: "1px solid #f4f6fb",
                            paddingBottom: "0.5rem",
                            marginTop: "0",
                          }}
                        >
                          <Typography  component="span">
                            {t("Message")} :
                          </Typography>
                          <h4 className="bold-text">
                          {this.state.details.message||"-"}
                          </h4>
                        
                        </Box>
                       
                      </Box>
                     
                    </CardContent>
                  </Card>
                </Box>}
                {(this.state.details!=null && this.state.details.status=="responded")&& 
                <Card style={{marginTop:"20px",marginBottom:"80px"}}>
                 <CardContent>
                  <Typography className="bold-text" style={{marginBottom:"10px"}}>Response</Typography>
                {this.state.details?.response ||"-"}
                </CardContent>
                </Card>
  }
                {( this.state.details!=null && this.state.details.status==="pending_response") &&
                <div style={{display:"flex",justifyContent:"flex-end", marginTop:"70px"}}>
             <Button
                    variant="contained"
                    className="bold-text"
                    id="clientRes"
                    style={{ borderRadius: "8px" ,backgroundColor:"#2c6fed",color:"white",padding:"10px 30px 10px 30px",fontSize:"16px",}}
                    onClick={() => this.handleResponse()}
                   
                  >
                    {t("RESPOND TO THE CLIENT")}
                  </Button>
             </div>
  }
              </Container>
             

              <Dialog
                className="add-faq-dialog"
                onClose={() => this.setState({handleOpen:false})}
                open={this.state.handleOpen}
                fullWidth
                id="closeModal"
              >
                <MuiDialogTitle disableTypography className="dialog-heading">
                  <Typography variant="h6" className="bold-text">
                    {t("Respond to the client")}
                  </Typography>
                  <IconButton
                    onClick={()=>this.setState({handleOpen:false})}
                    id="assignProvider"
                  >
                    <CloseIcon />
                  </IconButton>
                </MuiDialogTitle>
                <DialogContent dividers>
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <TextareaAutosize  style={{padding:"15px"}} aria-label="minimum height" minRows={10} placeholder="Add Notes"  onChange={(e)=>{this.setState({updateVal:e.target.value})}}/>
                  </FormControl>
                 
                </DialogContent>
                <DialogActions className="dialog-button-group">
                  <Button
                    className="cancel-button"
                    onClick={()=>this.setState({handleOpen:false})}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className="add-button bold-text"
                    id="submit-text"
                    onClick={()=>this.handleUpdate()}
                   
                  >
                    {t("Submit")}
                  </Button>
                </DialogActions>
              </Dialog>
              
              <Loader loading={this.state.loading} />
            </Grid>
          </Box>
          
          <Loader loading={this.state.loading} />
          
          <AlertErrorWeb
              show={this.state.showError}
              handleClose={()=>this.setState({ error: null, })}
              message={this.state.error}
            />
        </MainScreen>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// export default ClientTicketsDetails;
const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    // fontWeight: 600,
    marginTop: 15,
    fontSize:"32px"
  },
  detailsHead:{
    textTransform:"none"
  },
  pending_response:{
    borderRadius: "20px", textTransform: "capitalize" ,backgroundColor:"#FFEDE0",color:"#FC8434"
  },
  responded:{
    borderRadius: "20px", backgroundColor:"#d2ffdd",color:"1bcd47",textTransform: "capitalize"
  }
};
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ClientTicketsDetails))
);
// Customizable Area End
