export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const user_icon = require("../assets/user.png");
export const email_icon = require("../assets/email-icon.png");
export const unit = require("../assets/unit.png");
export const phone_icon = require("../assets/phone-icon.png");
export const building = require("../assets/building.png");
export const x_mark = require("../assets/x-mark.png");
export const true_mark = require("../assets/true-mark.png");
export const dots_menu = require("../assets/dots-menu.png");
export const delete_icon = require("../assets/delete-icon.png");
export const call_org = require("../assets/call-org.png");
export const email_org = require("../assets/email-org.png");
export const chat = require("../assets/chat.png");
export const facebook = require("../assets/facebook.png");
export const instagram = require("../assets/instagram.png");
export const twitter_org = require("../assets/twitter-org.png");
export const snap = require("../assets/snap.png");
export const bentalyLogo = require("../assets/bentalylogo.png");
export const Building1 = require("../assets/building1.png");
export const message = require("../assets/message.png");
export const phone = require("../assets/phone.png");
export const email = require("../assets/email.png");
export const fb = require("../assets/fb.png");
export const twitter = require("../assets/twitter.png");
export const user = require("../assets/user.svg");
export const username = require("../assets/username.png");
export const SearchIconImage = require("../assets/search.png");
export const avatarIcon = require("../assets/avatar-icon.png");
export const calenderIcon = require("../assets/calender.png");
export const poolImage = require("../assets/dummy.png");
export const CheckIcon = require("../assets/check.png");
export const UploadImage = require("../assets/upload.png");
export const invite = require("../assets/invite.png");
export const addgroup = require("../assets/add-group.png");
export const newMember = require("../assets/newmember.png");
export const info = require("../assets/info.png");
export const cancle = require("../assets/cancle.png");
export const twitteredit = require("../assets/twitteredit.png");
export const emailedit = require("../assets/emailedit.png");
export const calendar = require("../assets/calendar.png");
export const heart = require("../assets/heart.png");
export const fbedit = require("../assets/fbedit.png");
export const instaedit = require("../assets/instaedit.png");
export const snapedit = require("../assets/snapedit.png");
export const mobile = require("../assets/mobile.png");
export const FB_Icon = require("../assets/facebook-icon.png");
export const Twitter_Icon = require("../assets/twitter-icon.png");
export const Instagram_Icon = require("../assets/instagram-icon.png");
export const Snapchat_Icon = require("../assets/snapchat-icon.png");
export const Pencil = require("../assets/Pencil.png");
export const Chat_Icon = require("../assets/chat-icon.png");
export const Chat_Disable_Icon = require("../assets/chat-disable-icon.svg");
export const Contact_Icon = require("../assets/contact-icon.png");
export const Contact_Disable_Icon = require("../assets/contact-disable-icon.svg");
export const Email_Msg_Icon = require("../assets/email-msg-icon.png");
export const Email_Disable_Icon = require("../assets/email-disable-icon.svg");
export const NoProfile_Img = require("../assets/no-profile.png");
export const Call = require("../assets/call.png");
export const Chatgreen = require("../assets/chat-green.png");
export const FbGreen = require("../assets/fb-grren.png");
export const TwitterGreen = require("../assets/twitter-green.png");
export const InstaGreen = require("../assets/insta.png");
export const SnapGreen = require("../assets/snap-green.png");
export const EmailGreen = require("../assets/email-green.png");
export const Lock = require("../assets/lock.png");
export const InfoGrey = require("../assets/info-grey.png");
export const BuildingDetail = require("../assets/building-details.png");

export const userIcon = require("../assets/user.svg");
