// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../components/src/APIErrorResponse";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  managementFeeDetails: any;
  getMyManagementList: any;
  budgetYear: any;
  total: any;
  note: string;
  complexId: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getManagementFeeDetailsId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      managementFeeDetails: {},
      getMyManagementList: [],
      budgetYear: new Date().getFullYear(),
      total: null,
      note: "",
      complexId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const complex_id = this.props.navigation.getParam("id");
    this.setState({ complexId: complex_id }, () => {
      this.getManagementFee(this.state.budgetYear);
    });
  }

  getManagementFee = async (selectedYear: any) => {
    const { complexId } = this.state;
    this.getManagementFeeDetailsId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/society_managements/${complexId}/bx_block_fees_payment/management_fees?year=${selectedYear}`,
    });
  };

  getMyManagementResponse = (responseJson: any) => {
    if (responseJson && responseJson?.management_fee) {
      this.setState({
        getMyManagementList: responseJson?.management_fee?.data,
        total: responseJson?.Totals,
        note: responseJson?.Notes.notes,
      });
    } else {
      this.setState({
        getMyManagementList: [],
        total: null,
        note: "",
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (this.getManagementFeeDetailsId === apiRequestCallId) {
        this.getMyManagementResponse(responseJson);
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  handleChange = (e: any) => {
    this.setState(
      {
        budgetYear: e.target.value,
      },
      () => {
        this.getManagementFee(e.target.value);
      }
    );
  };
}

// Customizable Area End
