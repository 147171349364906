  // Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../../framework/src/RunEngine";
  import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
  export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
  }
  
  interface S {
  }
  
  interface SS {
    id: any;
  }
  
  export default class CEMyTeamController extends CommonApiCallForBlockComponent<
    Props,
    S,
    SS
  > {
    LoadDataCallID: any;
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage)
      ];
      this.state = {
      };/*  */
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
  
    }  
  }
  
  // Customizable Area End
  