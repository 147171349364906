// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";
// @ts-ignore
import {baseURL} from "../../../framework/src/config";

export const configJSON = require("../../dashboard/src/config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    loading: boolean;
    anchorEl: any;
    openModal: boolean;
    payment_type: any;
    generateReceipt: boolean;
    paymentType: any;
    confirmPaymentModal: boolean;
    paymentAmount: any;
    invoicesList: any;
    invoiceData: any;
    filterBuilding: any;
    filterFloor: any;
    filterUnit: any;
    filterStatus: any;
    filterType: any;
    searchKey: any;
    buildingList: any;
    unitList: any;
    floorList: any;
    page: any
    count: any;
    pagination: any;
    invoiceDetails: {
        "others": any;
        "status": any;
        "partial_paid_amount": any;
        "rent_amount": any;
        "generated_on": any;
        "due_date":any;
        "late_charge": any;
        "building_name": any;
        "paid_amount":any;
        "unit_number": any;
        "month_year":any;
        "management_fee_amount": any;
        "total_amount": any;
        "tax": any;
        "due_amount": any;
        "currency": {
            "currency": any;
            "currency_ar": any;
        },
        "resident_details": {
            "resident_name":any;
            "resident_id":any;
        },
        "owner_details": {
            "owner_name": any;
            "owner_id":any;
        }
    };
    downloadId: any;
    partialPaymentAmount: any;
    showError: boolean;
    error: any;
    showSuccess: boolean;
    successMessage: any;
    partialPaymentError: any;
    invoiceType: any;
    isShareModalOpen:boolean;
    shareUrl:any;
}

interface SS {
    id: any;
}

export default class CharmainInvoicesController extends CommonApiCallForBlockComponent<Props, S, SS> {
    getInvoiceBillingApiCallId: any
    getInvoiceListId: any
    getBuildingListId: any
    getUnitListId: any;
    getFloorListId: any;
    getInvoiceDetailsId: any;
    registerFullPaymentId: any;
    registerPartialPaymentId: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
        ];
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            loading: false,
            anchorEl: null,
            openModal: true,
            payment_type: 'fullpayment',
            generateReceipt: true,
            paymentType: "",
            confirmPaymentModal: false,
            paymentAmount: "",
            invoicesList: [],
            invoiceData: {},
            filterBuilding: "",
            filterFloor: "",
            filterStatus: "",
            filterType: "",
            filterUnit: "",
            searchKey: "",
            buildingList: [],
            floorList: [],
            unitList: [],
            page: 1,
            count: 10,
            pagination: {
                current_page: 1,
                total_count: 0,
                total_pages: 1,
            },
            invoiceDetails:{
                "others": 0,
                "status": "due",
                "partial_paid_amount": null,
                "rent_amount": 0,
                "generated_on": "2023-09-04T18:00:42.519Z",
                "due_date": "2023-09-16T18:00:42.524Z",
                "late_charge": 0,
                "building_name": "",
                "paid_amount": 0,
                "unit_number": "13",
                "month_year": "September 2023",
                "management_fee_amount": 0,
                "total_amount": 0,
                "tax": 0,
                "due_amount": 0,
                "currency": {
                    "currency": "USD",
                    "currency_ar": "دولار أمريكي"
                },
                "resident_details": {
                    "resident_name": "",
                    "resident_id": 0
                },
                "owner_details": {
                    "owner_name": "",
                    "owner_id": 0
                }
            },
            downloadId: "",
            partialPaymentAmount: "",
            showError: false,
            error: "",
            showSuccess: false,
            successMessage: "",
            partialPaymentError: "",
            invoiceType: "",
            isShareModalOpen:false,
            shareUrl:"",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getBuildingList()
        this.getInvoiceList({
            buildingId: this.state.filterBuilding,
            floorNo: this.state.filterFloor,
            unitId: this.state.filterUnit,
            paymentType: this.state.filterType,
            status: this.state.filterStatus,
            searchKey: this.state.searchKey,
            page: this.state.page,
        })
    }

    handleInvoicesPagination = (e: any, value: any) => {
        this.getInvoiceList({
            buildingId: this.state.filterBuilding,
            floorNo: this.state.filterFloor,
            unitId: this.state.filterUnit,
            paymentType: this.state.filterType,
            status: this.state.filterStatus,
            searchKey: this.state.searchKey,
            page: value,
        })
        this.setState({
            page: value
        })
    }

    handleFilterBy = () => {
        this.setState({
            page:1,
        })
        this.getInvoiceList({
            buildingId: this.state.filterBuilding,
            floorNo: this.state.filterFloor,
            unitId: this.state.filterUnit,
            paymentType: this.state.filterType,
            status: this.state.filterStatus,
            searchKey: this.state.searchKey,
            page: 1,
        })
    }

    getInvoicesListResponse = (responseJson: any) => {
        if (responseJson.hasOwnProperty("invoices")) {
            this.setState({
                invoicesList: responseJson?.invoices?.data,
                pagination: responseJson?.meta?.pagination,
            })
        }
    }

    handleShareModal = () => {
        this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
    };

    getBuildingListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("buildings")) {
            this.setState({
                buildingList: responseJson?.buildings
            })
        }
    }

    getUnitListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("units")) {
            this.setState({
                unitList: responseJson.units
            })
        } else {
            this.setState({
                unitList: []
            })
        }
    }

    getFloorListResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("floors")) {
            this.setState({
                floorList: responseJson.floors
            })
        } else {
            this.setState({
                floorList: []
            })
        }
    }

    registerPaymentConfirmation = () => {
        if (this.state.paymentType === "partial" || this.state.paymentType === "full") {
            if (this.state.paymentType === "partial") {
                if (this.state.partialPaymentAmount && this.state.partialPaymentError === "") {
                    this.setState({confirmPaymentModal: true})
                } else {
                    this.setState({
                        showError: true,
                        error: "Invalid partial payment amount"
                    })
                }
            } else {
                this.setState({confirmPaymentModal: true})
            }
        } else {
            this.setState({
                showError: true,
                error: "Please Select Payment Type"
            })
        }
    }

    manageDownload = async (id: any) => {
        await this.downloadPdf(`/bx_block_fees_payment/invoices/${id}/download_invoice`, `Invoice-${id}.pdf`)
    }

    manageShare = async (id: any) => {
        this.setState({
            isShareModalOpen:true,
            shareUrl:`${baseURL}/bx_block_fees_payment/invoices/${id}/download_invoice`
        })
    }

    getInvoiceDetailsResponse = (responseJson: any) => {
        if (responseJson.hasOwnProperty("report")) {
            this.setState({
                invoiceDetails: responseJson?.report?.data?.attributes,
                downloadId: responseJson?.report?.data?.id
            })
        }
    }

    registerFullPaymentResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("data")) {
            this.setState({
                confirmPaymentModal: false,
                openModal: false,
                showSuccess: true,
                generateReceipt: false,
                successMessage: "Full Payment Updated Successfully!",
                paymentType: "",
            })
            this.getInvoiceList({
                buildingId: this.state.filterBuilding,
                floorNo: this.state.filterFloor,
                unitId: this.state.filterUnit,
                paymentType: this.state.filterType,
                status: this.state.filterStatus,
                searchKey: this.state.searchKey,
                page: this.state.page,
            })
        } else {
            this.setState({
                error: "Something Went Wrong",
                showError: true
            })
        }
    }

    registerPartialPaymentResponse = (responseJson: any) => {
        if (responseJson?.hasOwnProperty("data")) {
            this.setState({
                confirmPaymentModal: false,
                openModal: false,
                showSuccess: true,
                successMessage: `Partial payment ${responseJson.data.attributes.currency} ${responseJson.data.attributes.partial_paid_amount} Updated Successfully!`,
                generateReceipt: false,
                partialPaymentAmount: "",
                paymentType: "",
            })
            this.getInvoiceList({
                buildingId: this.state.filterBuilding,
                floorNo: this.state.filterFloor,
                unitId: this.state.filterUnit,
                paymentType: this.state.filterType,
                status: this.state.filterStatus,
                searchKey: this.state.searchKey,
                page: this.state.page,
            })
        } else {
            this.setState({
                error: "Something Went Wrong",
                showError: true
            })
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.getInvoiceListId) {
                this.getInvoicesListResponse(responseJson)
            }
            if (apiRequestCallId === this.getBuildingListId) {
                this.getBuildingListResponse(responseJson)
            }
            if (apiRequestCallId === this.getUnitListId) {
                this.getUnitListResponse(responseJson)
            }
            if (apiRequestCallId === this.getInvoiceDetailsId) {
                this.getInvoiceDetailsResponse(responseJson)
            }
            if (apiRequestCallId === this.registerFullPaymentId) {
                this.registerFullPaymentResponse(responseJson)
            }
            if (apiRequestCallId === this.registerPartialPaymentId) {
                this.registerPartialPaymentResponse(responseJson)
            }
            if (apiRequestCallId === this.getFloorListId) {
                this.getFloorListResponse(responseJson)
            }
        }
    }

    managePartialAmount = (partialAmount: any, actualAmount: any) => {
        const partialAmountInt = parseFloat(partialAmount)
        const actualAmountInt = parseFloat(actualAmount)
        if (partialAmountInt <= actualAmountInt) {
            this.setState({
                partialPaymentAmount: partialAmountInt,
                partialPaymentError: ""
            })
        } else {
            this.setState({
                partialPaymentAmount: partialAmountInt,
                partialPaymentError: "Partial Amount is more then due amount!"
            })
        }
    }

    selectBuilding = (e: any) => {
        this.setState({
            filterBuilding: e.target.value,
            filterFloor:"",
            filterUnit:"",
            filterStatus:"",
        })
        this.getUnitList(e.target.value, "")
        this.getFloorList(e.target.value)
    }

    selectFloor = (e: any) => {
        this.setState({
            filterFloor: e.target.value,
            filterUnit:"",
            filterStatus:"",
        })
        this.getUnitList(this.state.filterBuilding, e.target.value)
    }

    paymentRegistration = () => {
        if (this.state.paymentType === "partial") {
            this.registerPartialPayment(this.state.downloadId, this.state.partialPaymentAmount)
        } else {
            this.registerFullPayment(this.state.downloadId)
        }
    }

    getInvoiceList = async (data: any) => {
        const {buildingId, floorNo, unitId, paymentType, status, searchKey, page} = data
        const societyID = localStorage.getItem("society_id")
        this.getInvoiceListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_fees_payment/invoices?society_management_id=${societyID}&search=${searchKey || ""}&unit_id=${unitId || ""}&building_id=${buildingId || ""}&floor_number=${floorNo || ""}&select_status=${status || ""}&select_type=${paymentType || ""}&page=${page || 1}`,
        });
        return true
    };

    getBuildingList = async () => {
        const societyID = localStorage.getItem("society_id")
        this.getBuildingListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/buiding_list?society_management_id=${societyID}`,
        });
        return true
    };

    getInvoiceDetails = async (id: any) => {
        this.getInvoiceDetailsId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/${id}/generate_invoice`,
        });
        return true
    };

    getUnitList = async (buildingId: any, floorId: any) => {
        this.getUnitListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/unit_list?building_management_id=${buildingId}&floor_number=${floorId}`,
        });
        return true
    };

    getFloorList = async (buildingId: any) => {
        this.getFloorListId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_fees_payment/invoices/floor_number?building_id=${buildingId}`,
        });
        return true
    };

    registerFullPayment = async (Id: any) => {
        this.registerFullPaymentId = await this.apiCall({
            contentType: "application/json",
            method: "PUT",
            endPoint: `/bx_block_fees_payment/invoices/${Id}/invoice_register_full_payment`,
        });
        return true
    };

    registerPartialPayment = async (Id: any, partialAmount: any) => {
        this.registerPartialPaymentId = await this.apiCall({
            contentType: "application/json",
            method: "PUT",
            endPoint: `/bx_block_fees_payment/invoices/${Id}/invoice_register_partial_payment?partial_amount=${partialAmount}`,
        });
        return true
    };

    handleSearch = (e: any) => {
        this.setState({
            searchKey: e.target.value,
            page:1,
        })
        this.getInvoiceList({
            buildingId: this.state.filterBuilding,
            floorNo: this.state.filterFloor,
            unitId: this.state.filterUnit,
            paymentType: this.state.filterType,
            status: this.state.filterStatus,
            searchKey: e.target.value,
            page: 1,
        })
    }
}
// Customizable Area End