//Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../../framework/src/RunEngine";
  import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
  import { Message } from "../../../../framework/src/Message";
  import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
  import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
  
  export interface Props {
    navigation: any;
    id: string;
    classes?: any;
    companyId: number,
    facilityId: number,
  }
  
  interface S {
    companyDetails:any,
    deleteConfirmModal: boolean,
    tabNo: number,
    proofFileName: any,
    page:number,
    pagination:any,
    mapSwitch: boolean,
    openOptions: boolean,
    editModal:boolean,
    initialValues:any,
    fileName:string,
    proofDoc:any,
    proofError:boolean
  }
  
  interface SS {
    id: any;
  }
  
  export default class ComplexSharedFacilityController extends CommonApiCallForBlockComponent<Props, S, SS> {
    GetCompanyDetails:any;
    DeleteCompany: any;
    slider: any;
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
        // this.handleDeleteModal = this.handleDeleteModal.bind(this);
        // this.handleTabChange = this.handleTabChange.bind(this)
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];
        this.state = {
          companyDetails:null,
          proofFileName: null,
          proofError:false,
          page:1,
          pagination:{
            current_page:1,
            total_count:1,
            total_pages:1,
          },
          mapSwitch: false,
          deleteConfirmModal: false,
          openOptions: false,
          editModal: false,
          proofDoc:null,
          fileName:'',
          tabNo: 0,
          initialValues: {
            unitMember: '',
            floorNumber:'',
            ownerId: '',
            unitStatus: '',
            size: '',
            configuration: '',
            purchasePrice:'',
            purchaseDate: '',
            currentValuation: '',
            rentStatus: '',
            rentAmount: '',
            rentTo: '',
            rentFrom:''
          }
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
  
    async componentDidMount() {
      // this.getCompanyDetails()
    }

    nextImage = () => {
      this.slider.slickNext();
    };
    previousImage = () => {
      this.slider.slickPrev();
    };

    handleOpenEditModal = () => {
      this.setState({editModal: true})
    }

    handleCloseEditModal = () => {
      this.setState({editModal: false})
    }

    handleAddBuilding = () => {
      this.props.navigation.history.push('/CompanyEmployee/ComplexBuildingMainBlock')
    }

    handleFileRemove = () => {
        this.setState({
          proofDoc: null,
          fileName:''
        })
      }

      handleProofDoc = (event:any) => {
        const file = event.target.files[0];
        this.setState({ proofDoc: file, proofError: false, fileName: file.name })
      }
  
    // async receive(from: string, message: Message) {
    //   runEngine.debugLog("Message Recived", message);
  
    //   // Customizable Area Start
    //   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    //     let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    //     let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    //     const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    //     // switch (apiRequestCallId) {
    //     //   case this.GetCompanyDetails:
    //     //     this.handleCompanyDetails(responseJson)
    //     //     break;
    //     //   case this.DeleteCompany:
    //     //     this.handleDeleteSuccess(responseJson)
    //     //     break;
    //     //   default:
    //     //     break;
    //     // }
  
    //     if (responseJson && responseJson.meta && responseJson.meta.token) {
    //       runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    //     } else {
    //       ApiErrorResponse(responseJson);
    //     }
    //     ApiCatchErrorResponse(errorResponse);
    //   }
    //   // Customizable Area End
    // }
  
    // handleDeleteModal () {
    //   this.setState({
    //     deleteConfirmModal:true
    //   })
    // }
  
    // handleCloseDeleteModal () {
    //   this.setState({
    //     deleteConfirmModal:false
    //   })
    // }
  
    // handleCompanyDetails = (responseJson: any) => {
    //   if (responseJson) {
    //     const fileName = responseJson.data.attributes.registration_certificate?.url
    //     if (fileName) {
    //       const splitFlieName = fileName.split("/")
    //       this.setState({ proofFileName: splitFlieName[splitFlieName.length - 1] })
    //     }
    //     this.setState({ companyDetails: responseJson.data })
    //   }
    // };
  
    // handleDeleteSuccess = (responseJson:any) => {
    //   // console.log('delete', responseJson)
    // }
  
    // handleDelete = async () => {
    //   const companyId = this.props.companyId;
  
    //   this.DeleteCompany = await apiCall({
    //     contentType: "application/json",
    //     method: "DELETE",
    //     endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
    //   });
    // }
  
    // getCompanyDetails = async () => {
    //   const companyId = this.props.companyId;
  
    //   this.GetCompanyDetails = await apiCall({
    //     contentType: "application/json",
    //     method: "GET",
    //     endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
    //   });
    // };
  }
  // Customizable Area End
  
  