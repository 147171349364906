// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../components/src/ApiCallCommon.web";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  match: any; // from react-router
}
interface S {
  dashboardData: any;
  token: string;
  errorMsg: string;
  providerLoading: boolean;
  Year: any;
  searchName: any;
  showDialog: boolean;
  updateStatus: any;
  confirmedStatus: any;
  dashboardServiceProvider: any;
  incidentDetails: any;
  imageShowDialog: any;
  file: any;
  error: any;
  errorShow: boolean;
  chatDrawer: boolean;
  openTickets: any;
  openTicketId: any;
  expanded: any;
  selectedValue: any;
  drawerChat:boolean;
  isOpenMapModalOpen: boolean;
}
interface SS {
  id: any;
}

export default class ServiceProviderController extends CommonApiCallForBlockComponent<Props, S, SS> {
  getDashBoardId: string = "";
  getDashBoardOpenTickets: any = "";
  updateIncidentStatusId:any ="";
  chatRoomAPIId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      providerLoading: false,
      Year: "",
      searchName: "",
      showDialog: false,
      updateStatus: false,
      confirmedStatus: false,
      dashboardServiceProvider: "",
      incidentDetails: "",
      imageShowDialog: false,
      file: "",
      error: "",
      errorShow: false,
      chatDrawer: false,
      openTickets: "",
      openTicketId: "",
      expanded: '',
      selectedValue: "",
      drawerChat:false,
      isOpenMapModalOpen: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getProviderData();
  }

  chatRoomCreateResponse = (responseData:any) => {
    if (responseData && responseData?.data) {
      localStorage.setItem('selectedChat', JSON.stringify(responseData.data))
      this.setState({
        drawerChat:true
      })
      this.setState({ providerLoading: false })
    } else if (responseData?.errors) {
      let error = responseData.errors[0] as string;
      this.setState({ error });
    } else {
      this.setState({ error: responseData?.error || "Something went wrong!" });
    }
    this.errorShow()
    this.setState({ providerLoading: false })
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData: any = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let responseError: any = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestId: any = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestId === this.getDashBoardId) {
        this.getDashBoardCardData(responseData);
      } else if (apiRequestId === this.getDashBoardOpenTickets) {
        this.setState({ incidentDetails: responseData?.data })
      } else if (apiRequestId === this.chatRoomAPIId) {
         this.chatRoomCreateResponse(responseData)
      } else if(apiRequestId === this.updateIncidentStatusId){
        if(responseData.hasOwnProperty('data')){
          this.setState({ updateStatus: true })
          this.getProviderData();
        }
      } else {
        this.setState({ errorMsg: responseError });
      }
    }
  }
    // test case try code change

  getProviderDetails = (id: any) => {
    this.setState({ openTicketId: id });
    localStorage.setItem("ServiceProviderManagementDetailId", id)
    //@ts-ignore
    this.props.history.push("/ServiceProviderManagementDetail");
  }

  getProviderData = async () => {
    this.getDashBoardId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/account_block/providers/provider_dashboard?year=${this.state.selectedValue}`,
    });

    const ticket = localStorage.getItem("ServiceProviderManagementDetailId")
    this.getDashBoardOpenTickets = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incidents/${ticket}`,
    });
  }

  updateIncidentStatus = async () => {
    const ticketId = localStorage.getItem("ServiceProviderManagementDetailId")
    this.updateIncidentStatusId = await this.apiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: `bx_block_custom_form/incidents/${ticketId}`,
      body:JSON.stringify({incident:{incident_status:"Pending Confirmation"}})
    });
  }


  getDashBoardCardData = (responseData: any) =>{
    this.setState({
      dashboardServiceProvider:responseData?.data.attributes?.general_dashbord,
      openTickets:responseData.data?.attributes?.general_dashbord?.open_tickets_details || []
    })
  }

  chatRoomCreate = async (id: any) => {
    this.setState({ chatDrawer: true })
    try {
      const messageRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.chatRoomAPIId = messageRequest.messageId;
      messageRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_chat/chats`
      );
      const headerDetails = {
        token: localStorage.getItem("userToken"),
      };
      messageRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerDetails)
      );
      const dataForm = new FormData();
      dataForm.append("chat[chatable_type]", 'BxBlockCustomForm::Incident');
      // @ts-ignore
      dataForm.append("chat[chatable_id]", id);
      messageRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        dataForm
      );
      messageRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(messageRequest.id, messageRequest);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  setShowDialog = () => {
    this.setState({ showDialog: true })
  }

  setDialogClose = () => {
    this.setState({ showDialog: false })
  }

  updateConfirm = () => {
    this.updateIncidentStatus()
    this.setState({ showDialog: false })
    // this.setState({ updateStatus: true })
  }

  updateConfirmClose = () => {
    this.setState({ updateStatus: false, confirmedStatus: true })
  }

  errorShow = () => {
    if (this.state.error) {
      this.setState({
        errorShow: true
      })
    }
  }

  handleChange = (event: any) => {
    this.setState({
      selectedValue: event.target.value as string,
    },() => {
        this.getProviderData()
    })
  };
}

// Customizable Area End

