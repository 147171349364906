// Customizable Area Start
import * as React from "react";
// custom components
import {
    Grid, Box,Typography,Button,
} from "@material-ui/core";
import {withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import {Share} from "../../CollectTransactionFees/src/assets"
import {share2} from "./assets"
import RentDetailsController, {
  Props
} from "./RentDetailsController";
import './style.css'
import {withTranslation} from "react-i18next";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
const {baseURL} = require("../../../framework/src/config")

class ReceiptDetails extends RentDetailsController{
  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const {t} = this.props
    const {id} = this.props.match.params
    // @ts-ignore
    const {language} = this.props.i18n;
      let languageCurrency = (currency:any) => {
          if(language === "en"){
              return currency?.currency
          }else{
              return currency?.currency_ar
          }
      }
    return (
        <>
            <Grid item xs={12} md={12} className="auth-cols RentDetails" dir={languageCondition(language,"rtl","ltr")}>
                <Grid container style={{ margin: '1rem', width: '90%' }} >
                  <Grid item xs={12} style={{ display:"flex", alignItems:"center", gap:"1rem",justifyContent:"space-between"}} >
                      <Box style={{ display:"flex", alignItems:"center", gap:"1rem"}}>
                          <ArrowBackIcon className="backButtonDetailsRent" onClick={() => window.history.back()} />
                          <p className="bold-text RentPaymentDetails" style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                              {t("Rent Invoice")} : {this.state.rentDetails.month}-{this.state.rentDetails.year}
                          </p>
                      </Box>
                  </Grid>
                </Grid>
                <Box style={{background: "#F7F9FE",minHeight:"95%",display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"space-between"}} >
                    <Grid container spacing={2} style={{width:"90%"}}>
                        <Grid item xs={12} style={{marginTop:"1.5rem"}}>
                            <Box
                                display="flex"
                                justifyContent='space-between'
                                alignItems="center"
                                borderRadius="10px"
                                bgcolor="white"
                                padding='1.5rem'
                                style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                            >
                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                            <Typography variant={"subtitle2"}>
                                                {t("Due on")} {moment(this.state.rentDetails.due_date,"DD/MM/YYYY").format("DD MMMM YYYY")}
                                            </Typography>
                                            {
                                                this.state.rentDetails.status === "due" &&
                                                <Typography variant="subtitle2" className="paymentStatusRed">
                                                    {t("Due")}
                                                </Typography>
                                            }
                                            {
                                                this.state.rentDetails.status === "partially_paid" &&
                                                <Typography variant="subtitle2" className="paymentStatusYellow">
                                                    {t("Partially Paid")}
                                                </Typography>
                                            }
                                            {
                                                this.state.rentDetails.status === "recieved" &&
                                                <Typography variant="subtitle2" className="paymentStatusGreen">
                                                    {t("Received")}
                                                </Typography>
                                            }
                                            {
                                                this.state.rentDetails.status === "overdue" &&
                                                <Typography variant="subtitle2" className="paymentStatusOrange">
                                                    {t("Overdue")}
                                                </Typography>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop:"15px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Landlord")}
                                        </Typography>
                                        <Typography variant={"body1"} className="bold-text RentPaymentDetails" style={{fontWeight:"bold",marginTop:"5px"}}>
                                            {this.state.rentDetails.landlord_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop:"15px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Building Name")}
                                        </Typography>
                                        <Typography variant={"body1"} className="bold-text RentPaymentDetailsBuilding" style={{fontWeight:"bold",marginTop:"5px"}}>
                                            {this.state.rentDetails.building_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop:"8px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Unit Number")}
                                        </Typography>
                                        <Typography variant={"body1"} className="bold-text RentPaymentDetailsUnit" style={{fontWeight:"bold",marginTop:"5px"}}>
                                            {this.state.rentDetails.unit_no}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop:"8px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Amount")}
                                        </Typography>
                                        <Typography variant={"body1"} className="bold-text RentPaymentDetailsRent" style={{fontWeight:"bold",marginTop:"5px"}}>
                                            {languageCurrency(this.state.rentDetails.currency)}{" "}{this.state.rentDetails.rent_amount?.toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{marginTop:"8px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Paid Amount")}
                                        </Typography>
                                        <Typography variant={"body1"} style={{fontWeight:"bold",marginTop:"5px"}} className="bold-text">
                                            {languageCurrency(this.state.rentDetails.currency)} {this.state.rentDetails.acknowledge_rentpayment?.toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} className="bold-text RentPaymentDetailsRentDue" style={{marginTop:"8px"}}>
                                        <Typography variant={"subtitle2"} >
                                            {t("Due Amount")}
                                        </Typography>
                                        {
                                            this.state.rentDetails.status === "recieved" &&
                                            <Typography variant={"body1"} className="bold-text RentPaymentDetailsRent" style={{fontWeight:"bold",marginTop:"5px"}}>
                                                {languageCurrency(this.state.rentDetails.currency)} 0
                                            </Typography>
                                        }
                                        {
                                            this.state.rentDetails.status === "partially_paid" &&
                                            <Typography variant={"body1"} className="bold-text RentPaymentDetailsPartiallyPaid" style={{fontWeight:"bold",marginTop:"5px"}}>
                                                {languageCurrency(this.state.rentDetails.currency)}{" "}{(this.state.rentDetails?.due_amount)?.toLocaleString()}
                                            </Typography>
                                        }
                                        {
                                            this.state.rentDetails.status !== "partially_paid" && this.state.rentDetails?.status !== "fully_paid" &&
                                            <Typography variant={"body1"} className="bold-text RentPaymentDetailsFullypaid" style={{fontWeight:"bold",marginTop:"5px"}}>
                                                {languageCurrency(this.state.rentDetails.currency)}{" "}{(this.state.rentDetails?.due_amount)?.toLocaleString() || 0}
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} style={{display:'flex',justifyContent:'center',position:"absolute",bottom:"10px",width:"90%",flexDirection:"column"}}>
                            {
                                (this.state.rentDetails.status === "partially_paid" || this.state.rentDetails.status === "recieved") &&
                                <Grid container spacing={2} style={{width:"95%",display:'flex',alignItems:'center'}}>
                                    <Grid item xs={10}>
                                        <Button2 fullWidth onClick={this.manageDownloadReceipt}>
                                            {t("DOWNLOAD RECEIPT")}
                                        </Button2>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Box onClick={()=>this.setState({isShareModalOpen:true,shareUrl:`${baseURL}/bx_block_rent_payment/rent_receipt_pdf/${id}`})}>
                                            <img src={share2} width="55px" height="50px"/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={2} style={{width:"95%",display:'flex',alignItems:'center',marginTop:"10px"}}>
                                <Grid item xs={10}>
                                    <Button1 fullWidth onClick={this.manageDownloadInvoice}>
                                        {t("Download Invoice")}
                                    </Button1>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box className="shareIcon shareDownloadRent" onClick={()=>this.setState({isShareModalOpen:true,shareUrl:`${baseURL}/bx_block_rent_payment/download_rent_invoice/${id}`})}>
                                        <img src={Share} />
                                    </Box>      
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Loader loading={this.state.loading} />
            <ShareDocumentModal
                isOpen={this.state.isShareModalOpen}
                handleClose={this.handleShareModal}
                heading={t("Share")}
                documentURL={this.state.shareUrl}
            />
        </>
    );
  }
}
export default withTranslation()(withRouter(ReceiptDetails))

const Button1 = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#2b6fed",
        fontWeight:"bold",
        borderRadius:"100px",
        height:"55px",
        marginRight:"10px",
        '&:hover': {
            backgroundColor: "#2b6fef",
        },
    },
}))(Button);

const Button2 = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#FC8434",
        fontWeight:"bold",
        borderRadius:"100px",
        height:"55px",
        marginRight:"10px",
        '&:hover': {
            backgroundColor: "#FC8434",
        },
    },
}))(Button);


// Customizable Area End
