// Customizable Area Start
import * as React from "react";
// custom components
import {
  Button, Grid, Box, Checkbox, Dialog, DialogActions, DialogContent, Typography
} from "@material-ui/core";
import { Building1, username } from "./assets";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import '../assets/css/style.scss';
import { Formik, Form } from "formik";
import ProfileController from "./ProfileController.web";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { withTranslation } from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";
class PublicView extends ProfileController {

  render() {
    // @ts-ignore
    // @ts-nocheck
    let profileData = JSON.parse(localStorage.getItem('profileData'));
    const { t,i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Grid container spacing={2} className="auth-container publicView" style={{ background:'#F7F9FE'}} dir={languageCondition(language,"rtl","ltr")}>
          <Grid item xs={12} md={7} className="auth-cols publicView" style={{ justifyContent: 'unset',padding:"0px" }}>
            <Grid container className="publicView" style={{borderBottom:'2px solid #f2efef',padding:"10px",paddingTop:"15px"}}>
              <Grid item xs={12} className="publicView" style={{ display: 'flex', alignItems: 'center',gap:'0.25rem',marginBottom:5 }}>
                <ArrowBackIcon onClick={() => window.history.back()} style={{fontSize:"27px"}} />
                <p style={{ fontWeight: 600, fontSize: '18px',paddingLeft:8 }} className="bold-text publicView">
                  {t("Publish on Community Page")}
                </p>
              </Grid>
            </Grid>
            <Box style={{paddingRight:"15px",paddingLeft:"15px",background:"#F7F9FE"}} className="publicView">
              <Grid container style={{background:"#F7F9FE"}} className="publicView">
                <Grid item xs={12} className="publicView">
                  <div style={{ background:'#EBF1FD', padding:'0.5rem', marginTop:'1rem', marginBottom:'1rem'}} className="publicView">
                    <p style={{fontSize:"14px"}} className="publicViewParagraph">{t("This screen will allow you to select which of your information wil be visible to the rest of the residents in the building.")}</p>
                  </div>
                </Grid>
              </Grid>
              <div style={{background:'#F7F9FE'}} className="publicView">
                <Grid container className="main-content-block publicView">
                  <Grid item xs={12}>
                    <Formik initialValues={{
                      full_name: profileData?.attributes?.full_name?.publilc_access, unit: profileData?.attributes?.apartment_number?.publilc_access,
                      phone: profileData?.attributes?.full_phone_number?.publilc_access, email: profileData?.attributes?.email?.publilc_access,
                      gender: profileData?.attributes?.gender?.publilc_access, DOB: profileData?.attributes?.date_of_birth?.publilc_access,
                      hobbies: profileData?.attributes?.hobbies?.publilc_access, twitter: profileData?.attributes?.website[0].publilc_access,
                      fb: profileData?.attributes?.website[1].publilc_access, insta: profileData?.attributes?.website[2].publilc_access,
                      snap: profileData?.attributes?.website[3].publilc_access, bio: profileData?.attributes?.bio?.publilc_access,
                      family: profileData?.attributes?.families?.publilc_access
                    }}
                            onSubmit={(values) => { this.updatePublicProfile(values) }}
                            // @ts-ignore
                            id="formik"
                    >
                      {({ values, errors, touched, handleChange}) => (
                          <Form className="commonForm publicView" translate="yes" >
                            <Box className='formGroup publicView' style={{ height: '90%' }}>
                              <Box className="formGroup formCheckbox publicView" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }} className="publicViewName">
                                  <div className="publicViewName">
                                    <label htmlFor="loginCheckboxna" className="checkboxLabel bold-text namePrivecy publicViewName">{t("Name")}</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }} className="publicViewName">
                                      {profileData.attributes.full_name.name}
                                    </p>
                                  </div>
                                  <div>
                                    <Checkbox className="radio-toolbar publicViewName" name="full_name" onChange={handleChange} checked={values.full_name} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxna"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewApprtment" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }} className="publicViewApprtment">
                                  <div className="publicViewApprtment">
                                    <label htmlFor="loginCheckboxu" className="checkboxLabel bold-text AppartmentPrivecy publicViewApprtment">{t("Apartment No")}.</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.apartment_number.apartment_number}
                                    </p>
                                  </div>
                                  <div>
                                    <Checkbox className="radio-toolbar publicViewApprtment" name="unit" onChange={handleChange} checked={values.unit} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxu"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewPhone" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white',borderRadius:'8px', padding:"10px", }} className="publicViewPhone">
                                  <div className="publicViewPhone">
                                    <label htmlFor="loginCheckboxp" className="checkboxLabel bold-text PhoneNoPrivecy publicViewPhone">{t("Phone No")}.</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }} className="publicViewPhone">
                                      {profileData.attributes.full_phone_number.full_phone_number}
                                    </p>
                                  </div>
                                  <div>
                                    <Checkbox className="radio-toolbar publicViewPhone" name="phone" onChange={handleChange} checked={values.phone} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxp"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewEmail" style={{ display:"block" }}>
                                <div className="publicViewEmail" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}>
                                  <div  className="publicViewEmail">
                                    <label htmlFor="loginCheckboxe" className="checkboxLabel bold-text EmailPrivecy publicViewEmail">{t("Email Address")}</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.email.email}
                                    </p>
                                  </div>
                                  <div  className="publicViewEmail">
                                    <Checkbox className="radio-toolbar publicViewEmail" name="email" onChange={handleChange} checked={values.email} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxe"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewGender" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}  className="publicViewGender">
                                  <div className="publicViewGender">
                                    <label htmlFor="loginCheckboxg" className="checkboxLabel bold-text genderPrivecy publicViewGender">{t("Gender")}</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }} className="publicViewGender">
                                      {profileData.attributes.gender.gender}
                                    </p>
                                  </div>
                                  <div className="publicViewGender">
                                    <Checkbox className="radio-toolbar publicViewGender" name="gender" onChange={handleChange} checked={values.gender} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxg"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewDOB" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }} className="publicViewDOB">
                                  <div className="publicViewDOB">
                                    <label htmlFor="loginCheckboxdb" className="checkboxLabel bold-text dateOfBirthPrivecy publicViewDOB">{t("Date of Birth")}</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }} className="publicViewDOB">
                                      {profileData.attributes.date_of_birth.date_of_birth}
                                    </p>
                                  </div>
                                  <div className="publicViewDOB">
                                    <Checkbox className="radio-toolbar publicViewDOB" name="DOB" onChange={handleChange} checked={values.DOB} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxdb"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox publicViewHobbies" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }} className="publicViewHobbies">
                                  <div className="publicViewHobbies">
                                    <label htmlFor="loginCheckboxh" className="checkboxLabel bold-text hobbiesPrivecy publicViewHobbies">{t("Hobbies")}</label>
                                    <p style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }} className="publicViewHobbies">
                                      {profileData.attributes.hobbies?.hobbies?.join(',')}
                                    </p>
                                  </div>
                                  <div className="publicViewHobbies">
                                    <Checkbox className="radio-toolbar publicViewHobbies" name="hobbies" onChange={handleChange} checked={values.hobbies} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxh"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}>
                                  <div>
                                    <label htmlFor="loginCheckboxt" className="checkboxLabel bold-text TwitterPrivecy">{t("Twitter")}</label>
                                    <p className="twitterLable" style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.website[0].twitter_link}
                                    </p>
                                  </div>
                                  <div>
                                    <Checkbox className="radio-toolbar" name="twitter" onChange={handleChange} checked={values.twitter} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxt"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox facebookPrivacy" style={{ display:"block" }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }} className={"facebookPrivacy"}>
                                  <div  className={"facebookPrivacy"}>
                                    <label htmlFor="loginCheckboxb" className="checkboxLabel bold-text facebookPrivacy">{t("Facebook")}</label>
                                    <p className="facbookLable" style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.website[2].fb_link}
                                    </p>
                                  </div>
                                  <div  className={"facebookPrivacy"}>
                                    <Checkbox className="radio-toolbar facebookPrivacy" name="fb" onChange={handleChange} checked={values.fb} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxb"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox insagramLable" style={{ display:"block" }}>
                                <div className="insagramLable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}>
                                  <div className="insagramLable">
                                    <label htmlFor="loginCheckboxi" className="checkboxLabel bold-text instaPrivecyJ insagramLable">{t("Instagram")}</label>
                                    <p className="insagramLable" style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.website[1].instagram_link}
                                    </p>
                                  </div>
                                  <div className="insagramLable">
                                    <Checkbox className="radio-toolbar insagramLable" name="insta" onChange={handleChange} checked={values.insta} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxi"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox snapchatLable" style={{ display:"block" }}>
                                <div className="snapchatLable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}>
                                  <div className="snapchatLable">
                                    <label htmlFor="loginCheckboxs" className="checkboxLabel bold-text SnapchatPrivecy snapchatLable">{t("Snapchat")}</label>
                                    <p className="snapchatLable" style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData.attributes.website[3].snapchat_link}
                                    </p>
                                  </div>
                                  <div className="snapchatLable">
                                    <Checkbox className="radio-toolbar snapchatLable" name="snap" onChange={handleChange} checked={values.snap} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxs"
                                    />
                                  </div>
                                </div>
                              </Box>
                              <Box className="formGroup formCheckbox FamilyDetailsLable" style={{ display:"block" }}>
                                <div  className="FamilyDetailsLable" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background:'white', borderRadius:'8px', padding:"10px" }}>
                                  <div className="FamilyDetailsLable">
                                    <label htmlFor="loginCheckboxf" className="checkboxLabel bold-text FamilyPrivecy FamilyDetailsLable">{t("Family Details")}</label>
                                    <p className="FamilyDetailsLable" style={{ marginTop: '8px',width:"250px",textOverflow:"ellipsis",overflow:"hidden" }}>
                                      {profileData?.attributes.families?.families?.length + t('Members') || t('0 Members')}
                                    </p>
                                  </div>
                                  <div className="FamilyDetailsLable">
                                    <Checkbox className="radio-toolbar FamilyDetailsLable" name="family" onChange={handleChange} checked={values.family} icon={<CircleUnchecked />}
                                              checkedIcon={<CircleCheckedFilled />} id="loginCheckboxf"
                                    />
                                  </div>
                                </div>
                              </Box>
                            </Box>
                            <Box className="customButton publicView">
                              <Button className="publicView" variant="contained" type="submit" style={{marginTop:"15px"}}>
                                {t("Save")}
                              </Button>
                            </Box>
                          </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>

              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} className="auth-cols publicView">
            <Box className="right-block publicView" display={{ xs: 'none', md: 'flex' }}>
              <img src={Building1.default} className="building-logo publicView" alt="" />
            </Box>
          </Grid>
        </Grid>
        <Dialog
          className="delete-document personal publicViewDailog"
          fullWidth
          onClose={() => this.setState({ showDialog: false })}
          open={this.state.showDialog}
        >
          <DialogContent>
            <Box textAlign="center" className="publicView">
              <img src={username} alt="ExclamationIcon" className="publicView" />
              <Typography variant="h6" className="bold-text https://meet.google.com/scz-ptrs-aou">
                {t("Are you sure you want")} <br /> {t("to hide your name?")}
              </Typography>
              <Typography variant="body1" className="publicView">
                {t(
                  "On hiding your name, you’ll go anonymous and you will not be able to message anyone. Do you want to hide your name?"
                )}
              </Typography>
              <DialogActions className="dialog-button-group publicView ">
                <Button onClick={() => this.setState({ showDialog: false })} className="publicView noButton">
                  {t("No, Don’t Hide It")}
                </Button>
                <Button onClick={() => this.publicViewAPI()} className="publicView yesButton">{t("Yes, Hide It")}</Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
        <Loader loading={this.state.loading} />
      </>

    )

  }

}

export default withTranslation()(withRouter(PublicView));

// Customizable Area End
