//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start

import {
  Box,
  Button,
  Dialog,
  Grid,
  TextareaAutosize,
  Typography,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import IncidentController, { Props } from "./IncidentController.web";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  Upload_Icon,
  Clipboard_Icon,
  Warning_Icon,
  House_Icon,
  Box_Icon,
  Building1,
  VideoIcon,
} from "../src/assets";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { del_image } from "../../LeadManagement/src/assets";
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {withTranslation} from "react-i18next";
import {languageCondition,ArrowBackIcon} from "../../../components/src/ConditionManager.web";

class CreateIncident extends IncidentController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.getMyApartmentList();
    this.getIncidentRelated();
  }

  incidentUploadFileValid = (e: any, existFile: any) => {
    let existFiles = existFile.map((media: any) => media.file);
    let totalFile = [...existFiles, ...e.target.files];

    if (totalFile.length > 6) {
      this.setState({ showError: true, error: "Only six files supported!" });
      e.target.value = null;
      return false;
    }
    let size = 0;
    [...totalFile].forEach((file: any) => {
      size += file.size;
    });
    size = size / (1024 * 1024);
    if (size > 30) {
      this.setState({ showError: true, error: "More then 30MB of files size not allowed!" });
      e.target.value = null;
      return false;
    }
    return true;
  };

  handleImageVideo = (file) => {
    if (file.type === "video/mp4" || file.type === "video/x-m4v" || file.type === "video/mkv" || file.type === "video/quicktime") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    // @ts-ignore
    const {t,i18n} = this.props;
    const language = i18n.language;
    return (
      <>
        <Box className="login-wrapper incident-wrapper">
          <Grid container spacing={2} className="auth-container">
            <Grid item xs={12} md={7} className={languageCondition(language, "arabic-grid auth-cols", "auth-cols createIncident")}>
              <Box className="common_content_block content-block" dir={languageCondition(language,"rtl","ltr")}>
                <Box className="content-header">
                  <Box className="left-block blocks" style={{gap:"1rem"}}>
                    <ArrowBackIcon onClick={() => window.history.back()} />
                    <h4 className="bold-text" style={{ fontSize: "18px" }}>{t("Add New Incident")}</h4>
                  </Box>
                </Box>
                <Box className="content-block-wrapper common-incident-block desktop-ui inputPlaceholderRegistration">
                  <Formik
                    initialValues={{
                      commonArea: " ",
                      incidentRelated: " ",
                      incidentTitle: "",
                      description: "",
                      media: [],
                      myApartment: " "
                    }}
                    validationSchema={this.createIncidentSchema()}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      if (!this.state.sizeError && !this.state.notImageOrVideoError) {
                        this.onSubmit(values);
                      }
                    }}
                  >
                    {({ values, touched, errors, isValid, setFieldError, setFieldValue, handleChange }) => (
                      <Form translate="yes" className="commonForm">
                        <h5 className="frm-title incident-preview-title bold-text">Incident Details</h5>
                        <Box className="formGroup customSelect">
                          <FormControl variant="outlined" >
                            <Select
                              name="myApartment"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              className="formInput_select_s selectIncidentUnit"
                              startAdornment={
                                <img src={Box_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                              }
                              style={{ marginTop: -3 }}
                              onChange={(e) => {
                                setFieldValue("myApartment", e.target.value);
                                this.getCommonArea(e.target.value?.attributes?.society_management?.id);
                              }}
                              value={values.myApartment}
                            >
                              <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu", "selectUnit")}>
                                {t("Select Unit")}
                              </MenuItem>
                              {
                                this.state.myApartmentList?.map((val: any, index: any) => (
                                  <MenuItem key={index} value={val} className={languageCondition(language, "select-arabic-menu", "selectUnit")}>
                                    {`${val?.attributes?.building_management?.name} ${val?.attributes?.apartment_name}`}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="myApartment" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect">
                          <FormControl variant="outlined" >
                            <Select
                              className="formInput_select_s selectIncidentUnit"
                              name="commonArea"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              startAdornment={
                                <img src={House_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                              }
                              className="commonArea-testcases"
                              onChange={(e) => {
                                setFieldValue("commonArea", e.target.value);
                              }}
                              value={values.commonArea}
                            >
                              <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu", "location")}>
                                {t("Location of the Incident")}
                              </MenuItem>
                              {
                                this.state.commonAreaData?.map((val: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    value={val}
                                    className={languageCondition(language, "select-arabic-menu", "location")}
                                  >
                                    {val?.name}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="commonArea" />
                          </FormControl>
                        </Box>
                        <Box className="formGroup customSelect" style={{ marginBottom: "15px" }}>
                          <FormControl variant="outlined" >
                            <Select
                              className="formInput_select_s incidentRelated"
                              name="incidentRelated"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              startAdornment={
                                <img src={Warning_Icon} className="frm-icons" alt="Warning Icon" style={languageCondition(language,{marginLeft:"0px",marginRight:"15px"},{marginLeft:"0px",marginRight:"15px"})}/>
                              }
                              onChange={(e) => {
                                setFieldValue("incidentRelated", e.target.value);
                              }}
                              value={values.incidentRelated}
                            >
                              <MenuItem disabled value=" " className={languageCondition(language, "select-arabic-menu", "incidentRelated")}>
                                {t("Incident is related to")}
                              </MenuItem>
                              {
                                this.state.incidentRelatedData?.map((val: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    value={`${val?.id} ${val?.name}`}
                                    className={languageCondition(language, "select-arabic-menu", "incidentRelated")}
                                  >
                                    {val?.name}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                            <ErrorMessage className="text-error" component="Typography" name="incidentRelated" />
                          </FormControl>
                        </Box>
                        <Box style={{ marginBottom: "15px" }}>
                          <Box className="formGroup" style={{ marginBottom: "0", border: "0.1px solid rgb(209 209 209 / 100%)", borderRadius: "25px" }}>
                            <Field name="incidentTitle" type="text" placeholder={t("Incident Title")} className={languageCondition(language, "formInput_ar", "formInput incidentTitle")} />
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons incidentTitle")}>
                              <img src={Warning_Icon} className="frm-icons" alt="Warning Icon" />
                            </span>
                          </Box>
                          <ErrorMessage className="text-error" component="Typography" name="incidentTitle" />
                        </Box>
                        <Box style={{ marginBottom: "15px" }}>
                          <Box className="formGroup" style={{ marginBottom: "0", border: "0.1px solid rgb(209 209 209 / 100%)", borderRadius: "15px" }}>
                            <TextareaAutosize onChange={(e: any) => {
                              setFieldValue("description", e.target.value);
                            }}
                              value={values.description} placeholder={t("Add Description")} className={languageCondition(language, "formInput_ar incident-text-area description", "formInput incident-text-area description")}
                                              style={{ fontWeight: "normal", fontSize: "16px", borderRadius: "15px" }} />
                            {/* <Field name="description" type="text" placeholder="Add description" className="formInput" /> */}
                            <span className={languageCondition(language, "frmRightIcons", "frmLeftIcons description")}>
                              <img src={Clipboard_Icon} className="clipboard-icon" alt="Clipboard_Icon" />
                            </span>
                          </Box>
                          <ErrorMessage className="text-error" component="Typography" name="description" />
                        </Box>
                        <Box className="formGroup customFileupload">
                          <Button
                            variant="contained"
                            component="label"
                            style={{ borderRadius: "15px", border: "2px dashed rgb(209,209,209)" }}
                          >
                            <img src={Upload_Icon} className="upload-icon" alt="upload-icon" />
                            <p style={{ color: '#7a7878' }}>
                              {t("Add Image / Video")}
                            </p>
                            <input
                              name='media'
                              type="file"
                              hidden
                              multiple
                              accept="image/jpg ,image/jpeg,image/gif,image/png,video/mp4,video/x-m4v,.HEVC,.mov"
                              className="incidentUpload-testcases"
                              onChange={(e: any) => {
                                if (this.incidentUploadFileValid(e, values.media)) {
                                  this.handleSelectMedia(
                                    e,
                                    values.media,
                                    setFieldValue,
                                    setFieldError
                                  );
                                }
                              }}
                            />
                          </Button>
                          {this.state.upload &&
                            <>
                              <Box style={{ marginTop: "20px", overflow: "hidden" }}>
                                <Grid spacing={2} container>
                                  {values?.media?.map((val: any, index: any) => (
                                    <Grid xs={4} sm={4} item onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }, () => console.log(this.state.file)); }}>
                                      <Box className="incident-preview-document">
                                        {this.handleImageVideo(val?.file) || val?.file?.name.includes(".hevc") ?
                                          <video poster={VideoIcon.default}>
                                            <source src={val.url} type={val.file.type} />
                                          </video> :
                                          <img src={val.url} alt="image" />
                                        }
                                        <FullscreenIcon className="play-icon" />
                                        <img
                                          src={del_image}
                                          className="delete-image"
                                          onClick={(e: any) => {
                                            const remainMedia = values.media.filter((img: any, idx: number) => idx !== index);
                                            setFieldValue("media", remainMedia);
                                            e.stopPropagation();
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            </>
                          }
                        </Box>
                        <Box className="customButton preview-incident-btn">
                          <Button variant="contained" type="submit">{t("preview")}</Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className="auth-cols">
              <Box className="right-block" display={{ xs: 'none', md: 'flex' }}>
                <img src={Building1.default} className="building-logo" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        <Loader loading={this.state.loading} />

        <Dialog
          className="delete-document personal"
          fullWidth
          onClose={() => this.setState({ showDialog: false })}
          open={this.state.showDialog}
        >
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              Image / Video
            </Typography>
            <IconButton onClick={() => this.setState({ showDialog: false })}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <DialogContent>
            {this.handleImageVideo(this.state.file) || this.state.file?.name?.includes(".hevc") ?
              <Player
                playsInline
                poster={this.state.file.url}
                src={this.state.file?.url}
              ><BigPlayButton position="center" /></Player> :
              <img src={this.state.file?.url} className="incident-dialog-photo" alt="card-img" />
            }
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(withRouter(CreateIncident));
// Customizable Area End 