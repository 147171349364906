// Customizable Area Start
import React from "react";
interface MyComponentProps {
    openteammember: any;
  }
export const MyTeamImageComponent:React.FC<MyComponentProps>  = ({openteammember}) => {
    const strokeColor = openteammember 
  return (
    <svg 
    width="26.599854px"
    height="26.59961px"
    viewBox="0 0 24.599854 24.59961"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="network-(1)" transform="translate(0.30004883 0.2998047)">
      <path
        d="M13.8824 4.8668C14.4444 4.3544 14.8 3.6188 14.8 2.8C14.8 1.2564 13.544 0 12 0C10.456 0 9.19995 1.2564 9.19995 2.8C9.19995 3.6188 9.55555 4.3544 10.1176 4.8668C8.45795 5.1868 7.19995 6.6484 7.19995 8.4L7.19995 10C7.19995 10.2212 7.37915 10.4 7.59995 10.4L16.4 10.4C16.6208 10.4 16.8 10.2212 16.8 10L16.8 8.4C16.8 6.6484 15.542 5.1868 13.8824 4.8668ZM10 2.80005C10 1.69725 10.8972 0.800049 12 0.800049C13.1028 0.800049 14 1.69725 14 2.80005C14 3.90285 13.1028 4.80005 12 4.80005C10.8972 4.80005 10 3.90285 10 2.80005ZM16 9.59998L8 9.59998L8 8.39998C8 6.85638 9.256 5.59998 10.8 5.59998L13.2 5.59998C14.744 5.59998 16 6.85638 16 8.39998L16 9.59998Z"
        id="Shape"
        
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M6.6824 18.4668C7.2444 17.9544 7.6 17.2188 7.6 16.4C7.6 14.8564 6.344 13.6 4.8 13.6C3.256 13.6 2 14.8564 2 16.4C2 17.2188 2.3556 17.9544 2.9176 18.4668C1.258 18.7868 0 20.2484 0 22L0 23.6C0 23.8212 0.1792 24 0.4 24L9.2 24C9.4208 24 9.6 23.8212 9.6 23.6L9.6 22C9.6 20.2484 8.342 18.7868 6.6824 18.4668ZM2.80005 16.4C2.80005 15.2972 3.69725 14.4 4.80005 14.4C5.90285 14.4 6.80005 15.2972 6.80005 16.4C6.80005 17.5028 5.90285 18.4 4.80005 18.4C3.69725 18.4 2.80005 17.5028 2.80005 16.4ZM8.80005 23.2L0.800049 23.2L0.800049 22C0.800049 20.4564 2.05605 19.2 3.60005 19.2L6.00005 19.2C7.54405 19.2 8.80005 20.4564 8.80005 22L8.80005 23.2Z"
        id="Shape"
        fill-rule="evenodd"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M21.0823 18.4668C21.6443 17.9544 21.9999 17.2188 21.9999 16.4C21.9999 14.8564 20.7439 13.6 19.1999 13.6C17.6559 13.6 16.3999 14.8564 16.3999 16.4C16.3999 17.2188 16.7555 17.9544 17.3175 18.4668C15.6579 18.7868 14.3999 20.2484 14.3999 22L14.3999 23.6C14.3999 23.8212 14.5791 24 14.7999 24L23.5999 24C23.8207 24 23.9999 23.8212 23.9999 23.6L23.9999 22C23.9999 20.2484 22.7419 18.7868 21.0823 18.4668ZM17.2 16.4C17.2 15.2972 18.0972 14.4 19.2 14.4C20.3028 14.4 21.2 15.2972 21.2 16.4C21.2 17.5028 20.3028 18.4 19.2 18.4C18.0972 18.4 17.2 17.5028 17.2 16.4ZM23.2 23.2L15.2 23.2L15.2 22C15.2 20.4564 16.456 19.2 18 19.2L20.4 19.2C21.944 19.2 23.2 20.4564 23.2 22L23.2 23.2Z"
        id="Shape"
        fill-rule="evenodd"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M2.4076 12.7872L1.6084 12.8184C1.6192 13.094 1.6412 13.3732 1.674 13.6476L2.4684 13.5524C2.438 13.2992 2.4176 13.0416 2.4076 12.7872Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M1.6736 11.1588C1.6412 11.4316 1.6188 11.7104 1.6084 11.9876L2.4076 12.0188C2.4176 11.7628 2.438 11.5056 2.468 11.2532L1.6736 11.1588Z"
        id="Path"
        stroke-width="1"
      />
      <path
        d="M5.69564 4.1272C5.47684 4.294 5.26124 4.4724 5.05444 4.658L5.58924 5.2532C5.77964 5.082 5.97844 4.9172 6.18044 4.7636L5.69564 4.1272Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M2.5867 7.97205C2.4691 8.22205 2.3603 8.47965 2.2627 8.73925L3.0115 9.02045C3.1015 8.78125 3.2019 8.54365 3.3103 8.31365L2.5867 7.97205Z"
        id="Path"
        stroke-width="1"
      />
      <path
        d="M2.00124 9.52917C1.92524 9.79358 1.85924 10.066 1.80444 10.3372L2.58884 10.4952C2.63924 10.2452 2.70004 9.99397 2.77044 9.74957L2.00124 9.52917Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M4.4588 5.23877C4.2672 5.44037 4.0832 5.65037 3.9104 5.86357L4.5324 6.36677C4.6916 6.16997 4.862 5.97557 5.0388 5.78957L4.4588 5.23877Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M7.09309 3.22839C6.85109 3.35759 6.60989 3.49959 6.37549 3.65039L6.80909 4.32319C7.02509 4.18359 7.24749 4.05279 7.46989 3.93399L7.09309 3.22839Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M3.41359 6.53003C3.25719 6.75843 3.10839 6.99523 2.97119 7.23483L3.66519 7.63243C3.79159 7.41163 3.92919 7.19283 4.07359 6.98243L3.41359 6.53003Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M10.1048 21.8127L9.94678 22.5971C10.216 22.6515 10.4928 22.6951 10.7692 22.7275L10.862 21.9331C10.608 21.9031 10.3528 21.8627 10.1048 21.8127Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M13.9205 21.808C13.6721 21.8584 13.4173 21.8996 13.1621 21.9308L13.2585 22.7252C13.5345 22.6916 13.8105 22.6468 14.0793 22.5924L13.9205 21.808Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M12.3973 21.9919C12.1417 22.0019 11.8833 22.0031 11.6293 21.9931L11.5977 22.7923C11.7309 22.7979 11.8653 22.8003 12.0013 22.8003C12.1445 22.7995 12.2873 22.7967 12.4293 22.7911L12.3973 21.9919Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M18.9472 4.66113L18.4128 5.25633C18.6032 5.42753 18.788 5.60753 18.9628 5.79153L19.5436 5.24153C19.354 5.04153 19.1536 4.84633 18.9472 4.66113Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M20.0912 5.86597L19.4692 6.36917C19.6288 6.56637 19.7832 6.77397 19.928 6.98517L20.5876 6.53277C20.4308 6.30437 20.264 6.07997 20.0912 5.86597Z"
        id="Path"
        stroke-width="1"
      />
      <path
        d="M22.3915 11.9888L21.5923 12.02C21.5975 12.146 21.5999 12.2732 21.5999 12.4C21.5999 12.53 21.5975 12.6592 21.5923 12.7884L22.3915 12.8196C22.3971 12.6804 22.3999 12.5404 22.3999 12.3992C22.3999 12.262 22.3971 12.1248 22.3915 11.9888Z"
        id="Path"
        stroke-width="1"
      />
      <path
        d="M21.0303 7.23682L20.3359 7.63482C20.4627 7.85602 20.5819 8.08522 20.6907 8.31562L21.4143 7.97402C21.2967 7.72442 21.1675 7.47642 21.0303 7.23682Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M21.7381 8.74084L20.9893 9.02244C21.0789 9.26085 21.1597 9.50604 21.2301 9.75084L21.9993 9.53045C21.9233 9.26484 21.8353 8.99924 21.7381 8.74084Z"
        id="Path"
      />
      <path
        d="M22.1951 10.3389L21.4111 10.4965C21.4615 10.7469 21.5023 11.0021 21.5319 11.2549L22.3263 11.1605C22.2939 10.8869 22.2499 10.6105 22.1951 10.3389Z"
        id="Path"
        stroke-width="1"
      />
      <path
        d="M17.6268 3.65198L17.1936 4.32478C17.4072 4.46238 17.6184 4.61078 17.822 4.76638L18.3076 4.12998C18.0872 3.96158 17.8584 3.80118 17.6268 3.65198Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
      <path
        d="M16.1617 2.86646L15.8413 3.59926C16.0745 3.70126 16.3065 3.81405 16.5317 3.93485L16.9101 3.23006C16.6665 3.09886 16.4145 2.97645 16.1617 2.86646Z"
        id="Path"
        stroke-width="1"
        stroke={strokeColor}
      />
    </g>
  </svg>
   
  );
};
// Customizable Area End
