//@ts-ignore
//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
 Box,
 Button,
 Typography,
 Grid,
 Card,
 CardContent,
 CardActions,
 Dialog,
 DialogTitle,
} from "@material-ui/core";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import IncidentController, { Props } from "./IncidentController.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import { Building1, Close_Icon} from "../src/assets";
import {withTranslation} from "react-i18next";
import {languageCondition} from "../../../components/src/ConditionManager.web";


class IncidentPreview extends IncidentController {
 constructor(props: Props) {
   super(props);
 }


 render() {
   //@ts-ignore
   const { t, i18n } = this.props;
   const language = i18n.language;


   const incidentFromData = JSON.parse(localStorage.getItem("incidentPreview") || '{}');
   const incidentRelated = incidentFromData.incidentRelated?.split(" ");
   if (!incidentFromData) {
     this.props.history.replace("/CreateIncident");
     return null;
   }


   return (
     <>
       <Box className="login-wrapper incident-wrapper" dir={languageCondition(language, "rtl", "ltr")}>
         <Grid container spacing={2} className="auth-container">
           <Grid item xs={12} md={7} className="auth-cols">
             <Box className="common_content_block content-block">
               <Box className="content-header">
                 <Box className="left-block blocks">
                   <Box  className="backIcons"
                    onClick={() => window.history.back()}
                    ><KeyboardBackspaceIcon className={languageCondition(language, "KeyboardForwardIconAR", "")} /></Box>
                   <h4 className="bold-text" style={{ fontSize: "18px" }}>{t("Incident Preview")}</h4>
                 </Box>
               </Box>
               <Box className="content-block-wrapper common-incident-block" style={{ backgroundColor: "#f7f9fe", height: "100%" }}>
                 <Box className="incident-content-wrapper">
                   <Box className="incident-rows">
                     <h4 className="bold-text">{t("Incident  Details")}</h4>
                   </Box>
                   <Card className="incident-card card">
                     <CardContent>
                       <Typography component="span">
                         {t("Affected Area")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentFromData.commonArea?.name}
                       </Typography>
                       <Typography component="span">
                         {t("Incident is related to")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentRelated && incidentRelated.length > 1 ? incidentRelated[1] : ""}
                       </Typography>
                       <Typography component="span">
                         {t("Incident Title")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentFromData.incidentTitle}
                       </Typography>


                       <Typography component="span">
                         {t("Building")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentFromData.myApartment?.attributes?.building_management?.name}
                       </Typography>
                       <Typography component="span">
                         {t("Unit")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentFromData.myApartment?.attributes?.apartment_name}
                       </Typography>
                       <Typography component="span">
                         {t("Description")}:
                       </Typography>
                       <Typography className="sub-title bold-text" component="h5">
                         {incidentFromData.description}
                       </Typography>
                       {
                         incidentFromData?.media?.length !== 0 &&
                           <>
                             <Typography component="span">
                               {t("Photos")}
                             </Typography>
                             <CardActions className="card-img-row">
                               {
                                 incidentFromData?.media?.map((val: any, index: any) => (
                                   val?.file.type === "video/mp4" || val?.file.type === "video/x-m4v" ?
                                     <Box className="video-img" key={index}
                                     onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }); }}
                                     >
                                       <Box className="img-layer"></Box>
                                       <video className="incident-dialog-video"   >
                                         <source src={val.url} type={val.file.type} />
                                       </video>
                                       <FullscreenIcon className="play-icon" />
                                     </Box>
                                     :
                                     <Box className="video-img" key={index}
                                     data-test-id="video"onClick={() => { this.setState({ showDialog: true, file: { url: val.url, type: val?.file.type, name: val?.file?.name } }); }}
                                     >
                                       <Box className="img-layer"></Box>
                                       <img src={val.url} className="card-img" alt="card-img" />
                                       <FullscreenIcon className="play-icon" />
                                     </Box>
                                 ))
                               }
                             </CardActions>
                           </>
                       }
                       {/* <hr /> */}


                     </CardContent>
                   </Card>
                 </Box>
                 <Box className="customButton preview-submit">
                   <Button className="submitIncident" variant="contained"
                    onClick={() => this.createIncident(incidentFromData, incidentRelated)}
                   >{t("Submit")}</Button>
                 </Box>
               </Box>
               <Box className="bottomBlock common-bottom-padding" display={{ xs: 'none', md: 'flex' }}>
                 <h6 className="bottom-text">POWERED BY</h6>
                 {/* <img src={Tenant_Logo?.default} className="tenant-logo" alt="" /> */}
               </Box>
             </Box>
           </Grid>
           {/* desktop footer block */}
           <Grid 
           item xs={12} md={5} className="auth-cols">
             <Box className="right-block" 
             display={{ xs: 'none', md: 'flex' }}>
               <img src={Building1.default} 
               className="building-logo" alt=""
                />
             </Box>
           </Grid>
         </Grid>
         <Dialog
           open=
           {this.state.showDialog}
           onClose={
            () => this.setState(
              { showDialog: false })}
           className="diloag-wrapper"
           PaperProps={{
             style: {
               borderRadius: '8px',
             },
           }}
         >
           <Box className="diloag-body">
             <Box className="diloag-header">
               <DialogTitle className="alert-dialog-title" id="alert-dialog-title">
                 {this.state.file.name}
               </DialogTitle>
               <Button
               className="closeIcon1-testcases"
               onClick={() => { this.setState({ showDialog: false }); }}
               >
                 <img src={Close_Icon} className="close-icon"
                 onClick={() => { this.setState({ showDialog: false }); }}
                 />
               </Button>
             </Box>
             <Box className="diloag-content-body">
               {
                 this.state.file.type === "video/mp4" || this.state.file.type === "video/x-m4v" ?
                   <video className="incident-dialog-video" controls >
                     <source src={this.state.file.url} type={this.state.file.type} />
                   </video>
                   :
                   <Box>
                     <img src={this.state.file.url} className="incident-dialog-photo" alt="card-img" />
                   </Box>
               }
             </Box>
           </Box>
         </Dialog>
       </Box>
       <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
       <Loader loading={this.state.loading} />
     </>
   );
 }
}


export default withTranslation()(withRouter(IncidentPreview));
// Customizable Area End
