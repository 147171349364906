// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../ApiCallCommon.web";
import {BlockComponent} from "../../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
  history?:any
  t?:any
  i18n?:any
  match?:any
  location?:any
  styleClass?:any
}

interface S {
  loading: boolean;
}

interface SS {
  id: any;
}

export default class ComplexDropdownController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
// Customizable Area End
